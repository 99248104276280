const Production = {
  Constants: {
      "WEBSITE_URL": "https://africanwomenamplified.com",
      "WEB_APP_URL": "https://app.africanwomenamplified.com",
      "API_BASE_URL": "https://us-central1-africanwomenamplified.cloudfunctions.net/api"
  }
}

const Development = {
  Constants: {
    "WEBSITE_URL": "https://africanwomenamplified.com",
    "WEB_APP_URL": "http://localhost:3000",
    "API_BASE_URL": "https://us-central1-africanwomenamplified.cloudfunctions.net/api"
  }
}

export default process.env.NODE_ENV === 'production' ? Production.Constants : Development.Constants;