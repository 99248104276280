import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/Hiring_section1.module.scss';
import section2Styles from '../scss/Hiring_section2.module.scss';
import styles from '../scss/Hiring.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanTwo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
	  isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      isRequesting: undefined,
      lessonProgress: '0',
      planEntries: {
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: '',
          manual_entry_q6: '',
          manual_entry_q7: '',
          manual_entry_q8: '',
          manual_entry_q9: '',
          manual_entry_q10: '',
          manual_entry_q11: '',
          manual_entry_q12: '',
          manual_entry_q13: ''
      },
      planEntriesErrors: {
        manual_entry_q1: '',
        manual_entry_q2: '',
        manual_entry_q3: '',
        manual_entry_q4: '',
        manual_entry_q5: '',
        manual_entry_q6: '',
        manual_entry_q7: '',
        manual_entry_q8: '',
        manual_entry_q9: '',
        manual_entry_q10: '',
        manual_entry_q11: '',
        manual_entry_q12: '',
        manual_entry_q13: ''
      },
      errorMessage: '',
      successMessage: '',
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP9"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5,
          manual_entry_q6: responseInfo.data.manual_entry_q6,
          manual_entry_q7: responseInfo.data.manual_entry_q7,
          manual_entry_q8: responseInfo.data.manual_entry_q8,
          manual_entry_q9: responseInfo.data.manual_entry_q9,
          manual_entry_q10: responseInfo.data.manual_entry_q10,
          manual_entry_q11: responseInfo.data.manual_entry_q11,
          manual_entry_q12: responseInfo.data.manual_entry_q12,
          manual_entry_q13: responseInfo.data.manual_entry_q13
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress(value){
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handleRedirect(){
    window.location = "./lwa-course-plan";
  }

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
			...this.state,
      planEntries: planEntries
    });
	}

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/multiple-save", {
          "lesson_plan": "LP9",
          "input": this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Nine</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
                successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                  <section className={section1Styles.section1}>
                  <div className={section1Styles.flexCol}>
                    <div className={section1Styles.flexCol1}>
            
                      <div className={section1Styles.content_box}>
                        <div className={section1Styles.flexRow1}>
                          <h1 className={section1Styles.hero_title}>Building a Strong Team Lesson Plan</h1>
                          <div className={section1Styles.flexRow1__spacer} />
            
                          
                        </div>
                      </div>
                    </div>
            
                    <div className={section1Styles.content_box1}>
                      <h3 className={section1Styles.subtitle}>
                        You are starting your business or Your business is growing, Congratulations!!! Now is the time to take the
                        important step of hiring superstars to help you grow your business.  <br />
                        In this lesson plan we will review some green flags (positive indicators) to help you hire the best fit for
                        your business.
                        <br />
                      </h3>
                    </div>
            
                    <div className={section1Styles.group}>
                      <div className={section1Styles.content_box2}>
                        <div className={section1Styles.flexCol4}>
                          <h2 className={section1Styles.medium_title2}>By the end of this session, you should:</h2>
                          <h3 className={section1Styles.subtitle1}>
                            1. Know what the following terms mean: company culture, turnover, attrition, core values, aspirational
                            values, permission to play values.
                            <br />
                            2. Know WHO to hire.  <br />
                            3. Know what the four types of Organizational Culture are.
                            <br />
                            <br />
                            4. Know the steps in the hiring process
                            <br />
                            <br />
                            5. Know the role of company culture.
                            <br />
                            <br />
                            6. Know the difference between a core and an aspirational value.
                            <br />
                          </h3>
                        </div>
                      </div>
            
                      <img
                        className={section1Styles.image21}
                        src={require('../../assets/courseplan-nine/93f9b76025b2ba893226a842b29cccfc.png')}
                        alt="alt text"
                      />
                    </div>
                  </div>
                </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                    <div className={section2Styles.flexCol}>
                      <div className={section2Styles.flexCol1}>
                        <h2 className={section2Styles.medium_title1}>Hiring new staff/employees</h2>
                        <h4 className={section2Styles.highlights}>
                          Before you Hire, it is important to ask yourself these questions:
                          <br />
                          <br />
                          1. What are the gaps in my business?  You should start by filling the gaps that are necessary for the core
                          parts of your business to function efficiently at the stage your business finds itself
                          <br />
                          <br />
                          <TextArea required
                              value={this.state.planEntries.manual_entry_q1}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q1");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                          <br />
                          <br />
                          2. What are your business’s weak spots? Gaps are deficiencies, weak spots on the other hand are skills that
                          you may possess, but you are not very good at.
                          <br />
                          <br />
                          <TextArea required
                              value={this.state.planEntries.manual_entry_q2}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q2");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                          <br />
                          <br />
                          3. What are your time drains? A time drain happens when you are committing significant amount of time to a
                          project or initiative that you could hire someone else to do.
                          <br />
                          <br />
                          <TextArea required
                              value={this.state.planEntries.manual_entry_q3}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q3");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                          <br />
                          <br />
                          4. What currently works well in your business? If your primary reason for hiring for your business is
                          because you want to accelerate growth but you are limited by a variety of factors, consider in that case
                          hiring for a role that will help you expand the parts of your startup business that already generates a good
                          return.
                          <br />
                          <br />
                          <TextArea required
                              value={this.state.planEntries.manual_entry_q4}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q4");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                          <br />
                          <br /> <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol2}>
                        <h1 className={section2Styles.title}>When thinking of WHO to hire, you need to:</h1>
                        <h3 className={section2Styles.subtitle2_box}>
                          <div className={section2Styles.subtitle2}>
                            <span className={section2Styles.subtitle2_span0}>
                              1. Thoroughly assess your potential new hire
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span1}>
                              So, your uncle’s sister’s brothers son has recommended this amazing individual to join your business to
                              help you out. This is fantastic news! Before you commit, however, make sure to independently VET the
                              candidate. You need to check their references, do a background check, and check every conceivable and
                              available detail about your potential hire(s). Be sure to ask for and call their references. It is also
                              a good idea to verify their past employment by calling the HR departments of those companies. Vetting
                              them properly to ensure they are a good fit for your business before they join can save you time and
                              money in the long run.
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span2}>
                              2. Hire for Potential, not (Just) Track Record
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span3}>
                              It is important to look for potential in your future employees, and not just focus on evidence of past
                              success. It is important to look for an individual who has a strong interest or passion for the causes
                              or missions that are similar to yours, and, separately, evidence that the person is really good at what
                              he or she has done before (even if it is a variety of different things).
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span4}>
                              3. Have your Applicants Demonstrate Skill or Aptitude
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span5}>
                              Many people know exactly how to answer interview questions in a way that instills confidence in a hiring
                              manager. Research shows that the best way to vet someone is to have them complete a task, e.g., if you
                              are hiring a salesperson, ask them to sell you something. How effectively they can do the assigned task
                              and even how they go about doing it will give you an insight into if they would be a good fit for your
                              business.
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span6}>
                              4. Invite Your Superstar to truly be part of your Team
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span7}>
                              Once you hire someone, you have two choices; either you consider them an employee, in the sense that you
                              issue them directives, evaluate their work, and compensate them for their time or, you can consider them
                              a member of your team that has chosen to dedicate their time to making your vision a reality. Most
                              people when given the choice will rather be seen as the latter, a member of a team that is helping bring
                              the vision to reality and will appreciate being treated as such.  <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span8}>
                              5. Design an Onboarding Process
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span9}>
                              Take the time to put together an official onboarding process which will educate all incoming employees
                              on the company culture, history, and strategy so every single employee has the same process coming into
                              your business. <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span10}>
                              6. Legal <br />
                            </span>
                            <span className={section2Styles.subtitle2_span11}>
                              Make sure you have a contract that clearly outlines your legal responsibility to your employee and vice
                              versa.  <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span12}>
                              7. Insurance
                              <br />
                            </span>
                            <span className={section2Styles.subtitle2_span13}>
                              Protect yourself, your employee and your business with the right Insurance depending on your business.
                              Look into providing workers compensation insurance for your employees where possible as this will
                              protect you and your employees in the event of an accident. It covers medical care and certain lost
                              wages, and it safeguards you from being sued, something your small and growing business will not want to
                              deal with.
                              <br />
                              <br />
                              <br />
                            </span>
                          </div>
                        </h3>
                      </div>
              
                      <div className={section2Styles.flexCol3}>
                        <h1 className={section2Styles.title}>The Hiring/Recruitment Process</h1>
                        <h4 className={section2Styles.highlights1}>
                          Step 1: Identify your hiring needs.  <br />
                          What position (s) are you hiring for?  <br />
                          <br />
                          Step 2: Prepare a job description
                          <br />A job description (JD) summarizes the essential responsibilities, activities, qualifications, and
                          skills for a role. This document describes the type of work to be performed and should include:
                          <br />
                          Job Title  <br />
                          Job Responsibilities
                          <br />
                          Candidate’s necessary qualifications and skills
                          <br />
                          Compensation, benefits, and perks  <br />
                          Location (remote or at a specific location)
                          <br />
                          <br />
                          Step 3: Devise your recruitment strategy
                          <br />
                          Create job posts using the JD discussed above.
                          <br />
                          Use social media to advertise the role.
                          <br />
                          Invest in an applicant tracking system.
                          <br />
                          Explore niche job boards if the position you are hiring for is a niche position.
                          <br />
                          Consider using college recruiting if your position is flexible.
                          <br />
                          <br />
                          Step 4: Screen and shortlist candidates.  <br />
                          Screen and shortlist the best fit candidates for your business. Remember that just because they are a great
                          candidate does not necessarily make them a great candidate for your business.  <br />
                          <br />
                          Step 5: Interview Process
                          <br />
                          Stages of an Interview:
                          <br />
                          #1) Introductions - this is where you and the candidate will introduce yourselves. Remember to be courteous
                          and keep in mind that most candidates are nervous, try to calm them down.  <br />
                          #2) Small Talk - After introductions have been made, it is a good idea to engage in a little small talk with
                          the candidate. This should help get their minds a little more relaxed so avoid speaking about controversial
                          topics.  <br />
                          #3) Information Gathering   
                          <br />
                          After small talk ask the candidate to give you their elevator pitch. This is an important step because it
                          showcases just how prepared or unprepared the candidate is and tests their ability to think on their feet.
                          <br />
                          While the candidate is giving their elevator pitch, it is important to pay close attention: is their speech
                          organized? Is it concise? Does the candidate sound confident about their abilities and qualifications?
                          What’s their body language like when they’re speaking?
                          <br />
                          These are all important items to keep track of, as they will often provide you with all you need to know
                          about what kind of employee this candidate will be.
                          <br />
                          #4) Question/Answer - This is where you give the candidate an opportunity to ask you about your business.
                          <br />
                          #5) Wrap Up - always end the interview with a handshake and thank the candidate for their time.
                          <br />
                          <br />
                          Step 6: Make the offer
                          <br />
                          Before you make the offer, remember to check with the candidate’s references. If everything checks out, you
                          can make the offer. However, there is always a chance that the candidate you have selected first might not
                          accept your offer. You therefore must be prepared to extend the offer to the second best or third best
                          candidate.
                          <br />
                          <br />
                          Step 7: Employee Onboarding
                          <br />
                          Onboarding is the process of integrating a new employee with your company and its culture, as well as
                          getting them the tools and information they need to succeed at their role.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol4}>
                        <h1 className={section2Styles.title}>{'Training & Orientation'}</h1>
                        <h4 className={section2Styles.highlights2}>
                          When you onboard a new employee, it is important to give them an insight into your company culture and what
                          their role is as an employee. This is the right time to introduce your new employee to your company’s goals
                          and give them relevant information about your company structure and departments/sections. You can even set
                          goals for the employees for the next 30, 45, 60, or 90 days.
                          <br />  <br />
                          Orientation is important as it will bring the new employee up to speed and help them get started quickly. It
                          also gives them access and an understanding of how your company works.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol4}>
                        <h1 className={section2Styles.title}>Company Culture</h1>
                        <h4 className={section2Styles.highlights3}>
                          Company culture is defined as a shared set of values, goals, attitudes, and practices that make up an
                          organization. Aspects such as working environment, company policies and employee behavior can all contribute
                          to company culture.
                          <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol5}>
                        <h1 className={section2Styles.title}>The Role of Company Culture</h1>
                        <h4 className={section2Styles.highlights4}>
                          1. Companies with good cultures have higher employee engagement ratings - Having a healthy company culture
                          makes your employees feel that their contributions are valued in your business. Engaged employees are more
                          productive in their work, which has a direct impact on the company’s bottom line.
                          <br />
                          <br />
                          2. A good company culture reduces turnover/attrition rates - Your employees will look for work elsewhere
                          when they do not feel like they are a good fit for your business. Your employees may not feel like a good
                          fit due to a myriad of reasons including but not limited to negative work environment at work or a company
                          culture that does not fit with their values. A good company culture is one that respects diversity and
                          recognizes the value and contribution of each employee.
                          <br />
                          <br />
                          3. A positive company culture can help attract higher-quality candidates.-  Job seekers often evaluate the
                          company’s culture as part of making a decision of whether they want to work for a particular organization or
                          not. When the company culture is a positive one, it attracts top candidates and makes them excited at the
                          prospect of working for the company. Where the company culture is negative or toxic most people look
                          elsewhere.  <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol5}>
                        <h1 className={section2Styles.title}>4 Types of Organizational Culture</h1>
                        <h4 className={section2Styles.highlights5}>
                          1. Companies with good cultures have higher employee engagement ratings - Having a healthy company culture
                          makes your employees feel that their contributions are valued in your business. Engaged employees are more
                          productive in their work, which has a direct impact on the company’s bottom line.
                          <br />
                          <br />
                          2. A good company culture reduces turnover/attrition rates - Your employees will look for work elsewhere
                          when they do not feel like they are a good fit for your business. Your employees may not feel like a good
                          fit due to a myriad of reasons including but not limited to negative work environment at work or a company
                          culture that does not fit with their values. A good company culture is one that respects diversity and
                          recognizes the value and contribution of each employee.
                          <br />
                          <br />
                          3. A positive company culture can help attract higher-quality candidates.-  Job seekers often evaluate the
                          company’s culture as part of making a decision of whether they want to work for a particular organization or
                          not. When the company culture is a positive one, it attracts top candidates and makes them excited at the
                          prospect of working for the company. Where the company culture is negative or toxic most people look
                          elsewhere.  <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol7}>

                        <div className={section2Styles.flexCol8}>
                            <div class="accordion" id="accordiono">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingo">
                                  <button class="accordion-button" type="button" 
                                  data-bs-toggle="collapse" 
                                  data-bs-target="#collapseo"
                                  aria-expanded="true" 
                                  aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}> Clan (Collaborative) Culture</h3></button>
                                  </h2>
                                  <div class="accordion-collapse collapse show" id="collapseo" aria-labelledby="headingo" data-bs-parent="#accordiono">
                                      <div class="accordion-body">
                                      <h4 className={section2Styles.highlights7}>
                                        Clan culture primarily exists in more traditional organizations versus digital ones. It is a
                                        people-focused, highly collaborative work environment with a focus on nurturing employees through
                                        interpersonal connections where communication is prioritized. Clan culture values action-orientation and
                                        the embrace of change, and it involves breaking down barriers between the executives and employees of the
                                        institution and encourages mentorship opportunities.
                                        <br />
                                      </h4>
                                      </div>
                                  </div>
                              </div>
                            </div> 
                        </div>
              
                        <div className={section2Styles.flexCol9}>
                            <div class="accordion" id="accordiona">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="headinga">
                                  <button class="accordion-button" type="button" 
                                  data-bs-toggle="collapse" 
                                  data-bs-target="#collapsea"
                                  aria-expanded="true" 
                                  aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Adhocracy Culture</h3></button>
                                  </h2>
                                  <div class="accordion-collapse collapse show" id="collapsea" aria-labelledby="headinga" data-bs-parent="#accordiona">
                                      <div class="accordion-body">
                                      <h4 className={section2Styles.highlights7}>
                                      This company culture encourages risk-taking, individuality and creativity.  An adhocracy culture is an
                                      innovative, adaptable work environment which seeks to develop the next big industry breakthrough.
                                      Typically, this type of company culture prioritizes converting new ideas to market growth and company
                                      success.
                                      <br />
                                    </h4>
                                      </div>
                                  </div>
                              </div>
                            </div> 
                        </div>
              
                        <div className={section2Styles.flexCol10}>
                          <div class="accordion" id="accordionb">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingb">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapseb"
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Market Culture</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapseb" aria-labelledby="headingb" data-bs-parent="#accordionb">
                                    <div class="accordion-body">
                                    <h4 className={section2Styles.highlights7}>
                                    Market culture is a results-oriented work environment where external success is placed above internal
                                    satisfaction, prioritizing the bottom line. It often values meeting quotas, reaching targets, and getting
                                    results. In other words, this is a culture where all employees are expected to be on their “A-game” all
                                    the time. Employees that consistently succeed in meeting or exceeding targets and quotas experience
                                    significant financial rewards or promotion opportunities. Market culture also commonly involves degrees of
                                    separation between the executives and the employees of the institution.
                                    <br />
                                  </h4>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
              
                        <div className={section2Styles.flexCol11}>
                            <div class="accordion" id="accordionc">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingc">
                                  <button class="accordion-button" type="button" 
                                  data-bs-toggle="collapse" 
                                  data-bs-target="#collapsec"
                                  aria-expanded="true" 
                                  aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Hierarchical Culture</h3></button>
                                  </h2>
                                  <div class="accordion-collapse collapse show" id="collapsec" aria-labelledby="headingc" data-bs-parent="#accordionc">
                                      <div class="accordion-body">
                                      <h4 className={section2Styles.highlights7}>
                                      Hierarchical culture is a traditional, risk-averse work environment where power and decision-making rest
                                      at the top. In these work cultures, there exists little room for adaptability and change. Well-defined
                                      processes, stability, and uniformity are highly valued. While this type of organizational culture can be
                                      very efficient, it is not ideal for fostering creativity or innovation. It often involves a set chain of
                                      command and multiple degrees of separation between the executives and employees of the institution.
                                      <br />
                                    </h4>
                                      </div>
                                  </div>
                              </div>
                            </div>
                        </div>

                      </div>
              
                      <div className={section2Styles.flexCol12}>
                        <h1 className={section2Styles.title}>How do you build a positive Company culture?</h1>
              
                      </div>
              
                      <div className={section2Styles.flexCol14}>
                          <div class="accordion" id="accordiond">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingd">
                                  <button class="accordion-button" type="button" 
                                  data-bs-toggle="collapse" 
                                  data-bs-target="#collapsed"
                                  aria-expanded="false" 
                                  aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Establish the Values System</h3></button>
                                  </h2>
                                  <div class="accordion-collapse collapse" id="collapsed" aria-labelledby="headingd" data-bs-parent="#accordiond">
                                      <div class="accordion-body">
                                      <h4 className={section2Styles.highlights9_box}>
                          <div className={section2Styles.highlights9}>
                            <span className={section2Styles.highlights9_span0}>
                              Core values are the deeply ingrained principles that guide all of a company’s actions; they serve as its
                              cultural cornerstones. Core values can never be compromised, either for convenience or short-term
                              economic gain.  <br />
                              Your company values do not have to be unique, but they do have to be what your business wants to see in
                              itself and its employees.  <br />
                              Find below some examples of company values to give you inspiration for writing your own:
                              <br />
                            </span>
                            <span className={section2Styles.highlights9_span1}>Integrity:</span>
                            <span className={section2Styles.highlights9_span2}>
                              <br />
                              Commitment, Honesty, Open-mindedness, Respect, Trust
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.highlights9_span3}>
                              Personal responsibility:
                              <br />
                            </span>
                            <span className={section2Styles.highlights9_span4}>
                              Goals-oriented, Accountability, Challenge, Cost-conscious, Determination, Drive, Empower, Growth, Hard
                              work, Ownership
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.highlights9_span5}>
                              Building a better world:
                              <br />
                            </span>
                            <span className={section2Styles.highlights9_span6}>
                              Accessibility, Boldness, Creativity, Education, Ethical, Environment Impact, Innovation, Fair,
                              Sustainability, Vision, Compassion, Empathy, Social responsibility, Social justice
                              <br />
                              People at the core:
                              <br />
                              Teamwork, Inclusivity, Mutual respect, Community, Communication, Courage, Curiosity, Belonging,
                              Diversity, Equity, Inclusion, Leadership, Passion, Selflessness, Human (and animal) rights
                              <br />
                              <br />
                              <TextArea required
                              value={this.state.planEntries.manual_entry_q5}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q5");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                              <br />
                              <br />
                              Aspirational values are those values that your company needs to succeed in the future but are lacking
                              currently. A company may need to develop a new value to support a new strategy, for example, or to meet
                              the requirements of a changing market or industry, that is where the aspirational values come to play.
                              <br />
                              <br />
                              <TextArea required
                              value={this.state.planEntries.manual_entry_q6}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q6");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                              <br />
                              <br />
                              Permission-to-play values  reflect the minimum behavioral and social standards your business requires of
                              any of its employees. These values tend not to vary much across companies, particularly those working in
                              the same region or industry, which means that, by definition, they never really help distinguish a
                              company from its competitors. e.g., respect for others, honesty, integrity etc.
                              <br />
                              <br />
                              <TextArea required
                              value={this.state.planEntries.manual_entry_q7}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q7");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                              <br />
                              <br />
                              Accidental values are the shared behaviors, beliefs, and attributes that arise without being cultivated
                              by leadership and take hold over time. These values usually reflect the common interests or
                              personalities of the organization’s employees. Accidental values can be good for a company, such as when
                              they create an atmosphere of inclusivity. But they can also be negative forces, reinforcing negative
                              behaviors or attributes throughout your organization, and that will eventually undermine your business’s
                              core values over time.
                              <br />
                              <br />
                              <TextArea required
                              value={this.state.planEntries.manual_entry_q8}
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q8");}}
                              autoSize={{ minRows: 10, maxRows: 20 }}
                          />
                            </span>
                          </div>
                              </h4>
                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
              
                      <div className={section2Styles.flexCol15}>
                          <div class="accordion" id="accordione">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="headinge">
                                  <button class="accordion-button" type="button" 
                                  data-bs-toggle="collapse" 
                                  data-bs-target="#collapsee"
                                  aria-expanded="false" 
                                  aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Company culture goals</h3></button>
                                  </h2>
                                  <div class="accordion-collapse collapse" id="collapsee" aria-labelledby="headinge" data-bs-parent="#accordione">
                                      <div class="accordion-body">
                                      <h4 className={section2Styles.highlights10}>
                          This company culture encourages risk-taking, individuality and creativity.  An adhocracy culture is an
                          innovative, adaptable work environment which seeks to develop the next big industry breakthrough. Typically,
                          this type of company culture prioritizes converting new ideas to market growth and company success.
                          <br />A company’s culture goals are the company’s objectives which relate to ideology, interpersonal
                          dynamic, behavior, community support, or workplace representation.
                          <br />
                          <br />
                          Here are three strategies which provide useful techniques to help you set goals at any level within your
                          company.  <br />
                          <br />
                          1. Management By Objectives (MBO Strategy)
                          <br />
                          The MBO strategy is a straightforward goal-setting method which is best used in helping employees or direct
                          reports understand the responsibilities and expectations specific to their role within a given project, or
                          day-to-day operations. MBOs can be implemented in departmental or enterprise level goal setting, but should
                          be used in tandem with other, more comprehensive strategies as MBOs only help you understand what should be
                          achieved, and not the steps you will need to take in order to get there.  <br />
                          In setting culture goals, MBOs will provide clear statements describing ideal end-results of unspecified
                          cultural initiatives.  <br />
                          <br />
                          Here are a few examples of cultural goals set using the MBO method.  <br />
                          <br />
                          Personal
                          <br />
                          Cultivate a better understanding of how to be supportive of colleagues with different backgrounds than my
                          own.
                          <br />
                          Present argument for inclusive language in marketing materials to supervisor.
                          <br />
                          Participate in departmental gatherings.
                          <br />
                          <br />
                          Departmental
                          <br />
                          Cultivate transparency in departmental decision making.
                          <br />
                          Ensure all projects are a collaborative effort with employees’ autonomy at the forefront.
                          <br />
                          Organizational
                          <br />
                          Create a company culture dedicated to learning and improvement.
                          <br />
                          Create cultural workplace initiatives which support the wellbeing of the wider community.
                          <br />
                          <br />
                          2. SMART Goals
                          <br />
                          <br />
                          The SMART acronym stands for Specific, Measurable, Attainable, Relevant and Time-based. This objective
                          strategy was designed to help goal-setters get as clear as possible on what it is they are aiming for, and
                          how they are going to get there. They can be easily leveraged for personal, team, departmental or enterprise
                          objectives.  <br />
                          Here is an example of an enterprise culture goal set using the SMART method.  <br />
                          <br />
                          Specific – Create a broad company dynamic which is encouraging of cross-level collaboration, employee
                          autonomy and transparent decision-making processes.  <br />
                          <br />
                          Measurable – Attainment of objectives will be measured by change in rates of participation, retention
                          levels, and through candid employee feedback.  <br />
                          <br />
                          Attainable – Our company holds cross-level collaboration as a fundamental value. Leadership will be trained
                          in facilitating collaboration and soliciting ideas and input from direct reports.  <br />
                          <br />
                          Relevant – Collaborative work environments are demonstrated to have a positive effect on employee
                          engagement, retention, and performance. Time-based – Leadership training will be completed within 1 year.
                          Engagement and retention metrics will be reviewed and assessed every 3 months to measure progress.  <br />
                          <br />
                          3. Objectives and Key Results (OKR Strategy)  <br />
                          <br />
                          The OKR strategy is a versatile goal-setting method that allows you to clearly understand your objective, as
                          well as the steps (“key results”) you will need to take in order to complete said objective. These key
                          results can include dynamic shifts, training requirements, hiring decisions, structural changes, and any
                          other relevant article or element which is necessitated in reaching your ultimate goal.  <br />
                          One particular benefit to using OKRs is that they can be implemented in tandem with other goal-setting
                          strategies. For example, you can define your objective using the SMART method, and your key results using
                          MBOs. But even without, OKRs have the greatest application potential and are the only strategies which lay
                          out a roadmap to your objective in their design.  <br />
                          <br />
                          Here is an example of a cultural objective set using the OKR method.
                          <br />
                          <br />
                          Objective: Create a company culture which is supportive of a healthy work-life balance for all employees.
                          <br />
                          <br />
                          Key result 1: Streamlined day-to-day operations in all departments.
                          <br />
                          <br />
                          Key result 2: Better mental health service access for all employees.  <br />
                          <br />
                          Key result 3: Flexible work hours, option to work remotely.  <br />
                          <br />
                        </h4>
                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
              
                      <div className={section2Styles.flexCol16}>
                        <h1 className={section2Styles.title}>Culture Goals and OKRs</h1>
                        <h4 className={section2Styles.highlights11}>
                          Facilitating cultural changes at your organization rarely has an obvious solution. And that is where OKRs
                          come in. Using an Objectives and Key Results strategy to implement culture shifts within your company will
                          allow you to set a goal and create flexible but comprehensive targets that track and measure cultural change
                          as it happens.
                          <br />
                          <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol17}>
                          <div class="accordion" id="accordionf">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingf">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapsef"
                                aria-expanded="false" 
                                aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Involve Your Entire Team</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse" id="collapsef" aria-labelledby="headingf" data-bs-parent="#accordionf">
                                    <div class="accordion-body">
                                    <h4 className={section2Styles.highlights12}>
                          It is important to include your employees in the company culture discussions from the start as they can give
                          invaluable feedback as to the culture that currently exists so you can amend or reinforce. Ask for their
                          help in defining a common language, set of values, and standards that you want everyone in your company to
                          adhere to. Encourage your employees (using surveys and other anonymous response tools) to be frank about
                          what works, what does not work so well and what they would want to change in relation to your existing
                          culture, then work together with them to find viable solutions of how your company culture may be improved.
                          Involving your employees will ensure that they feel a part of the discussion and therefore are more likely
                          to buy into the final solution.
                          <br />
                        </h4>
                                    </div>
                                </div>
                            </div>
                          </div>                        
                      </div>
              
                      <div className={section2Styles.flexCol18}>
                          <div class="accordion" id="accordionc">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingc">
                                  <button class="accordion-button" type="button" 
                                  data-bs-toggle="collapse" 
                                  data-bs-target="#collapsec"
                                  aria-expanded="fals" 
                                  aria-controls="collapseOne"><h3 className={section2Styles.subtitle23}>Follow Your Company Culture's Best Practices</h3></button>
                                  </h2>
                                  <div class="accordion-collapse collapse" id="collapsec" aria-labelledby="headingc" data-bs-parent="#accordionc">
                                      <div class="accordion-body">
                                      <h4 className={section2Styles.highlights13}>
                          Best practices are a standard set of guidelines, ethics, or ideas that represent the most efficient and/or
                          prudent course of action in any given business situation. Although each business will differ, the best
                          practices outlined below should help you on your journey to building a positive company culture.  <br />
                          <br />
                          1. Establish well-defined departmental goals
                          <br />
                          It is important that all team, department, section members know their objectives so they have tangible key
                          performance indicators (KPIs) they can work towards.   
                          <br />
                          <br />
                          2. Prioritize Respect  <br />
                          Every employee who works in your business should feel valued and heard, regardless of their status within
                          your company. Respect goes a long way in ensuring employees feel valued and thus give their best to your
                          company.  <br />
                          <br />
                          3. Promote your Organization’s Goals
                          <br />
                          Make sure your employees are clear on your organization’s long-term objectives. Knowing this will help them
                          cultivate a sense of professional purpose and help them recognize how their section quotas work in the
                          bigger picture towards achieving the company’s mission.
                          <br />
                          <br />
                          4. Promote Diversity and Inclusivity and a strict zero tolerance policy
                          <br />
                          Embrace diversity and encourage inclusivity by welcoming individuals from all backgrounds and celebrating
                          their differences. Ensure your Human Resource department makes diversity a part of your recruitment strategy
                          and work consistently to guarantee diversity and inclusion continue to be foundational elements as your
                          organization grows. At the same time, you need to ensure that your employees know their rights and
                          individualities are protected within the workplace
                          <br />
                          <br />
                          5. Create an Employee Recognition Initiative
                          <br />
                          Recognize and reward employees for achieving outstanding results. Doing so will encourage them to continue
                          performing at impressive levels and make them feel valued within the company. It will also motivate their
                          peers to aspire to be more like them.
                          <br />
                          <br />
                          6. Communication is essential.
                          <br />
                          Communication should be a long-term commitment to an ongoing and deep dialogue between management and staff
                          that welcomes and encourages input from all levels of the organization.
                          <br />
                          <br />
                          7. Accept and Utilize Your Employee’s Feedback  <br />
                          Feedback is an important tool that business owners should appreciate from their employees. Constructive
                          feedback brings the areas you should focus on in your business to the fore front. Encourage, accept and
                          utilize employee feedback to make your business the best it can be. Employees will also be more forthcoming
                          when they know their feedback makes a difference to the organization.  <br />
                          <br />
                          8. Be Flexible  <br />
                          Businesses need to run to a strict schedule, but it is important as a business owner to be empathetic and
                          recognize that sometimes life happens, and things may not go as you planned. Your employees should feel able
                          to approach you with emergencies. Once your employees recognize that the business is interested in their
                          well-being, they will be more inclined to also give their best.  <br />
                          <br />
                          9. Be Transparent  <br />
                          It is important to foster and promote transparency and open communication between your executive management,
                          department heads and team members. Doing so will create a positive work culture where employees do not feel
                          ambushed by business decisions.  <br />
                          <br />
                          10. Plan time outside the office  <br />
                          Plan social time for your employees outside the office. Maybe a once a month or quarterly social session
                          where your employees can “hang out” together outside of work. Think through with your staff about the types
                          of events they would most enjoy.
                          <br />
                          <br />
                          <br />
                          11. Update your HR practices constantly
                          <br />
                          It is important to integrate employee behaviour metrics into your performance review process, as well as
                          hiring and promotion practices
                          <br />
                          Hiring the right people, rewarding them for doing a good job, and recognizing people for embracing and
                          embodying your business culture values is essential to sustaining your desired work culture.
                          <br />
                          <br />
                          12. Communication is essential.
                          <br />
                          Communication should be an intentional long-term commitment to an ongoing dialogue with your employees that
                          welcomes and encourages input from all levels of your organization consistently.
                          <br />
                          <br />
                          13. Reassess your company culture regularly
                          <br />
                          It is important to review and assess your company’s culture regularly to see if you are on the path you want
                          to be on. If you have veered off the path, regular checks will help you realign your culture.
                          <br />
                          <br />
                                      </h4>
                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
              
                      <div className={section2Styles.flexCol19}>
                        <h1 className={section2Styles.title}>
                          Quick Quiz:
                          <br />
                        </h1>
                        <h3 className={section2Styles.subtitle4_box}>
                          <div className={section2Styles.subtitle4}>
                            <span className={section2Styles.subtitle4_span0}>
                              1. What does company culture mean?
                              <br />
                              <br />
                              <Input size="large" required type="text" id="manual_entry_q9" placeholder="" name="manual_entry_q9" 
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q9");}}
                              value={this.state.planEntries.manual_entry_q9}  />
                              {
                              this.state.planEntriesErrors.manual_entry_q9.length > 0 && 
                              <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q23}</div>
                              }
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle4_span4}>
                              <br />
                              <br />
                              2. What are the four types of Organizational Culture?
                              <br />
                              <br />
                              <Input size="large" required type="text" id="manual_entry_q10" placeholder="" name="manual_entry_q10" 
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q10");}}
                              value={this.state.planEntries.manual_entry_q10}  />
                              {
                              this.state.planEntriesErrors.manual_entry_q10.length > 0 && 
                              <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q23}</div>
                              }
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle4_span8}>
                              <br />
                              3. What is the role of company culture?
                              <br />
                              <br />
                              <Input size="large" required type="text" id="manual_entry_q11" placeholder="" name="manual_entry_q11" 
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q11");}}
                              value={this.state.planEntries.manual_entry_q11}  />
                              {
                              this.state.planEntriesErrors.manual_entry_q11.length > 0 && 
                              <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q23}</div>
                              }
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle4_span12}>
                              4. What are core values?
                              <br />
                              <br />
                              <Input size="large" required type="text" id="manual_entry_q12" placeholder="" name="manual_entry_q12" 
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q12");}}
                              value={this.state.planEntries.manual_entry_q12}  />
                              {
                              this.state.planEntriesErrors.manual_entry_q12.length > 0 && 
                              <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q23}</div>
                              }
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle4_span16}>
                              5. What are aspirational values?
                              <br />
                              <br />
                              <Input size="large" required type="text" id="manual_entry_q13" placeholder="" name="manual_entry_q13" 
                              onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q13");}}
                              value={this.state.planEntries.manual_entry_q13}  />
                              {
                              this.state.planEntriesErrors.manual_entry_q13.length > 0 && 
                              <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q23}</div>
                              }
                              <br />
                              <br />
                            </span>
                          </div>
                        </h3>
                      </div>
              
                      <div className={section2Styles.content_box4}>
                        <div className={section2Styles.flexCol20}>
                          <h1 className={section2Styles.title1_box}>
                            <div className={section2Styles.title1}>
                              <span className={section2Styles.title1_span0}>
                                You have completed the Lesson on <br />
                              </span>
                              <span className={section2Styles.title1_span1}>
                                Building a Strong Team!
                                <br />
                              </span>
                            </div>
                          </h1>
              
                          

                          {
                         this.state.isUploading ?
                          <div className={section2Styles.content_box3}>
                              <div className="inner-pre-loader-container">
                              <div className="inner-pre-loader">
                                  <div className="text-center">  
                                      <div className="spinner-border mt-4" role="status">    
                                      <span className="sr-only">Loading...</span>  
                                      </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                          :
                          <button className={section2Styles.content_box3} onClick={() => {this.submitEntry()}}>
                            <h3 className={section2Styles.subtitle3}>Save and Exit</h3>
                          </button>
                          }

                        </div>
                      </div>
                    </div>
                  </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" onClick={() => {this.handleRedirect()}} data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanTwo;