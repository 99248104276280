import React from 'react';
import cn from 'classnames';

import styles from '../../scss/AccountingAccount.module.scss';
import stylesXs from '../../scss/AccountingAccountXs.module.scss';
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import { reactLocalStorage } from 'reactjs-localstorage';

class BuiltSignIn extends React.Component {

  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
      isLoading: true,
      isUploading: false,
      isUploadingPitchVideo: false,
      isUploadingLogo: false,
      isUploadingProducts: false,
      isRequesting: false,
      isDeleting: false,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: '',
      deleteFileUrl: '',
      deleteFileType: '',
      profileForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: '',
        media: 'AWA'
      },
      profileFormErrors: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      errorMessage: '',
      successMessage: '',
      myfname: '',
      mylname: ''
    }

  }

  getUserProfile () {
    this.setState({
      ...this.state,
      isRequesting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-my-profile-info", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let myfname = responseInfo.data.data.full_name?.split(' ')?.[0];
      let mylname = responseInfo.data.data.full_name?.split(' ')?.[1];

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        myfname: myfname,
        mylname: mylname
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  updateProfileInfo(event, name) {
    var profileForm = this.state.profileForm;
    let value = event.target.value;

    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });

    //alert(JSON.stringify(...this.state.profileForm));

	}

  submitProfileForm () {

		this.setState({
			...this.state,
			isRequesting: true,
		 });

		axios.post("https://web.builtaccounting.com/api/auth/signup", {
      ...this.state.profileForm
    }, AuthService.getBuiltSUHeaders())
		.then((response) => {
      let responseInfo = response.data;

        var userObj = reactLocalStorage.getObject('userObj');
        userObj.built_account = "added";
        reactLocalStorage.setObject('userObj', userObj);

        axios.post(CONSTANTS.API_BASE_URL + "/accounting/add-user-token", {
          "built_Acc_user_token": responseInfo.access_token,
          "built_enc_id": null,
          "built_business_id": null
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo2 = response.data;
    
          this.setState({
            ...this.state,
            isRequesting: false,
            successMessage: "Your Built Account has been created successfully."
          })
    
          window.modalShow('success-modal');
          setTimeout(() => {
            window.location = "/built-business";
          }, 300);
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
            window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offlines";
          }
        });

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.data.status === 401 || error.response.data.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        if (typeof errorResponse.errors.data.email === 'undefined'){
        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });
        }else{
          this.setState({
            ...this.state,
            isRequesting: false,
          errorMessage: errorResponse.errors.email,
        });
        }

        console.log(error.response.data);
        window.modalShow('error-modal');
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
          //window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    window.KTComponents.init();
    AuthService.builtUserStatus();
    this.getUserProfile();
  }


  render(){
      return (
      <div className={cn(styles.root, 'accounting-account')}>
          <div className={styles.flexCol}>
            <img
              className={styles.builtlogo}
              src={require('../../../assets/accounting/built-logo.png')}
              alt="alt text"
            />
            <h1 className={styles.hero_title}>Create a Built Accounting User Account</h1>
    
            <div className={styles.flexCol1}>
              {
                this.state.isLoading ?
                <div className="inner-pre-loader-container">
                  <div className="inner-pre-loader py-20">
                    <div className="text-center">  
                      <div className="spinner-border mt-4" role="status">    
                        <span className="sr-only">Loading...</span>  
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className={styles.flexCol2}>
                  <div className={styles.flexCol2}>
                    <div className={styles.text}>First Name</div>
                    <Input size="large" type="text" id="first_name" 
                    name="first_name" placeholder="Enter your first name" 
                    onChange={(e) => {this.updateProfileInfo(e,"first_name");}}
                     />
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Last Name</div>
                    <Input size="large" type="text" id="last_name" 
                    name="last_name" placeholder="Enter your last name" 
                    onChange={(e) => {this.updateProfileInfo(e,"last_name");}}
                     />
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Phone Number</div>
                    <Input size="large" autoComplete="new-password" required type="tel" id="phone_number" placeholder="Enter your phone number" name="phone_number" onChange={(e) => {this.updateProfileInfo(e,"phone_number");}}  />
                    {
                      this.state.profileFormErrors.phone_number.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.phone_number}</div>
                    }
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Email address</div>
                    <Input size="large" autoComplete={'new-password'} required type="email" id="email" placeholder="Enter your email address" name="email" onChange={(e) => {this.updateProfileInfo(e,"email");}}  />
                    {
                      this.state.profileFormErrors.email.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.email}</div>
                    }
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Password</div>
                    <Input size="large" autoComplete={'new-password'} required type="password" id="password" placeholder="Enter your password" name="password" onChange={(e) => {this.updateProfileInfo(e,"password");}}  />
                    {
                      this.state.profileFormErrors.password.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.password}</div>
                    }
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Confirm Password</div>
                    <Input size="large" autoComplete="new-password" required type="password" id="password_confirmation" placeholder="Confirm your password" name="password_confirmation" onChange={(e) => {this.updateProfileInfo(e,"password_confirmation");}}  />
                    {
                      this.state.profileFormErrors.password_confirmation.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.password_confirmation}</div>
                    }
                  </div>
                </div>
              }
                {
                  this.state.isRequesting ?
                  <button className={styles.content_box}>
                    <h3 className={styles.subtitle}>
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader">
                        <div className="text-center">  
                          <div className="spinner-border" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    </h3>
                  </button>
                  :
                  <button className={styles.content_box} onClick={() => {this.submitProfileForm()}}>
                    <h3 className={styles.subtitle}>
                      Sign Up
                    </h3>
                  </button>
                }

            </div>
    
            <hr className={styles.line} size={1} />
          </div>
          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Account creation was not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
      </div>

      )
  }
}

class BuiltSignInXs extends React.Component {

  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
      isLoading: true,
      isUploading: false,
      isUploadingPitchVideo: false,
      isUploadingLogo: false,
      isUploadingProducts: false,
      isRequesting: false,
      isDeleting: false,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: '',
      deleteFileUrl: '',
      deleteFileType: '',
      profileForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: '',
        media: 'AWA'
      },
      profileFormErrors: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      errorMessage: '',
      successMessage: '',
      myfname: '',
      mylname: ''
    }

  }

  getUserProfile () {
    this.setState({
      ...this.state,
      isRequesting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-my-profile-info", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let myfname = responseInfo.data.data.full_name?.split(' ')?.[0];
      let mylname = responseInfo.data.data.full_name?.split(' ')?.[1];

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        myfname: myfname,
        mylname: mylname
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  updateProfileInfo(event, name) {
    var profileForm = this.state.profileForm;
    let value = event.target.value;

    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });

    //alert(JSON.stringify(...this.state.profileForm));

	}

  submitProfileForm () {

		this.setState({
			...this.state,
			isRequesting: true,
		 });

		axios.post("https://web.builtaccounting.com/api/auth/signup", {
      ...this.state.profileForm
    }, AuthService.getBuiltSUHeaders())
		.then((response) => {
      let responseInfo = response.data;

        var userObj = reactLocalStorage.getObject('userObj');
        userObj.built_account = "added";
        reactLocalStorage.setObject('userObj', userObj);

        axios.post(CONSTANTS.API_BASE_URL + "/accounting/add-user-token", {
          "built_Acc_user_token": responseInfo.access_token,
          "built_enc_id": null,
          "built_business_id": null
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo2 = response.data;
    
          this.setState({
            ...this.state,
            isRequesting: false,
            successMessage: "Your Built Account has been created successfully."
          })
    
          window.modalShow('success-modal');
          setTimeout(() => {
            window.location = "/built-business";
          }, 300);
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
            window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offlines";
          }
        });

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.data.status === 401 || error.response.data.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message; 
        }

        if (typeof errorResponse.errors.data.email === 'undefined'){
        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });
        }else{
          this.setState({
            ...this.state,
            isRequesting: false,
          errorMessage: errorResponse.errors.email,
        });
        }

        console.log(error.response.data);
        window.modalShow('error-modal');
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
          //window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    window.KTComponents.init();
    AuthService.builtUserStatus();
    this.getUserProfile();
  }

  render(){
      return (
        <div className={cn(stylesXs.root, 'accounting-account')}>
          <div className={stylesXs.flexCol}>
            <div
              className={stylesXs.content_box1}
              style={{ '--src': `url(${require('../../../assets/accounting/african-american-business-woman-working-computer_1303-9873.jpg')})` }}>
            <h1 className={stylesXs.title}></h1>
            </div>
    
            <div className={stylesXs.flexCol1}>
            <img
                className={stylesXs.builtlogo}
                src={require('../../../assets/accounting/built-logo.png')}
                alt="alt text"
              />
            <h1 className={stylesXs.title}>Create a Built Accounting User Account</h1>

              <div className={stylesXs.flexCol2}>
                <div className={stylesXs.text}>First Name</div>
                <Input size="large" type="text" id="first_name" 
                name="first_name" placeholder="Enter your first name" 
                value={this.state.myfname}
                onChange={(e) => {this.updateProfileInfo(e,"first_name");}} />
              </div>
    
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Last Name</div>
                <Input size="large" type="text" id="last_name" 
                name="last_name" placeholder="Enter your last name" 
                value={this.state.mylname}
                onChange={(e) => {this.updateProfileInfo(e,"last_name");}} />
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Phone Number</div>
                <Input size="large" required type="tel" id="phone_number" placeholder="Enter your phone number" name="phone_number" onChange={(e) => {this.updateProfileInfo(e,"phone_number");}}  />
                {
                      this.state.profileFormErrors.phone_number.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.phone_number}</div>
                    }
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Email address</div>
                <Input size="large" required type="email" id="email" placeholder="Enter your email address" name="email" onChange={(e) => {this.updateProfileInfo(e,"email");}}  />
                {
                      this.state.profileFormErrors.email.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.email}</div>
                    }
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Password</div>
                <Input size="large" required type="password" id="password" placeholder="Enter your password" name="password" onChange={(e) => {this.updateProfileInfo(e,"password");}}  />
                {
                      this.state.profileFormErrors.password.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.password}</div>
                    }
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Confirm Password</div>
                <Input size="large" required type="password" id="password_confirmation" placeholder="Confirm your password" name="password_confirmation" onChange={(e) => {this.updateProfileInfo(e,"password_confirmation");}}  />
                {
                      this.state.profileFormErrors.password_confirmation.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.password_confirmation}</div>
                    }
              </div>
    
              {
                this.state.isRequesting ?
                <button className={stylesXs.content_box}>
                <h3 className={stylesXs.subtitle}>
                <div className="inner-pre-loader-container">
                  <div className="inner-pre-loader">
                    <div className="text-center">  
                      <div className="spinner-border" role="status">    
                        <span className="sr-only">Loading...</span>  
                      </div>
                    </div>
                  </div>
                </div>
                </h3>
                </button>
                :
                <button className={stylesXs.content_box} onClick={() => {this.submitProfileForm()}}>
                  <h3 className={stylesXs.subtitle}>Sign up</h3>
                </button>
              }

            </div>
          </div>
{/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Account creation was not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
        </div>
      )
  }
}

export {BuiltSignIn, BuiltSignInXs};
