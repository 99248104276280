import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import {
  authentication,
  googleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword
} from '../../firebase';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Space, 
  Input,
} from 'antd';
import 'antd/dist/antd.css';

class Login extends React.Component {
  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: true,
			isRequesting: false,
			isRequestingGoogleLogin: false,
			loginForm: {
				email: '',
      	password: '',
        token: ''
			},
      errorMessage: ''
    }
	}
	
	loginForm(event, name) {
    let value = event.target.value;
    var loginForm = this.state.loginForm;
    loginForm[name] = value;
    this.setState({
			...this.state,
      loginForm: loginForm,
    });
	}

  firebaseSignInWithEmailAndPassword = e => {
    this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
		 });
		e.preventDefault();

    signInWithEmailAndPassword(authentication, this.state.loginForm.email, this.state.loginForm.password)
    .then((userCredential) => {
      let token = userCredential.user.accessToken;
      this.submitLoginForm(token);
    })
    .catch((error) => {

      this.setState({
        ...this.state,
        errorMessage: "Invalid login credentials",
        isRequesting: false
      })
    })
  }
	
	submitLoginForm = (token) => {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/auth/login", {
      email: this.state.loginForm.email,
      password: this.state.loginForm.password,
      token: token
    })
		.then((response) => {
      let data = response.data.data;
      console.log(data);
      let expirationDate = new Date();
      let validDays = 30;
      expirationDate.setTime(expirationDate.getTime() + (validDays*24*60*60*1000));

      cookie.save('access_token', data.access_token, { path: '/', expires: expirationDate })
      reactLocalStorage.setObject('userObj', data.user_obj);
      if(!data.user_obj.onboardingCompleted){
        window.location = "/onboarding";
      }else{
        window.location = "/home";
      }
      

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  signInWithGoogle = () => {
    this.setState({
			...this.state,
			isRequestingGoogleLogin: true,
      errorMessage: ''
		})

    signInWithPopup(authentication, googleAuthProvider)
    .then((response) => {
      console.log(response);
      axios.post(CONSTANTS.API_BASE_URL + "/auth/google-login", {
        token: response.user.accessToken
      })
      .then((response) => {
        let data = response.data.data;
        console.log(data);

        let expirationDate = new Date();
        let validDays = 30;
        expirationDate.setTime(expirationDate.getTime() + (validDays*24*60*60*1000));

        cookie.save('access_token', data.access_token, { path: '/', expires: expirationDate })
        reactLocalStorage.setObject('userObj', data.user_obj);
        if(!data.user_obj.onboardingCompleted){
          window.location = "/onboarding";
        }else{
          window.location = "/home";
        }
  
      }).catch((error) => {
        console.log(error)
        try{
          let errorResponse = error.response.data;
  
          let errorMessage = 'Error: Could not connect to server';
          if(errorResponse.hasOwnProperty("message")){
            errorMessage = errorResponse.message;
          }
  
          this.setState({
            ...this.state,
            isRequestingGoogleLogin: false,
            errorMessage: errorMessage,
          });
        }catch(e){
          console.log(e)
          // window.location = "/server-offline";
        }
      });
    })
    .catch((error) => {
      console.log(error);
      if(error.code !== 'auth/popup-closed-by-user'){
        this.setState({
          ...this.state,
          isRequestingGoogleLogin: false,
          errorMessage: error.code,
        });
      }else{
        this.setState({
          ...this.state,
          isRequestingGoogleLogin: false
        });
      }
    })
  }

  componentDidMount (){
    document.body.style.backgroundColor = '#114aa0';
    setTimeout(() => {
      this.setState({
        ...this.state,
        isLoading: false
      })
    }, 1000);
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Login - AWA</title>
          </MetaTags>
          <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
            {
              this.state.isLoading?
              <div className="pre-loader-container">
                <div className="pre-loader">
                  <div className="text-center">  
                    <div className="mb-5"><img alt="Logo" src="/assets/media/logos/logo.png" className="h-70px h-lg-75px" /></div>
                    <div className="spinner-border mt-4" role="status">    
                      <span className="sr-only">Loading...</span>  
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-lg-row-fluid">
                  <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                    
                    <img
                      className="mx-auto mw-100 w-250px w-lg-400px mb-10 mb-lg-20"
                      src="/assets/media/illustrations/awa/awa12.svg"
                      alt=""
                    />
                    <h1 className="text-white fs-2qx fw-bold text-center mb-7">
                      African Women Amplified
                    </h1>
                    <div className="text-white fs-base text-center fw-semibold">
                      AWA is a digital platform that provides women entrepreneurs in West Africa <br/> with access to resources to grow their business and impact.
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-lg-12 p-8">
                  <div className="bg-body d-flex flex-center rounded-4 w-md-600px p-10">
                    <div className="w-md-400px">
                      <form className="form w-100" onSubmit={this.firebaseSignInWithEmailAndPassword} method="POST">
                        <div className="text-center mb-11">
                          <a href="#" className="mb-0 mb-lg-12">
                            <img
                              alt="Logo"
                              src="/assets/media/logos/logo.png"
                              className="h-50px h-lg-55px"
                            />
                          </a>
                        </div>
                        <div className="text-center mb-11">
                          <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                          <div className="text-gray-500 fw-semibold fs-6">
                            Access your account
                          </div>
                        </div>
                        {
                          this.state.errorMessage.length > 0 &&
                          <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                            <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                  <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                  <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                </g>
                              </svg>
                            </span>
                            <div class="d-flex flex-column">
                              <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                          </div>
                        }
                        <div className="row g-3 mb-9">
                          <div className="col-md-12">
                            {
                              this.state.isRequestingGoogleLogin ?
                              <button type="button" disabled className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                <div className="spinner-border" role="status" style={{margin: "-6px"}}> </div> 
                              </button>
                              :
                              <button type="button" onClick={this.signInWithGoogle} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                <img
                                  alt="Logo"
                                  src="/assets/media/svg/brand-logos/google-icon.svg"
                                  className="h-15px me-3"
                                />
                                Sign in with Google
                              </button>
                            }
                            
                          </div>
                        </div>
                        <div className="separator separator-content my-14">
                          <span className="w-125px text-gray-500 fw-semibold fs-7">
                            Or with email
                          </span>
                        </div>
                        <div className="fv-row mb-8">
                          <label className="form-label mb-3">Email</label>   
                          <Input size="large" required type="email" id="email" className="form-control form-control-lg" placeholder="Enter your email address" name="email" value={this.state.loginForm.email} onChange={(e) => {this.loginForm(e,"email");}} />
                        </div>
                        <div className="fv-row mb-3">
                          <label className="form-label mb-3">Password</label>   
                          <Input.Password size="large" required type="password" className="form-control form-control-lg" id="password" name="password" value={this.state.loginForm.password} onChange={(e) => {this.loginForm(e,"password");}} placeholder="Enter your password" />
                        </div>
                        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                          <div />
                          <a href="/forgot-password" className="link-primary">
                            Forgot Password ?
                          </a>
                        </div>
                        <div className="d-grid mb-10">
                          { 
                            !this.state.isRequesting ? 
                            <button type="submit" disabled={this.state.isRequestingGoogleLogin} className="btn btn-lg btn-primary">
                              <span>Login</span> 
                            </button>
                            :
                            <button type="button" disabled className="btn btn-lg btn-primary btn-block">
                              <div className="spinner-border" role="status" style={{margin: "-6px"}}> </div> 
                            </button>
                          }
                        </div>
                        <div className="text-gray-500 text-center fw-semibold fs-6">
                          Not a Member yet?
                          <a href="/register" className="link-primary mx-1">
                            Join our community
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

      </Fragment>
    )
  }
}

export default Login;