import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/Courseplan_section1.module.scss';
import section2Styles from '../scss/Courseplan_section2.module.scss';
import section3Styles from '../scss/Courseplan_section3.module.scss';
import section1StylesXs from '../scss/Courseplan_section1-xs.module.scss';
import section2StylesXs from '../scss/Courseplan_section2-xs.module.scss';
import section3StylesXs from '../scss/Courseplan_section3-xs.module.scss';
import section4Styles from '../scss/Courseplan_section4.module.scss';
import section5Styles from '../scss/Courseplan_section5.module.scss';
import section6Styles from '../scss/Courseplan_section6.module.scss';
import section7Styles from '../scss/Courseplan_section7.module.scss';
import styles from '../scss/Courseplan.module.scss';
import stylesXS from '../scss/Courseplan-xs.module.scss';
import {FileModal, FileModalXs} from "../misc/awaModal";

class LearnWithAWA extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.myAwaRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0'
    }
	}

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  audioPlay = () => {
    this.myAwaRef.current.play()
  }

  audioPause = () => {
    this.myAwaRef.current.pause()
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  singleSaveGeneral(lessonPlan){
  
      axios.post(CONSTANTS.API_BASE_URL + "/early-stage/single-save", {
        "lesson_plan": lessonPlan,
        "field_name": [
            "manual_entry_q1"
        ],
        "input": [
            true
        ]
      }, AuthService.getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        this.handleLessonProgress(50);
        
        this.setState({
          ...this.state,
          successMessage: "Your progress has been has been saved successfully."
        })
  
      }).catch((error) => {
        try{
          let errorResponse = error.response.data;
  
          if(errorResponse.status === 401 || errorResponse.status === 404){
            alert('ha1');
            window.location = "/not-found";
            return;
          }
  
          let errorMessage = 'Error: Could not connect to server';
          if(errorResponse.hasOwnProperty("message")){
            errorMessage = errorResponse.message;
          }
  
          this.setState({
            ...this.state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
  
        window.modalShow('error-modal');
        window.scrollTo({top: 0, behavior: 'smooth'});
        }catch(e){
        window.location = "/server-offlines";
        }
      });
    }

  builtBusinessConfirm = () => {
    axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", {
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      // ---
      if(response.data.data.built_business_id !== null){
        this.singleSaveGeneral('LP5')
      }
    }).catch((error) => {
      try{
        
      }catch(e){
        
      }
    });
  } 

  componentDidMount () {
    window.KTComponents.init();
    this.getLessonProgress();
    if(this.state.lessonProgress >= 4){
      this.builtBusinessConfirm();
    }
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Course Plan</title>
          </MetaTags>
           <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                    /*<div className={section1StylesXs.flexRow}>

                            <div className={section1StylesXs.flexRow1}>
                              <div className={section1StylesXs.flexRow1__item}>
                                <img
                                  className={section1StylesXs.icon1}
                                  src={require('../../assets/courseplan/5f32923ae15027b5fba49b99d15136db.png')}
                                  alt="alt text"
                                />
                              </div>
                              <p className={section1StylesXs.desc}>Sound On</p>
                            </div>

                            <div className={section1StylesXs.flexRow__item}>
                              <div className={section1StylesXs.flexRow2}>
                                <div className={section1StylesXs.flexRow2__item}>
                                  <img
                                    className={section1StylesXs.image4}
                                    src={require('../../assets/courseplan/2ed00b85771cba3869b640779d42e609.png')}
                                    alt="alt text"
                                  />
                                </div>
                                <p className={section1StylesXs.desc1}>Sound Off</p>
                              </div>
                            </div>

                          </div>*/
                    //mobile below
                    <section className={section1StylesXs.section1}>
                      <div className={section1StylesXs.group}>
                        <div className={section1StylesXs.rect} />

                        <div className={section1StylesXs.flexCol}>
                          

                          <div className={section1StylesXs.flexCol1}>
                            <h1 className={section1StylesXs.title_box}>
                              <div className={section1StylesXs.title}>
                                <span className={section1StylesXs.title_span0}>Early Stage </span>
                                <span className={section1StylesXs.title_span1}>Learning Plan</span>
                              </div>
                            </h1>

                            <div className={section1StylesXs.flexCol2}>
                              <div className={section1StylesXs.flexCol2__item}>
                                <img
                                  className={section1StylesXs.image1}
                                  src={require('../../assets/courseplan/5209a47e41e829203c3d1621a0a7ad2b.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section1StylesXs.text}>Lessons completed</div>
                              <div className={section1StylesXs.flexCol2__item}>
                              <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                    <div
                                      className="progress-bar bg-warning"
                                      role="progressbar"
                                      style={{ width: `${this.state.lessonProgress}%`}}
                                      aria-valuenow={this.state.lessonProgress}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                              </div>
                              <div className={section1StylesXs.text1}>{this.state.lessonProgress / 10} / 10 Lessons</div>
                            </div>
                          </div>

                          <h1 className={section1StylesXs.title1_box}>
                            <div className={section1StylesXs.title1}>
                              <span className={section1StylesXs.title1_span0}>
                                Welcome!
                                <br />
                              </span>
                              <span className={section1StylesXs.title1_span1}>
                                <br />
                              </span>
                              <span className={section1StylesXs.title1_span2}>
                                As an early stage entrepreneur, sometimes it’s easy to start building your business and making sales.
                                However, without the proper structures in place, even after two years of relative success, you can find
                                that your business is stagnant and unable to effectively scale. Most markets are highly competitive and
                                require you to understand the ins and outs of your business to effectively compete locally and
                                globally. This learning path allows you to think through the necessary internal infrastructures of your
                                business. Such as honing your value proposition, understanding your target customer market, developing
                                your business’ operations and making sure you’re compliant to your country’s business regulations.
                                <br />
                              </span>
                              <span className={section1StylesXs.title1_span3}>
                                These courses are self-paced and take an average of five to fifteen minutes to complete. We wish you
                                all the best!{' '}
                              </span>
                            </div>
                          </h1>
                        </div>
                      </div>
                    </section>
                  }
                  {
                    <section className={section2StylesXs.section2}>
                      <div className={section2StylesXs.flexCol}>
                        <div className={section2StylesXs.flexCol1}>
                          <h2 className={section2StylesXs.medium_title}>Is your Business Registered?</h2>
                          {this.state.lessonProgress < 10 ? 
                          <div className={section2StylesXs.flexRow}>
                          <button onClick={() => {this.handlePickXs()}} className={section2StylesXs.content_box}>
                            <h4 className={section2StylesXs.highlights}>
                              Yes
                            </h4>
                          </button>

                          <div className={section2StylesXs.flexRow__spacer} />

                          <button data-bs-toggle="modal" data-bs-target="#register-modal" className={section2StylesXs.content_box1}>
                              <h4 className={section2StylesXs.highlights1}>
                                No
                              </h4>
                          </button>
                          </div>
                          :
                          <div className={section2StylesXs.flexRow}>
                            <button data-bs-toggle="modal" data-bs-target="#registered-modal" className={section2StylesXs.content_box}>
                              <h4 className={section2StylesXs.highlights}>
                                Yes
                              </h4>
                            </button>

                            <div className={section2StylesXs.flexRow__spacer} />

                            <button data-bs-toggle="modal" data-bs-target="#registered-modal" className={section2StylesXs.content_box1}>
                                <h4 className={section2StylesXs.highlights1}>
                                  No
                                </h4>
                            </button>
                          </div>
                          }

                        </div>

                        <div className={section2StylesXs.group}>
                          <div className={section2StylesXs.background} />

                          <div className={section2StylesXs.flexCol2}>
                          {this.state.lessonProgress < 10 ?
                            <div className={section2StylesXs.flexCol2__item}>
                              <img
                                className={section2StylesXs.icon2}
                                src={require('../../assets/courseplan/Group151.png')}
                                alt="alt text"
                              />
                            </div>
                            :
                            <div className={section2StylesXs.flexCol2__item}>
                              <img
                                className={section2StylesXs.icon2}
                                src={require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')}
                                alt="alt text"
                              />
                            </div>
                            }

                            <div className={section2StylesXs.flexCol3}>
                              <h2 className={section2StylesXs.medium_title1}>If not, register your Business</h2>

                              <div className={section2StylesXs.flexRow1}>
                                <div className={section2StylesXs.flexCol4}>
                                  <div className={section2StylesXs.flexCol4__item}><a href="https://rgd.gov.gh" target="_blank">
                                    <img
                                      className={section2StylesXs.icon}
                                      src={require('../../assets/courseplan/0a97c1703da574ad991d749459b56c44.png')}
                                      alt="alt text"
                                    /></a>
                                  </div>
                                  <div className={section2StylesXs.text2}>Ghana</div>
                                </div>

                                <div className={section2StylesXs.flexRow1__spacer} />

                                <div className={section2StylesXs.flexCol5}><a href="https://www.cac.gov.ng" target="_blank">
                                  <img
                                      className={section2StylesXs.icon}
                                      src={require('../../assets/courseplan/35c93502b2fc79498a1fee4428c3a8af.png')}
                                      alt="alt text"
                                    /></a>
                                  <div className={section2StylesXs.text2}>Nigeria</div>
                                </div>

                                <div className={section2StylesXs.flexRow1__spacer1} />

                                <div className={section2StylesXs.flexCol6}><a href="https://investinsenegal.com" target="_blank">
                                  <img
                                      className={section2StylesXs.icon}
                                      src={require('../../assets/courseplan/1d5d44247033864a6b0e14a50d17cab2.png')}
                                      alt="alt text"
                                    /></a>
                                  <div className={section2StylesXs.text2}>Senegal</div>
                                </div>

                                <div className={section2StylesXs.flexRow1__spacer2} />

                                <div className={section2StylesXs.flexCol7}><a href="https://www.cci.bf/?q=en/content/annuaires-dentreprises" target="_blank">
                                  <img
                                      className={section2StylesXs.icon}
                                      src={require('../../assets/courseplan/c24c5701ee1e15e53e1cda81645887aa.png')}
                                      alt="alt text"
                                    /></a>
                                  <div className={section2StylesXs.text2}>Burkina Faso</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  }
                  {
                    <section className={section3StylesXs.section3}>
                      <div className={section3StylesXs.flexCol}>
                        <div className={section3StylesXs.flexCol1}>
                          <h2 className={section3StylesXs.medium_title}>
                            Are you telling your relevant stakeholder(s) enough about your business?
                          </h2>

                          <div className={section3StylesXs.group}>
                            <div className={section3StylesXs.content_box11}>
                              <div className={section3StylesXs.flexCol2}>
                             
                                  <a className={section3StylesXs.flexCol2__item} href="./lesson-two">
                                  <img
                                    className={section3StylesXs.image5}
                                    src={require('../../assets/courseplan/deeef3df2661b6c621cc475b431a21d2.png')}
                                    alt="alt text"
                                  />
                                </a>
                                

                                <div className={section3StylesXs.flexCol3}>
                                  <h3 className={section3StylesXs.subtitle_box}>
                                    <div className={section3StylesXs.subtitle}>
                                      <span className={section3StylesXs.subtitle_span0}>Use our lesson plan to create an Elevator Pitch</span>
                                      <span className={section3StylesXs.subtitle_span1}>and upload it.</span>
                                    </div>
                                  </h3>
                                  <h5 className={section3StylesXs.highlights2}>
                                    A persuasive 30 seconds or less video or audio that tells people
                                    <br />
                                    about your business. (Video is preferable as it helps people relate to you better)
                                  </h5>
                                </div>
                              </div>
                            </div>

                            {this.state.lessonProgress < 20 ? 

                            <div
                              className={section3StylesXs.content_box2}
                              style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                              <h1 className={section3StylesXs.title2}>2</h1>
                            </div>
                            :
                            <div
                              className={section3StylesXs.content_box2}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }
                          </div>
                        </div>

                        <div className={section3StylesXs.group1}>
                          <div className={section3StylesXs.content_box12}>
                            <div className={section3StylesXs.flexCol4}>

                            {this.state.lessonProgress < 20 ? 

                              <a className={section3StylesXs.flexCol4__item} data-bs-toggle="modal" data-bs-target="#progress-modal">
                                  <img
                                    className={section3StylesXs.image5}
                                    src={require('../../assets/courseplan/6f69432d2628cdf30fb93169360e02bf.png')}
                                    alt="alt text"
                                  />
                              </a>
                              :
                              <a className={section3StylesXs.flexCol4__item} href="./lesson-three">
                                  <img
                                    className={section3StylesXs.image5}
                                    src={require('../../assets/courseplan/6f69432d2628cdf30fb93169360e02bf.png')}
                                    alt="alt text"
                                  />
                              </a>
                            }

                              <div className={section3StylesXs.flexCol5}>
                                <h3 className={section3StylesXs.subtitle1_box}>
                                  <div className={section3StylesXs.subtitle1}>
                                    <span className={section3StylesXs.subtitle1_span0}>Use our Pitch deck lesson plan</span>
                                    <span className={section3StylesXs.subtitle1_span1}>
                                      {' '}
                                      to create an impressive Pitch Deck for your business.
                                    </span>
                                  </div>
                                </h3>
                                <h5 className={section3StylesXs.highlights21}>
                                  Pitch Deck - a visual presentation that business owners use to showcase their
                                  <br />
                                  startups to relevant stakeholders.
                                </h5>
                              </div>
                            </div>
                          </div>

                          {this.state.lessonProgress < 30 ? 

                          <div
                            className={section3StylesXs.content_box21}
                            style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                            <h1 className={section3StylesXs.title21}>3</h1>
                          </div>
                            :
                            <div
                              className={section3StylesXs.content_box21}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>  
                            }

                          

                        </div>

                        <div className={section3StylesXs.flexCol6}>
                          <h2 className={section3StylesXs.medium_title1}>Have you created an effective brand?</h2>

                          <div className={section3StylesXs.group2}>
                            <div className={section3StylesXs.content_box13}>
                              <div className={section3StylesXs.flexCol7}>



                              {this.state.lessonProgress < 30 ? 

                                <a className={section3StylesXs.flexCol7__item} data-bs-toggle="modal" data-bs-target="#progress-modal">
                                  <img
                                    className={section3StylesXs.image7}
                                    src={require('../../assets/courseplan/2f7cf37d8c0722436b9dab950203ef6e.png')}
                                    alt="alt text"
                                  />
                                </a>
                              :
                              <a className={section3StylesXs.flexCol7__item} href="./lesson-four">
                              <img
                                className={section3StylesXs.image7}
                                src={require('../../assets/courseplan/2f7cf37d8c0722436b9dab950203ef6e.png')}
                                alt="alt text"
                              />
                            </a>
                              }
                                <div className={section3StylesXs.flexCol8}>
                                  <h3 className={section3StylesXs.subtitle2_box}>
                                    <div className={section3StylesXs.subtitle2}>
                                      <span className={section3StylesXs.subtitle2_span0}>Use our Marketing and Branding Lesson plan</span>
                                      <span className={section3StylesXs.subtitle2_span1}>
                                        {' '}
                                        to help you figure out how to create an effective brand and market it.  <br />
                                      </span>
                                    </div>
                                  </h3>
                                  <h5 className={section3StylesXs.highlights21}>
                                    If you already have your branding materials, please upload them, and use the lesson plan to review
                                    it and edit where necessary.
                                    <br />
                                  </h5>
                                </div>
                              </div>
                            </div>

                            
                            {this.state.lessonProgress < 40 ? 
                            <div
                            className={section3StylesXs.content_box22}
                            style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                            <h1 className={section3StylesXs.title22}>4</h1>
                            </div>
                            :
                            <div
                            className={section3StylesXs.content_box22}
                            style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }
                          </div>
                        </div>

                        <div className={section3StylesXs.flexCol3}>
                          <h2 className={section3StylesXs.medium_title2}>Are you keeping track of your finances?</h2>

                          <div className={section3StylesXs.group3}>
                            <div className={section3StylesXs.content_box14}>
                              <div className={section3StylesXs.flexCol9}>
                              {this.state.lessonProgress < 40 ? 

                                <a data-bs-toggle="modal" data-bs-target="#progress-modal" className={section3StylesXs.flexCol9__item}>
                                    <img
                                      className={section3StylesXs.image7}
                                      src={require('../../assets/courseplan/b1b210da718b78b4fe4f19a9b448b649.png')}
                                      alt="alt text"
                                    />
                                </a>

                                :
                                <a data-bs-toggle="modal" data-bs-target="#finance-modal" className={section3StylesXs.flexCol9__item}>
                                    <img
                                      className={section3StylesXs.image7}
                                      src={require('../../assets/courseplan/b1b210da718b78b4fe4f19a9b448b649.png')}
                                      alt="alt text"
                                    />
                                </a>
                                }
                                <h3 className={section3StylesXs.subtitle3_box}>
                                  <div className={section3StylesXs.subtitle3}>
                                    <span className={section3StylesXs.subtitle3_span0}>
                                      Take the introduction to Finances/Bookkeeping Course
                                    </span>
                                    <span className={section3StylesXs.subtitle3_span1}>
                                      {' '}
                                      to understand what basic financials you need for your early-stage business.  <br />
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                           
                            {this.state.lessonProgress < 50 ? 
                             <div
                             className={section3StylesXs.content_box23}
                             style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                             <h1 className={section3StylesXs.title23}>5</h1>
                           </div>
                            :
                            <div
                            className={section3StylesXs.content_box23}
                            style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                          </div>
                            }

                          </div>
                        </div>

                        <div className={section3StylesXs.flexCol10}>
                          <h2 className={section3StylesXs.medium_title3}>Have you developed business policies?</h2>

                          <div className={section3StylesXs.group4}>
                            <div className={section3StylesXs.content_box15}>
                              <div className={section3StylesXs.flexCol11}>
                                <div className={section3StylesXs.flexCol11__item}>
                                  <img
                                    className={section3StylesXs.image8}
                                    src={require('../../assets/courseplan/a3860b96d8592a2800e8c7bf50a698ed.png')}
                                    alt="alt text"
                                  />
                                </div>
                                <h3 className={section3StylesXs.subtitle4_box}>
                                  <div className={section3StylesXs.subtitle4}>
                                    <span className={section3StylesXs.subtitle4_span0}>
                                      Use our Business Regulations and Policies Lesson plan
                                    </span>
                                    <span className={section3StylesXs.subtitle4_span1}>
                                      {' '}
                                      to help you think through the regulations you need to think of and policies you will need to
                                      create for your business.
                                      <br />
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                            <div
                              className={section3StylesXs.content_box24}
                              style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                              <h1 className={section3StylesXs.title24}>6</h1>
                            </div>
                          </div>
                        </div>

                        <div className={section3StylesXs.group5}>
                          <div className={section3StylesXs.content_box16}>
                            <div className={section3StylesXs.flexCol12}>
                              <div className={section3StylesXs.flexCol12__item}>
                                <img
                                  className={section3StylesXs.image9}
                                  src={require('../../assets/courseplan/b877f694801200a5428fe25036000430.png')}
                                  alt="alt text"
                                />
                              </div>
                              <h3 className={section3StylesXs.subtitle5_box}>
                                <div className={section3StylesXs.subtitle5}>
                                  <span className={section3StylesXs.subtitle5_span0}>Use our Business Operations Lesson plan</span>
                                  <span className={section3StylesXs.subtitle5_span1}>
                                    {' '}
                                    to help you think through the things successful businesses do to keep running and making money
                                    <br />
                                  </span>
                                </div>
                              </h3>
                            </div>
                          </div>

                          <div
                            className={section3StylesXs.content_box25}
                            style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                            <h1 className={section3StylesXs.title25}>7</h1>
                          </div>
                        </div>

                        <div className={section3StylesXs.flexCol13}>
                          <h2 className={section3StylesXs.medium_title4}>Do you have a sales strategy?</h2>

                          <div className={section3StylesXs.group6}>
                            <div className={section3StylesXs.content_box17}>
                              <img
                                className={section3StylesXs.image10}
                                src={require('../../assets/courseplan/22ba66f585ad2a2c9659d0e1452c70f3.png')}
                                alt="alt text"
                              />
                            </div>

                            <div
                              className={section3StylesXs.content_box26}
                              style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                              <h1 className={section3StylesXs.title21}>8</h1>
                            </div>
                          </div>
                        </div>

                        <div className={section3StylesXs.flexCol14}>
                          <h2 className={section3StylesXs.medium_title5}>Are you ready to hire your first employee?</h2>

                          <div className={section3StylesXs.group7}>
                            <div className={section3StylesXs.content_box18}>
                              <div className={section3StylesXs.flexCol15}>
                                <div className={section3StylesXs.flexCol15__item}>
                                  <img
                                    className={section3StylesXs.image11}
                                    src={require('../../assets/courseplan/afb41fd2effe8a955151ccbdab5e6c47.png')}
                                    alt="alt text"
                                  />
                                </div>
                                <h3 className={section3StylesXs.subtitle6_box}>
                                  <div className={section3StylesXs.subtitle6}>
                                    <span className={section3StylesXs.subtitle6_span0}>Use our How to Hire Lesson </span>
                                    <span className={section3StylesXs.subtitle6_span1}>
                                      plan to help you figure out how to hire Superstars to help you grow your business.
                                    </span>
                                    <span className={section3StylesXs.subtitle6_span2}> </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                            <div
                              className={section3StylesXs.content_box27}
                              style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                              <h1 className={section3StylesXs.title26}>9</h1>
                            </div>
                          </div>
                        </div>

                        <div className={section3StylesXs.flexCol16}>
                          <h2 className={section3StylesXs.medium_title6}>Do you need a Board? What type of Board do you Need?</h2>

                          <div className={section3StylesXs.group8}>
                            <div className={section3StylesXs.content_box19}>
                              <h3 className={section3StylesXs.subtitle7_box}>
                                <div className={section3StylesXs.subtitle7}>
                                  <span className={section3StylesXs.subtitle7_span0}>Use our Board Structures lesson plan</span>
                                  <span className={section3StylesXs.subtitle7_span1}>
                                    {' '}
                                    to identify IF you need a Board, the TYPE of
                                    <br />
                                    Board that would work for you and the type of people you will need to help you grow
                                    <br />
                                    your business.
                                    <br />
                                    Board Structures – Governance and Structure (what do you have in place to grow
                                    <br />
                                    your business) Do you have a Board? What type of Board is it? Learn what makes a
                                    <br />
                                    strong board, the traits, and attributes for people to join your board). Learn how to
                                    <br />
                                    invite people to your Board.
                                  </span>
                                </div>
                              </h3>
                            </div>

                            <div
                              className={section3StylesXs.content_box10}
                              style={{ '--src': `url(${require('../../assets/courseplan/2cb79a6ccc66ba8ec73d9814c1e4b72c.png')})` }}>
                              <h1 className={section3StylesXs.title27}>10</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  }
                  {
                    //web below//
                    <section className={section1Styles.section1}>
                        <div className={section1Styles.content_box}>
                        <div className={section1Styles.flexCol}>
                            <div className={section1Styles.flexRow}>
                            <div className={section1Styles.flexRow__item}>
                                
                            </div>
                            <div className={section1Styles.flexRow__spacer} />

                            <audio id="esaudio" ref={this.myAwaRef} hidden>
                              <source src="https://firebasestorage.googleapis.com/v0/b/africanwomenamplified.appspot.com/o/resources%2Fearly_stage_recording.mp3?alt=media&token=38d722ed-c8d1-4ebb-a340-ab1c18184347" type="audio/mpeg" />
                            </audio>

                            <div className={section1Styles.flexRow1}>
                                <button className={section1Styles.flexRow1__item} onClick={this.audioPlay}>
                                <img
                                    className={section1Styles.image8}
                                    src={require('../../assets/courseplan/36010425d26b53a9bee4fa840aa17f93.png')}
                                    alt="alt text"
                                />
                                </button>
                                <h5 className={section1Styles.highlights1}>Sound On</h5>
                                <div className={section1Styles.flexRow1__spacer} />
                                <button className={section1Styles.flexRow1__item1} onClick={this.audioPause}>
                                <img
                                    className={section1Styles.icon7}
                                    src={require('../../assets/courseplan/e9d749ff25383bb8de7f20c6c57ec1ae.png')}
                                    alt="alt text"
                                />
                                </button>
                                <div className={section1Styles.flexRow1__spacer1} />
                                <h5 className={section1Styles.highlights1}>Sound Off</h5>
                            </div>
                            </div>

                            <div className={section1Styles.flexRow2}>
                            <div className={section1Styles.flexCol1}>
                                <h1 className={section1Styles.hero_title_box}>
                                <div className={section1Styles.hero_title}>
                                    <span className={section1Styles.hero_title_span0}>Early Stage </span>
                                    <span className={section1Styles.hero_title_span1}>Learning Plan</span>
                                </div>
                                </h1>

                                <div className={section1Styles.flexCol2}>
                                <div className={section1Styles.flexCol2__item}>
                                    <img
                                    className={section1Styles.image}
                                    src={require('../../assets/courseplan/8f404e01cae0e8bd6a760d71961c9578.png')}
                                    alt="alt text"
                                    />
                                </div>

                                <div className={section1Styles.flexCol3}>
                                    <div className={section1Styles.text}>Lessons completed</div>
                                    <div className={section1Styles.flexCol3__item}>
                                   
                                      <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                            <div
                                              className="progress-bar bg-warning"
                                              role="progressbar"
                                              style={{ width: `${this.state.lessonProgress}%`}}
                                              aria-valuenow={this.state.lessonProgress}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                      </div>
                                    <div className={section1Styles.text1}>{this.state.lessonProgress / 10} / 10 Lessons</div>
                                </div>
                                </div>
                            </div>

                            <div className={section1Styles.flexCol1_a}> 
                            <h1 className={section1Styles.hero_title2_box}>
                                <div className={section1Styles.hero_title2}>
                                <span className={section1Styles.hero_title2_span0}>
                                    Welcome!
                                    <br />
                                </span>
                                <span className={section1Styles.hero_title2_span1}>
                                    <br />
                                    As an early stage entrepreneur, sometimes it's easy to start building your business and making sales.
                                    However, without the proper structures in place, even after two years of relative success, you can
                                    find that your business is stagnant and unable to effectively scale. Most markets are highly
                                    competitive and require you to understand the ins and outs of your business to effectively compete
                                    locally and globally.<br /> This learning path allows you to think through the necessary internal
                                    infrastructures of your business. Such as honing your value proposition, understanding your target
                                    customer market, developing your business' operations and making sure you're compliant to your
                                    country's business regulations.<br />
                                </span>
                                <span className={section1Styles.hero_title2_span2}></span>
                                <span className={section1Styles.hero_title2_span3}>
                                    These courses are self-paced and take an average of five to fifteen minutes to complete. We wish you
                                    all the best!{' '}
                                </span>
                                </div>
                            </h1>
                            </div>
                        </div>
                            </div>
                        </div>
                    </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                      <div className={section2Styles.group}>
                        <div className={section2Styles.flexCol}>
                          <div className={section2Styles.flexRow}>
                            <h1 className={section2Styles.hero_title1}>Is your Business Registered?</h1>
                            <div className={section2Styles.flexRow__spacer} />

                            {this.state.lessonProgress < 10 ? 

                            <div className={section2Styles.wrapper2}>
                            <button onClick={() => {this.handlePick()}} className={section2Styles.content_box3}>
                              <h4 className={section2Styles.highlights2}>
                                Yes
                              </h4>
                            </button>

                            <button data-bs-toggle="modal" data-bs-target="#register-modal" className={section2Styles.rect6} >
                              <h4  className={section2Styles.highlights3}>
                                No
                              </h4>
                              </button>
                            </div>

                            :
                            <div className={section2Styles.wrapper2}>
                              <button data-bs-toggle="modal" data-bs-target="#registered-modal" className={section2Styles.content_box3}>
                                <h4 className={section2Styles.highlights2}>
                                  Yes
                                </h4>
                              </button>

                              <button data-bs-toggle="modal" data-bs-target="#registered-modal" className={section2Styles.rect6} >
                                <h4  className={section2Styles.highlights3}>
                                  No
                                </h4>
                                </button>
                            </div>
                            }


                          </div>

                          <div className={section2Styles.content_box2}>
                            <div className={section2Styles.flexCol1}>
                              <h2 className={section2Styles.medium_title}>If not, select a country to register your Business</h2>

                              <div className={section2Styles.flexRow1}>
                                <div className={section2Styles.flexCol2}>
                                  <div className={section2Styles.flexCol2__item}><a href="https://rgd.gov.gh" target="_blank">
                                    <img
                                      className={section2Styles.icon1}
                                      src={require('../../assets/courseplan/d32a9bd546be59a3e9119b5c5aa99248.png')}
                                      alt="alt text"
                                    /></a>
                                  </div>
                                  <h5 className={section2Styles.highlights}>Ghana</h5>
                                </div>

                                <div className={section2Styles.flexRow1__spacer} />

                                <div className={section2Styles.flexCol2}>
                                  <div className={section2Styles.flexCol2__item}><a href="https://www.cac.gov.ng" target="_blank">
                                    <img
                                      className={section2Styles.icon1}
                                      src={require('../../assets/courseplan/a19529b45f816c4a9ebdfcedbaff601c.png')}
                                      alt="alt text"
                                    /></a>
                                  </div>
                                  <h5 className={section2Styles.highlights}>Nigeria</h5>
                                </div>

                                <div className={section2Styles.flexRow1__spacer} />

                                <div className={section2Styles.flexCol2}>
                                  <div className={section2Styles.flexCol2__item}><a href="https://investinsenegal.com" target="_blank">
                                    <img
                                      className={section2Styles.icon1}
                                      src={require('../../assets/courseplan/40a61a4a32d06a79ed7965fd0d37ac62.png')}
                                      alt="alt text"
                                    /></a>
                                  </div>
                                  <h5 className={section2Styles.highlights}>Senegal</h5>
                                </div>

                                <div className={section2Styles.flexRow1__spacer} />

                                <div className={section2Styles.flexCol2}>
                                  <div className={section2Styles.flexCol2__item}><a href="https://www.cci.bf/?q=en/content/annuaires-dentreprises" target="_blank">
                                    <img
                                      className={section2Styles.icon1}
                                      src={require('../../assets/courseplan/3315658ec7497d6d589ad8081f9cf7d5.png')}
                                      alt="alt text"
                                    /></a>
                                  </div>
                                  <h5 className={section2Styles.highlights}>Burkina Faso</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.lessonProgress < 10 ? 

                          <div
                            className={section2Styles.content_box1}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section2Styles.hero_title4}>1</h1>
                          </div>                      
                        :
                        <div
                            className={section2Styles.content_box1}
                            style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                          </div> 

                        }

                      </div>
                    </section>
                  }

                  {
                  <section className={section3Styles.section3}>
                    <div className={section3Styles.flexRow}>
                      <div className={section3Styles.flexCol}>
                        <h1 className={section3Styles.hero_title1}>
                          Are you telling your relevant stakeholder(s) enough about your business?
                        </h1>

                        <div className={section3Styles.group}>
                          <div className={section3Styles.content_box7}>
                            <div className={section3Styles.flexCol1}>
                            {this.state.lessonProgress < 20 ? 

                              <a data-bs-toggle="modal" data-bs-target="#progress-modal" className={section3Styles.flexCol1__item}>
                                  <img
                                    className={section3Styles.icon2}
                                    src={require('../../assets/courseplan/d072b3216bedd42af5a660de6e061d2f.png')}
                                    alt="alt text"
                                  />
                              </a>
                              :
                              <a href="./lesson-three" className={section3Styles.flexCol1__item}>
                                  <img
                                    className={section3Styles.icon2}
                                    src={require('../../assets/courseplan/d072b3216bedd42af5a660de6e061d2f.png')}
                                    alt="alt text"
                                  />
                              </a>
                            }

                              <h3 className={section3Styles.subtitle_box}>
                                <div className={section3Styles.subtitle}>
                                  <span className={section3Styles.subtitle_span0}>Use our Pitch deck lesson plan</span>
                                  <span className={section3Styles.subtitle_span1}>
                                    {' '}
                                    to create an impressive Pitch Deck for your
                                    business.
                                  </span>
                                </div>
                              </h3>
                              <h5 className={section3Styles.highlights}>
                                Pitch Deck - a visual presentation that business owners use to showcase their
                                startups to relevant stakeholders.
                              </h5>
                            </div>
                          </div>
                          {this.state.lessonProgress < 30 ? 
                          <div
                            className={section3Styles.content_box1}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section3Styles.hero_title4}>3</h1>
                          </div>
                          :
                          <div
                          className={section3Styles.content_box1}
                          style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                          </div>
                          } 

                        </div>
                      </div>

                      <div className={section3Styles.flexRow__spacer} />

                      <div className={section3Styles.group1}>
                        <div className={section3Styles.content_box4}>
                          <div className={section3Styles.flexCol2}>
                          
                            <a className={section3Styles.flexCol2__item} href="./lesson-two">
                              <img
                                className={section3Styles.icon2}
                                src={require('../../assets/courseplan/a1fb26393cf82461b7bbd5fb738fec0e.png')}
                                alt="alt text"
                              />
                            </a>

                            <div className={section3Styles.flexCol3}>
                              <h3 className={section3Styles.subtitle1_box}>
                                <div className={section3Styles.subtitle1}>
                                  <span className={section3Styles.subtitle1_span0}>Use our lesson plan to create an Elevator Pitch</span>
                                  <span className={section3Styles.subtitle1_span1}> and upload it.</span>
                                </div>
                              </h3>
                              <h5 className={section3Styles.highlights1}>
                                A persuasive 30 seconds or less video or audio that tells people
                                about your business. (Video is preferable as it helps people relate to you better)
                              </h5>
                            </div>
                          </div>
                        </div>

                        {this.state.lessonProgress < 20 ? 

                        <div
                          className={section3Styles.content_box11}
                          style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                          <h1 className={section3Styles.hero_title41}>2</h1>
                        </div>
                        :
                        <div
                          className={section3Styles.content_box11}
                          style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                        </div>
                        }
                      </div>
                    </div>
                  </section>
                  }

                  {
                    <section className={section4Styles.section4}>
                      <div className={section4Styles.flexRow}>
                        <div className={section4Styles.flexCol}>
                          <h1 className={section4Styles.hero_title1}>Have you created an effective brand?</h1>

                          <div className={section4Styles.group}>
                            <div className={section4Styles.content_box8}>
                              <div className={section4Styles.flexCol1}>
                              {this.state.lessonProgress < 40 ? 

                                <a data-bs-toggle="modal" data-bs-target="#progress-modal" className={section4Styles.flexCol1__item}>
                                  <img
                                    className={section4Styles.icon4}
                                    src={require('../../assets/courseplan/b1b210da718b78b4fe4f19a9b448b649.png')}
                                    alt="alt text"
                                  />
                                </a>

                                :

                                <a data-bs-toggle="modal" data-bs-target="#finance-modal" className={section4Styles.flexCol1__item}>
                                  <img
                                    className={section4Styles.icon4}
                                    src={require('../../assets/courseplan/b1b210da718b78b4fe4f19a9b448b649.png')}
                                    alt="alt text"
                                  />
                                </a>

                              }

                                <h3 className={section4Styles.subtitle_box}>
                                  <div className={section4Styles.subtitle}>
                                    <span className={section4Styles.subtitle_span0}>
                                      Take the introduction to Finances/Bookkeeping Course
                                    </span>
                                    <span className={section4Styles.subtitle_span1}>
                                      {' '}
                                      to understand what basic financials you need for your early-stage business.  <br />
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                            {this.state.lessonProgress < 50 ? 

                            <div
                            className={section4Styles.content_box1}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section4Styles.hero_title4}>5</h1>
                            </div>
                              :
                              <div
                              className={section4Styles.content_box1}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                              }


                          </div>
                        </div>

                        <div className={section4Styles.flexRow__spacer} />

                        <div className={section4Styles.flexCol2}>
                          <div className={section4Styles.group1}>
                            <div className={section4Styles.content_box11}>
                              <div className={section4Styles.flexCol3}>

                              {this.state.lessonProgress < 30 ? 

                                <a data-bs-toggle="modal" data-bs-target="#progress-modal" className={section4Styles.flexCol3__item}>
                                  <img
                                    className={section4Styles.icon3}
                                    src={require('../../assets/courseplan/ca166040d58e2d86b465272e8230bc89.png')}
                                    alt="alt text"
                                  />
                                </a>

                                :
                                <a href="./lesson-four" className={section4Styles.flexCol3__item}>
                                  <img
                                    className={section4Styles.icon3}
                                    src={require('../../assets/courseplan/ca166040d58e2d86b465272e8230bc89.png')}
                                    alt="alt text"
                                  />
                                </a>
                              }
                                
                                <h3 className={section4Styles.subtitle1_box}>
                                  <div className={section4Styles.subtitle1}>
                                    <span className={section4Styles.subtitle1_span0}>Use our Marketing and Branding Lesson plan</span>
                                    <span className={section4Styles.subtitle1_span1}>
                                      {' '}
                                      to help you figure out how to create an effective brand and market it.  <br />
                                    </span>
                                  </div>
                                </h3>
                                <h5 className={section4Styles.highlights}>
                                  If you already have your branding materials, please upload them, and use the lesson plan to review it
                                  and edit where necessary.
                                  <br />
                                </h5>
                              </div>
                            </div>

                            
                            {this.state.lessonProgress < 40 ? 

                            <div
                            className={section4Styles.content_box12}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section4Styles.hero_title41}>4</h1>
                            </div>
                            :
                            <div
                              className={section4Styles.content_box12}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }

                          </div>

                          <h1 className={section4Styles.hero_title11}>Are you keeping track of your finances?</h1>
                        </div>
                      </div>
                    </section>
                  }

                  {
                    <section className={section5Styles.section5}>
                      <div className={section5Styles.flexRow}>
                        <div className={section5Styles.flexCol}>
                          <h1 className={section5Styles.hero_title1}>Have you developed business policies?</h1>

                          <div className={section5Styles.group}>
                            <div className={section5Styles.content_box14}>
                              <div className={section5Styles.flexCol1}>
                                <a href="./lesson-seven" className={section5Styles.flexCol1__item}>
                                  <img
                                    className={section5Styles.icon5}
                                    src={require('../../assets/courseplan/d79b13cca9ab4493aaa1d206afb30d9d.png')}
                                    alt="alt text"
                                  />
                                </a>
                                <h3 className={section5Styles.subtitle_box}>
                                  <div className={section5Styles.subtitle}>
                                    <span className={section5Styles.subtitle_span0}>Use our Business Operations Lesson plan</span>
                                    <span className={section5Styles.subtitle_span1}>
                                      {' '}
                                      to help you think through the things successful businesses do to keep running and making money
                                      <br />
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                            <div
                              className={section5Styles.content_box1}
                              style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                              <h1 className={section5Styles.hero_title4}>7</h1>
                            </div>
                          </div>
                        </div>

                        <div className={section5Styles.flexRow__spacer} />

                        <div className={section5Styles.flexCol2}>
                          <div className={section5Styles.group1}>
                            <div className={section5Styles.content_box15}>
                              <div className={section5Styles.flexCol3}>
                                <a href="lesson-six" className={section5Styles.flexCol3__item}>
                                  <img
                                    className={section5Styles.image5}
                                    src={require('../../assets/courseplan/43a694d1f9634d50a89d850ba2c2c56e.png')}
                                    alt="alt text"
                                  />
                                </a>
                                <h3 className={section5Styles.subtitle1_box}>
                                  <div className={section5Styles.subtitle1}>
                                    <span className={section5Styles.subtitle1_span0}>
                                      Use our Business Regulations and Policies Lesson plan
                                    </span>
                                    <span className={section5Styles.subtitle1_span1}>
                                      {' '}
                                      to help you think through the regulations you need to think of and policies you will need to
                                      create for your business.
                                      <br />
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                            <div
                              className={section5Styles.content_box1}
                              style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                              <h1 className={section5Styles.hero_title4}>6</h1>
                            </div>
                          </div>

                          <h1 className={section5Styles.hero_title11}>Have you developed an effective operations plan?</h1>
                        </div>
                      </div>
                    </section>
                  }
                  {
                    <section className={section6Styles.section6}>
                      <div className={section6Styles.flexRow}>
                        <div className={section6Styles.flexCol}>
                          <h1 className={section6Styles.hero_title1}>Do you have a sales strategy?</h1>

                          <div className={section6Styles.group}>
                            <div className={section6Styles.content_box}>
                              <a href="./lesson-nine" className={section6Styles.content_box__item}>
                                <img
                                  className={section6Styles.icon6}
                                  src={require('../../assets/courseplan/744186ab679b86ee6b6ea5b9c378683f.png')}
                                  alt="alt text"
                                />
                              </a>
                              <h3 className={section6Styles.subtitle_box}>
                                <div className={section6Styles.subtitle}>
                                  <span className={section6Styles.subtitle_span0}>Use our How to Hire Lesson </span>
                                  <span className={section6Styles.subtitle_span1}>
                                    plan to help you figure out how to hire Superstars to help you grow your business.
                                  </span>
                                  <span className={section6Styles.subtitle_span2}> </span>
                                </div>
                              </h3>
                            </div>

                            {this.state.lessonProgress < 90 ? 
                            <div
                              className={section6Styles.content_box1}
                              style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                              <h1 className={section6Styles.hero_title4}>9</h1>
                            </div>
                            :
                            <div
                              className={section6Styles.content_box1}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }


                          </div>
                        </div>

                        <div className={section6Styles.flexRow__spacer} />

                        <div className={section6Styles.flexCol1}>
                          <div className={section6Styles.group1}>
                            <a href="./lesson-nine" className={section6Styles.content_box17}>
                              <img
                                className={section6Styles.image6}
                                src={require('../../assets/courseplan/79c274d2fc1752d2d49c0f22d28abda7.png')}
                                alt="alt text"
                              />
                            </a>

                            <div
                              className={section6Styles.content_box11}
                              style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                              <h1 className={section6Styles.hero_title41}>8</h1>
                            </div>
                          </div>

                          <h1 className={section6Styles.hero_title11}>Are you ready to hire your first employee?</h1>
                        </div>
                      </div>
                    </section>                    
                  }
                  {
                    <section className={section7Styles.section7}>
                      <div className={section7Styles.flexRow}>
                        <h1 className={section7Styles.hero_title1}>Do you need a Board? What type of Board do you Need?</h1>
                        <div className={section7Styles.flexRow__spacer} />

                        <div className={section7Styles.group}>
                          <div className={section7Styles.content_box21}>
                            <h3 className={section7Styles.subtitle_box}>
                              <div className={section7Styles.subtitle}>
                                <span className={section7Styles.subtitle_span0}>Use our Board Structures lesson plan</span>
                                <span className={section7Styles.subtitle_span1}>
                                  {' '}
                                  to identify IF you need a Board, the TYPE of
                                  Board that would work for you and the type of people you will need to help you grow
                                  your business.
                                  Board Structures – Governance and Structure (what do you have in place to grow
                                  your business) Do you have a Board? What type of Board is it? Learn what makes a
                                  strong board, the traits, and attributes for people to join your board.
                                  Learn how to
                                  invite people to your Board.
                                </span>
                              </div>
                            </h3>
                          </div>

                          <div
                            className={section7Styles.content_box1}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section7Styles.hero_title4}>10</h1>
                          </div>
                        </div>
                      </div>
                    </section>                    
                  }

                  {/* success modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-success">
                                <i className="las la-check fs-3qx text-success"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">{this.state.successMessage}</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* Error modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            
                            <h3 className="nk-modal-title mt-10">Account creation was not successful.</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">{this.state.errorMessage}</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* biz already registered modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="registered-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-success">
                                <i className="las la-check fs-3qx text-success"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">You have submitted a valid business registration document already. Kindly move on to lesson 2.</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* do previous lesson modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="progress-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-success">
                                <i className="las la-check fs-3qx text-success"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Lesson Progress</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">Kindly complete the previous lesson to gain access to this one.</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="register-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-info">
                                <i className="las la-mouse fs-3qx text-info"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Select your country to begin the process</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">Click on the appropriate flag to begin the registration process.</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="finance-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-info">
                                <i className="las la-coins fs-3qx text-info"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Open a Built Accounting profile</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">To begin the journey on finances, set your business up on Built Accounting</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <a className="btn btn-lg btn-mw btn-light m-1" href="./accounting">Open Account</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LearnWithAWA;