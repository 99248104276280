import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import EntrepreneurProfile from "./includes/EntrepreneurProfile";
import InvestorProfile from "./includes/InvestorProfile";
import MentorProfile from "./includes/MentorProfile";
import JobSeekerProfile from "./includes/JobSeekerProfile";
import CommunityConversationCard from "./includes/CommunityConversationCard";
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import classNames from "classnames";
const { Search, TextArea } = Input;

class CommunityInfo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
      isRequesting: true,
      isLoadingSidePanel: false,
      isDeleting: false,
      isDeleted: false,
      viewProfile: false,
      viewProfileData: {},
      showLoadMore: false,
      title: '',
      category: '',
      people: [],
      user_id: '',
      community_id: '',
      community_name: '',
      industry: '',
      purpose: '',
      isCreator: false,
      isMember: false,
      posts: [],
      topicForm: {
        title: '',
        category: '',
        post: ''
      },
      topicFormErrors: {
        title: '',
        category: '',
        post: ''
      },
      communityForm: {
        community_name: '',
        industry: '',
        purpose: ''
      },
      communityFormErrors: {
        community_name: '',
        industry: '',
        purpose: ''
      },
      last_post_id: null,
      successMessage: '',
      errorMessage: '',
      deleteSuccessMessage: '',
      deleteErrorMessage: ''
    }
	}

  resetDeleteModal(){
    setTimeout(() => {
      this.setState({
        ...this.state,
        isDeleting: false,
        isDeleted: false,
        deleteSuccessMessage: '',
        deleteErrorMessage: ''
      })
    }, 1000);
  }

  updateFilter = (event, name) => {
    let value;

    if(name == 'category' ){
      value = event;

    }else{
      value = event.target.value;
    }

    this.setState({
      ...this.state,
      [name]: value
    })

  }

  updatePostForm = (event, name) => {
    var topicForm = this.state.topicForm;
    let value;

    if(name === 'category'){
      value = event;
    }else{
      value = event.target.value;
    }

    topicForm[name] = value;
    this.setState({
			...this.state,
      topicForm: topicForm
    });
  }

  createTopicForm () {
    let topicFormErrors = {
      title: '',
      category: '',
      post: ''
    };

    let error_count = 0;

    if(this.state.topicForm.title.length === 0){
      topicFormErrors.title = 'Input required';
      error_count++;      
    }

    if(this.state.topicForm.category.length === 0){
      topicFormErrors.category = 'Input required';
      error_count++;      
    }

    if(this.state.topicForm.post.length === 0){
      topicFormErrors.post = 'Input required';
      error_count++;      
    }

    if(error_count > 0 ){
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        topicFormErrors: topicFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      topicFormErrors: {
        title: '',
        category: '',
        post: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/create-community-post", {
      community_id: this.state.community_id,
      title: this.state.topicForm.title,
      category: this.state.topicForm.category,
      post: this.state.topicForm.post
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      let posts = this.state.posts;
      posts.unshift(responseInfo.data.post);
      this.setState({
        ...this.state,
        isRequesting: false,
        user_id: responseInfo.data.user_id,
        posts: posts,
        topicForm: {
          title: '',
          category: '',
          post: ''
        }
      });

      window.modalHide('create-modal');
      window.modalShow('success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  getPosts(load=null){
    this.setState({
      ...this.state,
      isRequesting: load === null ? true : false,
      isLoadingMore: load === null ? false : true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-community-posts", {
      community_id: this.state.community_id,
      title: this.state.title,
      category: this.state.category,
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let posts = responseInfo.data.posts;
      let showLoadMore = posts.length > 19 ? true : false;
      
      if(load !== null){
        let old_posts = this.state.posts;
        posts = old_posts.concat(posts);
      }

      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        community_name: responseInfo.data.community_name,
        industry: responseInfo.data.industry,
        purpose: responseInfo.data.purpose,
        isCreator: responseInfo.data.isCreator,
        communityForm: {
          community_id: this.state.community_id,
          community_name: responseInfo.data.community_name,
          industry: responseInfo.data.industry,
          purpose: responseInfo.data.purpose,
        },
        people: responseInfo.data.members,
        isMember: responseInfo.data.isMember,
        showLoadMore: showLoadMore,
        isLoadingMore: false,
        posts: posts
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          isLoadingMore: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  joinCommunity () {
    axios.post(CONSTANTS.API_BASE_URL + "/join-community", {
      community_id: this.state.community_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      this.setState({
        ...this.state,
        isMember: this.state.isMember ? false : true
      })

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  deleteCommunity () {
    this.setState({
      ...this.state,
      isDeleting: true,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: ''
    })

    axios.post(CONSTANTS.API_BASE_URL + "/delete-community", {
      community_id: this.state.community_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isDeleting: false,
        isDeleted: true,
        deleteSuccessMessage: 'The community has been deleted successfully'
      })

      window.scrollTo({top: 0, behavior: 'smooth'});

      setTimeout(() => {
        window.location = '/community/groups'
      }, 2000);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isDeleting: false,
          deleteErrorMessage: errorMessage
        })
        
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  createConnection (user_id, index) {

    axios.post(CONSTANTS.API_BASE_URL + "/create-connection", {
      user_id: user_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  deleteConnection (connection_id, index) {

    axios.post(CONSTANTS.API_BASE_URL + "/delete-connection", {
      connection_id: connection_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  removePost = (post_id, index) => {
    let posts = this.state.posts;

    if(posts[index].post_id == post_id){
      posts.splice(index, 1);
    }

    this.setState({
      ...this.state,
      posts: posts
    })
  }

  submitCommunityForm () {
    let communityFormErrors = {
      community_name: '',
      industry: '',
      purpose: ''
    };

    let error_count = 0;

    if(this.state.communityForm.community_name.length === 0){
      communityFormErrors.community_name = 'Input required';
      error_count++;      
    }

    if(this.state.communityForm.industry.length === 0){
      communityFormErrors.industry = 'Input required';
      error_count++;      
    }

    if(this.state.communityForm.purpose.length === 0){
      communityFormErrors.purpose = 'Input required';
      error_count++;      
    }

    if(error_count > 0 ){
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        communityFormErrors: communityFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      community_name: this.state.communityForm.community_name,
      industry: this.state.communityForm.industry,
      purpose: this.state.communityForm.purpose,
      communityFormErrors: {
        community_name: '',
        industry: '',
        purpose: ''
      },
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/edit-community", {
      ...this.state.communityForm
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      let communities = this.state.communities;
      communities.unshift(responseInfo.data);
      this.setState({
        ...this.state,
        isRequesting: false,
        communities: communities,
        successMessage: 'Community information has been updated successfully'
      });  
      window.modalHide('create-modal');
      window.modalShow('update-success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    window.KTComponents.init();
    const { match: { params } } = this.props;
		if(params.id){
      this.setState({
        ...this.state,
        community_id: params.id
      })

      setTimeout(() => {
        this.getPosts()
      }, 500);
    }else{
			window.location = "/not-found";
		}
    
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Community - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'community'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          Community
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <li className="breadcrumb-item text-muted">Home</li>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted">Community</li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <div className="m-0">
                          <a
                            href="#"
                            className="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            
                            <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            Filter
                          </a>
                          <div
                            className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                            data-kt-menu="true"
                            id="kt_menu_6371365f4a778"
                          >
                            <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bold">Filter Options</div>
                            </div>
                            <div className="separator border-gray-200" />
                            <div className="px-7 py-5">
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Title:</label>
                                <Input size="large" required type="text" id="title" placeholder="search by title" name="title" value={this.state.title} onChange={(e) => {this.updateFilter(e,"title");}} />
                              </div>
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Category:</label>
                                <Select
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={this.state.category}
                                  onChange={(value) => {this.updateFilter(value, 'category')}}
                                  options={[
                                    {
                                      value: 'Beauty',
                                      label: 'Beauty',
                                    },
                                    {
                                      value: 'Food and Beverage',
                                      label: 'Food and Beverage',
                                    },
                                    {
                                      value: 'Hospitality',
                                      label: 'Hospitality',
                                    },
                                    {
                                      value: 'Edu Tech',
                                      label: 'Edu Tech',
                                    },
                                    {
                                      value: 'Logistics',
                                      label: 'Logistics',
                                    },
                                    {
                                      value: 'Business Consulting/Management',
                                      label: 'Business Consulting/Management',
                                    },
                                    {
                                      value: 'Transportation',
                                      label: 'Transportation',
                                    },
                                    {
                                      value: 'Agriculture',
                                      label: 'Agriculture',
                                    },
                                    {
                                      value: 'Fashion',
                                      label: 'Fashion',
                                    },
                                    {
                                      value: 'Manufacturing',
                                      label: 'Manufacturing',
                                    }
                                  ]}
                                />
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      title: '',
                                      category: ''
                                    })

                                    setTimeout(() => {
                                      this.getPosts();
                                    }, 400);
                                  }}
                                  className="btn btn-sm btn-light btn-active-light-primary me-2"
                                  data-kt-menu-dismiss="true"
                                >
                                  Reset
                                </button>
                                <button
                                  onClick={() => {this.getPosts()}}
                                  disabled={this.state.isRequesting}
                                  className="btn btn-sm btn-primary"
                                  data-kt-menu-dismiss="true"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a href="/community/groups" className="btn btn-sm fw-bold btn-primary">
                          View Communities
                        </a>
                      </div>

                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">                      
                        {
                          this.state.viewProfile ?
                          <div>
                            <div className="app-container">
                              <span className="d-block px-lg-20 pb-10">
                                <button onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    viewProfile: false,
                                    viewProfileData: {}
                                  })
                                }} className="btn btn-sm btn-primary">
                                  <i class="las la-long-arrow-alt-left"></i> Go Back
                                </button>
                              </span>
                            </div>
                            {
                              this.state.viewProfileData.user_type === 'entrepreneur' &&
                              <EntrepreneurProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'investor' &&
                              <InvestorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'mentor' &&
                              <MentorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'job_seeker' &&
                              <JobSeekerProfile profileInfo={this.state.viewProfileData} />
                            }
                          </div>
                          :
                          <div className="px-lg-20">
                            <div class="card mb-6">
                              <div class="card-body pt-9 pb-0">
                                <div class="d-flex flex-wrap flex-sm-nowrap">
                                  <div class="flex-grow-1">
                                    <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                      <div class="d-flex flex-column">
                                        <div class="d-flex align-items-center mb-2"> 
                                          <span class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{this.state.community_name} 
                                          <span class="badge bg-light text-gray-700 px-3 py-2">{this.state.industry}</span></span>                                          
                                        </div>
                                        <p className="text-gray-400 fw-semibold fs-5 mt-1 mb-7">
                                          {this.state.purpose}
                                        </p>
                                      </div>
                                      <div class="d-flex my-4"> 
                                        {
                                          this.state.isCreator ?
                                          <>
                                            <button class="btn btn-sm btn-light me-2" data-bs-toggle="modal" data-bs-target="#create-modal">Edit</button>
                                            <button class="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2" data-bs-toggle="modal" data-bs-target="#delete-modal">Delete</button>
                                          </>
                                          :
                                          <>
                                            {
                                              this.state.isMember ?
                                              <button class="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2" onClick={() => {this.joinCommunity()}}>Leave Community</button>
                                              :
                                              <button class="btn btn-sm btn-primary me-2" onClick={() => {this.joinCommunity()}}>Join Community</button>
                                            }
                                          </>
                                        }

                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-row">
                              {/* main panel */}
                              <div className="w-100 flex-lg-row-fluid me-lg-13">
                                {/* side panel toggle */}
                                <div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
                                  <div className="d-flex align-items-center gap-2">
                                    <div
                                      className="btn btn-icon btn-active-color-primary w-30px h-30px"
                                      id="kt_social_end_sidebar_toggle"
                                    >
                                      
                                      <span className="svg-icon svg-icon-1">
                                        <svg
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            opacity="0.3"
                                            d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                {/* main content */}
                                {
                                this.state.isRequesting ?
                                <div className="inner-pre-loader-container">
                                  <div className="inner-pre-loader py-20">
                                    <div className="text-center">  
                                      <div className="spinner-border mt-4" role="status">    
                                        <span className="sr-only">Loading...</span>  
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {
                                    this.state.userInfo.profile_completion != "100" &&
                                    <div className="card bg-primary card-xl-stretch mb-5 mb-xl-8">
                                      <div className="card-body my-3">
                                        <div className="d-flex flex-stack">
                                          <div className="symbol symbol-60px me-5">
                                            
                                            <span
                                              className="symbol-label"
                                              style={{ background: "#ffffff42" }}
                                            >
                                              <i className="las la-user-alt text-white fs-3x" />
                                            </span>
                                          </div>
                                          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                            <div className="flex-grow-1 me-10">
                                              <div className="d-flex justify-content-between w-100 mt-auto mb-2 text-white">
                                                
                                                <span className="fw-semibold fs-6">Profile Completion</span>
                                                <span className="fw-bold fs-6">{this.state.userInfo.profile_completion}%</span>
                                              </div>
                                              <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                                <div
                                                  className="progress-bar bg-warning"
                                                  role="progressbar"
                                                  style={{ width: `${this.state.userInfo.profile_completion}%` }}
                                                  aria-valuenow={this.state.userInfo.profile_completion}
                                                  aria-valuemin={0}
                                                  aria-valuemax={100}
                                                />
                                              </div>
                                              <div className="text-light-warning fs-8 pt-2">Complete your profile and get access to all features on AWA.</div>
                                            </div>
                                            <a
                                              href="/account-settings"
                                              className="btn btn-sm btn-light fs-8 fw-bold mt-2"
                                            >
                                              Update
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  {
                                    this.state.isMember &&
                                    <div className="card card-flush mb-10">
                                      <div className="card-header justify-content-start d-flex flex-md-wrap navbar-expand-lg align-items-center pt-4">
                                        <div className="symbol symbol-45px me-5">
                                          {
                                            this.state.userInfo.profile_pic === null ?
                                            <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.userInfo.full_name} />
                                            :
                                            <img className="avatar" src={this.state.userInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userInfo.full_name} />
                                          }
                                        </div>
                                        <span className="text-gray-600 fs-5" style={{width: "80%"}}>
                                          <span className="fw-semibold">Hi {this.state.userInfo.full_name.split(" ")[0]} 👋,</span> <br/>
                                          <span className="fs-6">would you like to start a conversation?</span>
                                        </span>
                                      </div>
                                      <div className="card-footer d-flex justify-content-end pt-5">
                                        {
                                          this.state.userInfo.profile_completion == "100" ?
                                          <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#post-modal">
                                            <span className="indicator-label">Start a conversations</span>
                                          </button>
                                          :
                                          <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#alert-modal">
                                            <span className="indicator-label">Start a conversations</span>
                                          </button>
                                        }
                                      </div>
                                    </div>
                                  }
                                  

                                  <div className="mb-10" id="kt_social_feeds_posts">
                                    {
                                      this.state.errorMessage.length > 0 &&
                                      <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                                        <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"/>
                                              <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                              <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                              <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                            </g>
                                          </svg>
                                        </span>
                                        <div class="d-flex flex-column">
                                          <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                                      </div>
                                    }

                                    {
                                      this.state.posts.length === 0 ?
                                      <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                                        <div className="symbol symbol-40px">
                                          <i className="las la-comment fs-5qx text-gray-400"></i>
                                        </div>
                                        <span className="d-block mt-4">No topics found</span>
                                      </div>
                                      :
                                      <>                             
                                        {
                                          this.state.posts.map((data, index) => {
                                            return (
                                              <CommunityConversationCard community_id={this.state.community_id} postInfo={data} index={index} key={data.post_id} removePost={this.removePost} userID={this.state.userInfo.user_id} profileCompletion={this.state.userInfo.profile_completion == "100" ? true : false} isMember={this.state.isMember} />
                                            )
                                          })
                                        }
                                      </>
                                    }
                                    
                                    
                                  </div>
                                </>
                              }
                                
                              </div>
                              {/* side panel */}
                              <div
                                className="d-lg-flex flex-column flex-lg-row-auto w-lg-350px"
                                data-kt-drawer="true"
                                data-kt-drawer-name="end-sidebar"
                                data-kt-drawer-activate="{default: true, lg: false}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '250px': '300px'}"
                                data-kt-drawer-direction="end"
                                data-kt-drawer-toggle="#kt_social_end_sidebar_toggle"
                              >
                                {
                                  this.state.isLoadingSidePanel ?
                                  <div className="card mb-5 mb-xl-8">
                                    <div className="inner-pre-loader-container">
                                      <div className="inner-pre-loader py-20">
                                        <div className="text-center">  
                                          <div className="spinner-border mt-4" role="status">    
                                            <span className="sr-only">Loading...</span>  
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="card card-flush mb-5 mb-xl-8">
                                    <div className="card-header border-0 py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-dark fs-5">
                                        {this.state.people.length} Community Members
                                        </span>
                                        <span className="text-muted mt-1 fw-semibold fs-7">
                                          List of community members
                                        </span>
                                      </h3>
                                      {
                                        this.state.people.length > 0 &&
                                        <div className="card-toolbar">                                        
                                          <button className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#members-modal">
                                            View All
                                          </button>
                                        </div>
                                      }
                                    </div>
                                    <div className="card-body pt-5">
                                      {
                                        this.state.people.length === 0 ?
                                        <div className="text-center text-gray-700 my-10">
                                          <div className="symbol symbol-40px">
                                            <i className="las la-user-slash fs-3x text-gray-400"></i>
                                          </div>
                                          <span className="d-block mt-4">No one found</span>
                                        </div>
                                        :
                                        <>
                                          {
                                            this.state.people.map((data, index) => {
                                              if(index < 5){
                                                return (
                                                  <>
                                                    {index > 0 && <div className="separator separator-dashed my-4" />}
                                                    <div className="d-flex flex-stack">
                                                      <div className="symbol symbol-40px me-5">
                                                        {
                                                          data.profile_pic === null ?
                                                          <img className="align-self-center avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                                                          :
                                                          <img className="align-self-center avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                                                        }
                                                      </div>
                                                      <div className="d-flex align-items-center flex-row-fluid">
                                                        <div className="flex-grow-1 me-2">
                                                          <span onClick={() => {
                                                            this.setState({
                                                              ...this.state,
                                                              viewProfile: true,
                                                              viewProfileData: data
                                                            })
                                                          }}
                                                            className="cursor-pointer text-gray-800 text-hover-primary fs-6 fw-bold"
                                                          >
                                                            {data.full_name}
                                                          </span>
                                                          <span className="text-muted text-capitalize fw-semibold d-block fs-7">
                                                          {data.user_type}
                                                          </span>
                                                        </div>
                                                        {
                                                          this.state.userInfo.user_id != data.user_id &&
                                                          <>
                                                            {
                                                              this.state.userInfo.profile_completion == "100" ?
                                                              <>
                                                                {
                                                                  data.connection_status == "pending" ?
                                                                  <a href="/my-connections" className="btn btn-sm btn-light fs-8 fw-bold">
                                                                    Pending
                                                                  </a>
                                                                  :
                                                                  <button onClick={() => {
                                                                    let people = this.state.people;
                                                                    people[index].connection_status = 'pending';
                                                                    
                                                                    this.setState({
                                                                      ...this.state,
                                                                      people: people
                                                                    })

                                                                    this.createConnection(data.user_id);
                                                                  }}  className="btn btn-sm btn-light fs-8 fw-bold">
                                                                    Connect
                                                                  </button>
                                                                }
                                                              </>
                                                              :
                                                              <button data-bs-toggle="modal" data-bs-target="#alert-modal" className="btn btn-sm btn-light fs-8 fw-bold">
                                                                Connect
                                                              </button>
                                                              
                                                            }
                                                          </>
                                                        }
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                            })
                                          }
                                        </>
                                      }
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>

                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-success">
                        <i className="las la-check fs-3qx text-success"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">Created Successfully</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">{this.state.successMessage}</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="update-success-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-success">
                        <i className="las la-check fs-3qx text-success"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">Successfully</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">{this.state.successMessage}</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          {/* topic modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="post-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create a Topic</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Title
                      </label>
                      <Input size="large" required type="text" id="title" placeholder="Enter title" name="title" value={this.state.topicForm.title} onChange={(e) => {this.updatePostForm(e,"title");}} />
                      {
                        this.state.topicFormErrors.title.length > 0 && 
                        <div className="invalid-feedback">{this.state.topicFormErrors.title}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Category
                      </label>
                      <Select size="large"
                        style={{
                          width: "100%",
                        }}
                        value={this.state.topicForm.category}
                        onChange={(value) => {this.updatePostForm(value, 'category')}}
                        options={[
                          {
                            value: 'Beauty',
                            label: 'Beauty',
                          },
                          {
                            value: 'Food and Beverage',
                            label: 'Food and Beverage',
                          },
                          {
                            value: 'Hospitality',
                            label: 'Hospitality',
                          },
                          {
                            value: 'Edu Tech',
                            label: 'Edu Tech',
                          },
                          {
                            value: 'Logistics',
                            label: 'Logistics',
                          },
                          {
                            value: 'Business Consulting/Management',
                            label: 'Business Consulting/Management',
                          },
                          {
                            value: 'Transportation',
                            label: 'Transportation',
                          },
                          {
                            value: 'Agriculture',
                            label: 'Agriculture',
                          },
                          {
                            value: 'Fashion',
                            label: 'Fashion',
                          },
                          {
                            value: 'Manufacturing',
                            label: 'Manufacturing',
                          }
                        ]}
                      />
                      {
                        this.state.topicFormErrors.category.length > 0 && 
                        <div className="invalid-feedback">{this.state.topicFormErrors.category}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Post
                      </label>
                      <TextArea required
                        value={this.state.topicForm.post}
                        onChange={(e) => {this.updatePostForm(e,"post");}}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                      {
                        this.state.topicFormErrors.post.length > 0 && 
                        <div className="invalid-feedback">{this.state.topicFormErrors.post}</div>
                      }
                    </div>
                    {
                      this.state.isRequesting ?
                      <div className="nk-modal-action mt-10 text-end">
                        <button disabled className="btn btn-lg btn-mw btn-primary m-2">
                          <span>
                            Creating...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button disabled className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                      :
                      <div className="nk-modal-action mt-10 text-end">
                        <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => {this.createTopicForm()}}>Create</button>
                        <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* members modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="members-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>{this.state.people.length} Community Members</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="">
                      {
                        this.state.people.map((data, index) => {
                          return (
                            <>
                              {index > 0 && <div className="separator separator-dashed my-4" />}
                              <div className="d-flex flex-stack">
                                <div className="symbol symbol-40px me-5">
                                  {
                                    data.profile_pic === null ?
                                    <img className="align-self-center avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                                    :
                                    <img className="align-self-center avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                                  }
                                </div>
                                <div className="d-flex align-items-center flex-row-fluid">
                                  <div className="flex-grow-1 me-2">
                                    <span onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        viewProfile: true,
                                        viewProfileData: data
                                      })
                                    }}
                                      className="cursor-pointer text-gray-800 text-hover-primary fs-6 fw-bold"
                                    >
                                      {data.full_name}
                                    </span>
                                    <span className="text-muted text-capitalize fw-semibold d-block fs-7">
                                    {data.user_type}
                                    </span>
                                  </div>
                                  <>
                                    {
                                      this.state.userInfo.profile_completion == "100" ?
                                      <>
                                        {
                                          data.connection_status == "pending" ?
                                          <a href="/my-connections"  className="btn btn-sm btn-light fs-8 fw-bold">
                                            Pending
                                          </a>
                                          :
                                          <button onClick={() => {
                                            let people = this.state.people;
                                            people[index].connection_status = 'pending';
                                            
                                            this.setState({
                                              ...this.state,
                                              people: people
                                            })

                                            this.createConnection(data.user_id);
                                          }}  className="btn btn-sm btn-light fs-8 fw-bold">
                                            Connect
                                          </button>
                                        }
                                      </>
                                      :
                                      <button data-bs-toggle="modal" data-bs-target="#alert-modal" className="btn btn-sm btn-light fs-8 fw-bold">
                                        Connect
                                      </button>
                                      
                                    }
                                  </>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>                  
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          {/* alert modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="alert-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-gray-500">
                        <i className="las la-info fs-3qx text-gray-800"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">Sorry, can't perform this action</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">Complete your profile and get access to all features on AWA.</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <a href="/account-settings" className="btn btn-lg btn-mw btn-primary m-2">Complete Profile</a>
                      <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          {/* community modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="create-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Edit Community</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                      Community Name
                      </label>
                      <Input size="large" required type="text" id="community_name" placeholder="Enter community name" name="community_name" value={this.state.communityForm.community_name} onChange={(e) => {this.updateCommunityForm(e,"community_name");}} />
                      {
                        this.state.communityFormErrors.community_name.length > 0 && 
                        <div className="invalid-feedback">{this.state.communityFormErrors.community_name}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Industry
                      </label>
                      <Select size="large"
                        style={{
                          width: "100%",
                        }}
                        value={this.state.communityForm.industry}
                        onChange={(value) => {this.updateCommunityForm(value, 'industry')}}
                        options={[
                          {
                            value: 'Beauty',
                            label: 'Beauty',
                          },
                          {
                            value: 'Food and Beverage',
                            label: 'Food and Beverage',
                          },
                          {
                            value: 'Hospitality',
                            label: 'Hospitality',
                          },
                          {
                            value: 'Edu Tech',
                            label: 'Edu Tech',
                          },
                          {
                            value: 'Logistics',
                            label: 'Logistics',
                          },
                          {
                            value: 'Business Consulting/Management',
                            label: 'Business Consulting/Management',
                          },
                          {
                            value: 'Transportation',
                            label: 'Transportation',
                          },
                          {
                            value: 'Agriculture',
                            label: 'Agriculture',
                          },
                          {
                            value: 'Fashion',
                            label: 'Fashion',
                          },
                          {
                            value: 'Manufacturing',
                            label: 'Manufacturing',
                          }
                        ]}
                      />
                      {
                        this.state.communityFormErrors.industry.length > 0 && 
                        <div className="invalid-feedback">{this.state.communityFormErrors.industry}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Purpose
                      </label>
                      <TextArea required
                        value={this.state.communityForm.purpose}
                        onChange={(e) => {this.updateCommunityForm(e,"purpose");}}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                      {
                        this.state.communityFormErrors.purpose.length > 0 && 
                        <div className="invalid-feedback">{this.state.communityFormErrors.purpose}</div>
                      }
                    </div>
                    {
                      this.state.isRequesting ?
                      <div className="nk-modal-action mt-10 text-end">
                        <button disabled className="btn btn-lg btn-mw btn-primary m-2">
                          <span>
                            Saving...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button disabled className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                      :
                      <div className="nk-modal-action mt-10 text-end">
                        <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => {this.submitCommunityForm()}}>Save</button>
                        <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* delete modal */}
          <div className="modal fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id="delete-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  
                    <div className="nk-modal my-5">
                    {
                    this.state.isDeleted ?
                      <>
                        <div className="timeline-icon symbol symbol-circle symbol-90px">
                          <div className="symbol-label bg-light-success">
                            <i className="las la-check fs-3qx text-success"></i>
                          </div>
                        </div>
                        <h3 className="nk-modal-title mt-10">Deleted Successfully</h3>
                        <div className="nk-modal-text">
                          <p className="lead">{this.state.deleteSuccessMessage}</p>
                        </div>
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                        </div>
                      </>
                      :
                      <>
                        <div className="timeline-icon symbol symbol-circle symbol-90px">
                          <div className="symbol-label bg-light-danger">
                            <i className="las la-exclamation fs-3qx text-danger"></i>
                          </div>
                        </div>
                        <h3 className="nk-modal-title mt-10">Delete this community?</h3>
                        <div className="nk-modal-text">
                          <p className="lead">Are you sure you want to delete this community?. <br/>This action can not be reverted.</p>
                          {
                            this.state.deleteErrorMessage.length > 0 && <p className="text-danger">{this.state.deleteErrorMessage}</p>
                          }
                        </div>
                        {
                          this.state.isDeleting ?
                          <div className="nk-modal-action mt-10">
                            <button className="btn btn-lg btn-mw btn-danger m-2" disabled>
                              Deleting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </button>
                            <button className="btn btn-lg btn-mw btn-light m-2" disabled>Close</button>
                          </div>
                          :
                          <div className="nk-modal-action mt-10">
                            <button className="btn btn-lg btn-mw btn-danger m-2" onClick={() => { this.deleteCommunity() }}>Delete</button>
                            <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                          </div>
                        }
                      </>
                    } 
                    </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
		
      </Fragment>
    );
  }
}

export default CommunityInfo;