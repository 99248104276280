import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import moment from 'moment';

class ConversationCard extends React.Component {

  constructor(state){
    super(state);
    this.state = {
      userInfo: AuthService.getAuth().user,
      isRequesting: false,
      removed: false,
      new_comment: '',
      errorMessage: '',
      postInfo: this.props.postInfo,
      isDeleting: false,
      isDeleted: false,
      deleteID: '',
      deleteIndex: '',
      deleteSuccessMessage: '',
      deleteErrorMessage : ''
    }
  }

  createComment (post_id) {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: ''
    })

    if(this.state.new_comment.length === 0){
      this.setState({
        ...this.state,
        isRequesting: false,
        errorMessage: 'Type a message you want to post.'
      })
      return;
    }

    axios.post(CONSTANTS.API_BASE_URL + "/create-conversation-comment", {
      post_id: post_id,
      comment: this.state.new_comment
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let postInfo = this.state.postInfo;
      postInfo.comments.push(responseInfo.data);
      this.setState({
        ...this.state,
        isRequesting: false,
        postInfo: postInfo,
        new_comment: ''
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  likePost () {

    axios.post(CONSTANTS.API_BASE_URL + "/like-conversation", {
      post_id: this.state.postInfo.post_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;  

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  newCommentUpdate(event) {
    let value = event.target.value;

    this.setState({
			...this.state,
      new_comment: value
    });
	}

  onEnterPress = (e, post_id) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.createComment(post_id);
    }
  }

  deletePost () {
    this.setState({
      ...this.state,
      isDeleting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/delete-conversation", {
      post_id: this.state.postInfo.post_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;  

      this.setState({
        ...this.state,
        postInfo: {},
        removed: true,
        isDeleting: false,
        isDeleted: true,
        deleteSuccessMessage: 'Post has been deleted successfully'
      })

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isDeleting: false,
          deleteErrorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  deleteComment () {
    this.setState({
      ...this.state,
      isDeleting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/delete-conversation-comment", {
      post_id: this.state.postInfo.post_id,
      comment_id: this.state.deleteID,
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;  
      
      let postInfo = this.state.postInfo;
      postInfo.comments.splice(this.state.deleteIndex, 1)

      this.setState({
        ...this.state,
        postInfo: postInfo,
        isDeleting: false,
        isDeleted: true,
        deleteSuccessMessage: 'Comment has been deleted successfully'
      })
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isDeleting: false,
          deleteErrorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  resetPostDeleteModal () {
    this.resetDeleteModal();
    if(this.state.removed){
      setTimeout(() => {
        this.props.removePost(this.props.postInfo.post_id, this.props.index);
      }, 500);
    }
  }

  resetDeleteModal(){
    this.setState({
      ...this.state,
      deleteID: '',
      deleteIndex: '',
      isDeleting: false,
      isDeleted: false,
      deleteSuccessMessage: '',
      deleteErrorMessage: ''
    })
  }

  componentDidMount () {
    window.KTComponents.init();
  }

  render(){
    return (
      <>
        {
          !this.state.removed && 
          <div className="card card-flush mb-10">
            <div className="card-header pt-9">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50px me-5">   
                  {
                    this.state.postInfo.profile_pic === null ?
                    <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.postInfo.full_name} />
                    :
                    <img className="avatar" src={this.state.postInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.postInfo.full_name} />
                  }         
                </div>
                <div className="flex-grow-1 lh-base">
                  
                  <a href={`/profile/${this.state.postInfo.user_id}`} className="text-gray-800 text-hover-primary fs-4 fw-bold">
                    {this.state.postInfo.full_name}
                  </a>
                  <span className="text-gray-700 text-capitalize fs-7 fw-semibold d-block">
                    {this.state.postInfo.user_type}
                  </span>
                  <span className="text-gray-400 d-block">
                    {moment(this.state.postInfo.post_date, 'YYYY-MM-DD H:mm:ss').calendar({
                      sameDay: '[Today] h:mm a',
                      nextDay: '[Tomorrow] h:mm a',
                      nextWeek: 'dddd',
                      lastDay: '[Yesterday] h:mm a',
                      lastWeek: '[Last] dddd h:mm a',
                      sameElse: 'Do MMM YYYY hh:mm a'
                    })}
                  </span>
                </div>
              </div>
              {
                this.state.postInfo.user_id == this.props.userID &&
                <div className="card-toolbar">
                  <div className="m-0">
                    <button
                      className="btn btn-icon btn-color-gray-400 btn-active-color-primary me-n4"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-overflow="true"
                    >
                      
                      <span className="svg-icon svg-icon-1">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={4}
                            fill="currentColor"
                          />
                          <rect
                            x={11}
                            y={11}
                            width="2.6"
                            height="2.6"
                            rx="1.3"
                            fill="currentColor"
                          />
                          <rect
                            x={15}
                            y={11}
                            width="2.6"
                            height="2.6"
                            rx="1.3"
                            fill="currentColor"
                          />
                          <rect
                            x={7}
                            y={11}
                            width="2.6"
                            height="2.6"
                            rx="1.3"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </button>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                      data-kt-menu="true"
                    >
                      <div className="menu-item px-3" data-bs-toggle="modal" data-bs-target={`#delete-post-modal-${this.props.postInfo.post_id}`}>
                        <a className="menu-link px-3 text-danger">
                          Delete Post
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="card-body">
              <div>
                <span className="fw-semibold fs-6 text-primary">{this.state.postInfo.title}</span>
                <span className="badge bg-light text-gray-700 px-3 py-2 ms-2">{this.state.postInfo.category}</span>
              </div>
              <div className="fs-6 fw-normal text-gray-700 text-wrap">
                {this.state.postInfo.post}
              </div>
            </div>
            <div className="card-footer pt-0">
              <div className="mb-6">
                <div className="separator separator-solid" />
                <ul className="nav py-3">
                  <li className="nav-item">
                    <a className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary btn-active-light-primary fw-bold px-4 me-1 collapsible"
                      data-bs-toggle="collapse"
                      href={`#kt_social_feeds_comments_${this.props.index}`}>                  
                      <i className="bi bi-chat-square fs-2 me-1" />{this.state.postInfo.comments.length} {this.state.postInfo.comments.length > 1 ? `Comments` : `Comment`}
                    </a>
                  </li>
                  <li className="nav-item">
                    {
                      this.props.profileCompletion ?
                      <a onClick={() => {
                          let postInfo = this.state.postInfo;
                          postInfo.likes_count = postInfo.liked ? parseInt(postInfo.likes_count) - 1 : parseInt(postInfo.likes_count) + 1;
                          postInfo.liked = postInfo.liked ? false : true;
                          
                          this.setState({
                            ...this.state,
                            postInfo: postInfo
                          })

                          this.likePost()
                        }
                      }
                        className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4 me-1">
                        {
                          this.state.postInfo.liked ?
                          <i className="bi bi-heart-fill text-danger fs-2 me-1" />
                          :
                          <i className="bi bi-heart fs-2 me-1" />
                        }
                        {this.state.postInfo.likes_count} {parseInt(this.state.postInfo.likes_count) > 1 ? `Likes` : `Like`} 
                      </a>
                      :
                      <a data-bs-toggle="modal" data-bs-target="#alert-modal" className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4 me-1">
                        {
                          this.state.postInfo.liked ?
                          <i className="bi bi-heart-fill text-danger fs-2 me-1" />
                          :
                          <i className="bi bi-heart fs-2 me-1" />
                        }
                        {this.state.postInfo.likes_count} {parseInt(this.state.postInfo.likes_count) > 1 ? `Likes` : `Like`} 
                      </a>
                    }
                  </li>
                </ul>
                <div className="separator separator-solid mb-1" />
                <div className="collapse" id={`kt_social_feeds_comments_${this.props.index}`}>
                  {
                    this.state.postInfo.comments.map((data, index) => {
                      return (
                        <div className="d-flex pt-6" key={index}>
                          <div className="symbol symbol-45px me-5">
                            {
                              data.profile_pic === null ?
                              <img className="avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                              :
                              <img className="avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                            }
                          </div>
                          <div className="d-flex flex-column flex-row-fluid">
                            <div className="d-flex align-items-center flex-wrap mb-0">
                              <a className="text-gray-800 text-hover-primary fw-bold me-6">
                                {data.full_name}
                                <span className="text-gray-500 text-capitalize fs-8 ps-1 fw-semibold">
                                  - {data.user_type}
                                </span>
                              </a>
                              
                              {
                                data.user_id == this.props.userID &&
                                <a className="ms-auto text-gray-400 text-hover-primary fs-7"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      deleteID: data.comment_id,
                                      deleteIndex: index,
                                    })
                                  }} data-bs-toggle="modal" data-bs-target={`#delete-comment-modal-${this.props.postInfo.post_id}`}>
                                  Delete
                                </a>
                              }
                            </div>
                            <span className="text-gray-400 d-block fs-8 me-5">
                              {moment(data.comment_date, 'YYYY-MM-DD H:mm:ss').calendar({
                                sameDay: '[Today] h:mm a',
                                nextDay: '[Tomorrow] h:mm a',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday] h:mm a',
                                lastWeek: '[Last] dddd h:mm a',
                                sameElse: 'Do MMM YYYY hh:mm a'
                              })}
                            </span>

                            <span className="text-gray-800 fs-7 fw-normal pt-1 text-wrap">
                              {data.comment}
                            </span>
                          </div>
                        </div>
                      )
                    })
                  }
                  
                </div>
              </div>
              <div className="d-flex">
                <div className="symbol symbol-35px me-3 mt-1">  
                  {
                    this.state.userInfo.profile_pic === null ?
                    <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.userInfo.full_name} />
                    :
                    <img className="avatar" src={this.state.userInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userInfo.full_name} />
                  }            
                </div>
                <div className="position-relative w-100">
                  {
                    this.props.profileCompletion ?
                    <textarea
                      type="text"
                      className="form-control form-control-solid border ps-5"
                      rows={1}
                      disabled={this.state.isRequesting}
                      onKeyDown={(e) => {this.onEnterPress(e, this.state.postInfo.post_id)}}
                      value={this.state.new_comment}
                      onChange={(e) => {this.newCommentUpdate(e)}}
                      data-kt-autosize="true"
                      placeholder="Write your comment.."
                    />
                    :
                    <textarea
                      type="text"
                      disabled
                      className="form-control form-control-solid border ps-5"
                      rows={1}
                      data-kt-autosize="true"
                      placeholder={`Join the community or Complete your profile to have access to this feature`}
                    />
                  }
                  
                  {
                    this.state.isRequesting &&
                    <span className="d-block p-1 text-muted">Posting...</span>
                  }
                  {
                    this.state.errorMessage.length > 0 && 
                    <div className="invalid-feedback">{this.state.errorMessage}</div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {/* delete post modal */}
        <div className="modal fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id={`delete-post-modal-${this.props.postInfo.post_id}`}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                
                  <div className="nk-modal my-5">
                  {
                  this.state.isDeleted ?
                    <>
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Deleted Successfully</h3>
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.deleteSuccessMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal" onClick={() => { this.resetPostDeleteModal() }}>Close</button>
                      </div>
                    </>
                    :
                    <>
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-danger">
                          <i className="las la-exclamation fs-3qx text-danger"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Delete post?</h3>
                      <div className="nk-modal-text">
                        <p className="lead">Are you sure you want to delete this post?. <br/>This action can not be reverted.</p>
                        {
                          this.state.deleteErrorMessage.length > 0 && <p className="text-danger">{this.state.deleteErrorMessage}</p>
                        }
                      </div>
                      {
                        this.state.isDeleting ?
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-danger m-2" disabled>
                            Deleting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </button>
                          <button className="btn btn-lg btn-mw btn-light m-2" disabled>Close</button>
                        </div>
                        :
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-danger m-2" onClick={() => { this.deletePost() }}>Delete</button>
                          <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => { this.resetPostDeleteModal() }}>Close</button>
                        </div>
                      }
                    </>
                  } 
                  </div>
                
                
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}

        {/* delete comment modal */}
        <div className="modal fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id={`delete-comment-modal-${this.props.postInfo.post_id}`}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                
                  <div className="nk-modal my-5">
                  {
                  this.state.isDeleted ?
                    <>
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Deleted Successfully</h3>
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.deleteSuccessMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                      </div>
                    </>
                    :
                    <>
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-danger">
                          <i className="las la-exclamation fs-3qx text-danger"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Delete comment?</h3>
                      <div className="nk-modal-text">
                        <p className="lead">Are you sure you want to delete this comment?. <br/>This action can not be reverted.</p>
                        {
                          this.state.deleteErrorMessage.length > 0 && <p className="text-danger">{this.state.deleteErrorMessage}</p>
                        }
                      </div>
                      {
                        this.state.isDeleting ?
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-danger m-2" disabled>
                            Deleting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </button>
                          <button className="btn btn-lg btn-mw btn-light m-2" disabled>Close</button>
                        </div>
                        :
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-danger m-2" onClick={() => { this.deleteComment() }}>Delete</button>
                          <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                        </div>
                      }
                    </>
                  } 
                  </div>
                
                
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}
      </>
    )
  }
}

export default ConversationCard;