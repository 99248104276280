import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import {GrantFrame, GrantFrameXs} from "./includes/GrantsFrame";

class Grants extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true
    }
	}

  componentDidMount () {
    window.KTComponents.init();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Grants - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'grants'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                {
                    <GrantFrame 
                    granttitle="TechWomen 2024 For Women in STEM"
                    grantlink="https://msmeafricaonline.com/call-for-applications-techwomen-2024-for-women-in-stem/"
                    grantdeadline="January 10, 2024 at 09:00AM PST | Technology"
                    grantdescription="TechWomen aims to empower, connect and support the next generation of women leaders in science, technology, engineering and mathematics (STEM) by providing them access and opportunity to advance their careers, pursue their dreams, and become role models for women and girls in their communities."
                    /> 
                  }
                  {
                      <GrantFrame 
                      granttitle="Structural Transformation of African Agriculture & Rural Spaces (STAARS)"
                      grantlink="https://msmeafricaonline.com/call-for-applications-structural-transformation-of-african-agriculture-rural-spaces-staars-fellowship-program-2024/"
                      grantdeadline="January 19, 2024 | Agriculture "
                      grantdescription="Early-career African researchers selected as STAARS Fellows in 2024 advance rigorous, policy-oriented research on the causal determinants of productivity and income growth, asset accumulation, rural employment and risk management in African agriculture and rural spaces."
                      />
                    }
                    {
                        <GrantFrame 
                        granttitle="Nigeria Soot-free Bus CNG/Electric Bus Deployment Projects"
                        grantlink="https://msmeafricaonline.com/call-for-proposals-nigeria-soot-free-bus-cng-electric-bus-deployment-projects-us-240000-us-300000/"
                        grantdeadline="7 January, 2024 | Energy & Transport"
                        grantdescription="This project responds to the request by the Federal Ministry of Environment of Nigeria to develop a national policy on soot-free buses and a national strategy and implementation plan for the deployment of compressed natural gas (CNG)/electric powered buses."
                        />
                      }
                      {
                          <GrantFrame 
                          granttitle="GlaxosmithKline Africa Open Lab 2024 (up to £100,000)"
                          grantlink="https://msmeafricaonline.com/call-for-proposals-glaxosmithkline-africa-open-lab-2024-up-to-100000/"
                          grantdeadline="January 29, 2024 | Health & Medecine"
                          grantdescription="They are calling for research proposals focused on funding high-quality infectious disease research that has the potential to deliver significant health impact and develop future research leaders, with up to £100,000 available per award."
                          />
                        }
                          {
                              <GrantFrame 
                              granttitle="CSI Energy OPEN Fund"
                              grantlink="https://www.csi.energy/open"
                              grantdeadline="19th February 2023 | Energy Sector (Women-Led)"
                              grantdescription="The Global Diversity Export Initiative (GDEI) Trade Mission to Africa is focused on building commercial bridges between U.S. owned or led businesses from underserved communities and business communities in South Africa, Ghana, and Nigeria."
                              />
                            }
                   {
                    <GrantFrame 
                    granttitle="Global Diversity Trade Mission to Africa"
                    grantlink="https://www.trade.gov/global-diversity-export-initiative-mission-africa"
                    grantdeadline="31st May 2023 | Automotive, Consumer Goods, ICT & Security"
                    grantdescription="OPEN will invest up to $100,000 in women-led businesses within the energy sector in Africa, growing their business by providing access to funds and expertise to maximise their impact and profitability.  Investee companies will also be provided with non-financial support from the CSI Energy Group. We are looking for  women-led businesses in the Energy sector with an annual turnover of more than 25,000USD per year and with at least 3 employees."
                    />
                  }
                  {
                    <GrantFrame 
                    granttitle="Standard Chartered Women in Tech"
                    grantlink="https://scwomenintechgh.com/about-us/" 
                    grantdeadline="No active grant | All Industries"
                    grantdescription="The Standard Chartered Women in Tech incubator is specifically designed to provide business incubation
                    support for women-led or women -owned businesses that leverage technology."
                    />
                  }
                  {
                    <GrantFrame 
                    granttitle="African Women's Development Fund"
                    grantlink="https://awdf.org/what-we-do/grants/"
                    grantdeadline="No active grant | All Industries"
                    grantdescription="AWDF’s grantmaking ensures resources reach the hundreds of African women’s organisations that are working in diverse ways to improve the lives of women and African society at large. Our grantmaking is innovative, flexible and creative. Through our grants we support both established and small, locally based women’s organisations. We also provide specialised funding to facilitate learning and networking opportunities and to celebrate the contributions and achievements of many un-sung African heroines."
                    />
                  }
                  {
                    <GrantFrameXs 
                    granttitle="TechWomen 2024 For Women in STEM"
                    grantlink="https://msmeafricaonline.com/call-for-applications-techwomen-2024-for-women-in-stem/"
                    grantdeadline="January 10, 2024 at 09:00AM PST | Technology"
                    grantdescription="TechWomen aims to empower, connect and support the next generation of women leaders in science, technology, engineering and mathematics (STEM) by providing them access and opportunity to advance their careers, pursue their dreams, and become role models for women and girls in their communities."
                    />
                  }
                  {
                      <GrantFrameXs 
                      granttitle="Structural Transformation of African Agriculture & Rural Spaces (STAARS) Fellowship Program 2024"
                      grantlink="https://msmeafricaonline.com/call-for-applications-structural-transformation-of-african-agriculture-rural-spaces-staars-fellowship-program-2024/"
                      grantdeadline="January 19, 2024 | Agriculture "
                      grantdescription="Early-career African researchers selected as STAARS Fellows in 2024 advance rigorous, policy-oriented research on the causal determinants of productivity and income growth, asset accumulation, rural employment and risk management in African agriculture and rural spaces."
                      />
                    }
                    {
                        <GrantFrameXs 
                        granttitle="Nigeria Soot-free Bus CNG/Electric Bus Deployment Projects (US$ 240,000 – US$ 300,000)"
                        grantlink="https://msmeafricaonline.com/call-for-proposals-nigeria-soot-free-bus-cng-electric-bus-deployment-projects-us-240000-us-300000/"
                        grantdeadline="7 January, 2024 | Energy & Transport"
                        grantdescription="This project responds to the request by the Federal Ministry of Environment of Nigeria to develop a national policy on soot-free buses and a national strategy and implementation plan for the deployment of compressed natural gas (CNG)/electric powered buses."
                        />
                      }
                      {
                          <GrantFrameXs 
                          granttitle="GlaxosmithKline Africa Open Lab 2024 (up to £100,000)"
                          grantlink="https://msmeafricaonline.com/call-for-proposals-glaxosmithkline-africa-open-lab-2024-up-to-100000/"
                          grantdeadline="January 29, 2024 | Health & Medecine"
                          grantdescription="They are calling for research proposals focused on funding high-quality infectious disease research that has the potential to deliver significant health impact and develop future research leaders, with up to £100,000 available per award."
                          />
                        }
{
                    <GrantFrameXs 
                    granttitle="CSI Energy OPEN Fund"
                    grantlink="https://www.csi.energy/open"
                    grantdeadline="19th February 2023 | Energy Sector (Women-Led)"
                    grantdescription="OPEN will invest up to $100,000 in women-led businesses within the energy sector in Africa, growing their business by providing access to funds and expertise to maximise their impact and profitability.  Investee companies will also be provided with non-financial support from the CSI Energy Group. We are looking for  women-led businesses in the Energy sector with an annual turnover of more than 25,000USD per year and with at least 3 employees."
                    />
                  }
                
                  
                  {
                    //mobile

                  <GrantFrameXs 
                  granttitle="Standard Chartered Women in Tech"
                  grantdeadline="No active grant | All Industries"
                  grantlink="https://scwomenintechgh.com/about-us/"
                  grantdescription="The Standard Chartered Women in Tech incubator is specifically designed to provide business incubation
                  support for women-led or women -owned businesses that leverage technology."
                   />
                  }

                  
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default Grants;