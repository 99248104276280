import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import EntrepreneurProfile from "./includes/EntrepreneurProfile";
import InvestorProfile from "./includes/InvestorProfile";
import MentorProfile from "./includes/MentorProfile";
import JobSeekerProfile from "./includes/JobSeekerProfile";
import ConversationCard from "./includes/ConversationCard";
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
const { Option } = Select;
const { Search, TextArea } = Input;

class Community extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
      isRequesting: true,
      isLoadingMore: false,
      isLoadingSidePanel: true,
      viewProfile: false,
      viewProfileData: {},
      showLoadMore: false,
      search: '',
      category: '',
      conversations: [],
      communities: [],
      postForm: {
        title: '',
        category: '',
        post: ''
      },
      postFormErrors: {
        title: '',
        category: '',
        post: ''
      },
      new_post: '',
      deletePostID: '',
      deletePostIndex: '',
      errorMessage: '',
      postErrorMessage: '',
    }
	}

  updateFilter = (event, name) => {
    let value;

    if(name == 'category' ){
      value = event;

    }else{
      value = event.target.value;
    }

    this.setState({
      ...this.state,
      [name]: value
    })

  }

  updatePostForm = (event, name) => {
    var postForm = this.state.postForm;
    let value;

    if(name === 'category'){
      value = event;
    }else{
      value = event.target.value;
    }

    postForm[name] = value;
    this.setState({
			...this.state,
      postForm: postForm
    });
  }

  createPostForm () {
    let postFormErrors = {
      title: '',
      category: '',
      post: ''
    };

    let error_count = 0;

    if(this.state.postForm.title.length === 0){
      postFormErrors.title = 'Input required';
      error_count++;      
    }

    if(this.state.postForm.category.length === 0){
      postFormErrors.category = 'Input required';
      error_count++;      
    }

    if(this.state.postForm.post.length === 0){
      postFormErrors.post = 'Input required';
      error_count++;      
    }

    if(error_count > 0 ){
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        postFormErrors: postFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      postFormErrors: {
        title: '',
        category: '',
        post: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/create-conversation", {
      title: this.state.postForm.title,
      category: this.state.postForm.category,
      post: this.state.postForm.post
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      let conversations = this.state.conversations;
      conversations.unshift(responseInfo.data);
      this.setState({
        ...this.state,
        isRequesting: false,
        user_id: responseInfo.data.user_id,
        conversations: conversations,
        postForm: {
          title: '',
          category: '',
          post: ''
        }
      });

      window.modalHide('create-modal');
      window.modalShow('success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  getConversations(load=null){
    this.setState({
      ...this.state,
      isRequesting: load === null ? true : false,
      isLoadingMore: load === null ? false : true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-conversations", {
      title: this.state.title,
      category: this.state.category,
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let conversations = responseInfo.data;
      let showLoadMore = conversations.length > 19 ? true : false;
      
      if(load !== null){
        let old_conversations = this.state.conversations;
        conversations = old_conversations.concat(conversations);
      }

      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        showLoadMore: showLoadMore,
        isLoadingMore: false,
        conversations: conversations
      });  

      let alerts = reactLocalStorage.getObject('alerts');
      if(JSON.stringify(alerts) === '{}'){
        alerts = {
          community_welcome: true
        }
        reactLocalStorage.setObject('alerts', alerts);
        window.modalShow('welcome-modal');

      }else{
        if(alerts?.community_welcome !== true){
          alerts = {
            community_welcome: true
          }
          reactLocalStorage.setObject('alerts', alerts);
          window.modalShow('welcome-modal');
        }
        

      }
      

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          isLoadingMore: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  getCommunities(){
    this.setState({
      ...this.state,
      isLoadingSidePanel: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-communities", {
      search: this.state.search,
      industry: this.state.industry,
      last_community_id: null,
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let communities = responseInfo.data.communities;
      
      this.setState({
        ...this.state,
        isLoadingSidePanel: false,
        communities: communities
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoadingSidePanel: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }
  
  removePost = (post_id, index) => {
    let conversations = this.state.conversations;

    if(conversations[index].post.post_id == post_id){
      conversations.splice(index, 1);
    }

    this.setState({
      ...this.state,
      conversations: conversations
    })
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getConversations();
    this.getCommunities();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Community - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'community'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div style={{width: "50%"}} className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          Community
                        </h1>
                        <p className="mt-3 cursor-pointer" data-bs-toggle="modal" data-bs-target="#welcome-modal">The AWA community encourages you to start discussions with fellow community members on topics that are relevant to your industry. 
                        This space is designed for you to share ideas, resources, get feedback, hold a short seminar, or simply engage with your community of like minded leaders. <span className="text-primary fw-semibold">Learn More</span></p>
                        {/* <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <li className="breadcrumb-item text-muted">Home</li>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted">Community</li>
                        </ul> */}
                      </div>
                      <div className="d-flex align-items-center gap-2 gap-lg-3">
                        {/* <div className="m-0">
                          <a
                            href="#"
                            className="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            
                            <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            Filter
                          </a>
                          <div
                            className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                            data-kt-menu="true"
                            id="kt_menu_6371365f4a778"
                          >
                            <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bold">Filter Options</div>
                            </div>
                            <div className="separator border-gray-200" />
                            <div className="px-7 py-5">
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Search:</label>
                                <Input size="large" required type="text" id="search" placeholder="search by community name" name="search" value={this.state.search} onChange={(e) => {this.updateFilter(e,"search");}} />
                              </div>
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Industry:</label>
                                <Select
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={this.state.industry}
                                  onChange={(value) => {this.updateFilter(value, 'industry')}}
                                  options={[
                                    {
                                      value: '$10,000 - $50,000',
                                      label: '$10,000 - $50,000',
                                    },
                                    {
                                      value: '$50,000 - $100,000',
                                      label: '$50,000 - $100,000',
                                    },
                                    {
                                      value: '$100,000 - $500,000',
                                      label: '$100,000 - $500,000',
                                    },
                                    {
                                      value: '$500,000 - $1 million',
                                      label: '$500,000 - $1 million',
                                    },
                                    {
                                      value: 'More than $1 million',
                                      label: 'More than $1 million',
                                    },
                                  ]}
                                />
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      search: '',
                                      user_type: []
                                    })
                                  }}
                                  className="btn btn-sm btn-light btn-active-light-primary me-2"
                                  data-kt-menu-dismiss="true"
                                >
                                  Reset
                                </button>
                                <button
                                  type="submit"
                                  disabled={this.state.isRequesting}
                                  className="btn btn-sm btn-primary"
                                  data-kt-menu-dismiss="true"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <a
                          href="/community/groups"
                          className="btn btn-sm fw-bold btn-primary text-white"
                        >
                          Join a Community
                        </a>
                        <a
                          href="/community/people"
                          className="btn btn-sm fw-bold btn-warning text-primary btn-active-color-primary"
                        >
                          Make new Connections
                        </a>
                        <button class="btn btn-sm btn-icon btn-secondary" data-bs-toggle="modal" data-bs-target="#welcome-modal">
                          <i class="las la-info fs-2"></i>
                        </button>
                      </div>

                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">                      
                        {
                          this.state.viewProfile ?
                          <div>
                            <div className="app-container">
                              <span className="d-block px-lg-20 pb-10">
                                <button onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    viewProfile: false,
                                    viewProfileData: {}
                                  })
                                }} className="btn btn-sm btn-primary">
                                  <i class="las la-long-arrow-alt-left"></i> Go Back
                                </button>
                              </span>
                            </div>
                            {
                              this.state.viewProfileData.user_type === 'entrepreneur' &&
                              <EntrepreneurProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'investor' &&
                              <InvestorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'mentor' &&
                              <MentorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'job_seeker' &&
                              <JobSeekerProfile profileInfo={this.state.viewProfileData} />
                            }
                          </div>
                          :
                          <div className="d-flex flex-row px-lg-20">
                            {/* main panel */}
                            <div className="w-100 flex-lg-row-fluid me-lg-13">
                              {/* side panel toggle */}
                              <div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
                                <div className="d-flex align-items-center gap-2">
                                  <div
                                    className="btn btn-icon btn-active-color-primary w-30px h-30px"
                                    id="kt_social_end_sidebar_toggle"
                                  >
                                    
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* main content */}
                              {
                                this.state.isRequesting ?
                                <div className="inner-pre-loader-container">
                                  <div className="inner-pre-loader py-20">
                                    <div className="text-center">  
                                      <div className="spinner-border mt-4" role="status">    
                                        <span className="sr-only">Loading...</span>  
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {
                                    this.state.userInfo.profile_completion != "100" &&
                                    <div className="card bg-primary card-xl-stretch mb-5 mb-xl-8">
                                      <div className="card-body my-3">
                                        <div className="d-flex flex-stack">
                                          <div className="symbol symbol-60px me-5">
                                            
                                            <span
                                              className="symbol-label"
                                              style={{ background: "#ffffff42" }}
                                            >
                                              <i className="las la-user-alt text-white fs-3x" />
                                            </span>
                                          </div>
                                          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                            <div className="flex-grow-1 me-10">
                                              <div className="d-flex justify-content-between w-100 mt-auto mb-2 text-white">
                                                
                                                <span className="fw-semibold fs-6">Profile Completion</span>
                                                <span className="fw-bold fs-6">{this.state.userInfo.profile_completion}%</span>
                                              </div>
                                              <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                                <div
                                                  className="progress-bar bg-warning"
                                                  role="progressbar"
                                                  style={{ width: `${this.state.userInfo.profile_completion}%` }}
                                                  aria-valuenow={this.state.userInfo.profile_completion}
                                                  aria-valuemin={0}
                                                  aria-valuemax={100}
                                                />
                                              </div>
                                              <div className="text-light-warning fs-8 pt-2">Complete your profile and get access to all features on AWA.</div>
                                            </div>
                                            <a
                                              href="/account-settings"
                                              className="btn btn-sm btn-light fs-8 fw-bold mt-2"
                                            >
                                              Update
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }


                                  <div className="card card-flush mb-10">
                                    <div className="card-header justify-content-start d-flex flex-md-wrap navbar-expand-lg align-items-center pt-4">
                                      <div className="symbol symbol-45px me-5">
                                        {
                                          this.state.userInfo.profile_pic === null ?
                                          <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.userInfo.full_name} />
                                          :
                                          <img className="avatar" src={this.state.userInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userInfo.full_name} />
                                        }
                                      </div>
                                      <span className="text-gray-600 fs-5" style={{width: "80%"}}>
                                        <span className="fw-semibold text-primary">Hi {this.state.userInfo.full_name.split(" ")[0]} 👋,</span> <br/>
                                        <span className="fs-6">would you like to start a conversation?</span>
                                      </span>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end pt-5">
                                      {
                                        this.state.userInfo.profile_completion == "100" ?
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#post-modal">
                                          <span className="indicator-label">Start a conversation</span>
                                        </button>
                                        :
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#alert-modal">
                                          <span className="indicator-label">Start a conversations</span>
                                        </button>
                                      }
                                    </div>
                                  </div>
                                  <div className="mb-10" id="kt_social_feeds_posts">
                                    {
                                      this.state.errorMessage.length > 0 &&
                                      <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                                        <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"/>
                                              <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                              <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                              <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                            </g>
                                          </svg>
                                        </span>
                                        <div class="d-flex flex-column">
                                          <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                                      </div>
                                    }

                                    {
                                      this.state.conversations.length === 0 ?
                                      <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                                        <div className="symbol symbol-40px">
                                          <i className="las la-comment fs-5qx text-gray-400"></i>
                                        </div>
                                        <span className="d-block mt-4">No conversations found</span>
                                      </div>
                                      :
                                      <>                             
                                        {
                                          this.state.conversations.map((data, index) => {
                                            return (
                                              <ConversationCard postInfo={data.post} index={index} key={data.post.post_id} removePost={this.removePost} userID={this.state.userInfo.user_id} profileCompletion={this.state.userInfo.profile_completion == "100" ? true : false} />
                                            )
                                          })
                                        }
                                      </>
                                    }
                                    
                                    
                                  </div>
                                </>
                              }
                              
                            </div>
                            {/* side panel */}
                            <div
                              className="d-lg-flex flex-column flex-lg-row-auto w-lg-350px"
                              data-kt-drawer="true"
                              data-kt-drawer-name="end-sidebar"
                              data-kt-drawer-activate="{default: true, lg: false}"
                              data-kt-drawer-overlay="true"
                              data-kt-drawer-width="{default:'200px', '250px': '300px'}"
                              data-kt-drawer-direction="end"
                              data-kt-drawer-toggle="#kt_social_end_sidebar_toggle"
                            >
                              {
                                this.state.isLoadingSidePanel ?
                                <div className="card mb-5 mb-xl-8">
                                  <div className="inner-pre-loader-container">
                                    <div className="inner-pre-loader py-20">
                                      <div className="text-center">  
                                        <div className="spinner-border mt-4" role="status">    
                                          <span className="sr-only">Loading...</span>  
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="bg-primary card card-flush mb-5 mb-xl-8">
                                  <div className="card-header border-0 py-5">
                                    <h3 className="card-title align-items-start flex-column">
                                      <span className="card-label fw-bold text-warning fs-5">
                                        Join a community
                                      </span>
                                      <span className="text-white mt-1 fw-semibold fs-7">
                                        List of communities you can join
                                      </span>
                                    </h3>
                                    <div className="card-toolbar">
                                      
                                      <a href="/community/groups" className="btn btn-sm btn-warning text-primary">
                                        View All
                                      </a>
                                    </div>
                                  </div>
                                  <div className="card-body pt-5">
                                    {
                                      this.state.communities.length === 0 ?
                                      <div className="text-center text-gray-700 my-10">
                                        <div className="symbol symbol-40px">
                                          <i className="las la-users fs-3x text-gray-400"></i>
                                        </div>
                                        <span className="d-block mt-4">No communities found</span>
                                      </div>
                                      :
                                      <>
                                        {
                                          this.state.communities.map((data, index) => {
                                            if(index < 5){
                                              return (
                                                <>
                                                  {index > 0 && <div className="separator separator-dashed my-4" />}
                                                  <div className="d-flex flex-stack">
                                                    <div className="symbol symbol-50px me-5">                                            
                                                      <span className="symbol-label bg-light-primary">
                                                        <i class="las la-users fs-2qx text-primary"></i>
                                                      </span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-row-fluid">
                                                      <div className="flex-grow-1 me-2">
                                                        <a href={`/community/group/${data.community_id}`} className="cursor-pointer text-warning text-hover-primary fs-6 fw-bold">
                                                          {data.community_name}
                                                        </a>
                                                        <span className="text-white fw-semibold d-block fs-7">
                                                        {data.industry}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            }
                                          })
                                        }
                                      </>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          {/* post modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="post-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create a Conversation</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Title
                      </label>
                      <Input size="large" required type="text" id="title" placeholder="Enter conversation title" name="title" value={this.state.postForm.title} onChange={(e) => {this.updatePostForm(e,"title");}} />
                      {
                        this.state.postFormErrors.title.length > 0 && 
                        <div className="invalid-feedback">{this.state.postFormErrors.title}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Category
                      </label>
                      <Select size="large"
                        style={{
                          width: "100%",
                        }}
                        value={this.state.postForm.category}
                        onChange={(value) => {this.updatePostForm(value, 'category')}}
                        options={[
                          {
                            value: 'Beauty',
                            label: 'Beauty',
                          },
                          {
                            value: 'Food and Beverage',
                            label: 'Food and Beverage',
                          },
                          {
                            value: 'Hospitality',
                            label: 'Hospitality',
                          },
                          {
                            value: 'Edu Tech',
                            label: 'Edu Tech',
                          },
                          {
                            value: 'Logistics',
                            label: 'Logistics',
                          },
                          {
                            value: 'Business Consulting/Management',
                            label: 'Business Consulting/Management',
                          },
                          {
                            value: 'Transportation',
                            label: 'Transportation',
                          },
                          {
                            value: 'Agriculture',
                            label: 'Agriculture',
                          },
                          {
                            value: 'Fashion',
                            label: 'Fashion',
                          },
                          {
                            value: 'Manufacturing',
                            label: 'Manufacturing',
                          }
                        ]}
                      />
                      {
                        this.state.postFormErrors.category.length > 0 && 
                        <div className="invalid-feedback">{this.state.postFormErrors.category}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Post
                      </label>
                      <TextArea required
                        value={this.state.postForm.post}
                        onChange={(e) => {this.updatePostForm(e,"post");}}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                      {
                        this.state.postFormErrors.post.length > 0 && 
                        <div className="invalid-feedback">{this.state.postFormErrors.post}</div>
                      }
                    </div>
                    {
                      this.state.isRequesting ?
                      <div className="nk-modal-action mt-10 text-end">
                        <button disabled className="btn btn-lg btn-mw btn-primary m-2">
                          <span>
                            Creating...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button disabled className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                      :
                      <div className="nk-modal-action mt-10 text-end">
                        <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => {this.createPostForm()}}>Create</button>
                        <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* alert modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="alert-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-gray-500">
                        <i className="las la-info fs-3qx text-gray-800"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">Sorry, can't perform this action</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">Complete your profile and get access to all features on AWA.</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <a href="/account-settings" className="btn btn-lg btn-mw btn-primary m-2">Complete Profile</a>
                      <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          <div className="modal fade" id="welcome-modal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content rounded">
                <div className="modal-header pb-0 border-0 justify-content-end">
                  <div
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                  >
                    <span className="svg-icon svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.5"
                          x={6}
                          y="17.3137"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-45 6 17.3137)"
                          fill="currentColor"
                        />
                        <rect
                          x="7.41422"
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(45 7.41422 6)"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                  <div className="mb-13 text-center">
                    <h1 className="mb-3">Welcome to AWA Community!</h1>              
                  </div>
                  <div>
                    <p>The AWA community encourages you to start discussions with fellow community 
                      members on topics that are relevant to your industry. This space is designed 
                      for you to share ideas, resources, get feedback, hold a short seminar, 
                      or simply engage with your community of like minded leaders.</p>
                    <p>You can start a conversation by creating a topic of interest such as 
                      “Tips for leading a tech company as a non-technical founder”.  Not all conversations 
                      have to be all about business, if you simply want to get the perspectives of your community 
                      on topics such as “Managing a household and starting a new hobby”, you are welcomed to do just that. 
                      If you don’t have a conversation in mind you can contribute your thoughts or expertise by using the join a conversation button.</p>
                    <p>Lastly, you’re welcome to create and join sub-communities so that your conversations are focused on a particular industry.</p>
                  </div>
                  <div className="text-center mt-15">
                    <button className="btn btn-primary" data-bs-dismiss="modal">
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

		
		
      </Fragment>
    );
  }
}

export default Community;