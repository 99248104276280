import React, { Fragment } from "react";
import classNames from "classnames";
import axios from 'axios';
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import { 
  Input,
  Select,
  Switch,
  Popover,
  Tooltip
} from 'antd';
import 'antd/dist/antd.css';

class InvestorProfile extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userinfo: AuthService.getAuth().user,
      isRequesting: false,
      close: false,
      profileForm: {
        full_name: '',
        username: '',
        profile_pic: '',
        years_as_investor: '',
        role: '',
        company: '',
        company_url: '',
        company_self_bio: '',
        investor_type: '',
        companies_invested: [
          {
            company_name: '',
            company_url: '',
            company_logo: '',
          }
        ],
        industry_interest: '',
        deal_size_range: '',
        geo_interest_area: '',
        accepting_applicants: false,
      },
      errorMessage: '',
      successMessage: '',
    }
  }

  updateProfileInfo(event, name) {
    var profileForm = this.state.profileForm;
    let value = event;

    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });
	}

  isValidUrl = (urlString )=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

  submitProfileForm () {
    let profileFormErrors = {
      full_name: '',
      username: '',
      profile_pic: '',
      years_as_investor: '',
      role: '',
      company: '',
      company_url: '',
      company_self_bio: '',
      investor_type: '',
      companies_invested: '',
      industry_interest: '',
      deal_type: '',
      deal_size_range: '',
      geo_interest_area: '',
      accepting_applicants: ''
    };

    if(this.state.profileForm.full_name.length === 0){
      profileFormErrors.full_name = 'Input required';
      
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        profileFormErrors: profileFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    if(this.state.profileForm.company_url.length > 0 && !this.isValidUrl(this.state.profileForm.company_url)){
      profileFormErrors.company_url = 'Invalid URL';
      
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        profileFormErrors: profileFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    // validate companies invested
    let profileForm = this.state.profileForm;
    if(profileForm.companies_invested.length > 0){
      for(let i = 0; i < profileForm.companies_invested.length; i++){
        if(profileForm.companies_invested[i].company_name.length === 0){
          profileFormErrors.companies_invested = 'One of the companies you invested in is missing a name';
        }

        if(profileForm.companies_invested[i].company_logo.length === 0){
          profileFormErrors.companies_invested = 'One of the companies you invested in is missing a logo';
        }

        if(profileForm.companies_invested[i].company_url.length === 0){
          profileFormErrors.companies_invested = 'One of the companies you invested in is missing a url';
        }else if(!this.isValidUrl(profileForm.companies_invested[i].company_url)){
          profileFormErrors.companies_invested = 'One of the companies you invested in has an invalid url';
        }
      }

      if(profileFormErrors.companies_invested.length > 0){
        this.setState({
          ...this.state,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
          profileFormErrors: profileFormErrors,
        });
  
        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      profileFormErrors: {
        full_name: '',
        username: '',
        profile_pic: '',
        years_as_investor: '',
        role: '',
        company: '',
        company_url: '',
        company_self_bio: '',
        investor_type: '',
        companies_invested: '',
        industry_interest: '',
        deal_type: '',
        deal_size_range: '',
        geo_interest_area: '',
        accepting_applicants: '',
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/update-profile/investor", {
      ...this.state.profileForm
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isRequesting: false,
        successMessage: "Your availability status has been updated successfully."
      })

      window.modalShow('success-modal');

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.modalShow('error-modal');

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    this.setState({
      ...this.state,
      isLoading: false,
      profileForm: {
        full_name: this.props.profileInfo.full_name,
        username: this.props.profileInfo.username,
        profile_pic: this.props.profileInfo.profile_pic,
        years_as_investor: this.props.profileInfo.years_as_investor,
        role: this.props.profileInfo.role,
        company: this.props.profileInfo.company,
        company_url: this.props.profileInfo.company_url,
        company_self_bio: this.props.profileInfo.company_self_bio,
        investor_type: this.props.profileInfo.investor_type,
        companies_invested: this.props.profileInfo.companies_invested?.length > 0 ? this.props.profileInfo.companies_invested : [],
        industry_interest: this.props.profileInfo.industry_interest,
        deal_type: this.props.profileInfo.deal_type,
        deal_size_range: this.props.profileInfo.deal_size_range,
        geo_interest_area: this.props.profileInfo.geo_interest_area,
        accepting_applicants: this.props.profileInfo.accepting_applicants === true ? true : false,
      }
    })
  }

  render(){

    return (
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-xxl">
          <div className="d-flex flex-column flex-lg-row px-lg-20">
            <div className="d-flex flex-column flex-lg-row-auto w-lg-325px mb-8">
              <div className="bg-muted card mb-5 mb-xl-8">
                <div className="card-body pt-15 px-0">
                  <div className="d-flex flex-column text-center mb-9 px-9">
                    <div className="symbol symbol-200px mb-4">                      
                      <img
                        src={this.props.profileInfo.profile_pic}
                        className="avatar"
                        alt="" onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }}
                      />
                    </div>
                    <div className="text-center">                      
                      <span className="text-gray-800 fw-bold text-hover-primary fs-4">
                        {this.props.profileInfo.full_name}
                      </span>
                      <span className="text-gray-600 d-block fw-semibold text-capitalize">
                      {this.props.profileInfo.user_type}
                      </span>
                    </div>
                  </div>

                  <div className="mx-10">
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-user-tie fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Title
                        </span>
                        <span className="text-gray-800 d-block fs-7">{this.props.profileInfo.role}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-landmark fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Company
                        </span>
                        <span className="text-gray-800 d-block fs-7">{this.props.profileInfo.company}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i class="las la-donate fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Investment Type
                        </span>
                        <span className="text-gray-800 d-block fs-7">{this.props.profileInfo.investor_type}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-info fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Bio
                        </span>
                        <span className="text-gray-800 d-block fs-7">
                          {this.props.profileInfo.company_self_bio}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-industry fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Industry(s) of Interest
                        </span>
                        <span className="text-gray-800 d-block fs-7">
                          {this.props.profileInfo.industry_interest}
                        </span>
                      </div>
                    </div>
                    
                    {
                      this.props.owner ?  
                      <Popover placement="right" 
                        open={this.state.close}
                        onOpenChange={(state) => {
                          this.setState({
                            ...this.state,
                            close: state
                          })
                        }}
                        content={<div className="w-200px">
                          <div className="fv-row mb-3">
                            <label className="form-label mb-2 fw-light">
                              Accepting new applicants
                            </label>
                            <Switch className="d-block" onChange={(e) => {this.updateProfileInfo(e, 'accepting_applicants')}} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.profileForm.accepting_applicants} />
                          </div>
                          <hr className="text-gray-400 mb-10"/>
                          <div className="d-flex">
                            {
                              this.state.isRequesting ?
                              <button disabled className="btn btn-sm btn-primary me-2">
                                <span>
                                  Updating...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                              </button>
                              :
                              <button className="btn btn-sm btn-primary me-2" onClick={() => {this.submitProfileForm()}}>Save</button>
                            }
                            
                            <button disabled={this.state.isRequesting} className="btn btn-sm btn-light" onClick={() => {
                              this.setState({
                                ...this.state,
                                close: false
                              })
                            }}>Close</button>
                          </div>
                        </div>} title="Edit Availability" trigger="click">                      
                        <div className="d-flex cursor-pointer align-items-center mb-5">
                          <span className="symbol symbol-20px me-3">
                            <span className="symbol-label bg-white">
                              {
                                this.state.profileForm.accepting_applicants ?
                                <i className="las la-check-circle fs-2x text-primary"></i>
                                :
                                <i className="las la-ban fs-2x"></i>
                              }
                            </span>
                          </span>
                          <div className="flex-grow-1">
                            <span className="text-primary fs-7 fw-bold lh-0">
                              Status
                            </span>
                            <span className="text-gray-800 d-block fs-7">
                              {
                                this.state.profileForm.accepting_applicants ?
                                `Currently accepting new applicants`
                                :
                                `Not accepting new applicants`
                              }
                            </span>
                            <a className="ms-auto text-primary fw-semibold fs-8">Click to update</a>    
                          </div>
                        </div>
                      </Popover>
                      :
                      <div className="d-flex align-items-center mb-5">
                        <span className="symbol symbol-20px me-3">
                          <span className="symbol-label bg-white">
                            {
                              this.props.profileInfo.accepting_applicants ?
                              <i className="las la-check-circle fs-2x text-primary"></i>
                              :
                              <i className="las la-ban fs-2x"></i>

                            }
                          </span>
                        </span>
                        <div className="flex-grow-1">
                          <span className="text-primary fs-7 fw-bold lh-0">
                            Status
                          </span>
                          <span className="text-gray-800 d-block fs-7">
                            {
                              this.props.profileInfo.accepting_applicants ?
                              `Currently accepting new applicants`
                              :
                              `Not accepting new applicants`
                            }
                          </span>
                        </div>
                      </div>
                    }


                  </div>
                  {
                    this.props.owner === true && 
                    <div className="d-flex align-items-center w-100 flex-column px-10 pt-8 border-top">
                      <div className="d-flex justify-content-between w-100 mt-auto mb-2 mx-3">                                    
                        <span className="fw-semibold fs-6 text-gray-400">
                          Profile Completion
                        </span>
                        <span className="fw-bold fs-6">{this.props.profileInfo.profile_completion}%</span>
                      </div>
                      <div className="h-5px mx-3 w-100 bg-light mb-3">
                        <div
                          className="bg-success rounded h-5px"
                          role="progressbar"
                          style={{ width: `${this.props.profileInfo.profile_completion}%` }}
                          aria-valuenow={this.props.profileInfo.profile_completion}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
              {
                this.props.owner === true && this.props.profileInfo.connections.connections.length > 0 && 
                <div className="card mb-5 mb-xl-8">
                  <a href="/my-connections"><div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark fs-5">
                        {this.props.profileInfo.connections.connections.length} {this.props.profileInfo.connections.connections.length > 1 ? `Connections` : `Connection`}
                      </span>
                      
                    </h3>
                  </div>
                  <div className="card-body pt-0">
                    <div className="symbol-group symbol-hover flex-nowrap mb-3">
                      {
                        this.props.profileInfo.connections.connections.map((data, index) => {
                          let avatar = <></>;
                          if(index < 4){
                            avatar = <div key={index} className="symbol symbol-40px symbol-circle">
                                        {
                                          data.profile_pic === null ?
                                          <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} /></Tooltip>
                                          :
                                          <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} /></Tooltip>
                                        } 
                                      </div>
                          }

                          return (
                            <>{avatar}</>
                          )
                        })
                      }
                      {
                        this.props.profileInfo.connections.connections.length > 4 &&
                        <div className="symbol symbol-40px symbol-circle">
                          <Tooltip placement="bottom" title="Click to view all"><span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                            +{this.props.profileInfo.connections.connections.length - 4}
                          </span></Tooltip>
                        </div>
                      }
                    </div>
                    <span className="text-muted mt-1 fw-semibold fs-7">
                      Click to view
                    </span>
                  </div></a>
                </div>
              }
            </div>
            <div className="w-100 flex-lg-row-fluid mx-lg-10">
              <div className="bg-muted card mb-5 mb-xl-10">
                <div className="collapse show">
                  <div className="card-body border-top p-9">
                    <div className="border-bottom-dashed border-gray-200 pb-9">
                      <p className="mb-0 fs-6 text-gray-600">
                        Number of years as an Investor
                      </p>
                      <div className="text-primary fw-bold fs-5">
                      {this.props.profileInfo.years_as_investor} years
                      </div>
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Companies invested in
                      </p>
                      {
                        this.props.profileInfo.companies_invested?.length === 0 ?
                        <div className="text-center text-gray-700 mt-3">
                          <div className="symbol symbol-40px">
                            <i className="las la-industry fs-3x text-gray-400"></i>
                          </div>
                          <span className="d-block">No companies found</span>
                        </div>
                        :
                        <div className="symbol-hover flex-nowrap mt-3">
                          {
                            this.props.profileInfo.companies_invested?.map((data, index) => {
                              return (
                                <Tooltip placement="bottom" title={data.company_name}>
                                  <a href={data.company_url} target="_blank"><div className="symbol symbol-70px symbol-circle me-2">
                                    <img className="avatar" src={data.company_logo} />
                                  </div></a>
                                </Tooltip>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Deal Size
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.deal_size_range}
                      </div>
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Deal Type
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.deal_type}
                      </div>
                    </div>
                    <div className="mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Geographic areas of interest
                      </p>
                      <div className="text-primary fw-bold fs-5">
                      {this.props.profileInfo.geo_interest_area}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* success modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal my-5">
                  <div className="timeline-icon symbol symbol-circle symbol-90px">
                    <div className="symbol-label bg-light-success">
                      <i className="las la-check fs-3qx text-success"></i>
                    </div>
                  </div>
                  <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                  
                  <div className="nk-modal-text">
                    <p className="lead">{this.state.successMessage}</p>
                  </div>
                  <div className="nk-modal-action mt-10">
                    <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}

        {/* error modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal my-5">
                  <div className="timeline-icon symbol symbol-circle symbol-90px">
                    <div className="symbol-label bg-light-danger">
                      <i className="las la-check fs-3qx text-danger"></i>
                    </div>
                  </div>
                  <h3 className="nk-modal-title mt-10">Error</h3>
                  
                  <div className="nk-modal-text">
                    <p className="lead">{this.state.errorMessage}</p>
                  </div>
                  <div className="nk-modal-action mt-10">
                    <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}
      </div>
    )
  }
}

export default InvestorProfile;