import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/ElevatorPitch2_section1.module.scss';
import section1StylesXs from '../scss/ElevatorPitch2Xs_section1.module.scss';
import section2Styles from '../scss/ElevatorPitch2_section2.module.scss';
import section2StylesXs from '../scss/ElevatorPitch2Xs_section2.module.scss';
import styles from '../scss/ElevatorPitch2.module.scss';
import stylesXs from '../scss/ElevatorPitch2Xs.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonTwoModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanTwo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleRedirect(){
    window.location = "./lwa-course-plan";
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })

      this.getSavedProgress();
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
          ...this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  submitEntryXs(){
    this.setState({
      ...this.state,
      isUploading: true,
    })
  
    this.getSavedProgress();

      axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
        ...this.state.planEntries
      }, AuthService.getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
  
        this.setState({
          ...this.state,
          isUploading: false,
          successMessage: "Your answers have been saved successfully"
        })
  
        window.modalShow('success-modal-xs');
  
      }).catch((error) => {
        try{
          let errorResponse = error.response.data;
  
          if(errorResponse.status === 401 || errorResponse.status === 404){
            alert('ha1');
            window.location = "/not-found";
            return;
          }
  
          let errorMessage = 'Error: Could not connect to server';
          if(errorResponse.hasOwnProperty("message")){
            errorMessage = errorResponse.message;
          }
  
          this.setState({
            ...this.state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
  
        window.modalShow('error-modal');
        window.scrollTo({top: 0, behavior: 'smooth'});
        }catch(e){
        window.location = "/server-offline";
        }
      });
}

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Two</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                    <section className={section1Styles.section1}>
                    <div className={section1Styles.flexCol}>
                        
                        <div className={section1Styles.content_box}>
                        <div className={section1Styles.flexRow1}>
                            <h1 className={section1Styles.hero_title}>Elevator Pitch</h1>
                            <div className={section1Styles.flexRow1__spacer} />

                        </div>
                        </div>
                    </div>

                    <div className={section1Styles.flexCol3}>
                        <h1 className={section1Styles.hero_title1}>Elevator Pitch</h1>

                        <div className={section1Styles.content_box1}>
                        <h3 className={section1Styles.subtitle_box}>
                            <div className={section1Styles.subtitle}>
                            <span className={section1Styles.subtitle_span0}>
                                An Elevator Pitch (also referred to as elevator speech) is a short, persuasive synopsis of your
                                background, experience, product, and business. It is called an elevator pitch because it should be SHORT
                                enough to present during a brief elevator ride (i.e., 30 seconds maximum). The purpose of an elevator
                                pitch is to give your audience relevant information about your business and spark interest in it.  
                                <br />  <br />
                                An elevator pitch answers the question:{' '}
                            </span>
                            <span className={section1Styles.subtitle_span1}>
                                So, what does your business do?
                                <br />
                            </span>
                            <span className={section1Styles.subtitle_span2}>
                                <br />
                            </span>
                            <span className={section1Styles.subtitle_span3}>Elevator Pitch Summary</span>
                            <span className={section1Styles.subtitle_span4}>
                                : It is a short and to the point sales message that is so persuasive that once you are done, your
                                audience likes you and your business enough to patronize it or to help with funding it.  <br />
                            </span>
                            </div>
                        </h3>
                        </div>
                    </div>
                    </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                    <div className={section2Styles.flexCol}>
                        <h1 className={section2Styles.hero_title1}>
                        How to Write your Elevator Pitch
                        </h1>

                        <div className={section2Styles.flexCol1}>
                        <h4 className={section2Styles.highlights}>
                            1. Write about what your business does and how you do it.
                            <br />
                            Start with a conversation starter (interesting fact about your business or the industry that grabs
                            attention) THEN add…My company (builds, develops, creates) ____________.(what your company does.
                        </h4>
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q1}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q1");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>

                        <div className={section2Styles.flexCol2}>
                        <h4 className={section2Styles.highlights1}>
                            2. Explain the solutions your business presents and what makes you unique.
                        </h4>
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q2}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q2");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>

                        <div className={section2Styles.flexCol3}>
                        <h4 className={section2Styles.highlights2}>
                            3. Back up your information with data/numbers
                            <br />
                            In the past X (month, years) my company has been able to (deliver, train, sell) Y (products, good, services){' '}
                        </h4>
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q3}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q3");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>

                        <div className={section2Styles.flexCol4}>
                        <h4 className={section2Styles.highlights3}>
                            4. Include an engaging question.  <br />
                            E.g. Does your company have a problem with X?  
                        </h4>
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q4}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q4");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>

                        <h3 className={section2Styles.subtitle1_box}>
                        <div className={section2Styles.subtitle1}>
                            <span className={section2Styles.subtitle1_span0}>
                            Congratulations you have finished creating your initial Elevator pitch! If you are satisfied with what you
                            have written, or would like to edit it, go ahead and make changes and save. <br />
                            </span>
                            <span className={section2Styles.subtitle1_span1}>
                            <br />
                            <br />
                            <br />
                            5. Edit what you have written. Delete the sentences that are too long or unclear. Leave the sentences that
                            sound the way you speak. It is important to keep reading what you have written out loud to “hear” how it
                            sounds.
                            <br />
                            <br />
                            6. Record your pitch and play back to yourself to ensure that you are not repeating yourself, or using
                            pause fillers (umm, hmm etc.)
                            <br />
                            <br />
                            7. Make sure you stay within the 30 seconds timeframe without talking too fast.
                            <br />
                            <br />
                            8. Practice, practice, practice. Ideally, with someone who knows you. Ask for feedback: do you sound
                            natural? Is your elevator pitch conversational and free-flowing?
                            <br />
                            <br />
                            9. After presenting to potential investor, be ready for follow up questions. Have a business card at hand.
                            If the person you spoke with wants to continue the talk later, handing a business card will be much more
                            professional and it will be easier for them to remember to associate you with your business than just
                            giving them your phone number
                            </span>
                        </div>
                        </h3>

                        <div className={section2Styles.flexRow}>
                        <div className={section2Styles.content_box2}>
                            <a className={section2Styles.group} href="./lwa-course-plan">
                            <h4 className={section2Styles.highlights21}>Return</h4>
                            </a>
                        </div>
                        <div className={section2Styles.flexRow__spacer2} />

                        {
                         this.state.isUploading ?
                         <div className={section2Styles.content_box3}>
                            <div className="inner-pre-loader-container">
                            <div className="inner-pre-loader">
                                <div className="text-center">  
                                    <div className="spinner-border mt-4" role="status">    
                                    <span className="sr-only">Loading...</span>  
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        :
                        <button className={section2Styles.content_box3} onClick={() => {this.submitEntry()}}>
                            <h4 className={section2Styles.highlights11}>Save Progress</h4>
                        </button>
                        }

                        <div className={section2Styles.flexRow__spacer} />

                        {this.state.lessonProgress < 18 ? 
                        <button className={section2Styles.content_box2}>
                            <div className={section2Styles.group} data-bs-toggle="modal" data-bs-target="#pitch-modal">
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan/a1fb26393cf82461b7bbd5fb738fec0e.png')}
                              alt="alt text"
                            />
                            <h4 className={section2Styles.highlights21}>Upload Pitch</h4>
                            </div>
                        </button>
                        :
                        <button className={section2Styles.content_box2} onClick={this.handlePick}>
                            <div className={section2Styles.group} >
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan/a1fb26393cf82461b7bbd5fb738fec0e.png')}
                              alt="alt text"
                            />
                            <h4 className={section2Styles.highlights21}>Upload Pitch</h4>
                            </div>
                        </button>
                        }
                        </div>
                    </div>
                    </section>
                  }
                  {
                    <section className={section1StylesXs.section1}>
                      <div className={section1StylesXs.flexCol}>
                        <div className={section1StylesXs.flexCol1}>
                          
                
                          <div className={section1StylesXs.content_box}>
                            <h1 className={section1StylesXs.title}>Elevator Pitch</h1>
                          </div>
                        </div>
                
                        <div className={section1StylesXs.content_box1}>
                          <h5 className={section1StylesXs.highlights}>
                            An Elevator Pitch (also referred to as elevator speech) is a short, persuasive synopsis of your background,
                            experience, product, and business. It is called an elevator pitch because it should be SHORT enough to
                            present during a brief elevator ride (i.e., 30 seconds maximum). The purpose of an elevator pitch is to give
                            your audience relevant information about your business and spark interest in it.  <br />  <br />
                            An elevator pitch answers the question: So, what does your business do?
                            <br />
                            <br />
                            Elevator Pitch Summary: It is a short and to the point sales message that is so persuasive that once you are
                            done, your audience likes you and your business enough to patronize it or to help with funding it.  <br />
                          </h5>
                        </div>
                      </div>
                    </section>
                  }
                  {
                    <section className={section2StylesXs.section2}>
                      <div className={section2StylesXs.group}>
                        <h2 className={section2StylesXs.medium_title}>How to Write your Elevator Pitch </h2>
                
                        <div className={section2StylesXs.flexCol}>
                          <h5 className={section2StylesXs.highlights}>
                            1. Write about what your business does and how you do it.
                            <br />
                            Start with a conversation starter (interesting fact about your business or the industry that grabs
                            attention) THEN add…My company (builds, develops, creates) ____________.(what your company does.
                          </h5>
                          <TextArea required
                            value={this.state.planEntries.manual_entry_q1}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q1");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>
                
                        <div className={section2StylesXs.flexCol1}>
                          <h5 className={section2StylesXs.highlights1}>
                            2. Explain the solutions your business presents and what makes you unique.
                          </h5>
                          <TextArea required
                            value={this.state.planEntries.manual_entry_q2}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q2");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>
                
                        <div className={section2StylesXs.flexCol2}>
                          <h5 className={section2StylesXs.highlights2}>
                            3. Back up your information with data/numbers
                            <br />
                            In the past X (month, years) my company has been able to (deliver, train, sell) Y (products, good, services){' '}
                          </h5>
                          <TextArea required
                            value={this.state.planEntries.manual_entry_q3}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q3");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>
                
                        <div className={section2StylesXs.flexCol3}>
                          <h5 className={section2StylesXs.highlights3}>
                            4. Include an engaging question. <br />
                            E.g. Does your company have a problem with X?
                          </h5>
                          <TextArea required
                            value={this.state.planEntries.manual_entry_q4}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q4");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                        </div>
                
                        <div className={section2StylesXs.flexCol4}>
                          <h5 className={section2StylesXs.highlights4}>
                            Congratulations you have finished creating your initial Elevator pitch! If you are satisfied with what you
                            have written, or would like to edit it, please click HERE to review and/or edit.
                          </h5>
                          <h5 className={section2StylesXs.highlights5}>
                            5. Edit what you have written. Delete the sentences that are too long or unclear. Leave the sentences that
                            sound the way you speak. It is important to keep reading what you have written out loud to “hear” how it
                            sounds.
                            <br />
                            <br />
                            6. Record your pitch and play back to yourself to ensure that you are not repeating yourself, or using pause
                            fillers (umm, hmm etc.)  <br />
                            <br />
                            7. Make sure you stay within the 30 seconds timeframe without talking too fast.
                            <br />
                            <br />
                            8. Practice, practice, practice. Ideally, with someone who knows you. Ask for feedback: do you sound
                            natural? Is your elevator pitch conversational and free-flowing?
                            <br />
                            <br />
                            9. After presenting to potential investor, be ready for follow up questions. Have a business card at hand.
                            If the person you spoke with wants to continue the talk later, handing a business card will be much more
                            professional and it will be easier for them to remember to associate you with your business than just giving
                            them your phone number.
                            <br />
                          </h5>
                        </div>

                        {
                         this.state.isUploading ?
                         <div className={section2StylesXs.group1}>
                            <div className="inner-pre-loader-container">
                            <div className="inner-pre-loader">
                                <div className="text-center">  
                                    <div className="spinner-border mt-4" role="status">    
                                    <span className="sr-only">Loading...</span>  
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        :
                        <button className={section2StylesXs.group1} onClick={() => {this.submitEntryXs()}}>
                          <h4 className={section2StylesXs.highlights11}>Save & Exit</h4>
                        </button>
                        }

                        {this.state.lessonProgress < 18 ? 
                        <button className={section2StylesXs.content_box2} data-bs-toggle="modal" data-bs-target="#pitch-modal">
                        <div className={section2StylesXs.flexRow}>
                          <div className={section2StylesXs.flexRow__item}>
                            <img
                              className={section2StylesXs.image2}
                              src={require('../../assets/courseplan/a1fb26393cf82461b7bbd5fb738fec0e.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2StylesXs.flexRow__spacer} />
                          <h4 className={section2StylesXs.highlights21}>Upload Pitch</h4>
                        </div>
                      </button>
                        :
                        <button className={section2StylesXs.content_box2} onClick={this.handlePick}>
                          <div className={section2StylesXs.flexRow}>
                            <div className={section2StylesXs.flexRow__item}>
                              <img
                                className={section2StylesXs.image2}
                                src={require('../../assets/courseplan/a1fb26393cf82461b7bbd5fb738fec0e.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2StylesXs.flexRow__spacer} />
                            <h4 className={section2StylesXs.highlights21}>Upload Pitch</h4>
                          </div>
                        </button>
                        } 

                      </div>
                    </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal-xs">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" onClick={this.handleRedirect} data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanTwo;