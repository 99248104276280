import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/BAndM_section1.module.scss';
import section2Styles from '../scss/BAndM_section2.module.scss';
import section3Styles from '../scss/BAndM_section3.module.scss';
import section4Styles from '../scss/BAndM_section4.module.scss';
import section5Styles from '../scss/BAndM_section5.module.scss';
import section6Styles from '../scss/BAndM_section6.module.scss';
import section7Styles from '../scss/BAndM_section7.module.scss';
import section8Styles from '../scss/BAndM_section8.module.scss';
import styles from '../scss/BAndM.module.scss';
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanTwo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
	  isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      isRequesting: undefined,
      lessonProgress: '0',
      planEntries: {
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: '',
          manual_entry_q6: '',
          manual_entry_q7: '',
          manual_entry_q8: '',
          manual_entry_q9: '',
          manual_entry_q10: '',
          manual_entry_q11: '',
          manual_entry_q12: '',
          manual_entry_q13: '',
          manual_entry_q14: '',
          manual_entry_q15: '',
          manual_entry_q16: '',
          manual_entry_q17: '',
          manual_entry_q18: '',
          manual_entry_q19: '',
          manual_entry_q20: '',
          manual_entry_q21: '',
          manual_entry_q22: '',
          manual_entry_q23: '',
          manual_entry_q24: ''
      },
      planEntriesErrors: {
        manual_entry_q1: '',
        manual_entry_q2: '',
        manual_entry_q3: '',
        manual_entry_q4: '',
        manual_entry_q5: '',
        manual_entry_q6: '',
        manual_entry_q7: '',
        manual_entry_q8: '',
        manual_entry_q9: '',
        manual_entry_q10: '',
        manual_entry_q11: '',
        manual_entry_q12: '',
        manual_entry_q13: '',
        manual_entry_q14: '',
        manual_entry_q15: '',
        manual_entry_q16: '',
        manual_entry_q17: '',
        manual_entry_q18: '',
        manual_entry_q19: '',
        manual_entry_q20: '',
        manual_entry_q21: '',
        manual_entry_q22: '',
        manual_entry_q23: '',
        manual_entry_q24: ''
      },
      errorMessage: '',
      successMessage: '',
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP4"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5,
          manual_entry_q6: responseInfo.data.manual_entry_q6,
          manual_entry_q7: responseInfo.data.manual_entry_q7,
          manual_entry_q8: responseInfo.data.manual_entry_q8,
          manual_entry_q9: responseInfo.data.manual_entry_q9,
          manual_entry_q10: responseInfo.data.manual_entry_q10,
          manual_entry_q11: responseInfo.data.manual_entry_q11,
          manual_entry_q12: responseInfo.data.manual_entry_q12,
          manual_entry_q13: responseInfo.data.manual_entry_q13,
          manual_entry_q14: responseInfo.data.manual_entry_q14,
          manual_entry_q15: responseInfo.data.manual_entry_q15,
          manual_entry_q16: responseInfo.data.manual_entry_q16,
          manual_entry_q17: responseInfo.data.manual_entry_q17,
          manual_entry_q18: responseInfo.data.manual_entry_q18,
          manual_entry_q19: responseInfo.data.manual_entry_q19,
          manual_entry_q20: responseInfo.data.manual_entry_q20,
          manual_entry_q21: responseInfo.data.manual_entry_q21,
          manual_entry_q22: responseInfo.data.manual_entry_q22,
          manual_entry_q23: responseInfo.data.manual_entry_q23,
          manual_entry_q24: responseInfo.data.manual_entry_q24,
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress(value){
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handleRedirect(){
    window.location = "./lwa-course-plan";
  }

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
			...this.state,
      planEntries: planEntries
    });
	}

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/multiple-save", {
          "lesson_plan": "LP4",
          "input": this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Four</title>
          </MetaTags>
          
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                        <section className={section1Styles.section1}>
                        <div className={section1Styles.flexCol}>

                          <div className={section1Styles.content_box1}>
                            <div className={section1Styles.flexRow1}>
                              <h1 className={section1Styles.hero_title}>Branding and Marketing</h1>
                              <div className={section1Styles.flexRow1__spacer} />
                  
                            </div>
                          </div>
                  
                          <div className={section1Styles.flexCol3}>
                            <h1 className={section1Styles.hero_title1}>Branding</h1>
                  
                            <div className={section1Styles.content_box}>
                              <h3 className={section1Styles.subtitle}>
                                Branding is the process of researching, developing, and applying a distinctive feature or set of features
                                to your business so that consumers can begin to associate your brand with your products or services.
                                <br />
                                According to the International Organization for Standardization (ISO) brand standards, a brand “is an
                                intangible asset” that is intended to create “distinctive images and associations in the minds of
                                stakeholders, thereby generating economic benefit/values.”
                                <br />
                              </h3>
                            </div>
                          </div>
                        </div>
                      </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                    <div className={section2Styles.group}>
                        <div className={section2Styles.content_box2}>
                        <div className={section2Styles.flexCol}>
                            <h2 className={section2Styles.medium_title}>By the end of this session, you should:</h2>
                            <h3 className={section2Styles.subtitle}>
                            1. Know what the following terms mean: branding, brand identity, brand strategy, mission statement, vision
                            statement, value statement, brand positioning statement, brand manual.
                            <br />
                            2. Know how to write an effective mission statement.
                            <br />
                            3. Know the difference between a mission and vision statement.
                            <br />
                            4. Know how to write an effective vision statement.
                            <br />
                            5. Know how to write an effective value(s) statement.
                            <br />
                            6. Know how to write an effective brand positioning statement.
                            <br />
                            7. Know the difference between the mission, value and brand positioning statement.
                            <br />
                            </h3>
                        </div>
                        </div>

                        <div
                        className={section2Styles.image1}
                        style={{ '--src': `url(${require('../../assets/courseplan-four/93f9b76025b2ba893226a842b29cccfc.png')})` }}
                        />
                    </div>
                    </section>
                  }
                  {
                    <section className={section3Styles.section3}>
                    <div className={section3Styles.flexCol}>
                      <div className={section3Styles.flexCol1}>
                        <div className={section3Styles.flexCol2}>
                          <h1 className={section3Styles.hero_title1}>Brand Identity</h1>
                          <h4 className={section3Styles.highlights}>
                            Building brand recognition for your business in the market begins with a strong brand identity. You can
                            create a brand identity with elements such as your company name, your logo, your tag line, as well as the
                            specific visual elements that you employ, e.g. colour scheme, graphics, symbols, shapes, and audio
                            elements: jingles or sound effects. Strong brand identities also consider product names, the look and feel
                            of the package (if any), the pricing strategy and of course, the link to your personal values and your
                            company’s mission.
                            <br />
                            The first step in building a brand is to ask yourself why your business exists in the first place. An
                            understanding of this will allow your business to be aligned with its core purpose and then the brand can
                            communicate that core purpose effectively to others. To build a successful brand, it is important to
                            understand what your business is trying to achieve, how it is to be perceived now (and in the future), as
                            well as how success is measured in your business.  <br />
                            <br />
                            <br />
                          </h4>
                        </div>
              
                        <div className={section3Styles.flexCol3}>
                          <h1 className={section3Styles.hero_title1}>Building your business name </h1>
                          <h4 className={section3Styles.highlights}>
                            To create a strong business name, you need to reflect on the words your business stands for, brainstorm
                            ideas for a business name, and research them to determine which one will be the best fit. Whilst doing so,
                            it is important to ensure your brand name can match your domain name (and other social media sites).
                          </h4>
                        </div>
                      </div>
              
                      <div className={section3Styles.content_box3}>
                        <Input size="large" required type="text" id="manual_entry_q1" placeholder="Please type your Business Name" name="manual_entry_q1" 
                        onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q1");}}
                        value={this.state.planEntries.manual_entry_q1}  />
                        {
                        this.state.planEntriesErrors.manual_entry_q1.length > 0 && 
                        <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q1}</div>
                        }
                      </div>
                    </div>
                  </section>
                  }
                  {
                    <section className={section4Styles.section4}>
                    <div className={section4Styles.flexCol}>
                      <div className={section4Styles.flexCol1}>
                        <h1 className={section4Styles.hero_title1}>Brand Strategy</h1>
                        <h4 className={section4Styles.highlights}>
                          A branding strategy (also known as brand development strategy) is the long-term plan to achieve a series of
                          long-term goals that ultimately result in the identification and preference of your brand by consumers. The
                          elements of brand strategy we will look at here are:
                          <br />
                        </h4>
                      </div>
              
                      <div className={section4Styles.flexCol2}>
                        <div className={section4Styles.flexRow}>
                          <div className={section4Styles.flexRow__item}>
                            <div
                              className={section4Styles.icon}
                              style={{ '--src': `url(${require('../../assets/courseplan-four/ed616a6a9452b41f8a95cb64422247ec.png')})` }}
                            />
                          </div>
                          <div className={section4Styles.flexRow__spacer} />
                          <h3 className={section4Styles.subtitle1_box}>
                            <div className={section4Styles.subtitle1}>
                              <span className={section4Styles.subtitle1_span0}>Mission statement - </span>
                              <span className={section4Styles.subtitle1_span1}>
                                why your company exists, what it aims to do in the short-term
                              </span>
                            </div>
                          </h3>
                        </div>
              
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q2}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q2");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                      </div>
              
                      <div className={section4Styles.flexCol3}>
                        <div className={section4Styles.flexRow}>
                          <div className={section4Styles.flexRow__item1}>
                            <div
                              className={section4Styles.icon}
                              style={{ '--src': `url(${require('../../assets/courseplan-four/ed616a6a9452b41f8a95cb64422247ec.png')})` }}
                            />
                          </div>
                          <div className={section4Styles.flexRow__spacer1} />
                          <h3 className={section4Styles.subtitle1_box}>
                            <div className={section4Styles.subtitle1}>
                              <span className={section4Styles.subtitle1_span0}>Vision statement - </span>
                              <span className={section4Styles.subtitle1_span1}>
                                a long-term view of what the company wants to achieve
                                <br />
                              </span>
                            </div>
                          </h3>
                        </div>
              
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q3}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q3");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                      </div>
              
                      <div className={section4Styles.flexCol4}>
                        <div className={section4Styles.flexRow}>
                          <div className={section4Styles.flexRow__item2}>
                            <div
                              className={section4Styles.icon}
                              style={{ '--src': `url(${require('../../assets/courseplan-four/ed616a6a9452b41f8a95cb64422247ec.png')})` }}
                            />
                          </div>
                          <div className={section4Styles.flexRow__spacer2} />
                          <h3 className={section4Styles.subtitle11_box}>
                            <div className={section4Styles.subtitle11}>
                              <span className={section4Styles.subtitle11_span0}>Value statement - </span>
                              <span className={section4Styles.subtitle11_span1}>
                                what is important to your company, what it prioritizes, and how it conducts itself
                                <br />
                                <br />
                                <br />
                              </span>
                            </div>
                          </h3>
                        </div>
              
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q4}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q4");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />

                      </div>
              
                      <div className={section4Styles.flexCol4}>
                        <div className={section4Styles.flexRow}>
                          <div className={section4Styles.flexRow__item3}>
                            <div
                              className={section4Styles.icon}
                              style={{ '--src': `url(${require('../../assets/courseplan-four/ed616a6a9452b41f8a95cb64422247ec.png')})` }}
                            />
                          </div>
                          <div className={section4Styles.flexRow__spacer3} />
                          <h3 className={section4Styles.subtitle12}>Brand Position statement</h3>
                        </div>
              
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q5}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q5");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                      </div>
                    </div>
                    </section>
                  }
                  {
                    <section className={section5Styles.section5}>
                    <div className={section5Styles.flexCol}>
                      <div className={section5Styles.flexCol1}>
                        <div className={section5Styles.flexCol2}>
                         
                            <div class="accordion" id="accordion1">

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading1">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapse1"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Mission Statement</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapse1" aria-labelledby="heading1" data-bs-parent="#accordion1">
                                    <div class="accordion-body">
                                        
                                        <h4 className={section5Styles.highlights_box}>
                                            <div className={section5Styles.highlights}>
                                            <span className={section5Styles.highlights_span0}>
                                                A mission statement is defined as an action-based statement that declares the purpose of your business
                                                and how it serves its customers.{' '}
                                            </span>
                                            <span className={section5Styles.highlights_span1}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span2}>
                                                Mission statements are important because they define your business’s core values and priorities. It
                                                directs how and where you spend your time and energy. Your mission statement provides clarity behind
                                                the questions of the “what,” the “who,” and the “why,” of your business.
                                            </span>
                                            <span className={section5Styles.highlights_span3}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span4}>
                                                Most mission statements are between one and three sentences, never exceeding 100 words. Keep in mind
                                                that the best mission statements are typically a single succinct sentence.{' '}
                                            </span>
                                            <span className={section5Styles.highlights_span5}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span6}>
                                                There are a few things all good mission statements have in common:
                                            </span>
                                            <span className={section5Styles.highlights_span7}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span8}>• They are specific and direct</span>
                                            <span className={section5Styles.highlights_span9}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span10}>• They are brief</span>
                                            <span className={section5Styles.highlights_span11}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span12}>• They do not contain buzzwords or jargon</span>
                                            <span className={section5Styles.highlights_span13}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span14}>
                                                Building a good business mission statement includes incorporating the following:
                                            </span>
                                            <span className={section5Styles.highlights_span15}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span16}>
                                                Who are customers (and sometimes, where do you operate?)
                                            </span>
                                            <span className={section5Styles.highlights_span17}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span18}>What products or services do you provide?</span>
                                            <span className={section5Styles.highlights_span19}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span20}>
                                                What is the ultimate value/positive outcome of your products/services?
                                            </span>
                                            <span className={section5Styles.highlights_span21}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span22}>How you make it possible?</span>
                                            <span className={section5Styles.highlights_span23}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span24}>Mission statement</span>
                                            <span className={section5Styles.highlights_span25}>
                                                : This is what you do, why you do it, how you do it, and what value you bring.
                                            </span>
                                            <span className={section5Styles.highlights_span26}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span27}>Examples of Mission Statements:</span>
                                            <span className={section5Styles.highlights_span28}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span29}>AWA:</span>
                                            <span className={section5Styles.highlights_span30}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span31}>
                                                GAWE (Ghana Association of Women Entrepreneurs):
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span33}>
                                                “To be the empowering agent to facilitate the development of dynamic women entrepreneurs in Ghana, by
                                                strengthening their entrepreneurial capacities, and support government policies which focus on the
                                                growth of MSMEs in the formal and informal sectors, through the provision of services which includes
                                                training, market information dissemination, economic networking, credit, technology transfer,
                                                promotion of non-traditional exports, advocacy and lobby for policies that will enhance the active
                                                participation of Ghanaian women entrepreneurs in both the national and global economies”.
                                            </span>
                                            <span className={section5Styles.highlights_span34}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span35}>Women for Women in Africa:</span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span36}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span37}>
                                                “To work for the relief of impoverished persons in Kenya, with a particular focus on children and
                                                women from the Kibera slum in Nairobi Kenya, who are in poverty, or are suffering distress,
                                                destitution, sickness or misfortune, regardless of age, sex, ethnic background, religion, political
                                                beliefs or marital status”.
                                            </span>
                                            <span className={section5Styles.highlights_span38}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span39}>Mission versus Vision Statement</span>
                                            <span className={section5Styles.highlights_span40}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span32}>
                                                <br />
                                            </span>
                                            <span className={section5Styles.highlights_span41}>
                                                {
                                                "A mission statement clarifies what the company wants to achieve, who they want to support, and why they want to support them. On the other hand, a vision statement describes where the company wants a community, or the world, to be because of the company's services. Thus, a mission statement is a roadmap for the company's vision statement."
                                                }
                                            </span>
                                            </div>
                                        </h4>
                                    </div>
                                    </div>
                                </div>

                            </div>
              
                        </div>
              
                        <div className={section5Styles.flexCol3}>
                            <div class="accordion" id="accordion2">

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading2">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapse2" 
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Vision Statement</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapse2" aria-labelledby="heading2" data-bs-parent="#accordion2">
                                <div class="accordion-body">
                                    
                                <h4 className={section5Styles.highlights1_box}>
                            <div className={section5Styles.highlights1}>
                              <span className={section5Styles.highlights1_span0}>
                                {' '}
                                A vision statement describes what your company aspires to be, as opposed to what it is now. Vision
                                statements look to your company’s future or what its overarching vision is. The same elements from the
                                mission statement can be included in a vision statement, but they will be described in the future
                                tense.
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span2}>
                                The easiest way to separate the vision statement from the other statements is to take the mission
                                statement and think about what goal this serves. Identify where your company wants to eventually be
                                and how it wants to change the sector is finds itself in (or even society in general).
                              </span>
                              <span className={section5Styles.highlights1_span3}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span4}>
                                When writing a vision statement, consider these questions:
                              </span>
                              <span className={section5Styles.highlights1_span5}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span6}>Where are we going moving forward?</span>
                              <span className={section5Styles.highlights1_span7}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span8}>What do we want to achieve in the future?</span>
                              <span className={section5Styles.highlights1_span9}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span10}>What kind of future society do we envision?</span>
                              <span className={section5Styles.highlights1_span11}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span12}>
                                Here is a quick breakdown of what to do when formalizing your vision statement:
                              </span>
                              <span className={section5Styles.highlights1_span13}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span14}>Project five to ten years into the future.</span>
                              <span className={section5Styles.highlights1_span15}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span16}>Dream big and focus on success.</span>
                              <span className={section5Styles.highlights1_span17}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span18}>
                                Focus on your customer/consumer as your focal point
                              </span>
                              <span className={section5Styles.highlights1_span19}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span20}>Make it flexible enough </span>
                              <span className={section5Styles.highlights1_span21}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span22}>Use clear and concise language.</span>
                              <span className={section5Styles.highlights1_span23}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span24}>Infuse it with passion and make it inspiring.</span>
                              <span className={section5Styles.highlights1_span25}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span26}>Align it with your business values and goals.</span>
                              <span className={section5Styles.highlights1_span27}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span28}>
                                Create a plan to communicate your vision statement to your employees.
                              </span>
                              <span className={section5Styles.highlights1_span29}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span30}>
                                Prepare to commit time and resources to the vision you establish.
                              </span>
                              <span className={section5Styles.highlights1_span31}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span32}>Vision Statement Format:</span>
                              <span className={section5Styles.highlights1_span33}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span1}>
                                <br />
                              </span>
                              <span className={section5Styles.highlights1_span34}>
                                To (provide, give, create, make, etc...) a (healthy, happy, productive, safe, etc...) (world, place,
                                dream, reality, existence, etc...) for (the group of people you want to help).
                              </span>
                            </div>
                          </h4>
                                </div>
                                </div>
                            </div>

                            </div>
              
                          
                        </div>
              
                        <div className={section5Styles.flexCol4}>
                            <div class="accordion" id="accordion3">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading3">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapse3" 
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Value Statement</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapse3" aria-labelledby="heading3" data-bs-parent="#accordion3">
                                        <div class="accordion-body">
                                            
                                            <h4 className={section5Styles.highlights2_box}>
                                                <div className={section5Styles.highlights2}>
                                                <span className={section5Styles.highlights2_span0}>
                                                    A value statement is a message which conveys the values and priorities of your business. The value
                                                    statement lets your external stakeholders (customers) and internal stakeholders (employees) know what
                                                    is important to your business and the kind of culture it has. Value statements can be used both
                                                    internally as a guideline for your business operations and externally as a marketing tool for your
                                                    core audience.
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span2}>
                                                    The value statement should show what YOUR company believes in, i.e., what it prioritizes and deems
                                                    important. Think of it as a set of guidelines which demonstrate the “soul” of your company to whoever
                                                    reads it.
                                                </span>
                                                <span className={section5Styles.highlights2_span3}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span4}>Value Statements that </span>
                                                <span className={section5Styles.highlights2_span5}>DO NOT</span>
                                                <span className={section5Styles.highlights2_span6}> work:</span>
                                                <span className={section5Styles.highlights2_span7}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span8}>
                                                    We are a customer-oriented team that takes pride in authentic and ethical work. With killer teamwork
                                                    our experts will handle your issues quickly and professionally.
                                                </span>
                                                <span className={section5Styles.highlights2_span9}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span10}>
                                                    The value statement example above tells one nothing about what the company is, why they stand out or
                                                    how they conduct themselves differently to any other company. This statement is generic and
                                                    forgettable to potential customers and too vague to serve as a guideline for company’s own internal
                                                    team.
                                                </span>
                                                <span className={section5Styles.highlights2_span11}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span12}>
                                                    To create an effective value statement for your business, do not state anything another company could
                                                    say and carry out in the same way. It is YOUR company value(s) so it must be specific to you (your
                                                    business). If your value statement does not reflect your company, then it will either be ignored or
                                                    easily forgotten.
                                                </span>
                                                <span className={section5Styles.highlights2_span13}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span14}>
                                                    When drafting a values statement, some questions to consider include:
                                                </span>
                                                <span className={section5Styles.highlights2_span15}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span16}>What do we stand for?</span>
                                                <span className={section5Styles.highlights2_span17}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span18}>What behaviors do we value over all else?</span>
                                                <span className={section5Styles.highlights2_span19}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span20}>
                                                    How will we conduct our activities to achieve our mission and vision?
                                                </span>
                                                <span className={section5Styles.highlights2_span21}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span22}>
                                                    How do we treat members of our own organization and community?
                                                </span>
                                                <span className={section5Styles.highlights2_span23}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span24}>Examples:</span>
                                                <span className={section5Styles.highlights2_span25}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span26}>AWA values:</span>
                                                <span className={section5Styles.highlights2_span27}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span28}>African Union (AU) values:</span>
                                                <span className={section5Styles.highlights2_span29}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span30}>
                                                    The values to guide and govern the functioning and operations of the Commission are:{' '}
                                                </span>
                                                <span className={section5Styles.highlights2_span31}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span32}>Respect for diversity and team work; </span>
                                                <span className={section5Styles.highlights2_span33}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span34}>Think Africa above all. </span>
                                                <span className={section5Styles.highlights2_span35}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span36}>Transparency and accountability. </span>
                                                <span className={section5Styles.highlights2_span37}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span38}>Integrity and impartiality. </span>
                                                <span className={section5Styles.highlights2_span39}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span40}>Efficiency and professionalism; and </span>
                                                <span className={section5Styles.highlights2_span41}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights2_span42}>Information and knowledge sharing.</span>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
                        <div className={section5Styles.flexCol5}>
                            <div class="accordion" id="accordion4">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading4">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapse4" 
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Brand Positioning Statement</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapse4" aria-labelledby="heading4" data-bs-parent="#accordion4">
                                        <div class="accordion-body">
                                            <h4 className={section5Styles.highlights3_box}>
                                                <div className={section5Styles.highlights3}>
                                                <span className={section5Styles.highlights3_span0}>
                                                    A brand positioning statement is a statement that highlights the customers’ needs that your
                                                    product/service satisfies and tells them why your product is unique from its competition. Your brand
                                                    positioning statement provides the central focus and direction of your company, and the unique
                                                    reputation you intend to create for your brand. Your brand positioning statement is like a slogan and
                                                    will often be captured in your marketing efforts to appeal to your target consumers and urge them to
                                                    patronize your good/services
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span2}>
                                                    What are the four (4) elements of a positioning statement.  
                                                </span>
                                                <span className={section5Styles.highlights3_span3}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span4}>A good positioning statement:</span>
                                                <span className={section5Styles.highlights3_span5}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span6}>1. Describes who your target consumer is</span>
                                                <span className={section5Styles.highlights3_span7}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span8}>2. Describes what your product is</span>
                                                <span className={section5Styles.highlights3_span9}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span10}>3. Explains why you do what you do</span>
                                                <span className={section5Styles.highlights3_span11}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span12}>
                                                    4. Explains how you fill a need in a different way than your competitors
                                                </span>
                                                <span className={section5Styles.highlights3_span13}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span14}>Template for Creating Positioning Statement </span>
                                                <span className={section5Styles.highlights3_span15}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span16}>1. </span>
                                                <span className={section5Styles.highlights3_span17}>When you want to focus on your competition:</span>
                                                <span className={section5Styles.highlights3_span18}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span19}>
                                                    For target customer/audience (i.e., who your target consumer is) that need audience need that your
                                                    product addresses, name of your product/service is a define product category (what your product is)
                                                    that how does your product solve your customers need in a sentence (why you do what you do). Unlike
                                                    competitor, our product how is your product different from the competition (how you will fill the need
                                                    in a different way than your competitors)
                                                </span>
                                                
                                                <TextArea required
                                                    value={this.state.planEntries.manual_entry_q6}
                                                    onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q6");}}
                                                    autoSize={{ minRows: 10, maxRows: 20 }}
                                                />
                                                <span className={section5Styles.highlights3_span20}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span21}>2. </span>
                                                <span className={section5Styles.highlights3_span22}>When you want to focus on your customers: </span>
                                                <span className={section5Styles.highlights3_span23}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span24}>
                                                    For target customer/audience (who your target consumer is), name of your product/service is the define
                                                    product category what your product is) that will problem your product solves for your customer (why
                                                    you do what you do) so they can benefit your product brings to your customer.( how you will fill the
                                                    need in a different way than your competitors)
                                                </span>
                                                
                                                <TextArea required
                                                    value={this.state.planEntries.manual_entry_q7}
                                                    onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q7");}}
                                                    autoSize={{ minRows: 10, maxRows: 20 }}
                                                />
                                                <span className={section5Styles.highlights3_span25}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span26}>
                                                    A position statement is sometimes confused with mission statements and value propositions, but there
                                                    are a few key differences:
                                                </span>
                                                <span className={section5Styles.highlights3_span27}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span28}>
                                                    1. Positioning Statement: Describes a product and its target consumer and explains how it fills a need
                                                    in a different way than its competitors. Typically created for internal use but may be used externally
                                                    as well.
                                                </span>
                                                <span className={section5Styles.highlights3_span29}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span30}>
                                                    2. Value Proposition: Explains what benefits your product provides to your customers. The value
                                                    proposition is often used as part of the positioning statement.
                                                </span>
                                                <span className={section5Styles.highlights3_span31}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span1}>
                                                    <br />
                                                </span>
                                                <span className={section5Styles.highlights3_span32}>
                                                    3. Mission Statement: External use tagline or statement that identifies your brand strategy.
                                                </span>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
              
                        </div>
                      </div>
              
                      <div className={section5Styles.flexCol6}>
                        <div className={section5Styles.flexCol7}>
                          <h1 className={section5Styles.title}>Brand Manual</h1>
                          <h4 className={section5Styles.highlights4}>
                            A brand manual is where all your company’s brand assets (includes Brand name, Logo, Color palette,
                            Packaging, Slogans or taglines, Songs, Jingles or sounds, Brand guidelines, Mascots) should be kept. This
                            manual should be on hand when preparing any marketing material or communication for your business. Develop
                            a portfolio (hard copy and digital) that includes the draft and finalized versions of your brand assets.
                          </h4>
                        </div>
              
                        <div className={section5Styles.group}>
                          <div className={section5Styles.flexCol8}>
                            <h1 className={section5Styles.title}>
                              Quick Quiz:
                              <br />
                            </h1>
                            <h4 className={section5Styles.highlights5}>
                              <span className={section5Styles.highlights5_span0}>
                                1. What is a brand?
                              <br />
                              <br />
                                
                                <TextArea required
                                    value={this.state.planEntries.manual_entry_q8}
                                    onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q8");}}
                                    autoSize={{ minRows: 10, maxRows: 20 }}
                                />
                              <br />
                              <br />

                                2.List at least 4 elements that make up your brand identity.
                              <br />
                              <br />
                               
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q9}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q9");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />

                            <br />
                            <br />

                                3. What elements appear in a vision statement?
                              <br />
                              <br />
                               
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q10}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q10");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                              <br />
                              <br />

                                4. List at least two (2) things that a poorly written value statement will be.
                                <br />
                                <br />
                              
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q11}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q11");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                              <br />
                              <br />

                                5. What are the four (4) things a brand positioning statement must have
                                <br />
                                <br />
                          
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q12}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q12");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                              <br />
                              <br />

                                6. What is a brand manual?
                                <br />
                                <br />
                           
                        <TextArea required
                            value={this.state.planEntries.manual_entry_q13}
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q13");}}
                            autoSize={{ minRows: 10, maxRows: 20 }}
                        />
                              <br />
                              <br />

                              </span>
                            </h4>
                          </div>
              
                        </div>
                      </div>
                    </div>
                  </section>
                  }
                  {
                     <section className={section6Styles.section6}>
                     <div className={section6Styles.flexCol}>
                       <h1 className={section6Styles.hero_title1}>Marketing</h1>
               
                       <div className={section6Styles.content_box}>
                         <h3 className={section6Styles.subtitle}>
                           The essence of marketing is to shape the perception of the company, brand, and products that you offer in
                           the mind of your customer, from first glance to resulting sale and everything in between. To shape this
                           perception, you need to create a plan, i.e.,  you need to figure out what the unique value that your
                           product/service provides compared to anything else in the market and then pull together a plan that allows
                           you to sell that solution by telling a compelling story about your product/service.
                           <br />
                         </h3>
                       </div>
               
                       <div className={section6Styles.group}>
                         <div className={section6Styles.content_box2}>
                           <div className={section6Styles.flexCol1}>
                             <h2 className={section6Styles.medium_title}>By the end of this session, you should:</h2>
                             <h3 className={section6Styles.subtitle1}>
                               1. Know what the following terms mean: marketing, marketing plan, target market, market segments, unique
                               value proposition, unique selling proposition, SWOT, 7p’s of marketing strategy, market reach.
                               <br />
                               2. Know how to identify and cater to your target market.
                               <br />
                               3. Know how to create a SWOT table.
                               <br />
                               4. Know what the 7 Ps of marketing strategy.
                               <br />
                               5. Know how to calculate Market reach.
                               <br />
                               6. Know the different types of marketing.
                               <br />
                             </h3>
                           </div>
                         </div>
               
                         <div
                           className={section6Styles.image1}
                           style={{ '--src': `url(${require('../../assets/courseplan-four/93f9b76025b2ba893226a842b29cccfc.png')})` }}
                         />
                       </div>
                     </div>
                   </section>
                  }
                  {
                    <section className={section7Styles.section7}>
                    <div className={section7Styles.flexCol}>
                      <div className={section7Styles.flexCol1}>
                        <h1 className={section7Styles.hero_title1}>
                          <br />
                          Marketing Plan
                          <br />
                        </h1>
                        <h4 className={section7Styles.highlights}>
                          Your marketing plan is the advertising strategy that your business will implement to sell its
                          product/service. The marketing plan will help determine who your target market is, how best to reach that
                          target market, at what price point you should sell your product/service, and how your company will measure
                          its efforts.
                          <br />
                          {
                            "An effective way to start building your marketing plan is by revising your unique value proposition. Your unique value proposition (UPV) (also known as unique selling proposition USP), is a clear statement that describes the benefit of what you are offering, how you solve your customer's needs and what distinguishes you from your competition."
                          }
                          <br />A good fit between what customers need and want and the features and benefits of your business
                          offering is the value that you provide (i.e., your unique value proposition or unique selling proposition).
                          This is what forms the strengths and opportunities of your business, and upon which you can build a
                          marketing plan as this is exactly what your customers are willing to pay for.
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol2}>
                        <h1 className={section7Styles.hero_title11}>Target Market</h1>
                        <h4 className={section7Styles.highlights1}>
                          Your target market is the group of people that you have identified as the most likely potential customers
                          for your product/service because of shared characteristics such as their income, age, gender, geographic
                          location, income etc.
                          <br />
                          As a business owner, it is important that you identify your target market as this will help you know to
                          package and advertise your product/service to attract the highest number of your target group.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol3}>
                        <h1 className={section7Styles.hero_title11}>Market Segments </h1>
                        <h4 className={section7Styles.highlights2_box}>
                          <div className={section7Styles.highlights2}>
                            <span className={section7Styles.highlights2_span0}>
                              Market segmentation is the process of dividing your market into smaller groups of people (segments), to
                              identify areas for possible market growth. Through segmentation, marketers can identify the key
                              characteristics that define their target market and direct marketing efforts to their unique needs,
                              interests, and personalities.
                              <br />
                              <br />
                            </span>
                            <span className={section7Styles.highlights2_span1}>
                              What are the Main Target Market Segments?
                              <br />
                            </span>
                          </div>
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol5}>
                        
                        <div class="accordion" id="accordiona">

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headinga">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapsea"
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Demographic</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapsea" aria-labelledby="headinga" data-bs-parent="#accordiona">
                                <div class="accordion-body">
                                <h4 className={section7Styles.highlights3}>
                                These are the main characteristics that define your target market. Everyone can be identified as belonging
                                to a specific age group, income level, gender, occupation, and education level. Typically, this
                                segmentation is best used for business-to-customer (B2C) marketing efforts.
                                <br />
                                <br />
                                </h4>
                                <TextArea required
                                    value={this.state.planEntries.manual_entry_q14}
                                    onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q14");}}
                                    autoSize={{ minRows: 10, maxRows: 20 }}
                                />
                                </div>
                                </div>
                            </div>

                        </div>         
                      </div>
              
                      <div className={section7Styles.flexCol7}>
                        <div class="accordion" id="accordionb">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingb">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapseb"
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Geographic</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapseb" aria-labelledby="headingb" data-bs-parent="#accordionb">
                                    <div class="accordion-body">
                                        <h4 className={section7Styles.highlights3}>
                                            This segment is increasingly relevant in the era of globalization. Regional preferences need to be
                                            considered. 
                                            <br />
                                            <br />
                                        </h4>
                                        <TextArea required
                                            value={this.state.planEntries.manual_entry_q15}
                                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q15");}}
                                            autoSize={{ minRows: 10, maxRows: 20 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
              
                      <div className={section7Styles.flexCol9}>
                        <div class="accordion" id="accordionc">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingc">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapsec"
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Psychographic</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapsec" aria-labelledby="headingc" data-bs-parent="#accordionc">
                                    <div class="accordion-body">
                                        <h4 className={section7Styles.highlights3}>
                                            This segment goes beyond the basics of demographics (i.e., the who is buying) to consider lifestyle,
                                            attitudes, interests, and values (the why they buy something). Typically, this segmentation is as helpful
                                            for B2C and business-to-business (B2B) marketing efforts.
                                            <br />
                                            <br />
                                        </h4>
                                        <TextArea required
                                            value={this.state.planEntries.manual_entry_q16}
                                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q16");}}
                                            autoSize={{ minRows: 10, maxRows: 20 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
              
                      <div className={section7Styles.flexCol10}>
                        <div class="accordion" id="accordiond">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingd">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapsed"
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Behavioral</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapsed" aria-labelledby="headingd" data-bs-parent="#accordiond">
                                    <div class="accordion-body">
                                        <h4 className={section7Styles.highlights3}>
                                            {
                                            "This segment that relies on research into the decisions of a company's current customers and tells marketers how these consumers relate to the business. New products may be introduced based on research into the proven appeal of past products. Typically, this segmentation is as useful for B2C as B2B marketing efforts."
                                            }
                                            <br />
                                            <br />
                                        </h4>
                                <TextArea required
                                    value={this.state.planEntries.manual_entry_q17}
                                    onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q17");}}
                                    autoSize={{ minRows: 10, maxRows: 20 }}
                                />
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
              
                      <div className={section7Styles.flexCol11}>
                        <div class="accordion" id="accordione">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headinge">
                                <button class="accordion-button" type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapsee"
                                aria-expanded="true" 
                                aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Firmographic</h3></button>
                                </h2>
                                <div class="accordion-collapse collapse show" id="collapsee" aria-labelledby="headinge" data-bs-parent="#accordione">
                                    <div class="accordion-body">
                                        <h4 className={section7Styles.highlights3}>
                                            Firmographic segmentation classifies companies and businesses into a set of shared attributes, such as their
                                            industry and number of employees. In effect, firmographics is akin to demographics, except that it focuses
                                            on the characteristics of businesses rather than people. As a result, it is used exclusively for B2B
                                            marketing. <br />
                                            Common attributes to consider for firmographic segmentation include: <br />
                                            Industry <br />
                                            Location
                                            <br />
                                            Size
                                            <br />
                                            Status or Structure
                                            <br />
                                            Performance
                                            <br />
                                            <br />
                                        </h4>
                                <TextArea required
                                    value={this.state.planEntries.manual_entry_q18}
                                    onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q18");}}
                                    autoSize={{ minRows: 10, maxRows: 20 }}
                                />

                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
              
                      <div className={section7Styles.flexCol12}>
                        <h1 className={section7Styles.title}>SWOT Analysis</h1>
                        <h4 className={section7Styles.highlights8}>
                          SWOT, an acronym for Strengths, Weaknesses, Opportunities, and Threats, is a strategic analysis tool that
                          helps you analyze the internal and external factors that can impact your business.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol13}>
                        <h1 className={section7Styles.title}>SWOT Table </h1>
                        <h4 className={section7Styles.highlights9}>
                          {
                            "According to The Wall Street Journal study, the board should be large enough to carry out the board's fiduciary and other duties effectively and efficiently. For many organizations, that means five to seven board members are ideal."
                          }
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexRow3}>
                        <div className={section7Styles.flexRow3__item}>
                          <div className={section7Styles.flexCol14}>
                            <div className={section7Styles.content_box7}>
                              <h4 className={section7Styles.highlights31_box}>
                                <div className={section7Styles.highlights33}>
                                  <span className={section7Styles.highlights31_span0}>Strengths</span>
                                  <span className={section7Styles.highlights31_span1}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights31_span2}>1. What is our competitive advantage?</span>
                                  <span className={section7Styles.highlights31_span3}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights31_span4}>2. What resources do we have?</span>
                                  <span className={section7Styles.highlights31_span5}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights31_span6}>3. What products are doing well? </span>
                                </div>
                              </h4>
                            </div>
              
                            <div className={section7Styles.content_box8}>
                              <h4 className={section7Styles.highlights32_box}>
                                <div className={section7Styles.highlights32}>
                                  <span className={section7Styles.highlights32_span0}>Threats</span>
                                  <span className={section7Styles.highlights32_span1}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights32_span2}>
                                    1. What new regulations threaten operations?
                                  </span>
                                  <span className={section7Styles.highlights32_span3}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights32_span4}>2. What do our competitors do well?</span>
                                  <span className={section7Styles.highlights32_span5}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights32_span6}>
                                    3. What consumer trends threaten business?
                                  </span>
                                </div>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className={section7Styles.flexRow3__item1}>
                          <div className={section7Styles.flexCol14}>
                            <div className={section7Styles.content_box9}>
                              <h4 className={section7Styles.highlights33_box}>
                                <div className={section7Styles.highlights33}>
                                  <span className={section7Styles.highlights33_span0}>Weaknesses</span>
                                  <span className={section7Styles.highlights33_span1}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights33_span2}>1. Where can we improve?</span>
                                  <span className={section7Styles.highlights33_span3}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights33_span4}>2. What products are underperforming?</span>
                                  <span className={section7Styles.highlights33_span5}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights33_span6}>3. Where are we lacking resources?</span>
                                </div>
                              </h4>
                            </div>
              
                            <div className={section7Styles.content_box10}>
                              <h4 className={section7Styles.highlights34_box}>
                                <div className={section7Styles.highlights32}>
                                  <span className={section7Styles.highlights34_span0}>Opportunities</span>
                                  <span className={section7Styles.highlights34_span1}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights34_span2}>
                                    1. What technology can we use to improve operations?
                                  </span>
                                  <span className={section7Styles.highlights34_span3}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights34_span4}>2. Can we expand our core operations?</span>
                                  <span className={section7Styles.highlights34_span5}>
                                    <br />
                                  </span>
                                  <span className={section7Styles.highlights34_span6}>
                                    3. What new market segments can we explore?
                                  </span>
                                </div>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <div className={section7Styles.flexCol15}>
                        <h1 className={section7Styles.title}>How to Use a SWOT Analysis</h1>
                        <h4 className={section7Styles.highlights10}>
                          Internal <br />
                          What occurs within your company serves as a great source of information for the strengths and weaknesses
                          categories of the SWOT analysis. Examples of internal factors include financial and human resources,
                          tangible and intangible (brand name) assets, and operational efficiencies.
                          <br />
                          Potential questions to list internal factors are:
                          <br />
                          - (Strength) What are we doing well?
                          <br />
                          - (Strength) What is our strongest asset?
                          <br />
                          - (Weakness) What are our detractors?
                          <br />
                          - (Weakness) What are our lowest-performing product lines?
                          <br />
                          External <br />
                          What happens outside of the company is equally as important to the success of a company as internal factors.
                          External influences, such as monetary policies, market changes, and access to suppliers, are categories to
                          pull from to create a list of opportunities and weaknesses.
                          <br />
                          Potential questions to list external factors are:
                          <br />
                          - (Opportunity) What trends are evident in the marketplace?
                          <br />
                          - (Opportunity) What demographics are we not targeting?
                          <br />
                          - (Threat) How many competitors exist, and what is their market share?
                          <br />
                          - (Threat) Are there new regulations that potentially could harm our operations or products?
                          <br />
                          {
                            'Consider the internal areas of your business (S&W) for the strengths of you as an entrepreneur, of your product/service, and your market fit. Be candid also about what you need to work on. Carefully consider the external influences on your business (O and T) for the economic, industry, or customer trends, and your personal financial situation. Finally, look at the whole picture. Assess what areas of your business needs attention first and work on those weaknesses. If any changes are needed to minimize or avoid threats, act as soon as possible to effectively serve your target market. When you build your promotion for your business, you will leverage your strengths and take advantage of the opportunities.'
                          }
                          <br />
                          To have an effective marketing strategy, significant research and analysis is needed to understand both your
                          customer and your competition. Understanding these two elements will help you figure out the gaps that exist
                          that your business can fill successfully.{' '}
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol16}>
                        <h1 className={section7Styles.title}>Marketing Mix: The 7ps of Marketing Strategy</h1>
                      </div>
              
                      <div className={section7Styles.flexCol18}>
                        <div className={section7Styles.flexCol19}>
                            <div class="accordion" id="accordionz">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingz">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsez"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Product</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsez" aria-labelledby="headingz" data-bs-parent="#accordionz">
                                        <div class="accordion-body">
                                        <h4 className={section7Styles.highlights11}>
                                            A product is defined as a bundle of attributes (features, functions, benefits, and uses) capable of
                                            exchange or use, usually a mix of tangible and intangible forms. Thus, a product may be an idea, a
                                            physical entity (goods), or a service, or any combination of the three. It exists for the purpose of
                                            exchange in the satisfaction of individual and organizational objectives.
                                            <br />
                                            While the term “products and services” is occasionally used, product is a term that encompasses both goods
                                            and services.
                                            <br />
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
                        <div className={section7Styles.flexCol20}>
                            <div class="accordion" id="accordiony">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingy">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsey"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Price</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsey" aria-labelledby="headingy" data-bs-parent="#accordiony">
                                        <div class="accordion-body">
                                        <h4 className={section7Styles.highlights12}>
                                            The Price is the amount a customer must pay to acquire your product.  <br />
                                            When thinking of Price/Pricing of your product think of the following:  <br />
                                            What value are you providing to your customers? How much will customers pay for that value? Compare that
                                            to your breakeven (the point at which total cost and total revenue are equal, meaning there is no loss or
                                            gain for your business). Remember the brand image you want to create in the marketplace. Low prices may
                                            encourage more customers to buy if the value is still there. High prices are a good match for luxury
                                            products, specialty products, custom work, and products with an exclusive image. Ensure your pricing
                                            models meets the minimum financial metrics and provide the perception in the market that you are looking
                                            for.
                                            <br />
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
                        <div className={section7Styles.flexCol20}>
                            <div class="accordion" id="accordionx">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingx">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsex"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Place (or Distribution)</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsex" aria-labelledby="headingx" data-bs-parent="#accordionx">
                                        <div class="accordion-body">
                                        <h4 className={section7Styles.highlights13}>
                            Distribution refers to the act of conveying your products to your consumers. The term is also used to
                            describe the extent of market coverage for a given product.
                            <br />
                            When thinking of Place think of the following: <br />
                            Where and how are you going to get your product into the hands of your customers? Will you ship direct to
                            their homes, or will they come to a retail store to pick up? Will you partner with retailers or
                            distributors to get your products into the channels or list it on the websites where customers can find
                            it? Selling direct to customers eliminates margin sharing. You get to keep more of the profit, but you
                            will also be responsible for the product from place of manufacture to the end user.
                          </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
                        <div className={section7Styles.flexCol20}>
                            <div class="accordion" id="accordionw">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingw">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsew"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Promotion</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsew" aria-labelledby="headingw" data-bs-parent="#accordionw">
                                        <div class="accordion-body">
                                        <h4 className={section7Styles.highlights14}>
                            Promotion is how you will reach your customers and what you will tell them. It includes tactics that
                            encourage short-term purchase, influence trial and quantity of purchase, and are very measurable in
                            volume, share and profit.  <br />
                            When thinking of Promotion, think of the following:  <br />
                            How will I tell my customer about the features and benefits of my product(s)?
                            <br />
                            Research the best way to reach your customer and who will help you get there. It is important to foster
                            good relationships with your referral network, i.e., those who will spread the message about your product.
                            You can gain inbound traffic to your website with improved SEO (search engine optimization), personal
                            email campaigns, writing a blog, or sponsoring an event. Another way to promote your products is to attend
                            industry events where your customers will be present at. E.g., trade shows, etc. Advertise on the websites
                            that your customers frequent and if there are any key search terms specific to your industry or product
                            make sure you use these in your web marketing. Other examples of promotions include coupons, sweepstakes,
                            rebates, premiums, special packaging, and cause-related marketing.
                            <br />
                            Select your social media tools with your target consumer market in mind. What social media platforms are
                            your customers using and how can you best communicate with them? Using regularly scheduled posts of
                            photos, ideas or advice shared by the right social media platform will have customers directed to your
                            website and desiring your solution. Consider paying for your name/advertisement/content to appear first
                            when the customer searches terms related to your product in Google, sponsoring an ad on LinkedIn, and/or
                            bidding on an ad you created to show up in the sidebar while customers are scrolling Facebook. Online
                            advertising is flexible and dynamic. Generally, you pay when the customer clicks on your ad, link, or
                            content. You can test several ads with different graphics/wording and the analytics will show you which
                            are most successful.
                            <br />
                            Create a few adverts based on your brand image, then test these adverts out. Review your analytics
                            frequently and update your advert content to focus on what is most successful at targeting your ideal
                            customer(s). If you need more techniques to encourage your customers to purchase, consider using sales
                            promotion techniques: gift with purchase, sale price, discount code, referral rate, free trial, contest,
                            partner deal, giveaway, etc. Of course, you always have to ensure that the cost of these promotions are
                            worth gaining the customer in the end, and you are always staying focused on promoting your brand
                            identity.
                            <br />
                            Public relations could begin with a well written article and some high-quality photos about your business
                            and its launch for the web, community newspapers, or industry magazines. Make sure they include contact
                            info like your social media handles and your web domain to drive customer traffic to your business.
                            Realize that everywhere you go and every time you talk about your business that is promotion. Make it
                            count by wearing your logo, investing in a vehicle wrap if you drive in your geographic area, or a well
                            thought out promotional item that you might leave with a potential customer when you get the chance. Any
                            direct contact you have with a customer is personal selling. Think through how you will answer your phone,
                            emails, and customer posts online.
                            <br />
                          </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
                        <div className={section7Styles.flexCol21}>
                            <div class="accordion" id="accordionv">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingv">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsev"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>People</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsev" aria-labelledby="headingv" data-bs-parent="#accordionv">
                                        <div class="accordion-body">
                                        <h4 className={section7Styles.highlights15}>
                            The people who work in your business can make or break the business. It is therefore important to employ
                            the right people (i.e., the most competent) to support your business. You need to offer your employees the
                            best remuneration your business can afford for the work that they do, and you need to retain your
                            employees who have proven themselves and are loyal to the company.  You need to have an excellent customer
                            service personnel or Department that provides support with clearly known expectations, such as hours of
                            operation and average response time. Effective and efficient customer service is key to maintaining a high
                            level of customer satisfaction.{' '}
                          </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
              
                        <div className={section7Styles.flexCol21}>
                            <div class="accordion" id="accordionu">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingu">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapseu"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Process</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapseu" aria-labelledby="headingu" data-bs-parent="#accordionu">
                                        <div class="accordion-body">
                          <h4 className={section7Styles.highlights16}>
                            Effective, efficient, accurate and user-friendly business processes for ordering, delivery, IT and
                            production, and communication are important for your business. Your business systems should allow you to
                            measure everything, and this information should be readily available.
                            <br />
                          </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>              
                        </div>
              
                        <div className={section7Styles.flexCol21}>
                            <div class="accordion" id="accordiont">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingt">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapset"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Physical Evidence/Packaging</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapset" aria-labelledby="headingt" data-bs-parent="#accordiont">
                                        <div class="accordion-body">
                                            <h4 className={section7Styles.highlights17}>
                                                This refers to the way your product, service, and everything about your company, appears from the outside.
                                                 Decisions need to be made about the size, shape, color, material, UPC bar code, and label of the
                                                packaging.  This should be customer tested and updated as needed.  It should fall in line with your other
                                                product offerings as well.  Packaging involves the visual layout, practical setup, and when needed for
                                                products, clear and precise installation instructions.
                                                <br />
                                                Physical evidence also refers to your business space, so ensure that your brand personality is pervasive
                                                throughout your company space. Visible signage is important in the business space and your website should
                                                be modern and classy and user friendly.  Ensure that your intellectual property (IP) is adequately
                                                protected. For most businesses, ownership of the rights to a brand name, slogans, designs, content,
                                                inventions and so forth are critically important to the success and value of the business and getting it
                                                wrong and or not securing these can be a very expensive mistake.
                                                <br />
                                                <br />
                                                <br />
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                      </div>
              
                      <div className={section7Styles.flexCol22}>
                        <h1 className={section7Styles.title}>Channel Strategy</h1>
                        <h4 className={section7Styles.highlights18}>
                          Startup businesses must carefully consider where they will reach their customers. This forms their channel
                          strategy. To gain that connection, methods include online; social media and websites, and offline; events,
                          tradeshows, or community activities. Select several channels strategically to meet your customers where they
                          are, while making sure that you are not spreading yourself too thin. You need to determine how you will use
                          the channels you have selected and how you will measure success in your business.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol23}>
                        <h1 className={section7Styles.title}>Market Reach</h1>
                        <h4 className={section7Styles.highlights19}>
                          Market reach is one way of measuring success; estimate the number of potential consumers your channel,
                          specific campaign or advertising will connect with.  <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol24}>
                        <h1 className={section7Styles.title}>
                          Calculating Marketing Reach
                          <br />
                        </h1>
                        <h4 className={section7Styles.highlights20_box}>
                          <div className={section7Styles.highlights20}>
                            <span className={section7Styles.highlights20_span0}>The basic formula for calculating reach is</span>
                            <span className={section7Styles.highlights20_span1}> impressions divided by frequency</span>
                            <span className={section7Styles.highlights20_span2}>
                              {' '}
                              (reach = impressions/frequency).
                              <br />
                              <br />
                              <br />
                            </span>
                          </div>
                        </h4>
                      </div>
              
                      <div className={section7Styles.flexCol23}>
                        <h1 className={section7Styles.title}>Types of Marketing</h1>
              
                      </div>
              
                      <div className={section7Styles.flexCol25}>
                            <div class="accordion" id="accordionm">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingm">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsem"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Search Engine Optimization</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsem" aria-labelledby="headingm" data-bs-parent="#accordionm">
                                        <div class="accordion-body">
                                            <h4 className={section7Styles.highlights21_box}>
                                            <div className={section7Styles.highlights21}>
                                                <span className={section7Styles.highlights21_span0}>
                                                Search engine optimization (SEO) is the process of developing a marketing/technical plan to improve
                                                visibility within one or more search engines. Typically, this consists of two elements.
                                                </span>
                                                <span className={section7Styles.highlights21_span1}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span2}>
                                                On a technical side, SEO refers to ensuring that a website can be indexed properly by the major search
                                                engines and includes the use of the proper keywords, content, code, and links.
                                                </span>
                                                <span className={section7Styles.highlights21_span3}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span4}>
                                                On the marketing side, SEO refers to the process of targeting specific keywords where the site should
                                                “win” in searches. This can be done by modifying a website to score well in the algorithms search
                                                engines use to determine rank, or by purchasing placement with individual keywords. Often, SEO programs
                                                are a blend of several elements and strategies.{' '}
                                                </span>
                                                <span className={section7Styles.highlights21_span5}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span6}>
                                                Here are six steps to help you get results with SEO:
                                                </span>
                                                <span className={section7Styles.highlights21_span7}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span8}>
                                                1. Set Your Organic Search Baseline - Organic search, also known as natural search, refers to unpaid
                                                search results. The results of these searches are displayed solely based on the quality and content of
                                                the page.
                                                </span>
                                                <span className={section7Styles.highlights21_span9}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span10}>
                                                2. Research Keywords and Relevant Search Queries to know how to frame your content more effectively.
                                                </span>
                                                <span className={section7Styles.highlights21_span11}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span12}>
                                                3. Publish Optimized Content - Content optimization is the process of making sure that content for your
                                                online piece is written in a way that it can reach the largest possible target audience. The process of
                                                optimizing content should include making sure associated keywords are present, adding meta and title
                                                tags, and relevant links.
                                                </span>
                                                <span className={section7Styles.highlights21_span13}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span14}>
                                                {
                                                    "4. Analyze Your Backlinks - Backlinks are links on other websites that link to a page on your website. Also referred to as inbound links, backlinks represent another website's traffic coming to your site. The quality and quantity of your backlinks can help you rank higher in search engines."
                                                }
                                                </span>
                                                <span className={section7Styles.highlights21_span15}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span16}>
                                                5. Explore technical issues as soon as they develop or are identified.
                                                </span>
                                                <span className={section7Styles.highlights21_span17}>
                                                <br />
                                                </span>
                                                <span className={section7Styles.highlights21_span18}>
                                                6. Stay Informed of trends and key issues in your field/business so you can update your keywords and
                                                terms to take advantage of trends.
                                                </span>
                                            </div>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                      </div>
              
                      <div className={section7Styles.flexCol26}>
                            <div class="accordion" id="accordionn">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingn">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapsen"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Content Marketing</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapsen" aria-labelledby="headingn" data-bs-parent="#accordionn">
                                        <div class="accordion-body">
                                            <h4 className={section7Styles.highlights22}>
                                            Content Marketing is a marketing strategy used to attract, engage, and retain a clearly defined audience
                                            segment by creating and sharing valuable, relevant articles, videos, podcasts, and other media consistently
                                            with the objective of driving profitable customer action. This approach establishes your business’s
                                            expertise, promotes awareness of your brand, and keeps your business in mind when it is time to buy what you
                                            sell.
                                            <br />
                                            Who does not like a good story especially when it is told well and consistently? Stories are easy to
                                            remember so use them effectively to create emotional bonding with your consumers.{' '}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                      </div>
              
                      <div className={section7Styles.flexCol26}>
                            <div class="accordion" id="accordiono">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingo">
                                    <button class="accordion-button" type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapseo"
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"><h3 className={section5Styles.subtitlemine}>Influencer Marketing</h3></button>
                                    </h2>
                                    <div class="accordion-collapse collapse show" id="collapseo" aria-labelledby="headingo" data-bs-parent="#accordiono">
                                        <div class="accordion-body">
                                            <h4 className={section7Styles.highlights23}>
                                            Influencer marketing is a type of social media marketing that leverages endorsements and product mentions
                                            from influencers, i.e., individuals who have a dedicated social media following or influence over your
                                            potential buyers.
                                            <br />
                                            <br />
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                      </div>
                    </div>
                  </section>
                  }
                  {
                    <section className={section8Styles.section8}>
                    <div className={section8Styles.flexCol}>
                      <div className={section8Styles.flexCol1}>
                        <h1 className={section8Styles.title}>
                          Quick Quiz:
                          <br />
                        </h1>
                        <h3 className={section8Styles.subtitle3_box}>
                          <div className={section8Styles.subtitle3}>
                            <span className={section8Styles.subtitle3_span0}>
                              1. What is a brand?
                              <br />
                              <br />
                            </span>
                            <Input size="large" required type="text" id="manual_entry_q19" placeholder="" name="manual_entry_q19" 
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q19");}}
                            value={this.state.planEntries.manual_entry_q19}  />
                            {
                            this.state.planEntriesErrors.manual_entry_q19.length > 0 && 
                            <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q19}</div>
                            }
                              <br />
                              <br />

                            <span className={section8Styles.subtitle3_span4}>
                              2. What are the elements that make up your brand identity.
                              <br />
                              <br />
                            </span>
                            <Input size="large" required type="text" id="manual_entry_q20" placeholder="" name="manual_entry_q20" 
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q20");}}
                            value={this.state.planEntries.manual_entry_q20}  />
                            {
                            this.state.planEntriesErrors.manual_entry_q20.length > 0 && 
                            <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q20}</div>
                            }
                              <br />
                              <br />

                            <span className={section8Styles.subtitle3_span4}>
                              3. What is a vision statement?
                              <br />
                              <br />
                            </span>
                            <Input size="large" required type="text" id="manual_entry_q21" placeholder="" name="manual_entry_q21" 
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q21");}} 
                            value={this.state.planEntries.manual_entry_q21} />
                            {
                            this.state.planEntriesErrors.manual_entry_q21.length > 0 && 
                            <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q21}</div>
                            }
                              <br />
                              <br />

                            <span className={section8Styles.subtitle3_span9}>
                              4. What are the two (2) things that a poorly written value statement will be.
                              <br />
                              <br />
                            </span>
                            <Input size="large" required type="text" id="manual_entry_q22" placeholder="" name="manual_entry_q22" 
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q22");}}
                            value={this.state.planEntries.manual_entry_q22}  />
                            {
                            this.state.planEntriesErrors.manual_entry_q22.length > 0 && 
                            <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q22}</div>
                            }
                              <br />
                              <br />

                            <span className={section8Styles.subtitle3_span12}>
                              5. What must a brand positioning statement have
                              <br />
                              <br />
                            </span>
                            <Input size="large" required type="text" id="manual_entry_q23" placeholder="" name="manual_entry_q23" 
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q23");}}
                            value={this.state.planEntries.manual_entry_q23}  />
                            {
                            this.state.planEntriesErrors.manual_entry_q23.length > 0 && 
                            <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q23}</div>
                            }
                              <br />
                              <br />

                            <span className={section8Styles.subtitle3_span16}>
                              6. What is a brand manual?
                              <br />
                              <br />
                            </span>
                            <Input size="large" required type="text" id="manual_entry_q24" placeholder="" name="manual_entry_q24" 
                            onChange={(e) => {this.updatePlanEntries(e,"manual_entry_q24");}}
                            value={this.state.planEntries.manual_entry_q24}  />
                            {
                            this.state.planEntriesErrors.manual_entry_q24.length > 0 && 
                            <div className="invalid-feedback">{this.state.planEntriesErrors.manual_entry_q24}</div>
                            }
                          </div>
                        </h3>
                      </div>
              
                      <div className={section8Styles.content_box11}>
                        <div className={section8Styles.flexCol3}>
                          <h1 className={section8Styles.title1_box}>
                            <div className={section8Styles.title1}>
                              <span className={section8Styles.title1_span0}>
                                You have completed the Lesson on <br />
                              </span>
                              <span className={section8Styles.title1_span1}>
                                Branding and Marketing
                                <br />
                              </span>
                            </div>
                          </h1>
              
                          {
                         this.state.isUploading ?
                         <div className={section8Styles.box}>
                            <div className={section8Styles.group}>
                            <div className="inner-pre-loader-container">
                            <div className="inner-pre-loader">
                                <div className="text-center">  
                                    <div className="spinner-border mt-4" role="status">    
                                    <span className="sr-only">Loading...</span>  
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        :
                          <button className={section8Styles.box} onClick={() => {this.submitEntry()}}>
                            <div className={section8Styles.group}>
                              <h3 className={section8Styles.subtitle2}>Save and Exit</h3>
                            </div>
                          </button>
                        }

                        </div>
                      </div>
                    </div>
                  </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" onClick={this.handleRedirect} data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanTwo;