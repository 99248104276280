import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
class Header extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
      isLoadingNotifications: true,
      notifications: []
    }
  }

  getUserProfile () {

    axios.post(CONSTANTS.API_BASE_URL + "/get-my-profile-info", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.full_name = responseInfo.data.full_name;
      userObj.profile_pic = responseInfo.data.profile_pic;
      reactLocalStorage.setObject('userObj', userObj);
      let userInfo = userObj;
      
      this.setState({
        ...this.state,
        userInfo: userInfo
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;
        console.log(error)
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
    });
  }

  getNotifications () {

    axios.post(CONSTANTS.API_BASE_URL + "/get-notifications", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      this.setState({
        ...this.state,
        isLoadingNotifications: false,
        notifications: responseInfo.notifications
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoadingNotifications: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    this.getUserProfile();

    setTimeout(() => {
      this.getNotifications(); 
    }, 10000);

    setInterval(() => {
      this.getNotifications();      
    }, 120000);
  }

  render(){

    return (
      <div id="kt_app_header" className="app-header">
        <div
          className="app-container container-fluid d-flex align-items-stretch justify-content-between"
          id="kt_app_header_container"
        >
          <div
            className="d-flex align-items-center d-lg-none ms-n2 me-2"
            title="Show sidebar menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-35px h-35px"
              id="kt_app_sidebar_mobile_toggle"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            
          </div>
          <div
            className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
            id="kt_app_header_wrapper"
          >
            <div
              className="app-header-menu app-header-mobile-drawer align-items-end"
              data-kt-drawer="true"
              data-kt-drawer-name="app-header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="250px"
              data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_app_header_menu_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
              data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
            >
               <div className="app-navbar-item ms-1 ms-lg-3 pb-5">
                <div
                  className={classNames("btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px", {"btn-light pulse pulse-primary": this.state.notifications.length > 0})}
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-start"
                >
                  <i className="las la-bell fs-2x"></i>
                  {
                    this.state.notifications.length > 0 &&
                    <>
                      <span className="pulse-ring"></span>
                      <span className="bullet bullet-dot bg-primary h-6px w-6px position-absolute translate-middle top-0 start-50"></span>
                    </>
                  }
                </div>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column w-100 w-sm-350px"
                  data-kt-menu="true"
                >
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Notification</div>
                    </div>
                    <div className="card-body py-5">
                      <div className="mh-450px scroll-y me-n5 pe-5">
                        {
                          this.state.isLoadingNotifications?
                          <div className="inner-pre-loader-container">
                            <div className="inner-pre-loader py-20">
                              <div className="text-center">  
                                <div className="spinner-border mt-4" role="status">    
                                  <span className="sr-only">Loading...</span>  
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <>
                            {
                              this.state.notifications.length === 0 ?
                              <div className="text-center text-gray-700 my-10">
                                <div className="symbol symbol-40px">
                                  <i className="las la-bell-slash fs-3x text-gray-400"></i>
                                </div>
                                <span className="d-block mt-4">No new notification</span>
                              </div>
                              :
                              <>
                                {
                                  this.state.notifications.map((data, index) => {
                                    if(index < 6){
                                      return (
                                        <div key={index} className="d-flex flex-stack py-4" onClick={() => {
                                          if(data.type === 'post'){
                                            window.location = `${data.url}&n=${data.notification_id}`
                                          }else if(data.type === 'connection'){
                                            window.location = `${data.url}?n=${data.notification_id}`
                                          }
                                          
                                        }}>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-35px me-4">
                                              <span className="symbol-label bg-light-primary">
                                                {
                                                  data.type == "connection" &&
                                                  <i className="las la-user-friends fs-2 text-primary"></i>
                                                }
  
                                                {
                                                  data.type == "post" &&
                                                  <i className="las la-comment fs-2 text-primary"></i>
                                                }
                                              </span>
                                            </div>
                                            <div className="mb-0 me-2">
                                              <a className="fs-6 text-gray-800 text-hover-primary fw-bold">
                                                {data.message}
                                              </a>
                                              <div className="text-gray-400 fs-7">
                                                {moment(data.date, 'YYYY-MM-DD H:mm:ss').calendar({
                                                  sameDay: '[Today] h:mm a',
                                                  nextDay: '[Tomorrow] h:mm a',
                                                  nextWeek: 'dddd',
                                                  lastDay: '[Yesterday] h:mm a',
                                                  lastWeek: '[Last] dddd h:mm a',
                                                  sameElse: 'Do MMM YYYY hh:mm a'
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                }
                                <div className="py-3 text-center border-top">
                                  <a
                                    href="/notifications"
                                    className="btn btn-color-gray-600 btn-active-color-primary"
                                  >
                                    View All
                                  </a>
                                </div>

                              </>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="app-navbar-item ms-1 ms-lg-3 pb-5"
                id="kt_header_user_menu_toggle"
              >
                <div
                  className="cursor-pointer symbol symbol-35px symbol-md-40px"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-start"
                >
                  {
                    this.state.userInfo.profile_pic === null ?
                    <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.userInfo.full_name} />
                    :
                    <img className="avatar" src={this.state.userInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userInfo.full_name} />
                  }
                  
                </div>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        {
                          this.state.userInfo.profile_pic === null ?
                          <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.userInfo.full_name} />
                          :
                          <img className="avatar" src={this.state.userInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userInfo.full_name} />
                        }
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                          {this.state.userInfo.full_name}
                        </div>
                        <a href="#"
                          className="fw-semibold text-capitalize text-muted text-hover-primary fs-7">
                          {this.state.userInfo.user_type}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="separator my-2" />
                  <div className="menu-item px-5">
                    <a href="/my-profile" className="menu-link px-5">
                      My Profile
                    </a>
                  </div>
                  <div
                    className="menu-item px-5"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-placement="right"
                    data-kt-menu-offset="-15px, 0"
                  >
                    <a href="#" className="menu-link px-5">
                      <span className="menu-title position-relative">
                        Language
                        <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                          English
                          <img
                            className="w-15px h-15px rounded-1 ms-2"
                            src="/assets/media/flags/united-states.svg"
                            alt=""
                          />
                        </span>
                      </span>
                    </a>
                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                      <div className="menu-item px-3">
                        <a
                          href="../../account-settings"
                          className="menu-link d-flex px-5 active"
                        >
                          <span className="symbol symbol-20px me-4">
                            <img
                              className="rounded-1"
                              src="/assets/media/flags/united-states.svg"
                              alt=""
                            />
                          </span>
                          English
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          href="../../account-settings"
                          className="menu-link d-flex px-5"
                        >
                          <span className="symbol symbol-20px me-4">
                            <img
                              className="rounded-1"
                              src="/assets/media/flags/france.svg"
                              alt=""
                            />
                          </span>
                          French
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="menu-item px-5 my-1">
                    <a href="account-settings" className="menu-link px-5">
                      Account Settings
                    </a>
                  </div>
                  <div className="menu-item px-5">
                    <a
                      href="/logout"
                      className="menu-link px-5"
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <h1 class="d-flex flex-column align-self-center text-dark fw-bolder d-none d-md-block">
							<span class="text-white"><span className="">Welcome</span> to <span className="text-warning">the Community</span></span>
						</h1>
            <div className="app-navbar flex-shrink-0">
              

              <div
                className="app-navbar-item ms-1 ms-lg-3 d-none d-md-block"
                id="kt_header_user_menu_toggle"
              >
              <img alt="Logo" src="/assets/media/logos/logo-white.png" className="h-45px app-sidebar-logo-default" /> 

              </div>
              <div
                className="app-navbar-item ms-1 ms-lg-3 d-block mt-2 d-md-none"
                id="kt_header_user_menu_toggle"
              >
              <img alt="Logo" src="/assets/media/logos/logo.png" className="h-45px app-sidebar-logo-default" /> 

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Header;