import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import EmailVerification from './components/auth/EmailVerification';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import Onboarding from './components/client/Onboarding';
import Logout from './components/auth/Logout';
import Home from './components/client/Home';
import MyProfile from './components/client/MyProfile';
import AccountSettings from './components/client/AccountSettings';
import LearnWithAWA from './components/client/LearnWithAWA';
import Accounting from './components/client/Accounting';
import Community from './components/client/Community';
import JobBoard from './components/client/JobBoard';
import CommunityFund from './components/client/CommunityFund';
import Grants from './components/client/Grants';
import LWACoursePlan from './components/client/LWACoursePlan';
import MyConnections from './components/client/MyConnections';
import Notifications from './components/client/Notifications';
import PostInfo from './components/client/PostInfo';
import BuiltDashboard from './components/client/BuiltDashboard';
import BuiltBusiness from './components/client/BuiltBusiness';
import LessonPlanTwo from './components/client/LessonPlanTwo';
import LessonPlanThree from './components/client/LessonPlanThree';
import LessonPlanFour from './components/client/LessonPlanFour';
import LessonPlanSix from './components/client/LessonPlanSix';
import LessonPlanSeven from './components/client/LessonPlanSeven';
import LessonPlanNine from './components/client/LessonPlanNine';
import LessonPlanTen from './components/client/LessonPlanTen';
import People from './components/client/People';
import CommunityGroups from './components/client/CommunityGroups';
import CommunityInfo from './components/client/CommunityInfo';
import Chat from './components/client/Chat';
import LWAPlanTwo from './components/client/LWAPlanTwo';
import LWAPlanThree from './components/client/LWAPlanThree';
import IdeaLessonOne from './components/client/IdeaLessonOne';
import IdeaLessonTwo from './components/client/IdeaLessonTwo';
import IdeaLessonThree from './components/client/IdeaLessonThree';

class AppRoutes extends React.Component {
 
  render(){
    return (
      <Router>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Login} />
          <Route exact path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
          <Route exact path={`${process.env.PUBLIC_URL + "/register"}`} component={Register} />
          <Route exact path={`${process.env.PUBLIC_URL + "/forgot-password"}`} component={ForgotPassword} />
          <Route exact path={`${process.env.PUBLIC_URL + "/email-verification/"}`} component={EmailVerification} />
          <Route exact path={`${process.env.PUBLIC_URL + "/email-verification/:token"}`} component={EmailVerification} />
          <Route exact path={`${process.env.PUBLIC_URL + "/reset-password/:token"}`} component={ResetPassword} />
          <Route exact path={`${process.env.PUBLIC_URL + "/onboarding"}`} component={Onboarding} />
          <Route exact path={`${process.env.PUBLIC_URL + "/home"}`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL + "/post"}`} component={PostInfo} />
          <Route exact path={`${process.env.PUBLIC_URL + "/my-profile"}`} component={MyProfile} />
          <Route exact path={`${process.env.PUBLIC_URL + "/my-connections"}`} component={MyConnections} />
          <Route exact path={`${process.env.PUBLIC_URL + "/notifications"}`} component={Notifications} />
          <Route exact path={`${process.env.PUBLIC_URL + "/account-settings"}`} component={AccountSettings} />
          <Route exact path={`${process.env.PUBLIC_URL + "/learn-with-awa"}`} component={LearnWithAWA} />
          <Route exact path={`${process.env.PUBLIC_URL + "/accounting"}`} component={Accounting} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community"}`} component={Community} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community/group/:id"}`} component={CommunityInfo} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community/groups"}`} component={CommunityGroups} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community/people"}`} component={People} />
          <Route exact path={`${process.env.PUBLIC_URL + "/job"}`} component={JobBoard} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community/info"}`} component={Community} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community/topic"}`} component={Community} />
          <Route exact path={`${process.env.PUBLIC_URL + "/community-fund"}`} component={CommunityFund} />
          <Route exact path={`${process.env.PUBLIC_URL + "/grants"}`} component={Grants} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lwa-course-plan"}`} component={LWACoursePlan} />
          <Route exact path={`${process.env.PUBLIC_URL + "/built-dashboard"}`} component={BuiltDashboard} />
          <Route exact path={`${process.env.PUBLIC_URL + "/built-business"}`} component={BuiltBusiness} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-two"}`} component={LessonPlanTwo} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-three"}`} component={LessonPlanThree} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-four"}`} component={LessonPlanFour} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-six"}`} component={LessonPlanSix} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-seven"}`} component={LessonPlanSeven} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-nine"}`} component={LessonPlanNine} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lesson-ten"}`} component={LessonPlanTen} />
          <Route exact path={`${process.env.PUBLIC_URL + "/messages"}`} component={Chat} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lwa-plan-two"}`} component={LWAPlanTwo} />
          <Route exact path={`${process.env.PUBLIC_URL + "/lwa-plan-three"}`} component={LWAPlanThree} />
          <Route exact path={`${process.env.PUBLIC_URL + "/idea-one"}`} component={IdeaLessonOne} />
          <Route exact path={`${process.env.PUBLIC_URL + "/idea-two"}`} component={IdeaLessonTwo} />
          <Route exact path={`${process.env.PUBLIC_URL + "/idea-three"}`} component={IdeaLessonThree} />

          <Route exact path={`${process.env.PUBLIC_URL + "/logout"}`} component={Logout} />
        </Switch>
      </Router>
    )
  }
}

export default AppRoutes;