import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/TwoLessonOne_section1.module.scss';
import section2Styles from '../scss/TwoLessonOne_section2.module.scss';
import styles from '../scss/PitchDeck.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanThree extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
          ...this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Three</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                    <section className={section1Styles.section1}>
                         
                 
                         <div className={section1Styles.content_box}>
                           <div className={section1Styles.flexRow1}>
                             <h1 className={section1Styles.hero_title}>AWA Validating your business idea</h1>
                             <div className={section1Styles.flexRow1__spacer} />
                 
                           </div>
                         </div>
                 
                       <div className={section1Styles.flexCol3}>
                         <h1 className={section1Styles.hero_title1}>Validating your business Idea:</h1>
                 
                         <div className={section1Styles.content_box1}>
                           <h3 className={section1Styles.subtitle_box}>
                             <span className={section1Styles.subtitle}>
                               <span className={section1Styles.subtitle_span0}>Newsflash</span>
                               <span className={section1Styles.subtitle_span1}>
                                 : <br />
                                 Most business ideas fail within the first year. Whilst funding and lack of, or insufficient business
                                 knowledge may contribute to this failure, an analysis of the root cause of most new business failure can
                                 be attributed to a lack of thorough market research before entering into the market. Whilst you may see
                                 the potential of your idea, this may not always translate into making sound business or financial sense.
                                 Successful ideas are not all necessarily “safe bets”. Some “crazy” ideas can become lucrative
                                 businesses, other business ideas that may seem like winners need to remain ideas. In this lesson plan,
                                 we will look at business idea validation from the perspective of validating your new business idea and
                                 review some tools, which, you can use to determine if your business idea has the potential to be more
                                 than just an idea or not.
                               </span>
                             </span>
                           </h3>
                         </div>
                 
                         <div className={section1Styles.group}>
                           <div className={section1Styles.content_box2}>
                             <div className={section1Styles.flexCol4}>
                               <h2 className={section1Styles.medium_title}>By the end of this session, you should:</h2>
                               <h3 className={section1Styles.subtitle1}>
                                 1. Understand the concept of business idea validation.
                                 <br />
                                 2. Understand the purpose and the need to conduct idea validation before going to market.
                                 <br />
                                 3. Know the factors that contribute to the success of your idea.
                                 <br />
                                 4. Know the 4 steps to determine your market validation.
                                 <br />
                                 5. Know the tools you can employ for your business idea validation
                               </h3>
                             </div>
                           </div>
                 
                           <img
                             src={require('../../assets/courseplan/48cdb55d43a3e4df89eb38cdf4852807.png')}
                             alt="alt text"
                             className={section1Styles.image1}
                           />
                         </div>
                       </div>
                    </section>
                  }
                  {
                   <section className={section2Styles.section2}>
                   <div className={section2Styles.flexCol}>
                     <div className={section2Styles.flexCol1}>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Idea Validation:
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             <br />
                           </span>
                           <span className={section2Styles.title_span2}>
                             This is an experimental process of collecting evidence around your business idea to make fast, informed
                             and de-risked decisions. This process begins with your business idea and usually ends with a paying
                             customer. It is important to validate your business idea early in your entrepreneurial journey to ensure
                             you do not waste your valuable resources creating a product or offering that is not a good fit for the
                             market you are trying to penetrate. Validating your business idea enables you to reasonably predict
                             whether people will buy your product or service and thus whether it is worth pushing through with the
                             idea to have a product or offering or leaving it the idea simply as an idea. In some cases, a business
                             idea can be validated to:
                             <br />
                           </span>
                           <span className={section2Styles.title_span3}>1. See if the timing for that idea is right –</span>
                           <span className={section2Styles.title_span4}>
                             {' '}
                             sometimes it is a brilliant idea but executed at the wrong time. Idea validation can help you figure out
                             if your timing is right for that product or offering.
                             <br />
                           </span>
                           <span className={section2Styles.title_span5}>
                             2. Confirm that you can sell and deliver the solution efficiently enough –
                           </span>
                           <span className={section2Styles.title_span6}>
                             {' '}
                             Sometimes you have a brilliant idea that you can deliver on to one or two people, this does not
                             necessarily mean this idea is scalable or you have the resources to deliver to more people. Validating
                             your idea whilst you see if you have the people willing to pay for your product or offering also lets
                             you see if you have the right tools and resources to
                             <br />
                             consistently offer the solution.
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Purpose of Idea Validation:
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             The purpose of idea validation is to assess if your business idea will survive in the real world before
                             you dedicate time and resources to building and releasing it (business product or offering) into the
                             market. In other words, does your business idea have a real demand in the market in which you plan to
                             introduce it? Will the business idea make you money or cause you to lose money. Your business idea must
                             either be a. able to solve a real problem, b. fulfil an intended purpose or c. appeal to other
                             incentives.
                             <br />
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Why should my new business idea be validated when I can just test it on the market?
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             Every new business idea has high level of unpredictability, precisely because it is a new business idea.
                             Therefore, there is a need to test the idea thoroughly. It can take as little as one thing going wrong
                             to derail your entire business plan. Validating your business idea before bringing it to market ensures
                             that you are able to figure out the kinks and possible roadblocks and or delay. This will help you in
                             the long term by helping to reduce the time you have to dedicate to iron out these kinks if the item or
                             offering is already on the market. It will also help reduce or minimize costs in that you are not using
                             a trial-and-error approach and finally, it may also protects you against product or offering
                             inconsistency where customers can find your product one day and not another because you have not done
                             the work to ensure consistent production.
                             <br />
                             <br />
                             As indicated above, it makes better business sense in the longer term for you as a new business owner to
                             do this work pre-launching of your product or offering versus investing time, effort and resources into
                             creating and developing a business idea (i.e., a product or offering) that either is unwanted or not
                             needed in the market, an idea (product or offering) that already exists in some form on the market that
                             yours is inferior to and/or an idea (product or offering) that no one is willing to pay to acquire from
                             you. By validating your business idea, you can gain greater insight into how your product or offering
                             addresses or alternatively does not address your target customers’ need(s). These insights can help you
                             create a product or offering that meets your market segment’s needs, and thus translate into paying
                             customers. Taking the time to validate your business idea before going to market will also instil
                             confidence among your investors, crowd-funders, and banks that are considering funding your business as
                             they will have evidence that your product or offering is viable in the market and thus is a good
                             investment opportunity.
                             <br />
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Factors that contribute to the success of your idea
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             Validation is a continuous process for improving your idea. Even if a real business problem exists and
                             you have a validated business solution for it, there are other aspects which are presented below that
                             might also need to be validated as you develop your business idea:
                             <br />
                           </span>
                           <span className={section2Styles.title_span2}>
                             <br />
                             1. Adopter categories –
                           </span>
                           <span className={section2Styles.title_span3}>
                             {' '}
                             How appealing is your business idea to the relevant adopter categories?
                             <br />
                             Consumers are divided into 5 adopter categories based on their behavioural patterns and values. The 5
                             adopter categories, in order of their speed of uptake, are:
                             <br />
                           </span>
                           <span className={section2Styles.title_span4}>a. Innovators -</span>
                           <span className={section2Styles.title_span5}>
                             {' '}
                             Innovators are the first customers to try a new product. They are, by nature, risk takers and are
                             excited by the possibilities of new ideas and new ways of doing things. Products tend to be more
                             expensive at their point of release (though some products do defy this trend) and as such innovators are
                             generally wealthier than other types of adopters (though in some cases they may adopt products in a very
                             narrow field and devote much of their financial resources to this adoption).
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span6}>b. Early Adopters -</span>
                           <span className={section2Styles.title_span7}>
                             {' '}
                             Early adopters tend to be the most influential people within any market space and they will often have a
                             degree of “thought leadership” for other potential adopters. They may be very active in social media and
                             often create reviews and other materials around new products or offerings that they strongly like or
                             dislike. Early adopters will normally have a reasonably high social status (which in turn enables
                             thought leadership), reasonable access to finances (beyond those of
                             <br />
                             later adopters), high levels of education and a reasonable approach to risk. However, they do not take
                             as many risks as innovators and tend to make more reasoned decisions as to whether or not to become
                             involved in a particular product. They will try to obtain more information than an innovator in this
                             decision-making
                             <br />
                             process.
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span8}>c. Early Majority -</span>
                           <span className={section2Styles.title_span9}>
                             {' '}
                             As a product or offering begins to have mass market appeal, the next class of adopter to arrive is the
                             early majority. This class of adopter is reasonably risk averse and wants to be sure that their, often
                             more limited, resources are spent wisely on products or offerings. They are however, generally, people
                             with better than average social status and while not thought leaders in their own right – they will
                             often be in contact with thought leaders and use the opinions of these
                             <br />
                             thought leaders when making their adoption decisions.
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span10}>d. Late Majority -</span>
                           <span className={section2Styles.title_span11}>
                             {' '}
                             The late majority is rather more skeptical about product adoption than the first three classes of
                             adopters. They tend to put their resources towards tried and tested solutions only and are risk-averse.
                             In general terms, this category of adopter has less money, lower social status, and less interaction
                             with thought leaders and innovators than the other groups of adopters.
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span12}>e. Laggards -</span>
                           <span className={section2Styles.title_span13}>
                             {' '}
                             Laggards are last to arrive at the adoption party and their arrival is typically a sign that a product
                             is entering decline. Laggards value traditional methods of doing things and highly averse to change and
                             risk. Typically, laggards will have low socio-economic status and rarely seek opinions outside of their
                             own limited social set. However, it is worth noting that in many cases laggards are older people who are
                             less familiar with technology than younger generations and in these cases, they may still have a
                             mid-level of socio-economic status.
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span14}>*Note</span>
                           <span className={section2Styles.title_span15}>
                             : It is important to realize that, as with any generalization, not all members of a  class of adopter
                             will conform to the general patterns of that class. There will be high- income, well-educated,
                             risk-taking, laggards as well as low-income, poorly-educated, non-thought leader early adopters. There
                             are also plenty of older people familiar with technology. These categories are useful for generic
                             planning for market entry and should not be used to stereotype individuals.
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span16}>
                             <br />
                             2. Compatibility –
                           </span>
                           <span className={section2Styles.title_span17}>
                             {' '}
                             Is your business idea consistent with the needs of your potential adopters ? Potential adopters need to
                             know that your product or offering will be compatible with their life and lifestyle. If your product or
                             offering requires a huge lifestyle change or if the user must acquire additional products to make your
                             innovation work, then it is more likely to fail. Innovations meet with the greatest success when users
                             are able to seamlessly adopt them, i.e., when they replace an
                             <br />
                             existing product or idea, for the better.
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span18}>3. Relative advantage</span>
                           <span className={section2Styles.title_span19}>
                             {' '}
                             – Can your business idea/solution outperform the competition? How is it better? Is it actually better?
                             <br />
                             Relative advantage measures how improved an innovation is over a competing option or the previous
                             generation of a product or offering. Potential users need to see how an innovation improves their
                             current situation. Improvements can be in one or many of these areas:
                             <br />• better service,
                             <br />• consolidation of multiple functions into one tool,
                             <br />• decreased need for supplies and equipment,
                             <br />• empowerment of users,
                             <br />• improved interface,
                             <br />• increased customizability,
                             <br />• increased longevity,
                             <br />• increased productivity,
                             <br />• reduced user effort,
                             <br />• reduced environmental impact,
                             <br />• saving of money,
                             <br />• saving of space or storage,
                             <br />• saving of time.
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span20}>4. Complexity/Simplicity</span>
                           <span className={section2Styles.title_span21}>
                             {' '}
                             – Is your business idea easy to understand or does it require new knowledge and/or skills?
                             <br />
                             Complexity or simplicity refers to how difficult it is for adopters to learn to use an innovation.
                             Complexity slows down the gears of progress. The more complex your innovation, the more difficult it
                             will be for your potential adopters to incorporate it into their lives. The more intuitive your
                             innovation, the more likely it will be adopted.
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span22}>5. Trialability –</span>
                           <span className={section2Styles.title_span23}>
                             {' '}
                             Can your product or offering be experimented with by adopter before they purchase?
                             <br />
                             Trialability describes how easily your potential adopters can explore your innovation. Trialability is
                             critical to facilitating the adoption of one’s innovation. Potential users want to see what your
                             innovation can do and give it a test run before committing. This is the underlying concept of trial
                             sizes for tangible goods, and demo or beta releases for digital goods. Potential adopters can see for
                             themselves what life might be like once they adopt your product or offering.
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span24}>6. Observability –</span>
                           <span className={section2Styles.title_span25}>
                             {' '}
                             Are the benefits of your offering visible for your adopters?
                             <br />
                             Observability is the extent to which the results or benefit of using an innovation are visible to
                             potential adopters. The adopter types who come after early adopters rely on seeing members of this group
                             using an innovation. Observability extends beyond having earlier adopters use an innovation in view of
                             later adopters. Potential adopters of all types must clearly see the benefit of adopting your innovation
                             and using it.
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             4 STEPS TO DETERMINE YOUR MARKET VALIDATION
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             In the early stage you want to focus on validating your business assumptions to make sure the most
                             critical ones for your business are true. Business assumptions are your expectations or beliefs about
                             the future outcomes of your business product or offering. These assumptions are based on the data,
                             research, and experiences that you have. Assumptions can be made about anything from your customer
                             behaviour to market trends. For example, a business may assume that a new product will be successful
                             because of positive customer feedback they have received from a market survey. Or they may assume that a
                             particular target market will respond favorably to an advertising campaign.
                             <br />
                             Business assumptions help to inform the direction of your business and provide a basis for making
                             decisions. Without assumptions, it would be difficult to make decisions or plan for the future. It is
                             important to remember that assumptions are not always accurate and should be regularly reviewed and
                             updated as new information becomes available.
                             <br />
                             <br />
                             They can be divided into two main categories:
                             <br />
                             Internal assumptions are those made within the company, such as the number of sales that will be
                             achieved or the cost of production.
                             <br />
                             External assumptions are those made outside of the company, such as the potential demand for a product
                             or the response of the target market.
                             <br />
                             Some examples of common assumptions are:
                             <br />o “My customers have problem a,b,c”.
                             <br />o “My customers care about a”.
                             <br />o “My customers would pay for solution a”.
                             <br />o “There are no satisfactory substitutes for this problem so far”.
                             <br />
                             If your business assumptions regarding your market and business idea are valid, you can start testing
                             your product to learn how all of the varying elements work in reality, i.e. how it would work in the
                             market. Although there are several different ways to validate your business idea, the overall validation
                             process is quite simple and straightforward:
                             <br />
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Step 1. Define your business goal(s).
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             Defining and writing down the goal(s) of your business is the first step in market validation. In this
                             stage, you will decide what you want to learn and what aspects to validate.
                             <br />
                             For example, your goal can be one of the following:
                             <br />• Problem – Is there a real problem and is this problem worth solving?
                             <br />• Solution – Is your product/offer going to solve the problem identified?
                             <br />• Features – How do the core features of your product work? Or what are the
                             <br />
                             key features of your offering?
                             <br />• Business model – Is your business model viable and scalable?
                             <br />• Price – Is there enough demand to make your business model work with the price you have set? How
                             does your pricing model work in practice? The purpose of your goal is to identify the most critical
                             assumption related to your specific idea, so be sure to start with the most significant one. Map out all
                             your business assumptions regarding your business idea and prioritize the one that is the most critical
                             for your business idea to succeed.
                             <br />
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Step 2. Develop a Hypotheses
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             After you have defined your goal for idea validation, the next step is to develop a hypothesis based on
                             that goal. A practical hypothesis is a testable statement, which often includes a prediction. It is
                             important that you start from the most critical assumption, i.e., the one that is most likely to fail
                             and thus would also have the worst consequence(s). In developing the hypotheses, you are questioning
                             what would have to be true for your business idea to be feasible? When building new products, this basic
                             template for writing hypotheses works great: We believe [subject/target] will [predicted action] because
                             [reason]
                             <br />
                             In established companies with existing products, there is a more specific way of
                             <br />
                             thinking about them:
                             <br />
                             We believe [subject/target] has a [problem] because [reason]. If we do [action], this [metric] will
                             improve
                             <br />
                             The main difference between business assumptions and hypotheses is that hypotheses are actionable. They
                             turn one or more of your assumptions into a falsifiable hypothesis.
                             <br />
                             With your hypothesis you need to define the minimum success criteria for the test. A Minimum Success
                             Criteria (MSC) is how you make a hypothesis falsifiable, i.e., the breakpoint at which you consider the
                             experiment to have made your hypothesis valid or invalid. The findings/result here will help you decide
                             whether your product or offering is worth building or offering. It is important to note that it is also
                             possible for your experiments to end up somewhere in the middle of valid and invalid.
                             <br />
                           </span>
                         </span>
                       </h1>
             
                       <div className={section2Styles.title1}>
                         <h1 className={section2Styles.title2_box}>
                           <span className={section2Styles.title2}>
                             <span className={section2Styles.title2_span0}>Step 3. Experiment and revise</span>
                             <span className={section2Styles.title2_span1}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span2}>
                               Once you have developed a hypothesis, you can start validating that assumption by
                             </span>
                             <span className={section2Styles.title2_span3}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span4}>
                               running experiments. The point of experimenting is to find the fastest and cheapest
                             </span>
                             <span className={section2Styles.title2_span5}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span6}>
                               way to test your assumption in practice. An experiment is a test or a set of tests that
                             </span>
                             <span className={section2Styles.title2_span7}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span8}>
                               measure the effects of a hypothesis and reveal whether or not you should proceed
                             </span>
                             <span className={section2Styles.title2_span9}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span10}>
                               with your business idea. In other words, an experiment is conducted to learn if your
                             </span>
                             <span className={section2Styles.title2_span11}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span12}>
                               business assumption is or is not true. Often, your initial business idea is a starting
                             </span>
                             <span className={section2Styles.title2_span13}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span14}>
                               point for a better and more refined idea because you almost always need to improve
                             </span>
                             <span className={section2Styles.title2_span15}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span16}>
                               it. When you are going through the validation process, you have a chance to learn
                             </span>
                             <span className={section2Styles.title2_span17}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span18}>how to make your idea or product even better.</span>
                             <span className={section2Styles.title2_span19}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span20}>There are several ways to conduct an experiment:</span>
                             <span className={section2Styles.title2_span21}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span22}>1. You can build an MVP (Minimum Viable Product)</span>
                             <span className={section2Styles.title2_span23}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span24}>4 Types of Minimum Viable Products;</span>
                             <span className={section2Styles.title2_span25}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span26}>1. Physical:</span>
                             <span className={section2Styles.title2_span27}>
                               {' '}
                               If you are aiming to create a physical product, you will likely need to
                             </span>
                             <span className={section2Styles.title2_span28}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span29}>
                               develop a physical MVP to test your product’s features.
                             </span>
                             <span className={section2Styles.title2_span30}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span31}>2. Product design</span>
                             <span className={section2Styles.title2_span32}>
                               : A product design can serve as an MVP (especially for digital
                             </span>
                             <span className={section2Styles.title2_span33}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span34}>
                               products) if the design gives customers a reliable idea of your product’s core
                             </span>
                             <span className={section2Styles.title2_span35}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span36}>
                               features. Types of product design MVPs include sketches (freehand or digital),
                             </span>
                             <span className={section2Styles.title2_span37}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span38}>
                               mock-ups and landing pages (primarily focused on the user interface), wireframes of
                             </span>
                             <span className={section2Styles.title2_span39}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span40}>
                               webpages or mobile apps (primarily focused on user experience), and demo videos.
                             </span>
                             <span className={section2Styles.title2_span41}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span42}>3. Piecemeal</span>
                             <span className={section2Styles.title2_span43}>
                               : A piecemeal MVP combines different existing products or software to
                             </span>
                             <span className={section2Styles.title2_span44}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span45}>
                               create a working prototype. For example, combining an existing forum setup and
                             </span>
                             <span className={section2Styles.title2_span46}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span47}>
                               digital artboard to create a collaborative drawing experience.
                             </span>
                             <span className={section2Styles.title2_span48}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span49}>4. Concierge</span>
                             <span className={section2Styles.title2_span50}>
                               : Concierge MVPs are prototypes of a digital product that are initially
                             </span>
                             <span className={section2Styles.title2_span51}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span52}>
                               performed manually for testing—for example, if you want to create a website that
                             </span>
                             <span className={section2Styles.title2_span53}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span54}>
                               automatically ships books to purchasers, you can start shipping books manually as
                             </span>
                             <span className={section2Styles.title2_span55}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span56}>
                               your minimum viable product to test the business model before coding the back end.
                             </span>
                             <span className={section2Styles.title2_span57}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span58}>2. You can Fake it till you make it/ Wizard of Oz</span>
                             <span className={section2Styles.title2_span59}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span60}>
                               Fake it till you make it (sometimes referred to as the Wizard of Oz method) is a term
                             </span>
                             <span className={section2Styles.title2_span61}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span62}>
                               used to refer to the kind of prototypes where a person conducts a part of, or an
                             </span>
                             <span className={section2Styles.title2_span63}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span64}>
                               entire, service that is supposed to be automated or implemented later on to establish
                             </span>
                             <span className={section2Styles.title2_span65}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span66}>
                               if people are interested in and willing to pay for such a service.
                             </span>
                             <span className={section2Styles.title2_span67}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span68}>
                               For example, if your business idea would be to build a small diving robot that can
                             </span>
                             <span className={section2Styles.title2_span69}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span70}>
                               inspect the foundations of piers in harbours, the first step would be sell this service to
                             </span>
                             <span className={section2Styles.title2_span71}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span72}>
                               harbour owners and just have a diver do the job manually as opposed to sending a
                             </span>
                             <span className={section2Styles.title2_span73}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span74}>robot.</span>
                             <span className={section2Styles.title2_span75}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span76}>3. You can use an Interview/survey</span>
                             <span className={section2Styles.title2_span77}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span78}>
                               There are two types of survey research methods: a questionnaire (a list of questions
                             </span>
                             <span className={section2Styles.title2_span79}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span80}>
                               that respondents answer individually) and an interview (asking the respondent a
                             </span>
                             <span className={section2Styles.title2_span81}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span82}>
                               series of questions and follow-up questions based on the provided answers).
                             </span>
                             <span className={section2Styles.title2_span83}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span84}>
                               Conducting surveys/interviews with your target market segment can be an effective
                             </span>
                             <span className={section2Styles.title2_span85}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span86}>
                               way to learn about your product’s potential. This initiative might include sending out
                             </span>
                             <span className={section2Styles.title2_span87}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span88}>
                               an online survey, cold calling prospective clients or requesting a conversation with a
                             </span>
                             <span className={section2Styles.title2_span89}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span90}>potential client (e.g. at a supermarket etc.).</span>
                             <span className={section2Styles.title2_span91}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span92}>
                               Be sure to ask the potential customers about their motivations, preferences, needs,
                             </span>
                             <span className={section2Styles.title2_span93}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span94}>
                               and the products they currently use. Refer to the list you created in your first step of
                             </span>
                             <span className={section2Styles.title2_span95}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span96}>
                               the market validation process, and frame any assumptions you made as questions to
                             </span>
                             <span className={section2Styles.title2_span97}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span98}>ask your interviewees.</span>
                             <span className={section2Styles.title2_span99}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span100}>
                               Be open to the feedback you receive and record it for future use. This feedback may
                             </span>
                             <span className={section2Styles.title2_span101}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span102}>
                               reflect that your product as it exists does not have strong market validity, in which
                             </span>
                             <span className={section2Styles.title2_span103}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span104}>
                               case, you can use it to improve and repeat the market validation process until you
                             </span>
                             <span className={section2Styles.title2_span105}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span106}>get it just right.</span>
                             <span className={section2Styles.title2_span107}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span108}>4. You can use a Crowdfunding platform</span>
                             <span className={section2Styles.title2_span109}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span110}>
                               Generally, crowdfunding campaigns are run based on promises. These promises
                             </span>
                             <span className={section2Styles.title2_span111}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span112}>
                               usually take the form of products or services that you can provide once you obtain
                             </span>
                             <span className={section2Styles.title2_span113}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span114}>
                               enough money. It is then very important that you fulfil these promises or else risk
                             </span>
                             <span className={section2Styles.title2_span115}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span116}>angering a lot of people who gave you the money.</span>
                             <span className={section2Styles.title2_span117}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.title2_span118}>5. You can conduct A/B test</span>
                             <span className={section2Styles.title2_span119}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span120}>
                               A/B testing is essentially an experiment where two or more variants of a page are
                             </span>
                             <span className={section2Styles.title2_span121}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span122}>
                               shown to users at random, and statistical analysis is used to determine which
                             </span>
                             <span className={section2Styles.title2_span123}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span124}>
                               variation performs better for a given conversion goal.
                             </span>
                             <span className={section2Styles.title2_span125}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span126}>
                               If your business goal is to validate a problem, conducting interviews and surveys are
                             </span>
                             <span className={section2Styles.title2_span127}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span128}>
                               often enough. If, however, you are looking to validate a product or an offering, you
                             </span>
                             <span className={section2Styles.title2_span129}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span130}>
                               may want to employ the use of the Wizard of Oz prototyping. If your business goal is
                             </span>
                             <span className={section2Styles.title2_span131}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span132}>
                               to validate a price, you might want to create a landing page and conduct A/B tests.
                             </span>
                             <span className={section2Styles.title2_span133}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span134}>
                               You might also want to try selling a fictitious product and use accepted offers as a
                             </span>
                             <span className={section2Styles.title2_span135}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span136}>measure.</span>
                             <span className={section2Styles.title2_span137}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span138}>
                               When you are conducting your market research, ask for feedback; ideas, responses,
                             </span>
                             <span className={section2Styles.title2_span139}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span140}>
                               comments and look for common answers to the following themes:
                             </span>
                             <span className={section2Styles.title2_span141}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span142}>
                               • People who say they’ll buy your product = YOUR Target market
                             </span>
                             <span className={section2Styles.title2_span143}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span144}>
                               • What people are actually paying for it = YOUR Price
                             </span>
                             <span className={section2Styles.title2_span145}>
                               <br />
                             </span>
                             <span className={section2Styles.title2_span146}>
                               • People who will buy/keep buying it = YOUR Demand
                             </span>
                           </span>
                         </h1>
                       </div>
             
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Step 4: Test Your Product or Offering
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             Once your research has demonstrated a space in the market for your product, you need to ensure that you
                             release the most useful, intuitive version of it. You can achieve this through alpha and beta testing.
                             Alpha testing is when internal employees test a product in a staged setting. The purpose of alpha
                             testing is to eliminate any bugs, issues, or idiosyncrasies in the product before it’s available to
                             outside users. Beta testing is when a product is tested by a limited group of real, external users who
                             are specifically told to identify problems. In the case of a software or app, beta
                             <br />
                             testing might be open to the public with a notice letting users know the version they’re testing is
                             unfinished. Testing your product with real users can prove invaluable when assessing market
                             <br />
                             validity. If there is a need in the market, but your product is faulty, complex, or difficult to use,
                             customers may opt for a competitor’s offering. The feedback you get from both alpha and beta testers can
                             help you better leverage and meet your customer needs.
                             <br />
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Tools for idea validation
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             1. Validation Board
                             <br />
                           </span>
                           <span className={section2Styles.title_span2}>
                             The Validation Board by Lean Start-up Machine is a tool that can be used to validate and pivot your
                             assumptions and to track those pivots. It helps you identify potential customers and the problems to
                             solve an also helps to define the business assumptions to each key point of your start-up. The
                             Validation Board can be a useful tool for entrepreneurs who are looking for a systematic and active way
                             to set goals and make decisions about whether to proceed with a business idea or to pivot.
                             <br />
                             With the help of the Validation Board, you can define your customer, problem and solution hypotheses,
                             and identify the core assumptions related to these aspects. It can also be used to track multiple
                             hypotheses at once.
                             <br />
                             <br />
                             https://www.youtube.com/watch?v=HhoducyStMw
                             <br />
                           </span>
                           <span className={section2Styles.title_span3}>
                             <br />
                             <br />
                             2. Validation Canvas
                             <br />
                           </span>
                           <span className={section2Styles.title_span4}>
                             Validation Canvas by Lean Service Creation is another tool you can use. In contrast to the Validation
                             Board, Canvas helps to find proof that business hypotheses are not viable.
                             <br />
                             This tool helps you to clarify the following points:
                             <br />• Validation of UVP — Unique Value Proposition
                             <br />• How to find your target audience
                             <br />• How to validate your customers’ willingness to pay
                             <br />• How to analyze interview results
                             <br />• How to conclude in a proper way
                             <br />• What to decide — make a pivot or continue with the current idea
                             <br />
                             <br />
                             Both the Validation Board and the Validation Canvas are good and simple options for validating and
                             keeping track of your assumptions.
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span5}>
                             Javelin
                             <br />
                           </span>
                           <span className={section2Styles.title_span6}>
                             Another tool by Lean Start-up Machine is Javelin. An all-in-one solution that helps you to validate
                             assumptions by using customers’ interview. It collects and analyzes data allowing you to make the
                             decision faster. Javelin helps to record and transcribe interviews, test landing pages, and provides
                             ready-made surveys.
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Product Validation Checklist
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             There is a system for validating the market and product — Product Validation Checklist. It is an
                             interactive checklist that helps you to answer the questions:
                             <br />• Target market — how to find and what to focus on?
                             <br />• How to identify a customer’s business challenge?
                             <br />• How to find a new solution?
                             <br />• How to find a value proposition?
                             <br />• How to make sure about product/market fit?
                           </span>
                         </span>
                       </h1>
                       <h1 className={section2Styles.title_box}>
                         <span className={section2Styles.title}>
                           <span className={section2Styles.title_span0}>
                             Conclusion
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.title_span1}>
                             It is important to remember that business idea validation does not eliminate all the problems, but it
                             helps you be more prepared to deal with them as they arise. Business idea validation is an effective way
                             to minimize potential risks and MVP development costs. Consider all the steps to validate your idea to
                             see if it has a potential market, or can it be turned into a successful pivot.
                           </span>
                         </span>
                       </h1>
                     </div>
             
                     <div className={section2Styles.content_box3}>
                       <div className={section2Styles.flexCol2}>
                         <h1 className={section2Styles.title11_box}>
                           <span className={section2Styles.title11}>
                             <span className={section2Styles.title11_span0}>
                               You have completed the Lesson on <br />
                             </span>
                             <span className={section2Styles.title11_span1}>
                               Validating your Business
                               <br />
                               Idea.
                               <br />
                             </span>
                             <span className={section2Styles.title11_span2}>
                               Review your input and print if you are happy with the results.
                               <br />
                             </span>
                           </span>
                         </h1>
             
                         <div className={section2Styles.box}><a href="lwa-plan-two">
                           <h3 className={section2Styles.subtitle2}>Return</h3>
                         </a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanThree;