import React from 'react';
import cn from 'classnames';

import styles from '../../scss/AccountingAccount.module.scss';
import stylesXs from '../../scss/AccountingAccountXs.module.scss';
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import { reactLocalStorage } from 'reactjs-localstorage';
const { Option } = Select;
const { TextArea } = Input;

class BuiltBusiness extends React.Component {

  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
      isLoading: false,
      isUploading: false,
      isUploadingPitchVideo: false,
      isUploadingLogo: false,
      isUploadingProducts: false,
      isRequesting: false,
      isDeleting: false,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: '',
      deleteFileUrl: '',
      deleteFileType: '',
      profileForm: {
        name: '',
        email: '',
        country_id: 81,
        currency_id: 42,
        industry_id: 1,
        active: 1
      },
      profileFormErrors: {
        name: '',
        email: '',
        country_id: '',
        currency_id: '',
        industry_id: '',
      },
      errorMessage: '',
      successMessage: '',
      userToken: '',
    }

  }

  getUserToken () {
    this.setState({
      ...this.state,
        isLoading: true,
        isRequesting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      console.log(responseInfo.data.built_Acc_user_token);
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        userToken: responseInfo.data.built_Acc_user_token
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  updateProfileInfo(event, name) {
    var profileForm = this.state.profileForm;
    let value = event.target.value;
    console.log(value);
    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });
	}

  updateProfileInfoSelect(event, name) {
    var profileForm = this.state.profileForm;
    let value = event;

    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });
	}

  submitProfileForm () {

		this.setState({
			...this.state,
			isRequesting: true,
		 });

		axios.post("https://web.builtaccounting.com/api/createbusiness", {
      ...this.state.profileForm
    }, AuthService.getBuiltSUHeadersUserToken(this.state.userToken))
		.then((response) => {
      let responseInfo = response.data;

        var userObj = reactLocalStorage.getObject('userObj');
        userObj.built_account = "added";
        reactLocalStorage.setObject('userObj', userObj);

          axios.post("https://web.builtaccounting.com/api/v2/oauth/accesstoken", {
            "name": this.state.profileForm.name,
            "business_id": responseInfo.id
          }, AuthService.getBuiltSUHeadersUserToken(this.state.userToken))
          .then((response) => {
            let responseInfo3 = response.data;

            axios.post(CONSTANTS.API_BASE_URL + "/accounting/add-user-token", {
                "built_Acc_user_token": this.state.userToken,
                "built_enc_id": responseInfo.enc_id,
                "built_business_id": responseInfo3.accessToken
            }, AuthService.getAxiosHeaders())
            .then((response) => {
              let responseInfo4 = response.data;
        
              this.setState({
                ...this.state,
                isRequesting: false,
                successMessage: "Your Built Business Account has been created successfully."
              })
        
              window.modalShow('success-modal');
              setTimeout(() => {
                window.location = "/built-dashboard";
              }, 500);
        
            }).catch((error) => {
              try{
                let errorResponse = error.response.data;
        
                if(errorResponse.status === 401 || errorResponse.status === 404){
                  alert('ha1');
                  window.location = "/not-found";
                  return;
                }
        
                let errorMessage = 'Error: Could not connect to server';
                if(errorResponse.hasOwnProperty("message")){
                  errorMessage = errorResponse.message;
                }
        
                this.setState({
                  ...this.state,
                  isRequesting: false,
                  errorMessage: errorMessage,
                });
                alert('ha1');
        
                window.scrollTo({top: 0, behavior: 'smooth'});
              }catch(e){
                window.location = "/server-offline";
              }
            });
      
          }).catch((error) => {
            try{
              let errorResponse = error.response.data;
      
              if(errorResponse.status === 401 || errorResponse.status === 404){
                alert('ha1');
                window.location = "/not-found";
                return;
              }
      
              let errorMessage = 'Error: Could not connect to server';
              if(errorResponse.hasOwnProperty("message")){
                errorMessage = errorResponse.message;
              }
      
              this.setState({
                ...this.state,
                isRequesting: false,
                errorMessage: errorMessage,
              });
              window.modalShow('error-modal');
      
              window.scrollTo({top: 0, behavior: 'smooth'});
            }catch(e){
                //window.location = "/server-offline";
            }
          });

		}).catch((error) => {
      try{
        let errorResponse = error.response;

        if(error.response.status === 401 || error.response.status === 404){
          alert('heb');
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        if (typeof errorResponse.errors.email === 'undefined'){
        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });
        }else{
          this.setState({
            ...this.state,
            isRequesting: false,
          errorMessage: errorResponse.errors.email,
        });
        }

        console.log(error.response);
        window.modalShow('error-modal');
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
          window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    window.KTComponents.init();
    AuthService.builtUserStatusSecond();
    AuthService.builtBusinessStatus();
    this.getUserToken();

  }


  render(){
      return (
      <div className={cn(styles.root, 'accounting-account')}>
          <div className={styles.flexCol}>
            <img
              className={styles.builtlogo}
              src={require('../../../assets/accounting/built-logo.png')}
              alt="alt text"
            />
            <h1 className={styles.hero_title}>Now, let's register your business with Built Accounting</h1>
    
            <div className={styles.flexCol1}>

                <div className={styles.flexCol2}>
                  <div className={styles.flexCol2}>
                    <div className={styles.text}>Business Name</div>
                    <Input size="large" type="text" id="name" name="name" placeholder="Enter your business name" onChange={(e) => {this.updateProfileInfo(e,"name");}} />
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Business Email</div>
                    <Input size="large" required type="email" id="email" placeholder="Enter your business email" name="email" onChange={(e) => {this.updateProfileInfo(e,"email");}}  />
                    {
                      this.state.profileFormErrors.email.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.email}</div>
                    }
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Country</div>
                    <Select
                        size="large"
                        id="country"
                        style={{
                          width: "100%",
                        }}
                        value={this.state.profileForm.country_id}
                        options={[
                          {
                            value: 1,
                            label: "Afghanistan"
                        },
                        {
                            value: 2,
                            label: "Albania"
                        },
                        {
                            value: 3,
                            label: "Algeria"
                        },
                        {
                            value: 4,
                            label: "American Samoa"
                        },
                        {
                            value: 5,
                            label: "Andorra"
                        },
                        {
                            value: 6,
                            label: "Angola"
                        },
                        {
                            value: 7,
                            label: "Anguilla"
                        },
                        {
                            value: 8,
                            label: "Antarctica"
                        },
                        {
                            value: 9,
                            label: "Antigua and Barbuda"
                        },
                        {
                            value: 10,
                            label: "Argentina"
                        },
                        {
                            value: 11,
                            label: "Armenia"
                        },
                        {
                            value: 12,
                            label: "Aruba"
                        },
                        {
                            value: 13,
                            label: "Australia"
                        },
                        {
                            value: 14,
                            label: "Austria"
                        },
                        {
                            value: 15,
                            label: "Azerbaijan"
                        },
                        {
                            value: 16,
                            label: "Bahamas"
                        },
                        {
                            value: 17,
                            label: "Bahrain"
                        },
                        {
                            value: 18,
                            label: "Bangladesh"
                        },
                        {
                            value: 19,
                            label: "Barbados"
                        },
                        {
                            value: 20,
                            label: "Belarus"
                        },
                        {
                            value: 21,
                            label: "Belgium"
                        },
                        {
                            value: 22,
                            label: "Belize"
                        },
                        {
                            value: 23,
                            label: "Benin"
                        },
                        {
                            value: 24,
                            label: "Bermuda"
                        },
                        {
                            value: 25,
                            label: "Bhutan"
                        },
                        {
                            value: 26,
                            label: "Bolivia"
                        },
                        {
                            value: 27,
                            label: "Bosnia and Herzegovina"
                        },
                        {
                            value: 28,
                            label: "Botswana"
                        },
                        {
                            value: 29,
                            label: "Bouvet Island"
                        },
                        {
                            value: 30,
                            label: "Brazil"
                        },
                        {
                            value: 31,
                            label: "British Indian Ocean Territory"
                        },
                        {
                            value: 32,
                            label: "Brunei Darussalam"
                        },
                        {
                            value: 33,
                            label: "Bulgaria"
                        },
                        {
                            value: 34,
                            label: "Burkina Faso"
                        },
                        {
                            value: 35,
                            label: "Burundi"
                        },
                        {
                            value: 36,
                            label: "Cambodia"
                        },
                        {
                            value: 37,
                            label: "Cameroon"
                        },
                        {
                            value: 38,
                            label: "Canada"
                        },
                        {
                            value: 39,
                            label: "Cape Verde"
                        },
                        {
                            value: 40,
                            label: "Cayman Islands"
                        },
                        {
                            value: 41,
                            label: "Central African Republic"
                        },
                        {
                            value: 42,
                            label: "Chad"
                        },
                        {
                            value: 43,
                            label: "Chile"
                        },
                        {
                            value: 44,
                            label: "China"
                        },
                        {
                            value: 45,
                            label: "Christmas Island"
                        },
                        {
                            value: 46,
                            label: "Cocos (Keeling) Islands"
                        },
                        {
                            value: 47,
                            label: "Colombia"
                        },
                        {
                            value: 48,
                            label: "Comoros"
                        },
                        {
                            value: 49,
                            label: "Congo"
                        },
                        {
                            value: 50,
                            label: "Cook Islands"
                        },
                        {
                            value: 51,
                            label: "Costa Rica"
                        },
                        {
                            value: 52,
                            label: "Croatia (Hrvatska)"
                        },
                        {
                            value: 53,
                            label: "Cuba"
                        },
                        {
                            value: 54,
                            label: "Cyprus"
                        },
                        {
                            value: 55,
                            label: "Czech Republic"
                        },
                        {
                            value: 56,
                            label: "Denmark"
                        },
                        {
                            value: 57,
                            label: "Djibouti"
                        },
                        {
                            value: 58,
                            label: "Dominica"
                        },
                        {
                            value: 59,
                            label: "Dominican Republic"
                        },
                        {
                            value: 60,
                            label: "East Timor"
                        },
                        {
                            value: 61,
                            label: "Ecuador"
                        },
                        {
                            value: 62,
                            label: "Egypt"
                        },
                        {
                            value: 63,
                            label: "El Salvador"
                        },
                        {
                            value: 64,
                            label: "Equatorial Guinea"
                        },
                        {
                            value: 65,
                            label: "Eritrea"
                        },
                        {
                            value: 66,
                            label: "Estonia"
                        },
                        {
                            value: 67,
                            label: "Ethiopia"
                        },
                        {
                            value: 68,
                            label: "Falkland Islands (Malvinas)"
                        },
                        {
                            value: 69,
                            label: "Faroe Islands"
                        },
                        {
                            value: 70,
                            label: "Fiji"
                        },
                        {
                            value: 71,
                            label: "Finland"
                        },
                        {
                            value: 72,
                            label: "France"
                        },
                        {
                            value: 73,
                            label: "France, Metropolitan"
                        },
                        {
                            value: 74,
                            label: "French Guiana"
                        },
                        {
                            value: 75,
                            label: "French Polynesia"
                        },
                        {
                            value: 76,
                            label: "French Southern Territories"
                        },
                        {
                            value: 77,
                            label: "Gabon"
                        },
                        {
                            value: 78,
                            label: "Gambia"
                        },
                        {
                            value: 79,
                            label: "Georgia"
                        },
                        {
                            value: 80,
                            label: "Germany"
                        },
                        {
                            value: 81,
                            label: "Ghana"
                        },
                        {
                            value: 82,
                            label: "Gibraltar"
                        },
                        {
                            value: 84,
                            label: "Greece"
                        },
                        {
                            value: 85,
                            label: "Greenland"
                        },
                        {
                            value: 86,
                            label: "Grenada"
                        },
                        {
                            value: 87,
                            label: "Guadeloupe"
                        },
                        {
                            value: 88,
                            label: "Guam"
                        },
                        {
                            value: 89,
                            label: "Guatemala"
                        },
                        {
                            value: 83,
                            label: "Guernsey"
                        },
                        {
                            value: 90,
                            label: "Guinea"
                        },
                        {
                            value: 91,
                            label: "Guinea-Bissau"
                        },
                        {
                            value: 92,
                            label: "Guyana"
                        },
                        {
                            value: 93,
                            label: "Haiti"
                        },
                        {
                            value: 94,
                            label: "Heard and Mc Donald Islands"
                        },
                        {
                            value: 95,
                            label: "Honduras"
                        },
                        {
                            value: 96,
                            label: "Hong Kong"
                        },
                        {
                            value: 97,
                            label: "Hungary"
                        },
                        {
                            value: 98,
                            label: "Iceland"
                        },
                        {
                            value: 99,
                            label: "India"
                        },
                        {
                            value: 101,
                            label: "Indonesia"
                        },
                        {
                            value: 102,
                            label: "Iran (Islamic Republic of)"
                        },
                        {
                            value: 103,
                            label: "Iraq"
                        },
                        {
                            value: 104,
                            label: "Ireland"
                        },
                        {
                            value: 100,
                            label: "Isle of Man"
                        },
                        {
                            value: 105,
                            label: "Israel"
                        },
                        {
                            value: 106,
                            label: "Italy"
                        },
                        {
                            value: 107,
                            label: "Ivory Coast"
                        },
                        {
                            value: 109,
                            label: "Jamaica"
                        },
                        {
                            value: 110,
                            label: "Japan"
                        },
                        {
                            value: 108,
                            label: "Jersey"
                        },
                        {
                            value: 111,
                            label: "Jordan"
                        },
                        {
                            value: 112,
                            label: "Kazakhstan"
                        },
                        {
                            value: 113,
                            label: "Kenya"
                        },
                        {
                            value: 114,
                            label: "Kiribati"
                        },
                        {
                            value: 115,
                            label: "Korea, Democratic People's Republic of"
                        },
                        {
                            value: 116,
                            label: "Korea, Republic of"
                        },
                        {
                            value: 117,
                            label: "Kosovo"
                        },
                        {
                            value: 118,
                            label: "Kuwait"
                        },
                        {
                            value: 119,
                            label: "Kyrgyzstan"
                        },
                        {
                            value: 120,
                            label: "Lao People's Democratic Republic"
                        },
                        {
                            value: 121,
                            label: "Latvia"
                        },
                        {
                            value: 122,
                            label: "Lebanon"
                        },
                        {
                            value: 123,
                            label: "Lesotho"
                        },
                        {
                            value: 124,
                            label: "Liberia"
                        },
                        {
                            value: 125,
                            label: "Libyan Arab Jamahiriya"
                        },
                        {
                            value: 126,
                            label: "Liechtenstein"
                        },
                        {
                            value: 127,
                            label: "Lithuania"
                        },
                        {
                            value: 128,
                            label: "Luxembourg"
                        },
                        {
                            value: 129,
                            label: "Macau"
                        },
                        {
                            value: 130,
                            label: "Macedonia"
                        },
                        {
                            value: 131,
                            label: "Madagascar"
                        },
                        {
                            value: 132,
                            label: "Malawi"
                        },
                        {
                            value: 133,
                            label: "Malaysia"
                        },
                        {
                            value: 134,
                            label: "Maldives"
                        },
                        {
                            value: 135,
                            label: "Mali"
                        },
                        {
                            value: 136,
                            label: "Malta"
                        },
                        {
                            value: 137,
                            label: "Marshall Islands"
                        },
                        {
                            value: 138,
                            label: "Martinique"
                        },
                        {
                            value: 139,
                            label: "Mauritania"
                        },
                        {
                            value: 140,
                            label: "Mauritius"
                        },
                        {
                            value: 141,
                            label: "Mayotte"
                        },
                        {
                            value: 142,
                            label: "Mexico"
                        },
                        {
                            value: 143,
                            label: "Micronesia, Federated States of"
                        },
                        {
                            value: 144,
                            label: "Moldova, Republic of"
                        },
                        {
                            value: 145,
                            label: "Monaco"
                        },
                        {
                            value: 146,
                            label: "Mongolia"
                        },
                        {
                            value: 147,
                            label: "Montenegro"
                        },
                        {
                            value: 148,
                            label: "Montserrat"
                        },
                        {
                            value: 149,
                            label: "Morocco"
                        },
                        {
                            value: 150,
                            label: "Mozambique"
                        },
                        {
                            value: 151,
                            label: "Myanmar"
                        },
                        {
                            value: 152,
                            label: "Namibia"
                        },
                        {
                            value: 153,
                            label: "Nauru"
                        },
                        {
                            value: 154,
                            label: "Nepal"
                        },
                        {
                            value: 155,
                            label: "Netherlands"
                        },
                        {
                            value: 156,
                            label: "Netherlands Antilles"
                        },
                        {
                            value: 157,
                            label: "New Caledonia"
                        },
                        {
                            value: 158,
                            label: "New Zealand"
                        },
                        {
                            value: 159,
                            label: "Nicaragua"
                        },
                        {
                            value: 160,
                            label: "Niger"
                        },
                        {
                            value: 161,
                            label: "Nigeria"
                        },
                        {
                            value: 162,
                            label: "Niue"
                        },
                        {
                            value: 163,
                            label: "Norfolk Island"
                        },
                        {
                            value: 164,
                            label: "Northern Mariana Islands"
                        },
                        {
                            value: 165,
                            label: "Norway"
                        },
                        {
                            value: 166,
                            label: "Oman"
                        },
                        {
                            value: 167,
                            label: "Pakistan"
                        },
                        {
                            value: 168,
                            label: "Palau"
                        },
                        {
                            value: 169,
                            label: "Palestine"
                        },
                        {
                            value: 170,
                            label: "Panama"
                        },
                        {
                            value: 171,
                            label: "Papua New Guinea"
                        },
                        {
                            value: 172,
                            label: "Paraguay"
                        },
                        {
                            value: 173,
                            label: "Peru"
                        },
                        {
                            value: 174,
                            label: "Philippines"
                        },
                        {
                            value: 175,
                            label: "Pitcairn"
                        },
                        {
                            value: 176,
                            label: "Poland"
                        },
                        {
                            value: 177,
                            label: "Portugal"
                        },
                        {
                            value: 178,
                            label: "Puerto Rico"
                        },
                        {
                            value: 179,
                            label: "Qatar"
                        },
                        {
                            value: 180,
                            label: "Reunion"
                        },
                        {
                            value: 181,
                            label: "Romania"
                        },
                        {
                            value: 182,
                            label: "Russian Federation"
                        },
                        {
                            value: 183,
                            label: "Rwanda"
                        },
                        {
                            value: 184,
                            label: "Saint Kitts and Nevis"
                        },
                        {
                            value: 185,
                            label: "Saint Lucia"
                        },
                        {
                            value: 186,
                            label: "Saint Vincent and the Grenadines"
                        },
                        {
                            value: 187,
                            label: "Samoa"
                        },
                        {
                            value: 188,
                            label: "San Marino"
                        },
                        {
                            value: 189,
                            label: "Sao Tome and Principe"
                        },
                        {
                            value: 190,
                            label: "Saudi Arabia"
                        },
                        {
                            value: 191,
                            label: "Senegal"
                        },
                        {
                            value: 192,
                            label: "Serbia"
                        },
                        {
                            value: 193,
                            label: "Seychelles"
                        },
                        {
                            value: 194,
                            label: "Sierra Leone"
                        },
                        {
                            value: 195,
                            label: "Singapore"
                        },
                        {
                            value: 196,
                            label: "Slovakia"
                        },
                        {
                            value: 197,
                            label: "Slovenia"
                        },
                        {
                            value: 198,
                            label: "Solomon Islands"
                        },
                        {
                            value: 199,
                            label: "Somalia"
                        },
                        {
                            value: 200,
                            label: "South Africa"
                        },
                        {
                            value: 201,
                            label: "South Georgia South Sandwich Islands"
                        },
                        {
                            value: 202,
                            label: "Spain"
                        },
                        {
                            value: 203,
                            label: "Sri Lanka"
                        },
                        {
                            value: 204,
                            label: "St. Helena"
                        },
                        {
                            value: 205,
                            label: "St. Pierre and Miquelon"
                        },
                        {
                            value: 206,
                            label: "Sudan"
                        },
                        {
                            value: 207,
                            label: "Surilabel"
                        },
                        {
                            value: 208,
                            label: "Svalbard and Jan Mayen Islands"
                        },
                        {
                            value: 209,
                            label: "Swaziland"
                        },
                        {
                            value: 210,
                            label: "Sweden"
                        },
                        {
                            value: 211,
                            label: "Switzerland"
                        },
                        {
                            value: 212,
                            label: "Syrian Arab Republic"
                        },
                        {
                            value: 213,
                            label: "Taiwan"
                        },
                        {
                            value: 214,
                            label: "Tajikistan"
                        },
                        {
                            value: 215,
                            label: "Tanzania, United Republic of"
                        },
                        {
                            value: 216,
                            label: "Thailand"
                        },
                        {
                            value: 217,
                            label: "Togo"
                        },
                        {
                            value: 218,
                            label: "Tokelau"
                        },
                        {
                            value: 219,
                            label: "Tonga"
                        },
                        {
                            value: 220,
                            label: "Trinvaluead and Tobago"
                        },
                        {
                            value: 221,
                            label: "Tunisia"
                        },
                        {
                            value: 222,
                            label: "Turkey"
                        },
                        {
                            value: 223,
                            label: "Turkmenistan"
                        },
                        {
                            value: 224,
                            label: "Turks and Caicos Islands"
                        },
                        {
                            value: 225,
                            label: "Tuvalu"
                        },
                        {
                            value: 226,
                            label: "Uganda"
                        },
                        {
                            value: 227,
                            label: "Ukraine"
                        },
                        {
                            value: 228,
                            label: "United Arab Emirates"
                        },
                        {
                            value: 229,
                            label: "United Kingdom"
                        },
                        {
                            value: 230,
                            label: "United States"
                        },
                        {
                            value: 231,
                            label: "United States minor outlying islands"
                        },
                        {
                            value: 232,
                            label: "Uruguay"
                        },
                        {
                            value: 233,
                            label: "Uzbekistan"
                        },
                        {
                            value: 234,
                            label: "Vanuatu"
                        },
                        {
                            value: 235,
                            label: "Vatican City State"
                        },
                        {
                            value: 236,
                            label: "Venezuela"
                        },
                        {
                            value: 237,
                            label: "Vietnam"
                        },
                        {
                            value: 238,
                            label: "Virgin Islands (British)"
                        },
                        {
                            value: 239,
                            label: "Virgin Islands (U.S.)"
                        },
                        {
                            value: 240,
                            label: "Wallis and Futuna Islands"
                        },
                        {
                            value: 241,
                            label: "Western Sahara"
                        },
                        {
                            value: 242,
                            label: "Yemen"
                        },
                        {
                            value: 243,
                            label: "Zaire"
                        },
                        {
                            value: 244,
                            label: "Zambia"
                        },
                        {
                            value: 245,
                            label: "Zimbabwe"
                        }
                        ]}
                        onChange={(value) => {this.updateProfileInfoSelect(value,"country_id");}}

                      />
                    {
                      this.state.profileFormErrors.country_id.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.country_id}</div>
                    }
                  </div>
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Currency</div>
                    <Select
                        size="large"
                        id="currency"
                        value={this.state.profileForm.currency_id}
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => {this.updateProfileInfoSelect(e,"currency_id");}}
                        options={[
                          {
                            value: 3,
                            label: "Afghanis",
                        },
                        {
                            value: 1,
                            label: "Leke",
                        },
                        {
                            value: 2,
                            label: "Dollars",
                        },
                        {
                            value: 4,
                            label: "Pesos",
                        },
                        {
                            value: 5,
                            label: "Guilders",
                        },
                        {
                            value: 6,
                            label: "Dollars",
                        },
                        {
                            value: 7,
                            label: "New Manats",
                        },
                        {
                            value: 8,
                            label: "Dollars",
                        },
                        {
                            value: 9,
                            label: "Dollars",
                        },
                        {
                            value: 10,
                            label: "Rubles",
                        },
                        {
                            value: 11,
                            label: "Euro",
                        },
                        {
                            value: 12,
                            label: "Dollars",
                        },
                        {
                            value: 13,
                            label: "Dollars",
                        },
                        {
                            value: 14,
                            label: "Bolivianos",
                        },
                        {
                            value: 15,
                            label: "Convertible Marka",
                        },
                        {
                            value: 16,
                            label: "Pula",
                        },
                        {
                            value: 18,
                            label: "Reais",
                        },
                        {
                            value: 19,
                            label: "Pounds",
                        },
                        {
                            value: 20,
                            label: "Dollars",
                        },
                        {
                            value: 17,
                            label: "Leva",
                        },
                        {
                            value: 21,
                            label: "Riels",
                        },
                        {
                            value: 22,
                            label: "Dollars",
                        },
                        {
                            value: 23,
                            label: "Dollars",
                        },
                        {
                            value: 24,
                            label: "Pesos",
                        },
                        {
                            value: 25,
                            label: "Yuan Renminbi",
                        },
                        {
                            value: 26,
                            label: "Pesos",
                        },
                        {
                            value: 27,
                            label: "Colón",
                        },
                        {
                            value: 28,
                            label: "Kuna",
                        },
                        {
                            value: 29,
                            label: "Pesos",
                        },
                        {
                            value: 30,
                            label: "Euro",
                        },
                        {
                            value: 31,
                            label: "Koruny",
                        },
                        {
                            value: 32,
                            label: "Kroner",
                        },
                        {
                            value: 33,
                            label: "Pesos",
                        },
                        {
                            value: 34,
                            label: "Dollars",
                        },
                        {
                            value: 35,
                            label: "Pounds",
                        },
                        {
                            value: 36,
                            label: "Colones",
                        },
                        {
                            value: 37,
                            label: "Pounds",
                        },
                        {
                            value: 138,
                            label: "Ethiopian birr",
                        },
                        {
                            value: 38,
                            label: "Euro",
                        },
                        {
                            value: 39,
                            label: "Pounds",
                        },
                        {
                            value: 40,
                            label: "Dollars",
                        },
                        {
                            value: 41,
                            label: "Euro",
                        },
                        {
                            value: 42,
                            label: "Cedis",
                        },
                        {
                            value: 43,
                            label: "Pounds",
                        },
                        {
                            value: 133,
                            label: "Bitcoin",
                        },
                        {
                            value: 134,
                            label: "Ethereum",
                        },
                        {
                            value: 44,
                            label: "Euro",
                        },
                        {
                            value: 45,
                            label: "Quetzales",
                        },
                        {
                            value: 46,
                            label: "Pounds",
                        },
                        {
                            value: 47,
                            label: "Dollars",
                        },
                        {
                            value: 48,
                            label: "Euro",
                        },
                        {
                            value: 49,
                            label: "Lempiras",
                        },
                        {
                            value: 50,
                            label: "Dollars",
                        },
                        {
                            value: 51,
                            label: "Forint",
                        },
                        {
                            value: 52,
                            label: "Kronur",
                        },
                        {
                            value: 53,
                            label: "Rupees",
                        },
                        {
                            value: 54,
                            label: "Rupiahs",
                            "currency_id": "Rials",
                        },
                        {
                            value: 56,
                            label: "Euro",
                        },
                        {
                            value: 57,
                            label: "Pounds",
                        },
                        {
                            value: 58,
                            label: "New Shekels",
                        },
                        {
                            value: 59,
                            label: "Euro",
                        },
                        {
                            value: 60,
                            label: "Dollars",
                        },
                        {
                            value: 61,
                            label: "Yen",
                        },
                        {
                            value: 62,
                            label: "Pounds",
                        },
                        {
                            value: 63,
                            label: "Tenge",
                        },
                        {
                            value: 135,
                            label: "Kenyan shilling",
                        },
                        {
                            value: 64,
                            label: "Won",
                        },
                        {
                            value: 65,
                            label: "Won",
                        },
                        {
                            value: 66,
                            label: "Soms",
                        },
                        {
                            value: 67,
                            label: "Kips",
                        },
                        {
                            value: 68,
                            label: "Lati",
                        },
                        {
                            value: 69,
                            label: "Pounds",
                        },
                        {
                            value: 70,
                            label: "Dollars",
                        },
                        {
                            value: 71,
                            label: "Switzerland Francs",
                        },
                        {
                            value: 72,
                            label: "Litai",
                        },
                        {
                            value: 73,
                            label: "Euro",
                        },
                        {
                            value: 74,
                            label: "Denars",
                        },
                        {
                            value: 139,
                            label: "Malawian kwacha",
                        },
                        {
                            value: 75,
                            label: "Ringgits",
                        },
                        {
                            value: 76,
                            label: "Euro",
                        },
                        {
                            value: 77,
                            label: "Rupees",
                        },
                        {
                            value: 78,
                            label: "Pesos",
                        },
                        {
                            value: 79,
                            label: "Tugriks",
                        },
                        {
                            value: 80,
                            label: "Meticais",
                        },
                        {
                            value: 81,
                            label: "Dollars",
                        },
                        {
                            value: 82,
                            label: "Rupees",
                        },
                        {
                            value: 84,
                            label: "Euro",
                        },
                        {
                            value: 83,
                            label: "Guilders",
                        },
                        {
                            value: 85,
                            label: "Dollars",
                        },
                        {
                            value: 86,
                            label: "Cordobas",
                        },
                        {
                            value: 87,
                            label: "Nairas",
                        },
                        {
                            value: 88,
                            label: "Won",
                        },
                        {
                            value: 89,
                            label: "Krone",
                        },
                        {
                            value: 90,
                            label: "Rials",
                        },
                        {
                            value: 91,
                            label: "Rupees",
                        },
                        {
                            value: 92,
                            label: "Balboa",
                        },
                        {
                            value: 93,
                            label: "Guarani",
                        },
                        {
                            value: 94,
                            label: "Nuevos Soles",
                        },
                        {
                            value: 95,
                            label: "Pesos",
                        },
                        {
                            value: 96,
                            label: "Zlotych",
                        },
                        {
                            value: 97,
                            label: "Rials",
                        },
                        {
                            value: 98,
                            label: "New Lei",
                        },
                        {
                            value: 99,
                            label: "Rubles",
                        },
                        {
                            value: 136,
                            label: "Rwandan franc",
                        },
                        {
                            value: 100,
                            label: "Pounds",
                        },
                        {
                            value: 101,
                            label: "Riyals",
                        },
                        {
                            value: 102,
                            label: "Dinars",
                        },
                        {
                            value: 103,
                            label: "Rupees",
                        },
                        {
                            value: 104,
                            label: "Sierra Leonean leone",
                        },
                        {
                            value: 105,
                            label: "Singapore Dollars",
                        },
                        {
                            value: 106,
                            label: "Euro",
                        },
                        {
                            value: 107,
                            label: "Dollars",
                        },
                        {
                            value: 108,
                            label: "Shillings",
                        },
                        {
                            value: 109,
                            label: "Rand",
                        },
                        {
                            value: 110,
                            label: "Won",
                        },
                        {
                            value: 111,
                            label: "Euro",
                        },
                        {
                            value: 112,
                            label: "Rupees",
                        },
                        {
                            value: 115,
                            label: "Dollars",
                        },
                        {
                            value: 113,
                            label: "Kronor",
                        },
                        {
                            value: 114,
                            label: "Francs",
                        },
                        {
                            value: 116,
                            label: "Pounds",
                        },
                        {
                            value: 117,
                            label: "New Dollars",
                        },
                        {
                            value: 118,
                            label: "Baht",
                        },
                        {
                            value: 119,
                            label: "Dollars",
                        },
                        {
                            value: 120,
                            label: "Lira",
                        },
                        {
                            value: 121,
                            label: "Liras",
                        },
                        {
                            value: 122,
                            label: "Dollars",
                        },
                        {
                            value: 137,
                            label: "Ugandan shilling",
                        },
                        {
                            value: 123,
                            label: "Hryvnia",
                        },
                        {
                            value: 124,
                            label: "Pounds",
                        },
                        {
                            value: 125,
                            label: "Dollars",
                        },
                        {
                            value: 126,
                            label: "Pesos",
                        },
                        {
                            value: 127,
                            label: "Sums",
                        },
                        {
                            value: 128,
                            label: "Euro",
                        },
                        {
                            value: 129,
                            label: "Bolivares Fuertes",
                        },
                        {
                            value: 130,
                            label: "Dong",
                        },
                        {
                            value: 131,
                            label: "Rials",
                        },
                        {
                            value: 140,
                            label: "Zambian kwacha",
                        },
                        {
                            value: 132,
                            label: "Zimbabwe Dollars",
                        }
                        ]}
                      />
                    {
                      this.state.profileFormErrors.currency_id.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.currency_id}</div>
                    }
                  </div>
                  
                  <div className={styles.flexCol3}>
                    <div className={styles.text}>Industry</div>
                    <Select
                      size="large"
                      id="industry"
                        value={this.state.profileForm.industry_id}
                        style={{
                        width: "100%",
                      }}
                      onChange={(e) => {this.updateProfileInfoSelect(e,"industry_id");}}
                      options={[
                        {
                          value: 1,
                          label: "Accounting/Auditing"
                      },
                      {
                          value: 2,
                          label: "Administration"
                      },
                      {
                          value: 3,
                          label: "Advertising"
                      },
                      {
                          value: 4,
                          label: "Aerospace and Defense"
                      },
                      {
                          value: 5,
                          label: "Agriculture/Forestry/Fishing"
                      },
                      {
                          value: 6,
                          label: "Airlines/Aviation"
                      },
                      {
                          value: 7,
                          label: "Architecture"
                      },
                      {
                          value: 8,
                          label: "Arts/Entertainment/and Media"
                      },
                      {
                          value: 9,
                          label: "Automotive"
                      },
                      {
                          value: 10,
                          label: "Aviation/Marine Refueling"
                      },
                      {
                          value: 11,
                          label: "Banking"
                      },
                      {
                          value: 12,
                          label: "Biotechnology"
                      },
                      {
                          value: 13,
                          label: "Business Support"
                      },
                      {
                          value: 14,
                          label: "Catering/Food Services/Restaurants"
                      },
                      {
                          value: 15,
                          label: "Community/Social Services/and Nonprofit"
                      },
                      {
                          value: 16,
                          label: "Computer/Hardware"
                      },
                      {
                          value: 17,
                          label: "Computer/Software"
                      },
                      {
                          value: 18,
                          label: "Construction"
                      },
                      {
                          value: 19,
                          label: "Construction/Civil Engineering"
                      },
                      {
                          value: 20,
                          label: "Consulting Services"
                      },
                      {
                          value: 21,
                          label: "Contracts/Purchasing"
                      },
                      {
                          value: 22,
                          label: "Customer Service"
                      },
                      {
                          value: 23,
                          label: "Distributions and Logistics"
                      },
                      {
                          value: 24,
                          label: "Education, Training, and Library"
                      },
                      {
                          value: 25,
                          label: "Employment Placement Agencies/Recruiting"
                      },
                      {
                          value: 26,
                          label: "Energy"
                      },
                      {
                          value: 27,
                          label: "Engineering"
                      },
                      {
                          value: 28,
                          label: "Entertainment"
                      },
                      {
                          value: 29,
                          label: "Facilities Management"
                      },
                      {
                          value: 30,
                          label: "Fashion Design"
                      },
                      {
                          value: 31,
                          label: "Finance/Economics"
                      },
                      {
                          value: 32,
                          label: "Financial Services"
                      },
                      {
                          value: 33,
                          label: "FMCG"
                      },
                      {
                          value: 34,
                          label: "Government Sector"
                      },
                      {
                          value: 35,
                          label: "Graphic Design"
                      },
                      {
                          value: 36,
                          label: "Healthcare, other"
                      },
                      {
                          value: 37,
                          label: "Healthcare, Practitioner and Technician"
                      },
                      {
                          value: 38,
                          label: "Hospitality/Tourism/Travel"
                      },
                      {
                          value: 39,
                          label: "Human Resources"
                      },
                      {
                          value: 40,
                          label: "Industrial"
                      },
                      {
                          value: 41,
                          label: "Information Technology"
                      },
                      {
                          value: 42,
                          label: "Installation, Maintenance, and Repair"
                      },
                      {
                          value: 43,
                          label: "Insurance"
                      },
                      {
                          value: 44,
                          label: "Interior design"
                      },
                      {
                          value: 45,
                          label: "Internet/E-commerce"
                      },
                      {
                          value: 46,
                          label: "Islamic Banking"
                      },
                      {
                          value: 47,
                          label: "Journalism"
                      },
                      {
                          value: 48,
                          label: "Laboratory/QC"
                      },
                      {
                          value: 49,
                          label: "Law Enforcement/Security Services"
                      },
                      {
                          value: 50,
                          label: "Legal"
                      },
                      {
                          value: 51,
                          label: "LPG Bottling"
                      },
                      {
                          value: 52,
                          label: "Lubricants/Greases Blending"
                      },
                      {
                          value: 53,
                          label: "Management"
                      },
                      {
                          value: 54,
                          label: "Manufacturing"
                      },
                      {
                          value: 55,
                          label: "Manufacturing and Production"
                      },
                      {
                          value: 56,
                          label: "Marine Services"
                      },
                      {
                          value: 57,
                          label: "Marketing"
                      },
                      {
                          value: 58,
                          label: "Mechanical"
                      },
                      {
                          value: 59,
                          label: "Medical/Hospital"
                      },
                      {
                          value: 60,
                          label: "Merchandising"
                      },
                      {
                          value: 61,
                          label: "Military"
                      },
                      {
                          value: 62,
                          label: "Mining"
                      },
                      {
                          value: 63,
                          label: "Modeling"
                      },
                      {
                          value: 64,
                          label: "Natural Gas Distribution"
                      },
                      {
                          value: 65,
                          label: "Nursing"
                      },
                      {
                          value: 66,
                          label: "Oil/Gas"
                      },
                      {
                          value: 67,
                          label: "Other"
                      },
                      {
                          value: 68,
                          label: "Personal Care and Service"
                      },
                      {
                          value: 69,
                          label: "Petrochemicals"
                      },
                      {
                          value: 70,
                          label: "Pharmaceutical"
                      },
                      {
                          value: 71,
                          label: "Photography"
                      },
                      {
                          value: 72,
                          label: "Planning"
                      },
                      {
                          value: 73,
                          label: "Public Relations"
                      },
                      {
                          value: 74,
                          label: "Publishing"
                      },
                      {
                          value: 75,
                          label: "Quantity Survey"
                      },
                      {
                          value: 76,
                          label: "Real Estate"
                      },
                      {
                          value: 77,
                          label: "Retail/Wholesale"
                      },
                      {
                          value: 78,
                          label: "Safety/Environment"
                      },
                      {
                          value: 79,
                          label: "Sales"
                      },
                      {
                          value: 80,
                          label: "Science"
                      },
                      {
                          value: 81,
                          label: "Secretarial"
                      },
                      {
                          value: 82,
                          label: "Security"
                      },
                      {
                          value: 83,
                          label: "Shipping"
                      },
                      {
                          value: 84,
                          label: "Sports and Recreation"
                      },
                      {
                          value: 85,
                          label: "Support Services"
                      },
                      {
                          value: 86,
                          label: "Technical/Maintenance"
                      },
                      {
                          value: 87,
                          label: "Telecommunications"
                      },
                      {
                          value: 88,
                          label: "Telemarketing"
                      },
                      {
                          value: 89,
                          label: "Textiles"
                      },
                      {
                          value: 90,
                          label: "Translation"
                      },
                      {
                          value: 91,
                          label: "Transportation"
                      },
                      {
                          value: 92,
                          label: "Utilities"
                      },
                      {
                          value: 93,
                          label: "Vehicle Inspection"
                      },
                      {
                          value: 94,
                          label: "Warehousing"
                      }
                      ]}
                    />
                    {
                      this.state.profileFormErrors.industry_id.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.industry_id}</div>
                    }
                  </div>
                  
                </div>
                {
                  this.state.isRequesting ?
                  <button className={styles.content_box}>
                    <h3 className={styles.subtitle}>
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader">
                        <div className="text-center">  
                          <div className="spinner-border" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    </h3>
                  </button>
                  :
                  <button className={styles.content_box} onClick={() => {this.submitProfileForm()}}>
                    <h3 className={styles.subtitle}>
                      Sign Up
                    </h3>
                  </button>
                }

            </div>
    
            <hr className={styles.line} size={1} />
          </div>
          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Account creation was not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
      </div>

      )
  }
}

class BuiltBusinessXs extends React.Component {

    constructor(props){
        super(props);
        this.myRef = React.createRef();
        this.state = {
          userInfo: AuthService.getAuth().user,
          isLoading: false,
          isUploading: false,
          isUploadingPitchVideo: false,
          isUploadingLogo: false,
          isUploadingProducts: false,
          isRequesting: false,
          isDeleting: false,
          isDeleted: false,
          deleteErrorMessage: '',
          deleteSuccessMessage: '',
          deleteFileUrl: '',
          deleteFileType: '',
          profileForm: {
            name: '',
            email: '',
            country_id: 81,
            currency_id: 42,
            industry_id: 1,
            active: 1
          },
          profileFormErrors: {
            name: '',
            email: '',
            country_id: '',
            currency_id: '',
            industry_id: '',
          },
          errorMessage: '',
          successMessage: '',
          userToken: '',
        }
    
      }
    
      getUserToken () {
        this.setState({
          ...this.state,
            isLoading: true,
            isRequesting: true
        })
    
        axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", null, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
          console.log(responseInfo.data.built_Acc_user_token);
          this.setState({
            ...this.state,
            isLoading: false,
            isRequesting: false,
            userToken: responseInfo.data.built_Acc_user_token
          });   
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(error.response.status === 401 || error.response.status === 404){
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isLoading: false,
              isRequesting: false,
              errorMessage: errorMessage
            });
          }catch(e){
            console.log(error)
            // window.location = "/server-offline";
          }
        });
      }
    
      updateProfileInfo(event, name) {
        var profileForm = this.state.profileForm;
        let value = event.target.value;
        console.log(value);
        profileForm[name] = value;
        this.setState({
                ...this.state,
          profileForm: profileForm
        });
        }
    
      updateProfileInfoSelect(event, name) {
        var profileForm = this.state.profileForm;
        let value = event;
    
        profileForm[name] = value;
        this.setState({
                ...this.state,
          profileForm: profileForm
        });
        }
    
      submitProfileForm () {
    
            this.setState({
                ...this.state,
                isRequesting: true,
             });
    
            axios.post("https://web.builtaccounting.com/api/createbusiness", {
          ...this.state.profileForm
        }, AuthService.getBuiltSUHeadersUserToken(this.state.userToken))
            .then((response) => {
          let responseInfo = response.data;
    
            var userObj = reactLocalStorage.getObject('userObj');
            userObj.built_account = "added";
            reactLocalStorage.setObject('userObj', userObj);
    
              axios.post("https://web.builtaccounting.com/api/v2/oauth/accesstoken", {
                "name": this.state.profileForm.name,
                "business_id": responseInfo.id
              }, AuthService.getBuiltSUHeadersUserToken(this.state.userToken))
              .then((response) => {
                let responseInfo3 = response.data;
    
                axios.post(CONSTANTS.API_BASE_URL + "/accounting/add-user-token", {
                    "built_Acc_user_token": this.state.userToken,
                    "built_enc_id": responseInfo.enc_id,
                    "built_business_id": responseInfo3.accessToken
                }, AuthService.getAxiosHeaders())
                .then((response) => {
                  let responseInfo4 = response.data;
            
                  this.setState({
                    ...this.state,
                    isRequesting: false,
                    successMessage: "Your Built Business Account has been created successfully."
                  })
            
                  window.modalShow('success-modal');
                  setTimeout(() => {
                    window.location = "/built-dashboard";
                  }, 500);
            
                }).catch((error) => {
                  try{
                    let errorResponse = error.response.data;
            
                    if(errorResponse.status === 401 || errorResponse.status === 404){
                      alert('ha1');
                      window.location = "/not-found";
                      return;
                    }
            
                    let errorMessage = 'Error: Could not connect to server';
                    if(errorResponse.hasOwnProperty("message")){
                      errorMessage = errorResponse.message;
                    }
            
                    this.setState({
                      ...this.state,
                      isRequesting: false,
                      errorMessage: errorMessage,
                    });
                    alert('ha1');
            
                    window.scrollTo({top: 0, behavior: 'smooth'});
                  }catch(e){
                    window.location = "/server-offline";
                  }
                });
          
              }).catch((error) => {
                try{
                  let errorResponse = error.response.data;
          
                  if(errorResponse.status === 401 || errorResponse.status === 404){
                    alert('ha1');
                    window.location = "/not-found";
                    return;
                  }
          
                  let errorMessage = 'Error: Could not connect to server';
                  if(errorResponse.hasOwnProperty("message")){
                    errorMessage = errorResponse.message;
                  }
          
                  this.setState({
                    ...this.state,
                    isRequesting: false,
                    errorMessage: errorMessage,
                  });
                  window.modalShow('error-modal');
          
                  window.scrollTo({top: 0, behavior: 'smooth'});
                }catch(e){
                    //window.location = "/server-offline";
                }
              });
    
            }).catch((error) => {
          try{
            let errorResponse = error.response;
    
            if(error.response.status === 401 || error.response.status === 404){
              alert('heb');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            if (typeof errorResponse.errors.email === 'undefined'){
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
            }else{
              this.setState({
                ...this.state,
                isRequesting: false,
              errorMessage: errorResponse.errors.email,
            });
            }
    
            console.log(error.response);
            window.modalShow('error-modal');
            window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
              window.location = "/server-offline";
          }
            });
    
        }
    
      componentDidMount () {
        window.KTComponents.init();
        AuthService.builtUserStatusSecond();
        AuthService.builtBusinessStatus();
        this.getUserToken();
    
      }

  render(){
      return (
        <div className={cn(stylesXs.root, 'accounting-account')}>
          <div className={stylesXs.flexCol}>
            <div
              className={stylesXs.content_box1}
              style={{ '--src': `url(${require('../../../assets/accounting/african-american-business-woman-working-computer_1303-9873.jpg')})` }}>
            <h1 className={stylesXs.title}></h1>
            </div>
    
            <div className={stylesXs.flexCol1}>
            <img
                className={stylesXs.builtlogo}
                src={require('../../../assets/accounting/built-logo.png')}
                alt="alt text"
              />
            <h1 className={stylesXs.title}>Now, let's register your business with Built Accounting</h1>

              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Business Name</div>
                <Input size="large" type="text" id="name" name="name" placeholder="Enter your business name" onChange={(e) => {this.updateProfileInfo(e,"name");}} />
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Buisness Email</div>
                <Input size="large" required type="email" id="email" placeholder="Enter your business email" name="email" onChange={(e) => {this.updateProfileInfo(e,"email");}}  />
                {
                  this.state.profileFormErrors.email.length > 0 && 
                  <div className="invalid-feedback">{this.state.profileFormErrors.email}</div>
                }
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Country</div>
                <Select
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onChange={(value) => {this.updateProfileInfoSelect(value,"country_id");}}
                        options={[
                          {
                            "value": 1,
                            "label": "Afghanistan"
                        },
                        {
                            "value": 2,
                            "label": "Albania"
                        },
                        {
                            "value": 3,
                            "label": "Algeria"
                        },
                        {
                            "value": 4,
                            "label": "American Samoa"
                        },
                        {
                            "value": 5,
                            "label": "Andorra"
                        },
                        {
                            "value": 6,
                            "label": "Angola"
                        },
                        {
                            "value": 7,
                            "label": "Anguilla"
                        },
                        {
                            "value": 8,
                            "label": "Antarctica"
                        },
                        {
                            "value": 9,
                            "label": "Antigua and Barbuda"
                        },
                        {
                            "value": 10,
                            "label": "Argentina"
                        },
                        {
                            "value": 11,
                            "label": "Armenia"
                        },
                        {
                            "value": 12,
                            "label": "Aruba"
                        },
                        {
                            "value": 13,
                            "label": "Australia"
                        },
                        {
                            "value": 14,
                            "label": "Austria"
                        },
                        {
                            "value": 15,
                            "label": "Azerbaijan"
                        },
                        {
                            "value": 16,
                            "label": "Bahamas"
                        },
                        {
                            "value": 17,
                            "label": "Bahrain"
                        },
                        {
                            "value": 18,
                            "label": "Bangladesh"
                        },
                        {
                            "value": 19,
                            "label": "Barbados"
                        },
                        {
                            "value": 20,
                            "label": "Belarus"
                        },
                        {
                            "value": 21,
                            "label": "Belgium"
                        },
                        {
                            "value": 22,
                            "label": "Belize"
                        },
                        {
                            "value": 23,
                            "label": "Benin"
                        },
                        {
                            "value": 24,
                            "label": "Bermuda"
                        },
                        {
                            "value": 25,
                            "label": "Bhutan"
                        },
                        {
                            "value": 26,
                            "label": "Bolivia"
                        },
                        {
                            "value": 27,
                            "label": "Bosnia and Herzegovina"
                        },
                        {
                            "value": 28,
                            "label": "Botswana"
                        },
                        {
                            "value": 29,
                            "label": "Bouvet Island"
                        },
                        {
                            "value": 30,
                            "label": "Brazil"
                        },
                        {
                            "value": 31,
                            "label": "British Indian Ocean Territory"
                        },
                        {
                            "value": 32,
                            "label": "Brunei Darussalam"
                        },
                        {
                            "value": 33,
                            "label": "Bulgaria"
                        },
                        {
                            "value": 34,
                            "label": "Burkina Faso"
                        },
                        {
                            "value": 35,
                            "label": "Burundi"
                        },
                        {
                            "value": 36,
                            "label": "Cambodia"
                        },
                        {
                            "value": 37,
                            "label": "Cameroon"
                        },
                        {
                            "value": 38,
                            "label": "Canada"
                        },
                        {
                            "value": 39,
                            "label": "Cape Verde"
                        },
                        {
                            "value": 40,
                            "label": "Cayman Islands"
                        },
                        {
                            "value": 41,
                            "label": "Central African Republic"
                        },
                        {
                            "value": 42,
                            "label": "Chad"
                        },
                        {
                            "value": 43,
                            "label": "Chile"
                        },
                        {
                            "value": 44,
                            "label": "China"
                        },
                        {
                            "value": 45,
                            "label": "Christmas Island"
                        },
                        {
                            "value": 46,
                            "label": "Cocos (Keeling) Islands"
                        },
                        {
                            "value": 47,
                            "label": "Colombia"
                        },
                        {
                            "value": 48,
                            "label": "Comoros"
                        },
                        {
                            "value": 49,
                            "label": "Congo"
                        },
                        {
                            "value": 50,
                            "label": "Cook Islands"
                        },
                        {
                            "value": 51,
                            "label": "Costa Rica"
                        },
                        {
                            "value": 52,
                            "label": "Croatia (Hrvatska)"
                        },
                        {
                            "value": 53,
                            "label": "Cuba"
                        },
                        {
                            "value": 54,
                            "label": "Cyprus"
                        },
                        {
                            "value": 55,
                            "label": "Czech Republic"
                        },
                        {
                            "value": 56,
                            "label": "Denmark"
                        },
                        {
                            "value": 57,
                            "label": "Djibouti"
                        },
                        {
                            "value": 58,
                            "label": "Dominica"
                        },
                        {
                            "value": 59,
                            "label": "Dominican Republic"
                        },
                        {
                            "value": 60,
                            "label": "East Timor"
                        },
                        {
                            "value": 61,
                            "label": "Ecuador"
                        },
                        {
                            "value": 62,
                            "label": "Egypt"
                        },
                        {
                            "value": 63,
                            "label": "El Salvador"
                        },
                        {
                            "value": 64,
                            "label": "Equatorial Guinea"
                        },
                        {
                            "value": 65,
                            "label": "Eritrea"
                        },
                        {
                            "value": 66,
                            "label": "Estonia"
                        },
                        {
                            "value": 67,
                            "label": "Ethiopia"
                        },
                        {
                            "value": 68,
                            "label": "Falkland Islands (Malvinas)"
                        },
                        {
                            "value": 69,
                            "label": "Faroe Islands"
                        },
                        {
                            "value": 70,
                            "label": "Fiji"
                        },
                        {
                            "value": 71,
                            "label": "Finland"
                        },
                        {
                            "value": 72,
                            "label": "France"
                        },
                        {
                            "value": 73,
                            "label": "France, Metropolitan"
                        },
                        {
                            "value": 74,
                            "label": "French Guiana"
                        },
                        {
                            "value": 75,
                            "label": "French Polynesia"
                        },
                        {
                            "value": 76,
                            "label": "French Southern Territories"
                        },
                        {
                            "value": 77,
                            "label": "Gabon"
                        },
                        {
                            "value": 78,
                            "label": "Gambia"
                        },
                        {
                            "value": 79,
                            "label": "Georgia"
                        },
                        {
                            "value": 80,
                            "label": "Germany"
                        },
                        {
                            "value": 81,
                            "label": "Ghana"
                        },
                        {
                            "value": 82,
                            "label": "Gibraltar"
                        },
                        {
                            "value": 84,
                            "label": "Greece"
                        },
                        {
                            "value": 85,
                            "label": "Greenland"
                        },
                        {
                            "value": 86,
                            "label": "Grenada"
                        },
                        {
                            "value": 87,
                            "label": "Guadeloupe"
                        },
                        {
                            "value": 88,
                            "label": "Guam"
                        },
                        {
                            "value": 89,
                            "label": "Guatemala"
                        },
                        {
                            "value": 83,
                            "label": "Guernsey"
                        },
                        {
                            "value": 90,
                            "label": "Guinea"
                        },
                        {
                            "value": 91,
                            "label": "Guinea-Bissau"
                        },
                        {
                            "value": 92,
                            "label": "Guyana"
                        },
                        {
                            "value": 93,
                            "label": "Haiti"
                        },
                        {
                            "value": 94,
                            "label": "Heard and Mc Donald Islands"
                        },
                        {
                            "value": 95,
                            "label": "Honduras"
                        },
                        {
                            "value": 96,
                            "label": "Hong Kong"
                        },
                        {
                            "value": 97,
                            "label": "Hungary"
                        },
                        {
                            "value": 98,
                            "label": "Iceland"
                        },
                        {
                            "value": 99,
                            "label": "India"
                        },
                        {
                            "value": 101,
                            "label": "Indonesia"
                        },
                        {
                            "value": 102,
                            "label": "Iran (Islamic Republic of)"
                        },
                        {
                            "value": 103,
                            "label": "Iraq"
                        },
                        {
                            "value": 104,
                            "label": "Ireland"
                        },
                        {
                            "value": 100,
                            "label": "Isle of Man"
                        },
                        {
                            "value": 105,
                            "label": "Israel"
                        },
                        {
                            "value": 106,
                            "label": "Italy"
                        },
                        {
                            "value": 107,
                            "label": "Ivory Coast"
                        },
                        {
                            "value": 109,
                            "label": "Jamaica"
                        },
                        {
                            "value": 110,
                            "label": "Japan"
                        },
                        {
                            "value": 108,
                            "label": "Jersey"
                        },
                        {
                            "value": 111,
                            "label": "Jordan"
                        },
                        {
                            "value": 112,
                            "label": "Kazakhstan"
                        },
                        {
                            "value": 113,
                            "label": "Kenya"
                        },
                        {
                            "value": 114,
                            "label": "Kiribati"
                        },
                        {
                            "value": 115,
                            "label": "Korea, Democratic People's Republic of"
                        },
                        {
                            "value": 116,
                            "label": "Korea, Republic of"
                        },
                        {
                            "value": 117,
                            "label": "Kosovo"
                        },
                        {
                            "value": 118,
                            "label": "Kuwait"
                        },
                        {
                            "value": 119,
                            "label": "Kyrgyzstan"
                        },
                        {
                            "value": 120,
                            "label": "Lao People's Democratic Republic"
                        },
                        {
                            "value": 121,
                            "label": "Latvia"
                        },
                        {
                            "value": 122,
                            "label": "Lebanon"
                        },
                        {
                            "value": 123,
                            "label": "Lesotho"
                        },
                        {
                            "value": 124,
                            "label": "Liberia"
                        },
                        {
                            "value": 125,
                            "label": "Libyan Arab Jamahiriya"
                        },
                        {
                            "value": 126,
                            "label": "Liechtenstein"
                        },
                        {
                            "value": 127,
                            "label": "Lithuania"
                        },
                        {
                            "value": 128,
                            "label": "Luxembourg"
                        },
                        {
                            "value": 129,
                            "label": "Macau"
                        },
                        {
                            "value": 130,
                            "label": "Macedonia"
                        },
                        {
                            "value": 131,
                            "label": "Madagascar"
                        },
                        {
                            "value": 132,
                            "label": "Malawi"
                        },
                        {
                            "value": 133,
                            "label": "Malaysia"
                        },
                        {
                            "value": 134,
                            "label": "Maldives"
                        },
                        {
                            "value": 135,
                            "label": "Mali"
                        },
                        {
                            "value": 136,
                            "label": "Malta"
                        },
                        {
                            "value": 137,
                            "label": "Marshall Islands"
                        },
                        {
                            "value": 138,
                            "label": "Martinique"
                        },
                        {
                            "value": 139,
                            "label": "Mauritania"
                        },
                        {
                            "value": 140,
                            "label": "Mauritius"
                        },
                        {
                            "value": 141,
                            "label": "Mayotte"
                        },
                        {
                            "value": 142,
                            "label": "Mexico"
                        },
                        {
                            "value": 143,
                            "label": "Micronesia, Federated States of"
                        },
                        {
                            "value": 144,
                            "label": "Moldova, Republic of"
                        },
                        {
                            "value": 145,
                            "label": "Monaco"
                        },
                        {
                            "value": 146,
                            "label": "Mongolia"
                        },
                        {
                            "value": 147,
                            "label": "Montenegro"
                        },
                        {
                            "value": 148,
                            "label": "Montserrat"
                        },
                        {
                            "value": 149,
                            "label": "Morocco"
                        },
                        {
                            "value": 150,
                            "label": "Mozambique"
                        },
                        {
                            "value": 151,
                            "label": "Myanmar"
                        },
                        {
                            "value": 152,
                            "label": "Namibia"
                        },
                        {
                            "value": 153,
                            "label": "Nauru"
                        },
                        {
                            "value": 154,
                            "label": "Nepal"
                        },
                        {
                            "value": 155,
                            "label": "Netherlands"
                        },
                        {
                            "value": 156,
                            "label": "Netherlands Antilles"
                        },
                        {
                            "value": 157,
                            "label": "New Caledonia"
                        },
                        {
                            "value": 158,
                            "label": "New Zealand"
                        },
                        {
                            "value": 159,
                            "label": "Nicaragua"
                        },
                        {
                            "value": 160,
                            "label": "Niger"
                        },
                        {
                            "value": 161,
                            "label": "Nigeria"
                        },
                        {
                            "value": 162,
                            "label": "Niue"
                        },
                        {
                            "value": 163,
                            "label": "Norfolk Island"
                        },
                        {
                            "value": 164,
                            "label": "Northern Mariana Islands"
                        },
                        {
                            "value": 165,
                            "label": "Norway"
                        },
                        {
                            "value": 166,
                            "label": "Oman"
                        },
                        {
                            "value": 167,
                            "label": "Pakistan"
                        },
                        {
                            "value": 168,
                            "label": "Palau"
                        },
                        {
                            "value": 169,
                            "label": "Palestine"
                        },
                        {
                            "value": 170,
                            "label": "Panama"
                        },
                        {
                            "value": 171,
                            "label": "Papua New Guinea"
                        },
                        {
                            "value": 172,
                            "label": "Paraguay"
                        },
                        {
                            "value": 173,
                            "label": "Peru"
                        },
                        {
                            "value": 174,
                            "label": "Philippines"
                        },
                        {
                            "value": 175,
                            "label": "Pitcairn"
                        },
                        {
                            "value": 176,
                            "label": "Poland"
                        },
                        {
                            "value": 177,
                            "label": "Portugal"
                        },
                        {
                            "value": 178,
                            "label": "Puerto Rico"
                        },
                        {
                            "value": 179,
                            "label": "Qatar"
                        },
                        {
                            "value": 180,
                            "label": "Reunion"
                        },
                        {
                            "value": 181,
                            "label": "Romania"
                        },
                        {
                            "value": 182,
                            "label": "Russian Federation"
                        },
                        {
                            "value": 183,
                            "label": "Rwanda"
                        },
                        {
                            "value": 184,
                            "label": "Saint Kitts and Nevis"
                        },
                        {
                            "value": 185,
                            "label": "Saint Lucia"
                        },
                        {
                            "value": 186,
                            "label": "Saint Vincent and the Grenadines"
                        },
                        {
                            "value": 187,
                            "label": "Samoa"
                        },
                        {
                            "value": 188,
                            "label": "San Marino"
                        },
                        {
                            "value": 189,
                            "label": "Sao Tome and Principe"
                        },
                        {
                            "value": 190,
                            "label": "Saudi Arabia"
                        },
                        {
                            "value": 191,
                            "label": "Senegal"
                        },
                        {
                            "value": 192,
                            "label": "Serbia"
                        },
                        {
                            "value": 193,
                            "label": "Seychelles"
                        },
                        {
                            "value": 194,
                            "label": "Sierra Leone"
                        },
                        {
                            "value": 195,
                            "label": "Singapore"
                        },
                        {
                            "value": 196,
                            "label": "Slovakia"
                        },
                        {
                            "value": 197,
                            "label": "Slovenia"
                        },
                        {
                            "value": 198,
                            "label": "Solomon Islands"
                        },
                        {
                            "value": 199,
                            "label": "Somalia"
                        },
                        {
                            "value": 200,
                            "label": "South Africa"
                        },
                        {
                            "value": 201,
                            "label": "South Georgia South Sandwich Islands"
                        },
                        {
                            "value": 202,
                            "label": "Spain"
                        },
                        {
                            "value": 203,
                            "label": "Sri Lanka"
                        },
                        {
                            "value": 204,
                            "label": "St. Helena"
                        },
                        {
                            "value": 205,
                            "label": "St. Pierre and Miquelon"
                        },
                        {
                            "value": 206,
                            "label": "Sudan"
                        },
                        {
                            "value": 207,
                            "label": "Surilabel"
                        },
                        {
                            "value": 208,
                            "label": "Svalbard and Jan Mayen Islands"
                        },
                        {
                            "value": 209,
                            "label": "Swaziland"
                        },
                        {
                            "value": 210,
                            "label": "Sweden"
                        },
                        {
                            "value": 211,
                            "label": "Switzerland"
                        },
                        {
                            "value": 212,
                            "label": "Syrian Arab Republic"
                        },
                        {
                            "value": 213,
                            "label": "Taiwan"
                        },
                        {
                            "value": 214,
                            "label": "Tajikistan"
                        },
                        {
                            "value": 215,
                            "label": "Tanzania, United Republic of"
                        },
                        {
                            "value": 216,
                            "label": "Thailand"
                        },
                        {
                            "value": 217,
                            "label": "Togo"
                        },
                        {
                            "value": 218,
                            "label": "Tokelau"
                        },
                        {
                            "value": 219,
                            "label": "Tonga"
                        },
                        {
                            "value": 220,
                            "label": "Trinvaluead and Tobago"
                        },
                        {
                            "value": 221,
                            "label": "Tunisia"
                        },
                        {
                            "value": 222,
                            "label": "Turkey"
                        },
                        {
                            "value": 223,
                            "label": "Turkmenistan"
                        },
                        {
                            "value": 224,
                            "label": "Turks and Caicos Islands"
                        },
                        {
                            "value": 225,
                            "label": "Tuvalu"
                        },
                        {
                            "value": 226,
                            "label": "Uganda"
                        },
                        {
                            "value": 227,
                            "label": "Ukraine"
                        },
                        {
                            "value": 228,
                            "label": "United Arab Emirates"
                        },
                        {
                            "value": 229,
                            "label": "United Kingdom"
                        },
                        {
                            "value": 230,
                            "label": "United States"
                        },
                        {
                            "value": 231,
                            "label": "United States minor outlying islands"
                        },
                        {
                            "value": 232,
                            "label": "Uruguay"
                        },
                        {
                            "value": 233,
                            "label": "Uzbekistan"
                        },
                        {
                            "value": 234,
                            "label": "Vanuatu"
                        },
                        {
                            "value": 235,
                            "label": "Vatican City State"
                        },
                        {
                            "value": 236,
                            "label": "Venezuela"
                        },
                        {
                            "value": 237,
                            "label": "Vietnam"
                        },
                        {
                            "value": 238,
                            "label": "Virgin Islands (British)"
                        },
                        {
                            "value": 239,
                            "label": "Virgin Islands (U.S.)"
                        },
                        {
                            "value": 240,
                            "label": "Wallis and Futuna Islands"
                        },
                        {
                            "value": 241,
                            "label": "Western Sahara"
                        },
                        {
                            "value": 242,
                            "label": "Yemen"
                        },
                        {
                            "value": 243,
                            "label": "Zaire"
                        },
                        {
                            "value": 244,
                            "label": "Zambia"
                        },
                        {
                            "value": 245,
                            "label": "Zimbabwe"
                        }
                        ]}
                      />
                    {
                      this.state.profileFormErrors.country_id.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.country_id}</div>
                    }
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Currency</div>
                <Select
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onChange={(value) => {this.updateProfileInfoSelect(value,"currency_id");}}
                        options={[
                          {
                            "value": 3,
                            "label": "Afghanis",
                        },
                        {
                            "value": 1,
                            "label": "Leke",
                        },
                        {
                            "value": 2,
                            "label": "Dollars",
                        },
                        {
                            "value": 4,
                            "label": "Pesos",
                        },
                        {
                            "value": 5,
                            "label": "Guilders",
                        },
                        {
                            "value": 6,
                            "label": "Dollars",
                        },
                        {
                            "value": 7,
                            "label": "New Manats",
                        },
                        {
                            "value": 8,
                            "label": "Dollars",
                        },
                        {
                            "value": 9,
                            "label": "Dollars",
                        },
                        {
                            "value": 10,
                            "label": "Rubles",
                        },
                        {
                            "value": 11,
                            "label": "Euro",
                        },
                        {
                            "value": 12,
                            "label": "Dollars",
                        },
                        {
                            "value": 13,
                            "label": "Dollars",
                        },
                        {
                            "value": 14,
                            "label": "Bolivianos",
                        },
                        {
                            "value": 15,
                            "label": "Convertible Marka",
                        },
                        {
                            "value": 16,
                            "label": "Pula",
                        },
                        {
                            "value": 18,
                            "label": "Reais",
                        },
                        {
                            "value": 19,
                            "label": "Pounds",
                        },
                        {
                            "value": 20,
                            "label": "Dollars",
                        },
                        {
                            "value": 17,
                            "label": "Leva",
                        },
                        {
                            "value": 21,
                            "label": "Riels",
                        },
                        {
                            "value": 22,
                            "label": "Dollars",
                        },
                        {
                            "value": 23,
                            "label": "Dollars",
                        },
                        {
                            "value": 24,
                            "label": "Pesos",
                        },
                        {
                            "value": 25,
                            "label": "Yuan Renminbi",
                        },
                        {
                            "value": 26,
                            "label": "Pesos",
                        },
                        {
                            "value": 27,
                            "label": "Colón",
                        },
                        {
                            "value": 28,
                            "label": "Kuna",
                        },
                        {
                            "value": 29,
                            "label": "Pesos",
                        },
                        {
                            "value": 30,
                            "label": "Euro",
                        },
                        {
                            "value": 31,
                            "label": "Koruny",
                        },
                        {
                            "value": 32,
                            "label": "Kroner",
                        },
                        {
                            "value": 33,
                            "label": "Pesos",
                        },
                        {
                            "value": 34,
                            "label": "Dollars",
                        },
                        {
                            "value": 35,
                            "label": "Pounds",
                        },
                        {
                            "value": 36,
                            "label": "Colones",
                        },
                        {
                            "value": 37,
                            "label": "Pounds",
                        },
                        {
                            "value": 138,
                            "label": "Ethiopian birr",
                        },
                        {
                            "value": 38,
                            "label": "Euro",
                        },
                        {
                            "value": 39,
                            "label": "Pounds",
                        },
                        {
                            "value": 40,
                            "label": "Dollars",
                        },
                        {
                            "value": 41,
                            "label": "Euro",
                        },
                        {
                            "value": 42,
                            "label": "Cedis",
                        },
                        {
                            "value": 43,
                            "label": "Pounds",
                        },
                        {
                            "value": 133,
                            "label": "Bitcoin",
                        },
                        {
                            "value": 134,
                            "label": "Ethereum",
                        },
                        {
                            "value": 44,
                            "label": "Euro",
                        },
                        {
                            "value": 45,
                            "label": "Quetzales",
                        },
                        {
                            "value": 46,
                            "label": "Pounds",
                        },
                        {
                            "value": 47,
                            "label": "Dollars",
                        },
                        {
                            "value": 48,
                            "label": "Euro",
                        },
                        {
                            "value": 49,
                            "label": "Lempiras",
                        },
                        {
                            "value": 50,
                            "label": "Dollars",
                        },
                        {
                            "value": 51,
                            "label": "Forint",
                        },
                        {
                            "value": 52,
                            "label": "Kronur",
                        },
                        {
                            "value": 53,
                            "label": "Rupees",
                        },
                        {
                            "value": 54,
                            "label": "Rupiahs",
                            "currency_id": "Rials",
                        },
                        {
                            "value": 56,
                            "label": "Euro",
                        },
                        {
                            "value": 57,
                            "label": "Pounds",
                        },
                        {
                            "value": 58,
                            "label": "New Shekels",
                        },
                        {
                            "value": 59,
                            "label": "Euro",
                        },
                        {
                            "value": 60,
                            "label": "Dollars",
                        },
                        {
                            "value": 61,
                            "label": "Yen",
                        },
                        {
                            "value": 62,
                            "label": "Pounds",
                        },
                        {
                            "value": 63,
                            "label": "Tenge",
                        },
                        {
                            "value": 135,
                            "label": "Kenyan shilling",
                        },
                        {
                            "value": 64,
                            "label": "Won",
                        },
                        {
                            "value": 65,
                            "label": "Won",
                        },
                        {
                            "value": 66,
                            "label": "Soms",
                        },
                        {
                            "value": 67,
                            "label": "Kips",
                        },
                        {
                            "value": 68,
                            "label": "Lati",
                        },
                        {
                            "value": 69,
                            "label": "Pounds",
                        },
                        {
                            "value": 70,
                            "label": "Dollars",
                        },
                        {
                            "value": 71,
                            "label": "Switzerland Francs",
                        },
                        {
                            "value": 72,
                            "label": "Litai",
                        },
                        {
                            "value": 73,
                            "label": "Euro",
                        },
                        {
                            "value": 74,
                            "label": "Denars",
                        },
                        {
                            "value": 139,
                            "label": "Malawian kwacha",
                        },
                        {
                            "value": 75,
                            "label": "Ringgits",
                        },
                        {
                            "value": 76,
                            "label": "Euro",
                        },
                        {
                            "value": 77,
                            "label": "Rupees",
                        },
                        {
                            "value": 78,
                            "label": "Pesos",
                        },
                        {
                            "value": 79,
                            "label": "Tugriks",
                        },
                        {
                            "value": 80,
                            "label": "Meticais",
                        },
                        {
                            "value": 81,
                            "label": "Dollars",
                        },
                        {
                            "value": 82,
                            "label": "Rupees",
                        },
                        {
                            "value": 84,
                            "label": "Euro",
                        },
                        {
                            "value": 83,
                            "label": "Guilders",
                        },
                        {
                            "value": 85,
                            "label": "Dollars",
                        },
                        {
                            "value": 86,
                            "label": "Cordobas",
                        },
                        {
                            "value": 87,
                            "label": "Nairas",
                        },
                        {
                            "value": 88,
                            "label": "Won",
                        },
                        {
                            "value": 89,
                            "label": "Krone",
                        },
                        {
                            "value": 90,
                            "label": "Rials",
                        },
                        {
                            "value": 91,
                            "label": "Rupees",
                        },
                        {
                            "value": 92,
                            "label": "Balboa",
                        },
                        {
                            "value": 93,
                            "label": "Guarani",
                        },
                        {
                            "value": 94,
                            "label": "Nuevos Soles",
                        },
                        {
                            "value": 95,
                            "label": "Pesos",
                        },
                        {
                            "value": 96,
                            "label": "Zlotych",
                        },
                        {
                            "value": 97,
                            "label": "Rials",
                        },
                        {
                            "value": 98,
                            "label": "New Lei",
                        },
                        {
                            "value": 99,
                            "label": "Rubles",
                        },
                        {
                            "value": 136,
                            "label": "Rwandan franc",
                        },
                        {
                            "value": 100,
                            "label": "Pounds",
                        },
                        {
                            "value": 101,
                            "label": "Riyals",
                        },
                        {
                            "value": 102,
                            "label": "Dinars",
                        },
                        {
                            "value": 103,
                            "label": "Rupees",
                        },
                        {
                            "value": 104,
                            "label": "Sierra Leonean leone",
                        },
                        {
                            "value": 105,
                            "label": "Singapore Dollars",
                        },
                        {
                            "value": 106,
                            "label": "Euro",
                        },
                        {
                            "value": 107,
                            "label": "Dollars",
                        },
                        {
                            "value": 108,
                            "label": "Shillings",
                        },
                        {
                            "value": 109,
                            "label": "Rand",
                        },
                        {
                            "value": 110,
                            "label": "Won",
                        },
                        {
                            "value": 111,
                            "label": "Euro",
                        },
                        {
                            "value": 112,
                            "label": "Rupees",
                        },
                        {
                            "value": 115,
                            "label": "Dollars",
                        },
                        {
                            "value": 113,
                            "label": "Kronor",
                        },
                        {
                            "value": 114,
                            "label": "Francs",
                        },
                        {
                            "value": 116,
                            "label": "Pounds",
                        },
                        {
                            "value": 117,
                            "label": "New Dollars",
                        },
                        {
                            "value": 118,
                            "label": "Baht",
                        },
                        {
                            "value": 119,
                            "label": "Dollars",
                        },
                        {
                            "value": 120,
                            "label": "Lira",
                        },
                        {
                            "value": 121,
                            "label": "Liras",
                        },
                        {
                            "value": 122,
                            "label": "Dollars",
                        },
                        {
                            "value": 137,
                            "label": "Ugandan shilling",
                        },
                        {
                            "value": 123,
                            "label": "Hryvnia",
                        },
                        {
                            "value": 124,
                            "label": "Pounds",
                        },
                        {
                            "value": 125,
                            "label": "Dollars",
                        },
                        {
                            "value": 126,
                            "label": "Pesos",
                        },
                        {
                            "value": 127,
                            "label": "Sums",
                        },
                        {
                            "value": 128,
                            "label": "Euro",
                        },
                        {
                            "value": 129,
                            "label": "Bolivares Fuertes",
                        },
                        {
                            "value": 130,
                            "label": "Dong",
                        },
                        {
                            "value": 131,
                            "label": "Rials",
                        },
                        {
                            "value": 140,
                            "label": "Zambian kwacha",
                        },
                        {
                            "value": 132,
                            "label": "Zimbabwe Dollars",
                        }
                        ]}
                      />
                    {
                      this.state.profileFormErrors.currency_id.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.currency}</div>
                    }
              </div>
              <div className={stylesXs.flexCol3}>
                <div className={stylesXs.text}>Industry</div>
                <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {this.updateProfileInfoSelect(value,"industry_id");}}
                      options={[
                        {
                          "value": 1,
                          "label": "Accounting/Auditing"
                      },
                      {
                          "value": 2,
                          "label": "Administration"
                      },
                      {
                          "value": 3,
                          "label": "Advertising"
                      },
                      {
                          "value": 4,
                          "label": "Aerospace and Defense"
                      },
                      {
                          "value": 5,
                          "label": "Agriculture/Forestry/Fishing"
                      },
                      {
                          "value": 6,
                          "label": "Airlines/Aviation"
                      },
                      {
                          "value": 7,
                          "label": "Architecture"
                      },
                      {
                          "value": 8,
                          "label": "Arts/Entertainment/and Media"
                      },
                      {
                          "value": 9,
                          "label": "Automotive"
                      },
                      {
                          "value": 10,
                          "label": "Aviation/Marine Refueling"
                      },
                      {
                          "value": 11,
                          "label": "Banking"
                      },
                      {
                          "value": 12,
                          "label": "Biotechnology"
                      },
                      {
                          "value": 13,
                          "label": "Business Support"
                      },
                      {
                          "value": 14,
                          "label": "Catering/Food Services/Restaurants"
                      },
                      {
                          "value": 15,
                          "label": "Community/Social Services/and Nonprofit"
                      },
                      {
                          "value": 16,
                          "label": "Computer/Hardware"
                      },
                      {
                          "value": 17,
                          "label": "Computer/Software"
                      },
                      {
                          "value": 18,
                          "label": "Construction"
                      },
                      {
                          "value": 19,
                          "label": "Construction/Civil Engineering"
                      },
                      {
                          "value": 20,
                          "label": "Consulting Services"
                      },
                      {
                          "value": 21,
                          "label": "Contracts/Purchasing"
                      },
                      {
                          "value": 22,
                          "label": "Customer Service"
                      },
                      {
                          "value": 23,
                          "label": "Distributions and Logistics"
                      },
                      {
                          "value": 24,
                          "label": "Education, Training, and Library"
                      },
                      {
                          "value": 25,
                          "label": "Employment Placement Agencies/Recruiting"
                      },
                      {
                          "value": 26,
                          "label": "Energy"
                      },
                      {
                          "value": 27,
                          "label": "Engineering"
                      },
                      {
                          "value": 28,
                          "label": "Entertainment"
                      },
                      {
                          "value": 29,
                          "label": "Facilities Management"
                      },
                      {
                          "value": 30,
                          "label": "Fashion Design"
                      },
                      {
                          "value": 31,
                          "label": "Finance/Economics"
                      },
                      {
                          "value": 32,
                          "label": "Financial Services"
                      },
                      {
                          "value": 33,
                          "label": "FMCG"
                      },
                      {
                          "value": 34,
                          "label": "Government Sector"
                      },
                      {
                          "value": 35,
                          "label": "Graphic Design"
                      },
                      {
                          "value": 36,
                          "label": "Healthcare, other"
                      },
                      {
                          "value": 37,
                          "label": "Healthcare, Practitioner and Technician"
                      },
                      {
                          "value": 38,
                          "label": "Hospitality/Tourism/Travel"
                      },
                      {
                          "value": 39,
                          "label": "Human Resources"
                      },
                      {
                          "value": 40,
                          "label": "Industrial"
                      },
                      {
                          "value": 41,
                          "label": "Information Technology"
                      },
                      {
                          "value": 42,
                          "label": "Installation, Maintenance, and Repair"
                      },
                      {
                          "value": 43,
                          "label": "Insurance"
                      },
                      {
                          "value": 44,
                          "label": "Interior design"
                      },
                      {
                          "value": 45,
                          "label": "Internet/E-commerce"
                      },
                      {
                          "value": 46,
                          "label": "Islamic Banking"
                      },
                      {
                          "value": 47,
                          "label": "Journalism"
                      },
                      {
                          "value": 48,
                          "label": "Laboratory/QC"
                      },
                      {
                          "value": 49,
                          "label": "Law Enforcement/Security Services"
                      },
                      {
                          "value": 50,
                          "label": "Legal"
                      },
                      {
                          "value": 51,
                          "label": "LPG Bottling"
                      },
                      {
                          "value": 52,
                          "label": "Lubricants/Greases Blending"
                      },
                      {
                          "value": 53,
                          "label": "Management"
                      },
                      {
                          "value": 54,
                          "label": "Manufacturing"
                      },
                      {
                          "value": 55,
                          "label": "Manufacturing and Production"
                      },
                      {
                          "value": 56,
                          "label": "Marine Services"
                      },
                      {
                          "value": 57,
                          "label": "Marketing"
                      },
                      {
                          "value": 58,
                          "label": "Mechanical"
                      },
                      {
                          "value": 59,
                          "label": "Medical/Hospital"
                      },
                      {
                          "value": 60,
                          "label": "Merchandising"
                      },
                      {
                          "value": 61,
                          "label": "Military"
                      },
                      {
                          "value": 62,
                          "label": "Mining"
                      },
                      {
                          "value": 63,
                          "label": "Modeling"
                      },
                      {
                          "value": 64,
                          "label": "Natural Gas Distribution"
                      },
                      {
                          "value": 65,
                          "label": "Nursing"
                      },
                      {
                          "value": 66,
                          "label": "Oil/Gas"
                      },
                      {
                          "value": 67,
                          "label": "Other"
                      },
                      {
                          "value": 68,
                          "label": "Personal Care and Service"
                      },
                      {
                          "value": 69,
                          "label": "Petrochemicals"
                      },
                      {
                          "value": 70,
                          "label": "Pharmaceutical"
                      },
                      {
                          "value": 71,
                          "label": "Photography"
                      },
                      {
                          "value": 72,
                          "label": "Planning"
                      },
                      {
                          "value": 73,
                          "label": "Public Relations"
                      },
                      {
                          "value": 74,
                          "label": "Publishing"
                      },
                      {
                          "value": 75,
                          "label": "Quantity Survey"
                      },
                      {
                          "value": 76,
                          "label": "Real Estate"
                      },
                      {
                          "value": 77,
                          "label": "Retail/Wholesale"
                      },
                      {
                          "value": 78,
                          "label": "Safety/Environment"
                      },
                      {
                          "value": 79,
                          "label": "Sales"
                      },
                      {
                          "value": 80,
                          "label": "Science"
                      },
                      {
                          "value": 81,
                          "label": "Secretarial"
                      },
                      {
                          "value": 82,
                          "label": "Security"
                      },
                      {
                          "value": 83,
                          "label": "Shipping"
                      },
                      {
                          "value": 84,
                          "label": "Sports and Recreation"
                      },
                      {
                          "value": 85,
                          "label": "Support Services"
                      },
                      {
                          "value": 86,
                          "label": "Technical/Maintenance"
                      },
                      {
                          "value": 87,
                          "label": "Telecommunications"
                      },
                      {
                          "value": 88,
                          "label": "Telemarketing"
                      },
                      {
                          "value": 89,
                          "label": "Textiles"
                      },
                      {
                          "value": 90,
                          "label": "Translation"
                      },
                      {
                          "value": 91,
                          "label": "Transportation"
                      },
                      {
                          "value": 92,
                          "label": "Utilities"
                      },
                      {
                          "value": 93,
                          "label": "Vehicle Inspection"
                      },
                      {
                          "value": 94,
                          "label": "Warehousing"
                      }
                      ]}
                    />
                    {
                      this.state.profileFormErrors.industry_id.length > 0 && 
                      <div className="invalid-feedback">{this.state.profileFormErrors.industry_id}</div>
                    }
              </div>
    
              {
                  this.state.isRequesting ?
                  <button className={styles.content_box}>
                    <h3 className={styles.subtitle}>
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader">
                        <div className="text-center">  
                          <div className="spinner-border" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    </h3>
                  </button>
                  :
              <button className={stylesXs.content_box} onClick={() => {this.submitProfileForm()}}>
                <h3 className={stylesXs.subtitle}>Sign up</h3>
              </button>
              }
            </div>
          </div>
            {/* success modal */}
            <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
        </div>
      )
  }
}

export {BuiltBusiness, BuiltBusinessXs};
