// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAjLMESRONarIw9Vj8zanVj36HZUKouCs8",
  authDomain: "africanwomenamplified.firebaseapp.com",
  projectId: "africanwomenamplified",
  storageBucket: "africanwomenamplified.appspot.com",
  messagingSenderId: "731098388803",
  appId: "1:731098388803:web:6eee71e15a76271fe8b7f6",
  measurementId: "G-8RRRFS5V3V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const authentication = getAuth(app);
const storage = getStorage(app);

const googleAuthProvider = new GoogleAuthProvider;

export {
  storage,
  authentication,
  googleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword
}