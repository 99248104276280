import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import moment from 'moment';
const { Option } = Select;
const { Search, TextArea } = Input;

class MyConnections extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      connections: [],
      pending_connections_count: 0
    }
	}

  getConnections () {
    this.setState({
      ...this.state,
      isLoading: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-connections", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isLoading: false,
        connections: responseInfo.data.connections,
        pending_connections_count: responseInfo.data.pending_connections_count,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  createConnection (user_id) {

    axios.post(CONSTANTS.API_BASE_URL + "/create-connection", {
      user_id: user_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  acceptConnection (connection_id) {

    axios.post(CONSTANTS.API_BASE_URL + "/accept-connection", {
      connection_id: connection_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  deleteConnection (connection_id) {

    axios.post(CONSTANTS.API_BASE_URL + "/delete-connection", {
      connection_id: connection_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = window.parse_query_string(url.search.replace(/^(\?)/,""));
    
    let notification_id = params.hasOwnProperty('n') ? params.n : null;
    if(notification_id !== null){
      AuthService.updateNotification(notification_id);
    }
    this.getConnections();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>My Connections - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={''} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 mb-8 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          My Connections
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <a href="/home"><li className="breadcrumb-item text-muted me-2">Home </li></a>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted"> Connections</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="col-lg-8 offset-lg-2">
                          <div className="card card-flush mb-5 mb-xl-8">
                            <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark fs-5">
                                  {this.state.connections.length} {this.state.connections.length > 1 ? `Connections` : `Connection`} 
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7">
                                  List of your connections
                                </span>
                              </h3>
                            </div>
                            <div className="card-body pt-5">
                              
                              {
                                this.state.connections.length === 0 ?
                                <div className="text-center text-gray-700 my-10">
                                  <div className="symbol symbol-40px">
                                    <i className="las la-user-friends fs-3x text-gray-400"></i>
                                  </div>
                                  <span className="d-block mt-4">No connections found</span>
                                </div>
                                :
                                <>
                                  {
                                    this.state.connections.map((data, index) => {
                                      return (
                                        <div key={index} className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed flex-wrap">
                                          <div className="d-flex align-items-center mb-3">
                                            <div className="symbol symbol-45px">
                                              {
                                                data.profile_pic === null ?
                                                <img className="avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                                                :
                                                <img className="avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                                              }
                                            </div>
                                            <div className="ms-6">
                                              
                                              <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                                                {data.full_name}
                                              </a>
                                              <div className="fw-semibold text-muted text-capitalize">{data.user_type}</div>
                                            </div>
                                          </div>
                                          {
                                            data.isSender ?
                                            <div className="d-flex mt-md-3">
                                              {
                                                data.status == 'pending' ?
                                                <button onClick={() => {
                                                  this.deleteConnection(data.connection_id);

                                                  let connections = this.state.connections;
                                                  connections.splice(index, 1);

                                                  this.setState({
                                                    ...this.state,
                                                    connections: connections
                                                  })

                                                }} class="btn btn-outline mt-md-2 btn-sm btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2">Withdraw</button>
                                                :
                                                <button onClick={() => {
                                                  this.deleteConnection(data.connection_id);

                                                  let connections = this.state.connections;
                                                  connections.splice(index, 1);

                                                  this.setState({
                                                    ...this.state,
                                                    connections: connections
                                                  })

                                                }} class="btn btn-outline btn-sm btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2">Delete</button>
                                              }
                                            </div>
                                            :
                                            <div className="d-flex">
                                              {
                                                data.status == 'pending' ?
                                                <>
                                                  <button onClick={() => {
                                                    this.acceptConnection(data.connection_id);

                                                    let connections = this.state.connections;
                                                    connections[index].status = "accepted";

                                                    this.setState({
                                                      ...this.state,
                                                      connections: connections
                                                    })

                                                  }} class="btn btn-outline btn-sm btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">Accept</button>
                                                  <button onClick={() => {
                                                    this.deleteConnection(data.connection_id);

                                                    let connections = this.state.connections;
                                                    connections.splice(index, 1);

                                                    this.setState({
                                                      ...this.state,
                                                      connections: connections
                                                    })

                                                  }} class="btn btn-outline btn-sm btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2">Ignore</button>
                                                </>
                                                :
                                                <button onClick={() => {
                                                  this.deleteConnection(data.connection_id);

                                                  let connections = this.state.connections;
                                                  connections.splice(index, 1);

                                                  this.setState({
                                                    ...this.state,
                                                    connections: connections
                                                  })

                                                }} class="btn btn-outline btn-sm btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2">Delete</button>
                                              }
                                              
                                              
                                            </div>
                                          }
                                          
                                        </div>
                                      )
                                    })
                                  }
                                </>
                              }
                              

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default MyConnections;