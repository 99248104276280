import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import JobCard from "./JobCard";

class Jobs extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
      isRequesting: false,
      isLoadingMore: false,
      showLoadMore: false,
      jobIndex: null,
      jobId: '',
      new_jobs: [],
      applied_jobs: [],
      successMessage: '',
    }
	}

  getJobs(job_id = null, load = null){
    this.setState({
      ...this.state,
      isRequesting: load === null ? true : false,
      isLoadingMore: load === null ? false : true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-jobs", {
      job_id: job_id,
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let new_jobs = responseInfo.data.new_jobs;
      let applied_jobs = responseInfo.data.applied_jobs;
      // let showLoadMore = jobs.length > 19 ? true : false;
      
      // if(load !== null){
      //   let old_jobs = this.state.jobs;
      //   jobs = old_jobs.concat(jobs);
      // }

      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        // showLoadMore: showLoadMore,
        isLoadingMore: false,
        new_jobs: new_jobs,
        applied_jobs: applied_jobs
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          isLoadingMore: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  updateJobs = (type, index, status) => {
    if(type === 'new'){
      let new_jobs = this.state.new_jobs;
      let applied_job = new_jobs[index];
      applied_job.status = status;
      applied_job.has_applied = true;

      let applied_jobs = this.state.applied_jobs;
      applied_jobs.unshift(applied_job);

      new_jobs.splice(index, 1);

      this.setState({
        ...this.state,
        new_jobs: new_jobs,
        applied_jobs: applied_jobs,
        successMessage: 'Your application has been successfully sent.'
      })

      setTimeout(() => {
        window.modalShow('success-modal');
      }, 500);
    }
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getJobs();
  }
	

  render(){
    return (
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
          <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                Job Board
              </h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">Home</li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-muted">Job Board</li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3"></div>

          </div>
        </div>
        {
          this.state.isLoading ?
          <div className="inner-pre-loader-container">
            <div className="inner-pre-loader py-20">
              <div className="text-center">  
                <div className="spinner-border mt-4" role="status">    
                  <span className="sr-only">Loading...</span>  
                </div>
              </div>
            </div>
          </div>
          :
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-xxl">
              
                <div className="row mt-4">
                  <div className="col-lg-8 offset-lg-2">
                      {
                        this.state.userInfo.profile_completion != "100" &&
                        <div className="card bg-primary card-xl-stretch mb-5 mb-xl-8">
                          <div className="card-body my-3">
                            <div className="d-flex flex-stack">
                              <div className="symbol symbol-60px me-5">
                                
                                <span
                                  className="symbol-label"
                                  style={{ background: "#ffffff42" }}
                                >
                                  <i className="las la-user-alt text-white fs-3x" />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div className="flex-grow-1 me-10">
                                  <div className="d-flex justify-content-between w-100 mt-auto mb-2 text-white">
                                    
                                    <span className="fw-semibold fs-6">Profile Completion</span>
                                    <span className="fw-bold fs-6">{this.state.userInfo.profile_completion}%</span>
                                  </div>
                                  <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                    <div
                                      className="progress-bar bg-warning"
                                      role="progressbar"
                                      style={{ width: `${this.state.userInfo.profile_completion}%` }}
                                      aria-valuenow={this.state.userInfo.profile_completion}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                                  <div className="text-light-warning fs-8 pt-2">Complete your profile and get access to all features on AWA.</div>
                                </div>
                                <a
                                  href="/account-settings"
                                  className="btn btn-sm btn-light fs-8 fw-bold mt-2"
                                >
                                  Update
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="bg-white rounded border py-5 px-10 mb-4">
                        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                          <li className="nav-item">
                            <a className=" fw-semibold text-active-dark nav-link active" data-bs-toggle="tab" href="#new_jobs">
                              <span class="badge badge-square badge-light">{this.state.new_jobs.length}</span> {this.state.new_jobs.length > 1 ? `New Jobs` : `New Job`}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="fw-semibold text-active-dark nav-link" data-bs-toggle="tab" href="#applied_jobs">
                              <span class="badge badge-square badge-light">{this.state.applied_jobs.length}</span> {this.state.applied_jobs.length > 1 ? `Applied Jobs` : `Applied Job`}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="jobs">
                        <div
                          className="tab-pane fade show active"
                          id="new_jobs"
                          role="tabpanel"
                        >
                          {
                            this.state.new_jobs.length === 0 ?
                            <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                              <div className="symbol symbol-40px">
                                <i className="las la-briefcase fs-5qx text-gray-400"></i>
                              </div>
                              <span className="d-block mt-4">No jobs found</span>
                            </div>
                            :
                            <>
                              {
                                this.state.new_jobs.map((data, index) => {
                                  return (
                                    <JobCard jobInfo={data} updateJobs={this.updateJobs} index={index} key={data.job_id} />
                                  )
                                })
                              }
                            </>
                          }
                        </div>
                        <div className="tab-pane fade" id="applied_jobs" role="tabpanel">
                          {
                            this.state.applied_jobs.length === 0 ?
                            <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                              <div className="symbol symbol-40px">
                                <i className="las la-briefcase fs-5qx text-gray-400"></i>
                              </div>
                              <span className="d-block mt-4">No jobs found</span>
                            </div>
                            :
                            <>
                              {
                                this.state.applied_jobs.map((data, index) => {
                                  return (
                                    <JobCard jobInfo={data} updateJobs={this.updateJobs} index={index} key={data.job_id} />
                                  )
                                })
                              }
                            </>
                          }
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        }

        {/* success modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal my-5">
                  <div className="timeline-icon symbol symbol-circle symbol-90px">
                    <div className="symbol-label bg-light-success">
                      <i className="las la-check fs-3qx text-success"></i>
                    </div>
                  </div>
                  <h3 className="nk-modal-title mt-10">Application Successful</h3>
                  
                  <div className="nk-modal-text">
                    <p className="lead">{this.state.successMessage}</p>
                  </div>
                  <div className="nk-modal-action mt-10">
                    <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}

        {/* alert modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="alert-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal my-5">
                  <div className="timeline-icon symbol symbol-circle symbol-90px">
                    <div className="symbol-label bg-light-gray-500">
                      <i className="las la-info fs-3qx text-gray-800"></i>
                    </div>
                  </div>
                  <h3 className="nk-modal-title mt-10">Sorry, can't perform this action</h3>
                  
                  <div className="nk-modal-text">
                    <p className="lead">Complete your profile and get access to all features on AWA.</p>
                  </div>
                  <div className="nk-modal-action mt-10">
                    <a href="/account-settings" className="btn btn-lg btn-mw btn-primary m-2">Complete Profile</a>
                    <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}
      </div>
    );
  }
}

export default Jobs;