import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import JobDetails from "./JobDetails";
import moment from 'moment';
import { LinkItUrl } from 'react-linkify-it';
import { 
  Input,
  Select,
  Image,
  DatePicker
} from 'antd';
import 'antd/dist/antd.css';
const { Option } = Select;
const { Search, TextArea } = Input;

class MyJobs extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
      isRequesting: false,
      isLoadingMore: false,
      showLoadMore: false,
      isDeleting: false,
      isDeleted: false,
      viewJobDetails: false,
      jobs: [],
      jobIndex: null,
      jobId: '',
      jobInfo: {},
      jobForm: {
        title: '',
        description: '',
        end_date: ''
      },
      jobFormErrors: {
        title: '',
        description: '',
        end_date: ''
      },
      successMessage: '',
      errorMessage: '',
      deleteSuccessMessage: '',
      deleteErrorMessage: ''
    }
	}

  updateJobForm = (event, name) => {
    var jobForm = this.state.jobForm;
    let value = event.target.value;

    jobForm[name] = value;
    this.setState({
			...this.state,
      jobForm: jobForm
    });
  }

  createJobForm () {
    let jobFormErrors = {
      title: '',
      description: '',
      end_date: ''
    };

    let error_count = 0;

    if(this.state.jobForm.title.length === 0){
      jobFormErrors.title = 'Input required';
      error_count++;      
    }

    if(this.state.jobForm.description.length === 0){
      jobFormErrors.description = 'Input required';
      error_count++;      
    }

    if(this.state.jobForm.end_date.length === 0){
      jobFormErrors.end_date = 'Input required';
      error_count++;      
    }

    if(error_count > 0 ){
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        jobFormErrors: jobFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      jobFormErrors: {
        title: '',
        description: '',
        end_date: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/create-job", {
      title: this.state.jobForm.title,
      description: this.state.jobForm.description,
      end_date: this.state.jobForm.end_date
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      let jobs = this.state.jobs;
      jobs.unshift(responseInfo.data);
      this.setState({
        ...this.state,
        isRequesting: false,
        successMessage: "Your job has been created successfully",
        jobs: jobs,
        jobForm: {
          title: '',
          description: '',
          end_date: ''
        }
      });

      window.modalHide('create-modal');
      window.modalShow('success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  editJobForm () {
    let jobFormErrors = {
      title: '',
      description: '',
      end_date: ''
    };

    let error_count = 0;

    if(this.state.jobForm.title.length === 0){
      jobFormErrors.title = 'Input required';
      error_count++;      
    }

    if(this.state.jobForm.description.length === 0){
      jobFormErrors.description = 'Input required';
      error_count++;      
    }

    if(this.state.jobForm.end_date.length === 0){
      jobFormErrors.end_date = 'Input required';
      error_count++;      
    }

    if(error_count > 0 ){
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        jobFormErrors: jobFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      jobFormErrors: {
        title: '',
        description: '',
        end_date: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/edit-job", {
      job_id: this.state.jobId,
      title: this.state.jobForm.title,
      description: this.state.jobForm.description,
      end_date: this.state.jobForm.end_date
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      let jobs = this.state.jobs;
      jobs[this.state.jobIndex].title = this.state.jobForm.title;
      jobs[this.state.jobIndex].description = this.state.jobForm.description;
      jobs[this.state.jobIndex].end_date = this.state.jobForm.end_date;

      this.setState({
        ...this.state,
        isRequesting: false,
        successMessage: "Your job has been edited successfully",
        jobs: jobs,
        jobIndex: null,
        jobId: '',
        jobForm: {
          title: '',
          description: '',
          end_date: ''
        }
      });

      window.modalHide('edit-modal');
      window.modalShow('success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  deleteJob () {
    this.setState({
      ...this.state,
      isDeleting: true,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: ''
    })

    axios.post(CONSTANTS.API_BASE_URL + "/delete-job", {
      job_id: this.state.jobId
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      window.scrollTo({top: 0, behavior: 'smooth'});

      let jobs = this.state.jobs;

      if(jobs[this.state.jobIndex].job_id == this.state.jobId){
        jobs.splice(this.state.jobIndex, 1);
      }

      this.setState({
        ...this.state,
        isDeleting: false,
        isDeleted: true,
        jobs: jobs,
        jobIndex: null,
        deleteSuccessMessage: 'The job post has been deleted successfully'
      })

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isDeleting: false,
          deleteErrorMessage: errorMessage
        })

      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  resetDeleteModal(){
    setTimeout(() => {
      this.setState({
        ...this.state,
        isDeleting: false,
        isDeleted: false,
        deleteSuccessMessage: '',
        deleteErrorMessage: ''
      })
    }, 1000);
  }

  resetJobFormModal(){
    setTimeout(() => {
      this.setState({
        ...this.state,
        jobForm: {
          title: '',
          description: '',
          end_date: ''
        }
      })
    }, 1000);
  }

  onChangeDatePicker = (value) => {
    var jobForm = this.state.jobForm;
    let date = value !== null ? moment(value._d).format("YYYY-MM-DD") : null;

    jobForm.end_date = date;
    this.setState({
      ...this.state,
      jobForm: jobForm
    });
  }

  getMyJobs(load=null){
    this.setState({
      ...this.state,
      isRequesting: load === null ? true : false,
      isLoadingMore: load === null ? false : true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-my-jobs", {
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let jobs = responseInfo.data.jobs;
      let showLoadMore = jobs.length > 19 ? true : false;
      
      if(load !== null){
        let old_jobs = this.state.jobs;
        jobs = old_jobs.concat(jobs);
      }

      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        showLoadMore: showLoadMore,
        isLoadingMore: false,
        jobs: jobs
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          isLoadingMore: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getMyJobs();
  }
	

  render(){
    return (
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
          <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                Job Board
              </h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">Home</li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-muted">Job Board</li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <button data-bs-toggle="modal" data-bs-target="#create-modal" className="btn btn-sm fw-bold btn-primary">
                Create a Job
              </button>
            </div>

          </div>
        </div>
        {
          this.state.isLoading ?
          <div className="inner-pre-loader-container">
            <div className="inner-pre-loader py-20">
              <div className="text-center">  
                <div className="spinner-border mt-4" role="status">    
                  <span className="sr-only">Loading...</span>  
                </div>
              </div>
            </div>
          </div>
          :
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-xxl">
            {
              this.state.viewJobDetails ?
              <div>
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    <span className="d-block pb-10">
                      <button onClick={() => {
                        this.setState({
                          ...this.state,
                          viewJobDetails: false,
                          jobInfo: {}
                        })
                      }} className="btn btn-sm btn-primary">
                        <i class="las la-long-arrow-alt-left"></i> Go Back
                      </button>
                    </span>
                  </div>
                </div>
                <JobDetails jobInfo={this.state.jobInfo} />                
              </div>
              :
              <>
              {
                this.state.jobs.length === 0 ?
                <div className="row mt-4">
                  <div className="col-lg-8 offset-lg-2">
                    <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                      <div className="symbol symbol-40px">
                        <i className="las la-briefcase fs-5qx text-gray-400"></i>
                      </div>
                      <span className="d-block mt-4">No jobs found</span>
                    </div>
                  </div>
                </div>
                :
                <div className="row mt-4">
                  <div className="col-lg-8 offset-lg-2">
                    {
                      this.state.jobs.map((data, index) => {
                        return (
                          <div className="card mb-4" key={index}>
                            <div className="card-body">
                              <h4 className="fs-1 text-gray-800 w-bolder mb-2">{data.title}</h4>
                              {/* <p className="mb-6 text-gray-600">from <b>Company Name</b></p> */}
                              <LinkItUrl><p className="fs-4 text-gray-600 mb-3" style={{whiteSpace: "pre-wrap"}}>{data.description}</p></LinkItUrl>
                              <div className="d-flex flex-wrap my-6">
                                <div className="me-9 my-1 fs-6">
                                  <i class="las la-user-friends me-1 fs-2 text-primary"></i>
                                  <span className="fw-bold text-primary">{data.applicants.length} {data.applicants.length > 1 ? `Applicants` : `Applicant` }</span>
                                </div>
                                <div className="me-9 my-1 fs-6">
                                  <i class="las la-clock me-1 fs-2 text-primary"></i>
                                  <span className="fw-bold text-primary">Deadline: {moment(data.end_date, "YYYY-MM-DD").format("Do MMM YYYY")}</span>
                                </div>
                              </div>

                              {/* <p className="fs-6 text-primary mb-2">Deadline: <b>21st Jan 2023</b></p> */}
                            </div>
                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                              <button className="btn btn-sm btn-primary me-4" onClick={() => {
                                

                                this.setState({
                                  ...this.state,
                                  jobInfo: data,
                                  viewJobDetails: true
                                })
                              }}>
                                View
                              </button>
                              <button className="btn btn-sm btn-light me-4" onClick={() => {
                                let jobForm = this.state.jobForm;
                                jobForm.title = data.title;
                                jobForm.description = data.description;
                                jobForm.end_date = data.end_date;

                                this.setState({
                                  ...this.state,
                                  jobIndex: index,
                                  jobId: data.job_id,
                                  jobForm: jobForm
                                })
                              }} data-bs-toggle="modal" data-bs-target="#edit-modal">
                                Edit
                              </button>
                              <button className="btn btn-sm btn-icon btn-danger me-2" onClick={() => {
                                this.setState({
                                  ...this.state,
                                  jobIndex: index,
                                  jobId: data.job_id
                                })
                              }} data-bs-toggle="modal" data-bs-target="#delete-modal">
                                <i className="las la-trash-alt fs-2" />
                              </button>
                            </div>
                          </div>
                        )
                      })
                    }
                    

                  </div>
                </div>
              }
              </>
            }
            </div>
          </div>
        }
        {/* success modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-success"   >
                        <i className="las la-check fs-3qx text-success"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">Successful</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">{this.state.successMessage}</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          {/* create modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="create-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create a Job</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="fv-row mb-8">
                      <label className="required text-primary d-flex align-items-center form-label mb-3">
                      Job Title
                      </label>
                      <Input size="large" required type="text" id="title" placeholder="Enter job title" name="title" value={this.state.jobForm.title} onChange={(e) => {this.updateJobForm(e,"title");}} />
                      {
                        this.state.jobFormErrors.title.length > 0 && 
                        <div className="invalid-feedback">{this.state.jobFormErrors.title}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required text-primary d-flex align-items-center form-label mb-3">
                        Description  <small className="text-muted"> (Your can add a link for more information.)</small>
                      </label>
                      <TextArea required
                        value={this.state.jobForm.description}
                        onChange={(e) => {this.updateJobForm(e,"description");}}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength={500}
                        showCount={true}
                      />
                      {
                        this.state.jobFormErrors.description.length > 0 && 
                        <div className="invalid-feedback">{this.state.jobFormErrors.description}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required text-primary d-flex align-items-center form-label mb-3">
                        End Date
                      </label>
                      <DatePicker size="large" className="w-100" onChange={date => {this.onChangeDatePicker(date)}} />
                      {
                        this.state.jobFormErrors.end_date.length > 0 && 
                        <div className="invalid-feedback">{this.state.jobFormErrors.end_date}</div>
                      }
                    </div>
                    {
                      this.state.isRequesting ?
                      <div className="nk-modal-action mt-10 text-end">
                        <button disabled className="btn btn-lg btn-mw btn-primary m-2">
                          <span>
                            Creating...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button disabled className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                      :
                      <div className="nk-modal-action mt-10 text-end">
                        <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => {this.createJobForm()}}>Create</button>
                        <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => {this.resetJobFormModal()}}>Close</button>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* community modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="edit-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Edit Job Information</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                      Job Title
                      </label>
                      <Input size="large" required type="text" id="title" placeholder="Enter job title" name="title" value={this.state.jobForm.title} onChange={(e) => {this.updateJobForm(e,"title");}} />
                      {
                        this.state.jobFormErrors.title.length > 0 && 
                        <div className="invalid-feedback">{this.state.jobFormErrors.title}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Description
                      </label>
                      <TextArea required
                        value={this.state.jobForm.description}
                        onChange={(e) => {this.updateJobForm(e,"description");}}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                      {
                        this.state.jobFormErrors.description.length > 0 && 
                        <div className="invalid-feedback">{this.state.jobFormErrors.description}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        End Date
                      </label>
                      <DatePicker value={moment(this.state.jobForm.end_date)} size="large" className="w-100" onChange={date => {this.onChangeDatePicker(date)}} />
                      {
                        this.state.jobFormErrors.end_date.length > 0 && 
                        <div className="invalid-feedback">{this.state.jobFormErrors.end_date}</div>
                      }
                    </div>
                    {
                      this.state.isRequesting ?
                      <div className="nk-modal-action mt-10 text-end">
                        <button disabled className="btn btn-lg btn-mw btn-primary m-2">
                          <span>
                            Saving...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button disabled className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                      :
                      <div className="nk-modal-action mt-10 text-end">
                        <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => {this.editJobForm()}}>Save</button>
                        <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => {this.resetJobFormModal()}}>Close</button>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* delete modal */}
          <div className="modal fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id="delete-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  
                    <div className="nk-modal my-5">
                    {
                    this.state.isDeleted ?
                      <>
                        <div className="timeline-icon symbol symbol-circle symbol-90px">
                          <div className="symbol-label bg-light-success">
                            <i className="las la-check fs-3qx text-success"></i>
                          </div>
                        </div>
                        <h3 className="nk-modal-title mt-10">Deleted Successfully</h3>
                        <div className="nk-modal-text">
                          <p className="lead">{this.state.deleteSuccessMessage}</p>
                        </div>
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                        </div>
                      </>
                      :
                      <>
                        <div className="timeline-icon symbol symbol-circle symbol-90px">
                          <div className="symbol-label bg-light-danger">
                            <i className="las la-exclamation fs-3qx text-danger"></i>
                          </div>
                        </div>
                        <h3 className="nk-modal-title mt-10">Delete this job post?</h3>
                        <div className="nk-modal-text">
                          <p className="lead">Are you sure you want to delete this job post?. <br/>This action can not be reverted.</p>
                          {
                            this.state.deleteErrorMessage.length > 0 && <p className="text-danger">{this.state.deleteErrorMessage}</p>
                          }
                        </div>
                        {
                          this.state.isDeleting ?
                          <div className="nk-modal-action mt-10">
                            <button className="btn btn-lg btn-mw btn-danger m-2" disabled>
                              Deleting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </button>
                            <button className="btn btn-lg btn-mw btn-light m-2" disabled>Close</button>
                          </div>
                          :
                          <div className="nk-modal-action mt-10">
                            <button className="btn btn-lg btn-mw btn-danger m-2" onClick={() => { this.deleteJob() }}>Delete</button>
                            <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                          </div>
                        }
                      </>
                    } 
                    </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
      </div>
    );
  }
}

export default MyJobs;