
import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/LessonTwoThree_section1.module.scss';
import section2Styles from '../scss/LessonTwoThree_section2.module.scss';
import styles from '../scss/PitchDeck.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanThree extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
          ...this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Three</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                     <section className={section1Styles.section1}>
               
                     <div className={section1Styles.content_box2}>
                       <div className={section1Styles.flexRow1}>
                         <h1 className={section1Styles.hero_title}>
                           Understanding business models: What is the best model for your
                           <br />
                           business
                         </h1>
                         <div className={section1Styles.flexRow1__spacer} />
               
                       </div>
                     </div>
               
                     <div className={section1Styles.flexCol2}>
                       <h1 className={section1Styles.hero_title1}>Business Models</h1>
               
                       <div className={section1Styles.content_box}>
                         <h3 className={section1Styles.subtitle}>
                           {
                             "A business model refers to your company's high level plan for generating revenue. It identifies your products or services that will be sold, takes into account your anticipated business expenses and also your identified target market. A business model lets an entrepreneur experiment, test, and model different ways to structure their costs and revenue streams. For early-stage businesses, exploring potential business models can help you determine if your business idea is viable, attract investors and guide your overall management strategy. For mature businesses, the business model serves as the basis for developing financial forecasts, setting milestones, and setting a baseline for reviewing your business plan."
                           }
                         </h3>
                       </div>
                     </div>
               
                     <div className={section1Styles.group}>
                       <div className={section1Styles.content_box1}>
                         <div className={section1Styles.flexCol3}>
                           <h2 className={section1Styles.medium_title}>By the end of this session, you should:</h2>
                           <h3 className={section1Styles.subtitle1}>
                             1. Know what a business model is.
                             <br />
                             2. Know the types of business models.
                             <br />
                             3. Learn how design a business model.
                             <br />
                             4. Apply the knowledge to build your own Business Model Canvas
                             <br />
                             5. Know what to do with your Business Model Canvas after creating it.
                             <br />
                             6. Learn what software you can employ to build a Business Model Canvas.
                           </h3>
                         </div>
                       </div>
               
                       <img
                         src={require('../../assets/courseplan/48cdb55d43a3e4df89eb38cdf4852807.png')}
                         alt="alt text"
                         className={section1Styles.image1}
                       />
                     </div>
                   </section>
                  }
                  {
                   <section className={section2Styles.section2}>
                   <div className={section2Styles.flexCol}>
                     <div className={section2Styles.flexCol1}>
                       <div className={section2Styles.flexCol2}>
                         <h1 className={section2Styles.title}>What is a business model?</h1>
                         <h4 className={section2Styles.highlights}>
                           A business model is an outline for how your company plans to make money. In practice, your business model
                           includes baseline aspects of each and every business, such as value proposition (a description of the
                           goods or services that your company offers and why they are desirable to customers),processes, target
                           audience, offers, strategies, organizational structure, operational and political processes.
                           <br />
                           <br />
                           There are three key components within a business model: creating value, delivering value, and capturing
                           value. Your business model should not revolve around money, it should revolve around value.
                         </h4>
                       </div>
             
                       <div className={section2Styles.flexCol3}>
                         <h1 className={section2Styles.title}>Business Model Objectives</h1>
                         <h4 className={section2Styles.highlights1}>
                           The objective of a business model is to determine:
                           <br />
                           1. What product or service your company will sell.
                           <br />
                           2. How your business intends to market that product or service.
                           <br />
                           3. What kind of expenses your company will face.
                           <br />
                           4. How your company expects to turn a profit.
                           <br />
                           In addition to offering a direction for your business, your business model also serves
                           <br />
                           to attract investors, recruit talent, and motivate your team.
                         </h4>
                       </div>
             
                       <div className={section2Styles.flexCol4}>
                         <h1 className={section2Styles.title}>Types of business models</h1>
                         <h4 className={section2Styles.highlights2}>
                           Discussed below are 12 common business model options (not an exhaustive list), all of which can be
                           customized for a specific company or industry.
                           <br />A “disruptive business model” innovates on these basic structures. Several businesses earn money
                           from multiple revenue streams, meaning that their business models include several of these types.
                         </h4>
                       </div>
             
                       <div className={section2Styles.flexCol4}>
                         <h1 className={section2Styles.title}>Types of business models</h1>
                         <h4 className={section2Styles.highlights2}>
                           Discussed below are 12 common business model options (not an exhaustive list), all of which can be
                           customized for a specific company or industry. <br />A “disruptive business model” innovates on these
                           basic structures. Several businesses earn money from multiple revenue streams, meaning that their business
                           models include several of these types.
                         </h4>
                       </div>
                     </div>
             
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group}>
                         <h4 className={section2Styles.highlights3}>
                           The retailer model follows a direct-to-consumer approach. The retailer is the last link in the supply
                           chain. Retailers purchase goods from manufacturers or distributors and then sell these goods to customers.
                           The retailer must sell the products at a price that will cover their business expenses and enable them to
                           make a profit. Retailers may specialize in a particular niche product or alternatively, they may carry a
                           range of products. The retailer model implies a business model that is mostly local-based; it carries
                           higher margins but also higher costs and distribution risks. The four primary types of retailer formats
                           are department stores, supermarkets, speciality stores, and online retailers.
                         </h4>
                         <h3 className={section2Styles.subtitle1}>Retailer model</h3>
                         
                         <div className={section2Styles.rect4} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group1}>
                         <h4 className={section2Styles.highlights3}>
                           The manufacturer business model utilizes raw materials to create a product to sell to distributors,
                           retailers or directly to consumers. This type of business model might also involve the assembly of
                           prefabricated components to make a new product, such as automobile manufacturing.
                           <br />
                           <br />A manufacturing business can sell the products it creates directly to customers, which is known as
                           the business-to-consumer (B2C) model. Another option involves the outsourcing the sales aspect of the
                           process to another company, which is known as the business-to-business or B2B model.
                         </h4>
                         <h3 className={section2Styles.subtitle1}>Manufacturer model</h3>
                         
                         <div className={section2Styles.rect4} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group1}>
                         <h4 className={section2Styles.highlights4}>
                           With the fee for service model, your business will charge a set fee for engaging in a specific service.
                           With this model one can increase business earnings by engaging more clients or by raising its rates.
                           Depending on the type of work the business does, it might charge an hourly rate, a monthly retainer or a
                           commission. The business may also create a fee schedule with a set rate for different types of services.
                           For example, Lawyers, accountants and real estate agents all use the fee for service model and charge fees
                           for their specialized services.
                         </h4>
                         <h3 className={section2Styles.subtitle11_box}>
                           <span className={section2Styles.subtitle11}>
                             <span className={section2Styles.subtitle11_span0}>
                               Fee-for-service model
                               <br />
                             </span>
                             <span className={section2Styles.subtitle11_span1}>
                               <br />
                               <br />
                             </span>
                           </span>
                         </h3>
                         
                         <div className={section2Styles.rect4} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group1}>
                         <h4 className={section2Styles.highlights4}>
                           The subscription model is a business model where your customers pay you a recurring fee to access your
                           content or services. The subscription fee payment can be done monthly or yearly. This business model can
                           be applied to both traditional brick-and-mortar stores and also online e-commerce businesses.
                           <br />
                           The two main types of subscription are:
                           <br />
                           Content-based subscription: customers pay to access your content. This could be anything from accessing
                           online courses to e-books to blog posts.
                           <br />
                           Service-based subscription: customers pay to access your services. This could be anything from online
                           coaching to design work to consulting.
                         </h4>
                         <h3 className={section2Styles.subtitle1}>Subscription model</h3>
                         
                         <div className={section2Styles.rect4} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group1}>
                         <h4 className={section2Styles.highlights4}>
                           The bundling business model as the name suggests involves bundling your product or service, i.e., selling
                           two or more products together as a single unit, often for a lower price than you would charge selling the
                           products separately. This type of business model allows businessess to generate a greater volume of sales
                           and sometimes market products or services that may be more difficult to sell individually. However, it is
                           important to remember that selling the products for less will impact negatively on your profit margins so
                           you need to ensure that this model makes business sense for
                           <br />
                           your particular business. Bundling is not just limited to individual organizations, as two companies can
                           form a partnership and use a bundling model to deliver complementary products or services. E.g., a skin
                           care line and a spa.
                         </h4>
                         <h3 className={section2Styles.subtitle1}>Bundling model</h3>
                         
                         <div className={section2Styles.rect4} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group1}>
                         <h4 className={section2Styles.highlights4}>
                           The Product-as-a-Service (PaaS) model is a combination of your products accompanied by services. With
                           PaaS, you offer your products to your customers in subscription models that are offered with services
                           attached. Your customers would subscribe to the product and pay recurring fees. The transition from
                           product to an all- inclusive service engagement lifespan ensures that the customer lifecycle is extended
                           and is ensured by the services associated. Physical products are combined with services and software to
                           monitor the customer process.
                           <br />
                           Your customers would subscribe to your products and pay a weekly, monthly or yearly fee, versus a one-time
                           fee and your product is then delivered to them as an experience or an extra service.
                         </h4>
                         <h3 className={section2Styles.subtitle1}>Product-as-a-service model</h3>
                         
                         <div className={section2Styles.rect4} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group2}>
                         <h4 className={section2Styles.highlights5}>
                           Under a leasing business model, your company would buy a product from a seller. Your company <br />
                           would then allow another company to use the product you purchased for a recurring fee. <br />
                           Leasing agreements are usually most efficient with big-ticket items like manufacturing and <br />
                           medical equipment, but some companies may lease smaller items that they do not use regularly in <br />
                           their business.
                           <br />
                           Leasing is similar to the product-as-a-service business model, but leases usually have longer terms <br />
                           like days or weeks compared to minutes or hours.
                         </h4>
                         <h3 className={section2Styles.subtitle12}>Leasing model</h3>
                         
                         <div className={section2Styles.rect41} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group2}>
                         <h4 className={section2Styles.highlights5}>
                           A franchise is a type of business that is operated by an individual known as a franchisee using <br />
                           the trademark, branding and business model of a the original owner (parent company) <br />
                           called a franchisor. With this business model, there is a legal and commercial relationship between <br />
                           the owner of the company (the franchisor) and the individual (the franchisee). In other words, <br />
                           he franchisee is licensed to use the franchisor’s trade name and operating systems. In exchange <br />
                           for the rights to use the franchisor’s blueprint/business model to sell their product or service and{' '}
                           <br />
                           to be provided with support, training, and operational instructions, the franchisee pays a franchisee
                           <br />
                           fee (known as a royalty) to the franchisor. The franchisee must also sign a contract (franchise <br />
                           agreement) agreeing to operate in accordance with the terms specified in the contract.
                         </h4>
                         <h3 className={section2Styles.subtitle12}>Franchise model</h3>
                         
                         <div className={section2Styles.rect41} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group3}>
                         <h4 className={section2Styles.highlights6_box}>
                           <span className={section2Styles.highlights6}>
                             <span className={section2Styles.highlights6_span0}>
                               A distribution model outlines how a product makes it way from the manufacturer to a consumer
                               outlet/retailer.
                             </span>
                             <span className={section2Styles.highlights6_span1}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span2}>
                               Direct-to-customer using an assisted sales process
                             </span>
                             <span className={section2Styles.highlights6_span3}>
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span4}>
                               This model involves a sales team who will cold call potential customers and respond to leads generated
                               by marketing campaigns.
                             </span>
                             <span className={section2Styles.highlights6_span5}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span6}>
                               Direct-to-customer using an automated purchasing process
                             </span>
                             <span className={section2Styles.highlights6_span7}>
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span8}>
                               Rather than having a sales team handling the sales process, this distribution model uses software and
                               is fully automated. This model is great for companies that produce software because customers can
                               download the product instantly.
                             </span>
                             <span className={section2Styles.highlights6_span9}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span10}>Resale through authorized dealers</span>
                             <span className={section2Styles.highlights6_span11}>
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span12}>
                               This distribution model allows manufacturers to outsource their sales efforts to authorized dealers
                               and increase their market reach whilst controlling how their product is sold.
                             </span>
                             <span className={section2Styles.highlights6_span13}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span14}>Resale open to all resellers</span>
                             <span className={section2Styles.highlights6_span15}>
                               <br />
                             </span>
                             <span className={section2Styles.highlights6_span16}>
                               This model is great for manufacturers that want to outsource their sales efforts and maximize their
                               business reach. By pushing the product through as many resellers as possible, they can quickly
                               saturate the market to effectively compete against similar products.
                             </span>
                           </span>
                         </h4>
                         <h3 className={section2Styles.subtitle13}>Distribution model</h3>
                         
                         <div className={section2Styles.rect42} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group4}>
                         <h4 className={section2Styles.highlights7}>
                           Freemium is a business model in which a company offers basic or limited features to customers for free and
                           then charges them a premium for supplemental or advanced features. This model is common in the
                           software-as-a-service space e.g. Spotify
                         </h4>
                         <h3 className={section2Styles.subtitle14}>Freemium model</h3>
                         
                         <div className={section2Styles.rect43} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group5}>
                         <h4 className={section2Styles.highlights8}>
                           {
                             "Affiliate marketing is an advertising model in which a company pays third-party publishers to generate traffic or leads to the company's products and services. The third-party publishers are known as affiliates, and the commission fee that they are paid is their incentive to find ways to promote the company."
                           }
                           <br />
                           <br />
                           If you have ever heard a social media influencer encourage you to use a specific offer code when you buy a
                           product, they are promoting and affiliate marketing is probably part of that influencer’s business model.
                         </h4>
                         <h3 className={section2Styles.subtitle15}>Advertising or affiliate marketing model</h3>
                         
                         <div className={section2Styles.rect44} />
                       </div>
                     </div>
                     <div className={section2Styles.flexCol__cell}>
                       <div className={section2Styles.group6}>
                         <h4 className={section2Styles.highlights9}>
                           Also known as a razor and blades business model, the pricing and marketing strategy is designed to
                           generate reliable, recurring income by locking a consumer onto a platform or proprietary tool for a long
                           period. It is often employed with consumable goods, such as razors and their proprietary blades. This
                           business model is most common among companies that sell physical products. Printers that require a
                           specific type of ink or water pitchers that require a specific type of filters are examples of the razor
                           and blades model.
                         </h4>
                         <h3 className={section2Styles.subtitle15}>Razor and blades model</h3>
                         
                         <div className={section2Styles.rect44} />
                       </div>
                     </div>
             
                     <div className={section2Styles.flexCol5}>
                       <h1 className={section2Styles.title1}>How to design a business model</h1>
                       <h4 className={section2Styles.highlights6_box}>
                         <span className={section2Styles.highlights6}>
                           <span className={section2Styles.highlights6_span0}>
                             To design your own business model, start by thinking of answers to the following questions:
                           </span>
                           <span className={section2Styles.highlights6_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span2}>1. How will your business make money?</span>
                           <span className={section2Styles.highlights6_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span4}>
                             Outline one or several revenue streams, which are the different ways your company plans to generate
                             earnings.
                           </span>
                           <span className={section2Styles.highlights6_span5}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span6}>
                             A revenue stream can take the form of one of these revenue models:
                           </span>
                           <span className={section2Styles.highlights6_span7}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span8}>
                             • Transaction-based revenue: These are the revenues generated from the sales of goods. This revenue
                             stream is usually in the form of one-time customer payments.
                           </span>
                           <span className={section2Styles.highlights6_span9}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span10}>
                             • Service revenue: These are the revenues that are generated by providing a service to  your customers
                             and these are usually calculated based on time. For example, you would charge a client based on the
                             number of hours of consulting services that you provided.
                           </span>
                           <span className={section2Styles.highlights6_span11}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span12}>
                             • Project revenue: These are the revenues that you earn through one-time projects with  new or existing
                             customers.
                           </span>
                           <span className={section2Styles.highlights6_span13}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span14}>
                             • Recurring revenue: These are the earnings you make from ongoing payments for continuing services or
                             after-sale services to customers. The recurring revenue model is the model most commonly used by
                             businesses because it is predictable and it assures the company’s source of revenue as ongoing.
                           </span>
                           <span className={section2Styles.highlights6_span15}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span16}>Possible recurring revenue streams include:</span>
                           <span className={section2Styles.highlights6_span17}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span18}>
                             o Subscription fees (e.g., monthly fees for Netflix)
                           </span>
                           <span className={section2Styles.highlights6_span19}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span20}>o Renting, leasing, or lending assets</span>
                           <span className={section2Styles.highlights6_span21}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span22}>o Licensing content to third parties</span>
                           <span className={section2Styles.highlights6_span23}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span24}>o Brokerage fees</span>
                           <span className={section2Styles.highlights6_span25}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span26}>o Advertising fees</span>
                         </span>
                       </h4>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>2. What are your key metrics? </span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             Having profit is a sure way to know your business is successful, however for most start-up businesses
                             that may have to funnel back profits into the business using profit as a measure may not be the best way
                             to judge your business’s success.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>
                             You need to identify other ways that you will measure success, e.g., how much it costs to acquire new
                             customers or how many repeat customers you will have.
                           </span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             Additional metrics to consider include but are not limited to:
                           </span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>
                             • Sales Revenue - Month-over-month sales results show whether people are interested in buying your
                             product/service, whether your marketing efforts are paying off, if your business is still competitive,
                             etc. Sales revenue is calculated by summing up all the income from client purchases, minus the cost
                             associated with returned or undeliverable products.
                           </span>
                           <span className={section2Styles.subtitle16_span9}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span10}>
                             • Net Profit Margin - This metric indicates how efficient your company is at generating profit compared
                             to its revenue. The Net Profit Margin metric is a good way to predict long-term business growth, and see
                             whether your business income exceeds the costs of running the business. Net Profit Margin is measured by
                             calculating your monthly revenue and reducing all the sales expenses.
                           </span>
                           <span className={section2Styles.subtitle16_span11}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span12}>
                             • Gross Margin - The higher your Gross Margin, the more your company earns by each sales amount. You
                             will be able to invest this in other operations. This metric is especially important for starting
                             companies as it reflects on improved processes and production. This metric is the equivalent of your
                             company’s productivity, translated into numbers. Gross Margin = (total sales revenue – cost of goods
                             sold) / total sales revenue.
                           </span>
                           <span className={section2Styles.subtitle16_span13}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span14}>
                             • Sales Growth - Monitor your sales growth over various time periods – monthly, yearly, and long-term
                             metrics will give you a better understanding of where your company stands. Make it a goal to accelerate
                             your sales growth every month, or at least keep it at the same percentage, month over month. To measure
                             this metric, check your monthly sales revenue and the number of
                           </span>
                           <span className={section2Styles.subtitle16_span15}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span16}>new deals.</span>
                           <span className={section2Styles.subtitle16_span17}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span18}>
                             • Net Promoter Score - Net Promoter Score reflects on the quality of your product and the level of
                             customer satisfaction. This metric shows how many people are likely to recommend your product/service.
                             There are three levels of customer advocacy:
                           </span>
                           <span className={section2Styles.subtitle16_span19}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span20}>
                             a. Promoters (score 9-10) are loyal enthusiasts who praise your company to others and drive your sales{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span21}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span22}>
                             b. Passives (score 7-8) are satisfied but unenthusiastic customers who leave when they see a better
                             offer.
                           </span>
                           <span className={section2Styles.subtitle16_span23}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span24}>
                             {
                               "c. Detractors (score 0-6) are disappointed customers who spread negative information about your company and can damage your brand's image. This marketing metric can be measured on a ten-point scale by conducting customer surveys and interviews."
                             }
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>3. Who is your target customer?</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             {
                               "Your product or service should solve a specific problem for a specific group of consumers. Your business model should consider the size of your potential customer base. Your customer base is the group of people who repeatedly buy your company's products or use your organization's services. These customers frequently engage with your business and provide the most financial value to your company. Depending on the industry, your customer base can be a specific group or a target audience based on a buyer persona. Recognizing your target customers will help your marketing, sales, and customer service teams build productive relationship with them."
                             }
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>(AWA-customer-lesson-plan link)</span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>
                             4. How will your product or service benefit those customers?
                           </span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             Your business model should have a clear value proposition, which is what makes your business uniquely
                             attractive to customers. Ideally, your value proposition should be specialized enough that it cannot be
                             easily copied.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>
                             A value proposition is a concise statement of the benefits that your company is delivering to the
                             customers who buy your products or services. This statement serves as a declaration of intent, both
                             internal in the company and externally in the market.
                           </span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             Your business’s value proposition should clearly explain how your product or service fills your
                             customers need, communicate the specifics of its added benefit, and state the reason why it is better
                             than similar products or services on the market.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>5. What business expenses will you have?</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             It is important to make a list of the fixed and variable expenses your business requires to function,
                             and then figure out what prices you need to charge so your revenue will exceed those costs. Do not
                             forget to include the costs associated with the physical, financial, and intellectual assets of your
                             company in your calculations.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>Examples of fixed expenses</span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             These are costs that largely remain constant, and cannot easily be changed. They are usually paid on a
                             regular basis, such as weekly, monthly, quarterly or from year to year. E.g., rent or mortgage.
                           </span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>Examples of variable expenses</span>
                           <span className={section2Styles.subtitle16_span9}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span10}>
                             Variable costs are costs that change as the volume changes. Examples of variable costs are raw
                             materials, wages, commissions, production supplies, delivery costs, packaging supplies, certain
                             utilities etc.
                           </span>
                           <span className={section2Styles.subtitle16_span11}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span12}>
                             As a new business, you may not have a clear idea of what each of these components will look like, this
                             is where writing a business plan will help you identify these expenses. It may also be helpful to
                             conduct research into businesses that are similar to yours to see how they have structured their
                             operations. This market research may reveal business operations and practices you may want to adopt, as
                             well as highlight the gaps in the market that your business may be able to fill.
                           </span>
                           <span className={section2Styles.subtitle16_span13}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span14}>
                             Your business model will inform your business operations and vice versa. As your business grows, you
                             will be able to change and adapt your strategy based on what you have learned about your business,
                             customers and market.
                           </span>
                         </span>
                       </h3>
                     </div>
             
                     <div className={section2Styles.flexCol6}>
                       <h1 className={section2Styles.title}>Building your Business Model Canvas</h1>
                       <h4 className={section2Styles.highlights10}>
                         Alexander Osterwalder facilitated the creation of a business model by introducing the Business Model Canvas
                         (BMC). By definition, this is a visual template that illustrates various objects of a business model.
                         Osterwalder’s original canvas includes nine elements, which we will have explained below in the article.
                         They represent vital aspects of business survival.
                         <br />
                         <br />
                         The necessary tools depend on how you decide to brainstorm:
                         <br />
                         <br />
                         Offline: Download a PDF Business Model Canvas template, and take several coloured markers, sticky notes, and
                         anything else you may need and fill in the relevant sections with your team.
                         <br />
                         <br />
                         Online: Choose an online platform where you and your team will work with the template. E.g., Google Docs.
                       </h4>
                     </div>
             
                     <div className={section2Styles.flexCol7}>
                       <div className={section2Styles.flexCol2}>
                         <h1 className={section2Styles.title2}>The Nine Business Model Canvas blocks explained:</h1>
                         <h3 className={section2Styles.subtitle16_box}>
                           <span className={section2Styles.subtitle16}>
                             <span className={section2Styles.subtitle16_span0}>1. Customer Segment</span>
                             <span className={section2Styles.subtitle16_span1}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span2}>
                               Your business’s daily operations are highly dependent on your customers and their behavioral patterns.
                               This is why customer segmentation is vital when creating a business model.
                             </span>
                             <span className={section2Styles.subtitle16_span3}>
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span4}>
                               In this block, you need to describe your buyer persona. The description includes but is not limited to
                               the following:
                             </span>
                             <span className={section2Styles.subtitle16_span5}>
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span6}>• Demographics (age, gender, etc.)</span>
                             <span className={section2Styles.subtitle16_span7}>
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span8}>• Professional status</span>
                             <span className={section2Styles.subtitle16_span9}>
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span10}>• Motivation and goals</span>
                             <span className={section2Styles.subtitle16_span11}>
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span12}>• Shopping preferences</span>
                             <span className={section2Styles.subtitle16_span13}>
                               <br />
                               <br />
                             </span>
                             <span className={section2Styles.subtitle16_span14}>
                               *Note: This is a basic list of points. You can add specific parameters. For example, software
                               developers may identify the preferred device type. Already-established brands can also introduce
                               visitors’ tiers that differentiate people according to their level of connection with a brand.
                             </span>
                           </span>
                         </h3>
                       </div>
             
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>2. Value Proposition</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             A value proposition is a brief description of your product and its ultimate value for a client. In this
                             section of the Business Model Canvas write down why consumers should buy your product or engage with
                             your services.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>
                             Ideally, your product or service should solve a problem or drive additional value for a customer.
                           </span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             The wording for your value proposition should be concise. Do not describe your value proposition in
                             several sentences. Try to limit the proposition to a single but articulate phrase.
                           </span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>Here are some good examples:</span>
                           <span className={section2Styles.subtitle16_span9}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span10}>• Uber – The Smartest Way to Get Around.</span>
                           <span className={section2Styles.subtitle16_span11}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span12}>• Apple iPhone – The Experience IS the Product.</span>
                           <span className={section2Styles.subtitle16_span13}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span14}>• Unbounce – A/B Testing Without Tech Headaches.</span>
                           <span className={section2Styles.subtitle16_span15}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span16}>
                             • Slack – Be More Productive at Work with Less Effort.
                           </span>
                           <span className={section2Styles.subtitle16_span17}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span18}>• Digit – Save Money Without Thinking About It.</span>
                           <span className={section2Styles.subtitle16_span19}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span20}>
                             • Fast, detailed, and entirely offline maps with turn-by-turn navigation – trusted by over 140 million
                             travelers worldwide.” Maps.me
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>3. Distribution channels</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             Osterwalder, together with Pigneur, described five phases of channel development. Awareness includes the
                             channels that establish your initial contact with your target audience and develops this connection.
                             Awareness usually involves your business’s marketing channels.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>
                             Evaluation suggests allowing your potential buyers to try your goods and see its value. Popular channels
                             to use for evaluation include reviews, free samples, and case studies.
                           </span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             Purchase is about when and by what means your customers can buy your product. The channels vary
                             significantly depending on the prevalence of online or offline communication.
                           </span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>
                             Delivery describes how your end consumer receives your product/service. After-sales is usually limited
                             to the customer support that is provided after-sales service and is done to resolves problems your
                             customer may have with your product/service.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>4. Customer Relationship</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             Your customer relationship strategy determines how your target audience interacts with your brand. You
                             can choose from five types of customer relationships in terms of the Business Model Canvas:
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>
                             1. Personal assistance is a traditional approach where your customer interacts with a personal assistant
                             when contacting your brand. This personalized interaction implies a high level of personal care between
                             you and your clients.
                           </span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             2. Self-service is the opposite of personal assistance, as with this approach, your brand does not
                             directly communicate with your consumer(s), rather, the consumer can understand your product via guides
                             and FAQs.
                           </span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>
                             3. Automated service involves AI-based suggestions and bots that can provide basic assistance. This type
                             of customer relationship is more engaging than self-service.
                           </span>
                           <span className={section2Styles.subtitle16_span9}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span10}>
                             4. Communities are spaces developed by your brand itself to help your audience understand your product
                             better.
                           </span>
                           <span className={section2Styles.subtitle16_span11}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span12}>
                             5. Co-creation implies educating your customers using user-generated content.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>5. Revenue Streams</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             The next block of the Business Model Canvas is about determining where the revenue for your business
                             comes from. In this block you should consider your buyer’s persona to identify what your target audience
                             is ready to pay for.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>There are several methods to monetize:</span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>Direct sales:</span>
                           <span className={section2Styles.subtitle16_span7}>
                             {' '}
                             Implies selling your service or product for a fee. This is common for a{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span8}>majority of businesses.</span>
                           <span className={section2Styles.subtitle16_span9}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span10}>Advertising</span>
                           <span className={section2Styles.subtitle16_span11}>
                             : This is relevant for niches such as blogging or IT platforms. The revenue{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span12}>
                             generated here comes from advertisers who want to reach your audience.
                           </span>
                           <span className={section2Styles.subtitle16_span13}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span14}>Subscription:</span>
                           <span className={section2Styles.subtitle16_span15}>
                             {' '}
                             This is similar to a fee-based monetization with the only difference being{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span16}>
                             that the consumer pays for getting access to the service for only a limited time.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>6. Key Resources</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             In the Business Model Canvas, key resources are divided into four categories listed below:
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>Tangible</span>
                           <span className={section2Styles.subtitle16_span5}>
                             {' '}
                             - Any physical resources that your business has, this ranges from real estate{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span6}>
                             to your machines and equipment. The business stocks also fall in this category.
                           </span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>Intangible</span>
                           <span className={section2Styles.subtitle16_span9}>
                             {' '}
                             – Any intellectual property your company owns like patents, copyrights,{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span10}>licenses, and customer knowledge.</span>
                           <span className={section2Styles.subtitle16_span11}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span12}>Human</span>
                           <span className={section2Styles.subtitle16_span13}>
                             {' '}
                             – All the employees that make your business function
                           </span>
                           <span className={section2Styles.subtitle16_span14}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span15}>Financial</span>
                           <span className={section2Styles.subtitle16_span16}>
                             {' '}
                             - All your business’s finance, regardless of whether it is an obligation or not.{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span17}>
                             This includes cash, bank loans, grants or donations, and all other finances.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>7. Key activities</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             Key activities included in a canvas are the business activities vital for your work. These will vary
                             from industry to industry. Some people design this block by uniting the activities into one out of three
                             categories:
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>• Production</span>
                           <span className={section2Styles.subtitle16_span5}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span6}>• Problem-solving</span>
                           <span className={section2Styles.subtitle16_span7}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span8}>• Platform</span>
                           <span className={section2Styles.subtitle16_span9}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span10}>
                             For example, software developers fall in the first category as they design new products, while an IT
                             company with its own taxi service is attributed to the third category.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>8. Key Partners</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             Key partners are parties to your business such as your suppliers who are vital to your business
                             operations. Key partners are people or businesses your company cannot survive without. There are four
                             categories to include in the Business Model Canvas:
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>• </span>
                           <span className={section2Styles.subtitle16_span5}>Supplier</span>
                           <span className={section2Styles.subtitle16_span6}>
                             {' '}
                             – This is the partner who supplies your business with raw materials or{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span7}>finished goods</span>
                           <span className={section2Styles.subtitle16_span8}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span9}>•</span>
                           <span className={section2Styles.subtitle16_span10}> Non-competitors</span>
                           <span className={section2Styles.subtitle16_span11}>
                             {' '}
                             – These are partnerships that occur when you team up with{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span12}>
                             another company to leverage their resources: for example, you can source goods from several suppliers
                           </span>
                           <span className={section2Styles.subtitle16_span13}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span14}>• </span>
                           <span className={section2Styles.subtitle16_span15}>Joint ventures</span>
                           <span className={section2Styles.subtitle16_span16}>
                             {' '}
                             – These are partnerships with companies who help you fill a{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span17}>
                             market gap such as entering into a new market or reaching a new niche market. The result of a successful
                             joint venture is enhanced mutual profitability.
                           </span>
                           <span className={section2Styles.subtitle16_span18}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span19}>• </span>
                           <span className={section2Styles.subtitle16_span20}>Co-opetition</span>
                           <span className={section2Styles.subtitle16_span21}>
                             {' '}
                             – These are partnerships between two competitors, which may{' '}
                           </span>
                           <span className={section2Styles.subtitle16_span22}>
                             take place as a merger to market a new product or service.
                           </span>
                         </span>
                       </h3>
                       <h3 className={section2Styles.subtitle16_box}>
                         <span className={section2Styles.subtitle16}>
                           <span className={section2Styles.subtitle16_span0}>9. Cost Structure</span>
                           <span className={section2Styles.subtitle16_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span2}>
                             To set realistic revenue goals, your company needs to estimate its business costs first. These costs
                             will vary depending on the industry.
                           </span>
                           <span className={section2Styles.subtitle16_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.subtitle16_span4}>
                             {
                               'For example, some businesses have to cover R&D expenditures, together with production and post-service. Others, however, exclude this debit from the template.'
                             }
                           </span>
                         </span>
                       </h3>
                     </div>
             
                     <div className={section2Styles.flexCol8}>
                       <h1 className={section2Styles.title}>Business Model Canvas Application and Analysis</h1>
                       <h4 className={section2Styles.highlights11}>
                         Once you have finished populating your Business Model Canvas, you need to analyze it. For this, you should
                         assess the canvas by answering the following:
                         <br />
                         1. Is my model logical and coherent? Are there are any misaligned blocks? If yes, you need to address the
                         issue.
                         <br />
                         2. What can be improved? If you find any mediocre blocks, devote more time to improve them.
                         <br />
                         3. Are there any other ideas to consider? Maybe you can add something to the existing template or even
                         design a new one?
                         <br />
                         4. Does my team agree with the canvas? If not, reconsider the arguable moments. To prove your point of view,
                         rely on data and facts.
                         <br />
                         5. Have I taken into account a long-term competitive advantage? If no, address the point. You need to
                         consider the market and competitors when building a business plan.
                       </h4>
                       <h1 className={section2Styles.title3}>
                         What to Do After application and analysis of your Business Model Canvas?
                       </h1>
                       <h4 className={section2Styles.highlights6_box}>
                         <span className={section2Styles.highlights6}>
                           <span className={section2Styles.highlights6_span0}>
                             After you have examined the canvas, you can integrate it into your business’s daily routine.
                           </span>
                           <span className={section2Styles.highlights6_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span2}>Here is how it can be utilized:</span>
                           <span className={section2Styles.highlights6_span3}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span4}>1. </span>
                           <span className={section2Styles.highlights6_span5}>Track changes</span>
                           <span className={section2Styles.highlights6_span6}>
                             : All company evolves with time, having a solid plan will help to make{' '}
                           </span>
                           <span className={section2Styles.highlights6_span7}>
                             sure that these changes do not go against your business’s core principles.
                           </span>
                           <span className={section2Styles.highlights6_span8}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span9}>2. </span>
                           <span className={section2Styles.highlights6_span10}>Onboard senior-management</span>
                           <span className={section2Styles.highlights6_span11}>
                             : The model communicates who your business is and{' '}
                           </span>
                           <span className={section2Styles.highlights6_span12}>
                             how things work in it. Document and expose new hires to literature about your company.
                           </span>
                           <span className={section2Styles.highlights6_span13}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span14}>3. </span>
                           <span className={section2Styles.highlights6_span15}>Guide the brainstorming</span>
                           <span className={section2Styles.highlights6_span16}>
                             : Every time you have a meeting where idea generation is{' '}
                           </span>
                           <span className={section2Styles.highlights6_span17}>
                             a project, be sure to place your completed business canvas in a key place where it is obvious to
                             everyone. This will help the team to reject ideas that are unfit with the plan.
                           </span>
                         </span>
                       </h4>
                       <h1 className={section2Styles.title4}>Software for Business Model Canvas</h1>
                       <h4 className={section2Styles.highlights6_box}>
                         <span className={section2Styles.highlights6}>
                           <span className={section2Styles.highlights6_span0}>
                             If you would prefer using a digital solution as opposed to the traditional paper and markers, you can
                             opt for software to create your Business Model Canvas. There are many useful tools on the web including:
                           </span>
                           <span className={section2Styles.highlights6_span1}>
                             <br />
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span2}>1. </span>
                           <span className={section2Styles.highlights6_span3}>Canvanizer:</span>
                           <span className={section2Styles.highlights6_span4}>
                             {' '}
                             This tool is free, simple, and shareable. It allows collaborative{' '}
                           </span>
                           <span className={section2Styles.highlights6_span5}>
                             brainstorming in Google Docs. Later, the canvas can be exported to an image or other formats.
                           </span>
                           <span className={section2Styles.highlights6_span6}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span7}>2. </span>
                           <span className={section2Styles.highlights6_span8}>Strategyzer:</span>
                           <span className={section2Styles.highlights6_span9}>
                             {' '}
                             This free software is more advanced with deep analytics and{' '}
                           </span>
                           <span className={section2Styles.highlights6_span10}>
                             enhanced user experience. For example, the tool can assess the financial viability of a business idea.
                             It also offers additional modes: for example, a dashboard for Lean Startup development.
                           </span>
                           <span className={section2Styles.highlights6_span11}>
                             <br />
                           </span>
                           <span className={section2Styles.highlights6_span12}>3. </span>
                           <span className={section2Styles.highlights6_span13}>CNVS:</span>
                           <span className={section2Styles.highlights6_span14}>
                             {' '}
                             The software with a simple interface is easy-to-understand. It allows building{' '}
                           </span>
                           <span className={section2Styles.highlights6_span15}>
                             not only the BMC but also a Lean and Feature Canvas.
                           </span>
                         </span>
                       </h4>
                     </div>
             
                     <div className={section2Styles.content_box3}>
                       <div className={section2Styles.flexCol9}>
                         <h1 className={section2Styles.title21_box}>
                           <span className={section2Styles.title21}>
                             <span className={section2Styles.title21_span0}>
                               You have completed the Lesson on <br />
                             </span>
                             <span className={section2Styles.title21_span1}>
                               AWA Lesson on Business Models.
                               <br />
                             </span>
                             <span className={section2Styles.title21_span2}>
                               Review your input and print if you are happy with the results.
                               <br />
                             </span>
                           </span>
                         </h1>
             
                         <div className={section2Styles.box}><a href="lwa-plan-two">
                           <h3 className={section2Styles.subtitle2}>Return</h3></a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanThree;