import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../misc/AuthService';
import { 
  Input,
  Select,
  Image,
  Space,
  Tooltip
} from 'antd';
import 'antd/dist/antd.css';

class EntrepreneurProfile extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userinfo: AuthService.getAuth().user,
    }
  }

  render(){

    return (
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-xxl">
          <div className="d-flex flex-column flex-lg-row px-lg-20">
            <div className="d-flex flex-column flex-lg-row-auto w-lg-325px mb-8">
              <div className="bg-muted card mb-5 mb-xl-8">
                <div className="card-body pt-15 px-0">
                  <div className="d-flex flex-column text-center mb-9 px-9">
                    <div className="symbol symbol-200px mb-4">
                      {
                        this.props.profileInfo.profile_pic === null ?
                        <img
                          src="/assets/media/avatars/avatar.png"
                          className="avatar"
                          alt="" />
                        :
                        <img
                          src={this.props.profileInfo.profile_pic}
                          className="avatar"
                          alt="" onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }}
                        />
                      }
                      
                    </div>
                    <div className="text-center">                      
                      <span className="text-primary fw-bold text-hover-primary fs-4">
                        {this.props.profileInfo.full_name}
                      </span>
                      <span className="text-gray-600 d-block fw-semibold text-capitalize">
                      {this.props.profileInfo.user_type} - {this.props.profileInfo.user_sub_type}
                      </span>
                    </div>
                  </div>
                  
                  <div className="mx-10">
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i class="las la-venus fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Gender
                        </span>
                        <span className="text-gray-800 d-block fs-7 text-capitalize">{this.props.profileInfo.gender}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i class="lar la-star fs-2x text-primary"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <span className="text-primary fs-7 fw-bold lh-0">
                          Industry Expertise
                        </span>
                        <span className="text-gray-800 d-block fs-7">
                          {this.props.profileInfo.industry_experience}
                        </span>
                      </div>
                    </div>


                  </div>
                  {
                    this.props.owner === true && 
                    <div className="d-flex align-items-center w-100 flex-column px-10 pt-8 border-top">
                      <div className="d-flex justify-content-between w-100 mt-auto mb-2 mx-3">                                    
                        <span className="fw-semibold fs-6 text-primary">
                          Profile Completion
                        </span>
                        <span className="fw-bold fs-6">{this.props.profileInfo.profile_completion}%</span>
                      </div>
                      <div className="h-5px mx-3 w-100 bg-light mb-3">
                        <div
                          className="bg-warning rounded h-5px"
                          role="progressbar"
                          style={{ width: `${this.props.profileInfo.profile_completion}%` }}
                          aria-valuenow={this.props.profileInfo.profile_completion}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  }

                  
                </div>
              </div>
              {
                this.props.owner === true && this.props.profileInfo.connections.connections.length > 0 && 
                <div className="bg-muted card mb-5 mb-xl-8">
                  <a href="/my-connections"><div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-primary fs-5">
                        {this.props.profileInfo.connections.connections.length} {this.props.profileInfo.connections.connections.length > 1 ? `Connections` : `Connection`}
                      </span>
                      
                    </h3>
                  </div>
                  <div className="card-body pt-0">
                    <div className="symbol-group symbol-hover flex-nowrap mb-3">
                      {
                        this.props.profileInfo.connections.connections.map((data, index) => {
                          let avatar = <></>;
                          if(index < 4){
                            avatar = <div key={index} className="symbol symbol-40px symbol-circle">
                                        {
                                          data.profile_pic === null ?
                                          <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} /></Tooltip>
                                          :
                                          <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} /></Tooltip>
                                        } 
                                      </div>
                          }

                          return (
                            <>{avatar}</>
                          )
                        })
                      }
                      {
                        this.props.profileInfo.connections.connections.length > 4 &&
                        <div className="symbol symbol-40px symbol-circle">
                          <Tooltip placement="bottom" title="Click to view all"><span className="symbol-label bg-dark text-gray-800 fs-8 fw-bold">
                            +{this.props.profileInfo.connections.connections.length - 4}
                          </span></Tooltip>
                        </div>
                      }
                    </div>
                    <span className="text-gray-800 mt-1 fw-semibold fs-7">
                      Click to view
                    </span>
                  </div></a>
                </div>
              }
            </div>
            <div className="w-100 flex-lg-row-fluid mx-lg-10">
              <div className="bg-muted card mb-5 mb-xl-10">
                <div
                  className="card-header border-0">
                  <div className="card-title m-0">
                    <h3 className="fw-bold text-primary m-0">Pitch Video</h3>
                  </div>
                </div>
                <div className="collapse show">
                  <div className="card-body border-top p-9">
                    {
                      this.props.profileInfo.pitch_video?.length > 0 ?
                      <video width="100%" height="350" controls>
                        <source src={this.props.profileInfo.pitch_video}/>
                      </video>                      
                      :
                      <div className="text-center text-gray-700 mt-3">
                        <div className="symbol symbol-40px">
                          <i class="las la-video-slash fs-3x text-gray-400"></i>
                        </div>
                        <span className="d-block mt-4">No video found</span>
                        {
                          this.props.owner &&
                          <a href="/account-settings" class="btn btn-sm btn-primary text-warning fs-8 fw-bold my-8">Upload Pitch Video</a>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="bg-muted card mb-5 mb-xl-10">
                <div
                  className="card-header border-0">
                  <div className="card-title m-0">
                    <h3 className="fw-bold text-primary m-0">Company Information</h3>
                  </div>
                </div>
                <div className="collapse show">
                  <div className="card-body border-top p-9">
                    <div className="align-items-center mb-5">
                      {
                        this.props.profileInfo.company_logo?.length > 0 &&
                        <div className="position-relative">
                          <div className="symbol symbol-150px">
                            <img className="avatar" src={this.props.profileInfo.company_logo} />
                          </div>
                        </div>
                      }
                      <div className="mt-5">
                        <a href="#" className="fs-3 fw-bold text-primary text-hover-primary">
                          {this.props.profileInfo.company_name}
                        </a>
                        <div className="text-gray-600 fs-6">{this.props.profileInfo.company_bio}</div>
                      </div>
                    </div>
                    <div className="mt-10">
                      <a href="#" className="fs-5 fw-bold text-primary text-hover-primary">
                        About Founding Team
                      </a>
                      <div className="text-gray-600 fs-6">{this.props.profileInfo.about_founding_team}</div>
                    </div>
                    {
                      this.props.profileInfo.products_services?.length > 0 &&
                      <div className="mt-10">
                        <a href="#" className="fs-5 fw-bold text-primary text-hover-primary">
                          Products & Services
                        </a>
                        <div className="text-gray-800 fs-6">{this.props.profileInfo.products_services}</div>
                        {
                          this.props.profileInfo.products_images?.length > 0 &&
                          <div className="mt-5">
                            <Image.PreviewGroup>
                              <Space size={'middle'}>
                            {
                              this.props.profileInfo.products_images?.map((data, index) => {
                                return (
                                  <Image key={index}
                                  width={60}
                                  height={60}
                                  className ="avatar"
                                  src={data} />
                                )
                              })
                            } 
                              </Space>
                            </Image.PreviewGroup>
                          </div>
                        }
                        
                      </div>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EntrepreneurProfile;