import React, { useRef } from 'react';
import cn from 'classnames';
import ReactDom from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import AuthService from './AuthService';
import CONSTANTS from './Constants';
import classNames from "classnames";
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import moment from 'moment';
import cookie from 'react-cookies';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import styles from '../scss/awaModal.module.scss';
import section2StylesXs from '../scss/Courseplan_section2-xs.module.scss';

const { Option } = Select;
const { TextArea } = Input;

const customStyles = {
  content: {
    top: '25%',
    left: '50%',
    //right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    marginTop: '15%',
    marginLeft: '8%',
    padding: '0% 0%',
    borderRadius: '27px 27px 27px 27px',
    transform: 'translate(-50%, -50%)',
  },

};

const customStylesXs = {
  content: {
    top: '40%',
    left: '50%',
    right: '-45%',
    bottom: '24%',
    //marginRight: '0%',
    marginTop: '15%',
    //marginLeft: '8%',
    padding: '0% 0%',
    borderRadius: '50px',
    transform: 'translate(-50%, -50%)',
  },
};


class FileModal extends React.Component {
  
  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
      isLoading: true,
      isUploading: false,
      isUploadingPitchVideo: false,
      isUploadingLogo: false,
      isUploadingProducts: false,
      isRequesting: false,
      isDeleting: false,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: '',
      deleteFileUrl: '',
      deleteFileType: '',
      profileForm: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: []
      },
      profileFormErrors: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: ''
      },
      errorMessage: '',
      successMessage: '',
    }
  }

  
  handleClick = (e) => {
    this.myRef.current.click()
  };


  handleBusinessCertificateUpload = e => {
    if (e.target.files && e.target.files.length > 0){
      
      const file = e.target.files[0];
      this.setState({
        ...this.state,
        isUploading: true
      })
      
      if (!file) return null;
      const storageRef = ref(storage, `elevatorPitches/${this.state.userInfo.full_name.replace(/\s/g, "_")}-${moment().format()}`)
      
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          let profileForm = this.state.profileForm;
          profileForm.company_logo = downloadURL;
          
          this.setState({
            ...this.state,
            isUploadingLogo: false,
            profileForm: profileForm
          })

          setTimeout(() => {
            this.submitLink(downloadURL);
          }, 300);
        })
      })
    }
    
  }

  submitLink(link){
  this.setState({
    ...this.state,
    isUploading: true,
  })

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/single-save", {
      "lesson_plan": "LP2",
      "field_name": [
          "pitch_submission_link"
      ],
      "input": [
          link
      ]
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      this.props.handleLessonProgress(20);
      
      this.setState({
        ...this.state,
        isUploading: false,
        successMessage: "Your Elevator Pitch has been has been saved successfully."
      })

      this.props.handleClose();
      window.modalShow(this.props.successModal);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          alert('ha1');
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

      window.modalShow('error-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
      window.location = "/server-offlines";
      }
    });
  }
  

  render() {
    return(

      <Modal
        isOpen={!!this.props.selectedOption}
        onRequestClose={this.props.handleClose}
        contentLabel="AWA Modal"
        style={customStyles}
      >
        {
              this.state.isUploading ?
              <div className="inner-pre-loader-container">
                <div className="inner-pre-loader py-20">
                  <div className="text-center">  
                    <div className="spinner-border mt-4" role="status">    
                      <span className="sr-only">Loading...</span>  
                    </div>
                  </div>
                </div>
              </div>
              :
        <div className={cn(styles.root, 'awa-modal')}>
          <div className={styles.wrapper}>
            <div className={styles.flexRow}>
              <div className={styles.flexCol}>
                <h3 className={styles.subtitle}>
                Please attach or scan a copy of your Elevator Pitch 
                </h3>

                  <div className={styles.flexRow1}>
                    <div className={styles.flexCol1}>
                      <div className={styles.flexCol1__item}>
                      <button onClick={this.handleClick} className={styles.iconbut}>
                      <img
                          className={styles.icon1}
                          src={require('../../assets/modal/11a7b9a0ae5e8cb037f5cf1164e08656.png')}
                          alt="alt text"
                        />
                      </button>
                      <input ref={this.myRef} type="file" accept="image/*" onChange={this.handleBusinessCertificateUpload} hidden />
                        
                      </div>
                      <h5 className={styles.highlights}>Attach Video</h5>
                    </div>
                    
                    <div className={styles.flexRow1__spacer} />

                    <div className={styles.flexCol1}>
                      <div className={styles.flexCol1__item1}>
                        <button onClick={this.handleClick} className={styles.iconbut}>
                          <img
                            className={styles.icon11}
                            src={require('../../assets/modal/0e83a474ee6d495431a42c4ab88fe26e.png')}
                            alt="alt text"
                          />
                        </button>
                      </div>
                      <h5 className={styles.highlights}>Take a Video</h5>
                    </div>
                  </div>
              </div>

              <div className={styles.flexRow__item}>
                <button onClick={this.props.handleClose} className={styles.icon} type="button">
                <img className={styles.icon} src={require('../../assets/modal/e7fe37654a542c8322a552c5f6b07b78.png')} alt="alt text" />
                </button>
              </div>
            </div>
          </div>
        </div>
        }

            {/* success modal */}
            <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Uploaded Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
            <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Upload was not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
      </Modal>

    );
  }
}

class FileModalXs extends React.Component {
  
  
  constructor(props){
    super(props);
    this.myRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
      isLoading: true,
      isUploading: false,
      isUploadingPitchVideo: false,
      isUploadingLogo: false,
      isUploadingProducts: false,
      isRequesting: false,
      isDeleting: false,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: '',
      deleteFileUrl: '',
      deleteFileType: '',
      profileForm: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: []
      },
      profileFormErrors: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: ''
      },
      errorMessage: '',
      successMessage: '',
    }
  }

  
  handleClickXs = (e) => {
    this.myRef.current.click()
  };


  handleBusinessCertificateUploadXs = e => {
    if (e.target.files && e.target.files.length > 0){
      
      const file = e.target.files[0];
      this.setState({
        ...this.state,
        isUploading: true
      })
      
      if (!file) return null;
      const storageRef = ref(storage, `elevatorPitches/${this.state.userInfo.full_name.replace(/\s/g, "_")}-${moment().format()}`)
      
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          let profileForm = this.state.profileForm;
          profileForm.company_logo = downloadURL;
          
          this.setState({
            ...this.state,
            isUploading: false,
            profileForm: profileForm
          })

          setTimeout(() => {
            this.submitLinkXs(downloadURL);
          }, 300);
        })
      })
    }
    
  }

  submitLinkXs(link){
  this.setState({
    ...this.state,
    isUploading: true,
  })

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/single-save", {
      "lesson_plan": "LP2",
      "field_name": [
          "pitch_submission_link"
      ],
      "input": [
          link
      ]
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      this.props.handleLessonProgress(20);

      this.setState({
        ...this.state,
        isUploading: false,
        successMessage: "Your Elevator Pitch has been has been saved successfully."
      })

      this.props.handleClose();
      window.modalShow(this.props.successModal);

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          alert('ha1');
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

      window.modalShow('error-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
      window.location = "/server-offline";
      }
    });
  }

  render() {
    return(

      <Modal
        isOpen={!!this.props.selectedOptionXs}
        onRequestClose={this.props.handleCloseXs}
        contentLabel="AWA Modal"
        style={customStylesXs}
      >
        {
              this.state.isUploading ?
              <div className="inner-pre-loader-container">
                <div className="inner-pre-loader py-20">
                  <div className="text-center">  
                    <div className="spinner-border mt-4" role="status">    
                      <span className="sr-only">Loading...</span>  
                    </div>
                  </div>
                </div>
              </div>
              :
      <section className={section2StylesXs.section2}>
        <div className={section2StylesXs.flexColModal}>

          <div className={section2StylesXs.groupModal}>
            <div className={section2StylesXs.background} />

            <div className={section2StylesXs.flexCol2}>
              <div className={section2StylesXs.flexCol2__item}>
                <button onClick={this.props.handleCloseXs} className={styles.iconXs} type="button">
                <img
                  className={section2StylesXs.icon2Modal}
                  src={require('../../assets/modal/e7fe37654a542c8322a552c5f6b07b78.png')}
                  alt="alt text"
                />
                </button>
              </div>
              <div className={section2StylesXs.flexCol3}>
                <h6 className={section2StylesXs.medium_title1Modal}>Please attach or scan a copy of your Elevator Pitch </h6>

                <div className={section2StylesXs.flexRow1Modal}>

                  <div className={section2StylesXs.flexCol6}>
                    <button onClick={this.handleClickXs} className={styles.iconbutXs}>
                      <div
                        className={section2StylesXs.wrapper11Modal}
                        style={{ '--src': `url(${require('../../assets/modal/11a7b9a0ae5e8cb037f5cf1164e08656.png')})` }}
                      />
                    </button>
                    <input ref={this.myRefXs} type="file" accept="image/*" onChange={this.handleBusinessCertificateUploadXs} hidden />
                    <div className={section2StylesXs.text2}>Attach Elevator Pitch</div>
                  </div>

                  <div className={section2StylesXs.flexRow1__spacer2} />

                  <div className={section2StylesXs.flexCol7}>
                    <button onClick={this.handleClickXs} className={styles.iconbutXs}>
                      <div
                        className={section2StylesXs.wrapper12Modal}
                        style={{ '--src': `url(${require('../../assets/modal/0e83a474ee6d495431a42c4ab88fe26e.png')})` }}
                      />
                    </button>
                    <div className={section2StylesXs.text2}>Take a video</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  }
      </Modal>

    );
  }
}

export {FileModal, FileModalXs};
