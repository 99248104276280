import React, { Fragment } from "react";
import axios from 'axios';
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import classNames from "classnames";
import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import moment from 'moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
const { Option } = Select;
const { TextArea } = Input;

class EntrepreneurSettings extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userinfo: AuthService.getAuth().user,
      isLoading: true,
      isUploading: false,
      isUploadingPitchVideo: false,
      isUploadingLogo: false,
      isUploadingProducts: false,
      isRequesting: false,
      isDeleting: false,
      isDeleted: false,
      deleteErrorMessage: '',
      deleteSuccessMessage: '',
      deleteFileUrl: '',
      deleteFileType: '',
      profileForm: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: []
      },
      profileFormErrors: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: ''
      },
      errorMessage: '',
      successMessage: '',
    }
  }

  handleProfilePicUpload = e => {
    if (e.target.files && e.target.files.length > 0){

      const file = e.target.files[0];
      this.setState({
        ...this.state,
        isUploading: true
      })

      if (!file) return null;
      const storageRef = ref(storage, `profilePhoto/${this.props.profileInfo.full_name.replace(/\s/g, "_")}-${moment().format()}`)
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          let profileForm = this.state.profileForm;
          profileForm.profile_pic = downloadURL;
          
          this.setState({
            ...this.state,
            isUploading: false,
            profileForm: profileForm
          })

          setTimeout(() => {
            this.submitProfileForm();
          }, 300);
        })
      })
    }
    
  }

  handlePitchVideoUpload = e => {
    if (e.target.files && e.target.files.length > 0){

      const file = e.target.files[0];
      this.setState({
        ...this.state,
        isUploadingPitchVideo: true
      })

      if (!file) return null;
      const storageRef = ref(storage, `pitchVideos/${this.props.profileInfo.full_name.replace(/\s/g, "_")}-${moment().format()}`)
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          let profileForm = this.state.profileForm;
          profileForm.pitch_video = downloadURL;
          
          this.setState({
            ...this.state,
            isUploadingPitchVideo: false,
            profileForm: profileForm
          })

          setTimeout(() => {
            this.submitProfileForm();
          }, 300);
        })
      })
    }
    
  }

  handleCompanyLogoUpload = e => {
    if (e.target.files && e.target.files.length > 0){

      const file = e.target.files[0];
      this.setState({
        ...this.state,
        isUploadingLogo: true
      })

      if (!file) return null;
      const storageRef = ref(storage, `companyLogos/${this.props.profileInfo.full_name.replace(/\s/g, "_")}-${moment().format()}`)
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          let profileForm = this.state.profileForm;
          profileForm.company_logo = downloadURL;
          
          this.setState({
            ...this.state,
            isUploadingLogo: false,
            profileForm: profileForm
          })

          setTimeout(() => {
            this.submitProfileForm();
          }, 300);
        })
      })
    }
    
  }

  handleProductImageUpload = e => {
    if (e.target.files && e.target.files.length > 0){

      const file = e.target.files[0];
      this.setState({
        ...this.state,
        isUploadingProducts: true
      })

      if (!file) return null;
      const storageRef = ref(storage, `productImages/${this.props.profileInfo.full_name.replace(/\s/g, "_")}-${moment().format()}`)
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          let profileForm = this.state.profileForm;
          profileForm.products_images.push(downloadURL);
          
          this.setState({
            ...this.state,
            isUploadingProducts: false,
            profileForm: profileForm
          })

          setTimeout(() => {
            this.submitProfileForm();
          }, 300);
        })
      })
    }
    
  }

  deleteFile () {
    this.setState({
      ...this.state,
      isDeleting: true
    })
    const pictureRef = ref(storage, this.state.deleteFileUrl);
    deleteObject(pictureRef)
    .then(() => {
      let profileForm = this.state.profileForm;
      let success_message = 'Your profile picture has been deleted successfully.';
      if(this.state.deleteFileType === 'profile_pic'){
        profileForm.profile_pic = '';
        
      }else if(this.state.deleteFileType === 'pitch_video'){
        profileForm.pitch_video = '';
        success_message = 'Your pitch video has been deleted successfully.';
      
      }else if(this.state.deleteFileType === 'company_logo'){
        profileForm.company_logo = '';
        success_message = 'Your company has been deleted successfully.';
      
      }else if(this.state.deleteFileType === 'product_image'){
        profileForm.products_images = profileForm.products_images.filter((image) => image !== this.state.deleteFileUrl);
        success_message = 'The product image has been deleted successfully.';        
      }
      
      this.setState({
        ...this.state,
        isDeleting: false,
        isDeleted: true,
        profileForm: profileForm,
        deleteSuccessMessage: success_message
      })

      setTimeout(() => {
        this.submitProfileForm();
      }, 300);
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        ...this.state,
        isDeleting: false,
        deleteErrorMessage: 'Error: Could not delete the file, Try again later.'
      })
    });
  }

  resetDeleteModal(){
    setTimeout(() => {
      this.setState({
        ...this.state,
        deleteFileUrl: '',
        deleteFileType: '',
        isDeleting: false,
        isDeleted: false,
        deleteSuccessMessage: '',
        deleteErrorMessage: ''
      })
    }, 1000);
  }

  updateProfileInfo(event, name) {
    var profileForm = this.state.profileForm;
    let value = event.target.value;

    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });
	}

  submitProfileForm () {
    let profileFormErrors = this.state.profileFormErrors;

    if(this.state.profileForm.full_name.length === 0){
      profileFormErrors.full_name = 'Input required';
      
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        profileFormErrors: profileFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      profileFormErrors: {
        full_name: '',
        username: '',
        profile_pic: '',
        industry_experience: '',
        pitch_video: '',
        company_name: '',
        company_logo: '',
        company_bio: '',
        about_founding_team: '',
        products_services: '',
        products_images: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/update-profile/entrepreneur", {
      ...this.state.profileForm
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.full_name = this.state.profileForm.full_name;
      userObj.profile_pic = this.state.profileForm.profile_pic;
      reactLocalStorage.setObject('userObj', userObj);
      
      this.setState({
        ...this.state,
        isRequesting: false,
        userinfo: userObj,
        successMessage: "Your profile information has been updated successfully."
      })

      window.modalShow('success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    this.setState({
      ...this.state,
      isLoading: false,
      profileForm: {
        full_name: this.props.profileInfo.full_name,
        username: this.props.profileInfo.username,
        profile_pic: this.props.profileInfo.profile_pic,
        industry_experience: this.props.profileInfo.industry_experience,
        pitch_video: this.props.profileInfo.pitch_video,
        company_name: this.props.profileInfo.company_name,
        company_logo: this.props.profileInfo.company_logo,
        company_bio: this.props.profileInfo.company_bio,
        about_founding_team: this.props.profileInfo.about_founding_team,
        products_services: this.props.profileInfo.products_services,
        products_images: this.props.profileInfo.products_images
      }
    })
  }

  render(){

    return (
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-xxl">
          {
            this.state.isLoading ?
            <div className="inner-pre-loader-container">
              <div className="inner-pre-loader py-20">
                <div className="text-center">  
                  <div className="spinner-border mt-4" role="status">    
                    <span className="sr-only">Loading...</span>  
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="w-100 flex-lg-row-fluid">
                  <div className="card bg-primary card-xl-stretch mb-5 mb-xl-8">
                    <div className="card-body my-3">
                      <div className="d-flex flex-stack">
                        <div className="symbol symbol-60px me-5">
                          
                          <span
                            className="symbol-label"
                            style={{ background: "#ffffff42" }}
                          >
                            <i className="las la-user-alt text-white fs-3x" />
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                          <div className="flex-grow-1 me-10">
                            <div className="d-flex justify-content-between w-100 mt-auto mb-2 text-white">
                              
                              <span className="fw-semibold fs-6">Profile Completion</span>
                              <span className="fw-bold fs-6">{this.state.userinfo.profile_completion}%</span>
                            </div>
                            <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: `${this.state.userinfo.profile_completion}%` }}
                                aria-valuenow={this.state.userinfo.profile_completion}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <div className="text-light-warning fs-8 pt-2">Complete your profile and get access to all features on AWA.</div>
                          </div>
                          {/* <a
                            href="/account-settings"
                            className="btn btn-sm btn-light fs-8 fw-bold mt-2"
                          >
                            Update
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.errorMessage.length > 0 &&
                    <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                      <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                            <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                            <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                          </g>
                        </svg>
                      </span>
                      <div class="d-flex flex-column">
                        <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                    </div>
                  }
                  <div className="card mb-5 mb-xl-10">
                    <div className="card-body border-top p-9">
                      <div className="mb-5">
                        <h3 className="fw-bold mb-1">Profile Information</h3>
                        <span className="text-muted">Update your photo and personal information details here</span>
                      </div>
                      <hr className="text-gray-400 mb-10"/>
                      <div className="row mb-10">
                        <div className="col-xl-3">
                          <label className="required fw-semibold fs-6">Your Profile Picture</label>
                          <div className="form-text mb-3">Allowed file types: png, jpg, jpeg.</div>
                        </div>
                        <div className="col-lg-3">
                          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img className="avatar" src={this.state.profileForm.profile_pic?.length > 0 ? this.state.profileForm.profile_pic : '/assets/media/avatars/avatar.png' } onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex py-5">
                            {
                              this.state.isUploading ?
                              <div className="upload-btn-wrapper">
                                <button disabled className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">
                                  Uploading <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </button>
                              </div>
                              :
                              <div className="upload-btn-wrapper">
                                <button className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-4 mb-2">Update</button>
                                <input type="file" name="myfile" accept="image/*" onChange={this.handleProfilePicUpload}  />
                              </div>
                            }
                            {/* {
                              this.state.profileForm.profile_pic?.length > 0 &&
                              <button disabled={this.state.isUploading} className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2" 
                              data-bs-toggle="modal" data-bs-target="#delete-modal"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  deleteFileType: 'profile_pic',
                                  deleteFileUrl: this.state.profileForm.profile_pic
                                })
                              }}>Remove</button>
                            } */}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          Full Name
                        </label>
                        <div className="col-lg-6">
                          <Input size="large" required type="text" id="full_name" placeholder="Enter your full name" name="full_name" value={this.state.profileForm.full_name} onChange={(e) => {this.updateProfileInfo(e,"full_name");}} />
                          {
                            this.state.profileFormErrors.full_name.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.full_name}</div>
                          }
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          Username
                        </label>
                        <div className="col-lg-6">
                          <Input className="text-lowercase" size="large" required type="text" id="username" placeholder="Enter your username" name="username" value={this.state.profileForm.username} onChange={(e) => {this.updateProfileInfo(e,"username");}} />
                          {
                            this.state.profileFormErrors.username.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.username}</div>
                          }
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          Industry Experience
                        </label>
                        <div className="col-lg-6">
                          <Input size="large" required type="text" id="industry_experience" placeholder="Enter your industry experience" name="industry_experience" value={this.state.profileForm.industry_experience} onChange={(e) => {this.updateProfileInfo(e,"industry_experience");}} />
                          {
                            this.state.profileFormErrors.industry_experience.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.industry_experience}</div>
                          }
                        </div>
                      </div>

                      <div className="row mb-20">
                        <div className="col-lg-3 col-form-label">
                          <label className="fw-semibold fs-6">
                            Pitch Video
                          </label>
                          <div className="form-text mb-3">Allowed file types: mp4, avi, m4aa. Max size: 16mb</div>
                        </div>
                        <div className="col-lg-5">
                          {
                            this.state.profileForm.pitch_video?.length > 0 ?
                            <video width="100%" controls>
                              <source src={this.state.profileForm.pitch_video} />
                            </video>
                            :
                            <div className="text-center text-gray-700 mt-3">
                              <div className="symbol symbol-40px">
                                <i className="las la-video-slash fs-3x text-gray-400"></i>
                              </div>
                              <span className="d-block mt-4">No video found</span>
                            </div>
                          }
                          {
                            this.state.profileFormErrors.pitch_video.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.pitch_video}</div>
                          }
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex py-5">
                            {
                              this.state.isUploadingPitchVideo ?
                              <div className="upload-btn-wrapper">
                                <button disabled className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">
                                  Uploading <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </button>
                              </div>
                              :
                              <div className="upload-btn-wrapper">
                                <button className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-4 mb-2">Update</button>
                                <input type="file" name="myfile" accept="video/*" onChange={this.handlePitchVideoUpload}  />
                              </div>
                            }
                            {
                              this.state.profileForm.pitch_video?.length > 0 &&
                              <button disabled={this.state.isUploadingPitchVideo} className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2" 
                              data-bs-toggle="modal" data-bs-target="#delete-modal"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  deleteFileType: 'pitch_video',
                                  deleteFileUrl: this.state.profileForm.pitch_video
                                })
                              }}>Remove</button>
                            }
                          </div>
                        </div>
                      </div>
                      <hr className="text-gray-400 mb-10"/>
                      <div className="mb-10">
                        <h3 className="fw-bold mb-1">Company Information</h3>
                        <span className="text-muted">Update your company logo and information details here</span>
                      </div>
                      <hr className="text-gray-400 mb-10"/>
                      <div className="row mb-10">
                        <div className="col-xl-3">
                        <label className="required fw-semibold fs-6">Your Company Logo</label>
                          <div className="form-text mb-3">Allowed file types: png, jpg, jpeg.</div>
                        </div>
                        <div className="col-lg-3">
                          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img className="avatar" src={this.state.profileForm.company_logo?.length > 0 ? this.state.profileForm.company_logo : '/assets/media/avatars/company_logo.jpg'} onError={e => { e.currentTarget.src = "/assets/media/avatars/company_logo.jpg"; }} />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex py-5">
                            {
                              this.state.isUploadingLogo ?
                              <div className="upload-btn-wrapper">
                                <button disabled className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">
                                  Uploading <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </button>
                              </div>
                              :
                              <div className="upload-btn-wrapper">
                                <button className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-4 mb-2">Update</button>
                                <input type="file" name="myfile" accept="image/*" onChange={this.handleCompanyLogoUpload}  />
                              </div>
                            }
                            {
                              this.state.profileForm.company_logo?.length > 0 &&
                              <button disabled={this.state.isUploadingLogo} className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2 mb-2" 
                                data-bs-toggle="modal" data-bs-target="#delete-modal"
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    deleteFileType: 'company_logo',
                                    deleteFileUrl: this.state.profileForm.company_logo
                                  })
                                }}>Remove</button>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          Company Name
                        </label>
                        <div className="col-lg-6">
                          <Input size="large" required type="text" id="company_name" placeholder="Enter your company name" name="company_name" value={this.state.profileForm.company_name} onChange={(e) => {this.updateProfileInfo(e,"company_name");}} />
                          {
                            this.state.profileFormErrors.company_name.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.company_name}</div>
                          }
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          Company Bio
                        </label>
                        <div className="col-lg-6">
                          <TextArea required
                            value={this.state.profileForm.company_bio}
                            onChange={(e) => {this.updateProfileInfo(e,"company_bio");}}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                          {
                            this.state.profileFormErrors.company_bio.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.company_bio}</div>
                          }
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          About Founding Team
                        </label>
                        <div className="col-lg-6">
                          <TextArea required
                            value={this.state.profileForm.about_founding_team}
                            onChange={(e) => {this.updateProfileInfo(e,"about_founding_team");}}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                          {
                            this.state.profileFormErrors.about_founding_team.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.about_founding_team}</div>
                          }
                        </div>
                      </div>
                      <div className="row mb-10">
                        <label className="col-lg-3 col-form-label required fw-semibold fs-6">
                          Products / Services
                        </label>
                        <div className="col-lg-6">
                          <TextArea required
                            value={this.state.profileForm.products_services}
                            onChange={(e) => {this.updateProfileInfo(e,"products_services");}}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                          {
                            this.state.profileFormErrors.products_services.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.products_services}</div>
                          }
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-3">
                          <label className="fw-semibold fs-6">Products Gallery</label>
                          <div className="form-text mb-3">Click on the image to delete.</div>
                        </div>
                        <div className="col-lg-6">
                          <div className="d-flex flex-wrap">
                          {
                            this.state.profileForm.products_images?.length > 0 &&
                            <>
                              {
                                this.state.profileForm.products_images?.map((data, index) => {
                                  return (
                                    <img key={index}
                                    width={80}
                                    height={80}
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        deleteFileType: 'product_image',
                                        deleteFileUrl: data
                                      })
                                    }}
                                    data-bs-toggle="modal" data-bs-target="#delete-modal"
                                    className ="avatar me-3 cursor-pointer mb-3"
                                    src={data} />
                                  )
                                })
                              } 
                            </>
                          }
                          {
                            this.state.isUploadingProducts ?
                            <div className="upload-btn-wrapper">
                              <button disabled className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-4 mb-2 p-1 fs-8 w-80px h-80px">
                                <span className="d-block m-2">
                                  <span className="spinner-border spinner-border-sm align-middle"></span>
                                </span>
                                <span className="d-block">Uploading</span>
                              </button>
                            </div>
                            :
                            <div className="upload-btn-wrapper">
                              <button className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-4 mb-2 p-1 fs-8 w-80px h-80px">
                                <span className="d-block"><i className="las la-image fs-2"></i></span>
                                <span className="d-block">Add Image</span>
                              </button>
                              <input type="file" name="myfile" accept="image/*" onChange={this.handleProductImageUpload}  />
                            </div>
                          }
                          </div>
                          {
                            this.state.profileFormErrors.products_images.length > 0 && 
                            <div className="invalid-feedback">{this.state.profileFormErrors.products_images}</div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                    {
                      this.state.isRequesting ?
                      <button type="button" disabled className="btn btn-lg btn-primary">
                        <span>
                          Saving Changes...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                      :
                      <button className="btn btn-primary" onClick={() => {this.submitProfileForm()}}>
                        Save Changes
                      </button>
                    }
                    </div>

                  </div>
                </div>

                {/* success modal */}
                <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-body modal-body-lg text-center">
                        <div className="nk-modal my-5">
                          <div className="timeline-icon symbol symbol-circle symbol-90px">
                            <div className="symbol-label bg-light-success">
                              <i className="las la-check fs-3qx text-success"></i>
                            </div>
                          </div>
                          <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                          
                          <div className="nk-modal-text">
                            <p className="lead">{this.state.successMessage}</p>
                          </div>
                          <div className="nk-modal-action mt-10">
                            <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* modal end */}

                {/* delete modal */}
                <div className="modal fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id="delete-modal">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-body modal-body-lg text-center">
                        
                          <div className="nk-modal my-5">
                          {
                          this.state.isDeleted ?
                            <>
                              <div className="timeline-icon symbol symbol-circle symbol-90px">
                                <div className="symbol-label bg-light-success">
                                  <i className="las la-check fs-3qx text-success"></i>
                                </div>
                              </div>
                              <h3 className="nk-modal-title mt-10">Deleted Successfully</h3>
                              <div className="nk-modal-text">
                                <p className="lead">{this.state.deleteSuccessMessage}</p>
                              </div>
                              <div className="nk-modal-action mt-10">
                                <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                              </div>
                            </>
                            :
                            <>
                              <div className="timeline-icon symbol symbol-circle symbol-90px">
                                <div className="symbol-label bg-light-danger">
                                  <i className="las la-exclamation fs-3qx text-danger"></i>
                                </div>
                              </div>
                              <h3 className="nk-modal-title mt-10">Delete this file?</h3>
                              <div className="nk-modal-text">
                                <p className="lead">Are you sure you want to delete this file?. <br/>This action can not be reverted.</p>
                                {
                                  this.state.deleteErrorMessage.length > 0 && <p className="text-danger">{this.state.deleteErrorMessage}</p>
                                }
                              </div>
                              {
                                this.state.isDeleting ?
                                <div className="nk-modal-action mt-10">
                                  <button className="btn btn-lg btn-mw btn-danger m-2" disabled>
                                    Deleting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </button>
                                  <button className="btn btn-lg btn-mw btn-light m-2" disabled>Close</button>
                                </div>
                                :
                                <div className="nk-modal-action mt-10">
                                  <button className="btn btn-lg btn-mw btn-danger m-2" onClick={() => { this.deleteFile() }}>Delete</button>
                                  <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => { this.resetDeleteModal() }}>Close</button>
                                </div>
                              }
                            </>
                          } 
                          </div>
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
                {/* modal end */}
              </div>
            </div> 
          }
        </div>
      </div>
    )
  }
}

export default EntrepreneurSettings;