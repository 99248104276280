import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import EntrepreneurProfile from "./includes/EntrepreneurProfile";
import InvestorProfile from "./includes/InvestorProfile";
import MentorProfile from "./includes/MentorProfile";
import JobSeekerProfile from "./includes/JobSeekerProfile";
import moment from 'moment';
const { Option } = Select;
const { Search, TextArea } = Input;

class MyProfile extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      user_type: 'entrepreneur',
      profileInfo: {},
      entrepreneur_profile:{
        user_type: 'entrepreneur',
        user_sub_type: 'early-access',
        full_name: 'Afia Bonsu',
        username: 'abonsu',
        profile_pic: '/assets/media/avatars/300-24.jpg',
        gender: 'female',
        industry_experience: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        pitch_video: 'https://firebasestorage.googleapis.com/v0/b/africanwomenamplified.appspot.com/o/video%2F10-16%2F16659497244886AZ2daovsRQepbarF0FYH0axbQW2?alt=media&token=e438b8b9-4c4b-4483-89cd-c76f85dd2dde',
        company_name: 'Cool Corp.',
        company_logo: 'https://cdn.logojoy.com/wp-content/uploads/2018/05/01104823/1454.png',
        company_bio: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        about_founding_team: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        products_services: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        products_images: ['https://media.istockphoto.com/id/1198863709/photo/skin-and-hair-care-beauty-product-mock-up-lotion-bottle-oil-cream-isolated-on-white-3d-render.jpg?s=612x612&w=0&k=20&c=_0-9dLUohaQrThH0669Ygx3Ar17rX0cRkXy0cEexfQw=','https://t3.ftcdn.net/jpg/02/91/48/40/360_F_291484045_P7I20tXjueGJpHAUDmQhnThFWmUa0j9F.jpg'],
        profile_completion: '70',
        learn_with_awa_status: '20'
      },
      mentor_profile:{
        user_type: 'mentor',
        full_name: 'Nana Afua Owusu',
        username: 'nafua',
        profile_pic: 'https://firebasestorage.googleapis.com/v0/b/africanwomenamplified.appspot.com/o/profilePhoto%2F10-13%2F1665691038022fzhxmMXZ5xZICkSP1g6ysKUBz0m1?alt=media&token=7345a512-108f-4013-8301-86bbaa05c4eb',
        company: 'Dash Corp',
        years_as_mentor: '5',
        role: 'Director',
        value_statement: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        location: 'Adenta Barrier',
        country: 'Ghana',
        accepting_applicants: true,
        availability_type: 'Whatsapp',
        availability_time: 'Evening',
        success_story: '',
        companies_individual_mentored: '',
        programs_mentored: ['program 1','program 2','program 3'],
        experience: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        mentorship_approach: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        profile_completion: '70',
      },
      investor_profile:{
        user_type: 'investor',
        full_name: 'Mercy Obeng',
        username: 'mobeng',
        profile_pic: 'https://firebasestorage.googleapis.com/v0/b/africanwomenamplified.appspot.com/o/profilePhoto%2F10-14%2F16657501467072JXvpiE2rve6pe4YxXLr3oxi3ea2?alt=media&token=8d80e19b-effb-4ca8-819d-065cbaf34160',
        role: 'Director',
        years_as_investor: '5',
        company: 'Dash Corp',
        company_url: null,
        company_self_bio: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        investor_type: 'Venture Capital',
        companies_invested: [
          {
            company_name: 'Company 1',
            company_url: 'https://google.com',
            company_logo: 'https://www.shutterstock.com/image-vector/abstract-initial-letter-s-logo-260nw-1862762845.jpg'
          },
          {
            company_name: 'Company 2',
            company_url: 'https://google.com',
            company_logo: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324'
          }
        ],
        industry_interest: 'nisl nisi, scelerisque, euultrices vitae, auctoreu.',
        deal_type: 'Equity',
        deal_size_range: '$50,000 - $100,000',
        geo_interest_area: 'West Africa, East Africa',
        accepting_applicants: false,
        profile_completion: '70',
      },
      job_seeker_profile:{
        user_type: 'job_seeker',
        full_name: 'Ama Danso',
        username: 'amadan',
        profile_pic: 'https://firebasestorage.googleapis.com/v0/b/africanwomenamplified.appspot.com/o/profilePhoto%2F10-17%2F1666035921131f93NaQZezaUVgUN3UN8qD4Nl5S32?alt=media&token=81d7bc76-8731-4b33-9394-054364706ac2',
        role: 'UX Designer',
        country: 'Ghana',
        location: 'Adenta Barrier',
        education_history: [
          {
            school_name: 'SChool 1',
            degree: 'BSc Degree',
            start_year: '2020',
            end_year: 'Current'
          },
          {
            school_name: 'SChool 2',
            degree: 'BSc Degree',
            start_year: '2016',
            end_year: '2020'
          },
        ],
        area_of_interest: '',
        value_statement: 'nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra.',
        work_experience: [
          {
            company_name: 'Company 1',
            location: 'Accra, Ghana',
            position: 'Position 2',
            start_year: '2020',
            end_year: 'Current'
          },
          {
            company_name: 'Company 12',
            location: 'Accra, Ghana',
            position: 'Position 2',
            start_year: '2016',
            end_year: '2020'
          },
        ],
        expertise: ['Expertise 1','Expertise 2'],
        experience: '',
        awards: null,
        certifications: null,
        availability_status: false,
        availability_type: 'Fulltime',
        profile_completion: '70',
      },
    }
	}

  getUserProfile () {
    this.setState({
      ...this.state,
      isRequesting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-my-profile-info", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        profileInfo: responseInfo.data.data
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>My Profile - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'profile'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 mb-8 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          My Profile
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <a href="/home"><li className="breadcrumb-item text-muted me-2">Home </li></a>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted">My Profile</li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <a href="/account-settings" className="btn btn-sm fw-bold btn-primary">
                          Edit Profile
                        </a>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <>
                      {
                        this.state.profileInfo.user_type === 'entrepreneur' &&
                        <EntrepreneurProfile profileInfo={this.state.profileInfo} owner={true} />
                      }

                      {
                        this.state.profileInfo.user_type === 'investor' &&
                        <InvestorProfile profileInfo={this.state.profileInfo} owner={true} />
                      }

                      {
                        this.state.profileInfo.user_type === 'mentor' &&
                        <MentorProfile profileInfo={this.state.profileInfo} owner={true} />
                      }

                      {
                        this.state.profileInfo.user_type === 'job_seeker' &&
                        <JobSeekerProfile profileInfo={this.state.profileInfo} owner={true} />
                      }
                    </>
                  }
                  
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default MyProfile;