import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';

class EmailVerification extends React.Component {
  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: true,
			isVerified: false,
      errorMessage: ''
    }
	}
	
	submitEmailVerificationToken (token) {
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: '',
    });

    axios.post(CONSTANTS.API_BASE_URL + "/auth/verify-email", {token: token})
    .then((response) => {
      this.setState({
        ...this.state,
        isLoading: false,
        isVerified: true
      });

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: errorMessage
        });
      }catch(e){
        window.location = "/server-offline";
      }
    });

  }

  componentDidMount (){
    document.body.style.backgroundColor = '#114aa0';
    // get token and make verification request
    const { match: { params } } = this.props;
		if(params.token){
      this.submitEmailVerificationToken(params.token)
    }else{
			this.setState({
        ...this.state,
        isLoading: false,
      });
		}
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Email Verification - AWA</title>
          </MetaTags>
          <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
            {
              this.state.isLoading?
              <div className="pre-loader-container">
                <div className="pre-loader">
                  <div className="text-center">  
                    <div className="mb-5"><img alt="Logo" src="/assets/media/logos/logo.png" className="h-70px h-lg-75px" /></div>
                    <div className="spinner-border mt-4" role="status">    
                      <span className="sr-only">Loading...</span>  
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                  <div className="bg-body d-flex flex-center rounded-4 w-md-600px p-10 py-20">
                    {
                      !this.state.isVerified ?
                      <div className="w-md-400px">
                        <div className="pt-lg-10 mb-10 text-center">
                          <span className="svg-icon svg-icon-5hx svg-icon-primary me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <path d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z" fill="currentColor" opacity="0.3"/>
                                  <path d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z" fill="currentColor"/>
                              </g>
                            </svg>
                          </span>
                          <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Verify your email</h1>
                          <div className="fs-3 fw-bold text-muted mb-10">
                            We have sent you an email,<br />
                            please use the link in your email to verify your email.
                          </div>
                          <div className="text-center mb-10">
                            <a href="/login" className="btn btn-lg btn-primary fw-bolder">
                              Back to Login
                            </a>
                          </div>
                          {/* <div className="fs-5">
                            <span className="fw-bold text-gray-700">Did’t receive an email?</span>
                            <a href="" className="link-primary fw-bolder">
                              Resend
                            </a>
                          </div> */}
                        </div>
                      </div>
                      :
                      <div className="w-md-400px">
                        <div className="pt-lg-10 mb-10 text-center">
                          <span className="svg-icon svg-icon-5hx svg-icon-primary me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="currentColor" fill-rule="nonzero"/>
                            </g>
                            </svg>
                          </span>
                          <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Email Verified!</h1>
                          <div className="fs-3 fw-bold text-muted mb-10">
                            Your email has been verified successfully.
                            <br />
                            Login to access your account.
                          </div>
                          <div className="text-center mb-10">
                            <a href="/login" className="btn btn-lg btn-primary fw-bolder">
                              Login
                            </a>
                          </div>
                          {/* <div className="fs-5">
                            <span className="fw-bold text-gray-700">Did’t receive an email?</span>
                            <a href="" className="link-primary fw-bolder">
                              Resend
                            </a>
                          </div> */}
                        </div>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            }
          </div>

      </Fragment>
    )
  }
}

export default EmailVerification;