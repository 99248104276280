import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import Jobs from "./includes/Jobs";
import MyJobs from "./includes/MyJobs";
import moment from 'moment';

class Messaging extends React.Component {

    constructor(props){
        AuthService.checkProtected();
        super(props);
        this.state = {
            userInfo: AuthService.getAuth().user,
            isLoading: false,
            isSending: false,
            isRequesting: false,
            feed: [],
            chat: [],
            user_id: '',
            chat_id: '',
            receiverProfile: '',
            message: '',
            lastMessageView: '',
            lastMessageViewRead: ''
        }
    }

    
    getFeed () {

        this.setState({
            ...this.state,
            isLoading: true,
        });

        axios.post(CONSTANTS.API_BASE_URL + "/get-chat-feed", {
        }, AuthService.getAxiosHeaders())
        .then((response) => {
        let responseInfo = response.data;
            this.setState({
                ...this.state,
                feed: responseInfo.data,
                isLoading: false,
            });
        }).catch((error) => {
        try{
            let errorResponse = error.response.data;

            if(error.response.status === 401 || error.response.status === 404){
            window.location = "/not-found";
            return;
            }
        }catch(e){
            console.log(error)
            // window.location = "/server-offline";
        }
        });
    }

    getReceiverProfile (user_id) {

        axios.post(CONSTANTS.API_BASE_URL + "/get-user-profile-info", {
            user_id: user_id
        }, AuthService.getAxiosHeaders())
        .then((response) => {
        let responseInfo = response.data;
            this.setState({
                ...this.state,
                receiverProfile: responseInfo.data.data
            });
        }).catch((error) => {
        try{
            let errorResponse = error.response.data;

            if(error.response.status === 401 || error.response.status === 404){
            window.location = "/not-found";
            return;
            }
        }catch(e){
            console.log(error)
            // window.location = "/server-offline";
        }
        });
    }

    getChat (chat_id) {

        this.setState({
            ...this.state,
            isRequesting: true,
        });

        axios.post(CONSTANTS.API_BASE_URL + "/get-chat", {
            chat_id : chat_id
        }, AuthService.getAxiosHeaders())
        .then((response) => {
        let responseInfo = response.data;

        let chatArray = responseInfo.data.conservations;
            chatArray.sort(function compare(a, b) {
                var dateA = new Date(a.created_at);
                var dateB = new Date(b.created_at);
                return dateA - dateB;
            });

            this.getReceiverProfile(responseInfo.data.user_id);
            this.setState({
                ...this.state,
                chat: chatArray,
                user_id: responseInfo.data.user_id,
                chat_id: chat_id,
                isRequesting: false,
            });

        }).catch((error) => {
        try{
            let errorResponse = error.response.data;

            if(error.response.status === 401 || error.response.status === 404){
            window.location = "/not-found";
            return;
            }
        }catch(e){
            console.log(error)
            // window.location = "/server-offline";
        }
        });
    }


    authParams () {
        const params = new URLSearchParams(window.location.search.slice(1));

        this.getChat(params.get("c"));
    }

    updateMessage(event) {
        let value = event.target.value;
    
        this.setState({
                ...this.state,
          message: value
        });
    
      }


    sendChat () {

        this.setState({
            ...this.state,
            isSending: true,
        });

        axios.post(CONSTANTS.API_BASE_URL + "/send-chat", {
            recipient_id : this.state.user_id,
            chat_id : this.state.chat_id,
            message : this.state.message
        }, AuthService.getAxiosHeaders())
        .then((response) => {
        let responseInfo = response.data;

            let chatArray = responseInfo.data.conservations;
            chatArray.sort(function compare(a, b) {
                var dateA = new Date(a.created_at);
                var dateB = new Date(b.created_at);
                return dateA - dateB;
            });

            this.setState({
                ...this.state,
                chat: chatArray,
                message : '',
                lastMessageView : responseInfo.data.conservations.at(-1).text,
                lastMessageViewRead : responseInfo.data.conservations.at(-1).read,
                isSending: false,
            });
            
        }).catch((error) => {
        try{
            let errorResponse = error.response.data;

            if(error.response.status === 401 || error.response.status === 404){
            window.location = "/not-found";
            return;
            }
        }catch(e){
            console.log(error)
            // window.location = "/server-offline";
        }
        });
    }


    componentDidMount () {
        window.KTComponents.init();
        this.getFeed();
        this.authParams();
    }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Messages - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={''} />
								<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
									<div className="d-flex flex-column flex-column-fluid">
										<div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
											<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
											<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
													<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
													Messages
													</h1>
													<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
													<li className="breadcrumb-item text-muted">Home</li>
													<li className="breadcrumb-item">
															<span className="bullet bg-gray-400 w-5px h-2px" />
													</li>
													<li className="breadcrumb-item text-muted">Messages</li>
													</ul>
											</div>
											<div className="d-flex align-items-center gap-2 gap-lg-3">
													<a href="./community/people" className="btn btn-sm fw-bold btn-primary">
													Start a Conversation
													</a>
											</div>
					
											</div>
										</div>  

										<div id="kt_app_content" className="app-content flex-column-fluid">
											<div id="kt_app_content_container" className="app-container container-xxl">
												<div className="d-flex flex-column flex-lg-row">
													<div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
														<div className="card">
															<div className="card-header pt-7" id="kt_chat_contacts_header">
																{/* <form className="w-100 position-relative" autoComplete="off">
																	<span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
																		<svg
																			width={24}
																			height={24}
																			viewBox="0 0 24 24"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<rect
																				opacity="0.5"
																				x="17.0365"
																				y="15.1223"
																				width="8.15546"
																				height={2}
																				rx={1}
																				transform="rotate(45 17.0365 15.1223)"
																				fill="currentColor"
																			/>
																			<path
																				d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
																				fill="currentColor"
																			/>
																		</svg>
																	</span>
																	<input
																		type="text"
																		className="form-control form-control-solid px-15"
																		name="search"
																		defaultValue=""
																		placeholder="Search by username or email..."
																	/>
																</form> */}
																<h2 className="fs-2 fw-semibold">Chats</h2>
															</div>
															<div className="card-body pt-5" id="kt_chat_contacts_body">
																<div
																	className="scroll-y me-n5 pe-5 h-200px h-lg-auto"
																	data-kt-scroll="true"
																	data-kt-scroll-activate="{default: false, lg: true}"
																	data-kt-scroll-max-height="auto"
																	data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header"
																	data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body"
																	data-kt-scroll-offset="5px"
																	style={{ maxHeight: 41 }}
																>
																	{
																		this.state.isLoading === true ?
																		<div className="inner-pre-loader-container">
																			<div className="inner-pre-loader">
																			<div className="text-center">  
																					<div className="spinner-border mt-4" role="status">    
																					<span className="sr-only">Loading...</span>  
																					</div>
																			</div>
																			</div>
																		</div>
																		:
																		<>
																			{
																				this.state.feed.map((data, index) => {
																					return (
																						<div key={index}>
																							<div className="d-flex flex-stack py-4">
																								<div className="d-flex align-items-center">
																									<div class="symbol symbol-45px symbol-circle">
																										<img className="avatar" src={data.profile_pic} />
																									</div>
																									<div class="ms-5">
																										<a href={`./messages?c=${data.chat_id}`} class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">{data.full_name}</a>
																										{
																										data.last_message !== undefined ?
																										<div class="fw-bold text-muted">{data.last_message.text}</div>
																										:
																										<div class="fw-bold text-muted">Start a conversation</div>
																										}
																									</div>
																								</div>
																								<div className="d-flex flex-column align-items-end ms-2">
																									<span className="text-muted fs-7 mb-1">
																										{moment(data.last_message.created_at, 'YYYY-MM-DD H:mm:ss').calendar({
																											sameDay: '[Today] h:mm a',
																											nextDay: '[Tomorrow] h:mm a',
																											nextWeek: 'dddd',
																											lastDay: '[Yesterday] h:mm a',
																											lastWeek: '[Last] dddd h:mm a',
																											sameElse: 'Do MMM YYYY hh:mm a'
																										})}
																									</span>
																									{
																										data.last_message !== undefined && data.last_message.read == false &&
																										<span class="badge badge-sm badge-circle badge-light-success">New</span>
																									}
																								</div>
																							</div>
																							<div className="separator separator-dashed d-none" />
																						</div>
																					)
																				})
																			}
																		</>
																	}
																	
																</div>
															</div>
														</div>
													</div>
													<div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
														<div className="card" id="kt_chat_messenger">
															<div className="card-header" id="kt_chat_messenger_header">
																<div className="card-title">
																	<div className="d-flex justify-content-center flex-column me-3">
																		
																		<a
																			href="#"
																			className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
																		>
																			{this.state.receiverProfile.full_name}
																		</a>
																		<div className="mb-0 lh-1">
																			<span className="fs-7 fw-semibold text-capitalize text-muted">{this.state.receiverProfile.user_type}</span>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card-body" id="kt_chat_messenger_body">
																<div
																	className="scroll-y me-n5 pe-5 h-300px h-lg-auto"
																	data-kt-element="messages"
																	data-kt-scroll="true"
																	data-kt-scroll-activate="{default: false, lg: true}"
																	data-kt-scroll-max-height="auto"
																	data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
																	data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body"
																	data-kt-scroll-offset="5px"
																	style={{ maxHeight: 259 }}
																>
																	{
																		this.state.chat.map((data, index) => {
																			return (
																				<>
																					{
																						this.state.userInfo.user_id == data.sender_id ?
																						<div className="d-flex justify-content-end mb-10" key={index}>
																							<div className="d-flex flex-column align-items-end">
																								<div className="d-flex align-items-center mb-2">
																									<div className="me-3">
																										
																										<span className="text-muted fs-7 mb-1">
																											{moment(data.created_at, 'YYYY-MM-DD H:mm:ss').calendar({
																												sameDay: '[Today] h:mm a',
																												nextDay: '[Tomorrow] h:mm a',
																												nextWeek: 'dddd',
																												lastDay: '[Yesterday] h:mm a',
																												lastWeek: '[Last] dddd h:mm a',
																												sameElse: 'Do MMM YYYY hh:mm a'
																											})}
																										</span>
																										<a
																											href="#"
																											className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
																										>
																											You
																										</a>
																									</div>
																									<div className="symbol symbol-35px symbol-circle">
																										<img className="avatar" src={this.state.userInfo.profile_pic} />
																									</div>
																								</div>
																								<div
																									className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end text-wrap"
																									data-kt-element="message-text"
																								>{data.text}</div>
																							</div>
																						</div>
																						:
																						<div className="d-flex justify-content-start mb-10" key={index}>
																							<div className="d-flex flex-column align-items-start">
																								<div className="d-flex align-items-center mb-2">
																									<div className="symbol symbol-35px symbol-circle">
																										<img className="avatar" src={this.state.receiverProfile.profile_pic} />
																									</div>
																									<div className="ms-3">
																										<a
																											href="#"
																											className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
																										>
																											{this.state.receiverProfile.full_name}
																										</a>
																										<span className="text-muted fs-7 mb-1">
																											{moment(data.created_at, 'YYYY-MM-DD H:mm:ss').calendar({
																												sameDay: '[Today] h:mm a',
																												nextDay: '[Tomorrow] h:mm a',
																												nextWeek: 'dddd',
																												lastDay: '[Yesterday] h:mm a',
																												lastWeek: '[Last] dddd h:mm a',
																												sameElse: 'Do MMM YYYY hh:mm a'
																											})}
																										</span>
																									</div>
																								</div>
																								<div
																									className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start text-wrap"
																									data-kt-element="message-text"
																								>{data.text}</div>
																							</div>
																						</div>
																					}
																				</>
																			)
																		})
																	}
																</div>
															</div>
															<div class="card-footer pt-4" id="kt_chat_messenger_footer">
																<textarea class="form-control form-control-flush mb-3" 
																rows="1" data-kt-element="input" placeholder="Type a message"
																value={this.state.message}
																onChange={(e) => {this.updateMessage(e);}}
																></textarea>

																<div class="d-flex flex-stack">
																	{ this.state.isSending == true ?
																			<button class="btn btn-primary" 
																			type="button" data-kt-element="send"
																			onClick={() => {this.sendChat()}}
																			>Sending...</button>
																	:
																			<button class="btn btn-primary" 
																			type="button" data-kt-element="send"
																			onClick={() => {this.sendChat()}}
																			>Send</button>
																		}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>


          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default Messaging;