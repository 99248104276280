import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/Governance_section1.module.scss';
import section2Styles from '../scss/Governance_section2.module.scss';
import styles from '../scss/Governance.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanTwo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress(value){
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
          ...this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Ten</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
                successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                  <section className={section1Styles.section1}>
                  <div className={section1Styles.flexCol}>
                    <div className={section1Styles.flexCol1}>
                    
            
                      <div className={section1Styles.content_box}>
                        <div className={section1Styles.flexRow1}>
                          <h1 className={section1Styles.hero_title}>AWA Business Structure and Governance </h1>
                          <div className={section1Styles.flexRow1__spacer} />
            
                        </div>
                      </div>
                    </div>
            
                    <div className={section1Styles.group}>
                      <div className={section1Styles.content_box1}>
                        <div className={section1Styles.flexCol4}>
                          <h2 className={section1Styles.medium_title}>By the end of this session, you should:</h2>
                          <h3 className={section1Styles.subtitle}>
                            1. Know what the following terms mean: business registration types, sole proprietorship, partnership,
                            limited liability company, corporation, board chairman, directors, key competencies of directors, board
                            terms, board compensation, board powers, board meetings, board vacancies, advisory board.
                            <br />
                            2. Know the functions of a a. board b. board chairman  <br />
                            3. Know the difference between a board of directors and an advisory board.
                            <br />
                            4. Know how to appoint a director.
                            <br />
                            5. Know how to source for board members.
                            <br />
                            6. Know the different types of directors.  <br />
                          </h3>
                        </div>
                      </div>
            
                      <img
                        className={section1Styles.image1}
                        src={require('../../assets/courseplan-ten/93f9b76025b2ba893226a842b29cccfc.png')}
                        alt="alt text"
                      />
                    </div>
                  </div>
                </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                    <div className={section2Styles.flexCol}>
                      <h1 className={section2Styles.hero_title1}>Business Registration Types</h1>
              
                      <div className={section2Styles.flexCol1}>
                        <h4 className={section2Styles.highlights1}>
                          When starting your business, you must decide what form of business entity to establish. Presented below are
                          the different kinds of entities you can establish, please note that depending on your geographic region some
                          of these may not be available and/or you may have some not appearing here that will be available in your
                          jurisdiction.
                          <br />
                          <br />
                          The four (4) types discussed below are:
                          <br />
                          <br />
                        </h4>
              
                        <div className={section2Styles.flexCol2}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item}>
                              <img
                                className={section2Styles.icon1}
                                src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer} />
                            <h3 className={section2Styles.subtitle3_box}>
                              <div className={section2Styles.subtitle3}>
                                <span className={section2Styles.subtitle3_span0}>1. Sole Proprietorship</span>
                                <span className={section2Styles.subtitle3_span1}>
                                  {' '}
                                  <br />
                                  <br />
                                  <br />
                                </span>
                              </div>
                            </h3>
                          </div>
              
                          <div className={section2Styles.flexRow1}>
                            <div className={section2Styles.flexRow1__item}>
                              <img
                                className={section2Styles.icon1}
                                src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow1__spacer} />
                            <h3 className={section2Styles.subtitle31}>2. Partnership</h3>
                          </div>
              
                          <div className={section2Styles.flexRow2}>
                            <div className={section2Styles.flexRow2__item}>
                              <img
                                className={section2Styles.icon1}
                                src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow2__spacer} />
                            <h3 className={section2Styles.subtitle3_box}>
                              <div className={section2Styles.subtitle3}>
                                <span className={section2Styles.subtitle3_span0}>
                                  3. Limited Liability Company (LLC)
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span1}>
                                  <br />
                                  <br />
                                </span>
                              </div>
                            </h3>
                          </div>
              
                          <div className={section2Styles.flexRow2}>
                            <div className={section2Styles.flexRow2__item1}>
                              <img
                                className={section2Styles.icon1}
                                src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow2__spacer1} />
                            <h3 className={section2Styles.subtitle31}>4. Corporation</h3>
                          </div>
                        </div>
                      </div>
              
                      <div className={section2Styles.flexCol3}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item1}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer1} />
                          <h3 className={section2Styles.subtitle32_box}>
                            <div className={section2Styles.subtitle32}>
                              <span className={section2Styles.subtitle32_span0}>1. Sole Proprietorship</span>
                              <span className={section2Styles.subtitle32_span1}>
                                {' '}
                                <br />
                                <br />
                                <br />
                              </span>
                            </div>
                          </h3>
                        </div>
              
                        <h4 className={section2Styles.highlights11}>
                          A sole proprietorship is easy to form and gives you complete control of your business. You are automatically
                          considered to be a sole proprietorship if you do business activities but do not register as any other kind
                          of business. Sole proprietorships do not produce a separate business entity. This means your business assets
                          and liabilities are not separate from your personal assets and liabilities. You can be held personally
                          liable for the debts and obligations of the business. Sole proprietorships can be a good choice for low-risk
                          businesses and owners who want to test their business idea before forming a more formal business. Investors
                          such as Venture Capital (VCs), Angel Investors (Angels), High-Net-Worth Individuals (HNWIs), other private
                          equity sources or mechanisms such as Reg A+ funding will not invest in a sole proprietorship.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol4}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item2}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer2} />
                          <h3 className={section2Styles.subtitle33}>2. Partnership</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights12}>
                          Partnerships are the simplest structure for two or more people to own a business together. There are two
                          common kinds of partnerships: limited partnerships (LP) and limited liability partnerships (LLP).
                          <br />
                          Limited partnerships have only one general partner with unlimited liability, and all other partners have
                          limited liability. The partners with limited liability also tend to have limited control over the company,
                          which is documented in a partnership agreement
                          <br />
                          Limited liability partnerships are similar to limited partnerships but give limited liability to every
                          owner. An LLP protects each partner from debts against the partnership, they will not be responsible for the
                          actions of other partners.  <br />
                          Partnerships can be a good choice for businesses with multiple owners, professional groups (like attorneys),
                          and groups who want to test their business idea before forming a more formal business. Equity financing
                          possibilities for partnerships are greater than in proprietorships, however, it is important to note that a
                          general partnership may have problems raising equity capital because adding a new partner requires the
                          unanimous consent of all existing partners. Plus, numerous partners in a general partnership can create
                          burdensome management decision-making.
                          <br />
                          <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol5}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item3}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer3} />
                          <h3 className={section2Styles.subtitle32_box}>
                            <div className={section2Styles.subtitle32}>
                              <span className={section2Styles.subtitle32_span0}>
                                3. Limited Liability Company (LLC)
                                <br />
                              </span>
                              <span className={section2Styles.subtitle32_span1}>
                                <br />
                                <br />
                              </span>
                            </div>
                          </h3>
                        </div>
              
                        <h4 className={section2Styles.highlights12}>
                          An LLC lets you take advantage of the benefits of both the corporation and partnership business structures.
                          LLCs protect you from personal liability in most instances, your personal assets like your vehicle, house,
                          and savings accounts will not be at risk in case your LLC faces bankruptcy or lawsuits.
                          <br />
                          LLCs can be a good choice for medium- or higher-risk businesses, owners with significant personal assets
                          they want protected, and owners who want to pay a lower tax rate than they would with a corporation. While
                          you may not be able to attract as many equity investors as you would through a corporation, you can still
                          have multiple owners, who can bring with them a variety of skills/investments.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.group}>
                        <div className={section2Styles.flexCol6}>
                          <h1 className={section2Styles.hero_title1}>Business Registration Types</h1>
              
                          <div className={section2Styles.flexCol7}>
                            <div className={section2Styles.flexRow}>
                              <div className={section2Styles.flexRow__item4}>
                                <img
                                  className={section2Styles.icon11}
                                  src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow__spacer4} />
                              <div className={section2Styles.flexRow__item5}>
                                <img
                                  className={section2Styles.icon2}
                                  src={require('../../assets/courseplan-ten/eec651ae7cd7d8a3b4a7ffa40061d058.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow__spacer5} />
                              <h3 className={section2Styles.subtitle34}>Ghana</h3>
                            </div>
              
                            <div className={section2Styles.flexRow3}>
                              <div className={section2Styles.flexRow3__item}>
                                <img
                                  className={section2Styles.icon11}
                                  src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow3__spacer} />
                              <div className={section2Styles.flexRow3__item1}>
                                <img
                                  className={section2Styles.icon21}
                                  src={require('../../assets/courseplan-ten/440c7fcfc1ebb6382c866cb617263a21.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow3__spacer1} />
                              <h3 className={section2Styles.subtitle34}>Nigeria</h3>
                            </div>
              
                            <div className={section2Styles.flexRow4}>
                              <div className={section2Styles.flexRow4__item}>
                                <img
                                  className={section2Styles.icon11}
                                  src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow4__spacer} />
                              <div className={section2Styles.flexRow4__item1}>
                                <img
                                  className={section2Styles.icon21}
                                  src={require('../../assets/courseplan-ten/5a62d5674b95b20e01249a6e0521eb66.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow4__spacer1} />
                              <h3 className={section2Styles.subtitle34}>Senegal</h3>
                            </div>
              
                            <div className={section2Styles.flexRow4}>
                              <div className={section2Styles.flexRow4__item2}>
                                <img
                                  className={section2Styles.icon11}
                                  src={require('../../assets/courseplan-ten/ed616a6a9452b41f8a95cb64422247ec.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow4__spacer2} />
                              <div className={section2Styles.flexRow4__item3}>
                                <img
                                  className={section2Styles.icon22}
                                  src={require('../../assets/courseplan-ten/af67ce1fd7ae75218d85bb7bdac42e36.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section2Styles.flexRow4__spacer3} />
                              <h3 className={section2Styles.subtitle34}>Burkina Faso</h3>
                            </div>
                          </div>
                        </div>
              
                        <div className={section2Styles.flexCol8}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item6}>
                              <img
                                className={section2Styles.image2}
                                src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer6} />
                            <h3 className={section2Styles.subtitle33}>4. Corporation</h3>
                          </div>
              
                          <h4 className={section2Styles.highlights13_box}>
                            <div className={section2Styles.highlights13}>
                              <span className={section2Styles.highlights13_span0}>
                                The law regards a corporation as separate from its owners, with legal rights independent of its
                                owners. It can sue, be sued, own and sell property, and sell the rights of ownership in the form of
                                stocks.  <br />
                                There are several types of corporations, including C corporations, S corporations, B corporations,
                                closed corporations, and nonprofit corporations.
                                <br />
                              </span>
                              <span className={section2Styles.highlights13_span1}>C corporations:</span>
                              <span className={section2Styles.highlights13_span2}>
                                {' '}
                                C corporations, owned by shareholders, are taxed as separate entities. Since C corporations allow an
                                unlimited number of investors, many larger companies file for this tax status.
                                <br />
                                <br />
                              </span>
                              <span className={section2Styles.highlights13_span3}>S corporations:</span>
                              <span className={section2Styles.highlights13_span4}>
                                {' '}
                                S corporations were designed for small businesses. They avoid double taxation, much like partnerships
                                and LLCs. Owners also have limited liability protection.  <br />
                                <br />
                              </span>
                              <span className={section2Styles.highlights13_span5}>B corporations:</span>
                              <span className={section2Styles.highlights13_span6}>
                                {' '}
                                B corporations, otherwise known as benefit corporations, are for-profit entities committed to
                                corporate social responsibility and structured to positively impact society.  <br />
                                <br />
                              </span>
                              <span className={section2Styles.highlights13_span7}>Closed corporations:</span>
                              <span className={section2Styles.highlights13_span8}>
                                {' '}
                                Closed corporations, typically run by a few shareholders, are not publicly traded and benefit from
                                limited liability protection. Closed corporations, sometimes referred to as privately held companies,
                                have more flexibility than publicly traded companies.  <br />
                                <br />
                              </span>
                              <span className={section2Styles.highlights13_span9}>Open corporations:</span>
                              <span className={section2Styles.highlights13_span10}>
                                {' '}
                                Open corporations are available for trade on a public market. Each corporation has taken ownership of
                                the company and allows anyone to invest.
                                <br />
                                Nonprofit corporations: Nonprofit corporations exist to help others in some way and are rewarded by
                                tax exemption. These organizations all focus on something other than turning a profit.
                                <br />
                                <br />
                                The corporation structure accommodates widespread ownership of your business. It offers the easiest
                                method for raising capital from multiple investors, particularly investors not necessarily interested
                                in actively participating in the business.
                                <br />
                              </span>
                            </div>
                          </h4>
                        </div>
                      </div>
              
                      <div className={section2Styles.flexCol9}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item7}>
                            <img
                              className={section2Styles.image21}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer7} />
                          <div className={section2Styles.flexRow__item8}>
                            <img
                              className={section2Styles.icon23}
                              src={require('../../assets/courseplan-ten/eec651ae7cd7d8a3b4a7ffa40061d058.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer8} />
                          <h3 className={section2Styles.subtitle35}>Ghana</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights14}>
                          Depending on your business needs there are 6 main types of legal entities you may form in Ghana:
                          <br />
                          {
                            "1. Company Limited by Shares- This is a business entity registered at the Registrar General's Department comprising at least two individuals acting as directors of the company and at least one person (company or individual) acting as a shareholder in the entity with objects which are (usually) for the generation of profit. The liability of the members of this entity are limited by the value of their shares."
                          }
                          <br />
                          2. Company Limited by Guarantee- This company structure is best suited for Not-For-Profit Organisations.
                          This is because the objects of a company limited by guarantee cannot include any for the generation of
                          profit. As such these companies are exempt from corporate tax (although the organisation will have to pay
                          income tax on behalf of any its salaried employees. The Company Limited by Shares and Company Limited by
                          Guarantee are jointly referred to as “Limited Liability Companies” this is because the personal assets of a
                          persons who form such entities are protected from liability. In other words, the liability of members of
                          such a company is limited (by the value of their shares or guarantee).
                          <br />
                          {
                            "3. Company Unlimited by Shares- This is a business entity registered at the Registrar General's Department comprising at least two individuals acting as directors of the company and at least one person (company or individual) acting as a shareholder in the entity with objects which are (usually) for the generation of profit. The liability of the members of this entity are not limited by the value of their shares. It is typically used for entities that give professional advice and services such as law firms for instance."
                          }
                          <br />
                          {
                            "4. Sole Proprietorship- This is an entity exclusively owned by an individual who is entitled to all the profits of the business and personally liable for all liabilities of the business. In Ghana such entities are not required to be incorporated at the Registrar General's Department (RGD). If the owner of this entity would like to operate under a business name it is however required to register such business name at the RGD."
                          }
                          <br />
                          {
                            '5. External Company- An external company is a corporate body incorporated outside of Ghana which seeks to register a place of business in Ghana. Although an external company is subject to Ghanaian tax laws (income & corporate tax), such a company is regulated by the laws of the country in which it was originally incorporated in.'
                          }
                          <br />
                          6. Incorporated Partnership - Where two or more people (up to a maximum of 20) decide to go into business
                          for the generation of profit together, they may decide to do so through an incorporated partnership
                          registered at the RGD. There is no protection of a partners personal assets in an incorporated partnership.
                          The acts of one partner binds the whole partnership.
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol10}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item9}>
                            <img
                              className={section2Styles.image22}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer9} />
                          <div className={section2Styles.flexRow__item10}>
                            <img
                              className={section2Styles.icon24}
                              src={require('../../assets/courseplan-ten/440c7fcfc1ebb6382c866cb617263a21.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer10} />
                          <h3 className={section2Styles.subtitle36}>Nigeria</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights15}>
                          There are different types of company formation allowed under Nigeria’s company law.  By the provisions of
                          section 21 of the CAMA, the various kinds of company that can be formed under the Act are as follows:
                          <br />
                          (a) Company Limited by Shares
                          <br />
                          This is a company in which the financial liability of its members is limited by their shares, hence, they
                          are known as shareholders i.e In the event, the company winds up or becomes insolvent, they will not be
                          liable unless the member if any, that has not fully paid up the value of shares held.
                          <br />
                          (b) Company Limited by Guarantee
                          <br />
                          This is a company in which the financial liability of its members (known as guarantors), in the event of it
                          being wound up or insolvent, is limited up to the amount guaranteed to be contributed to the assets of the
                          company, which cannot be less than N100,000. The amount guaranteed can only be demanded at the time of it
                          being insolvent. This type of companies requires the consent of the Attorney General of the Federation for
                          its registration and are not formed to make profits to be distributed to the members but for the promotion
                          of commerce, art, science, religion, sports, culture, education, research, charity or other similar objects
                          i.e. they do not distribute their profits to their members but rather apply them solely for the promotion of
                          its objects or use them for some other charitable purpose. They are also exempted from paying taxes in
                          Nigeria.
                          <br />
                          (c) Unlimited Liability Company
                          <br />
                          {
                            "An unlimited liability company in Nigeria is the one where its members' financial liability in the event of it being wound up has no limit. Where the financial liability of the company exceeds its assets, the company may reach into its members' personal property to liquidate its debt. In other words, its members have a joint and non-limited obligation to contribute to the assets of the company to enable settlement of its financial liability, if any, in the event of the company's insolvency."
                          }
                          <br />
                          The above-stated types of companies may be registered as a private company or a public company. And upon its
                          registration, it assumes a separate legal personality from those of its members, enjoys perpetual
                          succession, and becomes endowed with the capacity to sue and be sued separately from its members.
                          <br />A company is a private company where its Memorandum and Article of association state so and it
                          restricts the transfer of its shares; limits the maximum number of its members to 50 (although where two or
                          more persons hold one or more shares in a company jointly they will be treated as a single member); cannot
                          invite the public to subscribe for any its share or debenture, unless authorized by law.
                          <br />A public company is any company other than a private company. The minimum membership is two but there
                          is no maximum as is the case with a private company. Public companies are also more regulated than private
                          companies as they are subjected to different external laws outside the CAMA for them to operate. For
                          instance, every public company must comply with the provisions of the Investment and Securities Act (ISA)
                          and the Rules and Regulations of the Securities and Exchange Commission (SEC) in inviting the public to
                          subscribe for any of their shares or debentures.{' '}
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol11}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item11}>
                            <img
                              className={section2Styles.image23}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer11} />
                          <div className={section2Styles.flexRow__item12}>
                            <img
                              className={section2Styles.icon25}
                              src={require('../../assets/courseplan-ten/5a62d5674b95b20e01249a6e0521eb66.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer12} />
                          <h3 className={section2Styles.subtitle31}>Senegal</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights16_box}>
                          <div className={section2Styles.highlights16}>
                            <span className={section2Styles.highlights16_span0}>Legal forms of companies in Senegal are:</span>
                            <span className={section2Styles.highlights16_span1}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span2}>1. Private Limited Company</span>
                            <span className={section2Styles.highlights16_span3}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span4}>Number of partners: Minimum 1 partner.</span>
                            <span className={section2Styles.highlights16_span5}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span6}>Maximum 50 partners.</span>
                            <span className={section2Styles.highlights16_span7}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span8}>
                              Capital (max/min): XOF 100,000 divided into shares of XOF 5,000 minimum.
                            </span>
                            <span className={section2Styles.highlights16_span9}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span10}>
                              Shareholders and liability: Liability is limited to the amount contributed.
                            </span>
                            <span className={section2Styles.highlights16_span11}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span12}>2. Public limited company</span>
                            <span className={section2Styles.highlights16_span13}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span14}>Number of partners: Minimum 1 shareholder.</span>
                            <span className={section2Styles.highlights16_span15}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span16}>
                              Capital (max/min): XOF 10,000,000 divided into shares of XOF 10,000 minimum.
                            </span>
                            <span className={section2Styles.highlights16_span17}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span18}>
                              Shareholders and liability: Liability is limited to the amount contributed.
                            </span>
                            <span className={section2Styles.highlights16_span19}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span20}>3. General Partnership</span>
                            <span className={section2Styles.highlights16_span21}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span22}>Number of partners: Minimum 2</span>
                            <span className={section2Styles.highlights16_span23}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span24}>
                              Capital (max/min): No minimum capital required.
                            </span>
                            <span className={section2Styles.highlights16_span25}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span26}>
                              Shareholders and liability: Partners have joint and unlimited liability
                            </span>
                            <span className={section2Styles.highlights16_span27}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span28}>4. Limited Partnership</span>
                            <span className={section2Styles.highlights16_span29}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span30}>
                              Number of partners: Minimum 2, one active partner and one silent partner
                            </span>
                            <span className={section2Styles.highlights16_span31}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span32}>
                              Capital (max/min): No minimum capital required.
                            </span>
                            <span className={section2Styles.highlights16_span33}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span34}>
                              Shareholders and liability: Limited partners who do not participate in management have limited
                              liability. However, general partners or managing partners have joint and unlimited liability
                            </span>
                            <span className={section2Styles.highlights16_span35}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span36}>
                              The National Agency for the Promotion of Investment and Major Projects (APIX) is responsible for
                              registration in Senegal.
                            </span>
                          </div>
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol12}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item13}>
                            <img
                              className={section2Styles.image23}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer13} />
                          <div className={section2Styles.flexRow__item14}>
                            <img
                              className={section2Styles.icon25}
                              src={require('../../assets/courseplan-ten/af67ce1fd7ae75218d85bb7bdac42e36.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer14} />
                          <h3 className={section2Styles.subtitle31}>Burkina Faso</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights16_box}>
                          <div className={section2Styles.highlights16}>
                            <span className={section2Styles.highlights16_span0}>Legal forms of companies in Burkina Faso are:</span>
                            <span className={section2Styles.highlights16_span1}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span2}>1. Limited Liability Company (LLC/SARL) </span>
                            <span className={section2Styles.highlights16_span3}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span4}>
                              Number of partners: Minimum one shareholder and one director of any nationality
                            </span>
                            <span className={section2Styles.highlights16_span5}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span6}>Capital (max/min): XOF 1,000,000</span>
                            <span className={section2Styles.highlights16_span7}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span8}>
                              Shareholders and liability: Liability is limited to the assets of the company
                            </span>
                            <span className={section2Styles.highlights16_span9}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span10}>2. Public Limited Company (SA)</span>
                            <span className={section2Styles.highlights16_span11}> </span>
                            <span className={section2Styles.highlights16_span12}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span13}>
                              Number of partners: Minimum one shareholder and three directors of any nationality
                            </span>
                            <span className={section2Styles.highlights16_span14}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span15}>Capital (max/min): XOF 10,000,000</span>
                            <span className={section2Styles.highlights16_span16}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span17}>
                              Shareholders and liability: Liability is limited to the assets of the company
                            </span>
                            <span className={section2Styles.highlights16_span18}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span19}>3. Société en Commandite Simple (SAS)</span>
                            <span className={section2Styles.highlights16_span20}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span21}>
                              Number of partners: Minimum one with limited liability and one with unlimited liability
                            </span>
                            <span className={section2Styles.highlights16_span22}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span23}>
                              Capital (max/min): No minimum capital required.
                            </span>
                            <span className={section2Styles.highlights16_span24}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span25}>
                              Shareholders and liability: Sleeping partners who do not participate in management have limited
                              liability. However, the liability of the general partners or managing partners remains unlimited
                            </span>
                            <span className={section2Styles.highlights16_span26}>
                              <br />
                            </span>
                            <span className={section2Styles.highlights16_span27}>
                              The Centre for Formation of Enterprises (Centre des Formalités des Entreprises CEFORE) is responsible
                              for registration in the commercial register of Burkina Faso. Additionally, the commercial court
                              (Tribunal de Commerce) is responsible for managing the commercial register.
                            </span>
                          </div>
                        </h4>
                      </div>
              
                      <h3 className={section2Styles.subtitle2}>
                        Review the business registration types and think of the purpose of your business. Answer the following
                        questions.
                      </h3>
              
                      <div className={section2Styles.wrapper}>
                        <div className={section2Styles.wrapper1}>
                          <h4 className={section2Styles.highlights2}>What level of risk am I willing to accept?</h4>
                        </div>
              
                        <img
                          className={section2Styles.decorator}
                          src={require('../../assets/courseplan-ten/dcdeeddb0a7e40375e9177bffba18a2c.png')}
                          alt="alt text"
                        />
                      </div>
              
                      <div className={section2Styles.wrapper2}>
                        <div className={section2Styles.wrapper11}>
                          <h4 className={section2Styles.highlights21}>
                            Of the various options available in my jurisdiction, which structure would be best for my business?
                            <br />
                          </h4>
                        </div>
              
                        <img
                          className={section2Styles.decorator1}
                          src={require('../../assets/courseplan-ten/8efc9e98263f2bdb5380a1859b40d687.png')}
                          alt="alt text"
                        />
                      </div>
              
                      <div className={section2Styles.wrapper3}>
                        <div className={section2Styles.wrapper12}>
                          <h4 className={section2Styles.highlights22}>What are the advantages in choosing this option?</h4>
                        </div>
              
                        <img
                          className={section2Styles.decorator2}
                          src={require('../../assets/courseplan-ten/dcdeeddb0a7e40375e9177bffba18a2c.png')}
                          alt="alt text"
                        />
                      </div>
              
                      <div className={section2Styles.wrapper4}>
                        <div className={section2Styles.wrapper1}>
                          <h4 className={section2Styles.highlights22}>What are the disadvantages in choosing this option?</h4>
                        </div>
              
                        <img
                          className={section2Styles.decorator}
                          src={require('../../assets/courseplan-ten/dcdeeddb0a7e40375e9177bffba18a2c.png')}
                          alt="alt text"
                        />
                      </div>
              
                      <div className={section2Styles.flexCol13}>
                        <div className={section2Styles.flexCol14}>
                          <h1 className={section2Styles.title}>What is a board in a business context?</h1>
                          <h4 className={section2Styles.highlights17}>
                            A board is a collective consensus decision-making entity where the whole is far greater than the sum of
                            the individuals. It consists of a group of appointed or elected professionals who oversee an organization,
                            company, or business. The Board is responsible for making and enforcing the rules. They meet regularly to
                            set policies or review the progress of the institution and their goal is to represent the viewpoint of
                            shareholder.  <br />
                            The size of a board varies depending on the jurisdiction and the company’s act of that jurisdiction (For
                            Ghana see Company’s Act 2019, Nigeria see Companies and Allied Matters Act (CAMA) 2020). For example, some
                            jurisdictions allow a single director to sit on the board and others require at least three directors.  
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.group1}>
                          <div className={section2Styles.flexCol15}>
                            <h1 className={section2Styles.title}>Functions of the Board</h1>
                            <h4 className={section2Styles.highlights18}>
                              Appointment of key staff
                              <br />
                              Risk Assessment  <br />
                              Monitoring and Evaluation – this is done through establishing a process of reviewing and reviewing data
                              with appropriate analysis
                              <br />
                              Internal control – determine appropriate procedures to be put in place to safe guard the assets of the
                              company
                              <br />
                              Compliance with the applicable laws and regulations
                              <br />
                              Approve the organizational structure.
                              <br />
                            </h4>
                          </div>
              
                          <div className={section2Styles.flexCol16}>
                            <h1 className={section2Styles.title}>Value Addition</h1>
                            <h4 className={section2Styles.highlights17}>
                              Understand their oversight role and their primary duty to the company
                              <br />
                              Feel empowered to question the Management and are comfortable in stating on straightforward explanations
                              from Management
                              <br />
                              Do not undermine the collective decision of the Board
                              <br />
                              Avoid conflicts of interest
                              <br />
                              Do not participate in day to day management of the company
                              <br />
                            </h4>
                          </div>
              
                          <div className={section2Styles.flexCol17}>
                            <h1 className={section2Styles.title}>How many Directors do I need on my Board?</h1>
                            <h4 className={section2Styles.highlights18}>
                              {
                                "According to The Wall Street Journal study, the board should be large enough to carry out the board's fiduciary and other duties effectively and efficiently. For many organizations, that means five to seven board members are ideal."
                              }
                            </h4>
                          </div>
              
                          <div className={section2Styles.flexCol18}>
                            <h1 className={section2Styles.title}>What does a board of directors do?</h1>
                            <h4 className={section2Styles.highlights17}>
                              A board of directors has the primary responsibility of protecting the assets of the company and
                              providing a return on the investments of its stock/shareholders.{' '}
                            </h4>
                          </div>
              
                          <div className={section2Styles.flexCol19}>
                            <h1 className={section2Styles.title1}>
                              A Board is accountable and responsible to many stakeholders including:
                            </h1>
                            <h4 className={section2Styles.highlights19}>
                              Corporate members, society, citizenry, client beneficiaries, regulatory authorities, local authorities,
                              donors, employees, financial institutions, creditors, suppliers.
                            </h4>
                          </div>
              
                          <div className={section2Styles.flexCol20}>
                            <h1 className={section2Styles.title}>Chairperson</h1>
                            <h4 className={section2Styles.highlights17}>
                              {
                                "The Chairperson of the Board of Directors is the executive leader of the board of directors who ensures accountability and is jointly responsible for the management of the officers. The chairperson's job is to ensure that the company's obligations to its stakeholders are met."
                              }
                            </h4>
                          </div>
              
                          <div className={section2Styles.flexCol21}>
                            <h1 className={section2Styles.title}>Functions of Board Chairperson</h1>
                            <h4 className={section2Styles.highlights17}>
                              • Providing leadership
                              <br />• Strategy direction
                              <br />• Chairing meeting
                              <br />• Initiative in setting agenda for meeting
                              <br />• Review minutes of Board meetings
                              <br />• Board remuneration  <br />• Board evaluation  <br />• Foster high corporate ethical culture
                              <br />• External representation
                            </h4>
                          </div>
                        </div>
                      </div>
              
                      <h1 className={section2Styles.hero_title3}>Director types</h1>
              
                      <div className={section2Styles.flexCol22}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item15}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer15} />
                          <h3 className={section2Styles.subtitle37}>Substitute Director</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights110}>
                          {
                            " A substitute director is a person nominated by another director to attend meetings or perform duties on their behalf. A director may nominate a substitute director only if it is permitted by the company's constitution."
                          }
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol23}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item16}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer16} />
                          <h3 className={section2Styles.subtitle37}>Alternate Director</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights110}>
                          Alternate director refers to personnel appointed by the Board, to fill in for a director who might be absent
                          from the country, for more than three (3) months (for Ghana this is for no more than six(6) months away).
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol24}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item17}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer17} />
                          <h3 className={section2Styles.subtitle37}>Executive Directors</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights110}>
                          An executive director is an employee of the company with additional managerial duties.
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol25}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item18}>
                            <img
                              className={section2Styles.image2}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer18} />
                          <h3 className={section2Styles.subtitle37}>Non-Executive Directors</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights110}>
                          A Non-Executive Director is not employed by the company and therefore does not perform day-to-day
                          activities. They are involved in policy making and strategic decisions.
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol26}>
                        <h1 className={section2Styles.title}>Key competencies that Directors must possess</h1>
                        <h3 className={section2Styles.subtitle1_box}>
                          <div className={section2Styles.subtitle1}>
                            <span className={section2Styles.subtitle1_span0}>
                              a. General competencies
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span1}>
                              • Informed business judgment
                              <br />• Enterprise – talent to contribute to creation
                              <br />• Wide Perspective
                              <br />• Common sense  <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span2}>b. Strategic Competencies</span>
                            <span className={section2Styles.subtitle1_span3}>
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span4}>
                              • Change Awareness
                              <br />• Compatibility and prioritization – have scales of priorities so whatever you have you use to
                              greatest advantaged
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span5}>
                              c. Analytical Competencies
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span6}>
                              • Financial literacy
                              <br />• Critical faculty- ability to prove facts, challenge assumptions, identify the advantages and
                              drawbacks or proposals (be discerning)
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span7}>
                              d. Character Competencies
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span8}>
                              • Honesty and accountability
                              <br />• Integrity – high ethical standards
                              <br />• Commitment-making oneself available for meeting and other activities
                              <br />• Courage-strength, character and boldness to pursue one’s own convictions
                              <br />• Objectivity-independence of thought and ability to resist pressure.
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span9}>
                              e. Communication/interaction  <br />
                            </span>
                            <span className={section2Styles.subtitle1_span10}>
                              • Communication – ability to articulate opinions  <br />• Empathy - ability to listen impartially
                              <br />• Teamwork
                              <br />• Adaptability
                              <br />
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span11}>
                              Knowledge competencies
                              <br />
                            </span>
                            <span className={section2Styles.subtitle1_span12}>
                              • Governance
                              <br />• Business management
                              <br />• Marketing  <br />• IT
                              <br />• HR
                              <br />
                              <br />
                            </span>
                          </div>
                        </h3>
                      </div>
              
                      <div className={section2Styles.group2}>
                        <div className={section2Styles.flexCol27}>
                          <h1 className={section2Styles.title}>Board Terms (Length of service)</h1>
                          <h4 className={section2Styles.highlights17}>
                            You can establish term limits for how long a member can sit on your board, including how long a term is
                            and the number of terms a director can serve. Term limits can help by bringing in new talent, ideas, and
                            oversight for your company. Staggering your directors term limits is also a way to ensure the rotation of
                            board members happens over time rather than all at once which would be detrimental as there will be no
                            institutional memory left.  <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol28}>
                          <h1 className={section2Styles.title}>Powers</h1>
                          <h4 className={section2Styles.highlights17}>
                            {
                              "Establish powers the board of directors must ensure progress and profit, like appointing or dismissing officers, creating committees, approving financial decisions, or creating policies, for example. Guiding the extent of a board's power at the onset can help alleviate or prevent potential issues in the future."
                            }
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol29}>
                          <h1 className={section2Styles.title}>Meetings</h1>
                          <h4 className={section2Styles.highlights17}>
                            Review the required number of annual meetings your jurisdiction calls for and include the number you
                            intend to hold noted in your bylaws. Discuss when and how the meetings take place and state the amount of
                            advance notice to be given for each one. Be sure to specify the number of board members that need to be
                            present to hold the meeting officially (quorum), like five out of six attendees, for example.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol30}>
                          <h1 className={section2Styles.title}>Board Vacancies</h1>
                          <h4 className={section2Styles.highlights17}>
                            {
                              "Outline the protocol when a board member's seat is vacant and how it is to be filled. For example, does an existing member select a successor, does executive management vote to appoint one or will members of the company elect a new board member by majority?"
                            }
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol31}>
                          <h1 className={section2Styles.title}>Compensation</h1>
                          <h4 className={section2Styles.highlights17}>
                            {
                              "Compensation for a board of directors often varies. With for-profit companies, stock is often an option since it rewards the board with earnings based on a company's performance and can serve as a motivation. Members of large corporations can also earn a high-grossing annual salary."
                            }
                          </h4>
                        </div>
                      </div>
              
                      <div className={section2Styles.flexCol32}>
                        <h1 className={section2Styles.hero_title2_box}>
                          <div className={section2Styles.hero_title2}>
                            <span className={section2Styles.hero_title2_span0}>
                              Sourcing for Board Members <br />
                            </span>
                            <span className={section2Styles.hero_title2_span1}>(Sourced from Venture Beat)</span>
                            <span className={section2Styles.hero_title2_span2}> </span>
                          </div>
                        </h1>
              
                        <div className={section2Styles.flexCol33}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item19}>
                              <img
                                className={section2Styles.image2}
                                src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer19} />
                            <h3 className={section2Styles.subtitle21}>1.   Look beyond your investors</h3>
                          </div>
              
                          <h4 className={section2Styles.highlights12}>
                            Your board should be representative of your market, both the current phase of your business as well as its
                            future strategic aspirations. It is important therefore to look beyond your current investors for your
                            directors and find directors who are better suited for that director position.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol34}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item20}>
                              <img
                                className={section2Styles.image2}
                                src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer20} />
                            <h3 className={section2Styles.subtitle21}>2.   Find an experienced Chairperson</h3>
                          </div>
              
                          <h4 className={section2Styles.highlights12}>
                            Having an experienced Chairperson to control your board is essential. Find a senior, experienced
                            chairperson who has had significant experience on Boards (at least five (5) to ten (10) years and must be
                            able to effectively manage diverse stakeholder interests internally as well as externally to the company
                            (i.e., board members, employees, investors, regulators, bankers, analysts, policy makers, lobbyists),
                            deftly broker solutions, and negotiate with confidence and skill.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol34}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item21}>
                              <img
                                className={section2Styles.image2}
                                src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer21} />
                            <h3 className={section2Styles.subtitle21}>3.   Research the appropriate board size for your company</h3>
                          </div>
              
                          <h4 className={section2Styles.highlights12}>
                            While the ideal board size varies from company to company, research suggests that five (5) is often the
                            optimal number for a Series B company, growing to seven for a pre-IPO business. Keeping the number of
                            board members low ensures diversity of views, while retaining discipline, focus, engagement, and
                            commitment. It is important to have an odd number to ensure there are no stalemates in voting.
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol35}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item22}>
                              <img
                                className={section2Styles.image2}
                                src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer22} />
                            <h3 className={section2Styles.subtitle21}>4.   Plan for the long term</h3>
                          </div>
              
                          <h4 className={section2Styles.highlights12}>
                            The  most effective boards have the right mix of skills, abilities, and perspectives, so think carefully
                            and holistically about whom you choose for your board. Be particularly thoughtful in selecting your
                            independent director(s) as your organization grows. The independent director will play a critical role in
                            shaping your organization’s culture and guiding it along the path to success. Also consider that you will
                            likely work with each person for five to seven years it is therefore important to make the selection
                            decisions with the long term in mind.
                          </h4>
                        </div>
                      </div>
              
                      <h1 className={section2Styles.hero_title21}>How to appoint a director</h1>
              
                      <div className={section2Styles.flexCol36}>
                        <div className={section2Styles.flexCol37}>
                          <div className={section2Styles.flexRow}>
                            <div className={section2Styles.flexRow__item23}>
                              <img
                                className={section2Styles.image21}
                                src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer23} />
                            <div className={section2Styles.flexRow__item24}>
                              <img
                                className={section2Styles.icon23}
                                src={require('../../assets/courseplan-ten/eec651ae7cd7d8a3b4a7ffa40061d058.png')}
                                alt="alt text"
                              />
                            </div>
                            <div className={section2Styles.flexRow__spacer24} />
                            <h3 className={section2Styles.subtitle22}>How to Appoint a Director in Ghana</h3>
                          </div>
              
                          <h4 className={section2Styles.highlights12}>
                            The first directors of the company are appointed and named in the regulations (now the constitution) of
                            the company.
                            <br />
                            After incorporation, subsequent directors are appointed by an ordinary resolution of the company in
                            general meeting.
                            <br />
                            Before the appointment, the director consents in writing to the appointment and files the same with the
                            Registrar of Companies within 28 days;
                            <br />
                            Makes a statutory declaration (to be filed with the Registrar of Companies) to the effect that the
                            director has not;
                            <br />
                            within the preceding five years of the application for incorporation been charged with or convicted of a
                            criminal offence involving fraud or dishonesty
                            <br />
                            been charged with or convicted of a criminal offence relating to the promotion, incorporation or
                            management of a company,
                            <br />
                            been a director or senior manager of a company that has become insolvent or if the person has been, the
                            date of the insolvency and the particular company.
                            <br />
                            <br />
                            Persons to be appointed directors must be natural persons of a minimum of 18 years of age, a sound mind
                            and must not be bankrupt.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol38}>
                          <h3 className={section2Styles.subtitle23}>Board Vacancies</h3>
                          <h4 className={section2Styles.highlights17}>
                            Signed consent letter of the person to be appointed.
                            <br />
                            Statutory declaration of potential director.
                            <br />
                            Ordinary resolution of appointment.
                            <br />
                            Completed Form 17 of the Registrar Companies ‘Change of Directors’.
                            <br />
                            Tax Identification Numbers of all new directors (In line with section 4 of the Tax Identification
                            Numbering System Act, 2002 (Act 632))
                            <br />
                          </h4>
                        </div>
                      </div>
              
                      <div className={section2Styles.flexCol39}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item25}>
                            <img
                              className={section2Styles.image22}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer25} />
                          <div className={section2Styles.flexRow__item26}>
                            <img
                              className={section2Styles.icon24}
                              src={require('../../assets/courseplan-ten/440c7fcfc1ebb6382c866cb617263a21.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer26} />
                          <h3 className={section2Styles.subtitle4}>How to appoint a director in Nigeria</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights15}>
                          The appointment of directors to the board of an existing company differs from that of a company at
                          incorporation stage in that the latter requires some board or shareholders’ resolution whereas the former
                          does not.
                          <br />
                          The particulars of directors required to be registered in a company’s register of directors are the same
                          which will be required to file their appointment with CAC. They are as follows:
                          <br />
                          i. Full names of director;
                          <br />
                          ii. Contact and home addresses of director including his/her Nationality, State and Local Government;
                          <br />
                          iii. Date of birth;
                          <br />
                          iv. Telephone number and email address;
                          <br />
                          v. Acceptance letter signed by director showing consent to be appointed as director;
                          <br />
                          vi. Board Resolution;
                          <br />
                          vii. Government-issued means of identity; and
                          <br />
                          viii. Residence permit (only for non-Nigerian directors who are resident in Nigeria or submit a Nigerian
                          address as his or her contact address).
                          <br />
                          Please note that the appointment of additional directors is now completed online using the CAC portal
                          system. <br />
                          Notice of appointment of a director shall be filed with the CAC within fifteen (15) days of the appointment
                          in accordance with Section 262(1) of CAMA though Section 321 imposes a duty on a company to notify CAC
                          within fourteen (14) days of the appointment or cessation of a person as a director. It is the
                          post-appointment filing at the CAC that formalizes or completes the appointment process.
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol40}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item27}>
                            <img
                              className={section2Styles.image23}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer27} />
                          <div className={section2Styles.flexRow__item28}>
                            <img
                              className={section2Styles.icon25}
                              src={require('../../assets/courseplan-ten/af67ce1fd7ae75218d85bb7bdac42e36.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer28} />
                          <h3 className={section2Styles.subtitle41}>How to appoint a director in Burkina Faso</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights15}>
                          Limited Liability Company (LLC/SARL): must have at least one managing director (gérant). It is recommended
                          that someone who is either based in or regularly travels to Burkina Faso be appointed as managing director,
                          as it is required for such a person to hold a long-term visa. There is no requirement to appoint directors /
                          managers in addition to the managing director. <br />
                          Public Limited Company (SA): must appoint a chairman of the board who can also act as general manager of the
                          company (directeur général). A board of directors with three to 12 members, including a chairman, is to be
                          appointed. It is also possible to have a sole managing director (administrateur general)  <br />
                          SAS: Free to determine its management structure, which should, as a minimum, consist of one chairman. There
                          is no requirement to appoint a board of directors.{' '}
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol41}>
                        <div className={section2Styles.flexRow}>
                          <div className={section2Styles.flexRow__item29}>
                            <img
                              className={section2Styles.image23}
                              src={require('../../assets/courseplan-ten/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer29} />
                          <div className={section2Styles.flexRow__item30}>
                            <img
                              className={section2Styles.icon25}
                              src={require('../../assets/courseplan-ten/5a62d5674b95b20e01249a6e0521eb66.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow__spacer30} />
                          <h3 className={section2Styles.subtitle41}>How to appoint a director in Senegal</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights15}>
                          Limited Liability Company (LLC/SARL): must have at least one managing director (gérant). It is recommended
                          that someone who is either based in or regularly travels to Burkina Faso be appointed as managing director,
                          as it is required for such a person to hold a long-term visa. There is no requirement to appoint directors /
                          managers in addition to the managing director. <br />
                          Public Limited Company (SA): must appoint a chairman of the board who can also act as general manager of the
                          company (directeur général). A board of directors with three to 12 members, including a chairman, is to be
                          appointed. <br />
                          SAS: Free to determine its management structure, which should, as a minimum, consist of one chairman. There
                          is no requirement to appoint a board of directors.{' '}
                        </h4>
                      </div>
              
                      <div className={section2Styles.group3}>
                        <div className={section2Styles.flexCol42}>
                          <h1 className={section2Styles.title}>Consider a board of advisors as an alternative</h1>
                          <h4 className={section2Styles.highlights17}>
                            Depending on the type of business you are running, a board of advisors may be more suitable for you than a
                            board of directors. An advisory board gives recommendations a company can consider, though it has no
                            governing authority overall. The advisory board allows you to benefit from brainstorming sessions and
                            company decision-making discussion and debate without the official authority or fiduciary responsibility a
                            board of directors has.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol43}>
                          <h1 className={section2Styles.title}>Advisory Board (Sourced from Advisory Board Center)</h1>
                          <h4 className={section2Styles.highlights17}>
                            An advisory board is an informal body that presents a structured and collaborative way for businesses to
                            engage with experts outside of the institution. Advisory boards act as a sounding board.
                            <br />
                            This type of board is useful in scaling businesses as well as larger entities like emerging corporates,
                            multinationals, non-profits, etc. A well structured advisory board with best practice principles allows
                            people in the organization to test their strategic thinking and access expertise or connections that may
                            not be readily available via other means.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol44}>
                          <h1 className={section2Styles.title}>The Purpose of an Advisory Board</h1>
                          <h4 className={section2Styles.highlights17}>
                            The purpose of most advisory boards is to help the organization gain new insights and advice to solve
                            business problems or explore new opportunities by stimulating robust, high-quality conversations. The role
                            of an advisory board is not to make decisions for the company, but rather to provide current knowledge,
                            critical thinking, and analysis to increase the confidence of the decision-makers who represent the
                            company. Unlike a Board of Directors, the advice of an advisory board can be accepted or discarded.   
                            <br />
                            Due to its flexible nature, the scope, or ‘terms of reference’, and advisor roles are chosen to fit the
                            business requirements. The specific roles, responsibilities and expectations are normally established
                            within the advisory board Charter, alongside protocols within the advisory board structure.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol45}>
                          <h1 className={section2Styles.title}>Advisory Board Roles and Responsibilities</h1>
                          <h4 className={section2Styles.highlights17}>
                            There are typically three key roles within an advisory board structure. These include a Chair, external
                            advisors and internal directors/stakeholders/organizational representatives.
                            <br />
                            For scaling businesses and emerging corporates, the most common structure is one independent Chair, two
                            external advisors and two internal business representatives (generally the business owner, director and/or
                            CEO). This structure provides a balance of facilitation, external advice, and follow-through for
                            implementation.
                            <br />
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol46}>
                          <h1 className={section2Styles.title}>Advisory Board Chairs</h1>
                          <h4 className={section2Styles.highlights111}>
                            Best practice for advisory boards is to appoint an independent Chair. The role of the Chair is to
                            establish and facilitate a formal advisory board structure to support effective advisory board outcomes.
                            This may include:
                            <br />
                            Establishment of new advisory board structures
                            <br />
                            Evaluation of existing Advisory Board structures for effectiveness and best practice
                            <br />
                            Facilitation of Advisory Board planning and meetings
                            <br />
                            Contribution to Advisory Board outcomes through value-driven insights and advice
                            <br />
                            Mentoring to the Business owner or executive
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol47}>
                          <h1 className={section2Styles.title}>External Advisors</h1>
                          <h4 className={section2Styles.highlights111}>
                            External advisors are appointed by the business for their knowledge and direct experience to problem
                            solve, explore options/concepts and strategic direction. Advisory board participants who are
                            ‘fit-for-purpose’ will be able to actively contribute to the goals and desired outcomes defined in the
                            charter. This may include:
                            <br />
                            Contribution to advisory board outcomes through value-driven insights and advice
                            <br />
                            Strategic introductions and advocacy within approved guidelines for the organization
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol48}>
                          <h1 className={section2Styles.title}>Internal directors and stakeholders</h1>
                          <h4 className={section2Styles.highlights111}>
                            Internal directors and stakeholders are ultimately the ones to establish (or disband) an advisory board,
                            make the business decisions and follow through on their commitments or implementation plans. Internal
                            directors and stakeholders are directly linked within the business and are often founders, directors,
                            CEO’s, lead investors and other senior executives.
                            <br />
                            Advisory boards can be especially helpful for startup organizations and small organizations that have
                            little resources to work with. No parameters exist with regards to which types of organizations can create
                            an advisory board. Boards have the liberty of expanding or decreasing the size of their advisory boards
                            and add or remove people as they see fit. Advisory board directors can be recruited to serve only as
                            they’re needed, and they can be easily replaced.
                          </h4>
                        </div>
              
                        <div className={section2Styles.flexCol49}>
                          <h1 className={section2Styles.title2}>
                            Advantages of a Board of Advisors vs. a Board of Directors (Sourced from Eminutes.com)
                          </h1>
                          <h4 className={section2Styles.highlights112}>
                            1. Easy to Create and Expand <br />A board of directors has legally defined responsibilities and is
                            usually elected by the shareholders and governed by the corporation’s bylaws. Therefore, the management
                            team’s ability to create and expand its board of directors is restricted by law and corporate policy.
                            Moreover, directors are elected for established terms and may be difficult to remove.
                            <br />
                            An advisory board, on the other hand, is informal group of experts and advisors hand-picked by the CEO and
                            management team. It is relatively easy to create, expand or decrease the size of an advisory board in
                            order to meet the needs of the organization. Moreover, members of a board of advisors can be recruited to
                            serve only as long as they are needed and can be easily replaced.
                            <br />
                            2. Less Costly
                            <br />
                            Since a board of directors has a fiduciary duty to the company and can be held personally liable for
                            mistakes, board members often receive generous compensation. Therefore, securing or expanding a board of
                            directors can be expensive, especially for smaller companies with limited means.
                            <br />
                            On the other hand, a board of advisors cannot be held liable for mistakes made in connection with their
                            duties and, therefore, less compensation is required to retain an advisor. Members of an advisory board
                            are usually compensated through an equity interest in the company or through a small yearly stipend.
                            <br />
                            3. Beholden to the Management Team
                            <br />
                            The fiduciary duty of a board of directors requires it to place the needs of the organization and its
                            shareholders before the needs of its employees. Conversely, a board of advisors has no such duty to the
                            company; directing, mentoring and advising the CEO and management team are the advisory board’s foremost
                            priority.
                            <br />
                            4. Not Liable To the Company and Shareholders
                            <br />
                            {
                              'Companies frequently obtain Directors & Officers Liability (D&O) Insurance to indemnify directors against claims from shareholders, employees and clients. Sarbanes-Oxley and other recent legislation have raised the accountability of corporate directors, increasing the risk that they will be found liable for acts performed connection with their duties. As a result, the cost of D&O insurance has become prohibitively expensive for small companies. Employing a board of advisors instead of a board of directors eliminates the need for costly D&O insurance.'
                            }
                          </h4>
                        </div>
                      </div>
              
                      <div className={section2Styles.content_box2}>
                        <div className={section2Styles.flexCol50}>
                          <h1 className={section2Styles.title3_box}>
                            <div className={section2Styles.title3}>
                              <span className={section2Styles.title3_span0}>
                                You have completed the Lesson on <br />
                              </span>
                              <span className={section2Styles.title3_span1}>
                                Business Structure and Governance.  <br />
                              </span>
                              <span className={section2Styles.title3_span2}>
                                Review your input and print if you are happy with the results.
                                <br />
                              </span>
                            </div>
                          </h1>
              
                          <div className={section2Styles.box}>
                            <h3 className={section2Styles.subtitle5}>Review</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanTwo;