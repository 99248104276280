import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import EntrepreneurProfile from "./includes/EntrepreneurProfile";
import InvestorProfile from "./includes/InvestorProfile";
import MentorProfile from "./includes/MentorProfile";
import JobSeekerProfile from "./includes/JobSeekerProfile";
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';

class People extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isMessaging: false,
      isRequesting: true,
      isLoadingSidePanel: false,
      isLoadingMore: false,
      viewProfile: false,
      showLoadMore: true,
      viewProfileData: {},
      search: '',
      user_type: [],
      last_user_id: null,
      people: [],
      communities: [],
      errorMessage: ''
    }
	}

  updateFilter = (event, name) => {
    let value = event.target.value;
    let user_type = this.state.user_type;
    let search = this.state.search;

    if(name == 'entrepreneur' || name == 'mentor' || name == 'investor' || name == 'job seeker'){
      let user_type = this.state.user_type;
      if(user_type.includes(value)){
        const index = user_type.indexOf(value);
        if (index > -1) {
          user_type.splice(index, 1);
        }
      }else{
        user_type.push(value);
      }
    }else{
      search = value;
    }

    this.setState({
      ...this.state,
      search: search,
      user_type: user_type
    })

  }

  getPeople(load=null){
    this.setState({
      ...this.state,
      isRequesting: load === null ? true : false,
      isLoadingMore: load === null ? false : true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-people", {
      search: this.state.search,
      user_type: this.state.user_type,
      last_user_id: load === null ? null : this.state.last_user_id,
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let last_user_id = null;
      let people = responseInfo.data.people;
      let showLoadMore = people.length > 19 ? true : false;
      
      if(load !== null){
        let old_people = this.state.people;
        people = old_people.concat(people);
      }

      if(people.length > 0){
        last_user_id = people[people.length - 1].user_id
      }
      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        showLoadMore: showLoadMore,
        isLoadingMore: false,
        last_user_id: last_user_id,
        people: people
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          isLoadingMore: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  createConnection (user_id) {

    axios.post(CONSTANTS.API_BASE_URL + "/create-connection", {
      user_id: user_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  deleteConnection (connection_id) {

    axios.post(CONSTANTS.API_BASE_URL + "/delete-connection", {
      connection_id: connection_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  initializeChat (user_id) {

    this.setState({
      ...this.state,
      isMessaging: true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/initialize-chat", {
      user_id: user_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isMessaging: false,
      })
      window.location = `/messages?c=${responseInfo.data.chat_id}`

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getPeople();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>People - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'community'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          People
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <li className="breadcrumb-item text-muted">Community</li>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted">People</li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <div className="m-0">
                          <a
                            href="#"
                            className="btn btn-sm btn-flex bg-warning btn-color-primary btn-active-color-primary fw-bold"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            
                            <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            Filter
                          </a>
                          <div
                            className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                            data-kt-menu="true"
                            id="kt_menu_6371365f4a778"
                          >
                            <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bold">Filter Options</div>
                            </div>
                            <div className="separator border-gray-200" />
                            <div className="px-7 py-5">
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Search:</label>
                                <Input size="large" required type="text" id="search" placeholder="search by name" name="search" value={this.state.search} onChange={(e) => {this.updateFilter(e,"search");}} />
                              </div>
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Member Type:</label>
                                <div className="d-flex flex-column gap-5">
                                  <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                    <input
                                      className="form-check-input"
                                      name="user_type"
                                      value="entrepreneur"
                                      type="checkbox"
                                      onChange={(e) => {this.updateFilter(e, "entrepreneur")}}
                                      checked={this.state.user_type.includes("entrepreneur")}
                                    />
                                    <span className="form-check-label">Entrepreneur</span>
                                  </label>
                                  <label className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      name="user_type"
                                      value="mentor"
                                      type="checkbox"
                                      onChange={(e) => {this.updateFilter(e, "mentor")}}
                                      checked={this.state.user_type.includes("mentor")}
                                    />
                                    <span className="form-check-label">Mentor</span>
                                  </label>
                                  <label className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      name="user_type"
                                      value="investor"
                                      type="checkbox"
                                      onChange={(e) => {this.updateFilter(e, "investor")}}
                                      checked={this.state.user_type.includes("investor")}
                                    />
                                    <span className="form-check-label">Investor</span>
                                  </label>
                                  <label className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      name="user_type"
                                      value="job seeker"
                                      type="checkbox"
                                      onChange={(e) => {this.updateFilter(e, "job seeker")}}
                                      checked={this.state.user_type.includes("job seeker")}
                                    />
                                    <span className="form-check-label">Job Seeker</span>
                                  </label>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      search: '',
                                      user_type: []
                                    })

                                    setTimeout(() => {
                                      this.getPeople();
                                    }, 400);
                                  }}
                                  className="btn btn-sm btn-light btn-active-light-primary me-2"
                                  data-kt-menu-dismiss="true"
                                >
                                  Reset
                                </button>
                                <button
                                  onClick={() => {this.getPeople()}}
                                  disabled={this.state.isRequesting}
                                  className="btn btn-sm btn-primary"
                                  data-kt-menu-dismiss="true"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a
                          href="/community"
                          className="btn btn-sm fw-bold btn-primary text-warning"
                        >
                          View Communities
                        </a>
                      </div>

                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">                      
                        {
                          this.state.viewProfile ?
                          <div>
                            <div className="app-container">
                              <span className="d-block px-lg-20 pb-10">
                                <button onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    viewProfile: false,
                                    viewProfileData: {}
                                  })
                                }} className="btn btn-sm btn-primary">
                                  <i class="las la-long-arrow-alt-left"></i> Go Back
                                </button>
                              </span>
                            </div>
                            {
                              this.state.viewProfileData.user_type === 'entrepreneur' &&
                              <EntrepreneurProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'investor' &&
                              <InvestorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'mentor' &&
                              <MentorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'job_seeker' &&
                              <JobSeekerProfile profileInfo={this.state.viewProfileData} />
                            }
                          </div>
                          :
                          <div className="d-flex flex-row px-lg-20">
                            {/* main panel */}
                            <div className="w-100 flex-lg-row-fluid me-lg-13">
                              {/* main content */}
                              {
                                this.state.isRequesting ?
                                <div className="inner-pre-loader-container">
                                  <div className="inner-pre-loader py-20">
                                    <div className="text-center">  
                                      <div className="spinner-border mt-4" role="status">    
                                        <span className="sr-only">Loading...</span>  
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {
                                    this.state.people.length === 0 ?
                                    <div className="text-center text-gray-700 mt-0 border-gray-300 border-dotted py-20">
                                      <div className="symbol symbol-40px">
                                        <i className="las la-user-slash fs-5qx text-gray-400"></i>
                                      </div>
                                      <span className="d-block mt-4">No one found</span>
                                    </div>                   
                                    :
                                    <div className="row g-5">
                                      {
                                        this.state.people.map((data, index) => {
                                          return (
                                            <div className="col-md-4" key={index}>
                                              <div className="card h-100">
                                                <div className="card-body d-flex text-center flex-column pt-12 p-9">
                                                  <div className="flex-center">
                                                    <div className="symbol symbol-100px symbol-circle mb-5">
                                                      {
                                                        data.profile_pic === null ?
                                                        <img className="align-self-center avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                                                        :
                                                        <img className="align-self-center avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                                                      }
                                                      {
                                                        data.user_type !== 'entrepreneur' && data.accepting_applicants === true && 
                                                        <div className="bg-success position-absolute border border-4 border-body h-20px w-20px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3" />
                                                      }
                                                    </div>
                                                  </div>
                                                  <a className="fs-4 text-primary text-hover-primary fw-bold mb-0" onClick={() => {
                                                    this.setState({
                                                      ...this.state,
                                                      viewProfile: true,
                                                      viewProfileData: data
                                                    })
                                                  }}>
                                                    {data.full_name}
                                                  </a>
                                                  <div className="fw-semibold text-primary mb-4 text-capitalize">
                                                    {data.user_type}
                                                  </div>
                                                  <ul class="nav nav-pills nav-pills-custom flex-row border-transparent fw-bold h-100px px-10 justify-content-center">
                                                    <li class="nav-item mt-1 me-0 text-center">
                                                      <a onClick={() => {
                                                        this.setState({
                                                          ...this.state,
                                                          viewProfile: true,
                                                          viewProfileData: data
                                                        })
                                                      }}class="nav-link text-muted text-hover-primary ms-0 p-1 border-0 fs-8"> 
                                                        <span className="symbol symbol-20px me-3">
                                                          <span className="symbol-label bg-white">
                                                            <i className="las la-user-alt fs-2 text-warning"></i>
                                                          </span>
                                                        </span>
                                                        <span className="fs-7 text-primary">View profile</span> 
                                                      </a>
                                                    </li>
                                                    {
                                                      data.connection_status == 'accepted' && data.user_type == 'mentor' && data.user_type !== 'investor' &&
                                                      <li class="nav-item mt-1 me-0 text-center">
                                                        <a href={`${data.schedule_link}`} target="_blank" class="nav-link text-muted text-hover-primary ms-0 p-1 border-0 fs-8"> 
                                                          <span className="symbol symbol-20px me-3">
                                                            <span className="symbol-label bg-white">
                                                              <i className="las la-calendar-check fs-2 text-warning"></i>
                                                            </span>
                                                          </span>
                                                          <span className="fs-7 text-primary">Schedule a meeting</span> 
                                                        </a>
                                                      </li>
                                                    }

                                                    {
                                                      data.connection_status == 'accepted' &&
                                                      <li class="nav-item mt-1 me-0 text-center">
                                                        <a 
                                                        onClick={() => {
                                                          this.initializeChat(data.user_id);
                                                        }}

                                                        class="nav-link text-muted text-hover-primary ms-0 p-1 border-0 fs-8"> 
                                                          <span className="symbol symbol-20px me-3">
                                                            <span className="symbol-label bg-white">
                                                              <i className="las la-comment fs-2 text-warning"></i>
                                                            </span>
                                                          </span>
                                                          { this.state.isMessaging == true ?
                                                            <span className="fs-7 text-primary">Opening Chat</span> 
                                                          :
                                                            <span className="fs-7 text-primary">Send a message</span> 
                                                          }
                                                        </a>
                                                      </li>
                                                    }
                                                  </ul>
                                                  <div className="text-center mt-5">
                                                    {
                                                      data.connection_status == 'pending' ?
                                                      <a href="/my-connections" className="btn btn-sm btn-primary text-warning">Pending</a>
                                                      :
                                                      <>
                                                        {
                                                         data.connection_status == 'Not connected' ?
                                                          <button onClick={() => {
                                                            let people = this.state.people;
                                                            people[index].connection_status = 'pending';
                                                            
                                                            this.setState({
                                                              ...this.state,
                                                              people: people
                                                            })
    
                                                            this.createConnection(data.user_id);
                                                          }} className="btn btn-sm btn-primary text-warning">Connect</button> 
                                                          :
                                                          <></>
                                                        }
                                                      </>
                                                      
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }

                                      {
                                        this.state.showLoadMore &&
                                        <div className="col-12">
                                          <div class="d-flex flex-center mt-10">
                                            {
                                              this.state.isLoadingMore ?
                                              <button disabled class="btn btn-primary fw-bold px-6"> 
                                                <span class="">Loading more... 
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                              </button>
                                              :
                                              <button onClick={() => {this.getPeople("more")}} class="btn btn-primary fw-bold px-6"> 
                                                <span class="indicator-label">Show more</span> 
                                              </button>

                                            }
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  }
                                </>
                              }
                              
                            </div>
                            {/* side panel */}
                            {/* <div
                              className="d-lg-flex flex-column flex-lg-row-auto w-lg-350px"
                              data-kt-drawer="true"
                              data-kt-drawer-name="end-sidebar"
                              data-kt-drawer-activate="{default: true, lg: false}"
                              data-kt-drawer-overlay="true"
                              data-kt-drawer-width="{default:'200px', '250px': '300px'}"
                              data-kt-drawer-direction="end"
                              data-kt-drawer-toggle="#kt_social_end_sidebar_toggle"
                            >
                              {
                                this.state.isLoadingSidePanel ?
                                <div className="card mb-5 mb-xl-8">
                                  <div className="inner-pre-loader-container">
                                    <div className="inner-pre-loader py-20">
                                      <div className="text-center">  
                                        <div className="spinner-border mt-4" role="status">    
                                          <span className="sr-only">Loading...</span>  
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="card card-flush mb-5 mb-xl-8">
                                  <div className="card-header py-5">
                                    <h3 className="card-title align-items-start flex-column">
                                      <span className="card-label fw-bold text-dark fs-5">
                                        Communities
                                      </span>
                                      <span className="text-muted mt-1 fw-semibold fs-7">
                                        List of communities
                                      </span>
                                    </h3>
                                    <div className="card-toolbar">
                                      
                                      <a href="/community" className="btn btn-sm btn-light">
                                        View All
                                      </a>
                                    </div>
                                  </div>
                                  <div className="card-body pt-5">
                                    {
                                      this.state.communities.length === 0 ?
                                      <div className="text-center text-gray-700 my-10">
                                        <div className="symbol symbol-40px">
                                          <i className="las la-users fs-3x text-gray-400"></i>
                                        </div>
                                        <span className="d-block mt-4">No communities found</span>
                                      </div>
                                      :
                                      <>
                                      </>
                                    }
                                    
                                  </div>
                                </div>
                              }
                            </div> */}
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          {/* alert modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="soon-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-gray-500">
                        <i className="las la-info fs-3qx text-gray-800"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">This feature is coming soon</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">This feature gives you access to reach out 
                      and have a one-on-one communication.</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          
		
		
      </Fragment>
    );
  }
}

export default People;