import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import classNames from "classnames";

const { Option } = Select;
const { TextArea } = Input;

class Onboarding extends React.Component {

  constructor(props){
		AuthService.checkOnboardingProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
			user_type: 'entrepreneur',
			current_step: 1,
      business_type: '',
      other_gender: '',
      other_interest: '',
      other_funding_type: '',
      other_business_other_funding_type: '',
      entrepreneur_info: {
        user_type: 'entrepreneur',
        user_sub_type: 'idea_stage',
        full_name: AuthService.getAuth().user.full_name,
        email: AuthService.getAuth().user.email,
        year_of_birth: '',
        gender: '',
        business_years_in_operation: '',
        is_business_registered: '',
        business_type: '',
        business_industry: '',
        number_of_employees: '',
        fulltime_employees: '',
        part_time_employees: '',
        interns: '',
        freelancers: '',
        been_part_of_accelerator: '',
        accelerator_name: '',
        received_funding: '',
        funding_type: '',
        why_no_funding: '',
        received_funding_for_other_business: '',
        other_business_funding_type: '',
        investors_network: '',
        females_advisors_network: '',
        annual_revenue: ''
      },
      mentor_info: {
        user_type: 'mentor',
        full_name: AuthService.getAuth().user.full_name,
        email: AuthService.getAuth().user.email,
        company: '',
        role: '',
        value_statement: ''
      },
      investor_info: {
        user_type: 'investor',
        full_name: AuthService.getAuth().user.full_name,
        email: AuthService.getAuth().user.email,
        company: '',
        role: '',
        investor_type: ''
      },
      job_seeker_info: {
        user_type: 'job_seeker',
        full_name: AuthService.getAuth().user.full_name,
        email: AuthService.getAuth().user.email,
        education: '',
        interest: '',
        value_statement: ''
      },
      error_entrepreneur_info: {
        year_of_birth: '',
        gender: '',
        business_years_in_operation: '',
        is_business_registered: '',
        business_type: '',
        business_industry: '',
        number_of_employees: '',
        employees_breakdown: '',
        been_part_of_accelerator: '',
        accelerator_name: '',
        received_funding: '',
        funding_type: '',
        why_no_funding: '',
        received_funding_for_other_business: '',
        other_business_funding_type: '',
        investors_network: '',
        females_advisors_network: '',
        annual_revenue: ''
      },
      error_mentor_info: {
        company: '',
        role: '',
        value_statement: ''
      },
      error_investor_info: {
        company: '',
        role: '',
        investor_type: ''
      },
      error_job_seeker_info: {
        education: '',
        interest: '',
        value_statement: ''
      },
      errorMessage: ''
    }
	}

  validateEntrepreneurInfo () {
    let current_step = this.state.current_step;
    let entrepreneur_info = this.state.entrepreneur_info;
    let errorMessage = '';
    let error_entrepreneur_info = {
      year_of_birth: '',
      gender: '',
      business_years_in_operation: '',
      is_business_registered: '',
      business_type: '',
      business_industry: '',
      number_of_employees: '',
      employees_breakdown: '',
      been_part_of_accelerator: '',
      accelerator_name: '',
      received_funding: '',
      funding_type: '',
      why_no_funding: '',
      received_funding_for_other_business: '',
      other_business_funding_type: '',
      investors_network: '',
      females_advisors_network: '',
      annual_revenue: ''
    };
    

    let error_count = 0;
    if(current_step === 2){
      // validate profile info
      let regex = /(?:(?:19|20)[0-9]{2})/;
      if(!regex.test(entrepreneur_info.year_of_birth)){
        error_entrepreneur_info.year_of_birth = 'Year of birth is invalid';
        error_count++;
      }

      if(entrepreneur_info.gender.length === 0){
        error_entrepreneur_info.gender = 'Select your gender';
        error_count++;
      }

      if(error_count > 0){
        this.setState({
          ...this.state,
          error_entrepreneur_info: error_entrepreneur_info,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
        })

        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }

    }

    if(current_step === 3){
      // validate business info
      if(entrepreneur_info.business_years_in_operation.length === 0){
        error_entrepreneur_info.business_years_in_operation = 'Input required';
        error_count++;
      }

      if(entrepreneur_info.is_business_registered.length === 0){
        error_entrepreneur_info.is_business_registered = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.business_type.length === 0){
        error_entrepreneur_info.business_type = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.business_industry.length === 0){
        error_entrepreneur_info.business_industry = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.number_of_employees.length === 0){
        error_entrepreneur_info.number_of_employees = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.number_of_employees !== 'none' && 
        entrepreneur_info.fulltime_employees.length === 0 &&
        entrepreneur_info.part_time_employees.length === 0 &&
        entrepreneur_info.interns.length === 0 &&
        entrepreneur_info.freelancers.length === 0){

        error_entrepreneur_info.employees_breakdown = 'Enter the breakdown of your employees';
        error_count++;
      }

      if(entrepreneur_info.been_part_of_accelerator.length === 0){
        error_entrepreneur_info.been_part_of_accelerator = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.been_part_of_accelerator === 'yes' && entrepreneur_info.accelerator_name.length === 0){
        error_entrepreneur_info.accelerator_name = 'Input required';
        error_count++;
      }

      if(entrepreneur_info.received_funding.length === 0){
        error_entrepreneur_info.received_funding = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.received_funding === 'yes' && entrepreneur_info.funding_type.length === 0){
        error_entrepreneur_info.funding_type = 'Input required';
        error_count++;
      }

      if(entrepreneur_info.received_funding === 'no' && entrepreneur_info.why_no_funding.length === 0){
        error_entrepreneur_info.why_no_funding = 'Input required';
        error_count++;
      }

      if(entrepreneur_info.received_funding_for_other_business.length === 0){
        error_entrepreneur_info.received_funding_for_other_business = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.received_funding_for_other_business === 'yes' && entrepreneur_info.other_business_funding_type.length === 0){
        error_entrepreneur_info.other_business_funding_type = 'Input required';
        error_count++;
      }

      if(entrepreneur_info.investors_network.length === 0){
        error_entrepreneur_info.investors_network = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.females_advisors_network.length === 0){
        error_entrepreneur_info.females_advisors_network = 'Select an option';
        error_count++;
      }

      if(entrepreneur_info.annual_revenue.length === 0){
        error_entrepreneur_info.annual_revenue = 'Select an option';
        error_count++;
      }

      if(error_count > 0){
        this.setState({
          ...this.state,
          error_entrepreneur_info: error_entrepreneur_info,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
        })

        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }

      setTimeout(() => {
        this.submitEntrepreneurOnboarding();
      }, 300);

      return;
      
    }

    this.setState({
      ...this.state,
      current_step: this.state.current_step + 1,
      error_entrepreneur_info: error_entrepreneur_info,
      errorMessage: errorMessage
    })
  }

  validateMentorInfo () {
    let current_step = this.state.current_step;
    let mentor_info = this.state.mentor_info;
    let errorMessage = '';
    let error_mentor_info = {
      company: '',
      role: '',
      value_statement: ''
    }

    let error_count = 0;
    if(current_step === 2){
      // validate profile info

      if(mentor_info.company.length === 0){
        error_mentor_info.company = 'Input required';
        error_count++;
      }

      if(mentor_info.role.length === 0){
        error_mentor_info.role = 'Input required';
        error_count++;
      }

      if(mentor_info.value_statement.length === 0){
        error_mentor_info.value_statement = 'Input required';
        error_count++;
      }

      if(error_count > 0){
        this.setState({
          ...this.state,
          error_mentor_info: error_mentor_info,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
        })

        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }

      setTimeout(() => {
        this.submitMentorOnboarding();
      }, 300);

      return;
    }

    this.setState({
      ...this.state,
      current_step: this.state.current_step + 1,
      error_mentor_info: error_mentor_info,
      errorMessage: errorMessage
    })
  }

  validateInvestorInfo () {
    let current_step = this.state.current_step;
    let investor_info = this.state.investor_info;
    let errorMessage = '';
    let error_investor_info = {
      company: '',
      role: '',
      investor_type: ''
    }

    let error_count = 0;
    if(current_step === 2){
      // validate profile info

      if(investor_info.company.length === 0){
        error_investor_info.company = 'Input required';
        error_count++;
      }

      if(investor_info.role.length === 0){
        error_investor_info.role = 'Input required';
        error_count++;
      }

      if(investor_info.investor_type.length === 0){
        error_investor_info.investor_type = 'Input required';
        error_count++;
      }

      if(error_count > 0){
        this.setState({
          ...this.state,
          error_investor_info: error_investor_info,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
        })

        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }

      setTimeout(() => {
        this.submitInvestorOnboarding();
      }, 300);

      return;

    }

    this.setState({
      ...this.state,
      current_step: this.state.current_step + 1,
      error_investor_info: error_investor_info,
      errorMessage: errorMessage
    })
  }

  validateJobSeekerInfo () {
    let current_step = this.state.current_step;
    let job_seeker_info = this.state.job_seeker_info;
    let errorMessage = '';
    let error_job_seeker_info = {
      education: '',
      interest: '',
      value_statement: ''
    }

    let error_count = 0;
    if(current_step === 2){
      // validate profile info

      if(job_seeker_info.education.length === 0){
        error_job_seeker_info.education = 'Input required';
        error_count++;
      }

      if(job_seeker_info.interest.length === 0){
        error_job_seeker_info.interest = 'Input required';
        error_count++;
      }

      if(job_seeker_info.value_statement.length === 0){
        error_job_seeker_info.value_statement = 'Input required';
        error_count++;
      }

      if(error_count > 0){
        this.setState({
          ...this.state,
          error_job_seeker_info: error_job_seeker_info,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
        })

        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }

      setTimeout(() => {
        this.submitJobSeekerOnboarding();
      }, 300);

      return;
    }

    this.setState({
      ...this.state,
      current_step: this.state.current_step + 1,
      error_job_seeker_info: error_job_seeker_info,
      errorMessage: errorMessage
    })
  }

  updateEntrepreneurInfo(event, name) {
    var entrepreneur_info = this.state.entrepreneur_info;
    var business_type = this.state.business_type;
    var other_funding_type = this.state.other_funding_type;
    var other_business_other_funding_type = this.state.other_business_other_funding_type;
    let value;

    if(name === 'business_type' || name === 'funding_type' || name === 'other_business_funding_type' || name === 'investors_network' || name === 'females_advisors_network' || name === 'annual_revenue'){
      value = event;
      business_type = name === 'business_type' ? event : business_type;
      other_funding_type = name === 'funding_type' ? event : other_funding_type;
      other_business_other_funding_type = name === 'other_business_funding_type' ? event : other_business_other_funding_type;

    }else{
      if(name === 'business_type_other'){
        name = 'business_type';
      }

      if(name === 'other_funding_type'){
        name = 'funding_type';
      }

      if(name === 'other_business_other_funding_type'){
        name = 'other_business_funding_type';
      }
      
      value = event.target.value;
    }

    entrepreneur_info[name] = value;
    this.setState({
			...this.state,
      entrepreneur_info: entrepreneur_info,
      business_type: business_type,
      other_funding_type: other_funding_type,
      other_business_other_funding_type: other_business_other_funding_type
    });
	}

  updateMentorInfo(event, name) {
    let mentor_info = this.state.mentor_info;
    let value = event.target.value;

    mentor_info[name] = value;

    this.setState({
			...this.state,
      mentor_info: mentor_info,
    });
	}

  updateInvestorInfo(event, name) {
    let investor_info = this.state.investor_info;
    let value;
    
    if(name === 'investor_type'){
      value = event;
    }else{
      value = event.target.value;
    }

    investor_info[name] = value;

    this.setState({
			...this.state,
      investor_info: investor_info,
    });
	}

  updateJobSeekerInfo(event, name) {
    let job_seeker_info = this.state.job_seeker_info;
    let other_interest = this.state.other_interest;
    let value;
    
    if(name === 'interest'){
      other_interest = event;
      value = event;

    }else{
      if(name === 'other_interest'){
        name = 'interest';
      }

      value = event.target.value;
    }

    job_seeker_info[name] = value;

    this.setState({
			...this.state,
      other_interest: other_interest,
      job_seeker_info: job_seeker_info,
    });
	}

  submitEntrepreneurOnboarding () {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      error_entrepreneur_info: {
        year_of_birth: '',
        gender: '',
        business_years_in_operation: '',
        is_business_registered: '',
        business_type: '',
        business_industry: '',
        number_of_employees: '',
        employees_breakdown: '',
        been_part_of_accelerator: '',
        accelerator_name: '',
        received_funding: '',
        funding_type: '',
        why_no_funding: '',
        received_funding_for_other_business: '',
        other_business_funding_type: '',
        investors_network: '',
        females_advisors_network: '',
        annual_revenue: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/onboarding/entrepreneur", {
      ...this.state.entrepreneur_info
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.onboardingCompleted = true;
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.user_type = this.state.entrepreneur_info.user_type;
      userObj.user_sub_type = this.state.entrepreneur_info.user_sub_type;
      reactLocalStorage.setObject('userObj', userObj);
      this.setState({
        ...this.state,
        current_step: this.state.current_step + 1
      })

      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  submitMentorOnboarding () {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      error_mentor_info: {
        company: '',
        role: '',
        value_statement: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/onboarding/mentor", {
      ...this.state.mentor_info
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.onboardingCompleted = true;
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.user_type = this.state.mentor_info.user_type;
      reactLocalStorage.setObject('userObj', userObj);
      this.setState({
        ...this.state,
        current_step: this.state.current_step + 1
      })

      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  submitInvestorOnboarding () {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      error_investor_info: {
        company: '',
        role: '',
        investor_type: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/onboarding/investor", {
      ...this.state.investor_info
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.onboardingCompleted = true;
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.user_type = this.state.investor_info.user_type;
      reactLocalStorage.setObject('userObj', userObj);
      this.setState({
        ...this.state,
        current_step: this.state.current_step + 1
      })

      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  submitJobSeekerOnboarding () {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      error_job_seeker_info: {
        education: '',
        interest: '',
        value_statement: ''
      }
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/onboarding/job-seeker", {
      ...this.state.job_seeker_info
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.onboardingCompleted = true;
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.user_type = this.state.job_seeker_info.user_type;
      reactLocalStorage.setObject('userObj', userObj);
      this.setState({
        ...this.state,
        current_step: this.state.current_step + 1
      })

      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    
    // get user onboarding information
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Onboarding - AWA</title>
          </MetaTags>
          <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
            <div
              className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
              id="kt_create_account_stepper"
            >
              <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
                <div
                  className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center bg-awa"
                >
                  <div className="d-flex flex-center py-10 py-lg-20 mt-lg-20">
                    <a href="#">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/logo-white.png"
                        className="h-70px"
                      />
                    </a>
                  </div>
                  <div className="d-flex flex-row-fluid justify-content-center p-10">
                    <div className="stepper-nav">
                      <div className={classNames("stepper-item ", {"current": this.state.current_step === 1}, {"completed": this.state.current_step > 1})} data-kt-stepper-element="nav">
                        <div className="stepper-wrapper">
                          <div className={classNames("stepper-icon rounded-3 ", {"bg-awa-yellow": this.state.current_step === 1})}>
                            <i className="stepper-check fas fa-check" />
                            <span className="stepper-number">1</span>
                          </div>
                          <div className="stepper-label">
                            <h3 className="stepper-title fs-2">Welcome</h3>
                            <div className="stepper-desc fw-normal">
                              Let's get you started
                            </div>
                          </div>
                        </div>
                        <div className="stepper-line h-40px" />
                      </div>
                      {
                        this.state.user_type === 'entrepreneur' &&
                        <>
                          <div className={classNames("stepper-item ", {"current": this.state.current_step === 2}, {"completed": this.state.current_step > 2})} data-kt-stepper-element="nav">
                            <div className="stepper-wrapper">
                              <div className={classNames("stepper-icon rounded-3 ", {"bg-awa-yellow": this.state.current_step === 2})}>
                                <i className="stepper-check fas fa-check" />
                                <span className="stepper-number">2</span>
                              </div>
                              <div className="stepper-label">
                                <h3 className="stepper-title fs-2">Personal Info</h3>
                                <div className="stepper-desc fw-normal">
                                  Setup your personal profile
                                </div>
                              </div>
                            </div>
                            <div className="stepper-line h-40px" />
                          </div>
                          <div className={classNames("stepper-item ", {"current": this.state.current_step === 3}, {"completed": this.state.current_step > 3})} data-kt-stepper-element="nav">
                            <div className="stepper-wrapper">
                              <div className={classNames("stepper-icon rounded-3 ", {"bg-awa-yellow": this.state.current_step === 3})}>
                                <i className="stepper-check fas fa-check" />
                                <span className="stepper-number">3</span>
                              </div>
                              <div className="stepper-label">
                                <h3 className="stepper-title fs-2">Business Info</h3>
                                <div className="stepper-desc fw-normal">
                                  Setup your business profile
                                </div>
                              </div>
                            </div>
                            <div className="stepper-line h-40px" />
                          </div>
                          <div className={classNames("stepper-item ", {"completed": this.state.current_step === 4})} data-kt-stepper-element="nav">
                            <div className="stepper-wrapper">
                              <div className="stepper-icon">
                                <i className="stepper-check fas fa-check" />
                                <span className="stepper-number">4</span>
                              </div>
                              <div className="stepper-label">
                                <h3 className="stepper-title">Completed</h3>
                                <div className="stepper-desc fw-normal">
                                  Your account is ready
                                </div>
                              </div>
                            </div>
                          </div>
                        </> 
                      }

                      {
                        this.state.user_type != 'entrepreneur' &&
                        <>
                          <div className={classNames("stepper-item ", {"current": this.state.current_step === 2}, {"completed": this.state.current_step > 2})} data-kt-stepper-element="nav">
                            <div className="stepper-wrapper">
                              <div className={classNames("stepper-icon rounded-3 ", {"bg-awa-yellow": this.state.current_step === 2})}>
                                <i className="stepper-check fas fa-check" />
                                <span className="stepper-number">2</span>
                              </div>
                              <div className="stepper-label">
                                <h3 className="stepper-title fs-2">Personal Info</h3>
                                <div className="stepper-desc fw-normal">
                                  Setup your personal profile
                                </div>
                              </div>
                            </div>
                            <div className="stepper-line h-40px" />
                          </div>
                          <div className={classNames("stepper-item ", {"completed": this.state.current_step === 3})} data-kt-stepper-element="nav">
                            <div className="stepper-wrapper">
                              <div className="stepper-icon">
                                <i className="stepper-check fas fa-check" />
                                <span className="stepper-number">3</span>
                              </div>
                              <div className="stepper-label">
                                <h3 className="stepper-title">Completed</h3>
                                <div className="stepper-desc fw-normal">
                                  Your account is ready
                                </div>
                              </div>
                            </div>
                          </div>
                        </> 
                      }
                    </div>
                  </div>
                  <div className="d-flex flex-center flex-wrap px-5 py-10">
                    <div className="d-flex fw-normal">
                      
                      <a
                        href="/logout"
                        className="text-warning px-5"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row-fluid py-10">
                <div className="d-flex flex-center flex-column flex-column-fluid">
                  <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
                    {
                      this.state.errorMessage.length > 0 &&
                      <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                        <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                              <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                              <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                            </g>
                          </svg>
                        </span>
                        <div class="d-flex flex-column">
                          <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                      </div>
                    }

                    <div className={classNames({"current": this.state.current_step === 1})} data-kt-stepper-element="content">
                      <div className="w-100">
                        <div className="pb-10 pb-lg-15">
                          <h2 className="fs-2x ">Welcome, <span className="text-primary">{this.state.userInfo.full_name}</span> 👋</h2>
                          <div className="fw-semibold fs-6">
                            Let's get you setup.
                          </div>
                        </div>
                        <div className="pb-10 pb-lg-15">
                          <h2 className="fw-bold d-flex align-items-center text-dark">
                            Choose What Best Describe You
                          </h2>
                          <div className="text-muted fw-semibold fs-6">
                            Select one of these options that best describes you.
                          </div>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="col-lg-6">
                              <input
                                type="radio"
                                className="btn-check"
                                name="user_type"
                                id="entrepreneur_user"
                                value="entrepreneur"
                                checked={this.state.user_type == 'entrepreneur'}
                                onChange={() => {
                                  this.setState({
                                    ...this.state,
                                    user_type: 'entrepreneur'
                                  })
                                }}
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                                htmlFor="entrepreneur_user">
                                <span className="svg-icon svg-icon-3x me-5">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor" />
                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor" /> 
                                  </svg>
                                </span>
                                <span className="d-block fw-semibold text-start">
                                  <span className="text-dark fw-bold d-block fs-4 mb-2">
                                    I'm an Entrepreneur
                                  </span>
                                  {/* <span className="text-muted fw-semibold fs-6">
                                    If you need more info, please check it out
                                  </span> */}
                                </span>
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="radio"
                                className="btn-check"
                                name="user_type"
                                id="investor_user"
                                value="investor"
                                checked={this.state.user_type == 'investor'}
                                onChange={() => {
                                  this.setState({
                                    ...this.state,
                                    user_type: 'investor'
                                  })
                                }}
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                                htmlFor="investor_user"
                              >
                                <span className="svg-icon svg-icon-3x me-5">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24" />
                                      <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="currentColor" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) " />
                                      <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="currentColor" /> </g>
                                  </svg>
                                </span>
                                <span className="d-block fw-semibold text-start">
                                  <span className="text-dark fw-bold d-block fs-4 mb-2">
                                    I want to fund a startup
                                  </span>
                                  {/* <span className="text-muted fw-semibold fs-6">
                                    Create corporate account to mane users
                                  </span> */}
                                </span>
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="radio"
                                className="btn-check"
                                name="user_type"
                                id="mentor_user"
                                value="mentor"
                                checked={this.state.user_type == 'mentor'}
                                onChange={() => {
                                  this.setState({
                                    ...this.state,
                                    user_type: 'mentor'
                                  })
                                }}
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                                htmlFor="mentor_user">
                                <span className="svg-icon svg-icon-3x me-5">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24" />
                                      <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="currentColor" />
                                      <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="currentColor" opacity="0.3" /> </g>
                                  </svg>
                                </span>
                                <span className="d-block fw-semibold text-start">
                                  <span className="text-dark fw-bold d-block fs-4 mb-2">
                                    I want to work as a Mentor
                                  </span>
                                  {/* <span className="text-muted fw-semibold fs-6">
                                    If you need more info, please check it out
                                  </span> */}
                                </span>
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="radio"
                                className="btn-check"
                                name="user_type"
                                id="job_seeker_user"
                                value="job_seeker"
                                checked={this.state.user_type == 'job_seeker'}
                                onChange={() => {
                                  this.setState({
                                    ...this.state,
                                    user_type: 'job_seeker'
                                  })
                                }}
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                                htmlFor="job_seeker_user"
                              >
                                <span className="svg-icon svg-icon-3x me-5">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor" />
                                    <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor" /> 
                                  </svg>
                                </span>
                                <span className="d-block fw-semibold text-start">
                                  <span className="text-dark fw-bold d-block fs-4 mb-2">
                                    I want to work for a startup
                                  </span>
                                  {/* <span className="text-muted fw-semibold fs-6">
                                    Create corporate account to mane users
                                  </span> */}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {
                          this.state.user_type === 'entrepreneur' &&
                          <div className="fv-row mb-10">
                            <label className="form-label required">
                              Entrepreneur Stage
                            </label>
                            <div className="mb-0 mt-3">
                              <label className="d-flex flex-stack mb-10 cursor-pointer">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"/>
                                              <circle fill="currentColor" opacity="0.3" cx="12" cy="9" r="8"/>
                                              <path d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z" fill="currentColor" fill-rule="nonzero" opacity="0.3"/>
                                              <path d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z" fill="currentColor" opacity="0.3"/>
                                              <path d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z" fill="currentColor"/>
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-5">
                                      Idea Stage
                                    </span>
                                    <span className="fs-6 fw-semibold text-muted">
                                      I have a really great idea that I’ve been experimenting on
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="stage"
                                    id="idea_stage"
                                    value="idea_stage"
                                    checked={this.state.entrepreneur_info.user_sub_type == 'idea_stage'}
                                    onChange={() => {
                                      let entrepreneur_info = this.state.entrepreneur_info;
                                      entrepreneur_info.user_sub_type = 'idea_stage';
                                      this.setState({
                                        ...this.state,
                                        entrepreneur_info: entrepreneur_info
                                      })
                                    }}
                                  />
                                </span>
                              </label>
                              <label className="d-flex flex-stack mb-10 cursor-pointer">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"/>
                                              <path d="M18.4246212,12.6464466 L21.2530483,9.81801948 C21.4483105,9.62275734 21.764893,9.62275734 21.9601551,9.81801948 L22.6672619,10.5251263 C22.862524,10.7203884 22.862524,11.0369709 22.6672619,11.232233 L19.8388348,14.0606602 C19.6435726,14.2559223 19.3269901,14.2559223 19.131728,14.0606602 L18.4246212,13.3535534 C18.2293591,13.1582912 18.2293591,12.8417088 18.4246212,12.6464466 Z M3.22182541,17.9497475 L13.1213203,8.05025253 C13.5118446,7.65972824 14.1450096,7.65972824 14.5355339,8.05025253 L15.9497475,9.46446609 C16.3402718,9.85499039 16.3402718,10.4881554 15.9497475,10.8786797 L6.05025253,20.7781746 C5.65972824,21.1686989 5.02656326,21.1686989 4.63603897,20.7781746 L3.22182541,19.363961 C2.83130112,18.9734367 2.83130112,18.3402718 3.22182541,17.9497475 Z" fill="currentColor" opacity="0.3"/>
                                              <path d="M12.3890873,1.28248558 L12.3890873,1.28248558 C15.150511,1.28248558 17.3890873,3.52106183 17.3890873,6.28248558 L17.3890873,10.7824856 C17.3890873,11.058628 17.1652297,11.2824856 16.8890873,11.2824856 L12.8890873,11.2824856 C12.6129449,11.2824856 12.3890873,11.058628 12.3890873,10.7824856 L12.3890873,1.28248558 Z" fill="currentColor" transform="translate(14.889087, 6.282486) rotate(-45.000000) translate(-14.889087, -6.282486) "/>
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-5">
                                      Early Stage
                                    </span>
                                    <span className="fs-6 fw-semibold text-muted">
                                      I have been in business for  2 - 3 years (Early-Stage Entrepreneur)
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="stage"
                                    id="early_stage"
                                    value="early_stage"
                                    checked={this.state.entrepreneur_info.user_sub_type == 'early_stage'}
                                    onChange={() => {
                                      let entrepreneur_info = this.state.entrepreneur_info;
                                      entrepreneur_info.user_sub_type = 'early_stage';
                                      this.setState({
                                        ...this.state,
                                        entrepreneur_info: entrepreneur_info
                                      })
                                    }}
                                  />
                                </span>
                              </label>
                              <label className="d-flex flex-stack mb-10 cursor-pointer">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"/>
                                              <path d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z" fill="currentColor"/>
                                              <path d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z" fill="currentColor" opacity="0.3"/>
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-5">
                                      Mid Stage
                                    </span>
                                    <span className="fs-6 fw-semibold text-muted">
                                      I have been in business for  4 - 6 years (Mid-Stage Entrepreneur)
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="stage"
                                    id="mid_stage"
                                    value="mid_stage"
                                    checked={this.state.entrepreneur_info.user_sub_type == 'mid_stage'}
                                    onChange={() => {
                                      let entrepreneur_info = this.state.entrepreneur_info;
                                      entrepreneur_info.user_sub_type = 'mid_stage';
                                      this.setState({
                                        ...this.state,
                                        entrepreneur_info: entrepreneur_info
                                      })
                                    }}
                                  />
                                </span>
                              </label>
                              <label className="d-flex flex-stack mb-10 cursor-pointer">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"/>
                                              <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="currentColor"/>
                                              <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1"/>
                                              <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="currentColor" opacity="0.3"/>
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-5">
                                      Matured Stage
                                    </span>
                                    <span className="fs-6 fw-semibold text-muted">
                                      I have been in business for over 6 years (Seasoned Entrepreneur)
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="stage"
                                    id="matured_stage"
                                    value="matured_stage"
                                    checked={this.state.entrepreneur_info.user_sub_type == 'matured_stage'}
                                    onChange={() => {
                                      let entrepreneur_info = this.state.entrepreneur_info;
                                      entrepreneur_info.user_sub_type = 'matured_stage';
                                      this.setState({
                                        ...this.state,
                                        entrepreneur_info: entrepreneur_info
                                      })
                                    }}
                                  />
                                </span>
                              </label>
                            </div>

                            {
                              this.state.error_entrepreneur_info.business_type.length > 0 && 
                              <div className="invalid-feedback">{this.state.error_entrepreneur_info.business_type}</div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                    {
                      this.state.user_type === 'entrepreneur' &&
                      <form className="my-auto pb-5" noValidate="novalidate" 
                        id="kt_create_account_form"  onSubmit={this.submitEntrepreneurOnboarding} method="POST">                      
                        <div className={classNames({"current": this.state.current_step === 2})} data-kt-stepper-element="content">
                          <div className="w-100">
                            <div className="pb-10 pb-lg-15">
                              <h2 className="fw-bold text-dark">Personal Info</h2>
                              <div className="text-muted fw-semibold fs-6">
                                Tell us about yourself.
                              </div>
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Full Name</label>   
                              <Input size="large" required disabled type="text" id="full-name" className="form-control form-control-lg" placeholder="Enter your full name" name="name" value={this.state.entrepreneur_info.full_name} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Email</label>   
                              <Input size="large" required disabled type="text" id="email" className="form-control form-control-lg" placeholder="Enter your email" name="email" value={this.state.entrepreneur_info.email} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Year of Birth</label>   
                              <Input size="large" maxLength={4} required type="text" id="year" className="form-control form-control-lg" placeholder="Enter your year of birth" name="year_of_birth" value={this.state.entrepreneur_info.year_of_birth} onChange={(e) => {this.updateEntrepreneurInfo(e,"year_of_birth");}} />
                              {
                                this.state.error_entrepreneur_info.year_of_birth.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.year_of_birth}</div>
                              }
                            </div>
                            <div className="mb-10 fv-row">
                              <label className="required d-flex align-items-center form-label mb-3">
                                What gender do you identify as?
                              </label>
                              <div className="row mb-2 gap-5" data-kt-buttons="true">
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.gender == 'female'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="gender"
                                      value="female"
                                      checked={this.state.entrepreneur_info.gender == 'female'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.gender = 'female';
                                        this.setState({
                                          ...this.state,
                                          other_gender: '',
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Female</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.gender == 'male'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="gender"
                                      value="female"
                                      checked={this.state.entrepreneur_info.gender == 'male'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.gender = 'male';
                                        this.setState({
                                          ...this.state,
                                          other_gender: '',
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Male</span>
                                  </label>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.gender == 'prefer not to say'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="gender"
                                      value="prefer not to say"
                                      checked={this.state.entrepreneur_info.gender == 'prefer not to say'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.gender = 'prefer not to say';
                                        this.setState({
                                          ...this.state,
                                          other_gender: '',
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Prefer not to say</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.other_gender == 'other'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="gender"
                                      value="other"
                                      checked={this.state.entrepreneur_info.gender == 'other'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.gender = 'other';
                                        this.setState({
                                          ...this.state,
                                          other_gender: 'other',
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">other</span>
                                  </label>
                                </div>
                                {
                                  this.state.other_gender === 'other' &&
                                  <div className="col-lg-6">
                                    <Input size="large" required type="text" id="other_gender" className="form-control form-control-lg" name="gender" value={this.state.entrepreneur_info.gender} onChange={(e) => {this.updateEntrepreneurInfo(e,"gender");}} />
                                  </div>
                                }
                              </div>
                              {
                                this.state.error_entrepreneur_info.gender.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.gender}</div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className={classNames({"current": this.state.current_step === 3})} data-kt-stepper-element="content">
                          <div className="w-100">
                            <div className="pb-10 pb-lg-12">
                              <h2 className="fw-bold text-dark">Business Info</h2>
                              <div className="text-muted fw-semibold fs-6">
                                Tell us about your business.
                              </div>
                            </div>
                            <div className="fv-row mb-10">
                              <label className="required form-label mb-3">How many years has your business been in operation?</label>   
                              <Input size="large" maxLength={2} required type="text" id="business_years_in_operation" className="form-control form-control-lg" name="business_years_in_operation" value={this.state.entrepreneur_info.business_years_in_operation} onChange={(e) => {this.updateEntrepreneurInfo(e,"business_years_in_operation");}} />
                              {
                                this.state.error_entrepreneur_info.business_years_in_operation.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.business_years_in_operation}</div>
                              }
                            </div>
                            <div className="mb-10 fv-row">
                              <label className="required d-flex align-items-center form-label mb-3">
                                Is your business registered?
                              </label>
                              <div className="row mb-2" data-kt-buttons="true">
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.is_business_registered == 'yes'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="is_business_registered"
                                      value="yes"
                                      checked={this.state.entrepreneur_info.is_business_registered == 'yes'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.is_business_registered = 'yes';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Yes</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.is_business_registered == 'no'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="is_business_registered"
                                      value="no"
                                      checked={this.state.entrepreneur_info.is_business_registered == 'no'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.is_business_registered = 'no';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">No</span>
                                  </label>
                                </div>
                              </div>
                              {
                                this.state.error_entrepreneur_info.is_business_registered.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.is_business_registered}</div>
                              }
                            </div>
                            <div className="fv-row mb-10">
                              <label className="form-label required">
                                Business Type
                              </label>
                              <Select
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={(value) => {this.updateEntrepreneurInfo(value, 'business_type')}}
                                options={[
                                  {
                                    value: 'Non - Tech Products business ( Physical products)',
                                    label: 'Non - Tech Products business ( Physical products)',
                                  },
                                  {
                                    value: 'Non - tech Services business',
                                    label: 'Non - tech Services business',
                                  },
                                  {
                                    value: 'Tech (Products)',
                                    label: 'Tech (Products)',
                                  },
                                  {
                                    value: 'Tech (Services)',
                                    label: 'Tech (Services)',
                                  },
                                  {
                                    value: 'Other',
                                    label: 'Other',
                                  },
                                ]}
                              />
                              {
                                this.state.error_entrepreneur_info.business_type.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.business_type}</div>
                              }
                            </div>
                            {
                              this.state.business_type === 'Other' &&
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">Briefly Explain your Business Type</label>   
                                <Input size="large" required type="text" id="business_type_other" className="form-control form-control-lg" name="business_type_other" value={this.state.entrepreneur_info.business_type} onChange={(e) => {this.updateEntrepreneurInfo(e,"business_type_other");}} />
                                {
                                  this.state.error_entrepreneur_info.business_type.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_entrepreneur_info.business_type}</div>
                                }
                              </div>
                            }
                            <div className="fv-row mb-10">
                              <label className="required form-label mb-3">
                                What Industry is your business in?
                              </label>
                              <Input size="large" required type="text" id="business_industry" className="form-control form-control-lg" name="business_industry" value={this.state.entrepreneur_info.business_industry} onChange={(e) => {this.updateEntrepreneurInfo(e,"business_industry");}} />
                              {
                                this.state.error_entrepreneur_info.business_industry.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.business_industry}</div>
                              }
                            </div>

                            <div className="mb-10 fv-row">
                              <label className="required d-flex align-items-center form-label mb-3">
                                Number of Employees
                              </label>
                              <div className="row mb-2 g-4" data-kt-buttons="true">
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.number_of_employees == 'none'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="number_of_employees"
                                      value="none"
                                      checked={this.state.entrepreneur_info.number_of_employees == 'none'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.number_of_employees = 'none';
                                        entrepreneur_info.fulltime_employees = '';
                                        entrepreneur_info.part_time_employees = '';
                                        entrepreneur_info.interns = '';
                                        entrepreneur_info.freelancers = '';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">None</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.number_of_employees == '1-3'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="number_of_employees"
                                      value="1-3"
                                      checked={this.state.entrepreneur_info.number_of_employees == '1-3'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.number_of_employees = '1-3';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">1 - 3</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.number_of_employees == '3-5'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="number_of_employees"
                                      value="3-5"
                                      checked={this.state.entrepreneur_info.number_of_employees == '3-5'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.number_of_employees = '3-5';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">3 - 5</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.number_of_employees == '5-10'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="number_of_employees"
                                      value="5-10"
                                      checked={this.state.entrepreneur_info.number_of_employees == '5-10'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.number_of_employees = '5-10';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">5 - 10</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.number_of_employees == '10-50'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="number_of_employees"
                                      value="10-50"
                                      checked={this.state.entrepreneur_info.number_of_employees == '10-50'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.number_of_employees = '10-50';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">10 - 50</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.number_of_employees == '50+'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="number_of_employees"
                                      value="50+"
                                      checked={this.state.entrepreneur_info.number_of_employees == '50+'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.number_of_employees = '50+';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">50+</span>
                                  </label>
                                </div>
                              </div>
                              {
                                this.state.error_entrepreneur_info.number_of_employees.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.number_of_employees}</div>
                              }
                            </div>

                            {
                              this.state.entrepreneur_info.number_of_employees !== 'none' && this.state.entrepreneur_info.number_of_employees !== '' &&
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">What is the breakdown of your Employees time commitment </label>   
                                <div className="row mb-2 g-4">
                                  <div className="col-md-6">
                                    <label className="form-label mb-3">Fulltime Employees</label>   
                                    <Input size="large" required type="text" id="fulltime_employees" className="form-control form-control-lg" name="fulltime_employees" value={this.state.entrepreneur_info.fulltime_employees} onChange={(e) => {this.updateEntrepreneurInfo(e,"fulltime_employees");}} />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-label mb-3">Part-time Employees</label>   
                                    <Input size="large" required type="text" id="part_time_employees" className="form-control form-control-lg" name="part_time_employees" value={this.state.entrepreneur_info.part_time_employees} onChange={(e) => {this.updateEntrepreneurInfo(e,"part_time_employees");}} />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-label mb-3">Interns</label>   
                                    <Input size="large" required type="text" id="interns" className="form-control form-control-lg" name="interns" value={this.state.entrepreneur_info.interns} onChange={(e) => {this.updateEntrepreneurInfo(e,"interns");}} />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-label mb-3">Freelancers</label>   
                                    <Input size="large" required type="text" id="freelancers" className="form-control form-control-lg" name="freelancers" value={this.state.entrepreneur_info.freelancers} onChange={(e) => {this.updateEntrepreneurInfo(e,"freelancers");}} />
                                  </div>
                                </div>
                                {
                                  this.state.error_entrepreneur_info.employees_breakdown.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_entrepreneur_info.employees_breakdown}</div>
                                }
                              </div>
                            }
                            <div className="mb-10 fv-row">
                              <label className="required d-flex align-items-center form-label mb-3">
                                Have you been part of any accelerator program or business incubation program before?
                              </label>
                              <div className="row mb-2" data-kt-buttons="true">
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.been_part_of_accelerator == 'yes'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="been_part_of_accelerator"
                                      value="yes"
                                      checked={this.state.entrepreneur_info.been_part_of_accelerator == 'yes'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.been_part_of_accelerator = 'yes';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Yes</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.been_part_of_accelerator == 'no'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="been_part_of_accelerator"
                                      value="no"
                                      checked={this.state.entrepreneur_info.been_part_of_accelerator == 'no'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.been_part_of_accelerator = 'no';
                                        entrepreneur_info.accelerator_name = '';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">No</span>
                                  </label>
                                </div>
                              </div>
                              {
                                this.state.error_entrepreneur_info.been_part_of_accelerator.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.been_part_of_accelerator}</div>
                              }
                            </div>
                            {
                              this.state.entrepreneur_info.been_part_of_accelerator === 'yes' &&
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">Name of the Accelerator / Incubation Program</label>   
                                <Input size="large" required type="text" id="accelerator_name" className="form-control form-control-lg" name="accelerator_name" value={this.state.entrepreneur_info.accelerator_name} onChange={(e) => {this.updateEntrepreneurInfo(e,"accelerator_name");}} />
                                {
                                  this.state.error_entrepreneur_info.accelerator_name.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_entrepreneur_info.accelerator_name}</div>
                                }
                              </div>
                            }

                            <div className="mb-10 fv-row">
                              <label className="required d-flex align-items-center form-label mb-3">
                                Has your current business received external funding?
                              </label>
                              <div className="row mb-2" data-kt-buttons="true">
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.received_funding == 'yes'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="received_funding"
                                      value="yes"
                                      checked={this.state.entrepreneur_info.received_funding == 'yes'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.received_funding = 'yes';
                                        entrepreneur_info.why_no_funding = '';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Yes</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.received_funding == 'no'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="received_funding"
                                      value="no"
                                      checked={this.state.entrepreneur_info.received_funding == 'no'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.received_funding = 'no';
                                        entrepreneur_info.funding_type = '';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">No</span>
                                  </label>
                                </div>
                              </div>
                              {
                                this.state.error_entrepreneur_info.received_funding.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.received_funding}</div>
                              }
                            </div>

                            {
                              this.state.entrepreneur_info.received_funding === 'yes' &&
                              <>
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">Type of Funding</label>   
                                <Select
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={(value) => {this.updateEntrepreneurInfo(value, 'funding_type')}}
                                  options={[
                                    {
                                      value: 'Angel Investors',
                                      label: 'Angel Investors',
                                    },
                                    {
                                      value: 'Friends and Family',
                                      label: 'Friends and Family',
                                    },
                                    {
                                      value: 'Bank loans or loan from other financial service providers',
                                      label: 'Bank loans or loan from other financial service providers',
                                    },
                                    {
                                      value: 'Venture Capital Fund',
                                      label: 'Venture Capital Fund',
                                    },
                                    {
                                      value: 'Grant Funding',
                                      label: 'Grant Funding',
                                    },
                                    {
                                      value: 'Prize Money',
                                      label: 'Prize Money',
                                    },
                                    {
                                      value: 'Other',
                                      label: 'Other',
                                    },
                                  ]}
                                />
                                {
                                  this.state.error_entrepreneur_info.funding_type.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_entrepreneur_info.funding_type}</div>
                                }
                              </div>
                              {
                                this.state.other_funding_type === 'Other' &&
                                <div className="fv-row mb-10">
                                  <label className="required form-label mb-3">Briefly Explain the Funding Type</label>   
                                  <Input size="large" required type="text" id="other_funding_type" className="form-control form-control-lg" name="other_funding_type" value={this.state.entrepreneur_info.funding_type} onChange={(e) => {this.updateEntrepreneurInfo(e,"other_funding_type");}} />
                                  {
                                    this.state.error_entrepreneur_info.funding_type.length > 0 && 
                                    <div className="invalid-feedback">{this.state.error_entrepreneur_info.funding_type}</div>
                                  }
                                </div>
                              }
                              </>
                            }

                            {
                              this.state.entrepreneur_info.received_funding === 'no' &&
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">Briefly Explain Why?</label>   
                                <TextArea required
                                  value={this.state.entrepreneur_info.why_no_funding}
                                  onChange={(e) => {this.updateEntrepreneurInfo(e,"why_no_funding");}}
                                  autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                                {
                                  this.state.error_entrepreneur_info.why_no_funding.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_entrepreneur_info.why_no_funding}</div>
                                }
                              </div>
                            }

                            <div className="mb-10 fv-row">
                              <label className="required d-flex align-items-center form-label mb-3">
                                Have you raised external funding for any previous businesses you have been part of?
                              </label>
                              <div className="row mb-2" data-kt-buttons="true">
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.received_funding_for_other_business == 'yes'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="received_funding_for_other_business"
                                      value="yes"
                                      checked={this.state.entrepreneur_info.received_funding_for_other_business == 'yes'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.received_funding_for_other_business = 'yes';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">Yes</span>
                                  </label>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <label className={classNames("btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4", {"active": this.state.entrepreneur_info.received_funding_for_other_business == 'no'})}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="received_funding_for_other_business"
                                      value="no"
                                      checked={this.state.entrepreneur_info.received_funding_for_other_business == 'no'}
                                      onChange={() => {
                                        let entrepreneur_info = this.state.entrepreneur_info;
                                        entrepreneur_info.received_funding_for_other_business = 'no';
                                        entrepreneur_info.other_business_funding_type = '';
                                        this.setState({
                                          ...this.state,
                                          entrepreneur_info: entrepreneur_info
                                        })
                                      }}
                                    />
                                    <span className="fw-bold fs-3">No</span>
                                  </label>
                                </div>
                              </div>
                              {
                                this.state.error_entrepreneur_info.received_funding_for_other_business.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.received_funding_for_other_business}</div>
                              }
                            </div>

                            {
                              this.state.entrepreneur_info.received_funding_for_other_business === 'yes' &&
                              <>
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">Previous Businesses Type of Funding</label>   
                                <Select
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={(value) => {this.updateEntrepreneurInfo(value, 'other_business_funding_type')}}
                                  options={[
                                    {
                                      value: 'Angel Investors',
                                      label: 'Angel Investors',
                                    },
                                    {
                                      value: 'Friends and Family',
                                      label: 'Friends and Family',
                                    },
                                    {
                                      value: 'Bank loans or loan from other financial service providers',
                                      label: 'Bank loans or loan from other financial service providers',
                                    },
                                    {
                                      value: 'Venture Capital Fund',
                                      label: 'Venture Capital Fund',
                                    },
                                    {
                                      value: 'Grant Funding',
                                      label: 'Grant Funding',
                                    },
                                    {
                                      value: 'Prize Money',
                                      label: 'Prize Money',
                                    },
                                    {
                                      value: 'Other',
                                      label: 'Other',
                                    },
                                  ]}
                                />
                                {
                                  this.state.error_entrepreneur_info.other_business_funding_type.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_entrepreneur_info.other_business_funding_type}</div>
                                }
                              </div>
                              {
                                this.state.other_business_other_funding_type === 'Other' &&
                                <div className="fv-row mb-10">
                                  <label className="required form-label mb-3">Briefly Explain the Funding Type</label>   
                                  <Input size="large" required type="text" id="other_business_other_funding_type" className="form-control form-control-lg" name="other_business_other_funding_type" value={this.state.entrepreneur_info.other_business_funding_type} onChange={(e) => {this.updateEntrepreneurInfo(e,"other_business_other_funding_type");}} />
                                  {
                                    this.state.error_entrepreneur_info.other_business_funding_type.length > 0 && 
                                    <div className="invalid-feedback">{this.state.error_entrepreneur_info.other_business_funding_type}</div>
                                  }
                                </div>
                              }
                              </>
                            }


                            <div className="fv-row mb-10">
                              <label className="required form-label mb-3">If you were seeking investment into your business at present, how many potential investors would you say you had in your network to approach for investment?</label>   
                              <Select
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={(value) => {this.updateEntrepreneurInfo(value, 'investors_network')}}
                                options={[
                                  {
                                    value: 'none',
                                    label: 'None',
                                  },
                                  {
                                    value: '1',
                                    label: '1',
                                  },
                                  {
                                    value: '2',
                                    label: '2',
                                  },
                                  {
                                    value: '3',
                                    label: '3',
                                  },
                                  {
                                    value: '4',
                                    label: '4',
                                  },
                                  {
                                    value: '5',
                                    label: '5',
                                  },
                                  {
                                    value: 'more than 5',
                                    label: 'More than 5',
                                  },
                                ]}
                              />
                              {
                                this.state.error_entrepreneur_info.investors_network.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.investors_network}</div>
                              }
                            </div>

                            <div className="fv-row mb-10">
                              <label className="required form-label mb-3">If you were seeking business advice or support at present, how many female entrepreneurs would you say you had in your network to approach for advice or support?</label>   
                              <Select
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={(value) => {this.updateEntrepreneurInfo(value, 'females_advisors_network')}}
                                options={[
                                  {
                                    value: 'none',
                                    label: 'None',
                                  },
                                  {
                                    value: '1',
                                    label: '1',
                                  },
                                  {
                                    value: '2',
                                    label: '2',
                                  },
                                  {
                                    value: '3',
                                    label: '3',
                                  },
                                  {
                                    value: '4',
                                    label: '4',
                                  },
                                  {
                                    value: '5',
                                    label: '5',
                                  },
                                  {
                                    value: 'more than 5',
                                    label: 'More than 5',
                                  },
                                ]}
                              />
                              {
                                this.state.error_entrepreneur_info.females_advisors_network.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.females_advisors_network}</div>
                              }
                            </div>

                            <div className="fv-row mb-10">
                              <label className="required form-label mb-3">How much revenue does your business generate annually?</label>   
                              <Select
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={(value) => {this.updateEntrepreneurInfo(value, 'annual_revenue')}}
                                options={[
                                  {
                                    value: 'Prefer to not say',
                                    label: 'Prefer to not say',
                                  },
                                  {
                                    value: 'Pre-revenue (Business not yet generating revenue)',
                                    label: 'Pre-revenue (Business not yet generating revenue)',
                                  },
                                  {
                                    value: 'Less than $10,000',
                                    label: 'Less than $10,000',
                                  },
                                  {
                                    value: 'Between $10,000 and $50,000',
                                    label: 'Between $10,000 and $50,000',
                                  },
                                  {
                                    value: 'Between $50,000 and $100,000',
                                    label: 'Between $50,000 and $100,000',
                                  },
                                  {
                                    value: 'Between $100,000 and $500,000',
                                    label: 'Between $100,000 and $500,000',
                                  },
                                  {
                                    value: 'Between $500,000 and $1 million',
                                    label: 'Between $500,000 and $1 million',
                                  },
                                  {
                                    value: 'More than $1 million',
                                    label: 'More than $1 million',
                                  },
                                ]}
                              />
                              <div className="form-text">Revenue refers to income generated from business operations annually</div>
                              {
                                this.state.error_entrepreneur_info.annual_revenue.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_entrepreneur_info.annual_revenue}</div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className={classNames({"current": this.state.current_step === 4})} data-kt-stepper-element="content">
                          <div className="w-100 text-center">
                            {/* <span className="svg-icon svg-icon-5hx svg-icon-primary me-4">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z" fill="currentColor" opacity="0.3"/>
                                  <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="currentColor"/>
                                </g>
                              </svg>
                            </span> */}
                            <img
                              className="mx-auto mw-100 w-150px w-lg-300px"
                              src="/assets/media/illustrations/awa/awa17.svg"
                              alt=""
                            />
                            <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Onboarding Completed </h1>
                            <div className="fs-3 fw-bold text-muted mb-10">
                              Well done, you have successfully completed your onboarding.
                            </div>
                          </div>
                        </div>
                        {
                          this.state.current_step < 4 ?
                          <div className="d-flex flex-stack pt-15">
                            <div className="mr-2">
                            {
                              this.state.current_step > 1 && this.state.current_step < 4 &&
                              
                                <button type="button" disabled={this.state.isRequesting}
                                  className="btn btn-lg btn-light-primary me-3" onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      current_step: this.state.current_step - 1
                                    })
                                  }}>                            
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={6} y={11} width={13} height={2} rx={1} fill="currentColor" />
                                      <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                  Previous
                                </button>
                            }
                            </div>

                            <div>
                            {
                              this.state.current_step < 3 &&
                              <button type="button" className="btn btn-lg btn-primary" onClick={() => {this.validateEntrepreneurInfo()}}>
                                Next
                                <span className="svg-icon svg-icon-4 ms-1">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                  </svg>
                                </span>
                              </button>
                            }
                            
                            {
                              this.state.current_step === 3 &&
                              <>
                                {
                                  this.state.isRequesting ?
                                  <button type="button" disabled className="btn btn-lg btn-primary">
                                    <span>
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                  </button>
                                  :
                                  <button type="button" onClick={() => {this.validateEntrepreneurInfo()}} className="btn btn-lg btn-primary">
                                    <span className="indicator-label">
                                      Submit
                                      <span className="svg-icon svg-icon-4 ms-2">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                          <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                }
                              </>
                            }

                            
                              
                            </div>
                          </div>
                          :
                          <div className="text-center pt-5">
                            
                              <button type="button" onClick={() => {window.location = "/home";}} className="btn btn-lg btn-primary">
                                <span className="indicator-label">
                                  Continue
                                  <span className="svg-icon svg-icon-4 ms-2">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                      <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                </span>
                              </button>
                          </div>
                        }
                      </form>
                    }

                    {
                      this.state.user_type === 'mentor' &&
                      <form className="my-auto pb-5" noValidate="novalidate" 
                        id="kt_create_account_form"  onSubmit={this.submitMentorOnboarding} method="POST">                      
                        <div className={classNames({"current": this.state.current_step === 2})} data-kt-stepper-element="content">
                          <div className="w-100">
                            <div className="pb-10 pb-lg-15">
                              <h2 className="fw-bold text-dark">Personal Info</h2>
                              <div className="text-muted fw-semibold fs-6">
                                Tell us about yourself.
                              </div>
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Full Name</label>   
                              <Input size="large" required disabled type="text" id="full-name" className="form-control form-control-lg" placeholder="Enter your full name" name="name" value={this.state.entrepreneur_info.full_name} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Email</label>   
                              <Input size="large" required disabled type="text" id="email" className="form-control form-control-lg" placeholder="Enter your email" name="email" value={this.state.entrepreneur_info.email} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Name of Company</label>   
                              <Input size="large" required type="text" id="company" className="form-control form-control-lg" placeholder="Enter the name of your company" name="company" value={this.state.mentor_info.company} onChange={(e) => {this.updateMentorInfo(e,"company");}} />
                              {
                                this.state.error_mentor_info.company.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_mentor_info.company}</div>
                              }
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Role Title</label>   
                              <Input size="large" required type="text" id="role" className="form-control form-control-lg" placeholder="Enter your role at your company" name="role" value={this.state.mentor_info.role} onChange={(e) => {this.updateMentorInfo(e,"role");}} />
                              {
                                this.state.error_mentor_info.role.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_mentor_info.role}</div>
                              }
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Value Statement</label> 
                              <TextArea required
                                value={this.state.mentor_info.value_statement}
                                onChange={(e) => {this.updateMentorInfo(e,"value_statement");}}
                                autoSize={{ minRows: 3, maxRows: 8 }}
                                showCount
                                maxLength={1000}
                              />  
                              {
                                this.state.error_mentor_info.value_statement.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_mentor_info.value_statement}</div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className={classNames({"current": this.state.current_step === 3})} data-kt-stepper-element="content">
                          <div className="w-100 text-center">
                            <img
                              className="mx-auto mw-100 w-150px w-lg-300px"
                              src="/assets/media/illustrations/awa/awa17.svg"
                              alt=""
                            />
                            <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Onboarding Completed </h1>
                            <div className="fs-3 fw-bold text-muted mb-10">
                              Well done, you have successfully completed your onboarding.
                            </div>
                          </div>
                        </div>
                        {
                          this.state.current_step < 3 ?
                          <div className="d-flex flex-stack pt-15">
                            <div className="mr-2">
                            {
                              this.state.current_step > 1 && this.state.current_step < 3 &&
                              
                                <button type="button" disabled={this.state.isRequesting}
                                  className="btn btn-lg btn-light-primary me-3" onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      current_step: this.state.current_step - 1
                                    })
                                  }}>                            
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={6} y={11} width={13} height={2} rx={1} fill="currentColor" />
                                      <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                  Previous
                                </button>
                            }
                            </div>

                            <div>
                            {
                              this.state.current_step < 2 &&
                              <button type="button" className="btn btn-lg btn-primary" onClick={() => {this.validateMentorInfo()}}>
                                Next
                                <span className="svg-icon svg-icon-4 ms-1">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                  </svg>
                                </span>
                              </button>
                            }
                            
                            {
                              this.state.current_step === 2 &&
                              <>
                                {
                                  this.state.isRequesting ?
                                  <button type="button" disabled className="btn btn-lg btn-primary">
                                    <span>
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                  </button>
                                  :
                                  <button type="button" onClick={() => {this.validateMentorInfo()}} className="btn btn-lg btn-primary">
                                    <span className="indicator-label">
                                      Submit
                                      <span className="svg-icon svg-icon-4 ms-2">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                          <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                }
                              </>
                            }
                            </div>
                          </div>
                          :
                          <div className="text-center pt-5">
                            
                              <button type="button" onClick={() => {window.location = "/home";}} className="btn btn-lg btn-primary">
                                <span className="indicator-label">
                                  Continue
                                  <span className="svg-icon svg-icon-4 ms-2">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                      <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                </span>
                              </button>
                          </div>
                        }
                      </form>
                    }

                    {
                      this.state.user_type === 'investor' &&
                      <form className="my-auto pb-5" noValidate="novalidate" 
                        id="kt_create_account_form"  onSubmit={this.submitInvestorOnboarding} method="POST">                      
                        <div className={classNames({"current": this.state.current_step === 2})} data-kt-stepper-element="content">
                          <div className="w-100">
                            <div className="pb-10 pb-lg-15">
                              <h2 className="fw-bold text-dark">Personal Info</h2>
                              <div className="text-muted fw-semibold fs-6">
                                Tell us about yourself.
                              </div>
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Full Name</label>   
                              <Input size="large" required disabled type="text" id="full-name" className="form-control form-control-lg" placeholder="Enter your full name" name="name" value={this.state.entrepreneur_info.full_name} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Email</label>   
                              <Input size="large" required disabled type="text" id="email" className="form-control form-control-lg" placeholder="Enter your email" name="email" value={this.state.entrepreneur_info.email} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Name of Company</label>   
                              <Input size="large" required type="text" id="company" className="form-control form-control-lg" placeholder="Enter the name of your company" name="company" value={this.state.investor_info.company} onChange={(e) => {this.updateInvestorInfo(e,"company");}} />
                              {
                                this.state.error_investor_info.company.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_investor_info.company}</div>
                              }
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Role Title</label>   
                              <Input size="large" required type="text" id="role" className="form-control form-control-lg" placeholder="Enter your role at your company" name="role" value={this.state.investor_info.role} onChange={(e) => {this.updateInvestorInfo(e,"role");}} />
                              {
                                this.state.error_investor_info.role.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_investor_info.role}</div>
                              }
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Investor Type</label> 
                              <Select
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={(value) => {this.updateInvestorInfo(value, 'investor_type')}}
                                options={[
                                  {
                                    value: 'Angel Investor',
                                    label: 'Angel Investor',
                                  },
                                  {
                                    value: 'Venture Capital',
                                    label: 'Venture Capital',
                                  },
                                  {
                                    value: 'Grant Investor',
                                    label: 'Grant Investor',
                                  },
                                  {
                                    value: 'Impact Investor',
                                    label: 'Impact Investor',
                                  },
                                  {
                                    value: 'Soft Loans',
                                    label: 'Soft Loans',
                                  },
                                  {
                                    value: 'Patient Capital',
                                    label: 'Patient Capital',
                                  }
                                ]}
                              />
                              {
                                this.state.error_investor_info.investor_type.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_investor_info.investor_type}</div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className={classNames({"current": this.state.current_step === 3})} data-kt-stepper-element="content">
                          <div className="w-100 text-center">
                            <img
                              className="mx-auto mw-100 w-150px w-lg-300px"
                              src="/assets/media/illustrations/awa/awa17.svg"
                              alt=""
                            />
                            <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Onboarding Completed </h1>
                            <div className="fs-3 fw-bold text-muted mb-10">
                              Well done, you have successfully completed your onboarding.
                            </div>
                          </div>
                        </div>
                        {
                          this.state.current_step < 3 ?
                          <div className="d-flex flex-stack pt-15">
                            <div className="mr-2">
                            {
                              this.state.current_step > 1 && this.state.current_step < 3 &&
                              
                                <button type="button" disabled={this.state.isRequesting}
                                  className="btn btn-lg btn-light-primary me-3" onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      current_step: this.state.current_step - 1
                                    })
                                  }}>                            
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={6} y={11} width={13} height={2} rx={1} fill="currentColor" />
                                      <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                  Previous
                                </button>
                            }
                            </div>

                            <div>
                            {
                              this.state.current_step < 2 &&
                              <button type="button" className="btn btn-lg btn-primary" onClick={() => {this.validateMentorInfo()}}>
                                Next
                                <span className="svg-icon svg-icon-4 ms-1">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                  </svg>
                                </span>
                              </button>
                            }
                            
                            {
                              this.state.current_step === 2 &&
                              <>
                                {
                                  this.state.isRequesting ?
                                  <button type="button" disabled className="btn btn-lg btn-primary">
                                    <span>
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                  </button>
                                  :
                                  <button type="button" onClick={() => {this.validateInvestorInfo()}} className="btn btn-lg btn-primary">
                                    <span className="indicator-label">
                                      Submit
                                      <span className="svg-icon svg-icon-4 ms-2">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                          <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                }
                              </>
                            }
                            </div>
                          </div>
                          :
                          <div className="text-center pt-5">
                            
                              <button type="button" onClick={() => {window.location = "/home";}} className="btn btn-lg btn-primary">
                                <span className="indicator-label">
                                  Continue
                                  <span className="svg-icon svg-icon-4 ms-2">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                      <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                </span>
                              </button>
                          </div>
                        }
                      </form>
                    }

                    {
                      this.state.user_type === 'job_seeker' &&
                      <form className="my-auto pb-5" noValidate="novalidate" 
                        id="kt_create_account_form"  onSubmit={this.submitJobSeekerOnboarding} method="POST">                      
                        <div className={classNames({"current": this.state.current_step === 2})} data-kt-stepper-element="content">
                          <div className="w-100">
                            <div className="pb-10 pb-lg-15">
                              <h2 className="fw-bold text-dark">Personal Info</h2>
                              <div className="text-muted fw-semibold fs-6">
                                Tell us about yourself.
                              </div>
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Full Name</label>   
                              <Input size="large" required disabled type="text" id="full-name" className="form-control form-control-lg" placeholder="Enter your full name" name="name" value={this.state.entrepreneur_info.full_name} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Email</label>   
                              <Input size="large" required disabled type="text" id="email" className="form-control form-control-lg" placeholder="Enter your email" name="email" value={this.state.entrepreneur_info.email} />
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Education</label>   
                              <Input size="large" required type="text" id="education" className="form-control form-control-lg" placeholder="Enter your education level" name="education" value={this.state.job_seeker_info.education} onChange={(e) => {this.updateJobSeekerInfo(e,"education");}} />
                              {
                                this.state.error_job_seeker_info.education.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_job_seeker_info.education}</div>
                              }
                            </div>
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Area of Interest</label>   
                              <Select
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={(value) => {this.updateJobSeekerInfo(value, 'interest')}}
                                options={[
                                  {
                                    value: 'Marketing',
                                    label: 'Marketing',
                                  },
                                  {
                                    value: 'Finance',
                                    label: 'Finance',
                                  },
                                  {
                                    value: 'Product Management',
                                    label: 'Product Management',
                                  },
                                  {
                                    value: 'Operations',
                                    label: 'Operations',
                                  },
                                  {
                                    value: 'Web development/Design',
                                    label: 'Web development/Design',
                                  },
                                  {
                                    value: 'Project Management',
                                    label: 'Project Management',
                                  },
                                  {
                                    value: 'Administration',
                                    label: 'Administration',
                                  },
                                  {
                                    value: 'Other',
                                    label: 'Other',
                                  }
                                ]}
                              />
                              {
                                this.state.error_job_seeker_info.interest.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_job_seeker_info.interest}</div>
                              }
                            </div>
                            {
                              this.state.other_interest === 'Other' &&
                              <div className="fv-row mb-10">
                                <label className="required form-label mb-3">Tell Us About your Interest</label>   
                                <Input size="large" required type="text" id="other_interest" className="form-control form-control-lg" name="other_interest" value={this.state.job_seeker_info.interest} onChange={(e) => {this.updateJobSeekerInfo(e,"other_interest");}} />
                                {
                                  this.state.error_job_seeker_info.interest.length > 0 && 
                                  <div className="invalid-feedback">{this.state.error_job_seeker_info.interest}</div>
                                }
                              </div>
                            }
                            <div className="fv-row mb-8">
                              <label className="required form-label mb-3">Value Statement</label> 
                              <TextArea required
                                value={this.state.job_seeker_info.value_statement}
                                onChange={(e) => {this.updateJobSeekerInfo(e,"value_statement");}}
                                autoSize={{ minRows: 3, maxRows: 8 }}
                                showCount
                                maxLength={1000}
                              />
                              <div className="form-text">why do you want to work with african women entrepreneurs?</div>
                              {
                                this.state.error_job_seeker_info.value_statement.length > 0 && 
                                <div className="invalid-feedback">{this.state.error_job_seeker_info.value_statement}</div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className={classNames({"current": this.state.current_step === 3})} data-kt-stepper-element="content">
                          <div className="w-100 text-center">
                            <img
                              className="mx-auto mw-100 w-150px w-lg-300px"
                              src="/assets/media/illustrations/awa/awa17.svg"
                              alt=""
                            />
                            <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Onboarding Completed </h1>
                            <div className="fs-3 fw-bold text-muted mb-10">
                              Well done, you have successfully completed your onboarding.
                            </div>
                          </div>
                        </div>
                        {
                          this.state.current_step < 3 ?
                          <div className="d-flex flex-stack pt-15">
                            <div className="mr-2">
                            {
                              this.state.current_step > 1 && this.state.current_step < 3 &&
                              
                                <button type="button" disabled={this.state.isRequesting}
                                  className="btn btn-lg btn-light-primary me-3" onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      current_step: this.state.current_step - 1
                                    })
                                  }}>                            
                                  <span className="svg-icon svg-icon-4 me-1">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={6} y={11} width={13} height={2} rx={1} fill="currentColor" />
                                      <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                  Previous
                                </button>
                            }
                            </div>

                            <div>
                            {
                              this.state.current_step < 2 &&
                              <button type="button" className="btn btn-lg btn-primary" onClick={() => {this.validateMentorInfo()}}>
                                Next
                                <span className="svg-icon svg-icon-4 ms-1">
                                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                  </svg>
                                </span>
                              </button>
                            }
                            
                            {
                              this.state.current_step === 2 &&
                              <>
                                {
                                  this.state.isRequesting ?
                                  <button type="button" disabled className="btn btn-lg btn-primary">
                                    <span>
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                  </button>
                                  :
                                  <button type="button" onClick={() => {this.validateJobSeekerInfo()}} className="btn btn-lg btn-primary">
                                    <span className="indicator-label">
                                      Submit
                                      <span className="svg-icon svg-icon-4 ms-2">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                          <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                }
                              </>
                            }
                            </div>
                          </div>
                          :
                          <div className="text-center pt-5">
                            
                              <button type="button" onClick={() => {window.location = "/home";}} className="btn btn-lg btn-primary">
                                <span className="indicator-label">
                                  Continue
                                  <span className="svg-icon svg-icon-4 ms-2">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                      <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" /> 
                                    </svg>
                                  </span>
                                </span>
                              </button>
                          </div>
                        }
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

      </Fragment>
    );
  }
}

export default Onboarding;