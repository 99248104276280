
import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/LessonTwoTwo_section1.module.scss';
import section2Styles from '../scss/LessonTwoTwo_section2.module.scss';
import styles from '../scss/PitchDeck.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanThree extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
          ...this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Three</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                        <section className={section1Styles.section1}>
                        <div className={section1Styles.content_box2}>
                          <div className={section1Styles.flexRow1}>
                            <h1 className={section1Styles.hero_title}>Customers Lesson Plan</h1>
                            <div className={section1Styles.flexRow1__spacer} />
                          </div>
                        </div>
                  
                        <div className={section1Styles.content_box}>
                          <h3 className={section1Styles.subtitle}>
                            Customers are an essential part of any business. Without customers, no business can survive which means your
                            customers are in control of your business success.  It is in you and your business’s best interest to
                            understand and satisfy your customers need(s) to keep them patronizing your business. It is also important to
                            get new customers as the more customers your business has, the bigger and more successful it will grow.  
                            <br />
                          </h3>
                        </div>
                  
                        <div className={section1Styles.group}>
                          <div className={section1Styles.content_box1}>
                            <div className={section1Styles.flexCol2}>
                              <h2 className={section1Styles.medium_title1}>By the end of this session, you should:</h2>
                              <h3 className={section1Styles.subtitle1}>
                                1. Know what the following terms mean: customer needs, main customer needs, price buyers, relationship
                                buyers, value buyers, poker player buyers, customer acquisition funnel, Customer Acquisition Channels,
                                Customer satisfaction
                                <br />
                                2. Know what the five main customer needs to serve are.
                                <br />
                                3. Know the four different types of customers.
                                <br />
                                4. Know the different ways to calculate customer satisfaction.
                                <br />
                                5. Know where to find your customers.
                                <br />
                              </h3>
                            </div>
                          </div>
                  
                          <img
                            src={require('../../assets/courseplan/48cdb55d43a3e4df89eb38cdf4852807.png')}
                            alt="alt text"
                            className={section1Styles.image31}
                          />
                        </div>
                      </section>
                  }
                  {
                      <section className={section2Styles.section2}>
                        <div className={section2Styles.flexCol}>
                          <div className={section2Styles.flexCol1}>
                            <div className={section2Styles.flexCol2}>
                              <h4 className={section2Styles.highlights}>
                                It is important to understand your customer i.e., what they need, what they deem relevant and important,
                                how they feel and think, their budgets etc. To do so, you need to put into segment the various types of
                                customers, so you know how to approach each of these segments to manage them effectively.
                              </h4>
                              <h1 className={section2Styles.title}>Customer Needs</h1>
                              <h4 className={section2Styles.highlights1}>
                                A customer need is the need or requirement that motivates your customer to purchase your product/service.
                                This need can be known to the customer (i.e., the customer can put it into words), or it may also be
                                unknown (i.e., a feeling the customer has). This need whether known or unknown is the ultimate factor that
                                determines what the customer purchases. As a business owner, the better you are at identifying and serving
                                your customers’ needs, the more loyal your customers will be to your business.
                                <br />
                              </h4>
                              <h1 className={section2Styles.title1}>Five main customer needs to serve</h1>
                            </div>
                  
                            <div className={section2Styles.flexCol3}>
                              <div className={section2Styles.flexCol3__cell}>
                                <div className={section2Styles.group}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights2}>
                                      Customers are incredibly sensitive to prices and whilst some business owners interpret this to mean
                                      that “the lower the price the better”, this is a misconception that might work against them. Most
                                      customers equate price with value, therefore if your customer feels that they are getting value for
                                      their money, they will be happy to patronize your business. It is therefore vital that you always
                                      relate your price to the value of the product or service that you are offering.
                                      <br />
                                      <br />
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle2}>Price points</h3>
                                    
                                </div>
                              </div>
                              <div className={section2Styles.flexCol3__cell}>
                                <div className={section2Styles.group1}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights2}>
                                      When thinking of convenience ask yourself the following questions:
                                      <br />
                                      How convenient is it for a customer to find, access, buy and use your product or service?  <br />
                                      The more convenient it is to find, access, buy and use your product or service, the better the
                                      chances of customers patronizing your business.
                                      <br />
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle2}>Convenience</h3>
                                   
                                </div>
                              </div>
                              <div className={section2Styles.flexCol3__cell}>
                                <div className={section2Styles.group2}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights2}>
                                      Customers are now thinking more about aligning their purchases with brands with eco-friendly
                                      products and packaging therefore your business also needs to think about this. From your business
                                      practices to your actual product/service, tailoring these towards sustainability might be the
                                      difference between a customer patronizing you versus your competition. It is important therefore to
                                      keep this in mind at every stage of your business and champion your sustainable practices in your
                                      marketing where possible.
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle2}>Sustainability</h3>
                                  
                                </div>
                              </div>
                              <div className={section2Styles.flexCol3__cell}>
                                <div className={section2Styles.group3}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights2}>
                                      Be honest, transparent, and fair to your customers at every step of their customer journey. How you
                                      manage your business errors will tell your customers a lot about you and your business and this may
                                      be what brings them back or dissuades them and others from patronizing your business
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle2}>Transparency</h3>
                                  
                                </div>
                              </div>
                              <div className={section2Styles.flexCol3__cell}>
                                <div className={section2Styles.group4}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights2}>
                                      Where your business can offer customization options, be sure to make this process as easy and
                                      seamless as possible. Where your business does not offer customization, pay attention to what your
                                      customers say they want and consider these for your future products and services. You do not need to
                                      provide everything your customers ask for but at least offering one or two of “popular demand items”
                                      shows them you are listening and that their opinions matter to you.
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle2}>Control and options</h3>
                                  
                                </div>
                              </div>
                            </div>
                  
                            <div className={section2Styles.flexCol5}>
                              <h1 className={section2Styles.title2}>
                                Four types of customers
                                <br />
                              </h1>
                              <div className={section2Styles.flexCol5__cell}>
                                <div className={section2Styles.group5}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights3_box}>
                                      <span className={section2Styles.highlights3}>
                                        <span className={section2Styles.highlights3_span0}>
                                          Price-buying customers only care about acquiring your product or service at the lowest price
                                          possible. These buyers are not committed to any particularly business and  make sure they are
                                          able to change suppliers easily and at will (i.e., they will go where the price dictates).
                                          <br />
                                          <br />
                                        </span>
                                        <span className={section2Styles.highlights3_span1}>Negotiating with price buyers</span>
                                        <span className={section2Styles.highlights3_span2}>
                                          <br />
                                          Some things to think about when negotiating with a price buyer:
                                          <br />
                                          Conduct an extensive cost analysis that includes all costs of service and support before you
                                          develop a price for your product/service.
                                          <br />
                                          Strip away all value-added features in pricing the deal.
                                          <br />
                                          Establish a rational walkaway price so you do not agree to a price that will cost your business
                                          in the long run.
                                          <br />
                                          <br />
                                        </span>
                                      </span>
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle21}>Price buyers </h3>
                                  
                                </div>
                              </div>
                              <div className={section2Styles.flexCol5__cell}>
                                <div className={section2Styles.group6}>
                                  <div className={section2Styles.group7}>
                                    <div className={section2Styles.flexCol4}>
                                      <div className={section2Styles.rect4} />
                                      <h4 className={section2Styles.highlights3_box}>
                                        <span className={section2Styles.highlights3}>
                                          <span className={section2Styles.highlights3_span0}>
                                            Relationship buyers expect you (your business) to invest in understanding the business your
                                            products or services support. These customers have a high level of trust in and loyalty to
                                            your business.
                                            <br />
                                            <br />
                                          </span>
                                          <span className={section2Styles.highlights3_span1}>
                                            Negotiating with relationship buyers
                                            <br />
                                          </span>
                                          <span className={section2Styles.highlights3_span2}>
                                            Here are the tactics to consider when negotiating with relationship buyers:
                                            <br />
                                            Develop an intimate knowledge of the prospect, concentrating on specific problems, value
                                            drivers and possible solutions.
                                            <br />
                                            Focus on making decisions that are in the customer’s best interest.
                                            <br />
                                            Continually probe for areas of dissatisfaction and the impact of current work.
                                          </span>
                                        </span>
                                      </h4>
                                    </div>
                  
                                    <h3 className={section2Styles.subtitle22}>
                                      Relationship buyers
                                      <br />
                                    </h3>
                                  </div>
                  
                                  
                                </div>
                              </div>
                              <div className={section2Styles.flexCol5__cell}>
                                <div className={section2Styles.group8}>
                                  <div className={section2Styles.group7}>
                                    <div className={section2Styles.flexCol4}>
                                      <div className={section2Styles.rect4} />
                                      <h4 className={section2Styles.highlights3_box}>
                                        <span className={section2Styles.highlights3}>
                                          <span className={section2Styles.highlights3_span0}>
                                            Value buyers favor businesses who add value to their operations in terms of increased
                                            efficiencies, reduced costs, increased sales and high margins.
                                            <br />
                                            <br />
                                          </span>
                                          <span className={section2Styles.highlights3_span1}>
                                            Negotiating with value buyers
                                            <br />
                                          </span>
                                          <span className={section2Styles.highlights3_span2}>
                                            When negotiating with a value buyer:
                                            <br />
                                            Do not focus on price during initial discussions.
                                            <br />
                                            Focus completely on discovering differential value compared to competitors.
                                            <br />
                                            Present an extensive list of where and how you can provide value relative to other vendors in
                                            the negotiation, and quantify that value to defend your position.
                                          </span>
                                        </span>
                                      </h4>
                                    </div>
                  
                                    <h3 className={section2Styles.subtitle22}>Value buyers </h3>
                                  </div>
                  
                                  
                                </div>
                              </div>
                              <div className={section2Styles.flexCol5__cell}>
                                <div className={section2Styles.group9}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights3_box}>
                                      <span className={section2Styles.highlights3}>
                                        <span className={section2Styles.highlights3_span0}>
                                          Poker players are value or relationship-buyers in a price buyer disguise. Their intent in acting
                                          like a price buyer is to force the negotiation into a bluffing situation that will benefit them
                                          (the buyers) at the expense of you (the seller). Poker players try to take advantage of you
                                          using a technique called nibbling, i.e., always asking for more, often little things.
                                          <br />
                                          Negotiating with poker plays
                                          <br />
                                        </span>
                                        <span className={section2Styles.highlights3_span1}>
                                          When negotiating with a poker player:
                                          <br />
                                        </span>
                                        <span className={section2Styles.highlights3_span2}>
                                          Establish valued give-gets (what are you willing to give to get) ahead of time.
                                          <br />
                                          Pre-plan tactics that the poker player will use and prepare an appropriate response to each.
                                          <br />
                                          Recognize that your product/services have value, and do not minimize these but immediately
                                          resorting to discounting, which will validate poker-playing behavior.
                                          <br />
                                          Adapted from: “Negotiating with Backbone,” by Reed K. Holden, CEO and founder of Holden
                                          Advisors.
                                          <br />
                                        </span>
                                      </span>
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle23}>
                                    Poker players
                                    <br />
                                  </h3>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol6}>
                            <div className={section2Styles.flexCol7}>
                              <h1 className={section2Styles.title3}>
                                The Customer Acquisition Funnel (Sourced from Hubspot)
                                <br />
                              </h1>
                              <h4 className={section2Styles.highlights4}>
                                In the business world, we typically visualize the customer journey with a funnel or a similar graphic that
                                highlights the stages in the buying process and the mindset of the prospect.
                                <br />
                              </h4>
                              <div className={section2Styles.flexCol7__cell}>
                                <img
                                  src={require('../../assets/courseplan/6633b3c9398c75370ceaca7a72b6e675.png')}
                                  alt="alt text"
                                  className={section2Styles.image5}
                                />
                              </div>
                            </div>
                  
                            <div className={section2Styles.flexCol8}>
                              <h4 className={section2Styles.highlights5}>
                                As consumers move through the funnel to become buyers, they:
                                <br />
                                Gain awareness about your brand
                                <br />
                                Add your product or service to their consideration pool
                                <br />
                                Decide to become a paying customer of your business
                                <br />
                                To simplify the process, lead generation typically happens at the top of the funnel, lead acquisition
                                happens in the middle, and lead conversion happens at the bottom.
                                <br />
                                And customer acquisition typically refers to the funnel as a whole.
                                <br />
                                <br />
                                Here’s another way to visualize it, in a less funnel-like fashion:
                                <br />
                              </h4>
                              <h4 className={section2Styles.highlights6}>
                                <br />
                                In the example above, customer acquisition lives in the “attract,” “convert,” and “close” phases, where
                                strangers become visitors, leads, and customers.
                                <br />
                                <br />
                                Acquisition marketing is the process of creating an advertising and promotion strategy that specifically
                                targets consumers who are already considering your products and services. These consumers are aware of
                                your brand, making them prime candidates for conversion.
                                <br />
                              </h4>
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol9}>
                            <div className={section2Styles.flexCol10}>
                              <h1 className={section2Styles.title3}>Customer Acquisition Channels</h1>
                              <h4 className={section2Styles.highlights7}>
                                Customer acquisition channels are the platforms companies use to promote their products and services to
                                new audiences.  This includes organic search, organic social media, and email. The best acquisition
                                channels for your business will depend on your audience (customers and consumers), resources (what you
                                have to build your business), and overall strategy (are you going for a niche market or appeal to mass
                                market).
                                <br />
                              </h4>
                            </div>
                  
                            <div className={section2Styles.flexCol9__cell}>
                              <div className={section2Styles.group10}>
                                <div className={section2Styles.group11}>
                                  <div className={section2Styles.flexCol4}>
                                    <div className={section2Styles.rect4} />
                                    <h4 className={section2Styles.highlights8}>
                                      Organic search refers to the search engine results pages (SERPs), such as Yahoo, MSN, or Google
                                      search results. Organic search marketing, by extension, refers to any efforts you make for your
                                      business to rank highly in these results.
                                      <br />
                                      To leverage organic search as a customer acquisition channel, you must invest in search engine
                                      optimization (SEO). The idea behind SEO is to create content that shows up high on the SERPs and
                                      makes searchers want to click on your content.
                                      <br />
                                      You can use tools such as SEMRush, Google Search Console, Open Site Explorer, KWFinder and Ahrefs to
                                      find the best keywords for your business and create powerful content that attracts potential new
                                      customers.
                                      <br />
                                      <br />
                                    </h4>
                                  </div>
                  
                                  <h3 className={section2Styles.subtitle24}>Organic Search</h3>
                                </div>
                  
                                
                              </div>
                            </div>
                            <div className={section2Styles.flexCol9__cell}>
                              <div className={section2Styles.group12}>
                                <h4 className={section2Styles.highlights9}>
                                  Paid search marketing (pay-per-click, or PPC) is advertising on search engines themselves. In addition,
                                  some PPC platforms, such as Google Ads, allow you to place display advertisements on partner websites
                                  and publishers. Instead of organically optimizing your content, PPC allows you to create a search result
                                  and pay for it to show up alongside organic results, theoretically increasing your chances of being
                                  found by searchers.
                                  <br />
                                  To equip your content and adverts to perform best in search engines, you can use tools such as Google
                                  Keyword Planner and Microsoft Advertising.
                                  <br />
                                </h4>
                                <h3 className={section2Styles.subtitle25}>Paid Search</h3>
                                
                                <div className={section2Styles.rect41} />
                              </div>
                            </div>
                            <div className={section2Styles.flexCol9__cell}>
                              <div className={section2Styles.group13}>
                                <h4 className={section2Styles.highlights9}>
                                  Social media marketing consists of two methods: organic and paid. Organic social media is most useful
                                  for boosting brand awareness, developing a company personality, and sharing content you have published
                                  elsewhere (like from your blog or videos). Organic social media also capitalizes on the “virality”
                                  (social media content that spreads widely in a matter of seconds, minutes or hours in the realm of
                                  social networks and other connected platforms) factor, inspiring your customers and followers to help
                                  you advertise.
                                  <br />
                                </h4>
                                <h3 className={section2Styles.subtitle25}>Organic Social Media</h3>
                                
                                <div className={section2Styles.rect41} />
                              </div>
                            </div>
                            <div className={section2Styles.flexCol9__cell}>
                              <div className={section2Styles.group14}>
                                <h4 className={section2Styles.highlights9}>
                                  Leveraging paid social media may be a better tactic for your business, depending on your budget and
                                  audience type. Paying for social media advertisements and exposure is a guaranteed way to get content in
                                  front of your audience, without having to build up a network of loyal followers.  <br />
                                  Sponsored posts on Facebook, Twitter, or Instagram get your content in front of the right eyes, however,
                                  Facebook Lead Ads allows you to advertise on social media and gather customer information such as their
                                  email addresses and names. This information can make the difference between a follower and a lead, so if
                                  your business is looking to build its list, this might be the acquisition method for you to consider.
                                  <br />
                                </h4>
                                <h3 className={section2Styles.subtitle25}>Paid social media</h3>
                                
                                <div className={section2Styles.rect41} />
                              </div>
                            </div>
                            <div className={section2Styles.flexCol9__cell}>
                              <div className={section2Styles.group15}>
                                <h4 className={section2Styles.highlights10}>
                                  Email marketing might seem like an outdated acquisition method, but it is a highly effective way to stay
                                  in front of your customers and promote quality content, product information, and discounts and events.
                                  Email is also a great way to simply connect with your audience, whether by sending a happy birthday
                                  email or a valuable promotional email.
                                  <br />
                                  Email marketing provides a direct line into your consumer’s inbox, unlike social media, search, or
                                  content marketing.  <br />
                                </h4>
                                <h3 className={section2Styles.subtitle26}>Email</h3>
                                
                                <div className={section2Styles.rect42} />
                              </div>
                            </div>
                            <div className={section2Styles.flexCol9__cell}>
                              <div className={section2Styles.group16}>
                                <h4 className={section2Styles.highlights11}>
                                  Customer referrals is one of the most powerful ways to acquire new customers.  <br />
                                  Creating a referral program is a guaranteed way to bring in new business through your customers.
                                  Offering incentives, be it credit, physical gifts, or monetary rewards is usually the best way to
                                  motivate a customer to share about your company.  <br />
                                  While a structured, incentive-based program typically works best for Business to consumer (B2C)
                                  companies, Business to Business (B2B) companies might have better luck asking for direct referrals from
                                  their customers. Give your customers a reason to want to refer you make them so delighted by your
                                  business that they cannot help but refer others.
                                  <br />
                                </h4>
                                <h3 className={section2Styles.subtitle27}>Referrals</h3>
                                
                                <div className={section2Styles.rect43} />
                              </div>
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol__cell}>
                            <div className={section2Styles.group17}>
                              <div className={section2Styles.group18}>
                                <h4 className={section2Styles.highlights12}>
                                  Events e.g. conferences, webinars, and trade shows, are a fantastic way to connect with interested
                                  prospects and acquire new customers. These days, a lot of events are hosted online which can make
                                  customer acquisition easier, as the event attendees must register with their email addresses to attend
                                  (whether they pay or not). This information is not always easy or natural to capture when meeting
                                  prospects in person. Utilize this email information to build relationships with prospective customers as
                                  discussed in the email section
                                </h4>
                                <h3 className={section2Styles.subtitle28}>Events</h3>
                                <div className={section2Styles.rect44} />
                              </div>
                  
                              
                            </div>
                          </div>
                          <div className={section2Styles.flexCol__cell}>
                            <div className={section2Styles.group19}>
                              <h4 className={section2Styles.highlights13}>
                                Traditional advertising channels such as Television (TV), radio, and print media are all great advertising
                                options for local businesses, as well as large businesses that have the budget for launching multi-city
                                campaigns. Traditional advertising can be a powerful customer acquisition method if you properly target
                                your adverts and carefully consider the audiences that each publisher caters to.
                                <br />
                                You can typically get audience details from the publisher’s media buying guide. There, they will also
                                delineate the accepted dimensions, as well as any other requirements for publishing or running your
                                advertisement.
                                <br />
                                <br />
                              </h4>
                              <h3 className={section2Styles.subtitle29}>Traditional Advertising</h3>
                              
                              <div className={section2Styles.rect45} />
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol11}>
                            <div className={section2Styles.flexCol12}>
                              <h1 className={section2Styles.title3}>Customer Satisfaction</h1>
                              <h4 className={section2Styles.highlights14}>
                                Customer Satisfaction is a metric that refers to the degree to which the customer expectations have been
                                met. If you “meet” their expectations they will be satisfied i.e., your product or service has done what
                                it said it would do. If you “exceed” their expectations, they will be happier, your product or service has
                                given them more than they expected. If your product or services fails to meet their expectation they will
                                be displeased. Businesses aim for the first two scenarios and try to minimize or eliminate the last.{' '}
                                <br />
                                <br />
                                <br />
                              </h4>
                            </div>
                  
                            <div className={section2Styles.flexCol13}>
                              <h1 className={section2Styles.title4}>
                                Customer Satisfaction can affect your business in positive and negative ways
                              </h1>
                              <h3 className={section2Styles.subtitle210}>
                                1. Positive ways
                                <br />
                                2. Negative ways
                                <br />
                              </h3>
                            </div>
                  
                            <div className={section2Styles.flexCol14}>
                              <h1 className={section2Styles.title3}>Positive ways </h1>
                              <h4 className={section2Styles.highlights15}>
                                Satisfied customers are loyal customers who will continue to patronize your business and help it grow.
                                They are also more likely to post positive reviews and tell others about your product(s)/service(s). As it
                                is cheaper to retain customers than to acquire new ones, satisfied customers also increase your revenue
                                (costs you less to retain them and so you make more money).{' '}
                              </h4>
                            </div>
                  
                            <div className={section2Styles.flexCol14}>
                              <h1 className={section2Styles.title3}>Negative ways</h1>
                              <h4 className={section2Styles.highlights16}>
                                Unsatisfied or dissatisfied customers can cause you to spend more money chasing new clients. Negative
                                reviews and publicity can hurt your brand image which in the long run will affect the number of customers
                                you can attract which will impact your business negatively.  <br />
                              </h4>
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol15}>
                            <div className={section2Styles.flexCol10}>
                              <h1 className={section2Styles.title3}>Measuring Customer Satisfaction</h1>
                              <h4 className={section2Styles.highlights17}>
                                Customer Satisfaction can be measured in several ways, below, we review three ways, i.e., using the
                              </h4>
                            </div>
                  
                            <div className={section2Styles.flexCol15__cell}>
                              <div className={section2Styles.group20}>
                                <h3 className={section2Styles.subtitle211}>Customer Satisfaction Score (CSAT Score)</h3>
                                
                                <div className={section2Styles.rect46} />
                                <h4 className={section2Styles.highlights18}>
                                  CSAT is determined by surveying your customers and asking them to rate their experience with your
                                  business in a binary (from 1 to 5) or non-binary (Highly Dissatisfied to Highly Satisfied) scale. After
                                  collecting all the responses, the average scores are calculated to find your overall CSAT score.
                                  <br />
                                  <br />
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol15__cell}>
                              <div className={section2Styles.group21}>
                                <h3 className={section2Styles.subtitle212}>Customer Effort Score (CES)</h3>
                                
                                <div className={section2Styles.rect47} />
                                <h4 className={section2Styles.highlights19}>
                                  Customer Effort Score (CES) is similar to the CSAT score, but instead of asking how satisfied the
                                  customer was, you ask them to gauge the ease of their experience. Satisfaction is still the variable
                                  being measured, but this way you are gauging the users effort with the assumption being that the easier
                                  a task is, the better the experience will be.  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol15__cell}>
                              <div className={section2Styles.group22}>
                                <h3 className={section2Styles.subtitle213}>Net Promoter Score (NPS)</h3>
                                
                                <div className={section2Styles.rect48} />
                                <h4 className={section2Styles.highlights3_box}>
                                  <span className={section2Styles.highlights3}>
                                    <span className={section2Styles.highlights3_span0}>
                                      Net Promoter Score (NPS) measures how likely your customers are to recommend your business to others
                                      based on their experience. This score is calculated by asking customers how likely they are to
                                      recommend your business to a friend or colleague on a scale of 1 to 10.
                                      <br />
                                      Customers are then categorized based on their answers.  <br />
                                      Promoters answer between 9-10.  <br />
                                      Passives answer between 7-8.  <br />
                                      Detractors answer 6 and lower.
                                      <br />
                                      <br />
                                      You will count the number of customers in each category and run this formula:
                                      <br />
                                    </span>
                                    <span className={section2Styles.highlights3_span1}>
                                      NPS = Number of Promoters - Number of Detractors / Total Number of Responses
                                      <br />
                                    </span>
                                    <span className={section2Styles.highlights3_span2}>
                                      <br />A high NPS indicates a large number of happy customers who will gladly recommend your business
                                      to others. A low score might indicate issues in customer satisfaction across your organization.
                                      <br />
                                    </span>
                                  </span>
                                </h4>
                                <h4 className={section2Styles.highlights20}>
                                  You can use more than one methodology to measure your customer satisfaction since they all measure
                                  something different. For example, a customer that has had several continuous, negative CSAT scores and
                                  is also a detractor on NPS would be an immediate at-risk customer. A customer with positive CSAT and a
                                  promoter on NPS are potentially the best source of advocacy and candidates to cross-sell or upsell since
                                  they already have seen the value in their interactions with your product and business.
                                  <br />
                                  <br />
                                  Surveying too often will result in low response rates, so it is recommended that you send your customers
                                  a customer satisfaction (NPS) survey seven days after they signup, 30 days after they take their first
                                  survey, and every 90 days during their customer lifecycle.
                                  <br />
                                </h4>
                              </div>
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol16}>
                            <h1 className={section2Styles.title5}>Where to find customers</h1>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group23}>
                                <h3 className={section2Styles.subtitle214}>Ask for referrals</h3>
                                
                                <div className={section2Styles.rect49} />
                                <h4 className={section2Styles.highlights18}>
                                  Referrals are one of the best ways to get new customers. Implement a system for actively soliciting
                                  referrals from your satisfied customers. Build referral-generating activity into the sales process. For
                                  example, send a follow-up email asking for a referral after a customer has received their order from
                                  your e-commerce site. Have your salespeople ask for referrals when they follow up with customers to
                                  answer questions after the sale.
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group24}>
                                <h3 className={section2Styles.subtitle215}>Network</h3>
                                
                                <div className={section2Styles.rect410} />
                                <h4 className={section2Styles.highlights18}>
                                  Generate word-of-mouth by participating in networking organizations and events relevant to your industry
                                  and your customers.  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group25}>
                                <h3 className={section2Styles.subtitle216}>Discounts and Offers</h3>
                                
                                <div className={section2Styles.rect411} />
                                <h4 className={section2Styles.highlights21}>
                                  Offer discounts and incentives for new customers and find ways to keep your old customers also satisfied
                                  with sales and discounts.
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group26}>
                                <h3 className={section2Styles.subtitle217}>Re-contact your old customers</h3>
                                
                                <div className={section2Styles.rect412} />
                                <h4 className={section2Styles.highlights18}>
                                  Go through your customer contacts on a regular basis and, after six months or a year without an
                                  interaction or purchase, reach out to dormant customers with a special offer via email, direct mail or
                                  phone.  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group27}>
                                <h3 className={section2Styles.subtitle217}>Improve your website</h3>
                                
                                <div className={section2Styles.rect412} />
                                <h4 className={section2Styles.highlights18}>
                                  These days, consumers and B2B buyers alike find new businesses primarily by searching online. That means
                                  your website must be attractive and well sourced to attract new customers. Revamp your website
                                  frequently to make sure that the design, content, graphics and SEO are all current.  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group28}>
                                <h3 className={section2Styles.subtitle217}>Partner with complementary businesses</h3>
                                
                                <div className={section2Styles.rect412} />
                                <h4 className={section2Styles.highlights22}>
                                  Partner with businesses that have a similar customer base, but are not your direct competition and
                                  strategize how you can target each other’s customers to drive new business to each other. E.g. a
                                  skincare company can consider partnering with a Spa.  <br />
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group29}>
                                <h3 className={section2Styles.subtitle218}>Promote your expertise</h3>
                                
                                <div className={section2Styles.rect413} />
                                <h4 className={section2Styles.highlights23}>
                                  Generate interest and gain new customers by publicizing your expertise in your industry. Participating
                                  in industry panel discussions or online webinars, speaking at industry events or to groups your target
                                  customers belong to, or holding educational sessions and/or workshops will impress potential new
                                  customers with your subject expertise.  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group30}>
                                <h3 className={section2Styles.subtitle218}>Use online reviews to your advantage</h3>
                                
                                <div className={section2Styles.rect413} />
                                <h4 className={section2Styles.highlights23}>
                                  Does your business get online reviews from customers? Cultivate your reviews and make the most of them.
                                  Social proof, (i.e, the idea that people copy the actions of others in an attempt to emulate behavior in
                                  certain situations) is powerful, and new customers are more likely to give your business a try if they
                                  see others praising it.
                                  <br />
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group31}>
                                <h3 className={section2Styles.subtitle219}>Participate in community events</h3>
                                
                                <div className={section2Styles.rect414} />
                                <h4 className={section2Styles.highlights24}>
                                  Raise your profile in your community by taking part in charity events and organizations.{' '}
                                </h4>
                              </div>
                            </div>
                            <div className={section2Styles.flexCol16__cell}>
                              <div className={section2Styles.group32}>
                                <h3 className={section2Styles.subtitle217}>Bring a friend</h3>
                                
                                <div className={section2Styles.rect412} />
                                <h4 className={section2Styles.highlights18}>
                                  Offer 2-for-1, “buy one, get one free” or “bring a friend” deals to get your “regulars” to introduce new
                                  customers to your business.  <br />
                                  <br />
                                </h4>
                              </div>
                            </div>
                          </div>
                  
                          <div className={section2Styles.flexCol17}>
                            <h1 className={section2Styles.title3}>Who is NOT your customer</h1>
                            <h4 className={section2Styles.highlights3_box}>
                              <span className={section2Styles.highlights3}>
                                <span className={section2Styles.highlights3_span0}>
                                  Everyone is not your customer, and you need to define your customers wisely. The strategic choice of
                                  primary customer with special emphasis on “primary” defines your business.
                                  <br />
                                  <br />
                                  Different categories of customers can be defined as follows:
                                  <br />  <br />
                                  A. Age Group — Some products are fit for a particular age group like 0 to 1, or 1 to 5, 5 to 18, above
                                  18, above 35 or something. Based on a product, the age group can be defined. This differentiation is
                                  commonly seen in the clothing industry, entertainment industry, food industry.
                                  <br />
                                  <br />
                                  B. Demographics — City wise, region wise, culture-wise, the product and use can be different.
                                  Accordingly, your target customers will be different.  <br />
                                  There can be more criteria as Monthly income, annual turnover, education background, Society location,
                                  Sex, Culture, etc. When you are preparing the sales plan, all these points must be brainstormed, and
                                  your target customer must be selected accordingly.
                                  <br />
                                  <br />
                                  Finding your
                                </span>
                                <span className={section2Styles.highlights3_span1}> SOM</span>
                                <span className={section2Styles.highlights3_span2}>
                                  {' '}
                                  is a brilliant first step for a new business, or anyone launching something previously unheard of. By
                                  carefully considering a modest, specific target market you can nurture these people into strong brand
                                  advocates, who will form the necessary foundations for you to grow bigger and more diverse in your
                                  offering
                                  <br />
                                </span>
                              </span>
                            </h4>
                          </div>
                  
                          <div className={section2Styles.flexCol18}>
                            <h1 className={section2Styles.title6}>Quick Quiz</h1>
                            <h3 className={section2Styles.subtitle3_box}>
                              <span className={section2Styles.subtitle3}>
                                <span className={section2Styles.subtitle3_span0}>
                                  1. What is a customer need?
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span1}>
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span2}>
                                  {' '}
                                     A customer need is the need or requirement that motivates your customer to purchase your
                                  product/service.
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span3}>
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span4}>
                                  {' '}
                                     Your customer contact information
                                  <br />
                                  <br />
                                  <br />    Your packaging
                                  <br />
                                  <br />
                                  <br />    What your friends say about your product
                                  <br />
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span5}>
                                  <br />
                                  2. What are the five main customer needs?
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span6}>
                                  {' '}
                                      Ease, Price point, Transparency, Sustainability, Availability
                                  <br />
                                  <br />
                                  <br />     Control and options, Convenience, Availability, Price points, Finance
                                  <br />
                                  <br />
                                  <br />     Convenience, Speed, Ease, Control and options
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span7}>
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span8}>
                                  {' '}
                                       Price points, Convenience, Sustainability, Transparency, Control and options
                                  <br />
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span9}>
                                  3. Price buyers are:
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span10}>
                                  {' '}
                                       Customers who want your products/services at the lowest possible price.
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span11}>
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span12}>
                                  {' '}
                                       Customers who want to trust and have dependable relationships with your business
                                  <br />
                                  <br />
                                  <br />      Customers are your friends and family
                                  <br />
                                  <br />
                                  <br />    Customers who understand value and want your business to be able to provide the most value in
                                  their relations.
                                  <br />
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span13}>
                                  4. Value buyers are:
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span14}>
                                  <br />     Customers who want your products/services at the lowest possible price.
                                  <br />
                                  <br />
                                  <br />     Customers who want to trust and have dependable relationships with your business
                                  <br />
                                  <br />
                                  <br />     Customers are your friends and family
                                  <br />
                                  <br />
                                  <br />   Customers who understand value and want your business to be able to provide the most value in
                                  their relations.
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span15}>
                                  5. Relationship buyers are:
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span16}>
                                  <br />     Customers who want your products/services at the lowest possible price.
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span17}>
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span18}>
                                  {' '}
                                      Customers who want to trust and have dependable relationships with your business
                                  <br />
                                  <br />
                                  <br />     Customers are your friends and family
                                  <br />
                                  <br />
                                  <br />      Customers who understand value and want your business to be able to provide the most value
                                  in their relations.
                                  <br />
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span19}>
                                  <br />
                                  6. Where can a business owner go to find customers?
                                  <br />
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span20}>
                                  {' '}
                                       Referrals, Networking, Discounts/incentives, old customers, bring a friend specials
                                  <br />
                                  <br />
                                  <br />      Website, Partnerships, Promotions, Online Reviews, Community event
                                  <br />
                                </span>
                                <span className={section2Styles.subtitle3_span21}>
                                  <br />
                                  <br />     a and b<br />
                                </span>
                                <span className={section2Styles.subtitle3_span22}>
                                  <br />
                                  <br />     none of the above
                                  <br />
                                </span>
                              </span>
                            </h3>
                          </div>
                  
                          <div className={section2Styles.content_box3}>
                            <div className={section2Styles.flexCol19}>
                              <h1 className={section2Styles.title11_box}>
                                <span className={section2Styles.title11}>
                                  <span className={section2Styles.title11_span0}>
                                    You have completed the Lesson on <br />
                                  </span>
                                  <span className={section2Styles.title11_span1}>
                                    Customers Lesson Plan
                                    <br />
                                  </span>
                                  <span className={section2Styles.title11_span2}>
                                    Review your input and print if you are happy with the results.
                                    <br />
                                  </span>
                                </span>
                              </h1>
                  
                              <div className={section2Styles.box}><a href="lwa-plan-two">
                                <h3 className={section2Styles.subtitle4}>Return</h3></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanThree;