import React, { Fragment } from "react";
import classNames from "classnames";
import axios from 'axios';
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import { 
  Input,
  Switch,
  Select,
  Popover,
  Tooltip
} from 'antd';
import 'antd/dist/antd.css';

class JobSeekerProfile extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userinfo: AuthService.getAuth().user,
      isLoading: false,
      isRequesting: false,
      close: false,
      profileForm: {
        user_type: '',
        full_name: '',
        username: '',
        profile_pic: '',
        country: '',
        location: '',
        education_history: [],
        area_of_interest: '',
        value_statement: '',
        work_experience: [],
        expertise: '',
        experience: '',
        awards: null,
        certifications: null,
        availability_status: false,
        availability_type: ''
      },
      profileFormErrors: {
        full_name: '',
        username: '',
        profile_pic: '',
        country: '',
        location: '',
        education_history: '',
        area_of_interest: '',
        value_statement: '',
        work_experience: '',
        expertise: '',
        experience: '',
        awards: '',
        certifications: '',
        availability_status: '',
        availability_type: ''
      },
      errorMessage: '',
      successMessage: ''
    }
  }

  updateProfileInfo(event, name) {
    var profileForm = this.state.profileForm;
    let value;

    value = event;

    profileForm[name] = value;
    this.setState({
			...this.state,
      profileForm: profileForm
    });
	}

  submitProfileForm () {
    let profileFormErrors = {
      full_name: '',
      username: '',
      profile_pic: '',
      country: '',
      location: '',
      education_history: '',
      area_of_interest: '',
      value_statement: '',
      work_experience: '',
      expertise: '',
      experience: '',
      awards: '',
      certifications: '',
      availability_status: '',
      availability_type: ''
    };

    let profileForm = this.state.profileForm;
    if(profileForm.full_name.length === 0){
      profileFormErrors.full_name = 'Input required';
      
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        profileFormErrors: profileFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

    if(this.state.profileForm.availability_status){
      if(this.state.profileForm.availability_type == null || this.state.profileForm.availability_type?.length === 0){
        profileFormErrors.availability_type = 'Enter availability type';
        
        this.setState({
          ...this.state,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
          profileFormErrors: profileFormErrors,
        });
  
        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }
      
      
    }

    // validate education history    
    if(profileForm.education_history.length > 0){
      for(let i = 0; i < profileForm.education_history.length; i++){
        if(profileForm.education_history[i].school_name.length === 0){
          profileFormErrors.education_history = 'One of your education history is missing a school name';
        }

        if(profileForm.education_history[i].degree.length === 0){
          profileFormErrors.education_history = 'One of your education history is missing a degree';
        }

        if(profileForm.education_history[i].start_year.length === 0){
          profileFormErrors.education_history = 'One of your education history is missing a start year';
        }

        if(profileForm.education_history[i].end_year.length === 0){
          profileFormErrors.education_history = 'One of your education history is missing a end year';
        }
      }

      if(profileFormErrors.education_history.length > 0){
        this.setState({
          ...this.state,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
          profileFormErrors: profileFormErrors,
        });
  
        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }
    }

    // validate work history    
    if(profileForm.work_experience.length > 0){
      for(let i = 0; i < profileForm.work_experience.length; i++){
        if(profileForm.work_experience[i].company_name.length === 0){
          profileFormErrors.work_experience = 'One of your work history is missing a company name';
        }

        if(profileForm.work_experience[i].position.length === 0){
          profileFormErrors.work_experience = 'One of your work history is missing a position';
        }

        if(profileForm.work_experience[i].location.length === 0){
          profileFormErrors.work_experience = 'One of your work history is missing a location';
        }

        if(profileForm.work_experience[i].start_year.length === 0){
          profileFormErrors.work_experience = 'One of your work history is missing a start year';
        }

        if(profileForm.work_experience[i].end_year.length === 0){
          profileFormErrors.work_experience = 'One of your work history is missing a end year';
        }
      }

      if(profileFormErrors.work_experience.length > 0){
        this.setState({
          ...this.state,
          errorMessage: 'Make sure the right information has been provided for all required fields.',
          profileFormErrors: profileFormErrors,
        });
  
        window.scrollTo({top: 0, behavior: 'smooth'});
        return;
      }
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      profileFormErrors: profileFormErrors
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/update-profile/job-seeker", {
      ...this.state.profileForm
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        isRequesting: false,
        successMessage: "Your availability status has been updated successfully."
      })

      window.modalShow('success-modal');

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  componentDidMount () {
    this.setState({
      ...this.state,
      isLoading: false,
      profileForm: {
        full_name: this.props.profileInfo.full_name,
        username: this.props.profileInfo.username,
        profile_pic: this.props.profileInfo.profile_pic,
        country: this.props.profileInfo.country,
        location: this.props.profileInfo.location,
        education_history: this.props.profileInfo.education_history?.length > 0 ? this.props.profileInfo.work_experience : [],
        area_of_interest: this.props.profileInfo.area_of_interest,
        value_statement: this.props.profileInfo.value_statement,
        work_experience: this.props.profileInfo.work_experience?.length > 0 ? this.props.profileInfo.work_experience : [],
        expertise: this.props.profileInfo.expertise,
        experience: this.props.profileInfo.experience,
        awards: this.props.profileInfo.awards,
        certifications: this.props.profileInfo.certifications,
        availability_status: this.props.profileInfo.availability_status === true ? true : false,
        availability_type: this.props.profileInfo.availability_type,
        cv: this.props.profileInfo.cv    
      }
    })
  }

  render(){

    return (
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-xxl">
          <div className="d-flex flex-column flex-lg-row px-lg-20">
            <div className="d-flex flex-column flex-lg-row-auto w-lg-325px mb-8">
              <div className="bg-muted card mb-5 mb-xl-8">
                <div className="card-body pt-15 px-0">
                  <div className="d-flex flex-column text-center mb-9 px-9">
                    <div className="symbol symbol-200px mb-4">
                      
                      <img
                        src={this.props.profileInfo.profile_pic}
                        className="avatar"
                        alt="" onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }}
                      />
                    </div>
                    <div className="text-center">                      
                      <span className="text-gray-800 fw-bold text-hover-primary fs-4">
                        {this.props.profileInfo.full_name}
                      </span>
                      <span className="text-gray-600 d-block fw-semibold text-capitalize">
                      {this.props.profileInfo.user_type}
                      </span>
                    </div>
                  </div>
                  
                  <div className="mx-10">
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-user-tie fs-2x"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 fs-7 fw-bold lh-0">
                          Role
                        </a>
                        <span className="text-gray-800 d-block fs-7">{this.props.profileInfo.role}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i class="las la-map-marker-alt fs-2x"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 fs-7 fw-bold lh-0">
                          Location
                        </a>
                        <span className="text-gray-800 d-block fs-7">{this.props.profileInfo.location}, {this.props.profileInfo.country}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-info fs-2x"></i>
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 fs-7 fw-bold lh-0">
                          Value Statement
                        </a>
                        <span className="text-gray-800 d-block fs-7">
                          {this.props.profileInfo.value_statement}
                        </span>
                      </div>
                    </div>

                    {
                      this.props.owner ?  
                      <Popover placement="right" 
                        open={this.state.close}
                        onOpenChange={(state) => {
                          this.setState({
                            ...this.state,
                            close: state
                          })
                        }}
                        content={<div className="w-200px">
                          <div className="fv-row mb-3">
                            <label className="form-label mb-2 fw-light">
                              Looking for work
                            </label>
                            <Switch className="d-block" onChange={(e) => {this.updateProfileInfo(e, 'availability_status')}} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.profileForm.availability_status} />
                          </div>
                          {
                            this.state.profileForm.availability_status &&
                            <>
                              <div className="fv-row mb-3">
                                <label className="form-label mb-2 fw-light">
                                  Availability Type
                                </label>
                                <Select size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={this.state.profileForm.availability_type}
                                  onChange={(value) => {this.updateProfileInfo(value, 'availability_type')}}
                                  options={[
                                    {
                                      value: 'Fulltime',
                                      label: 'Fulltime',
                                    },
                                    {
                                      value: 'Part-time',
                                      label: 'Part-time',
                                    },
                                    {
                                      value: 'Contract',
                                      label: 'Contract',
                                    },
                                    {
                                      value: 'Volunteer',
                                      label: 'Volunteer',
                                    }
                                  ]}
                                />
                                {
                                  this.state.profileFormErrors.availability_type.length > 0 && 
                                  <div className="invalid-feedback">{this.state.profileFormErrors.availability_type}</div>
                                }
                              </div>
                            </>
                          }
                          <hr className="text-gray-400 mb-10"/>
                          <div className="d-flex">
                            {
                              this.state.isRequesting ?
                              <button disabled className="btn btn-sm btn-primary me-2">
                                <span>
                                  Updating...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                              </button>
                              :
                              <button className="btn btn-sm btn-primary me-2" onClick={() => {this.submitProfileForm()}}>Save</button>
                            }
                            
                            <button disabled={this.state.isRequesting} className="btn btn-sm btn-light" onClick={() => {
                              this.setState({
                                ...this.state,
                                close: false
                              })
                            }}>Close</button>
                          </div>
                        </div>} title="Edit Availability" trigger="click">                      
                        <div className="d-flex cursor-pointer align-items-center mb-5">
                          <span className="symbol symbol-20px me-3">
                            <span className="symbol-label bg-white">
                              {
                                this.state.profileForm.availability_status ?
                                <i className="las la-check-circle fs-2x text-primary"></i>
                                :
                                <i className="las la-ban fs-2x"></i>
                              }
                            </span>
                          </span>
                          <div className="flex-grow-1">
                            <span className="text-gray-800 fs-7 fw-bold lh-0">
                              Status
                            </span>
                            {
                              this.props.profileInfo.availability_status ?
                              <><span className="text-gray-800 d-block fs-7">
                                Looking for new opportunities
                              </span>
                              <span className="text-primary d-block">{this.props.profileInfo.availability_type}</span></>
                              :
                              <span className="text-gray-800 d-block fs-7">
                                Not looking for new opportunities
                              </span>
                            }
                            <a className="ms-auto text-primary fw-semibold fs-8">Click to update</a>    
                          </div>
                        </div>
                      </Popover>
                      :
                      <div className="d-flex align-items-center mb-5">
                        <span className="symbol symbol-20px me-3">
                          <span className="symbol-label bg-white">
                            {
                              this.props.profileInfo.accepting_applicants ?
                              <i className="las la-check-circle fs-2x text-primary"></i>
                              :
                              <i className="las la-ban fs-2x"></i>

                            }
                          </span>
                        </span>
                        <div className="flex-grow-1">
                          <span className="text-gray-800 fs-7 fw-bold lh-0">
                            Status
                          </span>
                            {
                              this.props.profileInfo.availability_status ?
                              <><span className="text-gray-800 d-block fs-7">
                                Looking for new opportunities
                              </span>
                              <span className="text-primary d-block">{this.props.profileInfo.availability_type}</span></>
                              :
                              <span className="text-gray-800 d-block fs-7">
                                Not looking for new opportunities
                              </span>
                            }
                          
                        </div>
                      </div>
                    }

                  </div>
                  {
                    this.props.owner === true && 
                    <div className="d-flex align-items-center w-100 flex-column px-10 pt-8 border-top">
                      <div className="d-flex justify-content-between w-100 mt-auto mb-2 mx-3">                                    
                        <span className="fw-semibold fs-6 text-gray-400">
                          Profile Completion
                        </span>
                        <span className="fw-bold fs-6">{this.props.profileInfo.profile_completion}%</span>
                      </div>
                      <div className="h-5px mx-3 w-100 bg-light mb-3">
                        <div
                          className="bg-success rounded h-5px"
                          role="progressbar"
                          style={{ width: `${this.props.profileInfo.profile_completion}%` }}
                          aria-valuenow={this.props.profileInfo.profile_completion}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>

              {
                this.props.profileInfo.cv.length > 0 &&
                <div className="card mb-5 mb-xl-8">
                  <a href={this.props.profileInfo.cv} target="_blank"><div className="card-header border-0 py-5">
                    <div className="d-flex align-items-center">
                      <span className="symbol symbol-20px me-3">
                        <span className="symbol-label bg-white">
                          <i className="las la-file-alt fs-2x" />
                        </span>
                      </span>
                      <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 fs-5 fw-bold lh-0">
                          Resume / CV
                        </a>
                        <span className="text-gray-800 d-block fs-7">Click to view</span>
                      </div>
                    </div>
                  </div></a>
                </div>
              }

              {
                this.props.owner === true && this.props.profileInfo.connections.connections.length > 0 && 
                <div className="card mb-5 mb-xl-8">
                  <a href="/my-connections"><div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark fs-5">
                        {this.props.profileInfo.connections.connections.length} {this.props.profileInfo.connections.connections.length > 1 ? `Connections` : `Connection`}
                      </span>
                      
                    </h3>
                  </div>
                  <div className="card-body pt-0">
                    <div className="symbol-group symbol-hover flex-nowrap mb-3">
                      {
                        this.props.profileInfo.connections.connections.map((data, index) => {
                          let avatar = <></>;
                          if(index < 4){
                            avatar = <div key={index} className="symbol symbol-40px symbol-circle">
                                        {
                                          data.profile_pic === null ?
                                          <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} /></Tooltip>
                                          :
                                          <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} /></Tooltip>
                                        } 
                                      </div>
                          }

                          return (
                            <>{avatar}</>
                          )
                        })
                      }
                      {
                        this.props.profileInfo.connections.connections.length > 4 &&
                        <div className="symbol symbol-40px symbol-circle">
                          <Tooltip placement="bottom" title="Click to view all"><span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                            +{this.props.profileInfo.connections.connections.length - 4}
                          </span></Tooltip>
                        </div>
                      }
                    </div>
                    <span className="text-muted mt-1 fw-semibold fs-7">
                      Click to view
                    </span>
                  </div></a>
                </div>
              }

            </div>
            <div className="w-100 flex-lg-row-fluid mx-lg-10">
              <div className="card mb-5 mb-xl-10">
                <div className="collapse show">
                  <div className="card-body border-top p-9">
                    <div className="border-bottom-dashed border-gray-200 pb-9">
                      <p className="mb-0 fs-6 text-gray-600">
                        Work Experience
                      </p>
                      {
                        this.props.profileInfo.work_experience?.length > 0 &&
                        <div className="timeline pt-10">
                          {
                            this.props.profileInfo.work_experience?.map((data, index) => {
                              return (
                                <div className="timeline-item">
                                  <div className="timeline-line w-40px" />
                                  <div className="timeline-icon symbol symbol-circle symbol-40px">
                                    <div className="symbol-label bg-light">
                                      <i className="las la-industry fs-2x"></i>
                                    </div>
                                  </div>
                                  <div className="timeline-content mb-10 mt-n2">
                                    <div className="overflow-auto pe-3">
                                      <div className="fs-5 mb-1">
                                        <span className="fw-semibold">{data.company_name}</span> - <span>{data.location}</span>
                                      </div>
                                      <span className="d-block fs-5 text-primary">{data.position}</span>
                                      <span className="d-block fs-7">{data.start_year} - {data.end_year}</span>                              
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }                          
                        </div>
                      }
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Industry of Interest
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.area_of_interest}
                      </div>
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Domain of Expertise
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.expertise}
                      </div>
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Awards / Honors
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.awards}
                      </div>
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Certifications
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.certifications}
                      </div>
                    </div>
                    <div className="border-bottom-dashed border-gray-200 pb-9 mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Education
                      </p>
                      {
                        this.props.profileInfo.education_history?.length > 0 &&
                        <div className="timeline pt-10">
                          {
                            this.props.profileInfo.education_history?.map((data, index) => {
                              return (
                                <div className="timeline-item" key={index}>
                                  <div className="timeline-line w-40px" />
                                  <div className="timeline-icon symbol symbol-circle symbol-40px">
                                    <div className="symbol-label bg-light">
                                      <i className="las la-graduation-cap fs-2x"></i>
                                    </div>
                                  </div>
                                  <div className="timeline-content mb-10 mt-n2">
                                    <div className="overflow-auto pe-3">
                                      <div className="fs-5 mb-1">
                                        <span className="fw-semibold">{data.school_name}</span>
                                      </div>
                                      <span className="d-block fs-5 text-primary">{data.degree}</span>
                                      <span className="d-block fs-7">{data.start_year} - {data.end_year}</span>                              
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }                          
                        </div>
                      }
                    </div>
                    <div className="mt-10">
                      <p className="mb-0 fs-6 text-gray-600">
                        Description of Experience
                      </p>
                      <div className="text-primary fw-bold fs-5">
                        {this.props.profileInfo.experience}
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* success modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal my-5">
                  <div className="timeline-icon symbol symbol-circle symbol-90px">
                    <div className="symbol-label bg-light-success">
                      <i className="las la-check fs-3qx text-success"></i>
                    </div>
                  </div>
                  <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                  
                  <div className="nk-modal-text">
                    <p className="lead">{this.state.successMessage}</p>
                  </div>
                  <div className="nk-modal-action mt-10">
                    <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}

        {/* error modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal my-5">
                  <div className="timeline-icon symbol symbol-circle symbol-90px">
                    <div className="symbol-label bg-light-danger">
                      <i className="las la-check fs-3qx text-danger"></i>
                    </div>
                  </div>
                  <h3 className="nk-modal-title mt-10">Error</h3>
                  
                  <div className="nk-modal-text">
                    <p className="lead">{this.state.errorMessage}</p>
                  </div>
                  <div className="nk-modal-action mt-10">
                    <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}
      </div>
    )
  }
}

export default JobSeekerProfile;