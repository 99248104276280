import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/UntitledPage_section1.module.scss';
import section2Styles from '../scss/UntitledPage_section2.module.scss';
import styles from '../scss/UntitledPage.module.scss';

class LearnWithAWA extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true
    }
	}

  componentDidMount () {
    window.KTComponents.init();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {/* learn with awa component goes here */
                  <section className={section1Styles.section1}>
                    <div className={section1Styles.wrapper} style={{ '--src': `url(${require('../../assets/lwa/7a081639bed440fee62ce7cc3c6e6f1c.png')})` }}>
                      <h1 className={section1Styles.hero_title1_box}>
                        <div className={section1Styles.hero_title1}>
                          <span className={section1Styles.hero_title1_span0}>Learn with 
                          <span className={section1Styles.hero_title1_span1}>AWA</span>
                          </span>
                        </div>
                      </h1>
                    </div>
                    <h1 className={section1Styles.hero_title_box}>
                      <div className={section1Styles.hero_title}>
                        <span className={section1Styles.hero_title_span0}>
                          Welcome to your resource library!
                          <br />
                        </span>
                        <span className={section1Styles.hero_title_span1}>
                          These specific courses are designed to help you learn the technical skills needed to structure and enhance
                          your business. The expected outcome from each course is to produce a tangible output. Success in these
                          courses will help you to better understand how to strategically position your business for growth so that
                          you fully understand the inner workings of your own business.
                        </span>
                      </div>
                    </h1>
                    <div className={section1Styles.section1__item}>
                      <a className={section1Styles.btn} href="lwa-course-plan">
                        <span className={section1Styles.btn__text}>Get Started</span>
                      </a>
                    </div>
                  </section>
                  }
                  {
                  <section className={section2Styles.section2}>
                    <div className={section2Styles.flexRow}>
                      
                      <div className={section2Styles.flexRow__item1}>
                        <div className={section2Styles.flexRow1}>
                          <div
                            className={section2Styles.wrapper3}
                            style={{ '--src': `url(${require('../../assets/lwa/b0315e2dd218ce5b847025b922b3e899.png')})` }}>
                            <h1 className={section2Styles.title_box}>
                              <div className={section2Styles.title}>
                                <span className={section2Styles.title_span0}>Idea Stage</span>
                                <span className={section2Styles.title_span1}> </span>
                                <span className={section2Styles.title_span2}>Entrepreneur</span>
                              </div>
                            </h1>
                          <a className={section2Styles.icon} href="lwa-plan-two">
                            <img
                              className={section2Styles.icona}
                              src={require('../../assets/lwa/9ad60ff50d9175cf78c27c122b423dcf.png')}
                              alt="alt text"
                            /> </a>                       
                          </div>
                          <div className={section2Styles.flexRow1__spacer} />
                          <div
                            className={section2Styles.wrapper3}
                            style={{ '--src': `url(${require('../../assets/lwa/lwaladyearly.png')})` }}>
                            <h1 className={section2Styles.title_box}>
                              <div className={section2Styles.title}>
                                <span className={section2Styles.title_span0}>Early Stage</span>
                                <span className={section2Styles.title_span1}> </span>
                                <span className={section2Styles.title_span2}>Entrepreneur</span>
                              </div>
                            </h1>
                          <a className={section2Styles.icon} href="lwa-course-plan">
                            <img
                              className={section2Styles.icona}
                              src={require('../../assets/lwa/9ad60ff50d9175cf78c27c122b423dcf.png')}
                              alt="alt text"
                            /></a>
                          </div>
                          <div className={section2Styles.flexRow1__spacer} />
                          

                          <div
                            className={section2Styles.wrapper3}
                            style={{ '--src': `url(${require('../../assets/lwa/743a56968c58215768438481758aae92.png')})` }}>
                            <h1 className={section2Styles.title_box}>
                              <div className={section2Styles.title}>
                                <span className={section2Styles.title_span0}>Mature Stage</span>
                                <span className={section2Styles.title_span1}> </span>
                                <span className={section2Styles.title_span2}>Entrepreneur</span>
                              </div>
                            </h1>
                          <a className={section2Styles.icon} href="lwa-plan-three">
                            <img
                              className={section2Styles.icona}
                              src={require('../../assets/lwa/9ad60ff50d9175cf78c27c122b423dcf.png')}
                              alt="alt text"
                            /></a>
                          </div>
                          <div className={section2Styles.flexRow1__spacer} />


                          
                        </div>
                      </div>
                    </div>
                  </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LearnWithAWA;