import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select,
  Tooltip
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import PostCard from "./includes/PostCard";
import EntrepreneurProfile from "./includes/EntrepreneurProfile";
import InvestorProfile from "./includes/InvestorProfile";
import MentorProfile from "./includes/MentorProfile";
import JobSeekerProfile from "./includes/JobSeekerProfile";
import moment from 'moment';
const { Option } = Select;
const { Search, TextArea } = Input;

class Home extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isLoadingHighlight: true,
			isRequesting: false,
      viewProfile: false,
      viewProfileData: {},
      post_id: null,
      learn_with_awa: 0,
      user_id: '',
      home_feed: [
        // {
        //   full_name: "Jack",
        //   user_id: "sadf-dfgdsf-sdfg-asdA-DSF",
        //   user_type: "Entrepreneur",
        //   profile_pic: "/assets/media/avatars/300-2.jpg",
        //   post_id: "xcxz-sdfd-asdaszx-xzczx-zxs",
        //   post: "Long before you sit dow to put digital pen to paper Long before you sit dow to put digital pen to paper",
        //   post_date: moment("2022-12-13 13:14:01").format("YYYY-MM-DD H:mm:ss"),
        //   likes: "5",
        //   comments: [
        //     {
        //       full_name: "Narc",
        //       user_id: "sewr-sdffg-wer-dfg-werwe",
        //       user_type: "Investor",
        //       profile_pic: "/assets/media/avatars/300-7.jpg",
        //       comment_id: "weref-ertdsxf-ewtssdwasd-wersad",
        //       comment: "Digital pen to paper Long before you sit dow to put digital pen to",
        //       comment_date: moment("2022-12-14 13:14:01").format("YYYY-MM-DD H:mm:ss")
        //     }
        //   ],
        //   is_author: false,
        //   liked: true
        // },
        // {
        //   full_name: "Jack",
        //   user_id: "sadf-dfgdsf-sdfg-asdA-DSF",
        //   user_type: "Entrepreneur",
        //   profile_pic: "/assets/media/avatars/300-2.jpg",
        //   post_id: "xcxz-sdfd-asdaszx-xzczx-zxs",
        //   post: "Long before you sit dow to put digital pen to paper Long before you sit dow to put digital pen to paper",
        //   post_date: moment("2022-12-12 13:14:01").format("YYYY-MM-DD H:mm:ss"),
        //   likes: "5",
        //   comments: [
        //     {
        //       full_name: "Narc",
        //       user_id: "sewr-sdffg-wer-dfg-werwe",
        //       user_type: "Investor",
        //       profile_pic: "/assets/media/avatars/300-7.jpg",
        //       comment_id: "weref-ertdsxf-ewtssdwasd-wersad",
        //       comment: "Digital pen to paper Long before you sit dow to put digital pen to",
        //       comment_date: moment("2022-12-11 13:14:01").format("YYYY-MM-DD H:mm:ss")
        //     }
        //   ],
        //   is_author: true,
        //   liked: false
        // }
        
      ],
      people: [
        // {
        //   user_id: "dsfsdfsad-f-sad-fa-sdfasd-fa-sd-fsd",
        //   full_name: "",
        //   profile_pic: "",
        //   user_type: "",
        //   isFollowing: false
        // }
      ],
      topics: [
        // {
        //   post_id: "sdfsd-fsdfsad-sadsad",
        //   post: "asd fsdfasdfasdf as dfas dfa sfasdfdsaf sdsa fdas df  asddsafasdf afasdas",
        //   community_name: "",
        //   post_date: "Yestarday at 5:06 PM",

        // },
        // {
        //   post_id: "sdfsd-fsdfsad-sadsad",
        //   post: "asd fsdfasdfasdf as dfas dfa sfasdfdsaf sdsa fdas df  asddsafasdf afasdas",
        //   community_name: "",
        //   post_date: "Yestarday at 5:06 PM",

        // }
      ],
      pending_connections: [],
      grants: [],
      jobs: [],
      new_post: '',
      deletePostID: '',
      deletePostIndex: '',
      errorMessage: '',
      postErrorMessage: '',

    }
    
	}

  getHomeFeed () {
    this.setState({
      ...this.state,
      isLoading: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-posts", {
      post_id: this.state.post_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        user_id: responseInfo.data.user_id,
        home_feed: responseInfo.data.posts,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  getHighLights () {
    this.setState({
      ...this.state,
      isLoadingHighlight: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-highlights", {
      page: this.state.page
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      let people = [];
      if(responseInfo.data.people.length > 0){
        for(let i = 0; i < responseInfo.data.people.length; i++){
          let user = responseInfo.data.people[i];
          user.hasConnection = false;
          people.push(user);
        }
      }

      this.setState({
        ...this.state,
        isLoadingHighlight: false,
        people: people,
        pending_connections: responseInfo.data.pending_connections,
        topics: responseInfo.data.topics,
        grants: responseInfo.data.grants,
        jobs: responseInfo.data.jobs
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoadingHighlight: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        learn_with_awa: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          return;
        }

        let errorMessage = 'Error: Could not get Learn With AWA progress.';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
      }
    });
  }

  createPost () {
    this.setState({
      ...this.state,
      isRequesting: true,
      postErrorMessage: ''
    })

    if(this.state.new_post.length === 0){
      this.setState({
        ...this.state,
        isRequesting: false,
        postErrorMessage: 'Type a message you want to post.'
      })
      return;
    }

    axios.post(CONSTANTS.API_BASE_URL + "/create-post", {
      post: this.state.new_post
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let home_feed = this.state.home_feed;
      home_feed.unshift(responseInfo.data.post);
      this.setState({
        ...this.state,
        isRequesting: false,
        user_id: responseInfo.data.user_id,
        home_feed: home_feed,
        new_post: ''
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          postErrorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  createConnection (user_id) {

    axios.post(CONSTANTS.API_BASE_URL + "/create-connection", {
      user_id: user_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  newPostUpdate(event) {
    let value = event.target.value;

    this.setState({
			...this.state,
      new_post: value
    });
	}

  removePost = (post_id, index) => {
    let home_feed = this.state.home_feed;

    if(home_feed[index].post_id == post_id){
      home_feed.splice(index, 1);
    }

    this.setState({
      ...this.state,
      home_feed: home_feed
    })
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getHomeFeed();
    this.getHighLights();
    this.getLessonProgress();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Home - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'home'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          Home
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <li className="breadcrumb-item text-muted">Home</li>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted">Feed</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl"> 
                        {
                          this.state.viewProfile ?
                          <div>
                            <div className="app-container">
                              <span className="d-block px-lg-20 pb-10">
                                <button onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    viewProfile: false,
                                    viewProfileData: {}
                                  })
                                }} className="btn btn-sm btn-primary">
                                  <i class="las la-long-arrow-alt-left"></i> Go Back
                                </button>
                              </span>
                            </div>
                            {
                              this.state.viewProfileData.user_type === 'entrepreneur' &&
                              <EntrepreneurProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'investor' &&
                              <InvestorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'mentor' &&
                              <MentorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'job_seeker' &&
                              <JobSeekerProfile profileInfo={this.state.viewProfileData} />
                            }
                          </div>
                          :
                          <div className="d-flex flex-row px-lg-20">
                            <div className="w-100 flex-lg-row-fluid me-lg-13">
                              <div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
                                <div className="d-flex align-items-center gap-2">
                                  <div
                                    className="btn btn-icon btn-active-color-primary w-30px h-30px"
                                    id="kt_social_end_sidebar_toggle"
                                  >
                                    
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {
                                this.state.userInfo.profile_completion != "100" &&
                                <div className="card bg-primary card-xl-stretch mb-5 mb-xl-8">
                                  <div className="card-body my-3">
                                    <div className="d-flex flex-stack">
                                      <div className="symbol symbol-60px me-5">
                                        
                                        <span
                                          className="symbol-label"
                                          style={{ background: "#ffffff42" }}
                                        >
                                          <i className="las la-user-alt text-white fs-3x" />
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                        <div className="flex-grow-1 me-10">
                                          <div className="d-flex justify-content-between w-100 mt-auto mb-2 text-white">
                                            
                                            <span className="fw-semibold fs-6">Profile Completion</span>
                                            <span className="fw-bold fs-6">{this.state.userInfo.profile_completion}%</span>
                                          </div>
                                          <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                            <div
                                              className="progress-bar bg-warning"
                                              role="progressbar"
                                              style={{ width: `${this.state.userInfo.profile_completion}%` }}
                                              aria-valuenow={this.state.userInfo.profile_completion}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <div className="text-light-warning fs-8 pt-2">Complete your profile and get access to all features on AWA.</div>
                                        </div>
                                        <a
                                          href="/account-settings"
                                          className="btn btn-sm btn-light fs-8 fw-bold mt-2"
                                        >
                                          Update
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }


                              <div className="card card-flush mb-10">
                                <div className="card-header justify-content-start d-flex flex-md-wrap navbar-expand-lg align-items-center pt-4">
                                  <div className="symbol symbol-45px me-5">
                                    {
                                      this.state.userInfo.profile_pic === null ?
                                      <img className="avatar" src="/assets/media/avatars/avatar.png" alt={this.state.userInfo.full_name} />
                                      :
                                      <img className="avatar" src={this.state.userInfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userInfo.full_name} />
                                    }
                                  </div>
                                  <span className="text-gray-600 fs-5" style={{width: "80%"}}>
                                    <span className="fw-semibold">Hi {this.state.userInfo.full_name.split(" ")[0]} 👋,</span> <br/>
                                    <span className="fs-6">would you like to make an ask, share a resource or share an update?</span>
                                  </span>
                                </div>
                                <div className="card-body pt-2 pb-0">
                                  <TextArea className="my-2" value={this.state.new_post} onChange={(e) => {this.newPostUpdate(e)}} autoSize={{ minRows: 1, maxRows: 6 }} placeholder="Type your message..." bordered={false} />
                                  {
                                    this.state.postErrorMessage.length > 0 && 
                                    <div className="invalid-feedback">{this.state.postErrorMessage}</div>
                                  }
                                </div>
                                <div className="card-footer d-flex justify-content-end pt-0">
                                  {
                                    this.state.userInfo.profile_completion == "100" ?
                                    <>
                                    {
                                      this.state.isRequesting ?
                                      <button type="button" disabled className="btn btn-sm btn-primary">
                                        <span>
                                          Posting...
                                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                        </span>
                                      </button>
                                      :
                                      <button className="btn btn-sm btn-primary" onClick={() => {this.createPost()}}>
                                        <span className="indicator-label">Post</span>
                                      </button>
                                    }
                                    </>
                                    :
                                    <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#alert-modal">
                                      <span className="indicator-label">Post</span>
                                    </button>
                                  }
                                </div>
                              </div>
                              <div className="mb-10" id="kt_social_feeds_posts">
                                {
                                  this.state.errorMessage.length > 0 &&
                                  <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                                    <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <rect x="0" y="0" width="24" height="24"/>
                                          <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                          <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                          <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                        </g>
                                      </svg>
                                    </span>
                                    <div class="d-flex flex-column">
                                      <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                                  </div>
                                }

                                {
                                  this.state.home_feed.length === 0 ?
                                  <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                                    <div className="symbol symbol-40px">
                                      <i className="las la-comment fs-5qx text-gray-400"></i>
                                    </div>
                                    <span className="d-block mt-4">No post found</span>
                                  </div>
                                  :
                                  <>                             
                                    {
                                      this.state.home_feed.map((data, index) => {
                                        return (
                                          <PostCard postInfo={data} index={index} key={data.post_id} removePost={this.removePost} userID={this.state.user_id} profileCompletion={this.state.userInfo.profile_completion == "100" ? true : false} />
                                        )
                                      })
                                    }
                                  </>
                                }
                                
                                
                              </div>
                              {/* <div className="d-flex flex-center">
                                <a
                                  href="#"
                                  className="btn btn-primary fw-bold px-6"
                                  id="kt_social_feeds_more_posts_btn"
                                >
                                  
                                  <span className="indicator-label">Show more</span>
                                  <span className="indicator-progress">
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                  </span>
                                </a>
                              </div> */}
                            </div>
                            <div
                              className="d-lg-flex flex-column flex-lg-row-auto w-lg-350px"
                              data-kt-drawer="true"
                              data-kt-drawer-name="end-sidebar"
                              data-kt-drawer-activate="{default: true, lg: false}"
                              data-kt-drawer-overlay="true"
                              data-kt-drawer-width="{default:'200px', '250px': '300px'}"
                              data-kt-drawer-direction="end"
                              data-kt-drawer-toggle="#kt_social_end_sidebar_toggle"
                            >
                              {
                                this.state.isLoadingHighlight ?
                                <div className="card mb-5 mb-xl-8">
                                  <div className="inner-pre-loader-container">
                                    <div className="inner-pre-loader py-20">
                                      <div className="text-center">  
                                        <div className="spinner-border mt-4" role="status">    
                                          <span className="sr-only">Loading...</span>  
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {
                                    this.state.pending_connections.length > 0 &&
                                    <div className="bg-primary card mb-5 mb-xl-8">
                                      <a href="/my-connections"><div className="card-header border-0 py-5">
                                        <h3 className="card-title align-items-start flex-column">
                                          <span className="card-label fw-bold text-dark fs-5">
                                            {this.state.pending_connections.length} Pending {this.state.pending_connections.length > 1 ? `Connections` : `Connection`}
                                          </span>
                                          
                                        </h3>
                                      </div>
                                      <div className="card-body pt-0">
                                        <div className="symbol-group symbol-hover flex-nowrap mb-3">
                                          {
                                            this.state.pending_connections.map((data, index) => {
                                              let avatar = <></>;
                                              if(index < 4){
                                                avatar = <div key={index} className="symbol symbol-40px symbol-circle">
                                                            {
                                                              data.profile_pic === null ?
                                                              <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} /></Tooltip>
                                                              :
                                                              <Tooltip placement="bottom" title={data.full_name}><img className="avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} /></Tooltip>
                                                            } 
                                                          </div>
                                              }

                                              return (
                                                <>{avatar}</>
                                              )
                                            })
                                          }
                                          {
                                            this.state.pending_connections.length > 4 &&
                                            <div className="symbol symbol-40px symbol-circle">
                                              <Tooltip placement="bottom" title="Click to view all"><span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                                                +{this.state.pending_connections.length - 4}
                                              </span></Tooltip>
                                            </div>
                                          }
                                        </div>
                                        <span className="text-muted mt-1 fw-semibold fs-7">
                                          Click to view
                                        </span>
                                      </div></a>
                                    </div>
                                  }
                                  
                                  {
                                    this.state.userInfo.user_type === 'entrepreneur' &&
                                    <a href="/learn-with-awa" className="card bg-body hoverable borten card-xl-stretch mb-5 mb-xl-8">
                                      <div className="card-body bg-primary">
                                        <i className="las la-chalkboard text-warning fs-3x" />
                                        <div className="text-warning fw-bold fs-2 mt-5">Learn with AWA</div>
                                        <div className="flex-grow-1">

                                          <div className="progress h-7px bg-primary bg-opacity-50 mt-3">
                                            <div
                                              className="progress-bar bg-warning"
                                              role="progressbar"
                                              style={{ width: `${this.state.learn_with_awa}%` }}
                                              aria-valuenow={this.state.learn_with_awa}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                          <div className="d-flex justify-content-between w-100 mt-auto mb-2 text-gray-600">                                    
                                            <span className="fw-semibold fs-6 text-white">Completion</span>
                                            <span className="fw-bold fs-6 text-white">{this.state.learn_with_awa}%</span>
                                          </div>
                                          
                                        </div>
                                      </div>
                                    </a>
                                  }

                                  <div className="card mb-5 mb-xl-8 bg-primary">
                                    <div className="card-header border-0 py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-warning fs-5">
                                          Make new connections
                                        </span>
                                        <span className="text-white mt-1 fw-semibold fs-7">
                                          List of people you can connect with
                                        </span>
                                      </h3>
                                      <div className="card-toolbar">
                                        
                                        <a href="/community/people" className="btn btn-sm btn-warning text-primary">
                                          View All
                                        </a>
                                      </div>
                                    </div>
                                    <div className="card-body pt-5">
                                      {
                                        this.state.people.length === 0 ?
                                        <div className="text-center text-gray-700 my-10">
                                          <div className="symbol symbol-40px">
                                            <i className="las la-user-slash fs-3x text-gray-400"></i>
                                          </div>
                                          <span className="d-block mt-4 text-warning">No one found</span>
                                        </div>
                                        :
                                        <>
                                          {
                                            this.state.people.map((data, index) => {
                                              if(index < 5){
                                                return (
                                                  <>
                                                    {index > 0 && <div className="separator separator-dashed text-warning my-4" />}
                                                    <div className="d-flex flex-stack">
                                                      <div className="symbol symbol-40px me-5">
                                                        {
                                                          data.profile_pic === null ?
                                                          <img className="align-self-center avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                                                          :
                                                          <img className="align-self-center avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                                                        }
                                                      </div>
                                                      <div className="d-flex align-items-center flex-row-fluid">
                                                        <div className="flex-grow-1 me-2">
                                                          <span onClick={() => {
                                                            this.setState({
                                                              ...this.state,
                                                              viewProfile: true,
                                                              viewProfileData: data
                                                            })
                                                          }}
                                                            className="cursor-pointer text-warning text-hover-white fs-6 fw-bold"
                                                          >
                                                            {data.full_name}
                                                          </span>
                                                          <span className="text-white text-capitalize fw-semibold d-block fs-7">
                                                          {data.user_type}
                                                          </span>
                                                        </div>
                                                        <>
                                                          {
                                                            this.state.userInfo.profile_completion == "100" ?
                                                            <>
                                                              {
                                                                data.hasConnection ?
                                                                <button disabled  className="btn btn-sm btn-warning text-primary fs-8 fw-bold">
                                                                  Pending
                                                                </button>
                                                                :
                                                                <button onClick={() => {
                                                                  let people = this.state.people;
                                                                  people[index].hasConnection = true;
  
                                                                  setTimeout(() => {
                                                                    people.splice(index, 1);
                                                                    this.setState({
                                                                      ...this.state,
                                                                      people: people
                                                                    })
                                                                  }, 1000);
                                                                  
                                                                  this.setState({
                                                                    ...this.state,
                                                                    people: people
                                                                  })
  
                                                                  this.createConnection(data.user_id);
                                                                }}  className="btn btn-sm btn-warning text-primary fs-8 fw-bold">
                                                                  Connect
                                                                </button>
                                                              }
                                                            </>
                                                            :
                                                            <button data-bs-toggle="modal" data-bs-target="#alert-modal" className="btn btn-sm btn-light fs-8 fw-bold">
                                                              Connect
                                                            </button>
                                                            
                                                          }
                                                        </>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                            })
                                          }
                                        </>
                                      }
                                    </div>
                                  </div>
                                  <div className="card card-flush mb-5 mb-xl-8 bg-primary">
                                    <div className="card-header py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-warning fs-5">
                                          Recent Topics
                                        </span>
                                        <span className="text-white mt-1 fw-semibold fs-7">
                                          Latest community topics
                                        </span>
                                      </h3>
                                      <div className="card-toolbar">
                                        
                                        <a href="/community/people" className="btn btn-sm btn-warning text-primary">
                                          View All
                                        </a>
                                      </div>
                                    </div>
                                    <div className="card-body pt-5">
                                      <div className="text-center text-gray-700 my-10">
                                        <div className="symbol symbol-40px">
                                          <i className="las la-users fs-3x text-gray-400"></i>
                                        </div>
                                        <span className="d-block mt-4 text-white">No topics found</span>
                                      </div>
                                      {/* <div className="d-flex flex-stack">
                                        <div className="symbol symbol-50px me-5">
                                          
                                          <span className="symbol-label bg-light-primary">
                                            <i class="las la-users fs-2qx text-primary"></i>
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                          <div className="flex-grow-1 me-2">
                                            <a
                                              href="../user-profile/overview.html"
                                              className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                            >
                                              Jacob Jones
                                            </a>
                                            <span className="text-muted fw-semibold d-block fs-7">
                                              40 Members
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="separator separator-dashed my-4" />
                                      <div className="d-flex flex-stack">
                                        <div className="symbol symbol-50px me-5">
                                          
                                          <span className="symbol-label bg-light-primary">
                                            <i class="las la-users fs-2qx text-primary"></i>
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                          <div className="flex-grow-1 me-2">
                                            <a
                                              href="../user-profile/overview.html"
                                              className="text-gray-800 text-hover-primary fs-6 fw-bold"
                                            >
                                              Jacob Jones
                                            </a>
                                            <span className="text-muted fw-semibold d-block fs-7">
                                              34 Members
                                            </span>
                                          </div>
                                        </div>
                                      </div> */}
                                      {/* <div className="separator separator-dashed my-4" /> */}
                                    </div>
                                  </div>
                                  {this.state.userInfo.user_type === 'entrepreneur' ?

                                  <div className="card card-flush mb-5 mb-xl-8 bg-primary">
                                    <div className="card-header py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-warning fs-5">
                                          Track your Finances
                                        </span>
                                        <span className="text-white mt-1 fw-semibold fs-7">
                                          Generate financial reports
                                        </span>
                                      </h3>
                                      <div className="card-toolbar">
                                        
                                        <a href="/accounting" className="btn btn-sm btn-warning text-primary">
                                          Track here
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div>
                                    
                                  </div>
                                  }

                                {this.state.userInfo.user_type === 'entrepreneur' ?

                                  <div className="card card-flush mb-5 mb-xl-8 bg-primary">
                                    <div className="card-header py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-warning fs-5">
                                          List a Job
                                        </span>
                                        <span className="text-white mt-1 fw-semibold fs-7">
                                          Open to hire?
                                        </span>
                                      </h3>
                                      <div className="card-toolbar">
                                        
                                        <a href="/job" className="btn btn-sm btn-warning text-primary">
                                          Go to Jobs
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div>
                                    
                                  </div>
                                }
                                {this.state.userInfo.user_type === 'entrepreneur' ?
                                
                                  <div className="card card-flush mb-5 mb-xl-8 bg-primary">
                                    <div className="card-header py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-warning fs-5">
                                          Apply for a Grant
                                        </span>
                                        <span className="text-white mt-1 fw-semibold fs-7">
                                          
                                        </span>
                                      </h3>
                                      <div className="card-toolbar">
                                        
                                        <a href="/grants" className="btn btn-sm btn-warning text-primary">
                                          Apply here
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div>
                                    
                                    </div>
                                  }

                                  <div className="card card-flush mb-5 mb-xl-8 bg-primary">
                                    <div className="card-header py-5">
                                      <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold text-warning fs-5">
                                          Get Support
                                        </span>
                                        <span className="text-white mt-1 fw-semibold fs-7">
                                          
                                        </span>
                                      </h3>
                                      <div className="card-toolbar">
                                        
                                        <a href="mailto:info@africanwomenamplified.com" className="btn btn-sm btn-warning text-primary">
                                          Requests
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                              
                            </div>
                          </div>

                        }                     
                      </div>
                      {/* alert modal */}
                      <div className="modal fade" aria-hidden="true" tabindex="-1" id="alert-modal">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-body modal-body-lg text-center">
                              <div className="nk-modal my-5">
                                <div className="timeline-icon symbol symbol-circle symbol-90px">
                                  <div className="symbol-label bg-light-gray-500">
                                    <i className="las la-info fs-3qx text-gray-800"></i>
                                  </div>
                                </div>
                                <h3 className="nk-modal-title mt-10">Sorry, can't perform this action</h3>
                                
                                <div className="nk-modal-text">
                                  <p className="lead">Complete your profile and get access to all features on AWA.</p>
                                </div>
                                <div className="nk-modal-action mt-10">
                                  <a href="/account-settings" className="btn btn-lg btn-mw btn-primary m-2">Complete Profile</a>
                                  <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* modal end */}

                      {/* alert modal */}
                      <div className="modal fade" aria-hidden="true" tabindex="-1" id="soon-modal">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-body modal-body-lg text-center">
                              <div className="nk-modal my-5">
                                <div className="timeline-icon symbol symbol-circle symbol-90px">
                                  <div className="symbol-label bg-light-gray-500">
                                    <i className="las la-info fs-3qx text-gray-800"></i>
                                  </div>
                                </div>
                                <h3 className="nk-modal-title mt-10">This feature is coming soon</h3>
                                
                                <div className="nk-modal-text">
                                  <p className="lead">Connecting with other people gives you access to reach out 
                                  and have a one-on-one communication with them.</p>
                                </div>
                                <div className="nk-modal-action mt-10">
                                  <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* modal end */}

                    </div>
                  }

                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default Home;