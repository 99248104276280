import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../misc/AuthService';
import packageJson from '../../../../package.json';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';

class SideNav extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userinfo: AuthService.getAuth().user,
      isLoadingNotifications: true
    }
  }

  getUserProfile () {

    axios.post(CONSTANTS.API_BASE_URL + "/get-my-profile-info", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data.data;

      var userObj = reactLocalStorage.getObject('userObj');
      userObj.profile_completion = responseInfo.data.profile_completion;
      userObj.full_name = responseInfo.data.full_name;
      userObj.profile_pic = responseInfo.data.profile_pic;
      userObj.company_name = responseInfo.data.company_name;
      reactLocalStorage.setObject('userObj', userObj);
      let userinfo = userObj;
      
      this.setState({
        ...this.state,
        userinfo: userinfo
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;
        console.log(error)
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    this.getUserProfile();
  }

  render(){

    return (
      <div id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
        <div className="app-sidebar-logo px-6 pt-2" id="kt_app_sidebar_logo">
          <a href="/home" className="w-100 text-center"> 
            {
              this.state.userinfo.profile_pic === null ?
              <img alt="Logo" src="/assets/media/logos/logo-white.png" className="prof_image h-45px app-sidebar-logo-default" /> 
              :
              <img className="h-90px w-90px app-sidebar-logo-default prof_image" src={this.state.userinfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userinfo.full_name} />
            }
            {
              this.state.userinfo.profile_pic === null ?
              <img alt="Logo" src="/assets/media/logos/logo-white.png" className="h-20px app-sidebar-logo-minimize" /> 
              :
              <img className="h-20px app-sidebar-logo-minimize prof_image" src={this.state.userinfo.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={this.state.userinfo.full_name} />
            }
          </a><br/>
          <div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize"> <span className="svg-icon svg-icon-2 rotate-180">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
                <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
              </svg> 
            </span> 
          </div>
        </div>
          <p className="w-100 mb-0 text-center fs-3 text-white fw-bold">{this.state.userinfo.full_name}</p>
          <p className="w-100 mb-0 text-center text-warning text-capitalize">{this.state.userinfo.user_type}</p>
          <p className="w-100 mb-0 text-center text-white text-capitalize">{this.state.userinfo.company_name}</p>
        <div className="app-sidebar-menu overflow-hidden">
          <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-0" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
            <div className="menu menu-column menu-rounded menu-sub-indention px-3 fs-4" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
              <div className="menu-item">
                <a className={classNames("menu-link", {"active": this.props.currentPage == 'home'})} href="/home"> 
                  <span className="menu-icon">
                    <i class="lab la-connectdevelop fs-2x"></i>
                  </span> 
                  <span className="menu-title">Home</span> 
                </a>
              </div>
              <div className="menu-item">
                <a className={classNames("menu-link", {"active": this.props.currentPage == 'profile'})} href="/my-profile"> 
                  <span className="menu-icon">
                    <i class="las la-user-alt fs-2x"></i>
                  </span> 
                  <span className="menu-title">My Profile</span> 
                </a>
              </div>
              {
                this.state.userinfo.user_type == "entrepreneur" &&
                <div className="menu-item">
                  <a className={classNames("menu-link", {"active": this.props.currentPage == 'grants'})} href="/grants"> 
                    <span className="menu-icon">
                      <i class="las la-hand-holding-usd fs-2x"></i>
                    </span> 
                    <span className="menu-title">Grants</span> 
                  </a>
                </div>
              }
              

              {
                (this.state.userinfo.user_type == "entrepreneur" || this.state.userinfo.user_type == "job_seeker") && 
                <div className="menu-item">
                  <a className={classNames("menu-link", {"active": this.props.currentPage == 'job'})} href="/job"> 
                    <span className="menu-icon">
                      <span className="svg-icon svg-icon-2">
                        <i class="las la-briefcase fs-2x"></i>
                      </span> 
                    </span> 
                    <span className="menu-title">Job Board</span> 
                  </a>
                </div>
              }
              
              <div className="menu-item">
                <a className={classNames("menu-link", {"active": this.props.currentPage == 'community'})} href="/community"> 
                  <span className="menu-icon">
                    <i class="las la-users fs-2x"></i>
                  </span> 
                  <span className="menu-title">AWA Community</span> 
                </a>
              </div>
              {
                this.state.userinfo.user_type == "entrepreneur" &&
                <><div className="menu-item">
                  <a className={classNames("menu-link", {"active": this.props.currentPage == 'accounting'})} href="/accounting"> 
                    <span className="menu-icon">
                      <i class="las la-balance-scale fs-2x"></i>
                    </span> 
                    <span className="menu-title">Accounting</span> 
                  </a>
                </div>
                <div className="menu-item">
                  <a className={classNames("menu-link", {"active": this.props.currentPage == 'learn'})} href="/learn-with-awa"> 
                    <span className="menu-icon">
                      <i class="las la-chalkboard fs-2x"></i>
                    </span> 
                    <span className="menu-title">Learn with AWA</span> 
                  </a>
                </div></>
              }

              <div className="menu-item">
                <a className={classNames("menu-link", {"active": this.props.currentPage == 'fund'})} href="/community-fund"> 
                  <span className="menu-icon">
                    <i class="las la-piggy-bank fs-2x"></i>
                  </span> 
                  <span className="menu-title">Community Fund</span> 
                </a>
              </div>

              <div className="menu-item">
                <a className={classNames("menu-link", {"active": this.props.currentPage == 'settings'})} href="/account-settings"> 
                  <span className="menu-icon">
                    <i class="las la-cog fs-2x"></i>
                  </span> 
                  <span className="menu-title">Account Settings</span> 
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-column-auto p-7" id="kt_docs_aside_footer">
          <span className="text-warning fw-semibold">Version: {packageJson.version}</span>
        </div>
      </div>
    )
  }
}

export default SideNav;