import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/Operations_section1.module.scss';
import section2Styles from '../scss/Operations_section2.module.scss';
import section8Styles from '../scss/BAndM_section8.module.scss';
import styles from '../scss/Operations.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanTwo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress(value){
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handleRedirect(){
    window.location = "./lwa-course-plan";
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  singleSaveGeneral(){
  
    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/single-save", {
      "lesson_plan": "LP6",
      "field_name": [
          "manual_entry_q1"
      ],
      "input": [
          true
      ]
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      this.handleLessonProgress(60);
      window.location = "./lwa-course-plan";
      
      this.setState({
        ...this.state,
        successMessage: "Your progress has been has been saved successfully."
      })

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          alert('ha1');
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

      window.modalShow('error-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
      window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Six</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
                successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                    <section className={section1Styles.section1}>
                    <div className={section1Styles.flexCol}>
                      <div className={section1Styles.flexRow}>
                        
                    </div>
                      <div className={section1Styles.content_box}>
                        <div className={section1Styles.flexRow1}>
                          <h1 className={section1Styles.hero_title}>Business Regulations and Policies</h1>
                          <div className={section1Styles.flexRow1__spacer} />
              
                        </div>
                      </div>
                    </div>
                  </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                    <div className={section2Styles.flexCol}>
                      <div className={section2Styles.flexCol1}>
                        <h1 className={section2Styles.title}>Business Regulations</h1>
                        <h4 className={section2Styles.highlights}>
                          Governments enforces regulations to protect employees’ rights and to preserve their environments and
                          economies. These regulations also keep businesses accountable for their power and influence in the societies
                          in which they find themselves. Extensive regulations can help or hurt companies but regulations ensure that
                          all businesses are held to the same standards and must comply with similar rules.
                          <br />
                          Although regulations may vary depending on the jurisdictions, discussed below are three main ones that can
                          be found in most countries.  <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol2}>
                        <h1 className={section2Styles.title}> Taxes</h1>
                        <h4 className={section2Styles.highlights1}>
                          Ghana: https://gra.gov.gh/online-tools/
                          <br />
                          Nigeria: https://www.firs.gov.ng
                          <br />
                          Senegal: http://www.impotsetdomaines.gouv.sn/en
                          <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol3}>
                        <h1 className={section2Styles.title}>Environmental Regulations</h1>
                        <h4 className={section2Styles.highlights2}>
                          Ghana: http://www.epa.gov.gh/epa/regulations
                          <br />
                          Nigeria: https://ead.gov.ng
                          <br />
                          Senegal: https://www.senegel.org/en/administration/executive-power/ministers/orgdetails/177
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol2}>
                        <h1 className={section2Styles.title}>Licenses</h1>
                        <h4 className={section2Styles.highlights3}>
                          Business owners may be required to maintain small business licenses, permits or certificates to remain
                          legally compliant, depending on their industry and its jurisdiction. For example, businesses selling items
                          like alcohol may have to regularly renew their sales permits because those products are heavily regulated.
                          Professional service providers like may also have to obtain special certification and licenses. Check with
                          your local business licensing office to see what documents you need to obtain, as well as the renewal
                          requirement
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol4}>
                        <h1 className={section2Styles.title}>Company Policies</h1>
                        <h4 className={section2Styles.highlights4}>
                          Company policies are guidelines that help you the business owner deal with the health, safety, and
                          accountability of your staff/employees, as well as guide their interactions with your customers. These
                          policies can also be used as a guideline for ensuring compliance with legal, federal, state or city
                          regulatory requirements, and any other situations that can lead to severe consequences for your business.  
                          <br />
                          When your business has clear business practice policies, both you and your employees benefit. Outlining your
                          employees’ rights and what they can expect in your company gives them an overall framework of how they can
                          help you build a successful business. Company policies also help to protect your business and contribute to
                          a safe and more enjoyable work environment for everyone. As indicated above, there are regulatory business
                          policies that you may need to adhere to, but you also need to develop business practices policies for your
                          business
                          <br />
                        </h4>
                      </div>
              
                      <h1 className={section2Styles.title1}>Company Policies to consider</h1>
              
                      <div className={section2Styles.flexRow}>
                        <div className={section2Styles.flexRow__item}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow__spacer} />
                        <h3 className={section2Styles.subtitle}>Equal opportunity policy</h3>
                      </div>
              
                      <div className={section2Styles.flexRow1}>
                        <div className={section2Styles.flexRow1__item}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow1__spacer} />
                        <h3 className={section2Styles.subtitle}>Workplace health and safety</h3>
                      </div>
              
                      <div className={section2Styles.flexRow2}>
                        <div className={section2Styles.flexRow2__item}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow2__spacer} />
                        <h3 className={section2Styles.subtitle}>Employee code of conduct policy</h3>
                      </div>
              
                      <div className={section2Styles.flexRow2}>
                        <div className={section2Styles.flexRow2__item1}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow2__spacer1} />
                        <h3 className={section2Styles.subtitle}>Attendance, vacation, and time-off policies</h3>
                      </div>
              
                      <div className={section2Styles.flexRow3}>
                        <div className={section2Styles.flexRow3__item}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow3__spacer} />
                        <h3 className={section2Styles.subtitle}>Employee disciplinary action policy</h3>
                      </div>
              
                      <div className={section2Styles.flexRow2}>
                        <div className={section2Styles.flexRow2__item2}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow2__spacer2} />
                        <h3 className={section2Styles.subtitle}>Employee complaint policies</h3>
                      </div>
              
                      <div className={section2Styles.flexRow1}>
                        <div className={section2Styles.flexRow1__item1}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow1__spacer1} />
                        <h3 className={section2Styles.subtitle}>Ethics policy</h3>
                      </div>
              
                      <div className={section2Styles.flexRow2}>
                        <div className={section2Styles.flexRow2__item3}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow2__spacer3} />
                        <h3 className={section2Styles.subtitle}>Work schedule and rest period policies</h3>
                      </div>
              
                      <div className={section2Styles.flexRow2}>
                        <div className={section2Styles.flexRow2__item4}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow2__spacer4} />
                        <h3 className={section2Styles.subtitle}>Substance abuse policies</h3>
                      </div>
              
                      <div className={section2Styles.flexRow3}>
                        <div className={section2Styles.flexRow3__item1}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow3__spacer1} />
                        <h3 className={section2Styles.subtitle}>Workplace security policies</h3>
                      </div>
              
                      <div className={section2Styles.flexRow4}>
                        <div className={section2Styles.flexRow4__item}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow4__spacer} />
                        <h3 className={section2Styles.subtitle}>Bring Your Own Device (BYOD) policies</h3>
                      </div>
              
                      <div className={section2Styles.flexRow3}>
                        <div className={section2Styles.flexRow3__item2}>
                          <img
                            className={section2Styles.icon1}
                            src={require('../../assets/courseplan-six/ed616a6a9452b41f8a95cb64422247ec.png')}
                            alt="alt text"
                          />
                        </div>
                        <div className={section2Styles.flexRow3__spacer2} />
                        <h3 className={section2Styles.subtitle}>Compensation and benefits policy </h3>
                      </div>
              
                      <div className={section2Styles.flexCol5}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer} />
                          <h3 className={section2Styles.subtitle1}>
                            Equal opportunity policy
                            <br />
                          </h3>
                        </div>
              
                        <h4 className={section2Styles.highlights5}>
                          Many countries mandate that you must be an equal opportunity employer by law.  <br />
                          <br />
                          In Ghana, the labour laws prohibit discrimination on the basis of race, sex, ethnic origin, creed, colour,
                          religion, social, or economic status. Part VI of the Labour Act ensures protection of working women and Part
                          V protects workers with disabilities. An equal opportunity policy (EOP) prevents companies from
                          discriminating against job applicants or employees if they are a member of a protected class (e.g, race,
                          gender, age, religion, familial status, color).  <br />
                          <br />
                          {
                            "In Nigeria, the Constitution prohibits discrimination against Nigerian citizens on the basis of their community, ethnic group, place of origin, sex, religion, political opinion or the circumstances of their birth. In addition, the HIV and AIDS (Anti-discrimination) Act 2014 prohibits employers from discriminating directly or indirectly against employees on the basis of their HIV status or HIV-related illness. The Discrimination against Persons with Disabilities (Prohibition) Act, 2018 prohibits discrimination against persons with disabilities. The Lagos State Special Peoples Law 2011, which only applies in Lagos State, also prohibits discrimination on the basis of an employee's disability.  "
                          }
                          <br />
                          <br />
                          In Burkina Faso, Job and professional discrimination are prohibited.
                          <br />
                          By discrimination, it is meant:
                          <br />
                          1) Any distinction, exclusion or preference based mainly on the race, colour, sex, religion, political
                          opinion, disability, pregnancy, national ancestry or social origin, the effect of which is to destroy or
                          alter the equal opportunity or treatment regarding job or profession;
                          <br />
                          <br />
                          2) Any other distinction, exclusion or preference the effect of which is to destroy or alter the equal
                          opportunity or treatment regarding job or profession.
                          <br />
                          <br />
                          In Senegal,  <br />
                          Discrimination, harassment  <br />
                          (a) Age
                          <br />
                          Children below the age of 15 should not be employed in any company, even as apprentices. However, when
                          requested, the minister of labour can grant exemptions under Article L 145 of Labour Code.
                          <br />
                          <br />
                          (b) Race
                          <br />
                          Senegal ensures the equal opportunity and treatment of citizens regarding access to vocational training and
                          employment, regardless of ethnicity, race, sex and religion (Article L 1 of the Labour Code.)
                          <br />
                          <br />
                          (c) Disability
                          <br />
                          Under Article 2 of the Social Orientation Act, disabled workers have equal opportunities and are granted
                          specific rights against all forms of discrimination.
                          <br />
                          <br />
                          (d) Gender
                          <br />
                          Depending on professional qualifications, job and performance, wages are equal for all employees, regardless
                          of their origin, sex, age or status (Article L 105 of the Labour Code).
                          <br />
                          <br />
                          (e) Sexual orientation
                          <br />
                          As far as is known, the Labour Code provides no specific rules regarding sexual orientation. Senegal ensures
                          the equal opportunity and treatment of citizens regarding access to vocational training and employment,
                          regardless of ethnicity, race, sex or religion (Article L 1 of the Labour Code).
                          <br />
                          <br />
                          (g) Medical
                          <br />
                          Employers are not obliged to conduct background or medical checks of employees or candidates. However, where
                          they do, they can decide not to recruit a candidate based on the results.
                          <br />
                          <br />
                          (h)Other
                          <br />
                          There are some incentives under the Social Orientation Act aimed at favouring the employment of young
                          workers through internships and apprenticeships.
                          <br />
                          Your equal opportunity policy is essential for anti-harassment, workplace violence, non-discrimination, or
                          diversity policies your company may consider developing.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol6}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item1}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer1} />
                          <h3 className={section2Styles.subtitle1}>Workplace health and safety</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights6}>
                          It is important to provide your employees with a safe and healthy work environment, especially since
                          workplace health and safety violations can cause harm to your employees, cost your business money, and
                          damage your reputation.
                          <br />
                          You should be proactive and draw up a health and safety policy for your workplace. For example, for your
                          office you may draw up a plan of what employees should do in case of office emergencies, you may also draw a
                          separate one up for your factory or product space.  <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol7}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item2}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer2} />
                          <h3 className={section2Styles.subtitle1}>Employee code of conduct policy</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights7}>
                          {
                            "A code of conduct is the most common policy within an organization. This policy lays out the company's principles, standards, and the moral and ethical expectations that employees and third parties are held to as they interact with the organization. A clear and concise code of conduct can help your staff understand what the business expects of them in terms of their performance and behavior. This policy might include specific rules related to sexual harassment, substance abuse, bribery, giving of gifts, your company’s dress code, confidentiality, and even the use of cell phones or social media during work hours."
                          }
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol7}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item3}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer3} />
                          <h3 className={section2Styles.subtitle1}>Attendance, vacation, and time-off policies</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights6}>
                          Having a standardize system for your employees to request their leave will help your business run more
                          smoothly. A Paid Time Off (PTO) policy should outline how much time off employees receive, when and how they
                          can accrue more time off, who they should contact to request their time off and anything else they may need
                          to know about taking PTO. Other time off policies to consider creating include parental leave policies, sick
                          leave policies and bereavement leave policies.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol7}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item4}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer4} />
                          <h3 className={section2Styles.subtitle1}>Employee disciplinary action policy</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights6}>
                          {
                            "A disciplinary action policy identifies and standardizes your business’s procedures for responding to incidents that go against your company’s policies. A well-written disciplinary action policy clearly states your company's rules and the consequences that happen if those rules are broken.  "
                          }
                          <br />
                          Describe the specific process you will follow to ensure every employee is treated fairly when it comes to
                          discipline. It is important to have a lawyer review this information before you include it in your employee
                          handbook to make sure all disciplinary action is legal in your particular jurisdiction.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol7}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item5}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer5} />
                          <h3 className={section2Styles.subtitle1}>Employee complaint policies</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights6}>
                          Your employees have a right to draw your attention to grievances they have and a right to expect that these
                          grievances will be addressed by you, their employer. Grievances are formal complaints that your employees
                          can file to document their concerns with an aspect of their workplace. It is important to outline a formal
                          process for your employees to file and for you to resolve complaints within your company so that employees
                          know how to handle their concerns in a constructive and professional manner. Consider including a
                          whistleblower and non-retaliation policy to protect employees who make good faith complaints against their
                          superiors.{' '}
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol7}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item6}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer6} />
                          <h3 className={section2Styles.subtitle1}>Ethics policy</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights8}>
                          Ethical policies are guidelines for your employees to operate at the highest level of ethical behavior, in
                          other word, your employees will consistently do the right thing and behave at high standards. Business
                          ethics correlate with the implementation of policies related to all areas of your business.  Good ethical
                          policies create a good work culture based on trust and transparency.
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol8}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item7}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer7} />
                          <h3 className={section2Styles.subtitle1}>Work schedule and rest period policies</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights9}>
                          {' '}
                          A work schedule refers to the specified days and times that an employee is expected to complete the tasks of
                          their employment position. Depending on your business’s needs, you can use a range of methods to schedule
                          your employees’ work schedules, from doing it manually, to using automated scheduling software. In designing
                          a work schedule, it is important to keep in mind the laws regarding work periods in your particular
                          jurisdiction.
                          <br />
                          Ghana – According to the Labour law: A maximum is set at 8 hours a day or 40 hours a week, except in cases
                          expressly noted in the Act. Provision is made for paid overtime, and the Act permits unpaid overtime in
                          certain exceptional circumstances “including an accident threatening human lives or the very existence of
                          the undertaking”.
                          <br />
                          Nigeria - As specified under the National Minimum Wage Act, Normal full time working hours are forty hours
                          per week. However, the Labour Act does not specify general working hours rather these are fixed by the
                          mutual agreement or collective bargaining within the enterprise or industry. Where there is no machinery for
                          collective bargaining, the general working hours may be fixed by an industrial wages board.
                          <br />
                          {
                            'If the worker has to work more than the fixed normal working hours, it is considered as an overtime. There is no statutory provision on the overtime work limit and overtime pay. Overtime compensation is entirely a matter of mutual agreement (employment contract), collective bargaining agreement or an order by the industrial wages board. Sources: §13(1-2) & 59(8) of the Labour Act (Cap L1 LFN 2004); National Minimum Wage Act, 1981'
                          }
                          <br />
                          Burkina Faso:
                          <br />
                          The standard working hours accepted in Burkina Faso are 40 hours of work a week. For work beyond 40 hours,
                          overtime needs to be paid. The rates for overtime ranges from 15% to 120% of the agreed wage, depending upon
                          the type of work and employment.
                          <br />
                          Senegal:
                          <br />
                          Under the Labor Law, working hours cannot exceed:
                          <br />• 8 hours per day;
                          <br />• 40 hours per week; and
                          <br />• 173.33 hours per month.
                          <br />
                          Night work is possible between 10:00pm and 5:00am
                          <br />
                          Rest breaks of 30 minutes or one to two hours are provided for by law.
                          <br />
                          {
                            "Under Senegalese labour law, 'overtime' is defined as any additional time worked beyond regular working hours and is calculated by multiplying the hourly salary by the following rate: for overtime worked during the day - 15% for the first eight extra hours worked and 40% for any extra hours;"
                          }
                          <br />• for night hours - 60% for any extra hours worked; and
                          <br />• for overtime worked on weekends or public holidays - 60% for any extra daytime hours and 100% for
                          any extra nighttime hours.
                          <br />
                          {
                            "Exemptions from overtime are governed by Article L 138 of the Labour Code relating to hours of work within specific sectors. For example, employees who work as maids can work 60 hours per week instead of 40 hours without having the right to claim 20 hours' overtime."
                          }
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol9}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item8}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer8} />
                          <h3 className={section2Styles.subtitle2}>Substance abuse policies</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights10}>
                          {
                            "A substance abuse policy is a set of guidelines that outline your business's protocol for substance abuse in the workplace. It should address issues relating to prescribed medication, illegal drugs, and alcohol abuse."
                          }
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol10}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item9}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer9} />
                          <h3 className={section2Styles.subtitle2}>Workplace security policies </h3>
                        </div>
              
                        <h4 className={section2Styles.highlights11}>
                          The Safety and Security function deals with both business and employee safety and security. It includes all
                          your business’s efforts to prevent and/or mitigate loss, risks to or from staff, threats to your business’s
                          physical assets, damage to its technology and intellectual property, or risks of any other kind arising from
                          all elements surrounding your work environment.  <br />
                          The scope of workplace security has continued to expand. Depending on the nature of the business and related
                          security risks, organizations may need to address the following:
                          <br />
                          Establishing a formal security function.  <br />
                          Establishing computer, e-mail, and Internet policies and procedures.  <br />
                          Including non-compete agreements and other types of clauses in employment contracts for the protection of
                          proprietary information and intellectual property.
                          <br />
                          Developing crisis management and contingency plans.  <br />
                          Establishing theft and fraud prevention procedures.  <br />
                          Developing workplace violence prevention procedures.  <br />
                          Installing premises security systems.  <br />
                          Developing restricted-access policies and key-control procedures.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol11}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item10}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer10} />
                          <h3 className={section2Styles.subtitle2}>Bring Your Own Device (BYOD) policies</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights12}>
                          BYOD (bring your own device) is a policy that governs how employees should (and should not) bring in and use
                          their personally owned devices for work-related activities. Those activities include tasks such as accessing
                          their business emails, connecting to the corporate network, and accessing corporate apps and data. Your
                          company’s BYOD policies need to be transparent and understandable to your employees. Whether it’s legal
                          disclaimers about your company data or security measures and reminders, your BYOD policy should have clear
                          disclosures about the risks and liabilities associated with the policy.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol11}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item11}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer11} />
                          <h3 className={section2Styles.subtitle2}>Compensation and benefits policy</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights13}>
                          Compensation includes the total package including salary, benefits, paid time off and more. Companies that
                          pay attention to current salary conditions and market trends develop compensation strategies that align with
                          budgets and attract talent.
                          <br />
                          Common compensation strategies include:
                          <br />
                          Overtime pay
                          <br />
                          Variable salary based on experience or longevity
                          <br />
                          Employee healthcare
                          <br />
                          Raises and bonuses
                          <br />
                          Retirement package
                          <br />
                          Profit sharing
                          <br />
                          Paid time off (sick days, personal days or vacation time)
                          <br />
                          Education reimbursement or on the job training
                          <br />
                          Flexible schedules
                          <br />
                          Employee perks (gym membership, on-site childcare or free meals)
                          <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol11}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item12}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer12} />
                          <h3 className={section2Styles.subtitle2}>Employee fraternization policy</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights7}>
                          Having a company policy regarding fraternization (also known as dating policies or non-fraternization
                          policies) helps your employees know what is expected of them and how they should conduct themselves at work.
                          Because your employees spend most of their workweek together, there is a chance that their work
                          relationships may develop into romantic relationships. For this reason, your company policy should include
                          specific language regarding fraternization. <br />A fraternization policy must:
                          <br />
                          Define romantic versus friendship behavior and what the company deems as acceptable.
                          <br />
                          Describe the consequences of breaking the fraternization policy.
                          <br />
                          Forbid romantic relationships between managers and their subordinates.
                          <br />
                          Include a course of action for following through with the policy.
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol11}>
                        <div className={section2Styles.flexRow5}>
                          <div className={section2Styles.flexRow5__item13}>
                            <img
                              className={section2Styles.image3}
                              src={require('../../assets/courseplan-six/dfd45982ae64674f667b0b29fe738cf5.png')}
                              alt="alt text"
                            />
                          </div>
                          <div className={section2Styles.flexRow5__spacer13} />
                          <h3 className={section2Styles.subtitle2}>Remote work policy</h3>
                        </div>
              
                        <h4 className={section2Styles.highlights7}>
                          A remote work policy (also known as a work from home policy or telecommuting policy) is a set of guidelines
                          that outlines how and when it is appropriate for your employees to work outside your office. Remote work
                          policies often cover who is eligible to work remotely, what expectations you have of them with regards to
                          communication, how you will track their time, data security rules (VPNS), legal considerations and more.
                        </h4>
                      </div>

                      <button className={section8Styles.box} onClick={() => {this.singleSaveGeneral()}}>
                            <div className={section8Styles.group}>
                              <h3 className={section8Styles.subtitle2}>Finish and Exit</h3>
                            </div>
                          </button>

                    </div>
                  </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanTwo;