import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Space, 
  Input,
} from 'antd';
import 'antd/dist/antd.css';

class ResetPassword extends React.Component {
  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: true,
			showForgotPasswordForm: true,
      isRequesting: false,
			password: '',
      token: '',
      errorMessage: ''
    }
	}

  resetPasswordForm(event, name) {
    let value = event.target.value;
    this.setState({
      ...this.state,
      password: value,
    });
  }
	
	submitResetPasswordForm = e => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: '',
    });
    e.preventDefault();

    axios.post(CONSTANTS.API_BASE_URL + "/auth/reset-password", {token: this.state.token, password: this.state.password})
    .then((response) => {
      let result = response.data;
      this.setState({
        ...this.state,
        isRequesting: false,
        showForgotPasswordForm: false
      });

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        window.location = "/server-offline";
      }
    });

  }

  componentDidMount (){
    document.body.style.backgroundColor = '#114aa0';
    const { match: { params } } = this.props;
		if(params.token){
      this.setState({
        ...this.state,
        token: params.token,
        isLoading: false
      })
    }else{
			window.location = '/not-found';
		}
    
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Reset Password - AWA</title>
          </MetaTags>
          <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
            {
              this.state.isLoading?
              <div className="pre-loader-container">
                <div className="pre-loader">
                  <div className="text-center">  
                    <div className="mb-5"><img alt="Logo" src="/assets/media/logos/logo.png" className="h-70px h-lg-75px" /></div>
                    <div className="spinner-border mt-4" role="status">    
                      <span className="sr-only">Loading...</span>  
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                  <div className="bg-body d-flex flex-center rounded-4 w-md-600px p-10 py-20">
                    {
                      this.state.showForgotPasswordForm ?
                      <div className="w-md-400px">
                        <form className="form w-100" onSubmit={this.submitResetPasswordForm} method="POST">
                          <div className="text-center mb-11">
                            <a href="#" className="mb-0 mb-lg-12">
                              <img
                                alt="Logo"
                                src="/assets/media/logos/logo.png"
                                className="h-50px h-lg-55px"
                              />
                            </a>
                          </div>
                          <div className="text-center mb-11">
                            <h1 className="text-dark fw-bolder mb-3">Setup a New Password</h1>
                            <div className="text-gray-500 fw-semibold fs-6">
                              Enter a new password for your account.
                            </div>
                          </div>
                          {
                            this.state.errorMessage.length > 0 &&
                            <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                              <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                    <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                    <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                  </g>
                                </svg>
                              </span>
                              <div className="d-flex flex-column">
                                <h4 className="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                            </div>
                          }
                          <div className="fv-row mb-8">
                            <label className="form-label mb-3">New Password</label>   
                            <Input.Password size="large" required type="password" className="form-control form-control-lg" id="password" name="password" value={this.state.password} onChange={(e) => {this.resetPasswordForm(e,"password");}} placeholder="Enter your new password" />
                          </div>
                          <div className="d-grid mb-10">
                            { 
                              !this.state.isRequesting ? 
                              <button type="submit" className="btn btn-lg btn-primary">
                                <span>Submit</span> 
                              </button>
                              :
                              <button type="button" disabled className="btn btn-lg btn-primary btn-block">
                                <div className="spinner-border" role="status" style={{margin: "-6px"}}> </div> 
                              </button>
                            }
                          </div>
                          <div className="text-gray-500 text-center fw-semibold fs-6">
                            Remember your password ?
                            <a href="/login" className="link-primary mx-1">
                              Login
                            </a>
                          </div>
                        </form>
                      </div>
                      :
                      <div className="w-md-400px">
                        <div className="pt-lg-10 mb-10 text-center">
                          <span className="svg-icon svg-icon-5hx svg-icon-primary me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="currentColor" opacity="0.3"/>
                                  <path d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z" fill="currentColor"/>
                              </g>
                            </svg>
                          </span>
                          <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Password Changed</h1>
                          <div className="fs-3 fw-bold text-muted mb-10">
                            You have successfully changed your password. <br/>Click on the login button.
                          </div>
                          <div className="text-center mb-10">
                            <a href="/login" className="btn btn-lg btn-primary fw-bolder">
                              Login
                            </a>
                          </div>
                          {/* <div className="fs-5">
                            <span className="fw-bold text-gray-700">Did’t receive an email?</span>
                            <a href="" className="link-primary fw-bolder">
                              Resend
                            </a>
                          </div> */}
                        </div>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            }
          </div>

      </Fragment>
    )
  }
}

export default ResetPassword;