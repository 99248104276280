import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import EntrepreneurProfile from "./includes/EntrepreneurProfile";
import InvestorProfile from "./includes/InvestorProfile";
import MentorProfile from "./includes/MentorProfile";
import JobSeekerProfile from "./includes/JobSeekerProfile";
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
const { Search, TextArea } = Input;

class CommunityGroups extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
      isRequesting: true,
      isLoadingSidePanel: false,
      viewProfile: false,
      viewProfileData: {},
      showLoadMore: false,
      search: '',
      industry: '',
      people: [],
      user_id: '',
      communities: [],
      last_community_id: null,
      communityForm: {
        community_name: '',
        industry: '',
        purpose: ''
      },
      communityFormErrors: {
        community_name: '',
        industry: '',
        purpose: ''
      },
      successMessage: '',
      errorMessage: ''
    }
	}

  updateFilter = (event, name) => {
    let value;

    if(name == 'industry' ){
      value = event;

    }else{
      value = event.target.value;
    }

    this.setState({
      ...this.state,
      [name]: value
    })

  }

  updateCommunityForm = (event, name) => {
    var communityForm = this.state.communityForm;
    let value;

    if(name === 'industry'){
      value = event;
    }else{
      value = event.target.value;
    }

    communityForm[name] = value;
    this.setState({
			...this.state,
      communityForm: communityForm
    });
  }

  submitCommunityForm () {
    let communityFormErrors = {
      community_name: '',
      industry: '',
      purpose: ''
    };

    let error_count = 0;

    if(this.state.communityForm.community_name.length === 0){
      communityFormErrors.community_name = 'Input required';
      error_count++;      
    }

    if(this.state.communityForm.industry.length === 0){
      communityFormErrors.industry = 'Input required';
      error_count++;      
    }

    if(this.state.communityForm.purpose.length === 0){
      communityFormErrors.purpose = 'Input required';
      error_count++;      
    }

    if(error_count > 0 ){
      this.setState({
        ...this.state,
        errorMessage: 'Make sure the right information has been provided for all required fields.',
        communityFormErrors: communityFormErrors,
      });

      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }

		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
      communityFormErrors: {
        community_name: '',
        industry: '',
        purpose: ''
      },
		 });

		axios.post(CONSTANTS.API_BASE_URL + "/create-community", {
      ...this.state.communityForm
    }, AuthService.getAxiosHeaders())
		.then((response) => {
      let responseInfo = response.data;

      let communities = this.state.communities;
      communities.unshift(responseInfo.data);
      this.setState({
        ...this.state,
        isRequesting: false,
        communities: communities,
        successMessage: 'Community has been create successfully'
      });  
      window.modalHide('create-modal');
      window.modalShow('success-modal');
      window.scrollTo({top: 0, behavior: 'smooth'});

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });

        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
		});

	}

  getHighLights () {
    this.setState({
      ...this.state,
      isLoadingSidePanel: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-highlights", {
      page: this.state.page
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      let people = [];
      if(responseInfo.data.people.length > 0){
        for(let i = 0; i < responseInfo.data.people.length; i++){
          let user = responseInfo.data.people[i];
          user.hasConnection = false;
          people.push(user);
        }
      }

      this.setState({
        ...this.state,
        isLoadingSidePanel: false,
        people: people
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoadingSidePanel: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  getCommunities(load=null){
    this.setState({
      ...this.state,
      isRequesting: load === null ? true : false,
      isLoadingMore: load === null ? false : true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-communities", {
      search: this.state.search,
      industry: this.state.industry,
      last_community_id: load === null ? null : this.state.last_community_id,
      count: 20
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let last_community_id = null;
      let communities = responseInfo.data.communities;
      let showLoadMore = communities.length > 19 ? true : false;
      
      if(load !== null){
        let old_communities = this.state.communities;
        communities = old_communities.concat(communities);
      }

      if(communities.length > 0){
        last_community_id = communities[communities.length - 1].community_id
      }
      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        showLoadMore: showLoadMore,
        isLoadingMore: false,
        last_community_id: last_community_id,
        communities: communities
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          isLoadingMore: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  createConnection (user_id, index) {

    axios.post(CONSTANTS.API_BASE_URL + "/create-connection", {
      user_id: user_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  deleteConnection (connection_id, index) {

    axios.post(CONSTANTS.API_BASE_URL + "/delete-connection", {
      connection_id: connection_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
 

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getCommunities();
    this.getHighLights();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Community - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'community'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-wrap flex-stack">
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-4">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                          Communities
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                          <li className="breadcrumb-item text-muted">Home</li>
                          <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                          </li>
                          <li className="breadcrumb-item text-muted">Communities</li>
                        </ul>
                      </div>
                      <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <div className="m-0">
                          <a
                            href="#"
                            className="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            
                            <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            Filter
                          </a>
                          <div
                            className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                            data-kt-menu="true"
                            id="kt_menu_6371365f4a778"
                          >
                            <div className="px-7 py-5">
                              <div className="fs-5 text-dark fw-bold">Filter Options</div>
                            </div>
                            <div className="separator border-gray-200" />
                            <div className="px-7 py-5">
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Search:</label>
                                <Input size="large" required type="text" id="search" placeholder="search by community name" name="search" value={this.state.search} onChange={(e) => {this.updateFilter(e,"search");}} />
                              </div>
                              <div className="mb-10">
                                <label className="form-label fw-semibold">Industry:</label>
                                <Select
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={this.state.industry}
                                  onChange={(value) => {this.updateFilter(value, 'industry')}}
                                  options={[
                                    {
                                      value: 'Beauty',
                                      label: 'Beauty',
                                    },
                                    {
                                      value: 'Food and Beverage',
                                      label: 'Food and Beverage',
                                    },
                                    {
                                      value: 'Hospitality',
                                      label: 'Hospitality',
                                    },
                                    {
                                      value: 'Edu Tech',
                                      label: 'Edu Tech',
                                    },
                                    {
                                      value: 'Logistics',
                                      label: 'Logistics',
                                    },
                                    {
                                      value: 'Business Consulting/Management',
                                      label: 'Business Consulting/Management',
                                    },
                                    {
                                      value: 'Transportation',
                                      label: 'Transportation',
                                    },
                                    {
                                      value: 'Agriculture',
                                      label: 'Agriculture',
                                    },
                                    {
                                      value: 'Fashion',
                                      label: 'Fashion',
                                    },
                                    {
                                      value: 'Manufacturing',
                                      label: 'Manufacturing',
                                    }
                                  ]}
                                />
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      search: '',
                                      industry: ''
                                    })

                                    setTimeout(() => {
                                      this.getCommunities();
                                    }, 400);
                                  }}
                                  className="btn btn-sm btn-light btn-active-light-primary me-2"
                                  data-kt-menu-dismiss="true"
                                >
                                  Reset
                                </button>
                                <button
                                  onClick={() => {this.getCommunities()}}
                                  disabled={this.state.isRequesting}
                                  className="btn btn-sm btn-primary"
                                  data-kt-menu-dismiss="true"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button data-bs-toggle="modal" data-bs-target="#create-modal" className="btn btn-sm fw-bold btn-primary">
                          Create a Community
                        </button>
                      </div>

                    </div>
                  </div>
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">                      
                        {
                          this.state.viewProfile ?
                          <div>
                            <div className="app-container">
                              <span className="d-block px-lg-20 pb-10">
                                <button onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    viewProfile: false,
                                    viewProfileData: {}
                                  })
                                }} className="btn btn-sm btn-primary">
                                  <i class="las la-long-arrow-alt-left"></i> Go Back
                                </button>
                              </span>
                            </div>
                            {
                              this.state.viewProfileData.user_type === 'entrepreneur' &&
                              <EntrepreneurProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'investor' &&
                              <InvestorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'mentor' &&
                              <MentorProfile profileInfo={this.state.viewProfileData} />
                            }

                            {
                              this.state.viewProfileData.user_type === 'job_seeker' &&
                              <JobSeekerProfile profileInfo={this.state.viewProfileData} />
                            }
                          </div>
                          :
                          <div className="d-flex flex-row px-lg-20">
                            {/* main panel */}
                            <div className="w-100 flex-lg-row-fluid me-lg-13">
                              {/* side panel toggle */}
                              <div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
                                <div className="d-flex align-items-center gap-2">
                                  <div
                                    className="btn btn-icon btn-active-color-primary w-30px h-30px"
                                    id="kt_social_end_sidebar_toggle"
                                  >
                                    
                                    <span className="svg-icon svg-icon-1">
                                      <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* main content */}
                              {
                                this.state.isRequesting ?
                                <div className="inner-pre-loader-container">
                                  <div className="inner-pre-loader py-20">
                                    <div className="text-center">  
                                      <div className="spinner-border mt-4" role="status">    
                                        <span className="sr-only">Loading...</span>  
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {
                                    this.state.communities.length === 0 ?
                                    <div className="text-center text-gray-700 mt-0 border-gray-300 border-dotted py-20">
                                      <div className="symbol symbol-40px">
                                        <i className="las la-users fs-5qx text-gray-400"></i>
                                      </div>
                                      <span className="d-block mt-4">No community found</span>
                                    </div>                   
                                    :
                                    <div className="row">
                                      {
                                        this.state.communities.map((data, index) => {
                                          return (
                                            <div className="col-md-12" key={index}>
                                              <a href={`/community/group/${data.community_id}`}><div className="bg-primary card mb-10">
                                                <div className="card-body p-9">
                                                  <div className="fs-3 fw-bold text-warning text-hover-primary">{data.community_name} <span class="badge bg-light text-primary px-3 py-2">{data.industry}</span></div>
                                                  <p className="text-white fw-semibold fs-5 mt-1 mb-7">
                                                    {data.purpose}
                                                  </p>
                                                  <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                    <span class="d-flex align-items-center text-white text-hover-primary me-5 mb-2"> 
                                                      <i class="las la-users me-1 fs-1 text-warning"></i> {data.members_count == null ? 0 : data.members_count} {data.members_count > 1 ? `Members` : `Member`}
                                                    </span>
                                                    <span class="d-flex align-items-center text-white text-hover-primary me-5 mb-2"> 
                                                      <i class="las la-comment me-1 fs-1 text-warning"></i> {data.posts_count  == null ? 0 : data.posts_count} {data.posts_count > 1 ? `Topics` : `Topic`}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div></a>
                                            </div>
                                          )
                                        })
                                      }
                                      
                                    </div>
                                  }
                                </>
                              }
                              
                            </div>
                            {/* side panel */}
                            <div
                              className="d-lg-flex flex-column flex-lg-row-auto w-lg-350px"
                              data-kt-drawer="true"
                              data-kt-drawer-name="end-sidebar"
                              data-kt-drawer-activate="{default: true, lg: false}"
                              data-kt-drawer-overlay="true"
                              data-kt-drawer-width="{default:'200px', '250px': '300px'}"
                              data-kt-drawer-direction="end"
                              data-kt-drawer-toggle="#kt_social_end_sidebar_toggle"
                            >
                              {
                                this.state.isLoadingSidePanel ?
                                <div className="card mb-5 mb-xl-8">
                                  <div className="inner-pre-loader-container">
                                    <div className="inner-pre-loader py-20">
                                      <div className="text-center">  
                                        <div className="spinner-border mt-4" role="status">    
                                          <span className="sr-only">Loading...</span>  
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="bg-primary card mb-5 mb-xl-8">
                                  <div className="card-header border-0 py-5">
                                    <h3 className="card-title align-items-start flex-column">
                                      <span className="card-label fw-bold text-warning fs-5">
                                        Make new connections
                                      </span>
                                      <span className="text-white mt-1 fw-semibold fs-7">
                                        List of people you can connect with
                                      </span>
                                    </h3>
                                    <div className="card-toolbar">
                                      
                                      <a href="/community/people" className="btn btn-sm btn-warning text-primary">
                                        View All
                                      </a>
                                    </div>
                                  </div>
                                  <div className="card-body pt-5">
                                    {
                                      this.state.people.length === 0 ?
                                      <div className="text-center text-gray-700 my-10">
                                        <div className="symbol symbol-40px">
                                          <i className="las la-user-slash fs-3x text-gray-400"></i>
                                        </div>
                                        <span className="d-block mt-4">No one found</span>
                                      </div>
                                      :
                                      <>
                                        {
                                          this.state.people.map((data, index) => {
                                            if(index < 5){
                                              return (
                                                <>
                                                  {index > 0 && <div className="separator separator-dashed my-4" />}
                                                  <div className="d-flex flex-stack">
                                                    <div className="symbol symbol-40px me-5">
                                                      {
                                                        data.profile_pic === null ?
                                                        <img className="align-self-center avatar" src="/assets/media/avatars/avatar.png" alt={data.full_name} />
                                                        :
                                                        <img className="align-self-center avatar" src={data.profile_pic} onError={e => { e.currentTarget.src = "/assets/media/avatars/avatar.png"; }} alt={data.full_name} />
                                                      }
                                                    </div>
                                                    <div className="d-flex align-items-center flex-row-fluid">
                                                      <div className="flex-grow-1 me-2">
                                                        <span onClick={() => {
                                                          this.setState({
                                                            ...this.state,
                                                            viewProfile: true,
                                                            viewProfileData: data
                                                          })
                                                        }}
                                                          className="cursor-pointer text-warning text-hover-primary fs-6 fw-bold"
                                                        >
                                                          {data.full_name}
                                                        </span>
                                                        <span className="text-white text-capitalize fw-semibold d-block fs-7">
                                                        {data.user_type}
                                                        </span>
                                                      </div>
                                                      <>
                                                        {
                                                          this.state.userInfo.profile_completion == "100" ?
                                                          <>
                                                            {
                                                              data.hasConnection ?
                                                              <button disabled  className="btn btn-sm btn-warning text-primary fs-8 fw-bold">
                                                                Pending
                                                              </button>
                                                              :
                                                              <button onClick={() => {
                                                                let people = this.state.people;
                                                                people[index].hasConnection = true;

                                                                setTimeout(() => {
                                                                  people.splice(index, 1);
                                                                  this.setState({
                                                                    ...this.state,
                                                                    people: people
                                                                  })
                                                                }, 1000);
                                                                
                                                                this.setState({
                                                                  ...this.state,
                                                                  people: people
                                                                })

                                                                this.createConnection(data.user_id);
                                                              }}  className="btn btn-sm btn-warning text-primary fs-8 fw-bold">
                                                                Connect
                                                              </button>
                                                            }
                                                          </>
                                                          :
                                                          <button data-bs-toggle="modal" data-bs-target="#alert-modal" className="btn btn-sm btn-warning text-primary fs-8 fw-bold">
                                                            Connect
                                                          </button>
                                                          
                                                        }
                                                      </>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            }
                                          })
                                        }
                                      </>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal my-5">
                    <div className="timeline-icon symbol symbol-circle symbol-90px">
                      <div className="symbol-label bg-light-success">
                        <i className="las la-check fs-3qx text-success"></i>
                      </div>
                    </div>
                    <h3 className="nk-modal-title mt-10">Created Successfully</h3>
                    
                    <div className="nk-modal-text">
                      <p className="lead">{this.state.successMessage}</p>
                    </div>
                    <div className="nk-modal-action mt-10">
                      <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          {/* community modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="create-modal">
            <div className="modal-dialog modal-dialog-centered mw-600px" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create a Community</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal">
                    <i class="las la-times fs-2"></i>
                  </div>
                </div>

                <div className="modal-body modal-body-lg">
                  <div className="nk-modal m-5">
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                      Community Name
                      </label>
                      <Input size="large" required type="text" id="community_name" placeholder="Enter community name" name="community_name" value={this.state.communityForm.community_name} onChange={(e) => {this.updateCommunityForm(e,"community_name");}} />
                      {
                        this.state.communityFormErrors.community_name.length > 0 && 
                        <div className="invalid-feedback">{this.state.communityFormErrors.community_name}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Industry
                      </label>
                      <Select size="large"
                        style={{
                          width: "100%",
                        }}
                        value={this.state.communityForm.industry}
                        onChange={(value) => {this.updateCommunityForm(value, 'industry')}}
                        options={[
                          {
                            value: 'Beauty',
                            label: 'Beauty',
                          },
                          {
                            value: 'Food and Beverage',
                            label: 'Food and Beverage',
                          },
                          {
                            value: 'Hospitality',
                            label: 'Hospitality',
                          },
                          {
                            value: 'Edu Tech',
                            label: 'Edu Tech',
                          },
                          {
                            value: 'Logistics',
                            label: 'Logistics',
                          },
                          {
                            value: 'Business Consulting/Management',
                            label: 'Business Consulting/Management',
                          },
                          {
                            value: 'Transportation',
                            label: 'Transportation',
                          },
                          {
                            value: 'Agriculture',
                            label: 'Agriculture',
                          },
                          {
                            value: 'Fashion',
                            label: 'Fashion',
                          },
                          {
                            value: 'Manufacturing',
                            label: 'Manufacturing',
                          }
                        ]}
                      />
                      {
                        this.state.communityFormErrors.industry.length > 0 && 
                        <div className="invalid-feedback">{this.state.communityFormErrors.industry}</div>
                      }
                    </div>
                    <div className="fv-row mb-8">
                      <label className="required d-flex align-items-center form-label mb-3">
                        Purpose
                      </label>
                      <TextArea required
                        value={this.state.communityForm.purpose}
                        onChange={(e) => {this.updateCommunityForm(e,"purpose");}}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                      {
                        this.state.communityFormErrors.purpose.length > 0 && 
                        <div className="invalid-feedback">{this.state.communityFormErrors.purpose}</div>
                      }
                    </div>
                    {
                      this.state.isRequesting ?
                      <div className="nk-modal-action mt-10 text-end">
                        <button disabled className="btn btn-lg btn-mw btn-primary m-2">
                          <span>
                            Creating...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button disabled className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                      :
                      <div className="nk-modal-action mt-10 text-end">
                        <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => {this.submitCommunityForm()}}>Create</button>
                        <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal">Close</button>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
          
          
		
		
      </Fragment>
    );
  }
}

export default CommunityGroups;