import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/PitchDeck_section1.module.scss';
import section2Styles from '../scss/PitchDeck_section2.module.scss';
import styles from '../scss/PitchDeck.module.scss';
import {FileModal, FileModalXs} from "../misc/lessonThreeModal";
import { 
    Input,
    Select,
    Image,
    Space
  } from 'antd';
  import 'antd/dist/antd.css';
  const { Option } = Select;
  const { TextArea } = Input;

class LessonPlanThree extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isUploading: undefined,
			isLoading: undefined,
      isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0',
      planEntries: {
          pitch_submission_link: '',
          manual_entry_q1: '',
          manual_entry_q2: '',
          manual_entry_q3: '',
          manual_entry_q4: '',
          manual_entry_q5: ''            
      }
    }
	}

  getSavedProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/entry-request", {
      "lesson_plan_requested": "LP2"
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        planEntries: {
          pitch_submission_link: responseInfo.data.pitch_submission_link,
          manual_entry_q1: responseInfo.data.manual_entry_q1,
          manual_entry_q2: responseInfo.data.manual_entry_q2,
          manual_entry_q3: responseInfo.data.manual_entry_q3,
          manual_entry_q4: responseInfo.data.manual_entry_q4,
          manual_entry_q5: responseInfo.data.manual_entry_q5            
      }

      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/early-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        window.location = "/server-offline";
      }
    });
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  

  updatePlanEntries(event, name) {
    var planEntries = this.state.planEntries;
    let value = event.target.value;

    planEntries[name] = value;
    this.setState({
            ...this.state,
      planEntries: planEntries
    });

  }

  submitEntry(){
      this.setState({
        ...this.state,
        isUploading: true,
      })
    
        axios.post(CONSTANTS.API_BASE_URL + "/early-stage/lesson-plan-two", {
          ...this.state.planEntries
        }, AuthService.getAxiosHeaders())
        .then((response) => {
          let responseInfo = response.data;
    
          this.setState({
            ...this.state,
            isUploading: false,
            successMessage: "Your answers have been saved successfully"
          })
    
          window.modalShow('success-modal');
    
        }).catch((error) => {
          try{
            let errorResponse = error.response.data;
    
            if(errorResponse.status === 401 || errorResponse.status === 404){
              alert('ha1');
              window.location = "/not-found";
              return;
            }
    
            let errorMessage = 'Error: Could not connect to server';
            if(errorResponse.hasOwnProperty("message")){
              errorMessage = errorResponse.message;
            }
    
            this.setState({
              ...this.state,
              isRequesting: false,
              errorMessage: errorMessage,
            });
    
          window.modalShow('error-modal');
          window.scrollTo({top: 0, behavior: 'smooth'});
          }catch(e){
          window.location = "/server-offline";
          }
        });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getSavedProgress();
    this.getLessonProgress();
    // this.getUserProfile();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Lesson Plan Three</title>
          </MetaTags>
          <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                    <section className={section1Styles.section1}>
                    <div className={section1Styles.flexCol}>
                      <div className={section1Styles.flexCol1}>
              
                        <div className={section1Styles.content_box}>
                          <div className={section1Styles.flexRow1}>
                            <h1 className={section1Styles.hero_title}>Pitch Deck</h1>
                            <div className={section1Styles.flexRow1__spacer} />
              
                          </div>
                        </div>
                      </div>
              
                      <div className={section1Styles.flexCol4}>
                        <h1 className={section1Styles.hero_title1}>What is a Pitch Deck?</h1>
              
                        <div className={section1Styles.group}>
                          <div className={section1Styles.content_box1}>
                            <h3 className={section1Styles.subtitle}>
                              A Pitch Deck is usually the first communication tool used by entrepreneurs to reach out to potential
                              investors. It is a visual support i.e., a presentation that you create for investors to give them a
                              brief but informative overview of your business and its growth potential. It should cover the key points
                              of your business plan, the products, and services your business provides, high-level financial
                              projections, your funding needs. Your pitch deck should work well on its own as a visual document, but
                              it will primarily be used as a tool in tandem with a presentation to tell the story of your business.
                              <br />
                            </h3>
                          </div>
              
                          <img
                            className={section1Styles.image31}
                            src={require('../../assets/courseplan/93f9b76025b2ba893226a842b29cccfc.png')}
                            alt="alt text"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  }
                  {
                    <section className={section2Styles.section2}>
                    <div className={section2Styles.flexCol}>
                      <div className={section2Styles.flexCol1}>
                        <h1 className={section2Styles.title}>What is the aim of a Pitch Deck?</h1>
                        <h4 className={section2Styles.highlights}>
                          A pitch deck uses visuals like images, graphics, screenshots and videos to help convey in visual form your
                          message about your business to leave a lasting impact in the minds of your potential investors which will
                          helps you (your business) be invited to a future meeting (for more details), to secure funding, to recruit
                          employees or anything you need to do for your business.
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol2}>
                        <h1 className={section2Styles.title}>What must my Pitch Deck Include?</h1>
                        <h4 className={section2Styles.highlights1}>
                          A pitch deck presentation comprises of several presentation slides that help you tell a compelling story
                          about your business. You can put one together using a generic software like PowerPoint, or use other
                          software to create it.
                          <br />
                          <br />
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol3}>
                        <h1 className={section2Styles.title}>How long should my Pitch Deck Be?</h1>
                        <h4 className={section2Styles.highlights2_box}>
                          <div className={section2Styles.highlights2}>
                            <span className={section2Styles.highlights2_span0}>Your Pitch Deck should be</span>
                            <span className={section2Styles.highlights2_span1}> NO LONGER THAN 20 SLIDES</span>
                            <span className={section2Styles.highlights2_span2}>
                              , aim for your pitch deck to be as close to 10 slides as you can. The closer it is to that number of
                              slides, the better.  <br />
                            </span>
                          </div>
                        </h4>
                      </div>
              
                      <div className={section2Styles.flexCol4}>
                        <h1 className={section2Styles.title}>Pitch Deck Examples:</h1>
                        <h4 className={section2Styles.highlights3}>
                          https://piktochart.com/blog/startup-pitch-decks-what-you-can-learn/
                          <br />
                          <br />
                          https://piktochart.com/blog/startup-pitch-decks-what-you-can-learn/#Facebook
                          <br />
                          <br />
                          https://piktochart.com/blog/startup-pitch-decks-what-you-can-learn/#Airbnb
                          <br />
                        </h4>
                      </div>
              
                      <h1 className={section2Styles.title1}>Creating My Pitch deck </h1>
              
                      <div className={section2Styles.flexCol5}>
                        <h1 className={section2Styles.title}>Slide 1: Title Page and Introduction</h1>
                        <h4 className={section2Styles.highlights4}>
                          The Title Page sets the tone for the rest of your presentation. MAKE IT COUNT. The title slide should
                          include the name of your company, its logo, your name, and contact information. Consider putting your name
                          and company on every slide of your pitch deck so your audience will remember you and your company.
                          <br />
                          <br />
                          The introduction slide is where you introduce yourself to build credibility. Remember that this is about
                          your business and not you so do not spend too much time introducing yourself. <br />
                          <br />
                        </h4>
                      </div>
              
                      <h1 className={section2Styles.title2}>Slide 2: Problem Slide</h1>
                      <h1 className={section2Styles.title3}>Slide 3: Solution</h1>
                      <h1 className={section2Styles.title4}>Slide 4: Target Market</h1>
                      <h1 className={section2Styles.title5}>Slide 5: Traction</h1>
                      <h1 className={section2Styles.title6}>Slide 6: Team</h1>
              
                      <div className={section2Styles.content_box2}>
                        <div className={section2Styles.flexCol6}>
                          <h1 className={section2Styles.title11_box}>
                            <div className={section2Styles.title11}>
                              <span className={section2Styles.title11_span0}>
                                You have completed the Lesson on <br />
                              </span>
                              <span className={section2Styles.title11_span1}>
                                Pitch Deck
                                <br />
                              </span>
                              <span className={section2Styles.title11_span2}>
                                Submit your pitch deck here to proceed to the next lesson
                                <br />
                              </span>
                            </div>
                          </h1>
              
                          <button className={section2Styles.box} onClick={this.handlePick}>
                            <h3 className={section2Styles.subtitle1}>Submit</h3>
                          </button>
                        </div>
                      </div>
              
                      <h1 className={section2Styles.hero_title2_box}>
                        <div className={section2Styles.hero_title2}>
                          <span className={section2Styles.hero_title2_span0}>
                            Congratulations!!! You have completed the 6 slides needed for your Pitch deck.  <br />
                            <br />
                          </span>
                        </div>
                      </h1>
                      
                      <a href="./lwa-course-plan" className={section2Styles.box1}>
                            <h3 className={section2Styles.subtitle2}>Return</h3>
                          </a>
                    </div>
                  </section>
                  }
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          

          {/* success modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-success">
                          <i className="las la-check fs-3qx text-success"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.successMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
            {/* Error modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Not successful.</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
             {/* pitch modal */}
          <div className="modal fade" aria-hidden="true" tabindex="-1" id="pitch-modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal my-5">
                      
                      <h3 className="nk-modal-title mt-10">Kindly finish the course before submitting your elevator pitch</h3>
                      
                      <div className="nk-modal-text">
                        <p className="lead">{this.state.errorMessage}</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal end */}
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LessonPlanThree;