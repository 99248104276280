import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import PostCard from "./includes/PostCard";
const { Option } = Select;
const { Search, TextArea } = Input;

class PostInfo extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      user_id: '',
      post: [],
      errorMessage: ''
    }
	}

  getPost (post_id) {
    this.setState({
      ...this.state,
      isLoading: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-posts", {
      post_id: post_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        user_id: responseInfo.data.user_id,
        post: responseInfo.data.posts,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  removePost = (post_id, index) => {
    
    this.setState({
      ...this.state,
      post: null
    })
  }

  componentDidMount () {
    window.KTComponents.init();
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = window.parse_query_string(url.search.replace(/^(\?)/,""));
    
    let post_id = params.hasOwnProperty('p') ? params.p : null;
    if(post_id !== null){
      this.getPost(post_id);
      
    }else{
      this.setState({
        ...this.state,
        isLoading: true,
        errorMessage: "Post not found. Invalid post information."
      })
    }

    let notification_id = params.hasOwnProperty('n') ? params.n : null;
    if(notification_id !== null){
      AuthService.updateNotification(notification_id);
    }
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Post - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={''} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="col-lg-8 offset-lg-2">
                          <div  className="d-block py-10">
                            <a href="/home" className="btn btn-sm btn-primary">
                              <i class="las la-long-arrow-alt-left"></i> Go Home
                            </a>
                          </div>
                          {
                            this.state.errorMessage.length > 0 &&
                            <div class="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                              <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                    <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                    <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                  </g>
                                </svg>
                              </span>
                              <div class="d-flex flex-column">
                                <h4 class="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                            </div>
                          }

                          {
                            this.state.post.length === 0 ?
                            <div className="text-center text-gray-700 mt-20 border-gray-300 border-dotted py-20">
                              <div className="symbol symbol-40px">
                                <i className="las la-comment fs-5qx text-gray-400"></i>
                              </div>
                              <span className="d-block mt-4">No post found</span>
                            </div>
                            :
                            <>                             
                              {
                                this.state.post.map((data, index) => {
                                  return (
                                    <PostCard postInfo={data} index={index} key={data.post_id} removePost={this.removePost} userID={this.state.user_id} profileCompletion={this.state.userInfo.profile_completion == "100" ? true : false} />
                                  )
                                })
                              }
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default PostInfo;