import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import moment from 'moment';
import { LinkItUrl } from 'react-linkify-it';

class JobCard extends React.Component {

  constructor(state){
    super(state);
    this.state = {
      userInfo: AuthService.getAuth().user,
      isRequesting: false,
      errorMessage: '',
      jobInfo: this.props.jobInfo,
      accepting: false,
      accepted: false,
      offerErrorMessage: ''
    }
  }

  acceptJobOffer () {
    this.setState({
      ...this.state,
      accepting: true,
      accepted: false,
      offerErrorMessage: ''
    })

    axios.post(CONSTANTS.API_BASE_URL + "/accept-job", {
      job_id: this.state.jobInfo.job_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      window.scrollTo({top: 0, behavior: 'smooth'});

      let jobInfo = this.state.jobInfo;
      jobInfo.status = "accepted";

      this.setState({
        ...this.state,
        jobInfo: jobInfo,
        accepting: false,
        accepted: true,
      })

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          accepting: false,
          offerErrorMessage: errorMessage
        })

      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  applyJob () {
    this.setState({
      ...this.state,
      isRequesting: true,
    })

    axios.post(CONSTANTS.API_BASE_URL + "/apply-job", {
      job_id: this.state.jobInfo.job_id
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      window.scrollTo({top: 0, behavior: 'smooth'});

      let jobInfo = this.state.jobInfo;
      jobInfo.has_applied = true;
      jobInfo.status = "pending";

      this.setState({
        ...this.state,
        isRequesting: false,
        jobInfo: jobInfo,
      })

      this.props.updateJobs("new", this.props.index, "pending");

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        })

      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  resetOfferModal(){
    setTimeout(() => {
      this.setState({
        ...this.state,
        accepted: false,
        accepting: false,
        offerErrorMessage: ''
      })
    }, 1000);
  }

  componentDidMount () {
    window.KTComponents.init();
  }

  render(){
    return (
      <>
        <div className="card mb-4">
          <div className="card-body">
            <h4 className="fs-1 text-gray-800 w-bolder mb-2">
              {this.state.jobInfo.title} 
              {
                this.state.jobInfo.status === "pending" && this.state.jobInfo.has_applied === true &&
                <span class="badge badge-light-dark badge-lg ms-5">Pending Review</span>
              }

              {
                this.state.jobInfo.status === "offered" &&
                <span class="badge badge-light-primary badge-lg ms-5">Job Offer 🧾</span>
              }
              
              {
                this.state.jobInfo.status === "accepted" &&
                <span class="badge badge-light-success badge-lg ms-5">Employed 🎉</span>
              }
            </h4>
            <p className="mb-6 text-gray-600">from <b>{this.state.jobInfo.company_name}</b></p>
            <LinkItUrl><p className="fs-4 text-gray-600 mb-3" style={{whiteSpace: "pre-wrap"}}>{this.state.jobInfo.description}</p></LinkItUrl>
            <div className="d-flex flex-wrap my-6">
              <div className="me-9 my-1 fs-6">
                <i class="las la-clock me-1 fs-2 text-primary"></i>
                <span className="fw-bold text-primary">Deadline: {moment(this.state.jobInfo.end_date, "YYYY-MM-DD").format("Do MMM YYYY")}</span>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            {
              this.state.jobInfo.has_applied ?
              <>
                {
                  this.state.jobInfo.status === "offered" &&
                  <button className="btn btn-sm btn-light-success" data-bs-toggle="modal" data-bs-target={`#offer-modal${this.state.jobInfo.job_id}`}>
                    Accept Job Offer
                  </button>
                }
              </>
              
              :
              <>
                {
                  this.state.userInfo.profile_completion != "100" ?
                  <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#alert-modal">
                    <span className="indicator-label">Apply</span>
                  </button>
                  :
                  <>
                    {
                      this.state.isRequesting ?
                      <button disabled className="btn btn-sm btn-primary">
                        <span>
                          Applying...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                      :
                      <button className="btn btn-sm btn-mw btn-primary" onClick={() => {this.applyJob()}}>Apply</button>
                    }
                  </>
                }
              </>
              
            }
            
          </div>
        </div>
        {/* offer modal */}
        <div className="modal fade" aria-hidden="true" tabindex="-1" id={`offer-modal${this.state.jobInfo.job_id}`}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body modal-body-lg text-center">
                
                  <div className="nk-modal my-5">
                  {
                  this.state.accepted ?
                    <>
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light-primary">
                          {/* <i className="las la-check fs-3qx text-success"></i> */}
                          <span className="fs-3qx">🎉</span>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Congratulations!</h3>
                      <div className="nk-modal-text">
                        <p className="lead">Welcome to the team. We are excited to have you join us.</p>
                      </div>
                      <div className="nk-modal-action mt-10">
                        <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal" onClick={() => { this.resetOfferModal() }}>Close</button>
                      </div>
                    </>
                    :
                    <>
                      <div className="timeline-icon symbol symbol-circle symbol-90px">
                        <div className="symbol-label bg-light">
                          <i className="las la-exclamation fs-3qx text-dark"></i>
                        </div>
                      </div>
                      <h3 className="nk-modal-title mt-10">Want to accept job offer?</h3>
                      <div className="nk-modal-text">
                        <p className="lead">Are you sure you want to accept this job offer?.</p>
                        {
                          this.state.offerErrorMessage.length > 0 && <p className="text-danger">{this.state.offerErrorMessage}</p>
                        }
                      </div>
                      {
                        this.state.accepting ?
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-primary m-2" disabled>
                            Accepting <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </button>
                          <button className="btn btn-lg btn-mw btn-light m-2" disabled>Close</button>
                        </div>
                        :
                        <div className="nk-modal-action mt-10">
                          <button className="btn btn-lg btn-mw btn-primary m-2" onClick={() => { this.acceptJobOffer() }}>Accept</button>
                          <button className="btn btn-lg btn-mw btn-light m-2" data-bs-dismiss="modal" onClick={() => { this.resetOfferModal() }}>Close</button>
                        </div>
                      }
                    </>
                  } 
                  </div>
                
                
              </div>
            </div>
          </div>
        </div>
        {/* modal end */}
      </>
    )
  }
}

export default JobCard;