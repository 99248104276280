import React from 'react';
import cn from 'classnames';

import styles from '../../scss/GrantFrame.module.scss';
import stylesXs from '../../scss/GrantFrameXs.module.scss';

class GrantFrame extends React.Component {
  render(){
    return (
      <div className={cn(styles.root, 'grant-frame')}>
        <div className={styles.group}>
          <div className={styles.flexCol}>
            <div className={styles.rect} />
            <hr className={styles.line} size={1} />
          </div>
  
          <div className={styles.flexCol1}>
           

            <p className={styles.paragraph}>
              {
                this.props.granttitle
              }
            </p>

            <p className={styles.paragraph2}>
              {
                this.props.grantdeadline
              }
            </p>

            <p className={styles.paragraph1}>
              {
                this.props.grantdescription
              }
            </p>


  
            <div className={styles.box}>
                <a className={styles.flexRowBut} target="_blank" href={this.props.grantlink}>
                <div className={styles.flexRow}>
                    <h3 className={styles.subtitle}>Learn More</h3>
                    <div className={styles.flexRow__spacer} />
                    <div className={styles.flexRow__item}>
                    <img
                        className={styles.image}
                        src={require('../../../assets/grants/arr-bc2159af13552c5b0537db912eb6875b.png')}
                        alt="alt text"
                    />
                    </div>
                </div>
                </a>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

class GrantFrameXs extends React.Component {
  render(){
    return (
      <div className={cn(stylesXs.root, 'grants-frame')}>
    
      <div className={stylesXs.content_box}>
          <div className={stylesXs.flexCol}>
          <div className={stylesXs.flexCol1}>
              <h5 className={stylesXs.highlights}>{this.props.granttitle}</h5>
              <div className={stylesXs.text}>{this.props.grantdeadline}</div>
              <p className={stylesXs.paragraph}>
              {
                  this.props.grantdescription
              }
              </p>
          </div>

          <div className={stylesXs.box}>
              <a className={stylesXs.flexRowBut} target="_blank" href={this.props.grantlink}>
                  <div className={stylesXs.flexRow}>
                      <h3 className={stylesXs.subtitle}>Learn More</h3>
                      <div className={stylesXs.flexRow__spacer} />
                      <div className={stylesXs.flexRow__item}>
                          <img
                          className={stylesXs.icon}
                          src={require('../../../assets/grants/xsbc2159af13552c5b0537db912eb6875b.png')}
                          alt="alt text"
                          />
                      </div>
                  </div>
              </a>
          </div>
          </div>
      </div>
      </div>
    )
  }
}


export {GrantFrame, GrantFrameXs};
