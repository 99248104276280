import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select,
  Image,
  Space
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import moment from 'moment';
const { Option } = Select;
const { Search, TextArea } = Input;

class Notifications extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      user_type: 'entrepreneur',
      notifications: []
    }
	}

  getNotifications () {
    this.setState({
      ...this.state,
      isLoading: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/get-notifications", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      this.setState({
        ...this.state,
        isLoading: false,
        notifications: responseInfo.notifications
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    window.KTComponents.init();
    this.getNotifications();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Notifications - AWA</title>
          </MetaTags>
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={''} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  
                  {
                    this.state.isLoading ?
                    <div className="inner-pre-loader-container">
                      <div className="inner-pre-loader py-20">
                        <div className="text-center">  
                          <div className="spinner-border mt-4" role="status">    
                            <span className="sr-only">Loading...</span>  
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                      <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="row mt-20">
                          <div className="col-12">
                            <h1 className="mb-10 text-center">Notifications</h1>
                          </div>
                          <div className="col-lg-6 offset-lg-3">
                            <div className="card mb-5 mb-xl-8">
                              <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                  <span className="card-label fw-bold text-dark fs-5">
                                    {this.state.notifications.length} {this.state.notifications.length > 1 ? `Notifications` : `Notification`} 
                                  </span>
                                  <span className="text-muted mt-1 fw-semibold fs-7">
                                    List of your notifications
                                  </span>
                                </h3>
                                {/* {
                                  this.state.notifications.length > 0 &&
                                  <div class="card-toolbar">
                                    <button onClick={() => {this.updateNotification()}} type="button" class="btn btn-sm btn-light">
                                        Clear All
                                    </button>
                                  </div>
                                } */}
                              </div>
                              <div className="card-body pt-5">
                                
                                {
                                  this.state.notifications.length === 0 ?
                                  <div className="text-center text-gray-700 my-10">
                                    <div className="symbol symbol-40px">
                                      <i className="las la-bell-slash fs-3x text-gray-400"></i>
                                    </div>
                                    <span className="d-block mt-4">No new notification</span>
                                  </div>
                                  
                                  :
                                  <>
                                    {
                                      this.state.notifications.map((data, index) => {
                                        return (
                                          <div key={index} className="d-flex flex-stack py-4" onClick={() => {
                                            if(data.type === 'post'){
                                              window.location = `${data.url}&n=${data.notification_id}`
                                            }else if(data.type === 'connection'){
                                              window.location = `${data.url}?n=${data.notification_id}`
                                            }
                                          }}>
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-35px me-4">
                                                <span className="symbol-label bg-light-primary">
                                                  {
                                                    data.type == "connection" &&
                                                    <i className="las la-user-friends fs-2 text-primary"></i>
                                                  }

                                                  {
                                                    data.type == "post" &&
                                                    <i className="las la-comment fs-2 text-primary"></i>
                                                  }
                                                </span>
                                              </div>
                                              <div className="mb-0 me-2">
                                                <a className="fs-6 text-gray-800 text-hover-primary fw-bold">
                                                  {data.message}
                                                </a>
                                                <div className="text-gray-400 fs-7">
                                                  {moment(data.date, 'YYYY-MM-DD H:mm:ss').calendar({
                                                    sameDay: '[Today] h:mm a',
                                                    nextDay: '[Tomorrow] h:mm a',
                                                    nextWeek: 'dddd',
                                                    lastDay: '[Yesterday] h:mm a',
                                                    lastWeek: '[Last] dddd h:mm a',
                                                    sameElse: 'Do MMM YYYY hh:mm a'
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </>
                                }
                                

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default Notifications;