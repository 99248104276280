import React, { Fragment } from "react";
import cn from 'classnames';
import MetaTags from "react-meta-tags";
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import axios from 'axios';
import SideNav from "./includes/SideNav";
import Header from "./includes/Header";
import section1Styles from '../scss/Courseplan_section1.module.scss';
import section2Styles from '../scss/Courseplan_section2.module.scss';
import section3Styles from '../scss/Courseplan_section3.module.scss';
import section1StylesXs from '../scss/Courseplan_section1-xs.module.scss';
import section2StylesXs from '../scss/Courseplan_section2-xs.module.scss';
import section3StylesXs from '../scss/Courseplan_section3-xs.module.scss';
import section4Styles from '../scss/Courseplan_section4.module.scss';
import section5Styles from '../scss/Courseplan_section5.module.scss';
import section6Styles from '../scss/Courseplan_section6.module.scss';
import section7Styles from '../scss/Courseplan_section7.module.scss';
import styles from '../scss/Courseplan.module.scss';
import stylesXS from '../scss/Courseplan-xs.module.scss';
import {FileModal, FileModalXs} from "../misc/awaModal";

class LearnWithAWA extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.myAwaRef = React.createRef();
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isUploading: undefined,
      selectedOption: undefined,
      selectedOptionXs: undefined,
      lessonProgress: '0'
    }
	}

  getLessonProgress () {

    axios.post(CONSTANTS.API_BASE_URL + "/idea-stage/get-progress", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        lessonProgress: responseInfo.lwa_level,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.status === 401 || errorResponse.status === 404){
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
      }
    });
  }

  audioPlay = () => {
    this.myAwaRef.current.play()
  }

  audioPause = () => {
    this.myAwaRef.current.pause()
  }

  handlePick = () => {
    this.setState(() => ({
      selectedOption: true
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      selectedOption: undefined
    }));
  };

  handlePickXs = () => {
    this.setState(() => ({
      selectedOptionXs: true
    }));
  };

  handleCloseXs = () => {
    this.setState(() => ({
      selectedOptionXs: undefined
    }));
  };

  uploadModal(){
    this.setState(() => ({
      isUploading: true
    }));
  }

  handleLessonProgress = (value) => {
    this.setState(() => ({
      lessonProgress: value
    }));
  }

  singleSaveGeneral(lessonPlan){
  
      axios.post(CONSTANTS.API_BASE_URL + "/early-stage/single-save", {
        "lesson_plan": lessonPlan,
        "field_name": [
            "manual_entry_q1"
        ],
        "input": [
            true
        ]
      }, AuthService.getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        this.handleLessonProgress(50);
        
        this.setState({
          ...this.state,
          successMessage: "Your progress has been has been saved successfully."
        })
  
      }).catch((error) => {
        try{
          let errorResponse = error.response.data;
  
          if(errorResponse.status === 401 || errorResponse.status === 404){
            alert('ha1');
            window.location = "/not-found";
            return;
          }
  
          let errorMessage = 'Error: Could not connect to server';
          if(errorResponse.hasOwnProperty("message")){
            errorMessage = errorResponse.message;
          }
  
          this.setState({
            ...this.state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
  
        window.modalShow('error-modal');
        window.scrollTo({top: 0, behavior: 'smooth'});
        }catch(e){
        window.location = "/server-offlines";
        }
      });
    }

  builtBusinessConfirm = () => {
    axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", {
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      // ---
      if(response.data.data.built_business_id !== null){
        this.singleSaveGeneral('LP5')
      }
    }).catch((error) => {
      try{
        
      }catch(e){
        
      }
    });
  } 

  componentDidMount () {
    window.KTComponents.init();
    this.getLessonProgress();
    if(this.state.lessonProgress >= 4){
      this.builtBusinessConfirm();
    }
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Learn With AWA - Idea Stage Course Plan</title>
          </MetaTags>
           <FileModal
              selectedOption={this.state.selectedOption}
              handleClose={this.handleClose}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
              
            <FileModalXs
                selectedOptionXs={this.state.selectedOptionXs}
                handleCloseXs={this.handleCloseXs}
              handleLessonProgress={this.handleLessonProgress}
              successModal= 'success-modal'
              />
          <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
            
            <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              
              <Header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideNav currentPage={'learn'} />
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  {
                    /*<div className={section1StylesXs.flexRow}>

                            <div className={section1StylesXs.flexRow1}>
                              <div className={section1StylesXs.flexRow1__item}>
                                <img
                                  className={section1StylesXs.icon1}
                                  src={require('../../assets/courseplan/5f32923ae15027b5fba49b99d15136db.png')}
                                  alt="alt text"
                                />
                              </div>
                              <p className={section1StylesXs.desc}>Sound On</p>
                            </div>

                            <div className={section1StylesXs.flexRow__item}>
                              <div className={section1StylesXs.flexRow2}>
                                <div className={section1StylesXs.flexRow2__item}>
                                  <img
                                    className={section1StylesXs.image4}
                                    src={require('../../assets/courseplan/2ed00b85771cba3869b640779d42e609.png')}
                                    alt="alt text"
                                  />
                                </div>
                                <p className={section1StylesXs.desc1}>Sound Off</p>
                              </div>
                            </div>

                          </div>*/
                    //mobile below
                    <section className={section1StylesXs.section1}>
                      <div className={section1StylesXs.group}>
                        <div className={section1StylesXs.rect} />

                        <div className={section1StylesXs.flexCol}>
                          

                          <div className={section1StylesXs.flexCol1}>
                            <h1 className={section1StylesXs.title_box}>
                              <div className={section1StylesXs.title}>
                                <span className={section1StylesXs.title_span0}>Idea Stage </span>
                                <span className={section1StylesXs.title_span1}>Learning Plan</span>
                              </div>
                            </h1>

                            <div className={section1StylesXs.flexCol2}>
                              <div className={section1StylesXs.flexCol2__item}>
                                <img
                                  className={section1StylesXs.image1}
                                  src={require('../../assets/courseplan/5209a47e41e829203c3d1621a0a7ad2b.png')}
                                  alt="alt text"
                                />
                              </div>
                              <div className={section1StylesXs.text}>Lessons completed</div>
                              <div className={section1StylesXs.flexCol2__item}>
                              <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                    <div
                                      className="progress-bar bg-warning"
                                      role="progressbar"
                                      style={{ width: `${this.state.lessonProgress}%`}}
                                      aria-valuenow={this.state.lessonProgress}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                              </div>
                              <div className={section1StylesXs.text1}>{this.state.lessonProgress / 10} / 10 Lessons</div>
                            </div>
                          </div>

                          <h1 className={section1StylesXs.title1_box}>
                            <div className={section1StylesXs.title1}>
                              <span className={section1StylesXs.title1_span0}>
                                Welcome!
                                <br />
                              </span>
                              <span className={section1StylesXs.title1_span1}>
                                <br />
                              </span>
                              <span className={section1StylesXs.title1_span2}>
                              The early stage of your business begins with a potentially scalable idea for a product or service targeting a specific market (can be niche or broad) that will generate value. Unless you are a non-profit organization, every business exists to generate value. At the start up stage your team may be small and consist of just one or two people, in addition to yourself. You may not be organized in a formal structure or solid commitment to the concept or the business that is being built. 
                                <br />
                              </span>
                              <span className={section1StylesXs.title1_span3}>
                              You have an idea, its working so far and you are happy with it. At this stage you may be looking now to build a more solid commitment and structure for your business and the workplans that have been designed will help you do that. 
                               {' '}
                              </span>
                            </div>
                          </h1>
                        </div>
                      </div>
                    </section>
                  }
                 
                
                  {
                    <section className={section3StylesXs.section3}>
                      <div className={section3StylesXs.flexCol}>
                        <div className={section3StylesXs.flexCol1}>
                          <h2 className={section3StylesXs.medium_title}>
                          Have you validated your business idea?
                          </h2>

                          <div className={section3StylesXs.group}>
                            <div className={section3StylesXs.content_box11}>
                              <div className={section3StylesXs.flexCol2}>
                             
                                  <a className={section3StylesXs.flexCol2__item} href="./idea-one">
                                  <img
                                    className={section3StylesXs.image5}
                                    src={require('../../assets/courseplan/6f69432d2628cdf30fb93169360e02bf.png')}
                                    alt="alt text"
                                  />
                                </a>
                                

                                <div className={section3StylesXs.flexCol3}>
                                  <h3 className={section3StylesXs.subtitle_box}>
                                    <div className={section3StylesXs.subtitle}>
                                      <span className={section3StylesXs.subtitle_span0}>Use our Validating Business Ideas lesson plan</span>
                                      <span className={section3StylesXs.subtitle_span1}>to improve market research and understanding as well as to reduce your chance of preventable failures</span>
                                    </div>
                                  </h3>
                                </div>
                              </div>
                            </div>

                            {this.state.lessonProgress < 10 ? 

                            <div
                              className={section3StylesXs.content_box2}
                              style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                              <h1 className={section3StylesXs.title2}>1</h1>
                            </div>
                            :
                            <div
                              className={section3StylesXs.content_box2}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }
                          </div>
                        </div>

                        <div className={section3StylesXs.group1}>
                          <div className={section3StylesXs.content_box12}>
                            <div className={section3StylesXs.flexCol4}>

                            {this.state.lessonProgress > 10 ? 

                              <a className={section3StylesXs.flexCol4__item} data-bs-toggle="modal" data-bs-target="#progress-modal">
                                  <img
                                    className={section3StylesXs.image5}
                                    src={require('../../assets/courseplan/6f69432d2628cdf30fb93169360e02bf.png')}
                                    alt="alt text"
                                  />
                              </a>
                              :
                              <a className={section3StylesXs.flexCol4__item} href="./idea-two">
                                  <img
                                    className={section3StylesXs.image5}
                                    src={require('../../assets/courseplan/6f69432d2628cdf30fb93169360e02bf.png')}
                                    alt="alt text"
                                  />
                              </a>
                            }

                              <div className={section3StylesXs.flexCol5}>
                                <h3 className={section3StylesXs.subtitle1_box}>
                                  <div className={section3StylesXs.subtitle1}>
                                    <span className={section3StylesXs.subtitle1_span0}>Use our Customer Exploration lesson plan</span>
                                    <span className={section3StylesXs.subtitle1_span1}>
                                      {' '}
                                      to clearly identify and map who your customers are, including how to reach and understand them for higher sales and satisfaction.
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>
                          </div>

                          {this.state.lessonProgress < 20 ? 

                          <div
                            className={section3StylesXs.content_box21}
                            style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                            <h1 className={section3StylesXs.title21}>2</h1>
                          </div>
                            :
                            <div
                              className={section3StylesXs.content_box21}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>  
                            }

                          

                        </div>

                        <div className={section3StylesXs.flexCol6}>
                          <h2 className={section3StylesXs.medium_title1}>Have you created an effective business model?</h2>

                          <div className={section3StylesXs.group2}>
                            <div className={section3StylesXs.content_box13}>
                              <div className={section3StylesXs.flexCol7}>



                              {this.state.lessonProgress > 20 ? 

                                <a className={section3StylesXs.flexCol7__item} data-bs-toggle="modal" data-bs-target="#progress-modal">
                                  <img
                                    className={section3StylesXs.image7}
                                    src={require('../../assets/courseplan/2f7cf37d8c0722436b9dab950203ef6e.png')}
                                    alt="alt text"
                                  />
                                </a>
                              :
                              <a className={section3StylesXs.flexCol7__item} href="./idea-three">
                              <img
                                className={section3StylesXs.image7}
                                src={require('../../assets/courseplan/2f7cf37d8c0722436b9dab950203ef6e.png')}
                                alt="alt text"
                              />
                            </a>
                              }
                                <div className={section3StylesXs.flexCol8}>
                                  <h3 className={section3StylesXs.subtitle2_box}>
                                    <div className={section3StylesXs.subtitle2}>
                                      <span className={section3StylesXs.subtitle2_span0}>Use our Understanding Business Models Lesson plan</span>
                                      <span className={section3StylesXs.subtitle2_span1}>
                                        {' '}
                                      to help you figure out how to create an effective business model and execute.  <br />
                                      </span>
                                    </div>
                                  </h3>
                                </div>
                              </div>
                            </div>

                            
                            {this.state.lessonProgress < 30 ? 
                            <div
                            className={section3StylesXs.content_box22}
                            style={{ '--src': `url(${require('../../assets/courseplan/c41be4fe6c5b8e686bb7a7623f04744c.png')})` }}>
                            <h1 className={section3StylesXs.title22}>3</h1>
                            </div>
                            :
                            <div
                            className={section3StylesXs.content_box22}
                            style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </section>
                  }
                  {
                    //web below//
                    <section className={section1Styles.section1}>
                        <div className={section1Styles.content_box}>
                        <div className={section1Styles.flexCol}>
                            <div className={section1Styles.flexRow}>
                            <div className={section1Styles.flexRow__item}>
                                
                            </div>
                            <div className={section1Styles.flexRow__spacer} />

                            <audio id="esaudio" ref={this.myAwaRef} hidden>
                              <source src="https://firebasestorage.googleapis.com/v0/b/africanwomenamplified.appspot.com/o/resources%2Fearly_stage_recording.mp3?alt=media&token=38d722ed-c8d1-4ebb-a340-ab1c18184347" type="audio/mpeg" />
                            </audio>

                            <div className={section1Styles.flexRow1}>
                                <button className={section1Styles.flexRow1__item} onClick={this.audioPlay}>
                                <img
                                    className={section1Styles.image8}
                                    src={require('../../assets/courseplan/36010425d26b53a9bee4fa840aa17f93.png')}
                                    alt="alt text"
                                />
                                </button>
                                <h5 className={section1Styles.highlights1}>Sound On</h5>
                                <div className={section1Styles.flexRow1__spacer} />
                                <button className={section1Styles.flexRow1__item1} onClick={this.audioPause}>
                                <img
                                    className={section1Styles.icon7}
                                    src={require('../../assets/courseplan/e9d749ff25383bb8de7f20c6c57ec1ae.png')}
                                    alt="alt text"
                                />
                                </button>
                                <div className={section1Styles.flexRow1__spacer1} />
                                <h5 className={section1Styles.highlights1}>Sound Off</h5>
                            </div>
                            </div>

                            <div className={section1Styles.flexRow2}>
                            <div className={section1Styles.flexCol1}>
                                <h1 className={section1Styles.hero_title_box}>
                                <div className={section1Styles.hero_title}>
                                    <span className={section1Styles.hero_title_span0}>Idea Stage </span>
                                    <span className={section1Styles.hero_title_span1}>Learning Plan</span>
                                </div>
                                </h1>

                                <div className={section1Styles.flexCol2}>
                                <div className={section1Styles.flexCol2__item}>
                                    <img
                                    className={section1Styles.image}
                                    src={require('../../assets/courseplan/8f404e01cae0e8bd6a760d71961c9578.png')}
                                    alt="alt text"
                                    />
                                </div>

                                <div className={section1Styles.flexCol3}>
                                    <div className={section1Styles.text}>Lessons completed</div>
                                    <div className={section1Styles.flexCol3__item}>
                                   
                                      <div className="progress h-7px bg-warning bg-opacity-50 mt-3">
                                            <div
                                              className="progress-bar bg-warning"
                                              role="progressbar"
                                              style={{ width: `${this.state.lessonProgress}%`}}
                                              aria-valuenow={this.state.lessonProgress}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          </div>
                                      </div>
                                    <div className={section1Styles.text1}>{this.state.lessonProgress / 10} / 10 Lessons</div>
                                </div>
                                </div>
                            </div>

                            <div className={section1Styles.flexCol1_a}> 
                            <h1 className={section1Styles.hero_title2_box}>
                                <div className={section1Styles.hero_title2}>
                                <span className={section1Styles.hero_title2_span0}>
                                    Welcome!
                                    <br />
                                </span>
                                <span className={section1Styles.hero_title2_span1}>
                                    <br />
                                    The early stage of your business begins with a potentially scalable idea for a product or service targeting a specific market (can be niche or broad) that will generate value. Unless you are a non-profit organization, every business exists to generate value. At the start up stage your team may be small and consist of just one or two people, in addition to yourself. You may not be organized in a formal structure or solid commitment to the concept or the business that is being built. <br />
                                </span>
                                <span className={section1Styles.hero_title2_span2}></span>
                                <span className={section1Styles.hero_title2_span3}>
                                You have an idea, its working so far and you are happy with it. At this stage you may be looking now to build a more solid commitment and structure for your business and the workplans that have been designed will help you do that. 
                                    {' '}
                                </span>
                                </div>
                            </h1>
                            </div>
                        </div>
                            </div>
                        </div>
                    </section>
                  }

                  {
                  <section className={section3Styles.section3}>
                    <div className={section3Styles.flexRow}>
                      <div className={section3Styles.flexCol}>
                        <h1 className={section3Styles.hero_title1}>
                            Have you validated your business idea?
                        </h1>

                        <div className={section3Styles.group}>
                          <div className={section3Styles.content_box7}>
                            <div className={section3Styles.flexCol1}>
                            {this.state.lessonProgress > 20 ? 

                              <a data-bs-toggle="modal" data-bs-target="#progress-modal" className={section3Styles.flexCol1__item}>
                                  <img
                                    className={section3Styles.icon2}
                                    src={require('../../assets/courseplan/d072b3216bedd42af5a660de6e061d2f.png')}
                                    alt="alt text"
                                  />
                              </a>
                              :
                              <a href="./idea-two" className={section3Styles.flexCol1__item}>
                                  <img
                                    className={section3Styles.icon2}
                                    src={require('../../assets/courseplan/d072b3216bedd42af5a660de6e061d2f.png')}
                                    alt="alt text"
                                  />
                              </a>
                            }

                              <h3 className={section3Styles.subtitle_box}>
                                <div className={section3Styles.subtitle}>
                                  <span className={section3Styles.subtitle_span0}>Use our Customer Exploration lesson plan</span>
                                  <span className={section3Styles.subtitle_span1}>
                                    {' '}
                                    to clearly identify and map who your customers are, including how to reach and understand them for higher sales and satisfaction.
                                  </span>
                                </div>
                              </h3>
                            </div>
                          </div>
                          {this.state.lessonProgress < 20 ? 
                          <div
                            className={section3Styles.content_box1}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section3Styles.hero_title4}>2</h1>
                          </div>
                          :
                          <div
                          className={section3Styles.content_box1}
                          style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                          </div>
                          } 

                        </div>
                      </div>

                      <div className={section3Styles.flexRow__spacer} />

                      <div className={section3Styles.group1}>
                        <div className={section3Styles.content_box4}>
                          <div className={section3Styles.flexCol2}>
                          
                            <a className={section3Styles.flexCol2__item} href="./idea-one">
                              <img
                                className={section3Styles.icon2}
                                src={require('../../assets/courseplan/d072b3216bedd42af5a660de6e061d2f.png')}
                                alt="alt text"
                              />
                            </a>

                            <div className={section3Styles.flexCol3}>
                              <h3 className={section3Styles.subtitle1_box}>
                                <div className={section3Styles.subtitle1}>
                                  <span className={section3Styles.subtitle1_span0}>Use our Validating Business Ideas lesson plan</span>
                                  <span className={section3Styles.subtitle1_span1}> to improve market research and understanding as well as to reduce your chance of preventable failures</span>
                                </div>
                              </h3>
                            </div>
                          </div>
                        </div>

                        {this.state.lessonProgress < 10 ? 

                        <div
                          className={section3Styles.content_box11}
                          style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                          <h1 className={section3Styles.hero_title41}>1</h1>
                        </div>
                        :
                        <div
                          className={section3Styles.content_box11}
                          style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                        </div>
                        }
                      </div>
                    </div>
                  </section>
                  }

                  {
                    <section className={section4Styles.section4}>
                      <div className={section4Styles.flexRow}>
                        <div className={section4Styles.flexCol}>
                          <h1 className={section4Styles.hero_title1}>Have you created an effective business model?</h1>

                        </div>

                        <div className={section4Styles.flexRow__spacer} />

                        <div className={section4Styles.flexCol2}>
                          <div className={section4Styles.group1}>
                            <div className={section4Styles.content_box11}>
                              <div className={section4Styles.flexCol3}>

                              {this.state.lessonProgress > 20 ? 

                                <a data-bs-toggle="modal" data-bs-target="#progress-modal" className={section4Styles.flexCol3__item}>
                                  <img
                                    className={section4Styles.icon3}
                                    src={require('../../assets/courseplan/ca166040d58e2d86b465272e8230bc89.png')}
                                    alt="alt text"
                                  />
                                </a>

                                :
                                <a href="./idea-three" className={section4Styles.flexCol3__item}>
                                  <img
                                    className={section4Styles.icon3}
                                    src={require('../../assets/courseplan/ca166040d58e2d86b465272e8230bc89.png')}
                                    alt="alt text"
                                  />
                                </a>
                              }
                                
                                <h3 className={section4Styles.subtitle1_box}>
                                  <div className={section4Styles.subtitle1}>
                                    <span className={section4Styles.subtitle1_span0}>Use our Understanding Business Models Lesson plan</span>
                                    <span className={section4Styles.subtitle1_span1}>
                                      {' '}
                                      to help you figure out how to create an effective business model and execute.  <br />
                                    </span>
                                  </div>
                                </h3>
                              </div>
                            </div>

                            
                            {this.state.lessonProgress < 30 ? 

                            <div
                            className={section4Styles.content_box12}
                            style={{ '--src': `url(${require('../../assets/courseplan/d1e9e63c28761da3ef698fba33639ad7.png')})` }}>
                            <h1 className={section4Styles.hero_title41}>3</h1>
                            </div>
                            :
                            <div
                              className={section4Styles.content_box12}
                              style={{ '--src': `url(${require('../../assets/courseplan/a0b6cc0da87f97643cd511efc4069ae4.png')})` }}>
                            </div>
                            }

                          </div>

                        </div>
                      </div>
                    </section>
                  }


                  {/* success modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="success-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-success">
                                <i className="las la-check fs-3qx text-success"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">{this.state.successMessage}</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* Error modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="error-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            
                            <h3 className="nk-modal-title mt-10">Account creation was not successful.</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">{this.state.errorMessage}</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* biz already registered modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="registered-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-success">
                                <i className="las la-check fs-3qx text-success"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Updated Successfully</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">You have submitted a valid business registration document already. Kindly move on to lesson 2.</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  {/* do previous lesson modal */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="progress-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-success">
                                <i className="las la-check fs-3qx text-success"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Lesson Progress</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">Kindly complete the previous lesson to gain access to this one.</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* modal end */}
                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="register-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-info">
                                <i className="las la-mouse fs-3qx text-info"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Select your country to begin the process</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">Click on the appropriate flag to begin the registration process.</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <button className="btn btn-lg btn-mw btn-light m-1" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade" aria-hidden="true" tabindex="-1" id="finance-modal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal my-5">
                            <div className="timeline-icon symbol symbol-circle symbol-90px">
                              <div className="symbol-label bg-light-info">
                                <i className="las la-coins fs-3qx text-info"></i>
                              </div>
                            </div>
                            <h3 className="nk-modal-title mt-10">Open a Built Accounting profile</h3>
                            
                            <div className="nk-modal-text">
                              <p className="lead">To begin the journey on finances, set your business up on Built Accounting</p>
                            </div>
                            <div className="nk-modal-action mt-10">
                              <a className="btn btn-lg btn-mw btn-light m-1" href="./accounting">Open Account</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              
            </div>
            
          </div>
          
          
          <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
            
            <span class="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
            </span>
            
          </div>
          
          
          
          
		
		
      </Fragment>
    );
  }
}

export default LearnWithAWA;