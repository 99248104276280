import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from "axios";
import moment from 'moment';
import CONSTANTS from './Constants';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

const AuthService =  {
    checkProtected: () => {
        var access_token = cookie.load('access_token');
				var userObj = reactLocalStorage.getObject('userObj');

        if(access_token === undefined || access_token  == ""){
					window.location = "/logout"
					return;
				}

				if(JSON.stringify(userObj) === '{}'){
					window.location = "/logout";
					return;

				}else if(!userObj.isEmailVerified){
					window.location = "/email-verification";
					return;

				}else if(!userObj.onboardingCompleted){
					window.location = "/onboarding";
					return;
				}
				
    },

    checkOnboardingProtected: () => {
      var access_token = cookie.load('access_token');
      var userObj = reactLocalStorage.getObject('userObj');

      if(access_token === undefined || access_token  == ""){
        window.location = "/logout"
        return;
      }

      if(JSON.stringify(userObj) === '{}'){
        window.location = "/logout";
        return;
      }else if(!userObj.isEmailVerified){
        window.location = "/email-verification";
        return;

      }else if(userObj.onboardingCompleted){
        window.location = "/home";
        return;
      }
      
  },

		checkAdminProtected: () => {
			var access_token = cookie.load('admin_access_token');

			if(access_token === undefined || access_token  == ""){
				window.location = "/logout"
				return;
			}
			
		},

    getAuth: () => {
        var userObj = reactLocalStorage.getObject('userObj');
        var access_token = cookie.load('access_token');
        if(JSON.stringify(userObj) === '{}' || access_token === ''){
            window.location = "/logout";
						return;
        }   
        
        return {
          "user": userObj,
          "access_token": access_token
        }
    },

    checkGreeter: () => {
        var userObj = reactLocalStorage.getObject('userObj');
        var access_token = cookie.load('access_token');
        if(access_token !== undefined && userObj.isEmailVerified){
          window.location = "/home";
					return;
        }  
    },

    getAxiosHeaders: () => {
        var access_token = cookie.load('access_token');
        return { headers: {"Authorization" : `Bearer ${access_token}`} }
    },

    getBuiltSUHeaders: () => {
      return { 
        headers: {
          "X-Requested-With" : "XMLHttpRequest", 
          "Content-Type": "application/json",
          "Accept": "application/json"
        } 
      }
  },

  getBuiltSUHeadersUserToken: (usertoken) => {
    return { 
      headers: {
        "X-Requested-With" : "XMLHttpRequest", 
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization" : `Bearer ${usertoken}`
      } 
    }
},

		getAxiosAdminHeaders: () => {
			var access_token = cookie.load('admin_access_token');
			return { headers: {"Authorization" : `Bearer ${access_token}`} }
		},

    getAxiosAdminUploadHeaders: () => {
      var access_token = cookie.load('admin_access_token');
      return { 
          headers: {
              "Authorization" : `Bearer ${access_token}`, 
              "content-type": "multipart/form-data"
          } 
      }
    },

    getAxiosUploadHeaders: () => {
        var access_token = cookie.load('access_token');
        return { 
            headers: {
                "Authorization" : `Bearer ${access_token}`, 
                "content-type": "multipart/form-data"
            } 
        }
    },

    updateNotification: (notification_id) => {
      var access_token = cookie.load('access_token');
      axios.post(CONSTANTS.API_BASE_URL + "/update-notification", {
        notification_id: notification_id
      }, {headers: {"Authorization" : `Bearer ${access_token}`}})
      .then((response) => {
        // ---
      }).catch((error) => {
        try{
          console.log(error);
        }catch(e){
          console.log(error);
          // window.location = "/server-offline";
        }
      });
    }, 

    builtUserStatus: () => {
      var access_token = cookie.load('access_token');
      axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", {
      }, {headers: {"Authorization" : `Bearer ${access_token}`}})
      .then((response) => {
        // ---
        if(response.data.data.built_Acc_user_token !== null){
					window.location = "/built-business";
        }
      }).catch((error) => {
        try{
          
        }catch(e){
          
        }
      });
    }, 

    builtUserStatusSecond: () => {
      var access_token = cookie.load('access_token');
      axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", {
      }, {headers: {"Authorization" : `Bearer ${access_token}`}})
      .then((response) => {
        // ---
        if(response.data.data.built_Acc_user_token == null){
					window.location = "/accounting";
        }
      }).catch((error) => {
        try{
          
        }catch(e){
          
        }
      });
    }, 

    builtUserStatusThird: () => {
      var access_token = cookie.load('access_token');
      axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", {
      }, {headers: {"Authorization" : `Bearer ${access_token}`}})
      .then((response) => {
        // ---
        if(response.data.data.built_business_id == null){
					window.location = "/accounting";
        }
      }).catch((error) => {
        try{
          
        }catch(e){
          
        }
      });
    }, 

    builtBusinessStatus: () => {
      var access_token = cookie.load('access_token');
      axios.post(CONSTANTS.API_BASE_URL + "/accounting/request-user-token", {
      }, {headers: {"Authorization" : `Bearer ${access_token}`}})
      .then((response) => {
        // ---
        if(response.data.data.built_business_id !== null){
					window.location = "/built-dashboard";
        }
      }).catch((error) => {
        try{
          
        }catch(e){
          
        }
      });
    } 
};

export default AuthService;