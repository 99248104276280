import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios';
import AuthService from '../misc/AuthService';
import CONSTANTS from '../misc/Constants';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Space, 
  Input,
} from 'antd';
import 'antd/dist/antd.css';

class ForgotPassword extends React.Component {
  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: true,
			showForgotPasswordForm: true,
      isRequesting: false,
			email: '',
      errorMessage: ''
    }
	}

  forgotPasswordForm(event, name) {
    let value = event.target.value;
    this.setState({
      ...this.state,
      email: value,
    });
  }
	
	submitForgotPasswordForm = e => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: '',
    });
    e.preventDefault();

    axios.post(CONSTANTS.API_BASE_URL + "/auth/forgot-password", {email: this.state.email})
    .then((response) => {
      let result = response.data;
      this.setState({
        ...this.state,
        isRequesting: false,
        showForgotPasswordForm: false
      });

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        window.location = "/server-offline";
      }
    });

  }

  componentDidMount (){
    document.body.style.backgroundColor = '#114aa0';
    setTimeout(() => {
      this.setState({
        ...this.state,
        isLoading: false
      })
    }, 1000);
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Forgot Password - AWA</title>
          </MetaTags>
          <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
            {
              this.state.isLoading?
              <div className="pre-loader-container">
                <div className="pre-loader">
                  <div className="text-center">  
                    <div className="mb-5"><img alt="Logo" src="/assets/media/logos/logo.png" className="h-70px h-lg-75px" /></div>
                    <div className="spinner-border mt-4" role="status">    
                      <span className="sr-only">Loading...</span>  
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                  <div className="bg-body d-flex flex-center rounded-4 w-md-600px p-10 py-20">
                    {
                      this.state.showForgotPasswordForm ?
                      <div className="w-md-400px">
                        <form className="form w-100" onSubmit={this.submitForgotPasswordForm} method="POST">
                          <div className="text-center mb-11">
                            <a href="#" className="mb-0 mb-lg-12">
                              <img
                                alt="Logo"
                                src="/assets/media/logos/logo.png"
                                className="h-50px h-lg-55px"
                              />
                            </a>
                          </div>
                          <div className="text-center mb-11">
                            <h1 className="text-dark fw-bolder mb-3">Forgot your Password?</h1>
                            <div className="text-gray-500 fw-semibold fs-6">
                              Don't worry, enter your email below.
                            </div>
                          </div>
                          {
                            this.state.errorMessage.length > 0 &&
                            <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100"> 
                              <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <circle fill="currentColor" opacity="0.3" cx="12" cy="12" r="10"/>
                                    <rect fill="currentColor" x="11" y="7" width="2" height="8" rx="1"/>
                                    <rect fill="currentColor" x="11" y="16" width="2" height="2" rx="1"/>
                                  </g>
                                </svg>
                              </span>
                              <div className="d-flex flex-column">
                                <h4 className="mb-1 text-danger">Error</h4> <span>{this.state.errorMessage}</span> </div>
                            </div>
                          }
                          <div className="fv-row mb-8">
                            <label className="form-label mb-3">Email</label>   
                            <Input size="large" required type="email" id="email" className="form-control form-control-lg" placeholder="Enter your email address" name="email" value={this.state.email} onChange={(e) => {this.forgotPasswordForm(e,"email");}} />
                          </div>
                          <div className="d-grid mb-10">
                            { 
                              !this.state.isRequesting ? 
                              <button type="submit" className="btn btn-lg btn-primary">
                                <span>Submit</span> 
                              </button>
                              :
                              <button type="button" disabled className="btn btn-lg btn-primary btn-block">
                                <div className="spinner-border" role="status" style={{margin: "-6px"}}> </div> 
                              </button>
                            }
                          </div>
                          <div className="text-gray-500 text-center fw-semibold fs-6">
                            Remember your password ?
                            <a href="/login" className="link-primary mx-1">
                              Login
                            </a>
                          </div>
                        </form>
                      </div>
                      :
                      <div className="w-md-400px">
                        <div className="pt-lg-10 mb-10 text-center">
                          <span className="svg-icon svg-icon-5hx svg-icon-primary me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z" fill="currentColor" opacity="0.3"/>
                                <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="currentColor"/>
                              </g>
                            </svg>
                          </span>
                          <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 mt-7">Password Reset</h1>
                          <div className="fs-3 fw-bold text-muted mb-10">
                            We have sent an email to
                            <a href="#" className="link-primary fw-bolder px-2">{this.state.email}</a>
                            <br />
                            please follow a link to reset your password.
                          </div>
                          <div className="text-center mb-10">
                            <a href="/login" className="btn btn-lg btn-primary fw-bolder">
                              Back to Login
                            </a>
                          </div>
                          {/* <div className="fs-5">
                            <span className="fw-bold text-gray-700">Did’t receive an email?</span>
                            <a href="" className="link-primary fw-bolder">
                              Resend
                            </a>
                          </div> */}
                        </div>
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            }
          </div>

      </Fragment>
    )
  }
}

export default ForgotPassword;